import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton
} from '../Onboarding/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';
import universite_professionnalisante_icon from '../../assets/universite_professionnalisante_icon.png';
import classe_professionnalisante_icon from '../../assets/classe_professionnalisante_icon.png';
import grande_ecole_post_bac_icon from '../../assets/grande_ecole_post_bac_icon.png';
import universite_theorique_icon from '../../assets/universite_theorique_icon.png';
import classe_preparatoire_icon from '../../assets/classe_preparatoire_icon.png';
import dont_know_icon from '../../assets/dont_know_icon.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';

const UserTypeSelection = () => {
  const navigate = useNavigate();
  const [selectedTypes, setSelectedTypes] = useState([]); // Changed to array for multiple selections
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Quels types de formations t'intéressent ? Tu peux en choisir plusieurs !"; // Updated text to reflect multiple selection
  const { isMobile } = useDeviceType();

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const typeOptions = [
    {
      value: 'classe_preparatoire',
      label: 'Classe préparatoire',
      icon: classe_preparatoire_icon
    },
    {
      value: 'universite_theorique',
      label: 'Université théorique',
      icon: universite_theorique_icon
    },
    {
      value: 'grande_ecole_post_bac',
      label: 'Ecole post-bac',
      icon: grande_ecole_post_bac_icon
    },
    {
      value: 'classe_professionnalisante',
      label: 'Classe professionnalisante',
      icon: classe_professionnalisante_icon
    },
    {
      value: 'universite_professionnalisante',
      label: "Université professionnalisante",
      icon: universite_professionnalisante_icon
    },
    {
      value: 'dont_know',
      label: "Je ne suis pas sûr (ne t'inquiètes pas, je suis là pour ça !)",
      icon: dont_know_icon
    },
  ];

  const handleOptionClick = (value) => {
    if (value === 'dont_know') {
      // If "don't know" is selected, clear other selections
      setSelectedTypes(['dont_know']);
    } else {
      setSelectedTypes(prevSelected => {
        if (prevSelected.includes('dont_know')) {
          // If a specific option is selected and "don't know" was previously selected,
          // remove "don't know" and add the new selection
          return [value];
        }
        
        if (prevSelected.includes(value)) {
          // If the option is already selected, remove it
          return prevSelected.filter(type => type !== value);
        } else {
          // Add the new selection
          return [...prevSelected, value];
        }
      });
    }
  };

  const handleSubmit = () => {
    if (selectedTypes.length === 0 || isTyping) return;
    setIsLoading(true);
    try {
      localStorage.setItem('type_formation', JSON.stringify(selectedTypes));
      navigate('/origine-city');
    } catch (error) {
      console.error('Error saving user types to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/domaine-formation');
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        <ProgressBar startPercent={65} endPercent={75} />

        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {typeOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  $isSelected={selectedTypes.includes(option.value)}
                  onClick={() => handleOptionClick(option.value)}
                  type="button"
                  aria-pressed={selectedTypes.includes(option.value)}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedTypes.includes(option.value)}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || selectedTypes.length === 0}
          type="button"
          style={{
            opacity: (isTyping || selectedTypes.length === 0) ? 0.5 : 1,
            cursor: (isTyping || selectedTypes.length === 0) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default UserTypeSelection;