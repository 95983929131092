import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: #FFF;
  position: relative;
`;

export const FooterBox = styled.div`
  max-width: 100%;
  background-color: #38B6FF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 0;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5% 0 2%;
  z-index: 1000;
  border-bottom: 2px solid #E6E6E6;
`;

export const HeaderLogo = styled.img`
  height: 55px;
  width: auto;
`;

export const LoginHeaderButton = styled.button`
  border-radius: 15px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 3px 0px 0px #E6E6E6;
  color: #38B6FF;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 45px;
  width: 200px;
  transition: all 0.15s ease;
  
  &:hover {
    background: #f5f5f5;
  }
  
  &:active {
    transform: translateY(2px);
    box-shadow: none;
  }
`;

export const DemoButton = styled.button`
  background: #38B6FF;
  color: #FFF;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  height: 45px;
  min-width: 200px;
  width: fit-content;
  transition: all 0.15s ease;
  box-shadow: 0px 3px 0px #2d8fcc;
  white-space: nowrap;
  padding: 0 30px;
  
  &:hover {
    background: #5ac2ff;
  }
  
  &:active {
    transform: translateY(2px);
    box-shadow: none;
  }

  &.wide {
    width: 300px;
  }
`;

export const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 70px);
  background: #FFF;
`;

export const HeroSection = styled.div`
  width: 100%;
  height: 700px;
  flex-shrink: 0;
  background: #38B6FF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  margin-bottom: 50px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 50px 5%;
    text-align: center;
  }
`;

export const HeroText = styled.h1`
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 800px;
  
  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
`;

export const HeroImageContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export const HeroImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  
  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

export const WhiteSpace = styled.div`
  width: 100%;
  height: 200px;
  background: #FFF;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  
  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 50px;
  }
`;

export const ScreenContainer = styled.div`
  width: 70%;
  height: 90%;
  flex-shrink: 0;
  border-radius: 50px;
  background: linear-gradient(90deg, #FFD2DF 0%, #FFEAF0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ScreenImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 358px;
  
  @media (max-width: 400px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const InputField = styled.input`
  width: 358px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 0 15px;
  margin-bottom: 20px;
  color: #8C8C8C;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  &::placeholder {
    color: #8C8C8C;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 2px 10px rgba(56, 182, 255, 0.15);
  }
  
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

export const ForgotPasswordText = styled.a`
  color: #8C8C8C;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
  text-align: right;
  margin-bottom: 8px;
`;

export const Divider = styled.div`
  width: 135px;
  height: 1px;
  background: #8C8C8C;
  transition: transform 0.3s ease;
  
  ${ForgotPasswordContainer}:hover & {
    transform: translateY(-4px);
  }
`;

export const LoginButton = styled.button`
  width: 358px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #38B6FF;
  border: none;
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.15s ease;
  
  &:hover {
    background: #2d8fcc;
  }
  
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  z-index: 3000;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease;
`;

export const ExampleTextOverlay = styled.div`
  position: absolute;
  top: 68%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  z-index: 2;
`;

export const ExampleText = styled.span`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
`;

export const ExampleTextBold = styled.span`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 5px;
`;

export const TopExampleTextOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  z-index: 2;
`;

export const TopExampleText = styled.span`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
`;

export const TopExampleTextBold = styled.span`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 5px;
`;

export const CircleContainer = styled.div`
  position: relative;
  width: 800px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 100px auto;
`;

export const CircleTitle = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 150px;
  text-align: center;
`;

export const CirclesWrapper = styled.div`
  position: relative;
  width: 800px;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OuterCircle = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  flex-shrink: 0;
  border-radius: 800px;
  border: 2px solid #D3EFFF;
  background: rgba(255, 234, 240, 0.00);
`;

export const InnerCircle = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 500px;
  border: 2px solid #99DAFF;
  background: rgba(255, 234, 240, 0.00);
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const IconImage = styled.img`
  position: absolute;
  width: auto;
  height: auto;
  max-width: 400px;
  min-width: 175px;
`;

export const QuoteText = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  width: 500px;
  position: absolute;
  left: 20%;
  top: 35%;
  transform: translate(-50%, -50%);
`;

export const SecondQuoteText = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  width: 500px;
  position: absolute;
  right: -10%;
  bottom: 20%;
  transform: translateY(50%);
`;

export const FeatureSection = styled.div`
  width: 90%;
  max-width: 1300px;
  margin: 0 auto 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 200px;

  &.reverse {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    gap: 30px;
    margin: 0 auto 15px auto;
  }
`;

export const FeatureImage = styled.img`
  max-width: 40%;
  height: auto;
  object-fit: contain;
  max-height: 400px;

  @media (max-width: 1024px) {
    max-width: 80%;
  }
`;

export const FeatureContent = styled.div`
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  @media (max-width: 1024px) {
    max-width: 100%;
    align-items: center;
  }
`;

export const FeatureTitle = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
`;

export const FeatureDescription = styled.p`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 30px;
  max-width: 400px;
  text-align: left;

  @media (max-width: 1024px) {
    text-align: center;
    margin: 0 auto 30px auto;
  }
`;

export const AnalyticsTitle = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

export const AnalyticsSubtitle = styled.h3`
  color: #8D8D8D;
  font-family: 'Gabarito', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 30px 0;
`;

export const CTASection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0 100px 0;
`;

export const FooterColumn = styled.div`
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 20px;
  text-align: center;

  img {
    width: 150px; 
    height: auto; 
  }
`;

export const FooterSubtitle = styled.h3`
  margin-top: 10%;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #FFF;
  text-align: center;
  display: inline-block;
  line-height: 1.3;
`;

export const FooterLink = styled.a`
  font-size: 15px;
  font-family: 'Gabarito', sans-serif;
  color: #FFF;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SocialIconsRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const StudentButton = styled.button`
  border-radius: 15px;
  border: 1px solid #FFF;
  background: transparent;
  box-shadow: 0px 3px 0px 0px #FFF;
  color: #FFF;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 45px;
  width: 200px;
  transition: all 0.15s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  &:active {
    transform: translateY(2px);
    box-shadow: none;
  }
`;

export const BottomSpacing = styled.div`
  height: 50px;
`;