import React, { useState } from 'react';
import {
  Overlay,
  PopupContainer,
  ImageWrapper,
  Title,
  Description,
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
} from './styles';
// Import your assets – adjust the path as needed
import popupImage from '../../assets/meo_write.png';
import starIcon from '../../assets/etoile.png';

const StarsPopup = () => {
  // Local state to control the visibility of the popup
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  // Check if the device is iOS
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  console.log("stars popup isIOS : ", isIOS);

  const handlePrimaryButtonClick = () => {
    // App IDs for direct linking
    const IOS_APP_ID = '6737978700';
    const ANDROID_PACKAGE_NAME = 'com.meoria.twa'; // Updated package name

    let url;
    if (isIOS) {
      // iOS: Direct link to App Store
      url = `https://apps.apple.com/fr/app/meoria/id${IOS_APP_ID}`;
      
      // Alternative approach - try to directly open ratings page
      // Some older approaches like the deep link below may not be reliable on newer iOS
      setTimeout(() => {
        window.location.href = `itms-apps://itunes.apple.com/fr/app/id${IOS_APP_ID}?action=write-review`;
      }, 300);
    } else {
      // Android: Direct link to Play Store reviews
      // Format: market://details?id=[PACKAGE_NAME]&showAllReviews=true
      url = `market://details?id=${ANDROID_PACKAGE_NAME}&showAllReviews=true&hl=fr`;
      // Fallback for when the deep link doesn't work
      setTimeout(() => {
        window.location.href = `https://play.google.com/store/apps/details?id=${ANDROID_PACKAGE_NAME}&hl=fr`;
      }, 500);
    }
    window.location.href = url;
  };

  return (
    <Overlay>
      <PopupContainer>
        <ImageWrapper src={popupImage} alt="Popup banner" />
        <Title>Aide-nous à grandir !</Title>
        <Description>
          Si Meo t'as aidé dans tes choix d'orientation, laisse-nous une note pour que d'autres puissent aussi en profiter !
        </Description>
        <ButtonContainer>
          <PrimaryButton onClick={handlePrimaryButtonClick}>
            Noter Meoria
            <img src={starIcon} alt="Star icon" />
          </PrimaryButton>
          <SecondaryButton onClick={() => setIsVisible(false)}>
            Pas maintenant
          </SecondaryButton>
        </ButtonContainer>
      </PopupContainer>
    </Overlay>
  );
};

export default StarsPopup;
