import styled, {css, keyframes} from 'styled-components';
import { MenuButton } from '../sharedStyles';
import theme from '../../theme';


export const SubtitleText = styled.div`
  color: #7F7C7C;
  font-family: Gabarito;
  font-size: 0.68rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin-bottom: 1%;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 80px;
  padding: ${props => props.$user === 'You' ? '0.75rem 1rem' : '0.2rem 0.5rem 0.5rem'};
  margin-bottom: ${props => props.$user === 'You' ? '1.25rem' : '1.5rem'};
  border-radius: ${props => props.$user === 'You' ? '1.2rem' : '1rem'};
  background-color: ${props => props.$user === 'You' ? props.theme.colors.veryLightGray : 'transparent'};
  color: ${props => props.theme.colors.text};
  box-shadow: ${props => props.$user === 'You' ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'};
  width: ${props => props.$user === 'You' ? 'fit-content' : '95%'};
  max-width: ${props => props.$user === 'You' ? '75%' : '100%'};
  text-align: left;
  margin-left: ${props => props.$user === 'You' ? 'auto' : '0'};
  height: auto;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
`;

export const MenuBand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.small};
  background-color: ${props => props.theme.colors.background};
  position: sticky;
  top: ${theme.spacing.medium};
  z-index: 1;

`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MarkdownBox = styled.div`
  overflow-x: auto;
  min-height: 20px; /* Prevents collapse during updates */
  will-change: contents; /* Optimizes for content changes */
  transition: all 0.2s ease-in-out; /* Smooth transition for any changes */
  backface-visibility: hidden; /* Reduces flickering */
  transform: translateZ(0); /* Forces GPU acceleration */

  & > * {
    margin-bottom: 0.625rem;
    animation: ${fadeIn} 0.3s ease-out;
    will-change: opacity;
    opacity: 1;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }

  & th, & td {
    border: 1px solid ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.small};
    text-align: left;
  }

  & th {
    background-color: ${props => props.theme.colors.background};
    font-weight: bold;
  }

  & tr:nth-child(even) {
    background-color: ${props => props.theme.colors.background};
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & code {
    background-color: ${props => props.theme.colors.background};
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
    font-family: 'Gabarito', sans-serif;
  }

  & pre {
    background-color: ${props => props.theme.colors.background};
    padding: ${props => props.theme.spacing.medium};
    border-radius: 4px;
    overflow-x: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.colors.background};

`;

// Updated ChatContainer with conditional blur effect
export const ChatContainer = styled.div`
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
  margin-left: 0;
  border-radius: 12px;
  position: relative;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.medium};

`;


export const SendButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  position: absolute;
  right: 0.75rem;
  bottom: -5px;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Icon styling */
  svg {
    transform: translateY(-1px);
    transition: transform 0.2s ease;
    width: 16px;
    height: 16px;
  }

  /* Hover state */
  &:hover {
    background-color: ${props => props.theme.colors.primary}ee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

    svg {
      transform: translateY(-2px);
    }
  }

  /* Active state */
  &:active {
    transform: translateY(-48%);
  }

  /* Focus state */
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary}33;
  }

  /* Show button when there's input */
  ${props => props.show && css`
    opacity: 1;
    pointer-events: auto;
  `}

  /* Disabled state */
  &:disabled {
    background-color: ${props => props.theme.colors.primary}99;
    cursor: not-allowed;
    transform: translateY(-50%);
  }

  /* Mobile responsiveness */
  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
    right: 0.75rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const WelcomeMenuWrapper = styled.div`
   width: 100%;
   height: 100%;
   margin-bottom: -5%;
`;


export const NewChatIconButton = styled.button`
  position: absolute;
  top: ${theme.spacing.medium};
  right: 5%;
  padding: ${theme.spacing.small};
  background-color: transparent;
  color: ${theme.colors.primary};
  border: 0px solid ${theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  z-index: 2;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const HistoryIconButton = styled.div`
  position: absolute;
  top: ${theme.spacing.medium};
  left: 5%;
  padding: ${theme.spacing.small};
  background-color: transparent;
  color: ${theme.colors.primary};
  border: 0px solid ${theme.colors.primary};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  z-index: 2;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ChatBox = styled.div`
  background-color: ${props => props.theme.colors.background};
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  overflow: hidden;
  width: 100%;
  margin-left: 0;
  border-radius: 12px;

`;

export const ChatHistory = styled.div`
  background-color: ${props => props.theme.colors.background};
  flex: 1;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.small};
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 12%;
  height: 80%;
  padding-top: 10px;  // Add space for centering
  padding-bottom: 140px;

  &:hover {
    scroll-behavior: auto;
  }

`;

// Modified ChatHistory to accommodate the centered welcome menu
export const ModifiedChatHistory = styled(ChatHistory)`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.showWelcomeMenu ? 'center' : 'flex-start'};
  height: 100%;
`;

// Update the ChatBox component if needed
export const ModifiedChatBox = styled(ChatBox)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InputAreaWrapper = styled.div`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small};
  max-width: 100%;
  margin: 0 auto;
  position: fixed;
  pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
  left: 0;
  right: 0;
  bottom: 75px;
  z-index: 0;
  background-color: ${props => props.theme.colors.background};
  opacity: ${props => props.isDisabled ? 0.6 : 1};
  transition: opacity 0.2s ease;
`;

export const InputArea = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  box-shadow: none;

`;

export const Input = styled.textarea`
  flex: 1;
  padding: 0.75rem 3rem 0.75rem 1rem;
  border: 1px solid #ffb6c1;
  border-radius: 25px;
  font-size: 1rem;
  font-family: 'Gabarito', sans-serif;
  line-height: 1.5;
  resize: none;
  overflow-y: auto;
  background-color: ${props => props.disabled ? '#f0f0f0' : '#fff0f5'};
  color: ${props => props.theme.colors.text};
  min-height: 50px;
  max-height: 150px;
  width: 100%;
  outline: none;
  transition: all 0.2s ease;
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};

  &:focus {
    border-color: #ffb6c1;
    background-color: white;
  }

  &::placeholder {
    color: ${props => props.theme.colors.mediumGray};
  }
`;



export const StyledMenuButton = styled(MenuButton)`
position: absolute;
top: ${theme.spacing.medium};
left: ${theme.spacing.medium};
z-index: 2;
`;

export const SourcesContainer = styled.div`
  padding: 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SourceLink = styled.a`
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 7.5px;
  color: #1565c0;
  text-decoration: none;
  font-size: 0.9em;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }
`;


export const LoadingMessage = styled.div`
  display: flex;
  padding: 0.8rem 1.2rem;
  width: fit-content;
  align-items: flex-start;
  background-color: transparent; // Remove background
  box-shadow: none; // Remove box shadow
`;

export const LoadingText = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.text || '#333'};
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: Gabarito;
  font-style: italic; // Add italic style

  &::after {
    content: '';
    animation: loadingDots 0.8s infinite;
    font-weight: bold;
  }

  @keyframes loadingDots {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
    100% { content: ''; }
  }
`;