import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { FaChevronDown } from 'react-icons/fa';
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';

import compassImage from '../../assets/meo_write.png';

import stmgIcon from '../../assets/ses_icon.png';
import st2sIcon from '../../assets/bioeco_icon.png';
import sti2dIcon from '../../assets/engineer_icon.png';
import stlIcon from '../../assets/physics_icon.png';
import std2aIcon from '../../assets/arts_culture_icon.png';
import sthrIcon from '../../assets/wine_industry_icon.png';
import s2tmdIcon from '../../assets/spectacle_icon.png';
import stavIcon from '../../assets/agriculture_specific_icon.png';
import santeIcon from '../../assets/sante_sport_icon.png';
import agricultureIcon from '../../assets/agriculture_specific_icon.png';
import childrenIcon from '../../assets/children_icon.png';
import artsCultureIcon from '../../assets/arts_culture_icon.png';
import artsIcon from '../../assets/arts_icon.png';
import advertisingIcon from '../../assets/advertising_icon.png';
import electronicsIcon from '../../assets/electronics_icon.png';
import bioecoIcon from '../../assets/bioeco_icon.png';
import culinaryIcon from '../../assets/culinary_specific_icon.png';
import devDurableIcon from '../../assets/dev_durable_icon.png';
import physchimIcon from '../../assets/physchim_icon.png';
import commerceManagementIcon from '../../assets/commerce_management_icon.png';
import modeIcon from '../../assets/mode_icon.png';
import audiovisuelIcon from '../../assets/audiovisuel_icon.png';
import informatiqueIcon from '../../assets/informatique_icon.png';
import medecineIcon from '../../assets/medecine_icon.png';
import nuclearIcon from '../../assets/nuclear_specific_icon.png';
import designIcon from '../../assets/design_icon.png';
import accountingAuditIcon from '../../assets/accounting_audit_icon.png';

import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import { useAuth } from '../../contexts/AuthContext';
import apiCall from '../../utils/api';

const bacTechnoData = [
  {
    label: 'STMG',
    icon: stmgIcon,
    specialites: [
      { label: 'Droit et Economie', icon: accountingAuditIcon },
      { label: 'Mercatique', icon: advertisingIcon },
      { label: 'Gestion et Finance', icon: commerceManagementIcon },
      { label: 'Ressources humaines et communication', icon: childrenIcon },
      { label: "Systèmes d'information et de Gestion", icon: informatiqueIcon },
    ],
  },
  {
    label: 'ST2S',
    icon: st2sIcon,
    specialites: [
      { label: 'Chimie, biologie et physiopathologie humaines', icon: medecineIcon },
      { label: 'Sciences et techniques sanitaires et sociales', icon: santeIcon },
    ],
  },
  {
    label: 'STI2D',
    icon: sti2dIcon,
    specialites: [
      { label: '2I2D', icon: devDurableIcon },
      { label: 'Physique-Chimie et Mathématiques', icon: physchimIcon },
      { label: 'Territoires et technologie', icon: nuclearIcon },
    ],
  },
  {
    label: 'STL',
    icon: stlIcon,
    specialites: [
      { label: 'Physique-Chimie et Mathématiques', icon: physchimIcon },
      { label: 'Biochimie-Biologie-Biotechnologie', icon: bioecoIcon },
      { label: 'Sciences physiques et chimiques en laboratoire', icon: electronicsIcon },
    ],
  },
  {
    label: 'STD2A',
    icon: std2aIcon,
    specialites: [
      { label: 'Analyse et méthodes en design', icon: designIcon },
      { label: "Conception et création en design et métiers d'art", icon: artsIcon },
    ],
  },
  {
    label: 'STHR',
    icon: sthrIcon,
    specialites: [
      {
        label: 'Sciences et Technologies Culinaires et Services - ESAE',
        icon: culinaryIcon,
      },
      { label: 'Gestion des ressources et alimentation', icon: agricultureIcon },
      { label: 'Economie et gestion hôtelière', icon: commerceManagementIcon },
    ],
  },
  {
    label: 'S2TMD',
    icon: s2tmdIcon,
    specialites: [
      { label: 'Pratique musicale', icon: artsCultureIcon },
      { label: 'Culture et sciences chorégraphiques', icon: modeIcon },
      { label: 'Culture et sciences musicales', icon: audiovisuelIcon },
      { label: 'Pratique chorégraphiques', icon: childrenIcon },
    ],
  },
  {
    label: 'STAV',
    icon: stavIcon,
    specialites: [
      { label: 'Agronomie générale', icon: agricultureIcon },
      { label: 'Gestion des ressources naturelles', icon: devDurableIcon },
    ],
  },
];

const BacTechno = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const { checkAuth } = useAuth();

  const [expandedSeries, setExpandedSeries] = useState([]);
  const [selectedSpecialites, setSelectedSpecialites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const messageToType = 'Sélectionne les spécialités que tu as prises ou que tu veux prendre !';

  // Pointer state for visual feedback only
  const [submitActive, setSubmitActive] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= messageToType.length) {
        setText(messageToType.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, []);

  const toggleSerie = (serieLabel) => {
    setExpandedSeries((prevExpanded) => {
      if (prevExpanded.includes(serieLabel)) {
        return prevExpanded.filter((label) => label !== serieLabel);
      } else {
        return [...prevExpanded, serieLabel];
      }
    });
  };

  const handleSpecialiteSelection = (specialiteLabel) => {
    setSelectedSpecialites((prevSelected) => {
      if (prevSelected.includes(specialiteLabel)) {
        return prevSelected.filter((item) => item !== specialiteLabel);
      } else {
        return [...prevSelected, specialiteLabel];
      }
    });
  };

  // Enable submit if exactly 2 specialties are selected and typing is finished.
  const isSubmitEnabled = selectedSpecialites.length === 2 && !isTyping;

  // --- Option Button Handlers ---  
  // Use onClick for immediate selection.
  const handleSpecialiteClick = (label) => {
    handleSpecialiteSelection(label);
  };

  // For visual feedback only.
  const handleSpecialitePressStart = (label) => {
    // Visual feedback handled via CSS
  };

  const handleSpecialitePressEnd = () => {
    // Visual feedback handled via CSS
  };

  // --- Submit Button Handlers ---
  // Use onClick for immediate submit.
  const handleSubmitClick = () => {
    handleSubmit();
  };

  const handleSubmitPressStart = () => {
    if (!isSubmitEnabled) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!isSubmitEnabled) return;
    setSubmitActive(false);
  };

  const handleSubmit = async () => {
    if (!isSubmitEnabled) return;
    setIsLoading(true);
    try {
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          specialites: selectedSpecialites
        });
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.specialites = selectedSpecialites;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile/modify/grades');
      } else {
        localStorage.setItem('specialites', JSON.stringify(selectedSpecialites));
        navigate('/general-moyenne');
      }
    } catch (error) {
      console.error('Erreur en sauvegardant les spécialités en localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/type-bac');
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
    {!modifyProfile && <ProgressBar startPercent={55} endPercent={65} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>
        <OptionsScrollContainer  isMobile={isMobile}>
          <OptionsContainer>
            {bacTechnoData.map((serie) => {
              const isExpanded = expandedSeries.includes(serie.label);
              return (
                <div key={serie.label} style={{ marginTop: '1rem', width: '100%' }}>
                  <button
                    type="button"
                    onClick={() => toggleSerie(serie.label)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      background: 'none',
                      border: '1px solid #ccc',
                      padding: '0.75rem 1rem',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      marginBottom: '0.5rem',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
                      {serie.icon && (
                        <img
                          src={serie.icon}
                          alt={serie.label}
                          style={{ width: '24px', height: '24px' }}
                        />
                      )}
                      <span style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}>
                        {serie.label}
                      </span>
                    </div>
                    <FaChevronDown
                      style={{
                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease',
                        color: 'black',
                      }}
                    />
                  </button>
                  {isExpanded && (
                    <ButtonsWrapper
                      style={{
                        opacity: isTyping ? 0 : 1,
                        transition: 'opacity 0.3s ease',
                        pointerEvents: isTyping ? 'none' : 'auto',
                      }}
                    >
                      {serie.specialites?.map((spec) => (
                        <OptionButton
                          key={spec.label}
                          $isSelected={selectedSpecialites.includes(spec.label)}
                          type="button"
                          aria-pressed={selectedSpecialites.includes(spec.label)}
                          onClick={() => handleSpecialiteClick(spec.label)}
                          onMouseDown={() => handleSpecialitePressStart(spec.label)}
                          onMouseUp={handleSpecialitePressEnd}
                          onMouseLeave={handleSpecialitePressEnd}
                        // For mobile, you may let onClick do the work.
                        >
                          {spec.icon && (
                            <OptionIcon
                              src={spec.icon}
                              alt=""
                              $isSelected={selectedSpecialites.includes(spec.label)}
                            />
                          )}
                          {spec.label}
                        </OptionButton>
                      ))}
                    </ButtonsWrapper>
                  )}
                </div>
              );
            })}
          </OptionsContainer>
        </OptionsScrollContainer>
        <SubmitButton
          type="button"
          disabled={!isSubmitEnabled}
          style={{
            opacity: isSubmitEnabled ? 1 : 0.5,
            cursor: isSubmitEnabled ? 'pointer' : 'not-allowed',
          }}
          onClick={handleSubmitClick}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
        // Optionally, you can add onTouchStart/onTouchEnd similarly
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default BacTechno;
