import React, { useState, useEffect } from 'react';
import {
  PopupContainer,
  BackgroundBox,
  TopButton,
  TopButtonText,
  BottomButton,
  BottomButtonText,
  IconContainer,
} from './styles';
import BluePlayStore from '../../assets/blue_play_store.png';
import AppStore from '../../assets/logo_appstore.png';

const DownloadAppPopup = ({ onDismiss }) => {
  const [visible, setVisible] = useState(true);
  const [slideOut, setSlideOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pressedButton, setPressedButton] = useState(null);
  const [error, setError] = useState(null);
  
  // Enhanced device detection with additional logging
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/i.test(navigator.userAgent);
  
  // Log device info for debugging
  useEffect(() => {
    console.log('DownloadAppPopup initialized', {
      userAgent: navigator.userAgent,
      isIOS,
      isAndroid,
      hasDeferredPrompt: !!window.deferredPrompt
    });
  }, [isIOS, isAndroid]);
  
  const handleContinuePressStart = () => {
    setPressedButton('continue');
  };
  
  const handleContinuePressEnd = () => {
    setPressedButton(null);
    setError(null);
    
    try {
      setTimeout(() => {
        setSlideOut(true);
        setTimeout(() => {
          setVisible(false);
          if (onDismiss) onDismiss();
        }, 300);
      }, 150);
    } catch (err) {
      console.error('Error dismissing popup:', err);
      setError('Erreur lors de la fermeture');
      // Force dismiss after a delay even if error
      setTimeout(() => {
        setVisible(false);
        if (onDismiss) onDismiss();
      }, 500);
    }
  };
  
  const handleDownloadPressStart = () => {
    setPressedButton('download');
  };
  
  const handleDownloadPressEnd = () => {
    setPressedButton(null);
    setError(null);
    
    try {
      setTimeout(() => {
        setLoading(true);
        
        if (window.deferredPrompt) {
          console.log('PWA installation prompt available, showing prompt');
          // Try PWA installation if available
          window.deferredPrompt.prompt()
            .then(() => window.deferredPrompt.userChoice)
            .then((choiceResult) => {
              console.log('User installation choice:', choiceResult.outcome);
              if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
              } else {
                console.log('User dismissed the install prompt');
              }
              window.deferredPrompt = null;
              setLoading(false);
            })
            .catch(err => {
              console.error('Error during PWA install prompt:', err);
              setError('Erreur lors de l\'installation');
              setLoading(false);
              
              // Fall back to store redirect
              redirectToAppStore();
            });
        } else {
          console.log('No PWA prompt available, redirecting to store');
          redirectToAppStore();
        }
      }, 150);
    } catch (err) {
      console.error('Error in download handler:', err);
      setError('Erreur de téléchargement');
      setLoading(false);
    }
  };
  
  // Separate function for store redirection
  const redirectToAppStore = () => {
    try {
      // Redirect to the appropriate store
      if (isAndroid) {
        window.location.href = 'https://play.google.com/store/search?q=meoria&c=apps&hl=fr';
      } else if (isIOS) {
        window.location.href = 'https://apps.apple.com/fr/app/meoria-orientation-avec-lia/id6737978700?platform=iphone';
      } else {
        // Fallback for other devices
        console.log('Unknown device type for app store redirection');
        setError('Type d\'appareil non reconnu');
      }
      
      // Stop loading after a short delay
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      console.error('Error redirecting to app store:', err);
      setError('Erreur de redirection');
      setLoading(false);
    }
  };
  
  if (!visible) return null;
  
  return (
    <PopupContainer $slideOut={slideOut}>
      <BackgroundBox>
        {error && (
          <div style={{color: 'red', marginBottom: '10px', textAlign: 'center'}}>
            {error}
          </div>
        )}
        <TopButton 
          onMouseDown={handleContinuePressStart}
          onMouseUp={handleContinuePressEnd}
          onMouseLeave={() => setPressedButton(null)}
          onTouchStart={handleContinuePressStart}
          onTouchEnd={handleContinuePressEnd}
          active={pressedButton === 'continue'}
        >
          <TopButtonText>Continuer sur navigateur</TopButtonText>
        </TopButton>
        <BottomButton 
          onMouseDown={handleDownloadPressStart}
          onMouseUp={handleDownloadPressEnd}
          onMouseLeave={() => setPressedButton(null)}
          onTouchStart={handleDownloadPressStart}
          onTouchEnd={handleDownloadPressEnd}
          active={pressedButton === 'download'}
          disabled={loading}
        >
          <IconContainer>
            <img 
              src={isIOS ? AppStore : BluePlayStore} 
              alt={isIOS ? "App Store Icon" : "Play Store Icon"} 
            />
          </IconContainer>
          <BottomButtonText>
            {loading ? "Chargement..." : "Télécharger Meoria"}
          </BottomButtonText>
        </BottomButton>
      </BackgroundBox>
    </PopupContainer>
  );
};

export default DownloadAppPopup;