import styled from 'styled-components';

export const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background: transparent;
  padding: 0;
  margin-top: 8px;
  overflow-y: auto;
  max-height: 35vh; /* so it scrolls within the side menu */


  overflow-y: scroll
 scrollbar-width: thin;
  scrollbar-color:rgb(241, 241, 241) #FAFAFA;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color:f1f1f1;
  }

  @media (max-width: 800px) {
    display: none; /* hide entire ChatHistory on small screens */
  }
`;


/* Updated to match .rectangleDiv snippet */
export const HistoryItem = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  min-height: 60px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  }
`;

/* Date text style (unchanged) */
export const DateText = styled.span`
  position: absolute;
  top: 6px; /* slightly adjusted for a 51px height container */
  right: 12px;
  color: #8d8d8d;
  font-family: Gabarito;
  font-size: 11px;
  font-weight: 400;
`;

/* Input for renaming discussions (keep original logic, slightly adjusted if needed) */
export const RenameInput = styled.input`
  font-family: Gabarito;
  font-size: 13px;
  padding: 3px;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 140px;      /* Match the DiscussionName width */
  margin-left: 16px; /* So it lines up with DiscussionName */
  
  &:focus {
    border-color: #FFC1C1;
    outline: none;
  }
`;


/* Updated to match .compareCyTech snippet */
export const DiscussionName = styled.span`
  width: 190px;
  font-size: 13px;
  font-family: Gabarito;
  color: #8d8d8d;
  text-align: left;
  margin-left: 16px;
  display: inline-block;
`;


/* Updated to match .renameIcon5 snippet */
export const IconButton = styled.button`
  /* Remove width: 100% and max-width: 100% */
  background: none;
  border: none;
  padding: 0;
  margin-top: 10px;
  margin-right: 4px; /* optional: add spacing between buttons */
  cursor: pointer;
  color: #FFC1C1;
  
  /* Ensure proper sizing and alignment for the icons */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;

  img {
    width: 14px;
    height: 14px;
    object-fit: cover;
  }
`;

