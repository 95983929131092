import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  BottomMenuContainer, 
  MenuButton, 
  MenuText, 
  IconContainer, 
  SelectedBackground 
} from './styles';
import useDeviceType from '../useDeviceType';

// Import PNG icons from assets
import comparateurIcon from '../../assets/comparer.png';
import loupeIcon from '../../assets/loupe_menu.png';
import chatIcon from '../../assets/bavardage.png';
import profileIcon from '../../assets/profil.png';
import lettresIcon from '../../assets/lettre.png'; // Added import for lettres icon

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDeviceType();
  const isIOS = isMobile && /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;
  const isRankingActive =
    location.pathname === '/classement' || location.pathname === '/quiz';

  if (!isMobile) return null;

  return (
    <BottomMenuContainer isIOS={isIOS}>
      {/* COMPARATEUR BUTTON */}
      <MenuButton
        id="comparateur-button"
        onClick={() => handleNavigation('/comparateur')}
        active={isActive('/comparateur')}
        isIOS={isIOS}
      >
        <IconContainer>
          <div className="icon-wrapper">
            {isActive('/comparateur') && <SelectedBackground />}
            <img
              src={comparateurIcon}
              alt="Comparateur"
              width="30"
              height="30"
              className="icon"
            />
          </div>
          <MenuText active={isActive('/comparateur')}>Compare</MenuText>
        </IconContainer>
      </MenuButton>

      {/* LETTRES BUTTON - New button */}
      <MenuButton
        id="lettres-button"
        onClick={() => handleNavigation('/lettres')}
        active={isActive('/lettres')}
        isIOS={isIOS}
      >
        <IconContainer>
          <div className="icon-wrapper">
            {isActive('/lettres') && <SelectedBackground />}
            <img
              src={lettresIcon}
              alt="Lettres"
              width="30"
              height="30"
              className="icon"
            />
          </div>
          <MenuText active={isActive('/lettres')}>Lettres</MenuText>
        </IconContainer>
      </MenuButton>

      {/* LOUPE BUTTON (Explore) - Now in center */}
      <MenuButton
        id="loupe-button"
        onClick={() => handleNavigation('/classement')}
        active={isRankingActive}
        isIOS={isIOS}
      >
        <IconContainer>
          <div className="icon-wrapper">
            {isRankingActive && <SelectedBackground />}
            <img
              src={loupeIcon}
              alt="Loupe"
              width="30"
              height="30"
              className="icon"
            />
          </div>
          <MenuText active={isRankingActive}>Explore</MenuText>
        </IconContainer>
      </MenuButton>

      {/* CHAT BUTTON */}
      <MenuButton
        id="chat-button"
        onClick={() => handleNavigation('/chat')}
        active={isActive('/chat')}
        isIOS={isIOS}
      >
        <IconContainer>
          <div className="icon-wrapper">
            {isActive('/chat') && <SelectedBackground />}
            <img
              src={chatIcon}
              alt="Chat"
              width="30"
              height="30"
              className="icon"
            />
          </div>
          <MenuText active={isActive('/chat')}>Chat</MenuText>
        </IconContainer>
      </MenuButton>

      {/* PROFILE BUTTON */}
      <MenuButton
        id="profile-button"
        onClick={() => handleNavigation('/profile')}
        active={isActive('/profile')}
        isIOS={isIOS}
      >
        <IconContainer>
          <div className="icon-wrapper">
            {isActive('/profile') && <SelectedBackground />}
            <img
              src={profileIcon}
              alt="Profile"
              width="30"
              height="30"
              className="icon"
            />
          </div>
          <MenuText active={isActive('/profile')}>Profil</MenuText>
        </IconContainer>
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;