// styles.js
import styled, { keyframes} from 'styled-components';
import theme from '../../theme';

export const HorizontalScrollBox = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin: 5px 10px;
  height:30px;
  width: 93%;

  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;



// Base button component needs to be defined first
const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

/* ─── BUTTON TEXT ───────────────────────────────────────────── */
export const ButtonText = styled.span`
  font-size: 20px;
  font-family: 'Gabarito';
  font-weight: 600;
  text-align: center;
  height: 20px;
  
  /* Change text color based on disabled prop */
  color: ${props => (props.disabled ? '#bab8b8' : '#fff')};
`;


export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-width: calc(var(--wh, 1wh) * 100);
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2000;
  font-family: Gabarito;
  

`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${theme.spacing.large} 0;
  gap: 0.5rem;
`;


export const LogoImage = styled.img`
  width: 25%;
  // height: 25%;
  object-fit: contain;
  margin-left: 0%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;
  margin-top: 5%;
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;


export const ChatBubbleContainer = styled.div`
  position: relative;
  top: calc(var(--vh, 1vh) * -15);
  left: 28%;
  transform: none;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const ChatText = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: Gabarito;
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: 70%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #d9d7d7;
  
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #d9d7d7;
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;

const cursorBlink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const InputContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  padding: 0 ${theme.spacing.large};

  /* Shadow effect */
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 40px;
    background: radial-gradient(ellipse at top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 70%);
    pointer-events: none;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: visible;
`;

export const TextMeasure = styled.span`
  visibility: hidden;
  position: absolute;
  white-space: pre;
  font-size: ${theme.fontSizes.large};
  font-family: ${theme.fonts.main};
  pointer-events: none;
`;


export const StyledInput = styled.input`
  width: 100%;
  padding: ${theme.spacing.small} 0;
  border: none;
  background: transparent;
  font-size: ${theme.fontSizes.large};
  font-family: ${theme.fonts.main};
  color: ${theme.colors.noir};
  outline: none;
  caret-color: transparent;

  &::placeholder {
    opacity: 0;
  }

  &::selection {
    background: transparent;
  }
`;

export const Cursor = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 32px;
  background-color: ${theme.colors.gris};
  animation: ${cursorBlink} 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
  transition: left 0.1s ease-out;
`;

export const ReturnButton = styled.button`
  position: fixed;
  top: 1.15rem;
  left: ${theme.spacing.large};
  background: transparent;
  border: none;
  cursor: pointer;
  color: #BAB8B8;
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);

    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

// Updated SubmitButton styled component
export const SubmitButton = styled(BaseButton)`
  width: 90%;
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 5);
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  border-radius: 15px;
  transition: all 0.15s ease; /* fast transition */

  height: 53px;
  background-color: ${props => (props.disabled ? '#efecec' : '#ff2768')};
  /* Remove shadow if the button is disabled OR pressed (active) */
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 4px 0px #d42359'};

  display: flex;
  align-items: center;
  justify-content: center;
`;


export const OptionsScrollContainer = styled.div`
  position: absolute;
  top: ${props => props.isMobile ? "28%" : "35%"};
  left: 0;
  width: 90%;
  left: 5%;
  height: calc(var(--vh, 1vh) * 50);
  overflow-y: scroll;
  scrollbar-gutter: stable;
  padding-bottom: ${theme.spacing.large};


  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

export const OptionsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.isMobile ? theme.spacing.large : theme.spacing.large};
`;

export const OptionIcon = styled.img`
  height: 60%;
  object-fit: contain;
  transition: transform 0.2s ease;

  ${props => (props.$isSelected || props.$isDoubleClicked) && `
    transform: scale(1.05);
  `}
`;

export const OptionButton = styled.button`
  width: 100%;
  height: 60px;
  background: #FFF;
  border-radius: 0.9375rem;
  border: 2px solid rgba(217, 215, 215, 1);
  box-shadow: 0px 2px 0px 0px rgba(217, 215, 215, 1);
  color: ${theme.colors.noir};
  font-family: Gabarito;
  font-size: ${theme.fontSizes.large};
  font-weight: ${theme.fontWeights.medium};
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  align-items: center;
  gap: ${theme.spacing.medium};
  padding: 0 ${theme.spacing.large};
  position: relative;

  & > *:not(img) {
    flex: 1;
    text-align: center;
    padding-right: 15%; /* Offset for the icon width */
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  /* Default hover and active states */
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Selected state (blue) */
  ${props => props.$isSelected && !props.$isDoubleClicked && `
    border: 2px solid #38B6FF;
    background: #DCF3FF;
    color: #38B6FF;
    box-shadow: 0px 2px 2px 0px #38B6FF;

    &:hover {
      transform: none;
      box-shadow: 0 2px 0px #38B6FF;
    }
  `}

  /* When pressed via pointer events, remove shadow and push button down */
  ${props => props.active && `
    transform: translateY(4px);
    box-shadow: none;
  `}

  @media (max-height: 550px) {
    height: calc(var(--vh, 1vh) * 10);
    min-height: 40px;
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
  }

  /* Disabled state */
  ${props => props.$disabled && `
    background: ${theme.colors.gris_clair};
    color: ${theme.colors.gris};
    opacity: 0.6;
  `}
`;



export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--vh, 1vh) * 3);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-height: 550px) {
    gap: calc(var(--vh, 1vh) * 2);
  }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: ${props => props.isLoading ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
  ${props => props.isLoading && 'opacity: 1;'}
`;
