
import arts_spectacle_icon from '../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../assets/audiovisuel_icon.png';
import dessin_icon from '../../assets/dessin_icon.png';
import design_specific_icon from '../../assets/design_icon.png';
import culture_patimoine_icon from '../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../assets/mode_icon.png';
import luxe_specific_icon from '../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../assets/artisanat_icon.png';
import sport_management_icon from '../../assets/sport_icon.png';
import teaching_specific_icon from '../../assets/teacher_icon.png';
import health_medical_icon from '../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../assets/children_icon.png';
import esport_specific_icon from '../../assets/esport_icon.png';
import tourisme_specific_icon from '../../assets/tourisme_icon.png';
import droit_justice_icon from '../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../assets/fonction_publique_icon.png';
import humanitaire_specific_icon from '../../assets/help_icon.png';
import journalisme_specific_icon from '../../assets/journalisme_icon.png';
import communication_specific_icon from '../../assets/media_icon.png';
import social_specific_icon from '../../assets/help_icon.png';
import security_army_icon from '../../assets/armée_icon.png';
import entrepreneurship_icon from '../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../assets/psychology_icon.png';
import langues_translation_icon from '../../assets/llcer_icon.png';
import agroalimentaire_icon from '../../assets/agroalimentaire_icon.png';
import commerce_management_icon from '../../assets/commerce_management_icon.png';
import hospitality_icon from '../../assets/sante_sport_icon.png';
import transport_icon from '../../assets/transport_icon.png';
import math_physics_icon from '../../assets/physics_icon.png';
import finance_specific_icon from '../../assets/ses_icon.png';
import banque_assurance_icon from '../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../assets/accounting_audit_icon.png';
import informatics_icon from '../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../assets/nsi_icon.png';
import nuclear_specific_icon from '../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../assets/sciences_icon.png';
import energy_specific_icon from '../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../assets/industry_quality_icon.png';
import environment_icon from '../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../assets/wine_industry_icon.png';
import architecture_icon from '../../assets/architecture_icon.png';
import automobile_icon from '../../assets/automobile_icon.png';
import btp_icon from '../../assets/btp_icon.png';
import electronics_icon from '../../assets/electronics_icon.png';
import aeronautics_icon from '../../assets/aeronautics_icon.png';
import resources_human_icon from '../../assets/resources_human_icon.png';
import advertising_icon from '../../assets/advertising_icon.png';
import real_estate_icon from '../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../assets/culinary_specific_icon.png';
// import energyIcon from '../../assets/energy_specific_icon.png';
import santeIcon from '../../assets/sante_sport_icon.png';
import agricultureIcon from '../../assets/agriculture_specific_icon.png';
// import btpIcon from '../../assets/btp_icon.png';
// import architectureIcon from '../../assets/architecture_icon.png';
import childrenIcon from '../../assets/children_icon.png';
// import artisanatIcon from '../../assets/artisanat_icon.png';
import artsCultureIcon from '../../assets/arts_culture_icon.png';
// import artsIcon from '../../assets/arts_icon.png';
import advertisingIcon from '../../assets/advertising_icon.png';
// import aeronauticsIcon from '../../assets/aeronautics_icon.png';
import electronicsIcon from '../../assets/electronics_icon.png';
import bioecoIcon from '../../assets/bioeco_icon.png';
import culinaryIcon from '../../assets/culinary_specific_icon.png';
// import transportIcon from '../../assets/transport_icon.png';
// import marineIcon from '../../assets/marine_specific_icon.png';
import devDurableIcon from '../../assets/dev_durable_icon.png';
import physchimIcon from '../../assets/physchim_icon.png';
// import industryQualityIcon from '../../assets/industry_quality_icon.png';
// import icon3d from '../../assets/3d_icon.png';
import commerceManagementIcon from '../../assets/commerce_management_icon.png';
// import cybersecurityIcon from '../../assets/cybersecurity_specific_icon.png';
import modeIcon from '../../assets/mode_icon.png';
import audiovisuelIcon from '../../assets/audiovisuel_icon.png';
import informatiqueIcon from '../../assets/informatique_icon.png';
import medecineIcon from '../../assets/medecine_icon.png';
import nuclearIcon from '../../assets/nuclear_specific_icon.png';
// import localisationIcon from '../../assets/localisation_icon.png';
import designIcon from '../../assets/design_icon.png';
import accountingAuditIcon from '../../assets/accounting_audit_icon.png';


import artsIcon from '../../assets/arts_culture_icon.png';
import eppcsIcon from '../../assets/sport_icon.png';
import hggspIcon from '../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../assets/hlp_icon.png';
import llcerIcon from '../../assets/llcer_icon.png';
import lcaIcon from '../../assets/lca_icon.png';
import mathIcon from '../../assets/math_icon.png';
import nsiIcon from '../../assets/informatique_icon.png';
import physChimIcon from '../../assets/physics_icon.png';
import svtIcon from '../../assets/svt_icon.png';
import siIcon from '../../assets/engineer_icon.png';
import sesIcon from '../../assets/ses_icon.png';
import bioEcoIcon from '../../assets/bioeco_icon.png';



export const domainIconMapRaw = {
  'Arts du Spectacle': arts_spectacle_icon,
  'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
  'Communication visuelle - Dessin - Animation 3D': dessin_icon,
  'Design': design_specific_icon,
  "Marché de l'art - Culture - Patrimoine": culture_patimoine_icon,
  'Mode - Textile': mode_textile_icon,
  'Luxe': luxe_specific_icon,
  'Artisanat': artisanat_specific_icon,

  'Sport - Management sportif': sport_management_icon,
  'Enseignement': teaching_specific_icon,
  'Santé (Médical-Paramédical)': health_medical_icon,
  'Travailler avec des enfants': working_with_children_icon,
  'ESport - Gaming': esport_specific_icon,
  'Tourisme - Loisirs': tourisme_specific_icon,

  'Droit - Justice - Sciences Politiques': droit_justice_icon,
  'Fonction publique': fonction_publique_specific_icon,
  'Humanitaire': humanitaire_specific_icon,
  'Journalisme': journalisme_specific_icon,
  'Communication': communication_specific_icon,
  'Social': social_specific_icon,
  'Sécurité - Armée - Défense': security_army_icon,
  "Entrepreneuriat - Création d'entreprise": entrepreneurship_icon,

  "Edition - Métiers du livre": book_industry_icon,
  'Psychologie - Sociologie': psychology_sociology_icon,
  'Langues étrangères - Traduction': langues_translation_icon,
  'Commerce - Distribution - Management': commerce_management_icon,
  'Hôtellerie - Restauration': hospitality_icon,
  'Transport - Logistique': transport_icon,

  'Mathématiques - Sciences Physiques': math_physics_icon,
  'Finance': finance_specific_icon,
  'Banque - Assurance': banque_assurance_icon,
  'Comptabilité - Gestion - Audit': accounting_audit_icon,
  'Informatique - Numérique': informatics_icon,
  'Cybersécurité': cybersecurity_specific_icon,
  'Le nucléaire': nuclear_specific_icon,
  'Internet - Web - Multimédia': internet_web_icon,
  'Jeux vidéo': gaming_specific_icon,
  'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,

  'Chimie - Biologie': chemistry_biology_icon,
  'Énergie': energy_specific_icon,
  'Industrie - Qualité': industry_quality_icon,
  'Environnement - Développement durable': environment_icon,
  'Métiers animaliers': animals_specific_icon,
  'Agriculture': agriculture_specific_icon,
  'Agroalimentaire': agroalimentaire_icon,
  'Métiers de la mer': marine_specific_icon,
  'Métiers du vin': wine_industry_icon,

  'Architecture - Urbanisme - Paysagisme': architecture_icon,
  'Automobile - Moto': automobile_icon,
  'Bâtiment et travaux publics (BTP)': btp_icon,
  'Électronique': electronics_icon,
  'Aéronautique et aérospatial': aeronautics_icon,

  'Ressources humaines': resources_human_icon,
  'Publicité - Marketing': advertising_icon,
  'Immobilier': real_estate_icon,
  'Métiers de bouche': culinary_specific_icon,
};


export const speIconMap = [
    { value: 'arts', label: 'Arts', icon: artsIcon },
    { value: 'eppcs', label: 'Éducation physique, pratiques et cultures sportives', icon: eppcsIcon },
    { value: 'hggsp', label: 'Histoire-géographie, géopolitique et sciences politiques', icon: hggspIcon },
    { value: 'hlp', label: 'Humanités, littérature et philosophie', icon: hlpIcon },
    { value: 'llcer', label: 'Langues, littératures et cultures étrangères et régionales', icon: llcerIcon },
    { value: 'lca', label: "Littérature, langues et cultures de l'antiquité", icon: lcaIcon },
    { value: 'math', label: 'Mathématiques', icon: mathIcon },
    { value: 'nsi', label: 'Numérique et sciences informatiques', icon: nsiIcon },
    { value: 'physchim', label: 'Physique-Chimie', icon: physChimIcon },
    { value: 'svt', label: 'Sciences de la vie et de la Terre', icon: svtIcon },
    { value: 'si', label: "Sciences de l'ingénieur", icon: siIcon },
    { value: 'ses', label: 'Sciences économiques et sociales', icon: sesIcon },
    { value: 'bioeco', label: 'Biologie-Ecologie', icon: bioEcoIcon },
    { value: 'Droit et Economie', label: 'Droit et Economie', icon: accountingAuditIcon },
    { value: 'Mercatique', label: 'Mercatique', icon: advertisingIcon },
    { value: 'Gestion et Finance', label: 'Gestion et Finance', icon: commerceManagementIcon },
    { value: 'Ressources humaines et communication', label: 'Ressources humaines et communication', icon: childrenIcon },
    
    { value: "Systèmes d'information et de Gestion", label: "Systèmes d'information et de Gestion", icon: informatiqueIcon },
 
    { value: 'Chimie, biologie et physiopathologie humaines', label: 'Chimie, biologie et physiopathologie humaines', icon: medecineIcon },
    { value: 'Sciences et techniques sanitaires et sociales', label: 'Sciences et techniques sanitaires et sociales', icon: santeIcon },
 
    { value: '2I2D', label: '2I2D', icon: devDurableIcon },
    { value: 'Physique-Chimie et Mathématiques', label: 'Physique-Chimie et Mathématiques', icon: physchimIcon },
    { value: 'Territoires et technologie', label: 'Territoires et technologie', icon: nuclearIcon },
  
    { value: 'Biochimie-Biologie-Biotechnologie', label: 'Biochimie-Biologie-Biotechnologie', icon: bioecoIcon },
    { value: 'Sciences physiques et chimiques en laboratoire', label: 'Sciences physiques et chimiques en laboratoire', icon: electronicsIcon },
 
    { value: 'Analyse et méthodes en design', label: 'Analyse et méthodes en design', icon: designIcon },
    { value: "Conception et création en design et métiers d'art", label: "Conception et création en design et métiers d'art", icon: artsIcon },
 
    { value: 'Sciences et Technologies Culinaires et Services - ESAE', label: 'Sciences et Technologies Culinaires et Services - ESAE', icon: culinaryIcon},
    { value: 'Gestion des ressources et alimentation', label: 'Gestion des ressources et alimentation', icon: agricultureIcon },
    { value: 'Economie et gestion hôtelière', label: 'Economie et gestion hôtelière', icon: commerceManagementIcon },
 
    { value: 'Pratique musicale', label: 'Pratique musicale', icon: artsCultureIcon },
    { value: 'Culture et sciences chorégraphiques', label: 'Culture et sciences chorégraphiques', icon: modeIcon },
    { value: 'Culture et sciences musicales', label: 'Culture et sciences musicales', icon: audiovisuelIcon },
    { value: 'Pratique chorégraphiques', label: 'Pratique chorégraphiques', icon: childrenIcon },
 
    // À remplir selon ce que tu veux pour STAV
    { value: 'Agronomie générale', label: 'Agronomie générale', icon: agricultureIcon },
    { value: 'Gestion des ressources naturelles', label: 'Gestion des ressources naturelles', icon: devDurableIcon },
  ];





