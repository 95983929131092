// Import all icons
import dont_know_icon from '../../assets/dont_know_icon.png';
import englishIcon from '../../assets/english_icon.png';
import spectacleIcon from '../../assets/spectacle_icon.png';
import audiovisuelIcon from '../../assets/audiovisuel_icon.png';
import dessinIcon from '../../assets/dessin_icon.png';
import designIcon from '../../assets/design_icon.png';
import artsCultureIcon from '../../assets/arts_culture_icon.png';
import modeIcon from '../../assets/mode_icon.png';
import luxeIcon from '../../assets/luxe_icon.png';
import artisanatIcon from '../../assets/artisanat_icon.png';
import sportIcon from '../../assets/sport_icon.png';
import teacherIcon from '../../assets/teacher_icon.png';
import santeSportIcon from '../../assets/sante_sport_icon.png';
import childrenIcon from '../../assets/children_icon.png';
import esportIcon from '../../assets/esport_icon.png';
import tourismeIcon from '../../assets/tourisme_icon.png';
import droitPolitiqueIcon from '../../assets/droit_politique_icon.png';
import fonctionPubliqueIcon from '../../assets/fonction_publique_icon.png';
import helpIcon from '../../assets/help_icon.png';
import journalismeIcon from '../../assets/journalisme_icon.png';
import mediaIcon from '../../assets/media_icon.png';
import armeeIcon from '../../assets/armée_icon.png';
import entrepreneurshipIcon from '../../assets/entrepreneurship_icon.png';
import bookIndustryIcon from '../../assets/book_industry_icon.png';
import psychologyIcon from '../../assets/psychology_icon.png';
import llcerIcon from '../../assets/llcer_icon.png';
import agroalimentaireIcon from '../../assets/agroalimentaire_icon.png';
import commerceManagementIcon from '../../assets/commerce_management_icon.png';
import transportIcon from '../../assets/transport_icon.png';
import physicsIcon from '../../assets/physics_icon.png';
import sesIcon from '../../assets/ses_icon.png';
import banqueAssuranceIcon from '../../assets/banque_assurance_icon.png';
import accountingAuditIcon from '../../assets/accounting_audit_icon.png';
import nsiIcon from '../../assets/nsi_icon.png';
import nuclearSpecificIcon from '../../assets/nuclear_specific_icon.png';
import internetWebIcon from '../../assets/internet_web_icon.png';
import gamingSpecificIcon from '../../assets/gaming_specific_icon.png';
import blockchainMetaverseIcon from '../../assets/blockchain_metaverse_icon.png';
import sciencesIcon from '../../assets/sciences_icon.png';
import energySpecificIcon from '../../assets/energy_specific_icon.png';
import industryQualityIcon from '../../assets/industry_quality_icon.png';
import devDurableIcon from '../../assets/dev_durable_icon.png';
import animalsSpecificIcon from '../../assets/animals_specific_icon.png';
import agricultureSpecificIcon from '../../assets/agriculture_specific_icon.png';
import marineSpecificIcon from '../../assets/marine_specific_icon.png';
import wineIndustryIcon from '../../assets/wine_industry_icon.png';
import architectureIcon from '../../assets/architecture_icon.png';
import automobileIcon from '../../assets/automobile_icon.png';
import btpIcon from '../../assets/btp_icon.png';
import electronicsIcon from '../../assets/electronics_icon.png';
import aeronauticsIcon from '../../assets/aeronautics_icon.png';
import resourcesHumanIcon from '../../assets/resources_human_icon.png';
import advertisingIcon from '../../assets/advertising_icon.png';
import realEstateIcon from '../../assets/real_estate_icon.png';
import culinarySpecificIcon from '../../assets/culinary_specific_icon.png';
import medecineIcon from '../../assets/medecine_icon.png';
import bioecoIcon from '../../assets/bioeco_icon.png';
import informatiqueIcon from '../../assets/informatique_icon.png';
import physchimIcon from '../../assets/physchim_icon.png';
import hggspIcon from '../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../assets/hlp_icon.png';
import lcaIcon from '../../assets/lca_icon.png';
import mathIcon from '../../assets/math_icon.png';
import svtIcon from '../../assets/svt_icon.png';
import siIcon from '../../assets/engineer_icon.png';

// Create icon mapping object
const iconMapping = {
  // Map filename to imported icon
  'dont_know_icon.png': dont_know_icon,
  'english_icon.png': englishIcon,
  'spectacle_icon.png': spectacleIcon,
  'audiovisuel_icon.png': audiovisuelIcon,
  'dessin_icon.png': dessinIcon,
  'design_icon.png': designIcon,
  'arts_culture_icon.png': artsCultureIcon,
  'mode_icon.png': modeIcon,
  'luxe_icon.png': luxeIcon,
  'artisanat_icon.png': artisanatIcon,
  'sport_icon.png': sportIcon,
  'teacher_icon.png': teacherIcon,
  'sante_sport_icon.png': santeSportIcon,
  'children_icon.png': childrenIcon,
  'esport_icon.png': esportIcon,
  'tourisme_icon.png': tourismeIcon,
  'droit_politique_icon.png': droitPolitiqueIcon,
  'fonction_publique_icon.png': fonctionPubliqueIcon,
  'help_icon.png': helpIcon,
  'journalisme_icon.png': journalismeIcon,
  'media_icon.png': mediaIcon,
  'armée_icon.png': armeeIcon,
  'entrepreneurship_icon.png': entrepreneurshipIcon,
  'book_industry_icon.png': bookIndustryIcon,
  'psychology_icon.png': psychologyIcon,
  'llcer_icon.png': llcerIcon,
  'agroalimentaire_icon.png': agroalimentaireIcon,
  'commerce_management_icon.png': commerceManagementIcon,
  'transport_icon.png': transportIcon,
  'physics_icon.png': physicsIcon,
  'ses_icon.png': sesIcon,
  'banque_assurance_icon.png': banqueAssuranceIcon,
  'accounting_audit_icon.png': accountingAuditIcon,
  'nsi_icon.png': nsiIcon,
  'nuclear_specific_icon.png': nuclearSpecificIcon,
  'internet_web_icon.png': internetWebIcon,
  'gaming_specific_icon.png': gamingSpecificIcon,
  'blockchain_metaverse_icon.png': blockchainMetaverseIcon,
  'sciences_icon.png': sciencesIcon,
  'energy_specific_icon.png': energySpecificIcon,
  'industry_quality_icon.png': industryQualityIcon,
  'dev_durable_icon.png': devDurableIcon,
  'animals_specific_icon.png': animalsSpecificIcon,
  'agriculture_specific_icon.png': agricultureSpecificIcon,
  'marine_specific_icon.png': marineSpecificIcon,
  'wine_industry_icon.png': wineIndustryIcon,
  'architecture_icon.png': architectureIcon,
  'automobile_icon.png': automobileIcon,
  'btp_icon.png': btpIcon,
  'electronics_icon.png': electronicsIcon,
  'aeronautics_icon.png': aeronauticsIcon,
  'resources_human_icon.png': resourcesHumanIcon,
  'advertising_icon.png': advertisingIcon,
  'real_estate_icon.png': realEstateIcon,
  'culinary_specific_icon.png': culinarySpecificIcon,
  'medecine_icon.png': medecineIcon,
  'bioeco_icon.png': bioecoIcon,
  'informatique_icon.png': informatiqueIcon,
  'physchim_icon.png': physchimIcon,
  'hggsp_icon.jpeg': hggspIcon,
  'hlp_icon.png': hlpIcon,
  'lca_icon.png': lcaIcon,
  'math_icon.png': mathIcon,
  'svt_icon.png': svtIcon,
  'engineer_icon.png': siIcon,
};

// Default icon to use if a mapping is not found
export const getIcon = (iconFilename) => {
  return iconMapping[iconFilename] || dont_know_icon;
};

export default iconMapping;