import styled, { css, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1); }
  100% { transform: scale(1); }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
  50% { box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5); }
  100% { box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.3); }
`;

export const BottomMenuContainer = styled.div`
  position: fixed;
  z-index: 1600;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 110%;
  height: 110px;
  flex-shrink: 0;
  margin-bottom: -15px;
  background: #FFF5F8;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0px;
  padding: 0 10px; /* Reduced padding to fit 5 buttons */
  border: 1px solid #BCBBBB;
`;

export const MenuText = styled.span`
  display: block;
  font-size: 12px; /* Slightly smaller font to fit 5 buttons */
  font-family: 'Gabarito', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  color: ${props => props.active ? '#38B6FF' : '#BCBBBB'};
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .icon-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Slightly smaller to fit 5 buttons */
    height: 40px; /* Slightly smaller to fit 5 buttons */
  }
  
  .icon {
    position: relative;
    z-index: 2;
  }
`;

export const SelectedBackground = styled.div`
  width: 40px; /* Adjusted to match icon-wrapper */
  height: 40px; /* Adjusted to match icon-wrapper */
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #38B6FF;
  background: #DCF3FF;
  position: absolute;
  z-index: 1;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;
  position: relative;
  width: 20%; /* Changed from 25% to 20% for 5 buttons */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${props => props.isIOS ? '0px' : '10px'};
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: translateY(0); /* Prevent vertical movement on click */
  }

  svg {
    width: 28px; /* Slightly smaller to fit 5 buttons */
    height: 28px; /* Slightly smaller to fit 5 buttons */
    flex-shrink: 0;
    aspect-ratio: 1/1;
  }

  ${props => props.hasAnimation && css`
    animation: ${pulseAnimation} 2s infinite;
    
    &::before {
      animation: ${glowAnimation} 2s infinite;
    }
  `}
`;