// src/contexts/NotificationContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import NotificationService from '../utils/NotificationService';
import PushSubscriptionManager from '../utils/PushSubscriptionManager';

// Create context
const NotificationContext = createContext({
  permissionState: 'default',
  isSubscribed: false,
  shouldShowPrompt: false,
  dismissPrompt: () => {},
  updatePermissionState: () => {},
  requestPermission: async () => false,
  subscribeToNotifications: async () => false,
  unsubscribeFromNotifications: async () => false,
  sendTestNotification: async () => false
});

// Hook to use the notification context
export const useNotification = () => useContext(NotificationContext);

// Constants for timing of permission prompts
const PROMPT_DELAY_MIN = 20 * 1000; // 20 seconds after first visit
const PROMPT_DELAY_PAGES = 2; // Number of in-app page navigations before showing prompt
const PROMPT_DEFER_DAYS = 3; // Days to wait after "maybe later" click

// Provider component
export const NotificationProvider = ({ children }) => {
  // State variables
  const [permissionState, setPermissionState] = useState('default');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [shouldShowPrompt, setShouldShowPrompt] = useState(false);
  const [pageViewCount, setPageViewCount] = useState(0);
  const [timeInApp, setTimeInApp] = useState(0);

  // Initialize notification state
  useEffect(() => {
    const initNotifications = async () => {
      try {
        NotificationService.init();
        await PushSubscriptionManager.init();
        
        // Get current permission state
        const currentPermission = Notification.permission;
        setPermissionState(currentPermission);
        
        // Check if subscribed to push
        const subscribed = await PushSubscriptionManager.isSubscribed();
        setIsSubscribed(subscribed);
        
        // If we have storage indicating deferred status, respect that
        const deferred = localStorage.getItem('notificationPromptDeferred');
        const lastPrompted = deferred ? parseInt(deferred, 10) : null;
        
        if (lastPrompted) {
          const daysElapsed = (Date.now() - lastPrompted) / (1000 * 60 * 60 * 24);
          if (daysElapsed < PROMPT_DEFER_DAYS) {
            // Not enough days have passed since user said "maybe later"
            return;
          }
        }
        
        // If permission already decided (granted or denied), don't bother with prompt
        if (currentPermission !== 'default') {
          return;
        }
        
        // Start timing for initial prompt
        const startTime = Date.now();
        const intervalId = setInterval(() => {
          const elapsed = Date.now() - startTime;
          setTimeInApp(elapsed);
        }, 1000);
        
        return () => clearInterval(intervalId);
      } catch (error) {
        console.error('Error initializing notifications:', error);
      }
    };
    
    initNotifications();
  }, []);

  // Effect to determine if we should show permission prompt
  useEffect(() => {
    // Only show prompt if:
    // 1. Permission is not decided
    // 2. User has been in app for a minimum time
    // 3. User has viewed enough pages
    // 4. We're not already subscribed
    if (
      permissionState === 'default' &&
      timeInApp >= PROMPT_DELAY_MIN &&
      pageViewCount >= PROMPT_DELAY_PAGES &&
      !isSubscribed
    ) {
      setShouldShowPrompt(true);
    }
  }, [permissionState, timeInApp, pageViewCount, isSubscribed]);

  // Track page views for timing prompt
  useEffect(() => {
    const handleRouteChange = () => {
      setPageViewCount(prev => prev + 1);
    };
    
    // Listen for route changes
    window.addEventListener('popstate', handleRouteChange);
    
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  // Function to dismiss prompt
  const dismissPrompt = () => {
    setShouldShowPrompt(false);
  };

  // Function to update permission state
  const updatePermissionState = (state) => {
    setPermissionState(state);
    
    // If now granted, check subscription
    if (state === 'granted') {
      PushSubscriptionManager.isSubscribed().then(setIsSubscribed);
    }
  };

  // Function to request permissions
  const requestPermission = async () => {
    const granted = await NotificationService.requestPermission();
    updatePermissionState(granted ? 'granted' : 'denied');
    return granted;
  };

  // Function to subscribe to notifications
  const subscribeToNotifications = async () => {
    if (permissionState !== 'granted') {
      const granted = await requestPermission();
      if (!granted) return false;
    }
    
    const subscription = await PushSubscriptionManager.subscribe();
    setIsSubscribed(!!subscription);
    return !!subscription;
  };

  // Function to unsubscribe from notifications
  const unsubscribeFromNotifications = async () => {
    const success = await PushSubscriptionManager.unsubscribe();
    if (success) {
      setIsSubscribed(false);
    }
    return success;
  };

  // Function to send a test notification (useful for settings page)
  const sendTestNotification = async () => {
    if (!isSubscribed) {
      return false;
    }
    
    return NotificationService.showNotification(
      'test-notification',
      'Test Notification',
      {
        body: 'Ceci est un test de notification. Tout fonctionne correctement!',
        icon: '/logo192.png',
        vibrate: [100, 50, 100],
        data: {
          url: '/chat'
        }
      }
    );
  };

  // Create context value
  const contextValue = {
    permissionState,
    isSubscribed,
    shouldShowPrompt,
    dismissPrompt,
    updatePermissionState,
    requestPermission,
    subscribeToNotifications,
    unsubscribeFromNotifications,
    sendTestNotification
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;