import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  LoadingOverlay,
} from './styles';
import {
  InputContainer,
  StyledInput,
  SuggestionsContainer,
  SuggestionItem,
  NoIdeaButton,
  InputWrapper
} from './stylesCitySelection';

import compassImage from '../../assets/meo_write.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';
import noIdeaImageMan from '../../assets/emoji_dont_know.png';
import {allCitiesFormations} from '../data';
import { useAuth } from '../../contexts/AuthContext';
import apiCall from '../../utils/api';

const CitySelection = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Autour de quelle ville/région/department veux-tu faire tes études ?";
  const { isMobile } = useDeviceType();
  const inputRef = useRef(null);
  const { checkAuth } = useAuth();
  
  // Submit active state for button press effect (similar to BacGeneral)
  const [submitActive, setSubmitActive] = useState(false);

  const normalize = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const sortCities = (allCitiesFormations, searchTerm) => {
    const normalizedSearch = normalize(searchTerm);
    
    return allCitiesFormations.sort((a, b) => {
      const normalizedA = normalize(a);
      const normalizedB = normalize(b);
      
      const aStartsWith = normalizedA.startsWith(normalizedSearch);
      const bStartsWith = normalizedB.startsWith(normalizedSearch);
      
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      
      return normalizedA.localeCompare(normalizedB);
    });
  };

  useEffect(() => {
    // Set initial city value if in modify profile mode
    if (modifyProfile) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.ville) {
        setSelectedCity(user.ville);
        setInputValue(user.ville);
      }
    }

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, [modifyProfile]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setSelectedCity('');

    if (value.length > 0) {
      const filteredCities = allCitiesFormations.filter(city =>
        normalize(city).includes(normalize(value))
      );
      
      const sortedCities = sortCities(filteredCities, value).slice(0, 5);
      
      setSuggestions(sortedCities);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (city) => {
    setSelectedCity(city);
    setInputValue(city);
    setSuggestions([]);
  };

  const handleNoIdea = () => {
    setSelectedCity('Toute la France');
    setInputValue('Toute la France');
    setSuggestions([]);
  };

  const handleSubmit = async () => {
    if (!selectedCity || isTyping) return;
    setIsLoading(true);
    
    try {
      if (modifyProfile) {
        // API call to update user's city in the backend
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          user_city: selectedCity
        });
        
        // Update local storage
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.user_city = selectedCity;
        localStorage.setItem('user', JSON.stringify(userData));
        console.log("Ville mise à jour :", selectedCity);
        
        // Set flags for ranking updates like in BacGeneral
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        
        // Refresh auth context
        await checkAuth();
        
        // Navigate back to profile page or the next profile modification page
        navigate('/profile');
      } else {
        // Original onboarding flow
        localStorage.setItem('user_city', selectedCity);
        navigate('/phone-selection');
      }
    } catch (error) {
      console.error('Error saving city:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/classement');
    } else {
      navigate('/classe-ment');
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if (!selectedCity || isTyping) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!selectedCity || isTyping) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={95} endPercent={97} />}
        <LogoImage src={compassImage} alt="Compass Logo" />

        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <InputContainer
          style={{
            opacity: isTyping ? 0 : 1,
            transition: 'opacity 0.3s ease',
            pointerEvents: isTyping ? 'none' : 'auto'
          }}
        >
          <InputWrapper>
            <StyledInput
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Ville/Département/Région..."
              disabled={isTyping}
            />
            
            {suggestions.length > 0 && (
              <SuggestionsContainer>
                {suggestions.map((city, index) => (
                  <SuggestionItem
                    key={index}
                    onClick={() => handleSuggestionClick(city)}
                  >
                    {city}
                  </SuggestionItem>
                ))}
              </SuggestionsContainer>
            )}
          </InputWrapper>

          <NoIdeaButton
            onClick={handleNoIdea}
            disabled={isTyping}
            style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            <img src={noIdeaImageMan} alt="Toute la France" className="left-emoji" />
            Toute la France
          </NoIdeaButton>
        </InputContainer>
        
        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || !selectedCity}
          style={{
            opacity: (isTyping || !selectedCity) ? 0.5 : 1,
            cursor: (isTyping || !selectedCity) ? 'not-allowed' : 'pointer',
            boxShadow: (isTyping || !selectedCity || submitActive)
              ? 'none'
              : '0px 4px 0px #d42359'
          }}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default CitySelection;