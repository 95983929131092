// FicheTypeFormation.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import InfoTypeFormation from './InfoTypeFormation';
import FormationClassementTypeFormation from './formationClassementTypeFormation';
import CorrespondanceTypeFormation from './CorrespondanceTypeFormation';
import {
  Container,
  HeaderWrapper,
  HeaderSvg,
  HeaderContent,
  HeaderTopRow,
  ReturnButton,
  Subtitle,
  IconsContainer,
  ShareIcon,
  LikeIcon,
  HeaderBottomRow,
  FormationName,
  FloatingHeart,
  TabsContainer,
  TabButton,
  ScrollableContent,

  GrayBandWrapper,
  GrayBand,
  RectangleDiv,

  InfoRow,
  InfoItem,
  InfoIcon,
  InfoText
} from './styles';

import MatchIcon from '../../assets/correspondance_icon.png';
import DecouvrirIcon from '../../assets/decouvrir.png';

import dureeImg from '../../assets/duree.png';
import prixImg from '../../assets/prix.png';
import statutImg from '../../assets/statut.png';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { MdOutlineShare } from 'react-icons/md';
import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';

const CardTypeFormation = () => {
  const navigate = useNavigate();
  const [formation, setFormation] = useState({});
  const { isMobile } = useDeviceType();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [animateLike, setAnimateLike] = useState(false);
  const [animateShare, setAnimateShare] = useState(false);
  const [activeTab, setActiveTab] = useState('correspondance'); // 'correspondance' or 'Découvrir'
  const lastClickTimeRef = useRef(0);
  const [clickPosition, setClickPosition] = useState(null);
  const DOUBLE_CLICK_THRESHOLD = 300;
  const [posNeg, setPosNeg] = useState({});
  // Get formation id from URL query string
  const { id: urlSafeId } = useParams();
  console.log("urlSafeId ::", urlSafeId);
  const formationId = urlSafeId;

  // Add this function to track card visits
const trackCardVisit = async (formationId) => {
  try {
    await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/auth/track-card-visit/`,
      'POST',
      { formation_id: formationId }
    );
  } catch (error) {
    console.error('Error tracking card visit:', error);
  }
};

  
  const handleHeaderClick = (event) => {
    if (event.target.closest('[data-exclude="true"]')) return;
    const now = Date.now();
    if (now - lastClickTimeRef.current < DOUBLE_CLICK_THRESHOLD) {
      const rect = event.currentTarget.getBoundingClientRect();
      setClickPosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        key: Date.now()
      });
      if (!isFavorite) {
        setAnimateLike(true);
        handleLike();
        setTimeout(() => setAnimateLike(false), 500);
      }
    }
    lastClickTimeRef.current = now;
  };
// You can export this function for use in other parts of your React app.
 function normalizeUniqueType(inputStr) {
  // Ensure the input is a string.
  inputStr = String(inputStr);

  // Remove all plus signs.
  let result = inputStr.replace(/\+/g, '');

  // Replace any whitespace character or apostrophe with an underscore.
  result = result.replace(/[\s']/g, '_');

  // Replace multiple underscores with a single underscore.
  result = result.replace(/_+/g, '_');

  // Remove underscores from the beginning and end.
  result = result.replace(/^_+|_+$/g, '');

  // Remove any extra whitespace.
  result = result.trim();

  // Normalize the string to Unicode NFC form.
  result = result.normalize('NFC');

  // Convert the string to lowercase.
  result = result.toLowerCase();

  return result;
}

  useEffect(() => {
    const need_update_ranking_formation = JSON.parse(localStorage.getItem("need_update_ranking_formation"));
    if (formationId) {
      trackCardVisit(formationId);
    }
    const fetchPosNeg = async () => {
      try {
        // Check if there is a ranking in local storage with the id

        var posNeg = null
        console.log('[DEBUG] NEEDUPDATE:', need_update_ranking_formation);
        if (!need_update_ranking_formation) {
          posNeg = JSON.parse(localStorage.getItem("pos_neg"));
          console.log('[DEBUG] Ranking from local storage:', posNeg);
        }
        if (!posNeg) {
          const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/pos-neg/`;
          posNeg = await apiCall(url, 'GET');
        }
        localStorage.setItem("pos_neg", JSON.stringify(posNeg));
        setPosNeg(posNeg);
      } catch (err) {
        console.error('Erreur pos-neg:', err);
      }
    };

    const fetchTypeFormationData = async () => {
      try {
        
        // const need_update_ranking_formation = JSON.parse(localStorage.getItem("need_update_ranking"));
        var ranking = null
        console.log('[DEBUG] NEEDUPDATE:', need_update_ranking_formation);
        if (!need_update_ranking_formation) {
          const data = JSON.parse(localStorage.getItem("rankings"));
          ranking = data ? Object.values(data).find(r => normalizeUniqueType(r.nom_type) === normalizeUniqueType(formationId)) : null;
          console.log('[DEBUG] Ranking from local storage:', ranking);
        }
        if (!ranking) {
          const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/ficheTypeFormation/?id=${formationId}`;
          ranking = await apiCall(url, 'GET');
        }
        
        setFormation(ranking);
        console.log('formation:', ranking)
        setLoading(false);
      } catch (err) {
        console.error('Erreur type formation:', err);
        setError(true);
        setLoading(false);
      }
    };

    if (formationId) {
      fetchPosNeg();
      fetchTypeFormationData();

    } else {
      setError(true);
      setLoading(false);
    }
  }, [formationId]);
  
  // Replace the entire useEffect with this
useEffect(() => {
  // Only update if we have a formation with is_favorite property
  if (formation && 'is_favorite' in formation) {
    setIsFavorite(formation.is_favorite);
  }
}, [formation]);


const handleLike = async () => {
  // Define newFavoriteStatus outside the try block
  const newFavoriteStatus = !isFavorite;
  
  try {
    // Update local state immediately
    setIsFavorite(newFavoriteStatus);
    
    // Update the formation object
    setFormation(prev => ({
      ...prev,
      is_favorite: newFavoriteStatus
    }));
    
    // Update the formation in localStorage rankings
    const rankings = JSON.parse(localStorage.getItem("rankings")) || {};
    Object.keys(rankings).forEach(key => {
      if (normalizeUniqueType(rankings[key].nom_type) === normalizeUniqueType(formationId)) {
        rankings[key].is_favorite = newFavoriteStatus;
      }
    });
    localStorage.setItem("rankings", JSON.stringify(rankings));

    if (newFavoriteStatus) {
      // Add to favorites
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_type_formation/`,
        'POST',
        { 
          nom: formation.nom_type, 
          matchPercentage: formation.score_formations
        }
      );
    } else {
      // Remove from favorites
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_type_formation/`,
        'DELETE',
        { nom: formation.nom_type }
      );
    }
    
  } catch (error) {
    console.error('Error updating favorite:', error);
    
    // Revert on error
    setIsFavorite(!newFavoriteStatus);
    
    // Revert the formation object
    setFormation(prev => ({
      ...prev,
      is_favorite: !newFavoriteStatus
    }));
    
    // Revert localStorage
    const rankings = JSON.parse(localStorage.getItem("rankings")) || {};
    Object.keys(rankings).forEach(key => {
      if (normalizeUniqueType(rankings[key].nom_type) === normalizeUniqueType(formationId)) {
        rankings[key].is_favorite = !newFavoriteStatus;
      }
    });
    localStorage.setItem("rankings", JSON.stringify(rankings));
  }
};

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('Lien copié dans le presse‑papiers');
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur de chargement</div>;

  return (
    <Container isMobile={isMobile}>
      {/* HEADER */}
      <HeaderWrapper isMobile={isMobile} onClick={handleHeaderClick}>
        {clickPosition && (
          <FloatingHeart
            key={clickPosition.key}
            style={{
              left: `${clickPosition.x}px`,
              top: `${clickPosition.y}px`,
            }}
            onAnimationEnd={() => setClickPosition(null)}
          >
            <FaHeart />
          </FloatingHeart>
        )}

        <HeaderSvg
          xmlns="http://www.w3.org/2000/svg"
          width="379"
          height="140"
          viewBox="0 0 379 168"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 25C0 11.1929 11.1929 0 25 0H354C367.807 0 379 11.1929 379 25V143C379 156.807 367.807 168 354 168H25C11.1929 168 0 156.807 0 143V25Z"
            fill="url(#paint0_linear_176_161)"
            fillOpacity="1"
          />
          <defs>
            <linearGradient
              id="paint0_linear_176_161"
              x1="0"
              y1="84"
              x2="379"
              y2="84"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FC7D16" />
              <stop offset="1" stopColor="#FE276A" />
            </linearGradient>
          </defs>
        </HeaderSvg>

        <HeaderContent>
          <HeaderTopRow>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <ReturnButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleBack();
                }}
              >
                <IoIosArrowBack />
              </ReturnButton>
              <Subtitle>Filière</Subtitle>
            </div>
            <IconsContainer>
              <ShareIcon
                $animate={animateShare}
                onClick={(e) => {
                  setAnimateShare(true);
                  e.stopPropagation();
                  handleShare();
                  setTimeout(() => setAnimateShare(false), 300);
                }}
              >
                <MdOutlineShare />
              </ShareIcon>
              <LikeIcon
                data-exclude="true"
                $animate={animateLike}
                $isFavorite={isFavorite}
                onClick={handleLike}
              >
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </LikeIcon>
            </IconsContainer>
          </HeaderTopRow>

          <HeaderBottomRow>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormationName title={formation.nom_type}>
                {formation.nom_type}
              </FormationName>
              {/* Note: No school name is shown for a type formation */}
            </div>
          </HeaderBottomRow>
          {/* Removed action buttons (city/site/link) since they are not applicable */}
        </HeaderContent>
      </HeaderWrapper>
      <InfoRow>
        <InfoItem>
          <InfoIcon>
            <img src={dureeImg} alt="durée" />
          </InfoIcon>
          <InfoText>{formation.duree}</InfoText>
        </InfoItem>

        <InfoItem>
          <InfoIcon>
            <img src={prixImg} alt="prix" />
          </InfoIcon>
          <InfoText>{formation.cout} €/an</InfoText>
        </InfoItem>

        <InfoItem>
          <InfoIcon>
            <img src={statutImg} alt="statut" />
          </InfoIcon>
          <InfoText>{formation.public_prive}</InfoText>
        </InfoItem>
      </InfoRow>
      {/* TABS */}
      <GrayBandWrapper>
        <GrayBand />
      </GrayBandWrapper>

            <TabsContainer>
              <TabButton
                active={activeTab === 'correspondance'}
                onClick={() => setActiveTab('correspondance')}
              >
                <img
                  src={MatchIcon}
                  alt="Match icon"
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                />
                <span>Match</span>
              </TabButton>
      
              <TabButton
                active={activeTab === 'infos'}
                onClick={() => setActiveTab('infos')}
              >
                <img
                  src={DecouvrirIcon}
                  alt="Infos icon"
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                />
                <span>Découvrir</span>
              </TabButton>
            </TabsContainer>
            <RectangleDiv />
      

      {/* Scrollable container for tab content */}
      <ScrollableContent>
        {activeTab === 'correspondance' ? (
          <CorrespondanceTypeFormation formation={formation} posNeg={posNeg} />
        ) : (
          <FormationClassementTypeFormation /> 
        )}
      </ScrollableContent>
    </Container>
  );
};

export default CardTypeFormation;
