import styled from 'styled-components';

export const WelcomeMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
    /* Firefox-specific scrollbar settings */
  scrollbar-width: thin; 
  /* Format: scrollbar-color: thumb track; */
  scrollbar-color: #f1f1f1 #FAFAFA;

  /* WebKit-based browsers */
  &::-webkit-scrollbar {
    width: 8px;            /* Adjust scrollbar width */
  }

  &::-webkit-scrollbar-track {
    background-color: #FAFAFA; /* Match the second (track) color above */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; /* Match the first (thumb) color above */
    border-radius: 4px;        /* Optional: round corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e5e5e5; /* Slightly darker on hover, if you wish */
  }
  
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  gap: ${props => props.isMobile ? '0.25rem' : '0.5rem'};
  padding: 0 1rem;
  box-sizing: border-box;
`;

export const AppLogo = styled.img`
  width: min(50%, 200px);
  height: auto;
  margin-bottom: 0rem;
  margin-top: 0;
  object-fit: contain;
`;

export const WelcomeDescription = styled.p`
  font-size: 16px;
  text-align: center;
  color: #000000;
  max-width: 95%;
  font-family: 'Gabarito', sans-serif;
`;

export const ExamplesContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;  // Allow items to wrap
  gap: 0.75rem;
  padding: 0.5rem 0;
  margin-top: 0.25rem;
  justify-content: center; // Center items for better spacing
`;

export const ExampleBox = styled.div`
  padding: 8px 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #7A7A7A;
  background-color: #FFF;
  display: flex;
  align-items: center;
  min-height: 41px;
  font-size: 14px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  gap: 0.75rem;
  flex: 1 1 calc(50% - 0.75rem); // Take 50% width minus gap
  max-width: 90%; // Prevent exceeding 50% width
  box-sizing: border-box;

  &:hover {
    background-color: #f5f5f5;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


`;

export const IconWrapper = styled.div`
  width: 21px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ExampleContent = styled.div`
  flex: 1;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  word-break: break-word;
  min-width: min-content;
  display: block;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: normal;
`;