import {
  InfoWrapper,
  InfoRowContainer,
  InfoGrid,
  GridCell,
  TitleRow,
  InfoTitle,
  InfoContent,
  InfoIcon,
  VerticalDivider,
  HorizontalDivider,
  DomainContainer,
  DomainItem,
  DomainIcon,
  DomainText,
  SectionTitle,
  SectionText,
  SectionTitleWrapper,
  ImportanceSubtitle,
  DomainItemWrapper,
  LeftDomainContent,
  RightIconsContainer,
  SectionWithIcon,
  SectionIconWrapper,
} from './styles';

// import { ScoreContainer, ScoreTopText, ScoreValue, HeaderBottomRow } from '../CardTypeFormation/styles';

import salaireImg from '../../assets/salaire_icon.png'; // You might need to add this
import poursuiteImg from '../../assets/diplome_icon.png'; // You might need to add this
import debouchesImg from '../../assets/debouches_icon.png'; // You might need to add this
import useDeviceType from '../useDeviceType';
import {domainIconMapRaw} from './domainIconMap';
// Domain icons
import defaultIcon from '../../assets/dont_know_icon.png';

import plusIcon from '../../assets/plus_icon.png';
import dureeImg from '../../assets/duree.png';
import prixImg from '../../assets/prix.png';
import statutImg from '../../assets/statut.png';

function normalizeString(str) {
  return str
    .toLowerCase()                  // Lowercase
    .normalize('NFD')               // Decompose accents
    .replace(/[\u0300-\u036f]/g, '')// Strip accents
    .trim();                        // Remove trailing spaces
}

// Build a map with normalized keys
const normalizedDomainMap = Object.entries(domainIconMapRaw).reduce(
  (acc, [displayDomain, icon]) => {
    const key = normalizeString(displayDomain);
    acc[key] = { displayDomain, icon };
    return acc;
  },
  {}
);

// Styles pour la mise en page desktop
const desktopStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  topRowContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '20px',
    width: '100%',
    alignItems: 'stretch',
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  domains: {
    height: '100%',
  },
  bottomRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    width: '100%',
    alignItems: 'stretch',
  },
  equalHeight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 0,
    padding: 0,
  }
};

const InfoFormation = ({ formation, headerHeight }) => {
  const { isMobile } = useDeviceType();

  // Fonction pour rendre les domaines
  const renderDomains = () => (
    <DomainContainer style={isMobile ? {} : { height: '100%', margin: 0 }}>
      <SectionTitleWrapper>
        <SectionTitle>Domaines enseignés</SectionTitle>
        <ImportanceSubtitle>Poids dans la formation</ImportanceSubtitle>
      </SectionTitleWrapper>

      {[...formation.domaines_enseignés_principaux.map(d => ({ domain: d, type: 'principal' })),
        ...formation.domaines_enseignés_secondaires.map(d => ({ domain: d, type: 'secondaire' }))].map(({ domain, type }, idx) => {
        
        // Normalized domain code remains the same
        const normalized = normalizeString(domain);
        const found = normalizedDomainMap[normalized];
        const finalDomain = found ? found.displayDomain : domain; 
        const finalIcon = found ? found.icon : defaultIcon;

        return (
          <DomainItem key={idx}>
            <DomainItemWrapper>
              <LeftDomainContent>
                <DomainIcon>
                  <img src={finalIcon} alt={finalDomain} />
                </DomainIcon>
                <DomainText>{finalDomain}</DomainText>
              </LeftDomainContent>
              
              <RightIconsContainer>
                {Array.from({ length: type === 'principal' ? 2 : 1 }).map((_, i) => (
                  <img
                    key={i}
                    src={plusIcon}
                    alt="Importance"
                    style={{ width: '16px', height: '16px' }}
                  />
                ))}
              </RightIconsContainer>
            </DomainItemWrapper>
          </DomainItem>
        );
      })}
    </DomainContainer>
  );

  // Fonction pour rendre la grille d'informations
  const renderInfoGrid = () => (
    <InfoRowContainer style={isMobile ? {} : { height: '100%', margin: 0 }}>
      <InfoGrid style={isMobile ? {} : { height: '100%' }}>
        {/* Top Left: Durée */}
        <GridCell>
          <TitleRow>
            <InfoIcon>
              <img src={dureeImg} alt="durée" />
            </InfoIcon>
            <InfoTitle>Durée</InfoTitle>
          </TitleRow>
          <InfoContent>{formation.duree}</InfoContent>
        </GridCell>
        
        {/* Top Right: Coût */}
        <GridCell>
          <TitleRow>
            <InfoIcon>
              <img src={prixImg} alt="coût" />
            </InfoIcon>
            <InfoTitle>Coût</InfoTitle>
          </TitleRow>
          <InfoContent>{formation.cout} €/an</InfoContent>
        </GridCell>
        
        {/* Bottom Left: Statut */}
        <GridCell>
          <TitleRow>
            <InfoIcon>
              <img src={statutImg} alt="statut" />
            </InfoIcon>
            <InfoTitle>Statut</InfoTitle>
          </TitleRow>
          <InfoContent>{formation.public_prive}</InfoContent>
        </GridCell>
        
        {/* Bottom Right: Salaire de sortie */}
        <GridCell>
          <TitleRow>
            <InfoIcon>
              <img src={salaireImg} alt="salaire" />
            </InfoIcon>
            <InfoTitle>Salaire de sortie</InfoTitle>
          </TitleRow>
          <InfoContent>{formation.salaire_sortie || "Non spécifié"}</InfoContent>
        </GridCell>
        
        {/* Dividers */}
        <VerticalDivider />
        <HorizontalDivider />
      </InfoGrid>
    </InfoRowContainer>
  );

  // Fonction pour rendre la poursuite d'études
  const renderPoursuiteDEtudes = () => (
    <DomainContainer style={isMobile ? {} : { height: '100%', display: 'flex', flexDirection: 'column', margin: 0 }}>
      <SectionWithIcon>
        <SectionIconWrapper>
          <img src={poursuiteImg} alt="poursuite d'études" />
        </SectionIconWrapper>
        <SectionTitle>Poursuite d'études</SectionTitle>
      </SectionWithIcon>
      <div style={isMobile ? {} : { flex: 1 }}>
        {Object.values(formation.poursuite_etudes || {}).map((value, idx) => (
          <SectionText key={idx}>{value}</SectionText>
        ))}
      </div>
    </DomainContainer>
  );

  // Fonction pour rendre les débouchés professionnels
  const renderDebouchesProfessionnels = () => (
    <DomainContainer style={isMobile ? {} : { height: '100%', display: 'flex', flexDirection: 'column', margin: 0 }}>
      <SectionWithIcon>
        <SectionIconWrapper>
          <img src={debouchesImg} alt="débouchés" />
        </SectionIconWrapper>
        <SectionTitle>Débouchés Professionnels</SectionTitle>
      </SectionWithIcon>
      <div style={isMobile ? {} : { flex: 1 }}>
        {Object.values(formation.débouchés_professionnels || {}).map((value, idx) => (
          <SectionText key={idx}>{value}</SectionText>
        ))}
      </div>
    </DomainContainer>
  );

  // Mobile layout (original)
  if (isMobile) {
    return (
      <InfoWrapper>
        {renderInfoGrid()}
        {renderDomains()}
        {renderPoursuiteDEtudes()}
        {renderDebouchesProfessionnels()}
      </InfoWrapper>
    );
  }

  // Desktop layout (new grid layout)
  return (
    <InfoWrapper style={isMobile ? {} : { padding: '15px' }}>
      <div style={desktopStyles.container}>
        {/* Top Row: InfoGrid on left, Domains on right */}
        <div style={desktopStyles.topRowContainer}>
          <div style={desktopStyles.cardStyle}>
            {renderInfoGrid()}
          </div>
          <div style={desktopStyles.cardStyle}>
            {renderDomains()}
          </div>
        </div>
        
        {/* Bottom Row: Poursuite d'études on left, Débouchés on right */}
        <div style={desktopStyles.bottomRow}>
          <div style={desktopStyles.cardStyle}>
            {renderPoursuiteDEtudes()}
          </div>
          <div style={desktopStyles.cardStyle}>
            {renderDebouchesProfessionnels()}
          </div>
        </div>
      </div>
    </InfoWrapper>
  );
};

export default InfoFormation;