import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import FilteringBrowser from './FilteringBrowser';
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  OptionsContainer,
  OptionButton,
  LoadingOverlay,
  ChatBubbleContainer,
  ChatText,
  LogoImage,
  OptionsScrollContainer
} from './styles';

// Import high schools data directly
import highSchoolsData from './highschoolsData';

import compassImage from '../../assets/meo_write.png';
import lieuIcon from '../../assets/lieu_gris.png';
import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';

// School info container
const SchoolInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex: 1;
  padding: 10px 15px;
`;

// School name styling
const SchoolName = styled.div`
  font-weight: ${props => props.$isSelected ? '600' : 'normal'};
  color: ${props => props.$isSelected ? '#38B6FF' : 'inherit'};
  margin-bottom: 6px;
`;

// Location container styling
const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

// Location icon styling
const LocationIcon = styled.img`
  width: 14px;
  height: 14px;
  filter: ${props => props.$isSelected ? 'invert(52%) sepia(82%) saturate(2548%) hue-rotate(187deg) brightness(101%) contrast(101%)' : 'none'};
`;

// Location text styling
const LocationText = styled.div`
  font-size: 15px;
  font-family: Gabarito;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${props => props.$isSelected ? '#38B6FF' : '#D9D7D7'};
`;

// Wrapper for FilteringBrowser to ensure clicks work properly
const BrowserWrapper = styled.div`
  width: 100%;
  margin-top: -15%;
  z-index: 10;
  position: relative;
`;

// Autre option styling
const AutreOption = styled(OptionButton)`
  border-bottom: 1px solid #e0e0e0;
`;

// Show more button
const ShowMoreButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  color: #38B6FF;
  font-weight: 500;
  
  &:hover {
    background-color: #e0e0e0;
  }
`;

// Optimized normalization function with memoization
const normalizeStringMemo = (() => {
  const cache = {};
  return (str) => {
    if (!cache[str]) {
      cache[str] = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    return cache[str];
  };
})();

// Create an "Autre" option once
const autreOption = {
  name: "Autre",
  location: "",
  uai: "autre"
};

// Initial number of items to display
const INITIAL_ITEMS_TO_SHOW = 20;
// Number of additional items to load when clicking "Show More"
const ITEMS_PER_LOAD = 30;

// Debounce function to limit how often a function can be called
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const Lycee = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const { isMobile } = useDeviceType();
  const { checkAuth } = useAuth();
  const browserRef = useRef(null);
  const optionsContainerRef = useRef(null);

  const [selectedHighSchool, setSelectedHighSchool] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = modifyProfile ? "Choisis ton lycée" : "Pour finir, sélectionne ton lycée !";
  const [searchText, setSearchText] = useState('');
  const [itemsToShow, setItemsToShow] = useState(INITIAL_ITEMS_TO_SHOW);
  
  // Track if user has scrolled to see more schools
  const [hasScrolled, setHasScrolled] = useState(false);

  // Optimized typing animation - simpler and faster
  useEffect(() => {
    // Skip animation on slow devices or when modifying profile
    if (window.navigator.hardwareConcurrency < 4 || modifyProfile) {
      setText(fullText);
      setIsTyping(false);
      return;
    }
    
    let currentIndex = 0;
    const charIncrement = 3;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex += charIncrement;
      } else {
        setText(fullText);
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 5);
    return () => clearInterval(typingInterval);
  }, [fullText, modifyProfile]);

  // Check if user already has lycee data when modifying profile
  useEffect(() => {
    if (modifyProfile) {
      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      
      if (userData.lycee_uai) {
        if (userData.lycee_uai === "autre" || userData.lycee_uai === "") {
          setSelectedHighSchool(autreOption);
        } else {
          const existingSchool = highSchoolsData.find(
            school => school.uai === userData.lycee_uai
          );
          
          if (existingSchool) {
            setSelectedHighSchool(existingSchool);
            
            // If we have a selected school, scroll into view if possible
            setTimeout(() => {
              const selectedElement = document.getElementById(`school-${userData.lycee_uai}`);
              if (selectedElement && optionsContainerRef.current) {
                // Ensure we show enough items to include the selected school
                const index = highSchoolsData.findIndex(s => s.uai === userData.lycee_uai);
                if (index >= 0 && index >= itemsToShow) {
                  setItemsToShow(Math.ceil((index + 1) / ITEMS_PER_LOAD) * ITEMS_PER_LOAD);
                }
              }
            }, 300);
          }
        }
      }
    }
  }, [modifyProfile]);

  // Add scroll event listener for pagination
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!optionsContainerRef.current) return;
      
      const { scrollTop, scrollHeight, clientHeight } = optionsContainerRef.current;
      const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 20;
      
      if (scrolledToBottom && !hasScrolled) {
        setHasScrolled(true);
      }
    }, 100);
    
    const optionsContainer = optionsContainerRef.current;
    if (optionsContainer) {
      optionsContainer.addEventListener('scroll', handleScroll);
    }
    
    return () => {
      if (optionsContainer) {
        optionsContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasScrolled, itemsToShow]);

  // Focus on browser input when clicked - memoized to prevent unnecessary re-renders
  const handleBrowserClick = useCallback(() => {
    if (browserRef.current) {
      const inputElement = browserRef.current.querySelector('input');
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, []);

  // Memoized search handler with debounce
  const handleSearchChange = useCallback((value) => {
    const debouncedSearch = debounce((searchValue) => {
      setSearchText(searchValue);
      // Reset pagination when searching
      setItemsToShow(INITIAL_ITEMS_TO_SHOW);
      // Reset scroll indicator
      setHasScrolled(false);
    }, 300);
    
    debouncedSearch(value);
  }, []);

  // Memoized high school selection handler
  const handleHighSchoolSelection = useCallback((school) => {
    setSelectedHighSchool(school);
  }, []);

  // Handle loading more items
  const handleLoadMore = useCallback(() => {
    setItemsToShow(prev => prev + ITEMS_PER_LOAD);
  }, []);

  // Memoized submit handler
  const handleSubmit = useCallback(async () => {
    if (!selectedHighSchool || isTyping) return;
    setIsLoading(true);
    try {
      const lycee_name = selectedHighSchool.uai === "autre" ? "" : selectedHighSchool.name;
      const lycee_city = selectedHighSchool.uai === "autre" ? "" : selectedHighSchool.location;
      const lycee_uai = selectedHighSchool.uai === "autre" ? "" : selectedHighSchool.uai;

      if (modifyProfile) {
        // Optimize API calls - combine into one request if possible
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          lycee_name, lycee_city, lycee_uai
        });

        // Update user data in localStorage
        let userData = JSON.parse(localStorage.getItem('user') || '{}');
        userData.lycee_name = lycee_name;
        userData.lycee_city = lycee_city;
        userData.lycee_uai = lycee_uai;
        localStorage.setItem('user', JSON.stringify(userData));
        
        await checkAuth();
        navigate('/profile/modify/classe-ment');
      } else {
        // Store lycee info in localStorage for onboarding flow
        localStorage.setItem('lycee_name', lycee_name);
        localStorage.setItem('lycee_city', lycee_city);
        localStorage.setItem('lycee_uai', lycee_uai);
        
        navigate('/classe-ment');
      }
    } catch (error) {
      console.error('Error saving high school data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedHighSchool, isTyping, modifyProfile, checkAuth, navigate]);

  // Memoized back handler
  const handleBack = useCallback(() => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate("/general-moyenne");
    }
  }, [modifyProfile, navigate]);

  // Memoized filtering of high schools for better performance
  const filteredHighSchools = useMemo(() => {
    const normalizedSearch = searchText.trim() === '' ? '' : normalizeStringMemo(searchText);
    
    if (normalizedSearch === '') {
      return highSchoolsData;
    }
    
    return highSchoolsData.filter(school => {
      const normalizedName = normalizeStringMemo(school.name);
      const normalizedLocation = normalizeStringMemo(school.location);
      
      return normalizedName.includes(normalizedSearch) || 
             normalizedLocation.includes(normalizedSearch);
    });
  }, [searchText]);

  // Memoize the sliced list to show
  const visibleHighSchools = useMemo(() => {
    // If searching, we might want to show more results
    const limit = searchText ? Math.max(itemsToShow, 50) : itemsToShow;
    return filteredHighSchools.slice(0, limit);
  }, [filteredHighSchools, itemsToShow, searchText]);

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={85} endPercent={90} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <BrowserWrapper ref={browserRef} onClick={handleBrowserClick}>
          <FilteringBrowser
            searchValue={searchText}
            onSearchChange={(value) => {
              setSearchText(value);
              handleSearchChange(value);
            }}
          />
        </BrowserWrapper>

        <OptionsScrollContainer 
          isMobile={isMobile}
          style={{
            position: 'relative',
            top: '10px',
            maxHeight: 'calc(var(--vh, 1vh) * 60)'
          }}
          ref={optionsContainerRef}
        >
          <OptionsContainer style={{ paddingBottom: '60px' }}>
            {/* Autre option always at the top */}
            <AutreOption
              $isSelected={selectedHighSchool && selectedHighSchool.uai === autreOption.uai}
              type="button"
              aria-pressed={selectedHighSchool && selectedHighSchool.uai === autreOption.uai}
              onClick={() => handleHighSchoolSelection(autreOption)}
            >
              <SchoolInfoContainer>
                <SchoolName $isSelected={selectedHighSchool && selectedHighSchool.uai === autreOption.uai}>
                  {autreOption.name}
                </SchoolName>
              </SchoolInfoContainer>
            </AutreOption>

            {filteredHighSchools.length > 0 ? (
              <>
                {visibleHighSchools.map((school) => (
                  <OptionButton
                    key={school.uai}
                    id={`school-${school.uai}`}
                    $isSelected={selectedHighSchool && selectedHighSchool.uai === school.uai}
                    type="button"
                    aria-pressed={selectedHighSchool && selectedHighSchool.uai === school.uai}
                    onClick={() => handleHighSchoolSelection(school)}
                  >
                    <SchoolInfoContainer>
                      <SchoolName $isSelected={selectedHighSchool && selectedHighSchool.uai === school.uai}>
                        {school.name}
                      </SchoolName>
                      <LocationContainer>
                        <LocationIcon 
                          src={lieuIcon} 
                          alt="Location" 
                          $isSelected={selectedHighSchool && selectedHighSchool.uai === school.uai}
                        />
                        <LocationText $isSelected={selectedHighSchool && selectedHighSchool.uai === school.uai}>
                          {school.location}
                        </LocationText>
                      </LocationContainer>
                    </SchoolInfoContainer>
                  </OptionButton>
                ))}
                
                {/* Show "load more" button if there are more schools to display */}
                {visibleHighSchools.length < filteredHighSchools.length && (
                  <ShowMoreButton onClick={handleLoadMore}>
                    Afficher plus ({filteredHighSchools.length - visibleHighSchools.length} restants)
                  </ShowMoreButton>
                )}
              </>
            ) : (
              <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                Aucun lycée trouvé
              </div>
            )}
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || !selectedHighSchool}
          style={{
            opacity: (isTyping || !selectedHighSchool) ? 0.5 : 1,
            cursor: (isTyping || !selectedHighSchool) ? 'not-allowed' : 'pointer'
          }}
          onClick={(e) => { e.preventDefault(); handleSubmit(); }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

// Use React.memo to prevent unnecessary re-renders
export default React.memo(Lycee);