import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  LoadingOverlay,
} from './styles';
import {
  InputContainer,
  StyledInput,
  InputWrapper
} from './stylesPhoneSelection';

import compassImage from '../../assets/meo_write.png';
import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';
import { useAuth } from '../../contexts/AuthContext';
import apiCall from '../../utils/api';
import { styled } from 'styled-components';
import theme from '../../theme';
const SubmitButton = styled.button`
    margin: 0 auto;
  width: 90%;
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  max-width: 600px;
  border-radius: 15px;
  transition: all 0.15s ease; /* fast transition */

  height: 53px;
  background-color: ${props => (props.disabled ? '#efecec' : '#ff2768')};
  /* Remove shadow if the button is disabled OR pressed (active) */
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 4px 0px #d42359'};

  display: flex;
  align-items: center;
  justify-content: center;
`;
const PhoneSelection = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Quel est ton numéro de téléphone portable ?";
  const { isMobile } = useDeviceType();
  const inputRef = useRef(null);
  const { checkAuth } = useAuth();
  
  // Submit active state for button press effect
  const [submitActive, setSubmitActive] = useState(false);

  useEffect(() => {
    // Set initial phone value if in modify profile mode
    if (modifyProfile) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.telephone) {
        setPhoneNumber(user.telephone);
        setFormattedPhoneNumber(formatPhoneNumber(user.telephone));
        // Validate immediately after setting the initial value
        validatePhoneNumber(user.telephone, true);
      }
    }

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, [modifyProfile]);

  const convertToNationalFormat = (value) => {
    // Remove all non-digit characters and potential '+' sign
    let digits = value.replace(/[^\d+]/g, '');
    
    // Check if starts with +33
    if (digits.startsWith('+33')) {
      // Replace +33 with 0
      return '0' + digits.substring(3);
    }
    
    return digits;
  };

  const formatPhoneNumber = (value) => {
    // Special case for initial partial inputs
    if (value === '+') return '+';
    if (value === '+3') return '+3';
    if (value === '+33') return '+33';
    
    // Extract just the digits and the + sign if present
    const cleaned = value.replace(/[^\d+]/g, '');
    
    // Check if we're dealing with an international format
    if (cleaned.startsWith('+33')) {
      // Get the part after +33
      const afterPrefix = cleaned.substring(3);
      
      // If nothing after the prefix yet, just return
      if (afterPrefix.length === 0) {
        return '+33';
      }
      
      // Format: +336 XX XX XX or +337 XX XX XX
      let formatted = '+33' + afterPrefix.charAt(0);
      
      // Format the remaining digits with spaces between pairs
      for (let i = 1; i < Math.min(afterPrefix.length, 8); i += 2) {
        formatted += ' ' + afterPrefix.substring(i, Math.min(i + 2, afterPrefix.length));
      }
      
      return formatted;
    } 
    // National format (06 or 07)
    else {
      const digits = cleaned.replace(/\D/g, '');
      
      // Format with spaces between pairs (06 XX XX XX XX)
      let formatted = '';
      for (let i = 0; i < Math.min(digits.length, 10); i += 2) {
        formatted += digits.substring(i, Math.min(i + 2, digits.length));
        if (i + 2 < Math.min(digits.length, 10)) formatted += ' ';
      }
      
      return formatted;
    }
  };

  const validatePhoneNumber = (value, forceValidation = false) => {
    // Convert to national format if needed
    const nationalFormat = convertToNationalFormat(value);
    
    // Remove all non-digit characters
    const digits = nationalFormat.replace(/\D/g, '');
    
    // Check if starts with 06 or 07 and has 10 digits total
    const isValidFormat = /^(06|07)\d{8}$/.test(digits);
    
    // Update validation state
    setIsValid(isValidFormat);
    
    // For autofilled values or initial values, we might need to force validation
    if (forceValidation && isValidFormat) {
      // Make sure the state is updated properly
      setTimeout(() => {
        setIsValid(true);
      }, 0);
    }
    
    return isValidFormat;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    
    // Special case for handling single "+" character
    if (value === "+" && formattedPhoneNumber === "") {
      setFormattedPhoneNumber("+");
      setPhoneNumber("+");
      return;
    }
    
    // Accept any input that contains digits, spaces, or plus sign
    const sanitizedValue = value.replace(/[^\d\s+]/g, '');
    
    // For pasted values, we need to handle them correctly
    const isPastedValue = Math.abs(value.length - formattedPhoneNumber.length) > 1 || e.nativeEvent.inputType === 'insertFromPaste';
    
    if (sanitizedValue === value || isPastedValue) {
      // Extract all digits plus the + sign if present
      const allDigitsAndPlus = value.replace(/[^\d+]/g, '');
      
      // Check if it's an international format
      const isInternationalFormat = allDigitsAndPlus.startsWith('+33');
      
      // Extract just the digits for length checking
      const justDigits = allDigitsAndPlus.replace(/\D/g, '');
      
      // Limit the length based on format
      const maxDigits = isInternationalFormat ? 11 : 10; // +33 (2) + 9 digits or 10 digits total
      const truncatedDigits = justDigits.slice(0, maxDigits);
      
      // Don't allow more than the maximum digits
      if (truncatedDigits.length === justDigits.length) {
        // Process for storage - always convert to national format
        const nationalFormat = convertToNationalFormat(allDigitsAndPlus);
        setPhoneNumber(nationalFormat);
        
        // Process for display - format according to input type
        const formattedValue = formatPhoneNumber(allDigitsAndPlus);
        setFormattedPhoneNumber(formattedValue);
        
        // For autofilled values, force validation
        const isAutofilled = isPastedValue || 
                            e.nativeEvent.inputType === 'insertReplacementText' || 
                            e.nativeEvent.data === null;
        
        // Validate the number
        validatePhoneNumber(nationalFormat, isAutofilled);
      }
    }
  };

  // Handle input field selection or focus, particularly important for autofilled values
  const handleFocus = () => {
    // Re-validate on focus for autofilled values
    if (formattedPhoneNumber) {
      validatePhoneNumber(phoneNumber, true);
    }
  };

  // Handle when browser autofills the field
  const handleChange = (e) => {
    handleInputChange(e);
    
    // Additional check for autofilled values
    setTimeout(() => {
      if (inputRef.current && inputRef.current.value !== formattedPhoneNumber) {
        // Handle browser autofill
        const autofilledValue = inputRef.current.value;
        handleInputChange({ 
          target: { value: autofilledValue },
          nativeEvent: { inputType: 'insertReplacementText', data: null }
        });
      }
    }, 100);
  };

  const handleSubmit = async () => {
    if (!isValid || isTyping) return;
    setIsLoading(true);
    
    // Ensure phone number is in national format for storage
    const nationalFormat = convertToNationalFormat(phoneNumber);
    
    try {
      if (modifyProfile) {
        // API call to update user's phone in the backend
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          phone_number: nationalFormat
        });
        
        // Update local storage
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.telephone = nationalFormat;
        localStorage.setItem('user', JSON.stringify(userData));
        console.log("Téléphone mis à jour :", nationalFormat);
        
        // Refresh auth context
        await checkAuth();
        
        // Navigate back to profile page
        navigate('/profile');
      } else {
        // Original onboarding flow
        localStorage.setItem('user_phone', nationalFormat);
        navigate('/presentation-features');
      }
    } catch (error) {
      console.error('Error saving phone number:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/city-selection');
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if (!isValid || isTyping) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!isValid || isTyping) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={97} endPercent={100} />}
        <LogoImage src={compassImage} alt="Compass Logo" />

        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <InputContainer
          style={{
            opacity: isTyping ? 0 : 1,
            transition: 'opacity 0.3s ease',
            pointerEvents: isTyping ? 'none' : 'auto'
          }}
        >
          <InputWrapper>
            <StyledInput
              ref={inputRef}
              type="tel"
              value={formattedPhoneNumber}
              onChange={handleChange}
              onFocus={handleFocus}
              placeholder="06 XX XX XX XX"
              disabled={isTyping}
              isValid={isValid}
              autoComplete="tel"
            />
            {formattedPhoneNumber && !isValid && (
              <div className="error-message">
                Veuillez entrer un numéro de portable français valide (06, 07, +33 6 ou +33 7)
              </div>
            )}
          </InputWrapper>
        </InputContainer>
        
        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || !isValid}
          style={{
            opacity: (isTyping || !isValid) ? 0.5 : 1,
            cursor: (isTyping || !isValid) ? 'not-allowed' : 'pointer',
            boxShadow: (isTyping || !isValid || submitActive)
              ? 'none'
              : '0px 4px 0px #d42359'
          }}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default PhoneSelection;
