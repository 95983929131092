import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { searchFormations } from '../Comparateur/search-utils';
// Import these directly instead of using the loadSearchData function
import formationsMeta from "../Comparateur/formationsMeta.json";
import searchIndex from "../Comparateur/searchIndex.json";

// Import shared assets
import lettresHeaderImage from '../../assets/meo_lettre.png';
import loupe_grise from '../../assets/loupe_grise.png';
import loupe_blanche from '../../assets/loupe.png';
import useDeviceType from '../useDeviceType';
import theme from '../../theme';

// Import the new LetterHistory component
import LetterHistory from './History';

// Styled Components
const Container = styled.div`
  width: ${props => props.isMobile ? '99%' : 'calc(100% - 330px)'};
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color:${theme.colors.background};
  font-family: 'Gabarito', sans-serif;
  padding-bottom: 100px;
  margin-left: ${props => props.isMobile ? '0' : '330px'};

    
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.lightGray} transparent;
  
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.background};
      border-radius: 2px;
    }
`;

// New Header Image Container
const HeaderImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0;
  width: 100%;
`;

const HeaderImage = styled.img`
  max-width: 150px;
  height: auto;
`;

// Search Box styles
const SearchBox = styled.div`
  padding: 0px;
  margin-top: -10px;
  z-index: 100;
  background-color:${theme.colors.background};
  display: flex;
  justify-content: center;
`;

// SearchInputContainer
const SearchInputContainer = styled.div`
  position: relative;
  width: 350px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  border: 2px solid ${props => props.active ? '#38B6FF' : '#E6E6E6'};
  background: #FFF;
  overflow: hidden;
`;

// SearchInput
const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  border: none;
  font-size: 15px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  line-height: 108.52%;
  text-align: left;
  outline: none;
  background: transparent;
  
  &::placeholder {
    color: #C4C4C4;
    text-align: left;
    font-family: Gabarito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 108.52%;
  }
`;

// SearchIcon position to right
const SearchIcon = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Circle container for search icon
const SearchIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  margin-right: -3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#38B6FF' : '#E6E6E6'};
`;


const FormationResultsContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  z-index: 200;
`;

// Components for search results
const FormationResultItem = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  z-index: 200;
  position: relative;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  ${props => props.loading && `
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
  `}
`;

const ResultItemLoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  color: #38B6FF;
  font-size: 12px;
  font-weight: bold;
`;

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(56, 182, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #38B6FF;
  animation: spin 1s linear infinite;
  margin-left: 8px;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Components for global loading
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 245, 248, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const LoadingSpinnerLarge = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid rgba(56, 182, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #38B6FF;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
`;

const LoadingText = styled.div`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 500;
`;


// Empty state message
const EmptyStateMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #777;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 30%;
`;

// LoadingIndicator component
const LoadingIndicator = () => (
  <LoadingOverlay>
    <LoadingSpinnerLarge />
    <LoadingText>Chargement des formations...</LoadingText>
  </LoadingOverlay>
);

const FormationMeta = styled.div`
  color: #888;
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 6px;
    font-size: 12px;
  }
`;

const FormationName = styled.h2`
  font-size: ${props => props.small ? '16px' : '19px'};
  font-weight: 400;
  margin: 0 0 4px 0;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
`;

const FormationSearch = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const { isMobile } = useDeviceType();
  const searchInputRef = useRef(null);
  const resultsContainerRef = useRef(null);
  const [loadingFormationId, setLoadingFormationId] = useState(null);
  const [hasLetterHistory, setHasLetterHistory] = useState(false);

  // Check if user has letter history with actual letters
  useEffect(() => {
    const checkLetterHistory = () => {
      try {
        const storedData = localStorage.getItem('lettresData');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          // Make sure we have at least one formation with content
          const hasValidLetters = Object.keys(parsedData).length > 0 && 
                                 Object.values(parsedData).some(data => 
                                   (data.questions && data.questions.length > 0) || data.letterContent
                                 );
          setHasLetterHistory(hasValidLetters);
        } else {
          setHasLetterHistory(false);
        }
      } catch (error) {
        console.error('Error checking letter history:', error);
        setHasLetterHistory(false);
      }
    };

    checkLetterHistory();
  }, []);

  // Just initialize from the imported JSON directly
  useEffect(() => {
    // Short delay to avoid blocking the UI
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);
  
  // Search formations using inverted index when search text changes
  useEffect(() => {
    if (searchText.trim() === '') {
      setSearchResults([]);
      return;
    }
    
    try {
      // Use the imported searchIndex and formationsMeta directly
      const results = searchFormations(searchText, searchIndex, formationsMeta, 100);
      setSearchResults(results || []);
    } catch (error) {
      console.error('Error during search:', error);
      setSearchResults([]);
    }
  }, [searchText]);
  
  // Handle formation selection to redirect to comparateur
  const handleFormationSelect = (formationMeta) => {
    setLoadingFormationId(formationMeta.id);
    
    // Save formation to localStorage
    localStorage.setItem('selectedFormation', JSON.stringify({
      id: formationMeta.id,
      name: formationMeta.nomFormation
    }));
    
    // Redirect to lettre validation page
    navigate(`/lettres-validate`);
    
    // Reset search state
    setLoadingFormationId(null);
    setSearchText('');
    setSearchResults([]);
    setShowResults(false);
  };
  
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setShowResults(true);
  };
  
  // Handle focus on search input
  const handleSearchFocus = () => {
    setShowResults(true);
  };
  
  // Handle click outside to close search results
  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedInsideSearch = searchInputRef.current && searchInputRef.current.contains(event.target);
      const clickedInsideResults = resultsContainerRef.current && resultsContainerRef.current.contains(event.target);
      
      if (!clickedInsideSearch && !clickedInsideResults) {
        setShowResults(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container isMobile={isMobile}>
      {loading && <LoadingIndicator />}
      
      {/* Header with image */}
      <HeaderImageContainer>
        <HeaderImage src={lettresHeaderImage} alt="Recherche de formations" />
      </HeaderImageContainer>

      {/* Search box */}
      <SearchBox>
        <div style={{ position: 'relative' }}>
          <SearchInputContainer ref={searchInputRef} active={searchText.length > 0}>
            <SearchInput
              type="text"
              placeholder="Rechercher une formation"
              value={searchText}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
            />
            <SearchIcon>
              <SearchIconCircle active={searchText.length > 0}>
                {searchText.length === 0 ? (
                  <img src={loupe_grise} alt="Search" style={{ width: '20px', height: '20px' }} />
                ) : (
                  <img src={loupe_blanche} alt="Search" style={{ width: '20px', height: '20px' }} />
                )}
              </SearchIconCircle>
            </SearchIcon>
          </SearchInputContainer>
          
          <FormationResultsContainer 
            ref={resultsContainerRef}
            visible={showResults && searchResults.length > 0}
          >
            {searchResults.slice(0, 50).map((formation, index) => (
              <FormationResultItem 
                key={index} 
                loading={loadingFormationId === formation.id}
                onClick={(e) => {
                  // Prevent propagation
                  e.preventDefault();
                  e.stopPropagation();
                  
                  // Do nothing if already loading
                  if (loadingFormationId) return;
                  
                  console.log("Formation sélectionnée:", formation);
                  handleFormationSelect(formation);
                }}
              >
                <FormationName small>{formation.nomFormation}</FormationName>
                <FormationMeta>
                  <span>{formation.nomEtablissement}</span>
                  {formation.commune && (
                    <span> • {formation.commune}</span>
                  )}
                </FormationMeta>
                
                {/* Show spinner if this formation is loading */}
                {loadingFormationId === formation.id && (
                  <ResultItemLoadingSpinner>
                    <span>Chargement</span>
                    <Spinner />
                  </ResultItemLoadingSpinner>
                )}
              </FormationResultItem>
            ))}
            {searchResults.length === 0 && searchText.trim() !== '' && (
              <div style={{ padding: '16px', textAlign: 'center', color: '#888' }}>
                Aucun résultat trouvé
              </div>
            )}
          </FormationResultsContainer>
        </div>
      </SearchBox>
      
      {/* Updated content area: Either show empty state message or letter history */}
      {hasLetterHistory ? (
        <LetterHistory />
      ) : (
        <EmptyStateMessage>
          Sélectionne la formation pour laquelle tu veux écrire une lettre de motivation !
        </EmptyStateMessage>
      )}
    </Container>
  );
};

export default FormationSearch;