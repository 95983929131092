import React from 'react';
import styled from 'styled-components';
import searchIcon from '../../assets/loupe_icon.png';
import useDeviceType from '../useDeviceType';

// -------------------------
// Styled Components
// -------------------------
const BrowserContainer = styled.div`
  width: 100%;
  top: ${(props) => (props.isMobile ? '-15%' : '20%')};
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;  
`;

const SearchArea = styled.div`
  width: 100%;
  margin: 1% auto;
  position: relative;
  box-shadow: 0px 2px 2px #38b6ff;
  border-radius: 25px;
  background-color: #f3fbff;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

const Ellipse = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 0);
  border: 1px solid #38b6ff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

const LoupeIcon = styled.img`
  width: 15px;
  height: 15px;
  object-fit: cover;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: 'Gabarito', sans-serif;
  background-color: transparent;
`;

// -------------------------
// Component
// -------------------------
const FilteringBrowser = ({ searchValue, onSearchChange }) => {
  const {isMobile} = useDeviceType();
  return (
    <BrowserContainer isMobile={isMobile}>
      <SearchArea>
        <Ellipse>
          <LoupeIcon src={searchIcon} alt="search icon" />
        </Ellipse>
        <SearchInput
          placeholder="Rechercher..."
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </SearchArea>
    </BrowserContainer>
  );
};

export default FilteringBrowser;
