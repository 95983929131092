// styles.js
import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 80%;
  margin-left: 15%;
  margin-top: -2%;
  position: relative;
  border-radius: 10px;
  background-color: #e6e6e6; /* Background bar */
  height: 16px;
  min-height: 16px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.width}%;
  background-color: #ff2768; /* Primary fill */
  border-radius: 10px;
  transition: width 0.6s ease-in-out;
  position: relative; /* Allows SubBar to be positioned relative to this */
`;

export const SubBar = styled.div`
  position: absolute;
  top: 40%;
  left: 18px;
  right: 18px;
  transform: translateY(-50%);
  height: 5px;
  background-color: rgba(255, 142, 176, 0.7);
  border-radius: 10px;
`;
