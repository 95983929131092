import React, { useState } from 'react';
import {
  WelcomeMenuWrapper,
  AppLogo,
  WelcomeDescription,
  ExampleBox,
  ExamplesContainer,
  ContentWrapper,
  ExampleContent,
  IconWrapper
} from './styles';
import appLogo from '../../../assets/meo_compass_v2.png';
import BourseIcon from '../../../assets/bourse_icon.png';
import FacIcon from '../../../assets/fac_icon.png';
import MedecinIcon from '../../../assets/medecine_icon.png';
import LogementIcon from '../../../assets/logement_icon.png';
import CompareIcon from '../../../assets/comparer_icon.png';

const WelcomeMenu = ({ onExampleClick, isSideMenuOpen, isMobile }) => {
  const [imageError, setImageError] = useState(false);

  const examples = [
    { text: 'Bourse', icon: BourseIcon },
    { text: "C'est quoi la fac ?", icon: FacIcon },
    { text: 'Compare X et Y', icon: MedecinIcon },
    { text: 'Logement', icon: LogementIcon },
    { text: 'PASS, LAS, P0, je suis perdu...', icon: CompareIcon }
  ];

  const handleImageError = () => {
    console.error('Failed to load the image');
    setImageError(true);
  };

  return (
    <WelcomeMenuWrapper isSideMenuOpen={isSideMenuOpen} isMobile={isMobile}>
      <ContentWrapper>
        {!imageError && (
          <AppLogo src={appLogo} alt="App Logo" onError={handleImageError} />
        )}
        <WelcomeDescription>
          Je suis Meo. Pose-moi tes questions sur l'orientation et je ferai tout mon possible pour y répondre.
        </WelcomeDescription>
        <ExamplesContainer isMobile={isMobile}>
          {examples.map((example, index) => (
            <ExampleBox
              key={index}
              onClick={() => onExampleClick(example.text)}
              isMobile={isMobile}
            >
              <IconWrapper>
                <img src={example.icon} alt="" />
              </IconWrapper>
              <ExampleContent>{example.text}</ExampleContent>
            </ExampleBox>
          ))}
        </ExamplesContainer>
      </ContentWrapper>
    </WelcomeMenuWrapper>
  );
};

export default WelcomeMenu;