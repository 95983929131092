import styled from 'styled-components';
import theme from '../../theme';

export const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 349px;
  padding: 16px;
  background: ${theme.colors.background};
  border-radius: 10px;
  position: fixed;
  top: 100px;
  bottom: 20px;
  overflow-y: auto;
  z-index: 1000;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #DDD;
    border-radius: 3px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;
  display: ${props => props.show ? 'block' : 'none'};
`;

export const UnauthenticatedMessage = styled.p`
  margin-top: 100%;
  text-align: center;
  color: ${theme.colors.text};
  cursor: pointer;
  transition: color 0.2s ease;
  max-width: 80%;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const HistoryTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.text};
`;

export const HistoryItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
  padding: 12px;
  background: #FFF;
  border: 1px solid #DDD;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
`;

export const DateText = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  color: #FFC1C1;
  font-family: Gabarito;
  font-size: 11px;
  font-weight: 400;
`;

export const RenameInput = styled.input`
  position: absolute;
  bottom: 30%;
  left: 15%;
  right: 60px;
  padding: 3px;
  border-radius: 6px;
  font-family: Gabarito;
  font-size: 15px;
  
  &:focus {
    border-color: #FFC1C1;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #FFC1C1;
  margin-bottom:-7%;

`;

export const DiscussionName = styled.span`
  position: absolute;
  bottom: 32%;
  left: 13%;
  color: #000;
  font-family: Gabarito;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;