import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import rehypeExternalLinks from 'rehype-external-links'
import WelcomeMenu from '../WelcomeMenu';
import { FaRegPenToSquare } from "react-icons/fa6";
import PopupGlobaleOneChoice from '../PopupGlobaleOneChoice';

import {
  Container,
  ChatContainer,
  ChatBox,
  ChatHistory,
  Message,
  InputArea,
  Input,
  LoadingMessage,
  LoadingText,
  NewChatIconButton,
  SendButton,
  MarkdownBox,
  InputAreaWrapper,
  SourceLink,
  SourcesContainer,
  WelcomeMenuWrapper,
  HistoryIconButton,
  SubtitleText

} from './styles';
import { GrHistory } from "react-icons/gr";

import { FaArrowUp } from "react-icons/fa";
import { useAuth } from '../../contexts/AuthContext';
import MobileHistoryTab from '../MobileHistoryTab';
import useDeviceType from '../useDeviceType';
import { useChat } from '../../contexts/ChatContext';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatDiscussion = forwardRef(({ onStartNewChat, toggleMobileHistory }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const chatHistoryRef = useRef(null);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingMessageRef = useRef(null);
  const { isMobile } = useDeviceType();
  const isUnmounting = useRef(false);
  const lastMessageRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const [userInterrupted, setUserInterrupted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [popupMessage, setPopupMessage] = useState('');

  const {
    chatHistory, setChatHistory,
    discussion, setDiscussion,
    inputText, setInputText,
    showWelcomeMenu, setShowWelcomeMenu,
    resetChat,
    loadDiscussionState
  } = useChat();

  useEffect(() => {
    const handleScroll = () => {
      if (!chatHistoryRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
      
      // Detect user scroll during streaming
      if (isLoading || streamingMessage) {
        setUserInterrupted(!isNearBottom);
      }
      
      setAutoScroll(isNearBottom);
    };
  
    const currentRef = chatHistoryRef.current;
    currentRef?.addEventListener('scroll', handleScroll);
    return () => currentRef?.removeEventListener('scroll', handleScroll);
  }, [isLoading, streamingMessage]); // Add dependencies


  useEffect(() => {
    if (chatHistoryRef.current && autoScroll && !userInterrupted) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [chatHistory, streamingMessage, isLoading, autoScroll, userInterrupted]); // Add userInterrupted

  useEffect(() => {
    isUnmounting.current = false;
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    const hasLoaded = sessionStorage.getItem('chatHasLoaded');
    if (!hasLoaded) {
      sessionStorage.setItem('chatHasLoaded', 'true');
    }
  }, []);

// Replace the existing scroll-to-bottom useEffect with this
useEffect(() => {
  if (chatHistoryRef.current && autoScroll) {
    chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  }
}, [chatHistory, streamingMessage, isLoading, autoScroll]);

  useImperativeHandle(ref, () => ({
    resetChat: handleStartNewChat,
    loadDiscussion: loadDiscussion
  }));

  const loadDiscussion = useCallback(async (discussionId) => {
    console.log("ChatHistory is rendering");
    setIsLoading(true);
    setShowWelcomeMenu(false);

    try {

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inference/get_single_discussion/`,
        {
          withCredentials: true,               // <--- Add this to include cookies
          params: { discussion_id: discussionId },
        }
      );

      console.log('Response status:', response.status);
      console.log('Response data:', response.data);

      if (response.status === 200 && response.data) {
        const { messages, last_context, begin_date } = response.data;

        const formattedMessages = messages.map(msg => ({
          user: msg.is_user ? 'You' : 'Bot',
          message: msg.content
        }));

        loadDiscussionState({
          id: discussionId,
          messages: formattedMessages,
          contextHistory: last_context,
          beginDate: begin_date
        });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error loading discussion:', error);
      setChatHistory(prevHistory => [...prevHistory, {
        user: 'Bot',
        message: "Error loading discussion. Please try again."
      }]);
    } finally {
      setIsLoading(false);
    }
  }, [setChatHistory, loadDiscussionState, setShowWelcomeMenu]);


  useEffect(() => {
    const handleLocationStateChange = async () => {
      if (location.state?.selectedDiscussion) {
        const discussionId = location.state.selectedDiscussion.id;
        await loadDiscussion(discussionId);

        // Clear the location state after loading
        navigate(location.pathname, {
          replace: true,
          state: {}
        });
      }
    };

    handleLocationStateChange();
  }, [location.state, loadDiscussion, navigate, location.pathname]);

  const toggleHistory = useCallback(() => {
    setIsMobileHistoryOpen(prev => !prev);
  }, []);
  
  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 150);
      inputRef.current.style.height = `${newHeight}px`;

      // Adjust the textarea's position within its container
      inputRef.current.style.top = `${150 - newHeight}px`;
    }
  };



  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputText(newValue);
    adjustTextareaHeight();
  };

  const handleStartNewChat = useCallback(() => {
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }
    resetChat();
  }, [isAuthenticated, resetChat]);

  useEffect(() => {
    if (location.state?.selectedDiscussion) {
      loadDiscussion(location.state.selectedDiscussion.id);
      setShowWelcomeMenu(false);
    }
  }, [location.state, loadDiscussion, setShowWelcomeMenu, location.pathname]);

// Remove this line:
// const [counter, setCounter] = useState(0);

useEffect(() => {
  // Reset popup first
  setShowPopup(false);

  if (messageCount === 5) {
    setPopupMessage("Il te reste 5 messages aujourd'hui. Fais en bon usage ! \n Orientant, Meo");
    setShowPopup(true);
  } else if (messageCount >= 10) {
    setPopupMessage("Tu as épuisé tes messages du jour, à demain ! \n Orientant, Meo");
    setShowPopup(true);
  }
}, [messageCount]); // Still depends on messageCount

  const handleExampleClick = (example) => {
    setInputText(example);
    handleSubmit(null, example);
  };


  const extractLinks = (markdown) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const links = [];
    let match;

    while ((match = linkRegex.exec(markdown)) !== null) {
      // Only use the text within brackets
      const displayText = match[1].match(/\[(.*?)\]/)?.[1] || match[1];
      links.push({
        text: displayText,
        url: match[2]
      });
    }

    return links;
  };

  const handleSubmit = async (e, exampleText = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    console.log('[DEBUG] Initial chatHistory:', chatHistory);
  
    const messageToSend = exampleText || inputText.trim();
  
    if (!messageToSend) {
      alert('Please enter a valid message before sending.');
      return;
    }
  
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }
  
    setShowWelcomeMenu(false);
  
    // Add new user message to chat history
    const newUserMessage = { user: 'You', message: messageToSend };
    setChatHistory(prevHistory => {
      const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
      console.log('[DEBUG] Adding user message to history');
      return [...currentHistory, newUserMessage];
    });
  
    setInputText('');
    setIsLoading(true);
    setStreamingMessage('');
    setAutoScroll(true);
  
    let streamingCompleted = false;
    let receivedCount = null;
  
    try {
      let currentDiscussion = discussion;
  
      // Create new discussion if none exists
      if (!currentDiscussion) {
        try {
          console.log('[DEBUG] Creating new discussion');
          const newDiscussionResponse = await axios.post(
            `${REACT_APP_BACKEND_URL}/inference/start_new_discussion/`,
            { user_id: user.id },
            {
              withCredentials: true, // include the HttpOnly cookie
            }
          );
  
          if (newDiscussionResponse?.status === 200 && newDiscussionResponse?.data?.new_discussion_id) {
            currentDiscussion = newDiscussionResponse.data.new_discussion_id;
            console.log('[DEBUG] New discussion ID:', currentDiscussion);
            setDiscussion(currentDiscussion);
          } else {
            throw new Error('Invalid response for new discussion');
          }
        } catch (error) {
          console.error('[ERROR] Discussion creation failed:', error);
          throw error;
        }
      }
  
      console.log('[DEBUG] Sending to inference endpoint');
  
      // UPDATE: Include credentials so that the HttpOnly cookie is sent.
      const response = await fetch(`${REACT_APP_BACKEND_URL}/inference/inference_view/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',  // <<--- ADDED THIS LINE
        body: JSON.stringify({
          input: messageToSend,
          discussion: currentDiscussion,
          user_id: user.id,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 429 && errorData.error === 'rate_limit') {
          setMessageCount(10); // Force show limit exceeded message
          return; // Exit before processing stream
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';
  
      console.log('[DEBUG] Starting stream processing');
      while (true) {
        try {
          const { done, value } = await reader.read();
          console.log('[DEBUG] Stream chunk received, done:', done);
  
          if (done) {
            console.log('[DEBUG] Stream completed');
            streamingCompleted = true;
            
            // Save final message
            if (fullResponse.trim()) {
              console.log('[DEBUG] Saving final response');
              const links = extractLinks(fullResponse.trim());
              const newBotMessage = {
                user: 'Bot',
                message: fullResponse.trim(),
                sources: links
              };
  
              setChatHistory(prevHistory => {
                const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
                const updatedHistory = [...currentHistory, newBotMessage];
                console.log('[DEBUG] Updated chat history:', updatedHistory);
                return updatedHistory;
              });
            }
  
            // Update message count and show popup if needed
            if (receivedCount !== null) {
              console.log(`[DEBUG] Final message count: ${receivedCount}`);
              setMessageCount(receivedCount);
              if (receivedCount >= 5) {
                console.log('[DEBUG] Showing message limit popup');
                setShowPopup(true);
              }
            }
            
            setStreamingMessage('');
            break;
          }
  
          // Process stream chunks
          const chunk = decoder.decode(value, { stream: true });
          console.log('[DEBUG] Raw chunk:', chunk);
  
          const lines = chunk.split('\n');
          console.log('[DEBUG] Processing', lines.length, 'lines');
  
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(5));
                console.log('[DEBUG] Parsed data:', data);
  
                if (data?.chunk) {
                  console.log('[DEBUG] Received text chunk:', data.chunk);
                  fullResponse += data.chunk;
                  setStreamingMessage(prev => {
                    const newMessage = prev + data.chunk;
                    console.log('[DEBUG] Streaming message update:', newMessage);
                    return newMessage;
                  });
                }
  
                if (data?.message_count !== undefined) {
                  console.log('[DEBUG] Received message count:', data.message_count);
                  receivedCount = data.message_count;
                }
              } catch (error) {
                console.error('[ERROR] Failed to parse chunk:', error);
                console.error('[ERROR] Problematic line:', line);
              }
            }
          }
        } catch (error) {
          console.error('[ERROR] Stream processing failed:', error);
          throw error;
        }
      }
    } catch (error) {
      console.error('[ERROR] Inference failed:', error);
      if (error.message.includes('429') || error.message.includes('rate_limit')) {
        setMessageCount(10);
        // Remove optimistically added user message
        setChatHistory(prev => prev.slice(0, -1));
      } else if (!streamingCompleted) {
        console.log('[DEBUG] Adding error message to chat history');
        setChatHistory(prevHistory => {
          const currentHistory = Array.isArray(prevHistory) ? prevHistory : [];
          return [...currentHistory, {
            user: 'Bot',
            message: "Je suis momentanément indisponible. Veuillez réessayer plus tard. Nous réparons cette erreur au plus vite.\n\nMEORIA, le staff ;)"
          }];
        });
      }
    } finally {
      console.log('[DEBUG] Cleaning up after submission');
      setIsLoading(false);
    }
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
        <Container isMobile={isMobile}>

        {isMobile && (
  <MobileHistoryTab
    isOpen={isMobileHistoryOpen}
    onClose={toggleHistory}
  />
)}
<PopupGlobaleOneChoice 
        isVisible={showPopup}
        message={popupMessage}
        onClose={() => setShowPopup(false)}
      />

<>
  <NewChatIconButton onClick={handleStartNewChat}>
    <FaRegPenToSquare  />
  </NewChatIconButton>

  <HistoryIconButton onClick={toggleHistory}>
  <GrHistory />

  </HistoryIconButton>
</>

          <ChatContainer
            showWelcomeMenu={showWelcomeMenu}
            isMobile={isMobile}
          >
            <ChatBox>
              <ChatHistory ref={chatHistoryRef}>
              {showWelcomeMenu ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                marginTop: '-2vh'  // Negative margin will pull it up
                // or you could use paddingTop: '-15vh'
              }}>
                <WelcomeMenuWrapper
                  isMobile={isMobile}
                >
                  <WelcomeMenu
                    onExampleClick={handleExampleClick}
                    isMobile={isMobile}
                  />
                </WelcomeMenuWrapper>
              </div>
            ) : (
                  <>
                    {chatHistory.map((chat, index) => (
                      <React.Fragment key={index}>
                        {chat.user === 'Bot' && chat.sources && chat.sources.length > 0 && (
                          <SourcesContainer>
                            {chat.sources.map((source, sourceIndex) => (
                              <SourceLink
                                key={sourceIndex}
                                href={source.url}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                              >
                                {source.text}
                              </SourceLink>
                            ))}
                          </SourcesContainer>
                        )}
                       <Message 
                            $user={chat.user} 
                            data-user={chat.user}
                            ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                          >
                          <MarkdownBox>
                            <ReactMarkdown
                              remarkPlugins={[[remarkGfm]]}
                              rehypePlugins={[
                                [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                              ]}
                            >
                              {chat.message}
                            </ReactMarkdown>
                          </MarkdownBox>
                        </Message>
                      </React.Fragment>
                    ))}
                    {streamingMessage && (
                      <Message $user="Bot" ref={streamingMessageRef}>
                        <MarkdownBox>
                          <ReactMarkdown
                            remarkPlugins={[[remarkGfm]]}
                            rehypePlugins={[
                              [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                            ]}
                          >
                            {streamingMessage}
                          </ReactMarkdown>
                        </MarkdownBox>
                      </Message>
                    )}
                    {isLoading && !streamingMessage && (
                      <LoadingMessage>
                        <LoadingText>Meo réfléchit</LoadingText>
                      </LoadingMessage>
                    )}
                  </>
                )}
              </ChatHistory>
            </ChatBox>
          </ChatContainer>

          

          <InputAreaWrapper
            isMobile={isMobile}
            showWelcomeMenu={showWelcomeMenu}
            isDisabled={messageCount >= 10}

          >
            <SubtitleText>
            Meo s'entraîne toujours, il peut faire des erreurs. Aidez-nous à l'améliorer.
          </SubtitleText>
            <InputArea
              as="form"
              onSubmit={handleSubmit}
              isMobile={isMobile}
            >
              <Input
                ref={inputRef}
                as="textarea"
                value={inputText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Message à Meo..."
                disabled={isLoading}
                rows={1}
              />
              <SendButton
                type="submit"
                disabled={isLoading}
                show={inputText.trim().length > 0}
              >
                <FaArrowUp size={16} />
              </SendButton>
            </InputArea>
          </InputAreaWrapper>

        </Container>
    </>
  );
});

export default ChatDiscussion;