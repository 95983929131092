// src/components/QuestionManager/styles.js
import styled from 'styled-components';
import theme from '../../theme';
import { Clock, BookOpen, Briefcase, Globe, Calendar, Target } from 'lucide-react';
import { LuCross } from 'react-icons/lu';
import { Heart } from 'lucide-react';

export const CrossIcon = styled(LuCross)`
  transform: rotate(45deg);
  color: red;
  width: 32px;
  height: 32px;
`;

export const HeartIconGreen = styled(Heart)`
  color: green;
  width: 32px;
  height: 32px;
`;

// Original category colors (for the bottom section)
export const categoryColors = {
  'Charge de travail': theme.colors.violet,
  'Environnement académique': theme.colors.vert,
  'Immersion professionnelle': theme.colors.gris_clair_clair,
  'International': theme.colors.orange,
  'Organisation': theme.colors.bleu_fonce,
  'Projets futurs': theme.colors.jaune,
};

// A darker version of each category color (for the top section)
export const categoryColorsDark = {
  'Charge de travail': theme.colors.violet_fonce,
  'Environnement académique': theme.colors.vert_fonce,
  'Immersion professionnelle': theme.colors.gris_clair,
  'International': theme.colors.orange_fonce,
  'Organisation': theme.colors.bleu_tres_fonce,
  'Projets futurs': theme.colors.jaune_clair,
};

export const categoryIcons = {
  'Charge de travail': Clock,
  'Environnement académique': BookOpen,
  'Immersion professionnelle': Briefcase,
  'International': Globe,
  'Organisation': Calendar,
  'Projets futurs': Target,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 0;
  z-index: 1602;
  overflow: hidden;
  background: ${theme.colors.background};
`;


export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.small};
  margin-bottom: ${theme.spacing.medium};
  position: sticky;
  top: 0;
  background: ${theme.colors.background};
  z-index: 10;
  border-bottom: 1px solid ${theme.colors.background_fonce};
  height: 15vh;
`;

export const LogoImage = styled.img`
  height: 150px;
  width: auto;
  margin-bottom: 0;
  margin-top: -8%;
  object-fit: contain;
`;

export const Title = styled.h1`
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
  text-align: center;
  margin: 0 0 ${theme.spacing.small};
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${theme.spacing.medium};
  /* minimal custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.gris_clair} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background_fonce};
    border-radius: 2px;
  }
`;

/**
 * The main container for a single question card.
 * We use a vertical flex layout so the top is ~30% height, bottom is ~70%.
 */
export const QuestionCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px; /* Enough to visualize the 30/70 split */
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: ${theme.spacing.large};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

// In styles.js

export const CardTop = styled.div`
  flex: 0 0 30%;
  background-color: ${({ category }) =>
    categoryColorsDark[category] || theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.medium};
`;


/** Category name or any text in the top area */
export const CategoryText = styled.h2`
  color: ${theme.colors.white};
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.small};
  text-transform: uppercase;
  margin: 0;
  margin-left: 10px;
`;

export const RedCrossIcon = styled(LuCross)`
  transform: rotate(45deg);
  /* For lucide / react-icons with strokes: */
  stroke: red;
  fill: red;
  /* If you want the cross thicker or thinner, adjust stroke-width: 
   * stroke-width: 2; 
   */
`;

export const GreenHeartIcon = styled(Heart)`
  stroke: #0BDB22;
  fill: #0BDB22;
`;


// styles.js

export const AnswerIconButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex; 
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.3);
  }
  &:active {
    transform: scale(0.8);
  }

  /* Force distinct sizes for React icons vs. PNG images */
  svg {
    width: 32px;
    height: 32px;
    /* fill / stroke as needed */
  }

  img {
    max-width: 32px !important;
    max-height: 32px !important;
    width: 32px !important;
    height: 32px !important;
    object-fit: contain;
    display: block;
  }
`;



export const CardBottom = styled.div`
  flex: 1;
  background-color: ${({ category }) =>
    categoryColors[category] || theme.colors.primary};
  padding: ${theme.spacing.medium};

  /* Lay out icon + question text side by side, 
     or stack them as you prefer */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.small};
`;

/** Category icon (white) in the bottom section */
export const CategoryIconWrapper = styled.div`
  color: ${theme.colors.white};
  display: flex;
  align-items: center;

  svg {
    width: 28px;
    height: 28px;
  }
`;

/** The actual question text */
export const QuestionText = styled.p`
  font-family: 'Gabarito', sans-serif;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.medium};
  margin: 0;
  flex: 1; /* so it uses the remaining space next to the icon */
`;

export const AnswerEmoji = styled.div`
  margin-left: ${theme.spacing.medium};
  color: #fff;
  
  /* Force images and SVG to a set size here */
  img{
    max-width: 32px;
    max-height: 32px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  },
  svg {
    max-width: 32px;
    max-height: 32px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

/**
 * Expanded panel that appears below everything if the card is clicked.
 */
export const ExpandedPanel = styled.div`
  background: ${theme.colors.background};
  padding: ${theme.spacing.small};
  border-top: 1px solid ${theme.colors.background_fonce};
`;

export const AnswerOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem; /* Increase spacing as needed */
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  background: ${theme.colors.background};
`;

export const AnswerOptionButton = styled.button`
  flex: 1;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 8px;
  padding: ${theme.spacing.small};
  font-size: ${theme.fontSizes.medium};
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: ${theme.colors.bleu};
    color: ${theme.colors.white};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  }
`;

export const RestartButton = styled.button`
  position: fixed;
  top: ${theme.spacing.large};
  right: ${theme.spacing.large};
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.primary};
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 1604;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);

    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

export const ReturnButtonWrapper = styled.div`
  position: fixed;
  top: 1rem;
  left: 10rem;
  z-index: 20;
`;

// Add this new one for the restart button in top-right
export const RestartButtonWrapper = styled.div`
  top: 1rem;
  margin-right: 20rem;
  z-index: 20;
`;
// src/components/QuestionManager/styles.js

// Existing export and styles remain...

/** 
 * A semi-transparent dark overlay that covers the screen 
 * when the popup is open 
 */
export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 9999; /* Ensure it's on top */
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** 
 * The white popup container that appears in the middle. 
 */
export const PopupContent = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 80%;
  max-width: 400px;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0,0,0,0.2);
  text-align: center;
`;

/** 
 * The main message text in the popup 
 */
export const PopupMessage = styled.p`
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
  margin-bottom: 1.5rem;
`;

/** 
 * Container for the Yes/No buttons 
 */
export const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
`;

/** 
 * Style the Yes/No buttons 
 */
export const PopupButton = styled.button`
  flex: 1;
  max-width: 100px;
  background: ${theme.colors.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.small};
  transition: background 0.3s ease;

  &:hover {
    background: ${theme.colors.primary_hover || '#0066cc'};
  }
`;

export const PopupButtonYes = styled.button`
  flex: 1;
  max-width: 100px;
  background: ${theme.colors.background_fonce};
  color: ${theme.colors.primary};
  border-radius: 10px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.small};
  transition: background 0.3s ease, border-color 0.3s ease;

  &:hover {
    background: ${theme.colors.primary_hover || '#0066cc'};
  }
`;
