import styled from 'styled-components';
import theme from '../../theme';

// ==================================
// Container Wrappers
// ==================================
export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;

export const Container = styled.div`
  /* Base container styles */
  width: 100vw;
  height: calc(var(--vh, 1vh) * 80);
  // margin-top: calc(var(--vh, 1vh) * 10);
  padding: ${props => props.theme.spacing.large};
  position: relative;
  background-color: transparent;
`;

export const EmailLoginContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
`;

// ==================================
// Return / Next Buttons
// ==================================
export const ReturnButton = styled.button`
  position: fixed;
  top: ${theme.spacing.large};
  left: ${theme.spacing.large};
  background: transparent;
  border: none;
  cursor: pointer;
  color: #BAB8B8;
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    stroke: #BAB8B8;
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);
    svg {
      stroke: #BAB8B8;
    }
  }
`;

export const NextButton = styled(ReturnButton)`
  right: ${theme.spacing.large};
  left: auto;
  &:hover {
    transform: translateX(3px);
    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

// ==================================
// Logo (no animation now)
// ==================================
export const LogoImage = styled.img`
  display: block;
  height: 200px;
  margin: 0 auto;
  margin-top: calc(var(--vh, 1vh) * 1.3);
  /* Removed all hover/transition/animation to comply with "no animation" request */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
`;
export const LogoImageFirstLogin = styled.img`
  display: block;
  height: 20%;
  margin: 0 auto;
  margin-top: calc(var(--vh, 1vh) * 1.3);
  /* Removed all hover/transition/animation to comply with "no animation" request */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
`;
// ==================================
// Input Container & Styled Input
// (Matches the .rectangleDiv & .entrerVotreEmail requirements)
// ==================================
export const InputContainer = styled.div`
  width: 100%;
  // margin-top: -15%;
  position: relative;
  height: 72px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25); /* No visible shadow */
  border-radius: 15px;
  background-color: #fff;
  border: 2px solid #e6e6e6;
  box-sizing: border-box;
  margin-bottom: 16px; /* give a small gap below */
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  text-align: left;
  margin-left: 5%;

  font-size: 16px;
  font-family: "Gabarito", sans-serif;
  color: #000; /* typed text color can be black (or adjust as you want) */

  &::placeholder {
    color: #e6e6e6; 
  }
`;

// ==================================
// Button: "Envoyer un code de validation" or "Confirmer"
// Has two visual states (disabled vs enabled)
// ==================================
export const SendCodeButton = styled.button`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--vh, 1vh) * -7);

  width: 100%;
  border: none;
  border-radius: 15px;
  font-family: "Gabarito", sans-serif;
  font-size: 20px;
  text-align: center;
  cursor: pointer;

  /* Distinguish states: if disabled -> gray style; if enabled -> blue style */
  ${({ disabled }) => disabled ? `
    background-color: #efecec;
    color: #bab8b8;
    height: 53px;
    box-shadow: none;
  ` : `
    background-color: #41b6fb;
    color: #fff;
    height: 58px;
    box-shadow: 0px 4px 0px #1396e4;
  `}
`;

// ==================================
// Divider between email & social logins
// ==================================
export const Divider = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.medium};
  bottom: calc(var(--vh, 1vh) *  41);
`;

export const DividerLine = styled.span`
  flex: 1;
  height: 1px;
  background-color: ${theme.colors.noir};
  opacity: 0.3;
`;

export const DividerText = styled.span`
  color: ${theme.colors.noir};
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
  padding: 0 ${theme.spacing.small};
  opacity: 0.6;
`;

// ==================================
// Countdown Text (Vous pourrez renvoyer...)
// Matching .vousPourrezRenvoyerContainer
// ==================================
export const CountdownContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--vh, 1vh) * -10); /* Adjust this value to position lower or higher */
  font-size: 15px;
  font-family: "Gabarito", sans-serif;
  color: #bab8b8;
  text-align: center;
  height: 20px;
`;

export const CountdownText = styled.p`
  margin: 0;
  white-space: pre-line;
`;


// ==================================
// Alert Overlay
// ==================================
export const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const AlertBox = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const AlertMessage = styled.p`
  margin: 0 0 20px 0;
  color: #ef4444;
  font-size: 16px;
`;

export const AlertButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #dc2626;
  }
`;

// ==================================
// Google and Apple Buttons
// Matching requested .rectangleDiv for each
// ==================================
export const GoogleButton = styled.button`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--vh, 1vh) * 30);

  width: 90%;
  height: 53px;
  border-radius: 15px;
  background-color: #fff;
  border: 2px solid #e6e6e6;
  box-sizing: border-box;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoogleButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const GoogleText = styled.span`
  font-size: 20px;
  font-family: "Gabarito", sans-serif;
  color: #0611a3;
  text-align: center;
  display: inline-block;
  height: 20px;
  margin-bottom: 6px;
  font-weight: ${theme.fontWeights.medium};
`;

export const AppleButton = styled.button`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--vh, 1vh) * 20);

  width: 90%;
  height: 53px;
  border-radius: 15px;
  background-color: #000;
  border: 2px solid #fff;
  box-sizing: border-box;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppleButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const AppleIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const AppleText = styled.span`
  font-size: 20px;
  font-family: 'Gabarito', sans-serif;
  color: #fff;
  text-align: center;
  display: inline-block;
  height: 20px;
  margin-bottom: 6px;
  font-weight: ${theme.fontWeights.medium};
`;

export const Subtitle = styled.span`
  color: ${props => props.theme.colors.noir};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${theme.fontWeights.semiBold};
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 300px; /* Match input width */
  text-align: left; /* Align text to left */
`;

