import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Import assets
import MeoriaManagerLogo from '../../../assets/meoria_manager_bleu.png';
import MeoriaManagerScreen from '../../../assets/meoria_manager_screen.png';
import ExempleEsve from '../../../assets/exemple_esve.png';
import DebouchesIcon from '../../../assets/debouches_icon.png';
import LeFigaro from '../../../assets/lefigaro.png';
import FranceInter from '../../../assets/france_inter.png';
import HugoDecrypte from '../../../assets/hugodecrypte.png';
import France3 from '../../../assets/france3.jpg';
import Mvp from '../../../assets/mvp.png';
import MeoriaManagerAnalytics from '../../../assets/meoria_manager_analitycs_desktop.png';

// Import styles
import {
  Container,
  Header,
  HeaderLogo,
  DemoButton,
  LoginHeaderButton,
  MainContent,
  HeroSection,
  HeroText,
  HeroImage,
  HeroImageContainer,
  ExampleTextOverlay,
  ExampleText,
  ExampleTextBold,
  TopExampleTextOverlay,
  TopExampleText,
  TopExampleTextBold,
  CircleContainer,
  CircleTitle,
  CirclesWrapper,
  OuterCircle,
  InnerCircle,
  IconContainer,
  IconImage,
  QuoteText,
  SecondQuoteText,
  FeatureSection,
  FeatureImage,
  FeatureContent,
  FeatureDescription,
  AnalyticsTitle,
  AnalyticsSubtitle,
  CTASection,
  FooterBox,
  FooterColumn,
  FooterSubtitle,
  FooterLink,
  SocialIconsRow,
  BottomSpacing
} from './ManagerStyles';

// Import social media icons
import InstagramIcon from '../../../assets/insta_blanc.png';
import TiktokIcon from '../../../assets/tiktok_blanc.png';
import LinkedinIcon from '../../../assets/linkedin_blanc.png';
import MeoFamily from '../../../assets/meos.png';

const LandingPageMeoriaManager = () => {
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Navigate to Calendly
  const goToCalendly = () => {
    window.scrollTo(0, 0);
    window.open("https://calendly.com/dimittrichoudhury/30min", "_blank");
  };

  // Navigate to login
  const goToLogin = () => {
    window.scrollTo(0, 0);
    navigate('/manager-login');
  };

  // Navigate to main landing page
  const goToMainLanding = () => {
    window.scrollTo(0, 0);
    navigate('/');
  };

  // Navigate to demo
  const handleDemoClick = () => {
    window.scrollTo(0, 0);
    window.open("https://calendly.com/dimittrichoudhury/30min", "_blank");
  };

  return (
    <Container>
      <Header>
        <HeaderLogo 
          src={MeoriaManagerLogo} 
          alt="Meoria Manager" 
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/manager');
          }}
          style={{ cursor: 'pointer' }}
        />
        <div style={{ display: 'flex', gap: '20px' }}>
          <LoginHeaderButton onClick={goToLogin}>
            Se connecter
          </LoginHeaderButton>
          <DemoButton onClick={goToCalendly}>
            Demander une démo
          </DemoButton>
        </div>
      </Header>
      
      <MainContent>
        <HeroSection>
          <HeroText>
            Captez les talents de demain en touchant les lycéens d'aujourd'hui
          </HeroText>
          <HeroImageContainer>
            <HeroImage src={ExempleEsve} alt="Exemple ESVE" style={{ position: 'absolute', bottom: '5%', left: '30%', maxWidth: '25%' }} />
            <TopExampleTextOverlay>
              <TopExampleText>Une</TopExampleText>
              <TopExampleTextBold>marketplace</TopExampleTextBold>
              <TopExampleText>pensée pour les écoles</TopExampleText>
              <img 
                src={DebouchesIcon} 
                alt="Débouchés" 
                style={{ 
                  position: 'absolute', 
                  right: '0px', 
                  top: '0%', 
                  transform: 'translateY(-50%)',
                  width: '35px',
                  height: '35px'
                }} 
              />
            </TopExampleTextOverlay>
            <ExampleTextOverlay>
              <ExampleText>Devenez</ExampleText>
              <ExampleTextBold>visible</ExampleTextBold>
              <ExampleText>devant +50 000 lycéens</ExampleText>
            </ExampleTextOverlay>
            <HeroImage src={MeoriaManagerScreen} alt="Meoria Manager Interface" style={{ marginLeft: '65%', maxWidth: '120%' }} />
          </HeroImageContainer>
        </HeroSection>
        
        <CircleContainer>
          <CircleTitle>Ils parlent de nous</CircleTitle>
          <CirclesWrapper>
            <OuterCircle />
            <InnerCircle />
            <IconContainer>
              <IconImage 
                src={LeFigaro} 
                alt="Le Figaro" 
                style={{ top: '-5%', left: '5%', width: '300px' }}
              />
              <IconImage 
                src={FranceInter} 
                alt="France Inter" 
                style={{ top: '-10%', right: '0%', width: '180px' }}
              />
              <IconImage 
                src={HugoDecrypte} 
                alt="Hugo Decrypte" 
                style={{ top: '45%', right: '-10%', width: '240px' }}
              />
              <IconImage 
                src={France3} 
                alt="France 3" 
                style={{ bottom: '-10%', right: '40%', width: '250px' }}
              />
              <IconImage 
                src={Mvp} 
                alt="MVP" 
                style={{ bottom: '10%', left: '-5%', width: '180px' }}
              />
            </IconContainer>
            <QuoteText>
              "Meoria veut rendre plus accessible l'accès à l'information post-bac en la personnalisant et en la rendant plus ludique. C'est un pari réussi avec près de 50 000 utilisateurs moins de trois mois après sa sortie !"
            </QuoteText>
            <SecondQuoteText>
              "Meo va devenir le meilleur ami des lycéens et des étudiants qui veulent trouver une formation qui leur correspond."
            </SecondQuoteText>
          </CirclesWrapper>
        </CircleContainer>

        <CTASection>
          <DemoButton className="wide" onClick={handleDemoClick}>
            Accéder à Meoria Manager
          </DemoButton>
        </CTASection>

        <FeatureSection>
          <FeatureImage src={MeoriaManagerAnalytics} alt="Meoria Manager Analytics" />
          <FeatureContent>
            <AnalyticsTitle>Analyse</AnalyticsTitle>
            <AnalyticsSubtitle>de vos campagnes</AnalyticsSubtitle>
            <FeatureDescription>
              Suivez vos campagnes de leads grâce à Meoria Analytics. Il est temps d'optimiser vos dépenses en acquisition.
            </FeatureDescription>
          </FeatureContent>
        </FeatureSection>

        <FeatureSection className="reverse">
          <FeatureImage src={ExempleEsve} alt="Page personnalisée" />
          <FeatureContent>
            <AnalyticsTitle>Votre page</AnalyticsTitle>
            <AnalyticsSubtitle>personnalisée</AnalyticsSubtitle>
            <FeatureDescription>
              Améliorez votre visibilité directement sur l'application Meoria en mettant en avant vos atouts.
            </FeatureDescription>
          </FeatureContent>
        </FeatureSection>

        <FeatureSection>
          <FeatureImage src={MeoriaManagerScreen} alt="Meoria Manager Interface" />
          <FeatureContent>
            <AnalyticsTitle>Trouvez les</AnalyticsTitle>
            <AnalyticsSubtitle>meilleurs leads</AnalyticsSubtitle>
            <FeatureDescription>
              Trouver des leads qualifiés n'aura jamais été aussi simple.
            </FeatureDescription>
          </FeatureContent>
        </FeatureSection>
      </MainContent>

      {/* Add the footer section before the closing Container tag */}
      <BottomSpacing />

      {/* Footer Box */}
      <FooterBox>
        {/* Left Column */}
        <FooterColumn>
          <FooterSubtitle>Meo est en règles</FooterSubtitle>
          <FooterLink
            href="https://meoria.com/politique.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Politique de confidentialité
          </FooterLink>
          <FooterLink
            href="https://meoria.com/CGU.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conditions d'utilisations
          </FooterLink>
        </FooterColumn>

        {/* Middle Column */}
        <FooterColumn>
          <FooterSubtitle style={{ marginBottom: '-60px' }}>
            Par l'équipe Meoria
            <br />
            &
          </FooterSubtitle>
          <img src={MeoFamily} alt="Meo Family" />
        </FooterColumn>

        <FooterColumn>
          <FooterSubtitle>Je suis un lycéen</FooterSubtitle>
          <LoginHeaderButton onClick={goToMainLanding}>
            Accéder à Meoria
          </LoginHeaderButton>
        </FooterColumn>

        {/* Right Column */}
        <FooterColumn>
          <FooterSubtitle>Retrouvez-nous ici</FooterSubtitle>
          <SocialIconsRow>
            <a
              href="https://www.instagram.com/meoria_app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@meoria_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TiktokIcon} alt="TikTok" />
            </a>
            <a
              href="https://www.linkedin.com/company/96219690/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinIcon} alt="LinkedIn" />
            </a>
          </SocialIconsRow>
        </FooterColumn>
      </FooterBox>
    </Container>
  );
};

export default LandingPageMeoriaManager;
