// Import styled and theme
import styled, { keyframes } from 'styled-components';
import theme from '../../theme';

/* 
  Container to hold the entire "Classement" page
*/
export const Container = styled.div`
  width: ${props => props.isMobile ? '95%' : 'calc(100vw - 320px)'};
  margin-left: ${props => props.isMobile ? '2.5%' : '320px'};
  min-height: 100vh;
  background-color: ${theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;


export const ButtonsRow = styled.div`
  position: sticky;
  top: 230px; 
  z-index: 1603;
  display: flex;
  justify-content: center;
  gap: 3rem;
  // margin: 1rem 0;
  background-color: ${theme.colors.background};
  // padding-bottom: 1.5rem;
`;


export const TabButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px; /* space for icon + text */
  width: 40%;
  height: 40px;
  min-height: 40px;
  position: relative;
  box-shadow: ${({ active }) =>
    active
      ? '0px 2px 2px rgba(56, 182, 255, 0.75)'
      : '0px 2px 2px rgba(217, 215, 215, 0.75)'};
  border-radius: 25px;
  background-color: ${({ active }) => (active ? '#dcf3ff' : '#fff')};
  border: 2px solid
    ${({ active }) =>
      active ? 'rgba(56, 182, 255, 0.47)' : 'rgba(217, 215, 215, 0.47)'};
  cursor: pointer;
  justify-content: center;
  font-size: 15px;
  font-weight: ${({ active }) => (active ? '600' : '500')};
  font-family: 'Gabarito', sans-serif;
  color: ${({ active }) => (active ? '#38b6ff' : '#000')};
  text-align: left;

`;



/*
  ContentWrapper can still be used for limiting max-width, etc.
*/
export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:auto;
`;

/*
  PodiumHeader is the section that includes the top image and possibly the share button
*/
export const PodiumHeader = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/*
  A container for the "Meo" trophy or top area image.
  We'll switch to a DIV with a background-image instead of an <img>.
*/
export const TopImage = styled.div`
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: none;
  margin-top: -2rem;
`;

/*
  Share button position (top-right)
*/
export const ShareButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 12;
`;

/*
  Scrollable area for the list of rankings
*/
export const ScrollableRankings = styled.div`
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  margin-top: 0%; // Adjust this value based on the height of your bottom menu
  padding-bottom: 250px;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 95%;
  align-items: center;
  margin-bottom: 60%;
  margin-left: 2.5%;
  
`;

/*
  Main Ranking Item box:
  1) 360px wide, 94px tall
  2) border-radius: 25px
  3) background: #FFF
  4) box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25)
*/
export const RankingItem = styled.div`
  width: 100%;
  height: 94px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  padding: 0 20px; /* Some horizontal padding */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  justify-content: space-between;
  &:active {
    transform: scale(0.98);
  }
`;

/*
  For the top 3, we show a trophy icon (30x30).
  It's just a div with a background image in JS code.
*/
export const TrophyIcon = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  margin-right: 10px;
`;

/*
  For the ranks > 3, we show a rank number
  w/ color #6E6E6E, font-size: 21px, etc.
*/
export const RankNumber = styled.span`
  width: 30px; /* Enough to hold 2 digits comfortably */
  text-align: center;
  color: #6e6e6e;
  font-family: 'Gabarito', sans-serif; /* or your chosen fallback */
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-right: 10px;
  flex-shrink: 0;
`;

export const FormationName = styled.div`
  width: 193px;
  position: relative;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium}; /* retains the current text size */
  font-style: normal;
  font-weight: 400;
  text-align: left;  /* added to align text left */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 10px;
`;

// Create a new component for the circle
export const ScoreCircle = styled.div`
  position: relative;
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// Update the ScoreValue styling to match Figma specs
export const ScoreValue = styled.div`
  color: ${props => props.color || '#28BB23'};
  font-family: Gabarito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// Add a heart icon component
export const HeartIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

// Update ScoreMatch for the new layout
export const ScoreMatch = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-family: Gantari;
  color: #28BB23;
  text-align: center;
  width: 100%;
  z-index: 1;
  margin-bottom: 2px;
`;

/* 
  PlaceholderItem while loading 
  - You can style to match the new design placeholders
*/

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export const PlaceholderLine = styled.div`
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${props => props.width || '70%'};
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const PlaceholderPercent = styled.div`
  width: 24px;
  height: 24px;
  background: #f0f0f0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

/*
  Modal styles remain similar to your previous approach
*/
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 1602;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  
`;

export const SlidingModal = styled.div`
  background: ${theme.colors.background};
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  position: absolute;
  bottom: 0;
  animation: slideUp 0.3s ease;

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  
`;

export const PlusButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;            /* Remove extra padding */
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    display: block;       /* Prevent extra inline space */
    width: auto;
    height: 10px;
    object-fit: contain;
    /* Sometimes a rotated element appears off-center.
       A small translateY adjustment can help. */
    transform: rotate(45deg) translateY(-1px);
  }
`;

export const PlusBox = styled.div`
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #fff;
  border: 0.1px solid #d6d2d2;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 5px; /* Add this to match FilterButton */
`;

// Conteneur pour la zone de recherche centrale
export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  position: relative;
  z-index: 1800; // Increased z-index to be above the modal (1700)
`;

// Add this to your styles.js file
export const ModalTopGap = styled.div`
  position: fixed;
  top: 70px; /* Adjust based on your search area's bottom position */
  left: 0;
  width: 100%;
  height: 40px; /* This should match the gap between search area and modal */
  background-color: white;
  z-index: 1750; /* Between the search area (1800) and modal (1700) */
`;

// Bouton pour ouvrir la modale de filtres (à l'intérieur du champ texte)
export const FilterModalButton = styled.button`
  position: absolute;
  right: 20px;
  top: 40%; // Adjust this value to center the button vertically
  transform: translateY(-50%);
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  background-color: #38B6FF;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200; // Increased z-index to make sure it appears on top
`;


export const FilterModalHeader = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom: 1px solid #eee;
  z-index: 1701;
  padding: 0 15px;

`;

// Add a new component for the modal title
export const FilterModalTitle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #38B6FF;
`;

// Conteneur de la modale de filtres
export const FilterModal = styled.div`
  position: fixed;
  top: 105px; // Adjusted to position right below the search area
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1700;
  padding: 20px; // Removed extra top padding since there's no header
  overflow-y: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

// Header fixe pour la modale
// export const FilterModalHeader = styled.div`
//   position: fixed;
//   top: 60px;
//   left: 0;
//   right: 0;
//   height: 50px;
//   background: white;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-top-left-radius: 25px;
//   border-top-right-radius: 25px;
//   border-bottom: 1px solid #eee;
//   z-index: 1701;
//   padding: 0 15px;
// `;

// Bouton pour effacer tous les filtres
export const ClearAllButton = styled.button`
  width: 135px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #EAEAEA;
  background: #FFF;
  color: #41B6FB;
  text-align: center;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

// Bouton pour fermer la modale
export const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #38B6FF;
  z-index: 1702;
`;

// Section de filtre dans la modale
export const FilterSection = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

// Titre de la section de filtre
export const FilterTitle = styled.div`
  font-family: 'Gabarito', sans-serif;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px; // Slightly larger
  font-weight: 600; // More prominent
  background: white;
  padding: 10px 0 5px 0;
`;

// Grille pour les options de filtre
export const FilterOptionsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

// Option de filtre individuelle
export const FilterOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  min-height: 36px;
  border-radius: 18px;
  background-color: ${props => props.selected ? '#DCF3FF' : '#f5f5f5'};
  border: ${props => props.selected ? '1px solid #38B6FF' : '1px solid transparent'};
  color: ${props => props.selected ? '#38B6FF' : '#333'};
  font-family: 'Gabarito', sans-serif;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.selected ? '#DCF3FF' : '#e9e9e9'};
  }
`;

// Icône du domaine
export const DomainIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

// Bouton pour voir plus/voir moins
export const SeeMoreButton = styled.button`
  background-color: transparent;
  color: #38B6FF;
  border: 1px solid #38B6FF;
  border-radius: 15px;
  padding: 6px 12px;
  margin: 5px auto 15px;
  font-family: 'Gabarito', sans-serif;
  font-size: 13px;
  cursor: pointer;
  display: block;
  text-align: center;
  
  &:hover {
    background-color: rgba(56, 182, 255, 0.1);
  }
`;
// Séparateur entre les sections de filtre
export const FilterSeparator = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 20px 0;
`;

// Conteneur pour la zone de recherche
export const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100; // Match with SearchContainer
  margin-bottom: 10px;
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 20px;
  padding: 15px 0;
  margin-top: 20px;
`;


export const ToggleTrack = styled.div`
  width: 32px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 25px;
  background: ${props => props.active ? '#38B6FF' : '#DCF3FF'};
  box-shadow: 1px 1px 1px 0px #C6C6C6;
  position: relative;
  cursor: pointer;
`;

export const ToggleThumb = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -2px;
  left: ${props => props.active ? '18px' : '-2px'};
  transition: left 0.2s;
`;

export const ToggleIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const ToggleText = styled.div`
  color: #3B3B3B;
  text-align: center;
  font-family: Gabarito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%;
`;

export const HeartIconClassement = styled.div`
   width: 24px;
   height: 24px;
   margin-left: 5px;
   cursor: pointer;
   position: relative;
   z-index: 10; // Add this to ensure it's above other elements
   
   img {
     width: 100%;
     height: 100%;
     object-fit: contain;
   }
`;


// Modifications pour styles.js
// Ajouter ces nouveaux composants pour la disposition verticale

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;
`;

export const CityFilterVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ToggleControlContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

// Modifier le ToggleLabel pour s'aligner correctement
export const ToggleLabel = styled.div`
  color: #C4C4C4;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%;
`;

export const CityFilterLabel = styled.div`
  color: #C4C4C4;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%;
`;

// Modifier le PrecisionToggle pour aligner les éléments horizontalement
export const PrecisionToggle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 20px;
`;