// styles.js
import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: ${theme.colors.background};
  padding: ${theme.spacing.medium};
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: flex-end; /* Push all children to the bottom */
  padding-bottom: ${theme.spacing.large}; /* Add extra bottom spacing if needed */
`;

export const LogoSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Spacing between the logo+subtitle and the buttons */
  margin-top: 30%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: ${theme.spacing.medium};
`;

export const LogoImage = styled.img`
  width: 40%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
`;

// Updated Title component
export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 30px;
  font-weight: ${theme.fontWeights.bold || 700};
  font-family: 'Londrina Solid', sans-serif;
  margin: 20px 0;
  text-align: center;
`;

export const TextContainer = styled.div`
  text-align: center;
  padding: ${theme.spacing.medium} 0;
`;

// Updated Subtitle component with media query to reduce gap on smaller screens
export const Subtitle = styled.h2`
  color: #8d8d8d;
  font-size: 18px;
  font-weight: ${theme.fontWeights.regular};
  font-family: 'Gabarito', sans-serif;
  margin: 0;
  line-height: 1.4;
  margin-top: 0%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-top: auto; /* This pushes the buttons to the bottom */
  margin-bottom: 0%;
`;

const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const SubmitButton = styled(BaseButton)`
  width: 90%;
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 15);
  left: 50%;
  transform: ${props =>
    props.active ? 'translateX(-50%) translateY(4px)' : 'translateX(-50%)'};
  max-width: 600px;
  border-radius: 15px;
  transition: all 0.15s ease; /* fast transition */

  height: 53px;
  background-color: ${props => (props.disabled ? '#efecec' : '#ff2768')};
  /* Remove shadow if the button is disabled OR pressed (active) */
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 4px 0px #d42359'};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #fff;
  text-align: center;
`;

export const SecondaryButton = styled(SubmitButton)`
  background: ${theme.colors.white};
  border: 2px solid rgba(217, 215, 215, 1);
  box-shadow: ${({ active }) =>
    active ? 'none' : '0px 2px 0px 0px rgba(217, 215, 215, 1);'};
  bottom: calc(var(--vh, 1vh) * 5);
`;

export const BottomButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #1db1f7;
  text-align: center;
`;
