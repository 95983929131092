import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import rehypeExternalLinks from 'rehype-external-links';
import WelcomeMenu from '../../Desktop/WelcomeMenu';
import PopupGlobaleOneChoice from '../../PopupGlobaleOneChoice';

import {
  Container,
  ChatContainer,
  ChatBox,
  ChatHistory,
  Message,
  InputAreaWrapper,
  InputArea,
  Input,
  SendButton,
  MarkdownBox,
  SourceLink,
  SourcesContainer,
  LoadingMessage,
  LoadingText,
  SubtitleText,
  WelcomeMenuWrapper
} from './styles';

import { FaArrowUp } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import { useChat } from '../../../contexts/ChatContext';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatDiscussion = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const chatHistoryRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [, setIsPopUpOpen] = useState(false);

  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingMessageRef = useRef(null);

  const isUnmounting = useRef(false);
  const lastMessageRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const [userInterrupted, setUserInterrupted] = useState(false);

  // Message limit popup
  const [showPopup, setShowPopup] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [popupMessage, setPopupMessage] = useState('');

  let streamingCompleted = false;

  const {
    user,
    isAuthenticated
  } = useAuth();

  const {
    chatHistory,
    setChatHistory,
    discussion,
    setDiscussion,
    inputText,
    setInputText,
    showWelcomeMenu,
    setShowWelcomeMenu,
    resetChat,
    loadDiscussionState
  } = useChat();

  useEffect(() => {
    // Keep track of unmount state to stop streaming if needed
    isUnmounting.current = false;
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  // Scroll logic
  useEffect(() => {
    const handleScroll = () => {
      if (!chatHistoryRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      const isNearBottom = scrollHeight - (scrollTop + clientHeight) < 100;

      // Detect user manual scroll away from bottom during streaming
      if (isLoading || streamingMessage) {
        setUserInterrupted(!isNearBottom);
      }
      setAutoScroll(isNearBottom);
    };

    const currentRef = chatHistoryRef.current;
    currentRef?.addEventListener('scroll', handleScroll);

    return () => currentRef?.removeEventListener('scroll', handleScroll);
  }, [isLoading, streamingMessage]);

  useEffect(() => {
    // Auto-scroll to bottom unless the user has scrolled away
    if (chatHistoryRef.current && autoScroll && !userInterrupted) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [chatHistory, streamingMessage, isLoading, autoScroll, userInterrupted]);

  // Make sure the discussion is loaded if a user clicked from history
  const loadDiscussion = useCallback(async (discussionId) => {
    setIsLoading(true);
    setShowWelcomeMenu(false);

    try {
      const response = await axios.get(
        `${REACT_APP_BACKEND_URL}/inference/get_single_discussion/`,
        {
          withCredentials: true,
          params: { discussion_id: discussionId },
        }
      );

      if (response.status === 200 && response.data) {
        const { messages, last_context, begin_date } = response.data;

        const formattedMessages = messages.map(msg => ({
          user: msg.is_user ? 'You' : 'Bot',
          message: msg.content
        }));

        loadDiscussionState({
          id: discussionId,
          messages: formattedMessages,
          contextHistory: last_context,
          beginDate: begin_date
        });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error loading discussion:', error);
      setChatHistory(prevHistory => [
        ...prevHistory,
        {
          user: 'Bot',
          message: "Error loading discussion. Please try again."
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  }, [
    setChatHistory,
    loadDiscussionState,
    setShowWelcomeMenu
  ]);

  useEffect(() => {
    if (location.state?.selectedDiscussion) {
      loadDiscussion(location.state.selectedDiscussion.id);
      setShowWelcomeMenu(false);

      // Clear the state so it won't auto-load again
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, loadDiscussion, navigate, setShowWelcomeMenu, location.pathname]);

  useImperativeHandle(ref, () => ({
    resetChat: handleStartNewChat,
    loadDiscussion: loadDiscussion
  }));

  const handleStartNewChat = useCallback(() => {
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }
    resetChat();
  }, [isAuthenticated, resetChat]);

  // Show a popup warning when the user is near or at the message limit
  useEffect(() => {
    setShowPopup(false);

    if (messageCount === 5) {
      setPopupMessage(
        "Il te reste 5 messages aujourd'hui. Fais en bon usage ! \nOrientant, Meo"
      );
      setShowPopup(true);
    } else if (messageCount >= 10) {
      setPopupMessage(
        "Tu as épuisé tes messages du jour, à demain ! \nOrientant, Meo"
      );
      setShowPopup(true);
    }
  }, [messageCount]);

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 150);
      inputRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const extractLinks = (markdown) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const links = [];
    let match;

    while ((match = linkRegex.exec(markdown)) !== null) {
      // The displayed text is match[1], the URL is match[2]
      links.push({
        text: match[1],
        url: match[2]
      });
    }

    return links;
  };

  const handleSubmit = async (e, exampleText = null) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const messageToSend = exampleText || inputText.trim();

    if (!messageToSend) {
      alert('Please enter a valid message before sending.');
      return;
    }

    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }

    setShowWelcomeMenu(false);

    // Add user message to the chat
    setChatHistory(prevHistory => [
      ...prevHistory,
      { user: 'You', message: messageToSend }
    ]);
    setInputText('');
    setIsLoading(true);
    setStreamingMessage('');
    setAutoScroll(true);

    let receivedCount = null;

    try {
      let currentDiscussion = discussion;

      // Create new discussion if none exists
      if (!currentDiscussion) {
        const newDiscussionResponse = await axios.post(
          `${REACT_APP_BACKEND_URL}/inference/start_new_discussion/`,
          { user_id: user.id },
          { withCredentials: true }
        );
        if (
          newDiscussionResponse?.status === 200 &&
          newDiscussionResponse?.data?.new_discussion_id
        ) {
          currentDiscussion = newDiscussionResponse.data.new_discussion_id;
          setDiscussion(currentDiscussion);
        } else {
          throw new Error('Invalid response for new discussion');
        }
      }

      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/inference/inference_view/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include', // important for sending HttpOnly cookie
          body: JSON.stringify({
            input: messageToSend,
            discussion: currentDiscussion,
            user_id: user.id
          })
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        // Rate limit
        if (response.status === 429 && errorData.error === 'rate_limit') {
          setMessageCount(10);
          // Remove the last (user) message from chat if it was "over limit"
          setChatHistory(prev => prev.slice(0, -1));
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Stream the response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';
      streamingCompleted = false;

      // Define the processing function outside the loop
      const processChunk = (text, currentFullResponse, currentReceivedCount) => {
        let updatedFullResponse = currentFullResponse;
        let updatedCount = currentReceivedCount;
        
        const lines = text.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));

              if (data?.chunk) {
                const chunk = data.chunk;
                updatedFullResponse += chunk;
                setStreamingMessage(prev => prev + chunk);
              }

              if (data?.count !== undefined) {
                updatedCount = data.count;
                receivedCount = updatedCount;
              }
            } catch (e) {
              console.error('Error parsing streaming JSON:', e);
            }
          }
        }
        return { updatedFullResponse, updatedCount };
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          streamingCompleted = true;
          // Save final response as a single bot message
          if (fullResponse.trim()) {
            const links = extractLinks(fullResponse.trim());
            setChatHistory(prev => [
              ...prev,
              {
                user: 'Bot',
                message: fullResponse.trim(),
                sources: links
              }
            ]);
          }

          // Update message count
          if (receivedCount !== null) {
            setMessageCount(receivedCount);
          }
          setStreamingMessage('');
          break;
        }

        // Parse the chunk
        const chunk = decoder.decode(value, { stream: true });
        const { updatedFullResponse } = processChunk(chunk, fullResponse, receivedCount);
        fullResponse = updatedFullResponse;
      }
    } catch (err) {
      console.error('Inference error:', err);
      // If there's an error during streaming, show a fallback message
      if (!streamingCompleted) {
        setChatHistory(prev => [
          ...prev,
          {
            user: 'Bot',
            message:
              "Je suis momentanément indisponible. Veuillez réessayer plus tard."
          }
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleExampleClick = (example) => {
    setInputText(example);
    handleSubmit(null, example);
  };

  return (
    <Container>
      {/* Limit usage popup */}
      <PopupGlobaleOneChoice
        isVisible={showPopup}
        message={popupMessage}
        onClose={() => setShowPopup(false)}
      />

      {/* Main chat area */}
      <ChatContainer>
        <ChatBox>
          <ChatHistory ref={chatHistoryRef}>
            {showWelcomeMenu ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  marginTop: '-2vh'
                }}
              >
                <WelcomeMenuWrapper>
                  <WelcomeMenu onExampleClick={handleExampleClick} />
                </WelcomeMenuWrapper>
              </div>
            ) : (
              <>
                {chatHistory.map((chat, index) => (
                  <React.Fragment key={index}>
                    {/* If the Bot message has sources */}
                    {chat.user === 'Bot' && chat.sources && chat.sources.length > 0 && (
                      <SourcesContainer>
                        {chat.sources.map((source, sourceIndex) => (
                          <SourceLink
                            key={sourceIndex}
                            href={source.url}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            {source.text}
                          </SourceLink>
                        ))}
                      </SourcesContainer>
                    )}
                    <Message
                      $user={chat.user}
                      ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                    >
                      <MarkdownBox>
                        <ReactMarkdown
                          remarkPlugins={[[remarkGfm]]}
                          rehypePlugins={[
                            [
                              rehypeExternalLinks,
                              { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }
                            ]
                          ]}
                        >
                          {chat.message}
                        </ReactMarkdown>
                      </MarkdownBox>
                    </Message>
                  </React.Fragment>
                ))}

                {/* Ongoing streaming message */}
                {streamingMessage && (
                  <Message $user="Bot" ref={streamingMessageRef}>
                    <MarkdownBox>
                      <ReactMarkdown
                        remarkPlugins={[[remarkGfm]]}
                        rehypePlugins={[
                          [
                            rehypeExternalLinks,
                            { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }
                          ]
                        ]}
                      >
                        {streamingMessage}
                      </ReactMarkdown>
                    </MarkdownBox>
                  </Message>
                )}

                {/* Loading indicator (only shown before streaming begins) */}
                {isLoading && !streamingMessage && (
                  <LoadingMessage>
                    <LoadingText>Meo réfléchit</LoadingText>
                  </LoadingMessage>
                )}
              </>
            )}
          </ChatHistory>
        </ChatBox>
      </ChatContainer>

      {/* Input area + subtitle */}
      <InputAreaWrapper isDisabled={messageCount >= 10}>
        <InputArea onSubmit={handleSubmit}>
          <Input
            ref={inputRef}
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Message à Meo..."
            disabled={isLoading}
            rows={1}
          />
          <SendButton
            type="submit"
            disabled={isLoading}
            show={inputText.trim().length > 0}
          >
            <FaArrowUp size={16} />
          </SendButton>
        </InputArea>

        <SubtitleText>
          Meo s'entraîne toujours, il peut faire des erreurs. Aidez-nous à l'améliorer.
        </SubtitleText>
      </InputAreaWrapper>
    </Container>
  );
});

export default ChatDiscussion;
