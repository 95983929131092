import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import apiCall from '../../utils/api';
import emptyHeartIcon from '../../assets/coeur_vide_rouge_icon.png';
import filledHeartIcon from '../../assets/coeur_icon.png';

import {
  PlaceholderLine,
} from '../DecouvrirFormations/styles';

import {
  ScoreValue,
  ScoreContainer,
  RankNumber,
  TrophyIcon,
  ScrollableRankings,
  ContentWrapper,
  FilterModal,
  BottomButtonContainer,
  CloseButton,
  ClearAllButton,
  FilterSection,
  FilterTitle,
  FilterOptionsGrid,
  FilterOption,
  FilterSeparator,
  DomainIcon,
  SeeMoreButton,
  ModalOverlay,
  ScoreCircle,
  HeartIconClassement
} from './styles';

import { domainIconMapRaw } from '../Onboarding/domainIconMap';

import TrophyGold from '../../assets/top1_icon.png';
import TrophySilver from '../../assets/top2_icon.png';
import TrophyBronze from '../../assets/top3_icon.png';
import { allDomains, allDurations, allFamilles_map_label } from './data';

const EmptyStateContainer = styled.div`
  padding:20px;
  text-align: center;
  font-family: Gabarito;
  font-size: 16px;
  color: #626060;
`;

const FilterLine = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const ClearFilterButton = styled.button`
  margin-left: 10px;
  background: #38B6FF;
  color: #fff;
  border: none;
  border-radius: 9px;
  padding: 4px 8px;
  cursor: pointer;
`;

const FilieresRankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 0%;
`;

const FiliereCard = styled.div`
  width: 95%;
  margin-left: 2.5%;
  height: 100px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
`;

const FiliereName = styled.div`
  color: #000;
  text-align: left;
  font-family: Gabarito;
  font-size: 16px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: 2.4em;
`;

const FiliereDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Gabarito;
  font-size: 14px;
  color: #626060;
  div:last-child {
    color: #888;
    font-size: 13px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilieresClassement = ({ searchKeyword = '', showFilterModal = false, setShowFilterModal = () => {} }) => {
  // Ajouter des keyframes pour l'animation
  useEffect(() => {
    // On crée un style dynamique pour l'animation
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes pulse {
        0% { transform: scale(1); }
        40% { transform: scale(1.3); }
        100% { transform: scale(1); }
      }
    `;
    document.head.appendChild(styleElement);
    
    // Nettoyage lors du démontage du composant
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // États pour les filtres sélectionnés
  const [selectedDomainsFormations, setSelectedDomainsFormations] = useState(() => {
    const stored = localStorage.getItem('selectedDomainsFormationsType');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedUniqueTypesFormations, setSelectedUniqueTypesFormations] = useState(() => {
    const stored = localStorage.getItem('selectedUniqueTypesFormationsType');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedDurationsFormations, setSelectedDurationsFormations] = useState(() => {
    const stored = localStorage.getItem('selectedDurationsFormationsType');
    return stored ? JSON.parse(stored) : [];
  });

  // État pour suivre quelle filière a sa pulsation en cours
  const [pulsatingHeartId, setPulsatingHeartId] = useState(null);
  
  // Add this SVG rendering function within your FilieresClassement component
  const renderScoreCircle = (score, index) => {
    const numericScore = Number(score) || 0;
    const clampedScore = Math.max(0, Math.min(100, numericScore));
    
    // Define progressive color gradient based on score
    // Color stops for progressive gradient (from red to yellow to green)
    const colorStops = [
      { value: 0, color: '#FF0000' },   // Red at 0%
      { value: 50, color: '#F1E424' },  // Yellow at 50%
      { value: 100, color: '#32EA2C' }  // Green at 100%
    ];
    
    // Find the two color stops to lerp between
    let lowerStop = colorStops[0];
    let upperStop = colorStops[colorStops.length - 1];
    
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (clampedScore >= colorStops[i].value && clampedScore <= colorStops[i + 1].value) {
        lowerStop = colorStops[i];
        upperStop = colorStops[i + 1];
        break;
      }
    }
    
    // Calculate interpolation factor
    const factor = (clampedScore - lowerStop.value) / (upperStop.value - lowerStop.value);
    
    // Helper function to interpolate between hex colors
    const lerpColor = (a, b, factor) => {
      const ah = parseInt(a.replace(/#/g, ''), 16);
      const ar = ah >> 16;
      const ag = (ah >> 8) & 0xff;
      const ab = ah & 0xff;
      
      const bh = parseInt(b.replace(/#/g, ''), 16);
      const br = bh >> 16;
      const bg = (bh >> 8) & 0xff;
      const bb = bh & 0xff;
      
      const rr = ar + factor * (br - ar);
      const rg = ag + factor * (bg - ag);
      const rb = ab + factor * (bb - ab);
      
      return `#${((1 << 24) + (Math.round(rr) << 16) + (Math.round(rg) << 8) + Math.round(rb)).toString(16).slice(1)}`;
    };
    
    // Calculate gradient colors
    const gradientStart = lerpColor(lowerStop.color, upperStop.color, factor);
    const gradientEnd = lerpColor(lowerStop.color, upperStop.color, Math.max(0, factor - 0.3)); // Slightly darker for end
    
    // Use start color for text
    const textColor = gradientStart;
    
    // Create a unique ID for this gradient
    const gradientId = `scoreGradient_${index}_${clampedScore}`;
    
    return {
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
          <circle cx="42.5" cy="42.5" r="37.5" stroke={`url(#${gradientId})`} strokeWidth="10"/>
          <defs>
            <linearGradient id={gradientId} x1="85" y1="42.5" x2="0" y2="42.5" gradientUnits="userSpaceOnUse">
              <stop stopColor={gradientStart}/>
              <stop offset="1" stopColor={gradientEnd}/>
            </linearGradient>
          </defs>
        </svg>
      ),
      textColor
    };
  };

  const toggleFavorite = async (e, filiere) => {
    e.stopPropagation();
    
    // Use is_favorite directly from the filiere object
    const newFavoriteStatus = !filiere.is_favorite;
    
    setPulsatingHeartId(filiere.nom_type);
    setTimeout(() => {
      setPulsatingHeartId(null);
    }, 600);
    
    const updatedRankings = {...rankingsFilieres};
    Object.keys(updatedRankings).forEach(key => {
      if (updatedRankings[key].nom_type === filiere.nom_type) {
        updatedRankings[key] = {...updatedRankings[key], is_favorite: newFavoriteStatus};
      }
    });
    setRankingsFilieres(updatedRankings);
    localStorage.setItem('rankings', JSON.stringify(updatedRankings));
    
    try {
      if (!newFavoriteStatus) {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_type_formation/`,
          'DELETE',
          { nom: filiere.nom_type }
        );
      } else {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_type_formation/`,
          'POST',
          { 
            nom: filiere.nom_type, 
            matchPercentage: filiere.score_formations 
          }
        );
      }

    } catch (error) {
      console.error('Error updating favorite:', error);
      
      // Revert on error
      const revertedRankings = {...rankingsFilieres};
      Object.keys(revertedRankings).forEach(key => {
        if (revertedRankings[key].nom_type === filiere.nom_type) {
          revertedRankings[key] = {...revertedRankings[key], is_favorite: !newFavoriteStatus};
        }
      });
      setRankingsFilieres(revertedRankings);
      localStorage.setItem('rankings', JSON.stringify(revertedRankings));
    }
  };

  // États pour les options "voir plus/voir moins"
  const [showAllDomains, setShowAllDomains] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);
  const [showAllDurations, setShowAllDurations] = useState(false);

  // Référence pour le modal
  const modalRef = useRef(null);

  // Sauvegarde des filtres dans le localStorage
  useEffect(() => {
    localStorage.setItem('selectedDomainsFormationsType', JSON.stringify(selectedDomainsFormations));
  }, [selectedDomainsFormations]);
  
  useEffect(() => {
    localStorage.setItem('selectedUniqueTypesFormationsType', JSON.stringify(selectedUniqueTypesFormations));
  }, [selectedUniqueTypesFormations]);
  
  useEffect(() => {
    localStorage.setItem('selectedDurationsFormationsType', JSON.stringify(selectedDurationsFormations));
  }, [selectedDurationsFormations]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showFilterModal && modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilterModal(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilterModal]);

  // Chargement des données
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rankingsFilieres, setRankingsFilieres] = useState({});
  const isFirstRender = useRef(true);

  const fetchFilieresRanking = async () => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams();
      
      if (selectedDomainsFormations.length) {
        params.append('domains', selectedDomainsFormations.join(','));
      }
      
      if (selectedUniqueTypesFormations.length) {
        params.append('familles', selectedUniqueTypesFormations.join(','));
      }
      
      if (selectedDurationsFormations.length) {
        params.append('durations', selectedDurationsFormations.join(','));
      }
      
      if (searchKeyword && searchKeyword.trim() !== "") {
        params.append('search_keyword_filiere', searchKeyword.trim());
      }
      
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/ranking/?${params.toString()}`,
        'GET'
      );

      if (response && response.rankings) {
        setRankingsFilieres(response.rankings);
        localStorage.setItem('rankings', JSON.stringify(response.rankings));
        localStorage.setItem('need_update_ranking', 'false');
}
    } catch (error) {
      console.error('Error fetching filieres rankings:', error);
      setRankingsFilieres({});
      localStorage.setItem('rankings', '{}');
      localStorage.setItem('need_update_ranking', 'false');
    } finally {
      setIsLoading(false);
    }
  };

  // Effet pour initialiser et mettre à jour les données
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Validation des filtres stockés
      for (const d of selectedDomainsFormations) {
        if (!allDomains.includes(d)) {
          setSelectedDomainsFormations((prev) => prev.filter((x) => x !== d));
        }
      }
      
      for (const t of selectedUniqueTypesFormations) {
        if (!Object.keys(allFamilles_map_label).includes(t)) {
          setSelectedUniqueTypesFormations((prev) => prev.filter((x) => x !== t));
        }
      }
      
      for (const dd of selectedDurationsFormations) {
        if (!allDurations.includes(dd)) {
          setSelectedDurationsFormations((prev) => prev.filter((x) => x !== dd));
        }
      }
      
      return;
    }
    
    localStorage.setItem('need_update_ranking', 'true');
    fetchFilieresRanking();
  }, [
    selectedDomainsFormations,
    selectedUniqueTypesFormations,
    selectedDurationsFormations,
    searchKeyword
  ]);

  useEffect(() => {
    const needUpdate = localStorage.getItem('need_update_ranking');
    if (needUpdate === 'true') {
      fetchFilieresRanking();
    } else if (needUpdate === 'false') {
      const stored = localStorage.getItem('rankings');
      if (stored) {
        setRankingsFilieres(JSON.parse(stored));
      } else {
        setRankingsFilieres({});
      }
      setIsLoading(false);
    } else {
      localStorage.setItem('need_update_ranking', 'true');
      fetchFilieresRanking();
    }
  }, []);

  // Conversion en tableau
  const topFilieresArray = Object.entries(rankingsFilieres);

  // Helpers pour le rendu
  const renderTrophyIcon = (rank) => {
    switch (rank) {
      case 1:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyGold})` }} />;
      case 2:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophySilver})` }} />;
      case 3:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyBronze})` }} />;
      default:
        return <RankNumber>{rank}</RankNumber>;
    }
  };
  
  // Fonction pour gérer le clic sur une option de filtre dans la modale
  const handleFilterOptionClick = (filterType, value) => {
    if (filterType === 'domains') {
      if (selectedDomainsFormations.includes(value)) {
        setSelectedDomainsFormations(selectedDomainsFormations.filter(item => item !== value));
      } else {
        setSelectedDomainsFormations([...selectedDomainsFormations, value]);
      }
    } else if (filterType === 'types') {
      if (selectedUniqueTypesFormations.includes(value)) {
        setSelectedUniqueTypesFormations(selectedUniqueTypesFormations.filter(item => item !== value));
      } else {
        setSelectedUniqueTypesFormations([...selectedUniqueTypesFormations, value]);
      }
    } else if (filterType === 'durations') {
      if (selectedDurationsFormations.includes(value)) {
        setSelectedDurationsFormations(selectedDurationsFormations.filter(item => item !== value));
      } else {
        setSelectedDurationsFormations([...selectedDurationsFormations, value]);
      }
    }
  };

  // Fonction pour effacer tous les filtres
  const handleClearAllFilters = () => {
    setSelectedDomainsFormations([]);
    setSelectedUniqueTypesFormations([]);
    setSelectedDurationsFormations([]);
    
    localStorage.setItem('need_update_ranking', 'true');
    fetchFilieresRanking();
    setShowFilterModal(false);
  };

  // Fonction pour obtenir l'icône de domaine ou un placeholder
  const getDomainIcon = (domainName) => {
    const icon = domainIconMapRaw[domainName];
    return icon || null;
  };

  return (
    <ContentWrapper>
      {/* Filter button without the search container */}

      {/* Modale de filtres */}
{showFilterModal && (
  <>
  <ModalOverlay onClick={() => setShowFilterModal(false)} />
  <FilterModal ref={modalRef}>
    {/* Remove FilterModalHeader and directly display the filters */}
    {/* Keep the clear and close buttons but reposition them */}
    <ClearAllButton onClick={handleClearAllFilters}>
      Réinitialiser
    </ClearAllButton>
    <CloseButton onClick={() => setShowFilterModal(false)}>×</CloseButton>
    
    {/* Section Domaine - First Filter */}
    <FilterSection>
      <FilterTitle>Domaine</FilterTitle>
      <FilterOptionsGrid>
        {allDomains
          .slice(0, showAllDomains ? allDomains.length : 10)
          .map((domain) => (
            <FilterOption
              key={domain}
              selected={selectedDomainsFormations.includes(domain)}
              onClick={() => handleFilterOptionClick('domains', domain)}
            >
              {getDomainIcon(domain) && (
                <DomainIcon src={getDomainIcon(domain)} alt={domain} />
              )}
              {domain}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {allDomains.length > 10 && (
        <SeeMoreButton onClick={() => setShowAllDomains(!showAllDomains)}>
          {showAllDomains ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
    
    <FilterSeparator />
    
    {/* Section Type */}
    <FilterSection>
      <FilterTitle>Type</FilterTitle>
      <FilterOptionsGrid>
        {Object.entries(allFamilles_map_label)
          .slice(0, showAllTypes ? Object.entries(allFamilles_map_label).length : 10)
          .map(([key, value]) => (
            <FilterOption
              key={key}
              selected={selectedUniqueTypesFormations.includes(key)}
              onClick={() => handleFilterOptionClick('types', key)}
            >
              {value}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {Object.entries(allFamilles_map_label).length > 10 && (
        <SeeMoreButton onClick={() => setShowAllTypes(!showAllTypes)}>
          {showAllTypes ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
    
    <FilterSeparator />
    
    {/* Section Durée */}
    <FilterSection>
      <FilterTitle>Durée</FilterTitle>
      <FilterOptionsGrid>
        {allDurations
          .slice(0, showAllDurations ? allDurations.length : 10)
          .map((duration) => (
            <FilterOption
              key={duration}
              selected={selectedDurationsFormations.includes(duration)}
              onClick={() => handleFilterOptionClick('durations', duration)}
            >
              {duration}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {allDurations.length > 10 && (
        <SeeMoreButton onClick={() => setShowAllDurations(!showAllDurations)}>
          {showAllDurations ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
  </FilterModal>
  </>
)}

      {/* Liste des classements ou message vide */}
      <ScrollableRankings>
        {isLoading ? (
          <FilieresRankingList>
            {Array.from({ length: 3 }).map((_, idx) => (
              <FiliereCard key={idx}>
                <div style={{ width: "100%" }}>
                  <PlaceholderLine width="90%" />
                  <PlaceholderLine width="60%" />
                </div>
              </FiliereCard>
            ))}
          </FilieresRankingList>
        ) : topFilieresArray.length > 0 ? (
          <FilieresRankingList>
            {topFilieresArray.map(([rankKey, item], index) => {
              const rank = parseInt(rankKey.replace("top", ""), 10);
              return (
                <FiliereCard key={item.id} onClick={() => {
                  const urlSafeId = item.nom_type.replace(/[ ']/g, '_');
                  if (!urlSafeId) {
                    alert('Formation ID missing');
                    return;
                  }
                  navigate(`/card-type-formation/${urlSafeId}`);
                }}>
                  {rank <= 3 ? renderTrophyIcon(rank) : <RankNumber>{rank}</RankNumber>}
                  <div style={{ width: "calc(100% - 170px)", flexGrow: 1 }}>
                    <FiliereName>{item.nom_type}</FiliereName>
                    <FiliereDetails>
                      {/* <div>BUT</div> */}
                      <div>{item.type || ''}</div>
                    </FiliereDetails>
                  </div>
                  <ScoreContainer>
                    <ScoreCircle>
                      {(() => {
                        const { svg, textColor } = renderScoreCircle(item.score_formations, index);
                        return (
                          <>
                            {svg}
                            <ScoreValue color={textColor}>{item.score_formations || 0}%</ScoreValue>
                          </>
                        );
                      })()}
                    </ScoreCircle>
                    <HeartIconClassement 
                      style={{
                        transform: pulsatingHeartId === item.nom_type ? 'scale(1.3)' : 'scale(1)',
                        transition: 'transform 0.3s ease-in-out'
                      }}
                    >
                      <img 
                        src={item.is_favorite ? filledHeartIcon : emptyHeartIcon} 
                        alt={item.is_favorite ? "Favorite" : "Add to favorites"}
                        style={{ 
                          cursor: 'pointer',
                          animation: pulsatingHeartId === item.nom_type ? 'pulse 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)' : 'none'
                        }}
                        onClick={(e) => toggleFavorite(e, item)}
                      />
                    </HeartIconClassement>
                  </ScoreContainer>
                </FiliereCard>
              );
            })}
          </FilieresRankingList>
        ) : (
          <EmptyStateContainer>
            <div>Aucune filière trouvée pour les filtres :</div>
            {searchKeyword.trim() !== "" && (
              <FilterLine>
                Recherche : {searchKeyword}
                <ClearFilterButton onClick={() => {
                  // We need to notify the parent component to update the searchKeyword
                  localStorage.setItem('searchKeywordType', '');
                  localStorage.setItem('need_update_ranking', 'true');
                  window.location.reload(); // A simple way to force update
                }}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedDomainsFormations.length > 0 && (
              <FilterLine>
                Domaines : {selectedDomainsFormations.slice(0, 5).join(" ou ")}
                {selectedDomainsFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedDomainsFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedUniqueTypesFormations.length > 0 && (
              <FilterLine>
                Types :{" "}
                {selectedUniqueTypesFormations
                  .slice(0, 5)
                  .map((key) => allFamilles_map_label[key])
                  .join(" ou ")}
                {selectedUniqueTypesFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedUniqueTypesFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedDurationsFormations.length > 0 && (
              <FilterLine>
                Durée : {selectedDurationsFormations.slice(0, 5).join(" ou ")}
                {selectedDurationsFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedDurationsFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
          </EmptyStateContainer>
        )}
      </ScrollableRankings>
    </ContentWrapper>
  );
};

export default FilieresClassement;