import styled from 'styled-components';
import theme from '../../theme';
import { IoSettingsOutline } from "react-icons/io5";
import quizBuzzIcon from '../../assets/quiz_buzz_icon.png'; // <--- NEW ICON

/** Main container: let the body handle scrolling **/
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.medium};
  position: relative;
  background: ${({ theme }) => theme.colors.background};

  /* Added to make the component scrollable */
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 45px;

  
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.lightGray} transparent;
  
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.background};
      border-radius: 2px;
    }
`;

/** Sticky header with full width & high z-index, always visible at top **/
export const Header = styled.div`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.background};
  z-index: 999;
  width: 110%;
  margin-left: -5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.background_fonce};
  opacity: 1;
`;

export const LogoImage = styled.img`
  height: 150px;
  width: auto;
  margin-top: -5%;
  object-fit: contain;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.large};
  padding: ${({ theme }) => theme.spacing.medium} 0;
`;

/** Settings button in top-right corner, absolutely placed in the page **/
export const SettingsButton = styled.button`
  position: fixed;
  top: ${({ theme }) => theme.spacing.small};
  right: ${({ theme }) => theme.spacing.small};
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  z-index: 1001;
`;


export const SettingsIcon = styled(IoSettingsOutline)`
  font-size: ${props => props.isMobile ? '20px' : '24px'};
  color: ${props => props.theme.colors.primary};
`;

/** Rubrique title, left-aligned by default **/
export const RubriqueTitle = styled.h2`
  text-align: left;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 -5px 0; /* Reduced bottom margin */
  width: 95%;
  margin-left: 2.5%;
  margin-top: 0%;
`;

/* -------------------------------------------------------
   PART 1: Récap (4 stat boxes). Updated layout with reduced gaps.
-------------------------------------------------------- */
export const RecapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two boxes per row */
  gap: 3px; /* Reduced space between boxes */
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 6%; /* Reduced top margin to subtitle */
`;

export const StatBox = styled.div`
  display: flex;
  flex-direction: column; /* Stack number and label vertically */
  align-items: flex-start;
  justify-content: center;
  gap: 0;               /* No explicit gap between items */
  padding: 2px;         /* Reduced padding */
  border-radius: 15px;
  background-color: ${props => props.bgColor || '#0066FF'};
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
`;

export const StatNumber = styled.div`
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 45px;      /* Same size */
  font-weight: 400;
  margin-left: 10%;
  margin-bottom: 0;     /* Ensure no extra spacing below number */
`;

export const StatLabel = styled.div`
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;      /* Same size */
  font-weight: 300;
  line-height: 1;
  margin-left: 10%;
  margin-top: -12px;
  margin-bottom: 10px;
`;


/* -------------------------------------------------------
   PART 2: Mes swipes
-------------------------------------------------------- */
export const SwipeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 6%;
  width: 95%;
  margin-left: 2.5%;
`;

export const SwipeBox = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: 80px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 2px 2px 2px rgba(166, 73, 73, 0.25);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  cursor: pointer;
`;

/** Replaces the old EmojiIcon with the new quiz icon **/
export const QuizIcon = styled.div`
  width: 41px;
  height: 41px;
  background: url(${quizBuzzIcon}) center / contain no-repeat; /* import quiz_buzz_icon.png */
  flex-shrink: 0;
`;

export const SwipeText = styled.div`
  color: #535252;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
`;

/* -------------------------------------------------------
   A generic container to mimic "SwipeBox" styling, 
   used for Domains, Specialties, and Infos 
-------------------------------------------------------- */
export const BoxContainer = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 2px 2px 2px rgba(166, 73, 73, 0.25);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 6%;
  width: 95%;
  margin-left: 2.5%;
`;

/** Each row (domain, specialty, or info) **/
export const ItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  /* or flex-start if you want text + icon on the left only */
  gap: 12px;
  min-height: 60px;
`;

/** If you have an icon to show on the left **/
export const ItemIcon = styled.div`
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  /* Remove the gray fallback, keep it transparent */
  background: ${({ bgImage }) =>
    bgImage
      ? `transparent url(${bgImage}) center / contain no-repeat`
      : 'transparent'};
`;

export const ItemText = styled.div`
  flex: 1;
  color: #535252;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
`;

/** Pink line used between multiple items **/
export const ItemSeparator = styled.div`
  width: 105%;
  margin-left:-2.5%;
  height: 2px;
  background:${theme.colors.background};
`;

/** VoirPlus button used after domain listing, etc. **/
export const VoirPlusButton = styled.div`
  color: rgba(131, 128, 128, 0.70);
  font-family: 'Geologica', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
`;

/* -------------------------------------------------------
   ScoreBox (for specialties) 
-------------------------------------------------------- */
export const ScoreBox = styled.div`
  border-radius: 5px;
  background: #000;
  width: 55px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScoreText = styled.div`
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

/* -------------------------------------------------------
   MessageStatus, ModalOverlay, etc. (unchanged except 
   ensuring they use the theme properly)
-------------------------------------------------------- */
export const MessageStatus = styled.div`
  padding: ${({ theme }) => theme.spacing.large};
  border-radius: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background: ${({ theme, error }) => (error ? theme.colors.primary : theme.colors.background_fonce)};
  color: ${({ theme, error }) => (error ? theme.colors.lightText : theme.colors.noir)};
`;

export const PseudoTextArea = styled.textarea`
  flex: 1;
  min-height: 35px;
  height: 35px;
  padding: 2px 8px;           /* Slightly smaller top/bottom padding */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Gabarito', sans-serif;
  resize: none;
  background-color: #fdfdfd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.black};
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
`;


export const ConfirmButton = styled.button`
  padding: 6px 12px;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #222;
  }
`;


export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 10px 0;
  padding: 12px;
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const SliderValue = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000;
`;

export const GradeSlider = styled.input`
  /* A black track with a white thumb */
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  background: #000; 
  border-radius: 3px;
  outline: none;
  margin: 0 8px;

  /* Remove default thumb on Chrome/Safari */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #fff; /* White button */
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    margin-top: 18px; /* Adjust this to fine-tune centering */
    transform: translateY(-50%); /* Ensure it's perfectly centered */
  }


`;


export const ConfirmGradeButton = styled.button`
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: #222;
  }
`;
