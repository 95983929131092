import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';

// Styled Components
import {
  Container,
  Header,
  CloseButtonWrapper,
  CloseButton,
  Title,
  Line,
  ScrollContent,
  IntroText,
  SectionTitle,
  FeedbackBox,
  FeedbackTextarea,
  SubmitButton,
  ButtonLabel,
  StatusMessage
} from './styles';

const UsersFeedBack = () => {
  const navigate = useNavigate();
  const { user } = useAuth();  // <--- changed from currentUser to user

  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  // Go back to settings or wherever you prefer
  const handleBack = () => {
    navigate('/settings');
  };

  const handleSubmit = async () => {
    setStatusMessage('');
    try {
      // Provide fallback if user is null or missing fields
      const user_email = user?.email || 'anonymous@example.com';
      const user_name = user?.first_name 
        ? `${user.first_name} ${user.last_name || ''}`
        : user?.email?.split('@')[0] || 'Anonymous';

      if (!message.trim()) {
        setStatusMessage("Merci d'ajouter un message avant d'envoyer.");
        return;
      }

      const body = {
        message,
        user_email,
        user_name,
      };

      // Updated endpoint
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/support/`,
        'POST',
        body
      );

      if (response?.message) {
        // success
        setStatusMessage('Merci pour votre message !');
        setMessage(''); // clear the textarea
      } else if (response?.error) {
        // display the error
        setStatusMessage(`Erreur: ${response.error}`);
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
      setStatusMessage("Une erreur s'est produite. Veuillez réessayer.");
    }
  };

  return (
    <Container>
      {/* Sticky, centered header */}
      <Header>
        <CloseButtonWrapper>
          <CloseButton onClick={handleBack} aria-label="Back">
            <IoIosArrowBack size={24} />
          </CloseButton>
        </CloseButtonWrapper>
        <Title>Améliorer l’app</Title>
        <Line />
      </Header>

      <ScrollContent>
        {/* Intro Text: left aligned */}
        <IntroText>
          Tu as une idée de fonctionnalité ? Ou tout simplement envie de nous
          partager ton expérience avec Meoria ? Dis-nous tout, on prendra soin
          de lire ton avis avec attention !
        </IntroText>

        {/* Section Title above the box */}
        <SectionTitle>Mon avis</SectionTitle>

        {/* Single box containing “Dis nous ce que tu penses...” + textarea */}
        <FeedbackBox>
          <FeedbackTextarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Décrivez votre idée ou votre avis ici..."
          />
        </FeedbackBox>

        {/* Submit Button */}
        <SubmitButton onClick={handleSubmit}>
          <ButtonLabel>Envoyer</ButtonLabel>
        </SubmitButton>

        {/* Display success/error message */}
        {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
      </ScrollContent>
    </Container>
  );
};

export default UsersFeedBack;
