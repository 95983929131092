import styled from 'styled-components';
import theme from '../../theme';

/* ─── CONTAINER STYLES ───────────────────────────── */
export const Container = styled.div`
    width: ${props => props.isMobile ? '99%' : 'calc(100% - 330px)'};
    margin-left: ${props => props.isMobile ? '0' : '330px'};
    height: 100vh;
    z-index: 1601;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: ${theme.colors.background};
    font-family: 'Gabarito', sans-serif;
    padding-bottom: 100px;

    position: relative;

  
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.lightGray} transparent;
  
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.background};
      border-radius: 2px;
    }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 90vh;
  padding: 20px;
  position: relative;
`;

/* ─── CHAT BUBBLE CONTAINER ────────────────────────── */
export const MeoChatBubbleContainer = styled.div`
  position: relative;
  top: calc(var(--vh, 1vh) * 5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 90%;
  max-width: 700px;
`;

/* ─── CHAT TEXT ─────────────────────────────────────── */
export const MeoChatText = styled.div`
  background-color: transparent;
  color: #3b3a3a;
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: Gabarito;
  font-size: 15px;
  text-align: left;
  width: 80%;
  margin: 0 auto;
  min-height: 70px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid #d9d7d7;
  position: relative;
  
  /* Bubble tail at the bottom center */
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #d9d7d7;
  }
`;

/* ─── CHAT TEXT INNER ELEMENTS ─────────────────────────── */
export const MessageContainer = styled.span`
  width: 100%;
  position: relative;
  font-size: 15px;
  color: #3b3a3a;
  text-align: left;
  display: inline-block;
  font-family: Gabarito;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

/* ─── LOGO CONTAINER ─────────────────────────────────────── */
export const LogoContainer = styled.div`
  position: relative;
  margin: 40px auto 40px;
  width: 45%;
  max-width: 150px;
  display: flex;
  justify-content: center;
`;

/* ─── LOGO IMAGE ────────────────────────────────────────── */
export const CenteredLogoImage = styled.img`
  display: block;
  width: 100%;
  object-fit: contain;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
  }
`;

// 1. Add this new styled component to your styles.js file
export const ProgressBarAbsoluteContainer = styled.div`
  position: absolute;
  top: 50%; /* Adjust this value to position the bar higher */
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  z-index: 10;
`;

// 2. Modify your ProgressBarContainer to remove margin
export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 13px;
  position: relative;
  border-radius: 10px;
  background-color: #E0E0E0;
  overflow: hidden;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
  padding: 0;
  box-sizing: border-box;
  margin-top: 70%;
`;

export const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 0px;
  overflow: hidden;
  border-radius: 10px;
`;

// Update the ProgressBarFill component in styles.js
// Update the ProgressBarFill component in styles.js for smoother transitions
export const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.width}%;
  background-color: #4FEA2C; /* Green fill */
  border-radius: 10px;
  transition: width 0.3s linear; /* Smooth linear transition */
  position: relative;
`;

export const SubBar = styled.div`
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  height: 3px;
  background-color: #89FD6F;
  border-radius: 10px;
`;

/* ─── BUTTON STYLES ──────────────────────────────────────── */
export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #38B6FF;
  border: none;
  border-radius: 25px;
  padding: 12px 30px;
  margin-top: 20px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  transform: ${props => (props.active ? 'scale(0.98)' : 'scale(1)')};
  transition: all 0.2s ease;
  position: absolute;
  bottom: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: ${props => (props.disabled ? '#38B6FF' : '#1a9aff')};
  }
`;

export const ButtonText = styled.span`
  color: white;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 500;
  user-select: none;
`;



export const LogoImageQuestions = styled.img`
  width: 100px;
  object-fit: contain;
  margin-left: 0%;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;
  margin-top: 5%;
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }

  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;


export const ContentWrapperQuestions = styled.div`
  position: relative;
  width: 90%;
  max-width: 600px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${theme.spacing.large} 0;
  gap: 0.5rem;
  margin-top: 0%;
  margin:auto;
`;

export const ChatBubbleContainerQuestions = styled.div`
  position: relative;
  top: -210px;
  left: 28%;
  transform: none;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const ChatTextQuestions = styled.div`
  background-color: transparent;
  color: ${theme.colors.noir};
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: Gabarito;
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  position: relative;
  width: 75%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #d9d7d7;
  
  & span {
    opacity: ${props => props.isVisible ? 1 : 0};
    transform: translateY(${props => props.isVisible ? '0' : '-10px'});
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #d9d7d7;
  }

  &:before {
    content: '';
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    z-index: 1;
  }
`;