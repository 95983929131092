import styled, { keyframes } from 'styled-components';
import { FaHeart } from 'react-icons/fa';

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export const HeaderImage = styled.img`
  width: 30%;
  height: 20%;
  margin-top: 5%;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ScrollableRankings = styled.div`
  width: 100%;
  max-height: 80vh;
  overflow-y: ${props => props.isMobile ? 'auto' : 'unset'};
  margin-top: 1rem;
  padding-bottom: 40px;
  
  scrollbar-width: thin;
  scrollbar-color: #f0f0f0 transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f0f0f0;
    border-radius: 2px;
  }
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  align-items: center;
  margin-top: 0%;
  margin-bottom: 5%;
  margin-left: 5%;
`;

export const SubtitleText = styled.div`
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.43);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 4px;
`;

export const RankingItem = styled.div`
  width: 100%;
  height: 110px; /* Increased from 94px to accommodate subtitle */
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  justify-content: space-between;
  
  &:active {
    transform: scale(0.98);
  }
`;

export const RankNumber = styled.span`
  width: 30px;
  text-align: center;
  color: #6e6e6e;
  font-family: 'Gabarito', sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const TrophyIcon = styled.div`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  margin-right: 10px;
`;

export const LikeButton = styled(FaHeart)`
  color: ${props => props.liked ? '#ff0000' : '#e0e0e0'};
  font-size: 24px;
  margin-right: 0px;
  cursor: pointer;
  transition: color 0.2s;
  
  &:hover {
    color: #ff0000;
  }
`;

export const FormationName = styled.div`
  width: 193px;
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: -20px;
  gap: 15px;
`;

// Circle component for the score
export const ScoreCircle = styled.div`
  position: relative;
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ScoreValue = styled.div`
  color: ${props => props.color || '#28BB23'};
  font-family: Gabarito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SeeMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'Gabarito', sans-serif;
  font-size: 1rem;
  font-style: italic;
  cursor: pointer;
  width: 100%;
  text-align: right;
  padding: 10px 20px;
  margin-bottom: 30px;
`;

export const NoFavorites = styled.div`
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 1.2rem;
  color: #666;
  margin-top: 30px;
  padding: 20px;
`;

export const PlaceholderLine = styled.div`
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${props => props.width || '70%'};
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const PlaceholderPercent = styled.div`
  width: 24px;
  height: 24px;
  background: #f0f0f0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const HeartIconClassement = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;