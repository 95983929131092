import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SideMenuContainer,
  MeoriaTitle,
  MenuList,
  MenuItem,
  ButtonIcon,
  ButtonText,
  RightDelimiter,
  RectangleDiv,
  NewChatIcon1
} from './styles';

// Import your icons
import chatIcon from '../../../assets/chat_icon.png';
import listeIcon from '../../../assets/liste_icon.png';
import profilDegradeIcon from '../../../assets/profil_degrade_icon.png';
import explorerIcon from '../../../assets/explorer_icon.png';
import parametresSiteIcon from '../../../assets/parametres_site.png';
import newChatIcon from '../../../assets/newchat_icon.png';
import lettreIcon from '../../../assets/lettre.png';

import ChatHistory from '../../Desktop/ChatHistory';

const menuItems = [
  {
    label: 'Chat avec Meo',
    icon: chatIcon,
    path: '/chat',
  },
  {
    label: 'Explorer',
    icon: explorerIcon,
    path: '/explorer',
  },
  {
    label: 'Lettres',
    icon: lettreIcon,
    path: '/lettres',
  },
  {
    label: 'Comparateur',
    icon: listeIcon,
    path: '/comparateur',
  },
  {
    label: 'Mon profil',
    icon: profilDegradeIcon,
    path: '/profile',
  },
  {
    label: 'Paramètres',
    icon: parametresSiteIcon,
    path: '/settings',
  },
];

/**
 * SideMenu component
 * 
 * @param {Function} onNewChat - Callback to trigger a "new chat" (will call ChatDiscussion's handleNewChat via ref or context)
 * @param {Function} onDiscussionClick - Callback when a discussion in ChatHistory is clicked
 */
const SideMenu = ({ onNewChat, onDiscussionClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Keep track of the active path
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleMenuClick = (newPath) => {
    setActivePath(newPath);
    navigate(newPath);
  };

  return (
    <SideMenuContainer>
      <MeoriaTitle>MEORIA</MeoriaTitle>
      <MenuList>
        {menuItems.map((item) => {
          // Updated active logic: any path that begins with the item's path is considered active
          const isActive = activePath.startsWith(item.path);

          return (
            <React.Fragment key={item.path}>
              <MenuItem
                isActive={isActive}
                onClick={() => handleMenuClick(item.path)}
              >
                <ButtonIcon src={item.icon} alt={item.label} />
                <ButtonText>{item.label}</ButtonText>

                {/* Only show the "new chat" rectangle when we're on /chat and it's active */}
                {item.path === '/chat' && isActive && (
                  <RectangleDiv
                    onClick={(e) => {
                      e.stopPropagation();  // Prevent triggering navigation
                      if (onNewChat) {
                        onNewChat();         // Calls the parent’s handleNewChatClick()
                      }
                    }}
                  >
                    <NewChatIcon1 src={newChatIcon} alt="New Chat" />
                  </RectangleDiv>
                )}
              </MenuItem>

              {/* When we are on "/chat" and it's active, render the ChatHistory below the "Chat avec Meo" item */}
              {item.path === '/chat' && isActive && (
                <ChatHistory
                  onDiscussionClick={onDiscussionClick}
                  isOpen={true}
                  onClose={() => {}}
                />
              )}
            </React.Fragment>
          );
        })}
      </MenuList>
      <RightDelimiter />
    </SideMenuContainer>
  );
};

export default SideMenu;
