import React, { useState } from 'react';
import styled from 'styled-components';
import { useNotification } from '../contexts/NotificationContext';

// Import your assets – adjust the path as needed
import popupImage from '../assets/meo_write.png'; // Replace with a more appropriate image

// Styled components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const PopupContainer = styled.div`
  width: 300px;
  max-width: 90%;
  height: auto;
  flex-shrink: 0;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

const Title = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 15px 0 5px;
  text-align: center;
`;

const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  width: 100%;
`;

// Primary button styling with blue color
const PrimaryButton = styled.button`
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  background: #41B6FB;
  box-shadow: ${props => props.isPressed ? 'none' : '0px 3px 0px 0px #1396E4'};
  border: none;
  border-radius: 20px;
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transform: ${props => props.isPressed ? 'translateY(3px)' : 'translateY(0)'};
  transition: transform 0.1s, box-shadow 0.1s;
  
  img {
    width: 20px;
    height: 20px;
  }
`;

const SecondaryButton = styled.button`
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  background: rgba(255, 39, 104, 0);
  color: rgb(187, 187, 187);
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

// If you don't have an icon, you can create a simple bell icon:
const BellIconFallback = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2C6.68632 2 4 4.68629 4 8V12.5L3 14.5V15.5H17V14.5L16 12.5V8C16 4.68629 13.3137 2 10 2ZM10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z" fill="white"/>
  </svg>
);

const NotificationPrompt = () => {
  const { 
    shouldShowPrompt, 
    dismissPrompt, 
    subscribeToNotifications
  } = useNotification();
  
  const [loading, setLoading] = useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  const handleAllow = async () => {
    setIsButtonPressed(true);
    setLoading(true);
    
    setTimeout(async () => {
      try {
        await subscribeToNotifications();
        dismissPrompt();
      } catch (error) {
        console.error('Error subscribing to notifications:', error);
      } finally {
        setLoading(false);
        setIsButtonPressed(false);
      }
    }, 150);
  };

  const handleDeny = () => {
    dismissPrompt();
    // Store in localStorage that user has declined
    localStorage.setItem('notificationPromptDeferred', Date.now().toString());
  };

  const handleShowToken = () => {
    const token = localStorage.getItem('token');
    alert('Your token is: ' + token);
  };

  return (
    <Overlay show={shouldShowPrompt}>
      <PopupContainer>
        <ImageWrapper src={popupImage} alt="Notifications" />
        <Title>Reste connecté(e) avec Meo</Title>
        <Description>
          Ne manque jamais une information importante ! Reçois des rappels personnalisés 
          pour ton orientation et des mises à jour.
        </Description>
        <ButtonContainer>
          <PrimaryButton 
            onClick={handleAllow} 
            disabled={loading}
            isPressed={isButtonPressed}
          >
            Activer les notifications
            <BellIconFallback />
          </PrimaryButton>
          <SecondaryButton onClick={handleDeny}>
            Pas maintenant
          </SecondaryButton>
          <SecondaryButton onClick={handleShowToken}>
            Afficher le token
          </SecondaryButton>
        </ButtonContainer>
      </PopupContainer>
    </Overlay>
  );
};

export default NotificationPrompt; 