import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Meo from '../../assets/meo_compass.png';
import DownloadAppPopup from '../DownloadAppPopup'; // Updated import name
import {
  Container,
  ContentWrapper,
  LogoSubtitleContainer,
  LogoImage,
  Title,
  TextContainer,
  Subtitle,
  ButtonsWrapper,
  SubmitButton,
  TopButtonText,
  SecondaryButton,
  BottomButtonText
} from './styles';

const FirstPage = () => {
  const navigate = useNavigate();
  const [pressedButton, setPressedButton] = useState(null);
  
  // For the "C'est parti !" button
  const handleCreatePressStart = () => {
    setPressedButton('create');
  };
  
  const handleCreatePressEnd = () => {
    setPressedButton(null);
    setTimeout(() => {
      navigate('/presentation-meo');
    }, 150); // Adjust delay as needed
  };
  
  // For the "J'ai déjà un compte" button
  const handleLoginPressStart = () => {
    setPressedButton('login');
  };
  
  const handleLoginPressEnd = () => {
    setPressedButton(null);
    setTimeout(() => {
      navigate('/login');
    }, 150);
  };
  
  // Detect mobile device type
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isMobile = isAndroid || isIOS;
  
  // Enhanced PWA detection with multiple methods
  const isPwaStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const isIOSStandalone = window.navigator.standalone === true;
  const isPwaFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
  const isPwaMinimalUi = window.matchMedia('(display-mode: minimal-ui)').matches;
  
  // Any of these conditions suggest the app is installed as a PWA
  const isPwaInstalled = isPwaStandalone || isIOSStandalone || isPwaFullscreen || isPwaMinimalUi;
  
  // Log detection results for debugging
  console.log('Device detection:', { isIOS, isAndroid, isMobile });
  console.log('PWA detection:', { isPwaStandalone, isIOSStandalone, isPwaFullscreen, isPwaMinimalUi, isPwaInstalled });
  
  // Only show the popup if on a mobile device and the PWA is not installed
  const [showAppPopup, setShowAppPopup] = useState(isMobile && !isPwaInstalled);
  
  return (
    <Container>
      <ContentWrapper>
        <LogoSubtitleContainer>
          <LogoImage src={Meo} alt="Compass Logo" />
          <Title>MEORIA</Title>
          <TextContainer>
            <Subtitle>
              La méthode gratuite, ludique<br />
              et efficace pour trouver sa<br />
              formation post-bac.
            </Subtitle>
          </TextContainer>
        </LogoSubtitleContainer>
        {!showAppPopup && (
          <ButtonsWrapper>
            <SubmitButton
              onMouseDown={handleCreatePressStart}
              onMouseUp={handleCreatePressEnd}
              onMouseLeave={() => setPressedButton(null)}
              onTouchStart={handleCreatePressStart}
              onTouchEnd={handleCreatePressEnd}
              active={pressedButton === 'create'}
            >
              <TopButtonText>C'est parti !</TopButtonText>
            </SubmitButton>
            <SecondaryButton
              onMouseDown={handleLoginPressStart}
              onMouseUp={handleLoginPressEnd}
              onMouseLeave={() => setPressedButton(null)}
              onTouchStart={handleLoginPressStart}
              onTouchEnd={handleLoginPressEnd}
              active={pressedButton === 'login'}
            >
              <BottomButtonText>J'ai déjà un compte</BottomButtonText>
            </SecondaryButton>
          </ButtonsWrapper>
        )}
      </ContentWrapper>
      {isMobile && showAppPopup && (
        <DownloadAppPopup onDismiss={() => setShowAppPopup(false)} />
      )}
    </Container>
  );
};

export default FirstPage;