// components/UserTypeSelection/index.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';

import compassImage from '../../assets/meo_write.png';
import secondeIcon from '../../assets/top2_icon.png';
import premiereIcon from '../../assets/top1_icon.png';
import terminaleIcon from '../../assets/top_0.png';
import grande_ecole_post_bac_icon from '../../assets/grande_ecole_post_bac_icon.png';

import useDeviceType from '../useDeviceType';
import ProgressBar from '../ProgressBar';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';

const UserTypeSelection = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const modifyProfile = window.location.href.includes('profile/modify');
  const [selectedType, setSelectedType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "En quelle classe es-tu ?";
  const { isMobile } = useDeviceType();
  // console.log(isMobile)
  // State to track pointer (active) states:
  const [activeOption, setActiveOption] = useState(null);
  const [submitActive, setSubmitActive] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, []);

  const typeOptions = [
    {
      value: 'seconde',
      label: 'Seconde',
      icon: secondeIcon,
      disabled: false
    },
    {
      value: 'premiere',
      label: 'Première',
      icon: premiereIcon,
      disabled: false
    },
    {
      value: 'terminale',
      label: 'Terminale',
      icon: terminaleIcon,
      disabled: false
    },
    {
      value: 'bac1',
      label: 'Étudiant en bac+1', 
      icon: grande_ecole_post_bac_icon,
      disabled: false
    }
  ];

  // When an option is released, update the selection.
  const handleOptionClick = (option) => {
    if (!option.disabled) {
      setSelectedType(option.value);
    }
  };

  const handleSubmit = async () => {
    if (!selectedType || isTyping) return;
    setIsLoading(true);
    try {
      if(modifyProfile){
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          user_type: selectedType
        });
        let userData = JSON.parse(localStorage.getItem('user'));
        if(userData === null) {
          console.error('Error saving user type: user is null');
          return;
        }
        userData.user_type = selectedType;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile/modify/type-bac');
      } else {
        localStorage.setItem('type_utilisateur', selectedType);
        const bacRedirectTypes = ['seconde', 'premiere', 'terminale'];
        const redirectPath = bacRedirectTypes.includes(selectedType) 
          ? '/type-bac'
          : '/bac1';
        navigate(redirectPath);
      }
    } catch (error) {
      console.error('Error saving user type:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/pseudo');
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if(isLoading || isTyping || !selectedType) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if(isLoading || isTyping || !selectedType) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      {!modifyProfile && <ProgressBar startPercent={15} endPercent={40} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer isMobile={isMobile}>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {typeOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  $isSelected={selectedType === option.value}
                  $disabled={option.disabled}
                  type="button"
                  aria-pressed={selectedType === option.value}
                  aria-disabled={option.disabled}
                  // Pointer events to trigger pressed style
                  onMouseDown={() => { if (!option.disabled) setActiveOption(option.value); }}
                  onMouseUp={() => { if (!option.disabled) { setActiveOption(null); handleOptionClick(option); } }}
                  onMouseLeave={() => setActiveOption(null)}
                  onTouchStart={() => { if (!option.disabled) setActiveOption(option.value); }}
                  onTouchEnd={() => { if (!option.disabled) { setActiveOption(null); handleOptionClick(option); } }}
                  active={activeOption === option.value}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedType === option.value}
                    style={{ opacity: option.disabled ? 0.5 : 1 }}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || !selectedType}
          style={{ cursor: (isTyping || !selectedType) ? 'not-allowed' : 'pointer' }}
          // Pointer events for the submit button:
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default UserTypeSelection;
