import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiCall from '../../utils/api';
import PopupGlobale from '../PopupGlobale';

// Import styles
import {
  HeaderImage,
  ScrollableRankings,
  RankingList,
  RankingItem,
  LikeButton,
  FormationName,
  SeeMoreButton,
  NoFavorites,
  PlaceholderLine,
  PlaceholderPercent,
  ScoreContainer,
  ScoreValue,
  ScoreCircle,
  SubtitleText,
} from './styles';

import { ReturnButton } from '../sharedStyles/styles';

// Import assets
import formations_icon from '../../assets/favoris.png';
import filieres_icon from '../../assets/favoris.png';
import { IoIosArrowBack } from 'react-icons/io';
//is mobile or desktop
import useDeviceType from '../useDeviceType';
const Favoris = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDeviceType();
  
  // Determine which type of favorites to show based on URL
  const isFormationsMode = location.pathname === "/formations-favorites";
  
  const [favorites, setFavorites] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getFavorites = async () => {
    try {
      const data = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/favorites/`, 'GET');
      return data || { formations: [], typesFormations: [] };
    } catch (error) {
      console.error('Error fetching favorites:', error);
      return { formations: [], typesFormations: [] };
    }
  };

  const fetchFavorites = async () => {
    setIsLoading(true);
    try {
      const data = await getFavorites();
      // Set favorites based on the current mode
      if (isFormationsMode) {
        setFavorites(data.formations || []);
      } else {
        setFavorites(data.typesFormations || []);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, [isFormationsMode]); // Refetch when mode changes

  const handleLikeClick = (itemId) => {
    setItemToDelete(itemId);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (isFormationsMode) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`, 'DELETE', { id: itemToDelete });
      } else {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_type_formation/`, 'DELETE', { nom: itemToDelete });
      }
      // Refresh favorites
      await fetchFavorites();
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
    setShowDeletePopup(false);
  };

  // Render score circle with SVG - same as in Classement
  const renderScoreCircle = (score, index) => {
    const numericScore = Number(score) || 0;
    const clampedScore = Math.max(0, Math.min(100, numericScore));
    
    // Define progressive color gradient based on score
    // Color stops for progressive gradient (from red to yellow to green)
    const colorStops = [
      { value: 0, color: '#FF0000' },   // Red at 0%
      { value: 50, color: '#F1E424' },  // Yellow at 50%
      { value: 100, color: '#32EA2C' }  // Green at 100%
    ];
    
    // Find the two color stops to lerp between
    let lowerStop = colorStops[0];
    let upperStop = colorStops[colorStops.length - 1];
    
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (clampedScore >= colorStops[i].value && clampedScore <= colorStops[i + 1].value) {
        lowerStop = colorStops[i];
        upperStop = colorStops[i + 1];
        break;
      }
    }
    
    // Calculate interpolation factor
    const factor = (clampedScore - lowerStop.value) / (upperStop.value - lowerStop.value);
    
    // Helper function to interpolate between hex colors
    const lerpColor = (a, b, factor) => {
      const ah = parseInt(a.replace(/#/g, ''), 16);
      const ar = ah >> 16;
      const ag = (ah >> 8) & 0xff;
      const ab = ah & 0xff;
      
      const bh = parseInt(b.replace(/#/g, ''), 16);
      const br = bh >> 16;
      const bg = (bh >> 8) & 0xff;
      const bb = bh & 0xff;
      
      const rr = ar + factor * (br - ar);
      const rg = ag + factor * (bg - ag);
      const rb = ab + factor * (bb - ab);
      
      return `#${((1 << 24) + (Math.round(rr) << 16) + (Math.round(rg) << 8) + Math.round(rb)).toString(16).slice(1)}`;
    };
    
    // Calculate gradient colors
    const gradientStart = lerpColor(lowerStop.color, upperStop.color, factor);
    const gradientEnd = lerpColor(lowerStop.color, upperStop.color, Math.max(0, factor - 0.3)); // Slightly darker for end
    
    // Use start color for text
    const textColor = gradientStart;
    
    // Create a unique ID for this gradient
    const gradientId = `scoreGradient_${index}_${clampedScore}`;
    
    return {
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
          <circle cx="42.5" cy="42.5" r="37.5" stroke={`url(#${gradientId})`} strokeWidth="10"/>
          <defs>
            <linearGradient id={gradientId} x1="85" y1="42.5" x2="0" y2="42.5" gradientUnits="userSpaceOnUse">
              <stop stopColor={gradientStart}/>
              <stop offset="1" stopColor={gradientEnd}/>
            </linearGradient>
          </defs>
        </svg>
      ),
      textColor
    };
  };

  const renderItem = (item, index) => {
    // For debugging - log the first item's structure
    if (index === 0) {
      console.log('Item structure:', item);
    }
    
    // For formations
    if (isFormationsMode) {
      return (
        <RankingItem 
          key={item.id} 
          onClick={() => navigate(`/formation?id=${item.id}`)}
        >
        <FormationName>
          {item.nom}
          <SubtitleText>
            {item.etablissement || "Établissement non spécifié"}
          </SubtitleText>
        </FormationName>
          
          <ScoreContainer>
            <ScoreCircle>
              {(() => {
                const { svg, textColor } = renderScoreCircle(item.matchPercentage, index);
                return (
                  <>
                    {svg}
                    <ScoreValue color={textColor}>{item.matchPercentage}%</ScoreValue>
                  </>
                );
              })()}
            </ScoreCircle>
            <LikeButton 
              liked={true} 
              onClick={(e) => {
                e.stopPropagation();
                handleLikeClick(item.id);
              }}
            />
          </ScoreContainer>
        </RankingItem>
      );
    }
    // For filières
    else {
      const urlSafeId = item.nom.replace(/ /g, '_');
      
      // Try to find any field that might contain type information
      const typeInfo = 
        item.type_details || 
        item.type || 
        item.famille || 
        item.domaine ||
        "Type de formation";
      
      return (
        <RankingItem 
          key={item.nom} 
          onClick={() => navigate(`/card-type-formation/${urlSafeId}`)}
        >
        <FormationName>
          {item.nom}
          <SubtitleText>
            {typeInfo}
          </SubtitleText>
        </FormationName>
          
          <ScoreContainer>
            <ScoreCircle>
              {(() => {
                const { svg, textColor } = renderScoreCircle(item.matchPercentage, index);
                return (
                  <>
                    {svg}
                    <ScoreValue color={textColor}>{item.matchPercentage}%</ScoreValue>
                  </>
                );
              })()}
            </ScoreCircle>
            <LikeButton 
              liked={true} 
              onClick={(e) => {
                e.stopPropagation();
                handleLikeClick(item.nom);
              }}
            />
          </ScoreContainer>
        </RankingItem>
      );
    }
  };

  return (
    <div style={{ 
      backgroundColor: '#fff5f8',
      minHeight: '110vh',
      zIndex: 3001 ,
      ...(!isMobile ? { marginLeft: '320px' } : {})
      }}>
      <ReturnButton onClick={() => navigate('/profile')}>
      <IoIosArrowBack />
    </ReturnButton>
      <HeaderImage 
        src={isFormationsMode ? formations_icon : filieres_icon} 
        alt={isFormationsMode ? "Formations favorites" : "Filières favorites"} 
      />

      <PopupGlobale
        isVisible={showDeletePopup}
        message="Voulez-vous vraiment supprimer ce favori ?"
        onCancel={() => setShowDeletePopup(false)}
        onConfirm={handleDeleteConfirm}
        cancelText="Annuler"
        confirmText="Confirmer"
      />

      <ScrollableRankings isMobile={isMobile}>
        {isLoading ? (
          <RankingList>
            {[0, 1, 2, 3].map((index) => (
              <RankingItem key={`placeholder-${index}`}>
                <FormationName>
                  <PlaceholderLine width="70%" />
                  {isFormationsMode && <PlaceholderLine width="50%" />}
                </FormationName>
                <ScoreContainer>
                  <ScoreCircle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
                      <circle cx="42.5" cy="42.5" r="37.5" stroke="#f0f0f0" strokeWidth="10"/>
                    </svg>
                    <ScoreValue>
                      <PlaceholderLine width="40%" style={{ height: '24px', margin: '0 auto' }} />
                    </ScoreValue>
                  </ScoreCircle>
                  <PlaceholderPercent as="div" style={{ marginLeft: '15px' }} />
                </ScoreContainer>
              </RankingItem>
            ))}
          </RankingList>
        ) : favorites.length === 0 ? (
          <NoFavorites>
            {isFormationsMode 
              ? "Vous n'avez pas encore de formations favorites." 
              : "Vous n'avez pas encore de filières favorites."}
          </NoFavorites>
        ) : (
          <>
            <RankingList>
              {favorites.slice(0, showAll ? undefined : 10).map((item, index) => 
                renderItem(item, index)
              )}
            </RankingList>
            {favorites.length > 10 && (
              <SeeMoreButton onClick={() => setShowAll(!showAll)}>
                Voir {showAll ? 'moins' : 'plus'}...
              </SeeMoreButton>
            )}
          </>
        )}
      </ScrollableRankings>
    </div>
  );
};

export default Favoris;