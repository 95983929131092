import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import apiCall from '../../utils/api';

import meoImage from '../../assets/meo_write.png';
import useDeviceType from '../useDeviceType';

// Import styled components
import {
  Container,
  ContentWrapper,
  MeoChatBubbleContainer,
  MeoChatText,
  MessageContainer,
  BoldText,
  LogoContainer,
  CenteredLogoImage,
  ProgressBarWrapper,
  ProgressBarContainer,
  ProgressBarFill,
  SubBar,
  ProgressBarAbsoluteContainer,
  ButtonText
} from './styles';

import {
    ReturnButton,
    SubmitButton,
} from '../Onboarding/styles';
  

const ValidateFormation = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  
  // State for message typing animation
  const [isTyping, setIsTyping] = useState(true);
  const [displayedText, setDisplayedText] = useState('');
  
  // State for button interaction
  const [buttonActive, setButtonActive] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  
  // State for formation data
  const [formation, setFormation] = useState({
    id: 123, // Placeholder ID, will be replaced with actual data
    name: 'Sciences Po Paris' // Placeholder name, will be replaced with actual data
  });
  
  // State for progress bar
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  
  // State for API response
  const [questions, setQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // Track if first click is completed
  const [firstClickCompleted, setFirstClickCompleted] = useState(false);
  
  // Message states
  const initialMessage = `Je vais t'aider à écrire ta lettre de motivation pour la formation ${formation.name}`;
  const followupMessage = `J'ai besoin de quelques infos en plus pour écrire cette lettre. Prêt(e) à m'aider ?`;
  
  // Full message to display
  const fullMessage = firstClickCompleted ? followupMessage : initialMessage;
  
  // Get the formation data from localStorage or state
  useEffect(() => {
    // In a real app, you'd get the formation from location state or local storage
    const storedFormation = localStorage.getItem('selectedFormation');
    if (storedFormation) {
      setFormation(JSON.parse(storedFormation));
    }
  }, []);
  
  // Type animation effect
  useEffect(() => {
    let currentIndex = 0;
    setIsTyping(true);
    setButtonDisabled(true); // Ensure button is disabled at start of typing
    
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullMessage.length) {
        setDisplayedText(fullMessage.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        setButtonDisabled(false); // Enable button after typing is complete
        clearInterval(typingInterval);
      }
    }, 10); // Typing speed
    
    return () => clearInterval(typingInterval);
  }, [fullMessage]); // Re-run when fullMessage changes
  
  // Handle back button
  const handleBack = () => {
    navigate('/lettres');
  };
  
  // When the user presses the button
  const handlePressStart = () => {
    if (buttonDisabled) return;
    setButtonActive(true);
  };
  
  // In handlePressEnd function
const handlePressEnd = () => {
  if (buttonDisabled) return;
  setButtonActive(false);
  
  console.log("handlePressEnd called with:", {
    firstClickCompleted,
    hasQuestions: !!questions,
    questions: questions
  });
  
  // If the first click is completed and questions are loaded, navigate to questions page
  if (firstClickCompleted && questions) {
    console.log("Navigating to /lettres-questions");
    navigate('/lettres-questions');
    return;
  }
  
  console.log("Starting question generation");
  // Otherwise, start the API call process
  handleGenerateQuestions();
};

// Also add logging in handleClick for completeness
const handleClick = () => {
  if (buttonDisabled) return;
  
  console.log("handleClick called with:", {
    firstClickCompleted,
    hasQuestions: !!questions,
    questions: questions
  });
  
  // If the first click is completed and questions are loaded, navigate to questions page
  if (firstClickCompleted && questions) {
    console.log("Navigating to /lettres-questions from handleClick");
    navigate('/lettres-questions');
    return;
  }
  
  console.log("Starting question generation from handleClick");
  // Otherwise, start the API call process
  handleGenerateQuestions();
};
  
// Updated processedQuestions creation to handle both array formats
const handleGenerateQuestions = async () => {
  // Show progress bar and disable button
  setShowProgressBar(true);
  setButtonDisabled(true);
  setIsLoading(true);
  setErrorMessage('');
  
  // Start with 0% progress
  setProgressWidth(0);
  
  // Flag to track API response
  let apiResponseReceived = false;
  
  // Progress intervals configuration
  const fastPhaseLimit = 75; // Progress rapidly until 75%
  const slowPhaseLimit = 95; // Then slowly until 95% (waiting for API)
  const fastInterval = 30; // Update every 30ms in fast phase
  const slowInterval = 200; // Update every 200ms in slow phase
  
  // Fast phase increment (0-75% in about 3 seconds)
  const fastIncrement = 0.8; 
  
  // Slow phase increment (75-95% in about 10+ seconds)
  const slowIncrement = 0.15;
  
  // Initialize progress interval
  let progressInterval = null;
  
  // Function to start the progress animation
  const startProgressAnimation = () => {
    // Start with fast progression
    progressInterval = setInterval(() => {
      setProgressWidth(currentWidth => {
        // If API response received, move quickly to 100%
        if (apiResponseReceived) {
          clearInterval(progressInterval);
          return 100;
        }
        
        // If we're in the fast phase (0-75%)
        if (currentWidth < fastPhaseLimit) {
          return Math.min(currentWidth + fastIncrement, fastPhaseLimit);
        } 
        // If we're in the slow phase (75-95%)
        else if (currentWidth < slowPhaseLimit) {
          // Switch to slower interval for smoother slow progression
          if (progressInterval && currentWidth === fastPhaseLimit) {
            clearInterval(progressInterval);
            progressInterval = setInterval(() => {
              setProgressWidth(currentWidth => {
                if (apiResponseReceived) {
                  clearInterval(progressInterval);
                  return 100;
                }
                
                return Math.min(currentWidth + slowIncrement, slowPhaseLimit);
              });
            }, slowInterval);
          }
          return Math.min(currentWidth + slowIncrement, slowPhaseLimit);
        }
        
        // Hold at slowPhaseLimit until API responds
        return currentWidth;
      });
    }, fastInterval);
  };
  
  // Start the progress animation immediately
  startProgressAnimation();
  
  try {
    // Get user object from localStorage
    const userString = localStorage.getItem('user');
    
    if (!userString) {
      throw new Error('Utilisateur non connecté. Veuillez vous connecter pour continuer.');
    }
    
    const user = JSON.parse(userString);
    
    if (!user || !user.id) {
      throw new Error('Informations utilisateur invalides. Veuillez vous reconnecter.');
    }
    
    const userId = user.id;
    
    // Make the API call using apiCall utility
    const url = `${process.env.REACT_APP_BACKEND_URL}/lettres/generate-questions/`;
    
    const response = await apiCall(url, 'POST', {
      user_id: userId,
      formation_id: formation.id
    });
    
    // Mark API response as received to trigger completion
    apiResponseReceived = true;
    
    // Debug: log the response structure
    console.log('API Response:', response);
    
    let processedQuestions = [];
    
    // Process the response based on its structure
    if (Array.isArray(response)) {
      processedQuestions = response.map(item => {
        // If item is a string (new format from backend)
        if (typeof item === 'string') {
          return {
            question: item,
            answer: '',
            icon: 'book_industry_icon.png' // default icon
          };
        } 
        // If item is an object with question property (old format)
        else if (item && typeof item === 'object' && item.question) {
          return {
            question: item.question,
            answer: '',
            icon: item.icon || 'book_industry_icon.png'
          };
        }
        // Fallback - should never happen
        return {
          question: 'Question non disponible',
          answer: '',
          icon: 'book_industry_icon.png'
        };
      });
    } else {
      throw new Error('Format de réponse inattendu du serveur');
    }
    // Save the questions to the new format in localStorage
    const lettresDataString = localStorage.getItem('lettresData');
    let lettresData = {};
    
    if (lettresDataString) {
      try {
        lettresData = JSON.parse(lettresDataString);
      } catch (error) {
        console.error('Error parsing lettresData:', error);
      }
    }
    
    // Save the questions in the new format
    lettresData[formation.id] = {
      questions: processedQuestions
    };
    
    localStorage.setItem('lettresData', JSON.stringify(lettresData));
    
    // Save the questions in state
    setQuestions(processedQuestions);
    setFirstClickCompleted(true);
    
    // Wait for the animation to complete before continuing
    setTimeout(() => {
      // Clean up interval just in case
      if (progressInterval) {
        clearInterval(progressInterval);
      }
      
      setIsLoading(false);
      setButtonDisabled(false);
    }, 600); // Time for completion animation
    
  } catch (error) {
    console.error('Error generating questions:', error);
    
    // Mark API response as received to trigger completion
    apiResponseReceived = true;
    
    // Clean up interval
    if (progressInterval) {
      clearInterval(progressInterval);
    }
    
    // Force progress to 100% for error state
    setProgressWidth(100);
    
    setTimeout(() => {
      setButtonDisabled(false);
      setIsLoading(false);
    }, 600);
    
    // Set error message
    setErrorMessage(error.message || 'Une erreur est survenue lors de la génération des questions. Veuillez réessayer.');
  }
};
  
  return (
    <Container isMobile={isMobile}>
      <ContentWrapper>
        <ReturnButton onClick={handleBack}>
          <IoIosArrowBack />
        </ReturnButton>
        
        <MeoChatBubbleContainer>
          <MeoChatText>
            {isTyping ? (
              displayedText
            ) : (
              <MessageContainer>
                {firstClickCompleted ? (
                  followupMessage
                ) : (
                  <>
                    Je réfléchis à ta lettre de motivation pour la formation <BoldText>{formation.name}</BoldText>
                  </>
                )}
              </MessageContainer>
            )}
          </MeoChatText>
        </MeoChatBubbleContainer>
        
        <LogoContainer>
          <CenteredLogoImage src={meoImage} alt="Meo Lettres" />
        </LogoContainer>
        
        {/* Progress bar positioned absolutely */}
        {isLoading && (
          <ProgressBarAbsoluteContainer>
            <ProgressBarContainer show={showProgressBar}>
              <ProgressBarWrapper>
                <ProgressBarFill width={progressWidth}>
                  <SubBar />
                </ProgressBarFill>
              </ProgressBarWrapper>
            </ProgressBarContainer>
          </ProgressBarAbsoluteContainer>
        )}
        
        {/* Keep an invisible placeholder to maintain original spacing */}
        <div style={{ height: '33px', visibility: 'hidden' }} />
        
        {errorMessage && <div style={{ color: 'red', margin: '10px 0' }}>{errorMessage}</div>}
        
        <SubmitButton
          onMouseDown={handlePressStart}
          onMouseUp={handlePressEnd}
          onMouseLeave={() => setButtonActive(false)}
          onTouchStart={handlePressStart}
          onTouchEnd={handlePressEnd}
          onClick={handleClick} 
          disabled={buttonDisabled}
          active={buttonActive}
          style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer' }} 
        >
          <ButtonText>
            {isLoading ? 'Chargement...' : firstClickCompleted ? 'Continuer' : "C'est parti !"}
          </ButtonText>
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default ValidateFormation;