import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color:${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1602; /* ensures it's on top if needed */
`;

/* 
  1) Header 
  - Sticky, centered horizontally & vertically 
  - z-index to ensure it's on top 
*/
export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1602;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 70px; /* you can adjust height as you like */
  display: flex;
  align-items: center;
  justify-content: center; /* center the title horizontally */
`;

/* Close button on the left side of the header */
export const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 20px; 
  width: 50px;
  height: 50px;
  border: 1px solid #ffd4d5;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary || '#ff5678'};
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(-2px);
  }
  &:active {
    opacity: 0.8;
  }
`;

/* Centered text */
export const Title = styled.h2`
  margin: 0;
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: #000;
`;

/* Divider line at the bottom of the header */
export const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px;
  background: #ffd4d5;
`;

export const ScrollContent = styled.div`
  width: 95%;
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
`;

/* Intro text: left aligned */
export const IntroText = styled.div`
  font-weight: 300;
  font-family: 'Gantari', sans-serif;
  font-size: 12px;
  color: #615F5F;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 20px;
`;

/* Section title "Mon avis" */
export const SectionTitle = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`;

/* A single box containing the label + textarea */
export const FeedbackBox = styled.div`
  border: 1px solid #ffd4d5;
  border-radius: 15px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
`;

/* Box Header: "Dis nous ce que..." */
export const BoxHeader = styled.div`
  font-family: 'Gantari', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #615f5f;
  margin-bottom: 10px;
  text-align: left;
`;

export const FeedbackTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  color: #000;
  resize: none;
  border-radius: 10px;
  background: #fdfdfd;

  &:focus {
    outline: none;
  }
`;

/* Submit button and label */
export const SubmitButton = styled.button`
  display: inline-block;
  border-radius: 15px;
  border: 1px solid #ffd4d5;
  background-color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  margin-bottom: 10px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }
`;

export const ButtonLabel = styled.span`
  font-size: 14px;
  color: #000;
`;

/* Success or error message after sending */
export const StatusMessage = styled.p`
  margin: 0;
  margin-top: 10px;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  color: #000;
`;
