import styled, { keyframes } from 'styled-components';


export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
  max-width: 110%;
  min-height: 50px;
  overflow-x: visible;  /* Changed from auto to visible */
  overflow-y: visible;
  position: relative;
`;

export const AdditionalFiltersRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-top:-15px;
  max-width: 110%;
  min-height: 50px;
  overflow-x: visible;  /* Changed from auto to visible */
  overflow-y: visible;
  position: relative;
`;

export const AdditionalFiltersRowType = styled.div`
  position: absolute;
  top: 100%; /* Directly below the FiltersContainer */
  left: 0;
  flex-wrap: wrap;
  min-height: 50px;
  z-index: 3;
  width: auto;
  justify-content: flex-start;
  /* Other styling such as background, padding, etc. */
`;

export const FilterButton = styled.div`
  height: 35px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  border: 0.1px solid #D6D2D2;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  width: auto; /* Adapts width based on content */
  margin-bottom: 5px;
`;

export const FilterText = styled.span`
  color: #626060;
  font-family: Gabarito;
  font-size: 13px;
  margin-right: auto;
`;

/* New component for the icon */
export const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px; /* Adjust this value for the desired spacing */
`;

export const Counter = styled.div`
  width: 19px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.2px solid #bcbbbb;
  background: #f3f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px; /* This already adds spacing from previous elements */
`;

export const CounterNumber = styled.span`
  color: #2181f6;
  font-family: Gabarito;
  font-size: 13px;
`;

export const DropdownArrow = styled.img`
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
  margin-left: 8px;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  width: 280px;        // Fixed width instead of 100%
  min-height: 150px;   // Minimum height
  background: white;
  border: 0.5px solid #D6D2D2;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
  max-height: 300px;   // Increased max height
  overflow-y: auto;
`;

export const DropdownItem = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  cursor: pointer;
  font-family: Gabarito;
  font-size: 14px;
  color: #626060;
`;

export const EstablishmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 95%;
  align-items: center;
  margin-top: 10%;
  margin-left: 2.5%;
`;

export const EstablishmentCard = styled.div`
  width: 100%;
  height: 110px; /* Increased height to accommodate two lines of text */
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
  text-align: left;
`;

export const EstablishmentName = styled.div`
  color: #000;
  font-family: Gabarito;
  font-size: 16px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: 2.4em;
  width: 95%;
`;

export const EstablishmentDetails = styled.div`
  gap: 2px;
  font-family: Gabarito;
  font-size: 14px;
  color: #626060;
  div:last-child {
    color: #888;
    font-size: 13px;
  }
  text-align: left;
  display: -webkit-box;        /* For line clamping */
  -webkit-line-clamp: 2;       /* Limit to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;            /* Must hide overflow for clamp to work */
  text-overflow: ellipsis;
 `;

export const LikeIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`;

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export const PlaceholderLine = styled.div`
  height: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  width: ${props => props.width || '70%'};
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;

export const PlaceholderHeart = styled.div`
  width: 24px;
  height: 24px;
  background: #f0f0f0;
  border-radius: 4px;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite linear;
`;