// src/theme.js
const theme = {
  colors: {
    primary: '#FF2768',
    secondary: '#FF6B98',
    accent: '#FF8CAD',
    background: '#FFF8F9',
    background_fonce: '#FFEAEE',
    noir: '#333333',
    white: '#FFFFFF',
    noir_clair: '#4b4b4b',
    gris: '#6E6E6E',
    bleu: '#DCF3FF',
    rouge: '#FF3B30',
    orange: '#FFA532',
    orange_fonce: '#FF8F17',
    jaune: '#FFE61C',
    jaune_clair: '#F0D81A',
    violet: '#C57DFF',
    violet_fonce: '#C057FF',
    vert: '#1EE018',
    marron: '#1EE018',
    vert_fonce: '#53D13D',
    vert_clair: '#A0F58E',
    bleu_fonce: '#38B6FF',
    bleu_tres_fonce: '#1C9EFF',
    gris_clair: '#BFBFBF',
    gris_clair_clair: '#E3E3E3',
    lightText: '#ffffff',
    lightGray: '#FFE4EC',
    midlightGray: '#FFEDF8',
    veryLightGray: '#FFF0F6',
    mediumGray: '#FF4F85',
  },
  fonts: {
    main: "'Gabarito', sans-serif",
    heading: "'Inter', sans-serif",
    bubble: "'Fira Sans', serif",
  },
  fontSizes: {
    xsmall: '0.75rem',
    small: '14px',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
    xxlarge: '1.5rem',
    xxxlarge: '1.75rem',
    big: '2rem',
    huge: '2.5rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bigBold: 800,
  },
  spacing: {
    xxsmall: '0rem',
    xsmall: '0.25rem',
    small: '0.5rem',
    semimedium: '0.75',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
    xxlarge: '2.5rem',
    xxxlarge: '3rem',
    big: '3.5rem',
  },
  breakpoints: {
    mobile: '767px',
  },
};

export default theme;