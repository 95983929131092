import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { domainIconMapRaw, speIconMap, allFamilles_map_label_backend } from './domainIconMap'; // votre mapping d'icônes
import meoIcon from '../../assets/meo_write.png';
import checkIcon from '../../assets/true_icon.png';
import crossIcon from '../../assets/attention_icon.png';
import infoIcon from '../../assets/info_gris.png'; // Import info icon
import flecheVioletteIcon from '../../assets/fleche_violette.png'; // Import purple arrow icon
import flecheVerteIcon from '../../assets/fleche_verte.png'; // Import green arrow icon - adjust path as needed

import lowIcon from '../../assets/chances_tres_basses.png'; // Import your icons
import belowAverageIcon from '../../assets/chances_basses.png';
import averageIcon from '../../assets/chances_basses.png';
import highIcon from '../../assets/true_icon.png';
import veryHighIcon from '../../assets/chances_tres_elevees.png';

import useDeviceType from '../useDeviceType';
/* ==================================================== 
   FONCTIONS UTILITAIRES POUR LE CALCUL DU GRADIENT
=====================================================*/
const chancesLevelMap = [
  { range: [0, 20], text: "Très Basses", icon: lowIcon },
  { range: [20, 40], text: "Basses", icon: belowAverageIcon },
  { range: [40, 60], text: "Moyennes", icon: averageIcon },
  { range: [60, 80], text: "Elevées", icon: highIcon },
  { range: [80, 100], text: "Très élevées", icon: veryHighIcon }
];

function normalizeString(str) {
  return str
    .toLowerCase()                  // Lowercase
    .normalize('NFD')               // Décomposer les accents
    .replace(/[\u0300-\u036f]/g, '')// Retirer les accents
    .trim();                        // Supprimer les espaces en fin de chaîne
}

// Construction d'une map avec des clés normalisées
const normalizedDomainMap = (domainIconMapRaw) => Object.entries(domainIconMapRaw).reduce(
  (acc, [displayDomain, icon]) => {
    const key = normalizeString(displayDomain);
    acc[key] = { displayDomain, icon };
    return acc;
  },
  {}
);

/* ====================================================
   STYLED-COMPONENTS POUR LA STRUCTURE
=====================================================*/


const MeoIcon = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

/* ---------- Composant Gauge ---------- */

const GaugeWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 120px;
  margin-top: 30px;
`;

const SvgStyled = styled.svg`
  overflow: visible;
`;

/* ---------- Card générique ---------- */
const Card = styled.div`
  width: 100%;
  background: white;
  border-radius: 25px;
  padding: 20px;
  margin: 10px 0;
`;

const CardTitle = styled.div`
  color: black;
  font-size: 16px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: left;
`;

const ScoreCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

/* ---------- Séparateur ---------- */
const Separator = styled.div`
  width: 100%;
  height: 1px;
  border: 1.5px solid #FFF5F8;
  margin: 10px 0;
`;

/* ---------- Liste des domaines ---------- */
const DomainList = styled.div`
  margin-top: 10px;
`;

const DomainItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const DomainIcon = styled.img`
  width: 35px;
  height: 35px;
  background: none;
  border-radius: 4px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DefaultDomainIcon = styled.div`
  width: 22px;
  height: 22px;
  background: #ccc;
  border-radius: 50%;
  margin-right: 10px;
`;

const DomainName = styled.div`
  color: black;
  font-size: 13px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  margin-left: 10px;
`;

const VoirPlusButton = styled.div`
  width: fit-content;
  color: rgba(130, 128, 128, 0.7);
  font-size: 14px;
  font-family: 'Geologica', sans-serif;
  font-style: italic;
  font-weight: 300;
  cursor: pointer;
  margin-left: 75%;
  &:hover {
    text-decoration: underline;
  }
`;

/* ---------- Liste pour le caractère ---------- */
const ListSection = styled.div`
  margin-top: 10px;
  text-align: left;
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const IconCheck = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 2px;
`;

const IconCross = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const ListText = styled.div`
  color: black;
  font-size: 13px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
`;

/* ====================================================
   NOUVEAUX COMPOSANTS POUR LES SPÉCIALITÉS
=====================================================*/

// Container pour les spécialités
const SpecialtyContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;
  flex-wrap: wrap;
`;

// Conteneur pour chaque spécialité
const SpecialtyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 30%;
`;

// Barre verticale pour les spécialités
const VerticalBar = styled.div`
  position: relative;
  width: 17px;
  height: 200px;
  border-radius: 30px;
  background: #DCF3FF;
  margin-bottom: 30px;
`;

// Filling de la barre verticale
const VerticalBarFill = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${props => props.max ? `${(props.max - props.rank + 1) / props.max * 100}%` : '50%'};
  border-radius: 30px;
  background: #38B6FF;
`;

// Badge de position
const PositionBadge = styled.div`
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 61px;
  height: 28px;
  border-radius: 25px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Gabarito, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

// Conteneur pour l'icône et le texte de spécialité
const SpecialtyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-top: 5px;
`;

// Icône de spécialité
const SpecialtyIcon = styled.img`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 3px;
`;

// Conteneur pour les textes de spécialité (label et nom)
const SpecialtyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Label "Spécialité"
const SpecialtyLabel = styled.div`
  color: #B6B6B6;
  font-family: Gabarito, sans-serif;
  font-size: 14px;
  font-weight: 400;
`;

// Nom de la spécialité
const SpecialtyName = styled.div`
  color: #000;
  font-family: Gabarito, sans-serif;
  font-size: 19px;
  font-weight: 400;
`;

// Message d'information sous les spécialités
const InfoMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  padding-left: 10px;
`;

const InfoIcon = styled.img`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 10px;
`;

const InfoText = styled.div`
  color: #8D8D8D;
  font-family: Gabarito, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;

/* ====================================================
   NOUVEAUX COMPOSANTS POUR LA MOYENNE
=====================================================*/

const GradeTitle = styled.div`
  color: black;
  font-size: 16px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: left;
`;

const GradeBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin: 0px 0;
`;

const HorizontalBar = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  border-radius: 30px;
  background: #DCF3FF;
`;

const GradeMarker = styled.div`
  position: absolute;
  top: 50%;
  left: ${props => (props.position / 20) * 100}%;
  transform: translate(-50%, -50%);
  width: 3px;
  height: 18px;
  border-radius: 30px;
  background: ${props => props.color};
  z-index: 2;
`;

const GradeLabel = styled.div`
  position: absolute;
  top: ${props => props.isUser ? '100px' : '30px'};
  left: ${props => (props.position / 20) * 100}%;
  transform: translateX(-50%);
  color: ${props => props.color};
  text-align: center;
  font-family: Gabarito, sans-serif;
  font-size: 15px;
  font-weight: 400;
`;

const GradeBound = styled.div`
  position: absolute;
  bottom: ${props => props.bottom ? '20px' : 'auto'};
  top: ${props => props.top ? '20px' : 'auto'};
  left: ${props => props.left};
  color: #8D8D8D;
  text-align: center;
  font-family: Gabarito, sans-serif;
  font-size: 10px;
  font-weight: 400;
`;


const SpecialtySection = styled.div`
  width: 100%;
`;

const GradeSection = styled.div`
  width: 100%;
  margin-top: 20px;
`;

/* ====================================================
   NOUVEAUX COMPOSANTS POUR LES CHANCES D'ADMISSION
=====================================================*/

const AdmissionContainer = styled.div`
  width: 100%;
  background: #FF2768;
  border-radius: 25px;
  padding: 20px;
  margin: 10px 0px 0px 0px;
  box-shadow: 0px 0px 0px 0px #D42359;
`;

const AdmissionTitle = styled.div`
  color: #FFF;
  font-family: Gabarito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

const AdmissionProgressContainer = styled.div`
  width: 100%;
  height: 15px;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const AdmissionProgressFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background: linear-gradient(90deg, #FF0000 0%, #FFFF00 50%, #26FF00 100%);
  border-radius: 25px;
`;

const AdmissionInfoMessage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`;

const AdmissionInfoText = styled.div`
  color: #FFF;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: ${props => props.pressed ? '16px' : '20px'};
  // left: 50%;
  // transform: translateX(-50%);
  width: 280px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FF2768;
  box-shadow: ${props => props.pressed ? 'none' : '0px 4px 0px 0px #D42359'};
  border: none;
  color: white;
  font-family: Gabarito;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
  display: ${props => props.show ? 'block' : 'none'};
  transition: bottom 0.05s ease, box-shadow 0.05s ease;
  outline: none;
  opacity: 1; /* Explicitly set opacity to 100% */
  
  &:hover, &:focus {
    outline: none;
    opacity: 1; /* Maintain 100% opacity on hover/focus */
  }
`;

const AdmissionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 25px;
  background: #FFF;
`;

const StatusText = styled.span`
  color: #000;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
`;

const StatusIcon = styled.img`
  width: 20px;
  height: 20px;
`;

// Nouveau boutton pour compléter le dossier
const CompleteProfileButton = styled.button`
  width: 100%;
  height: 44px;
  border-radius: 25px;
  background: #FFF;
  border: none;
  color: #FF2768;
  font-family: Gabarito;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, transform 0.1s;
  
  &:hover {
    background-color: #F7F7F7;
  }
  
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const getChancesLevel = (percentage) => {
  for (const level of chancesLevelMap) {
    if (percentage >= level.range[0] && percentage <= level.range[1]) {
      return level;
    }
  }
  // Default fallback
  return chancesLevelMap[2]; // "Moyenne" as default
};

/* ====================================================
   COMPOSANT PRINCIPAL
=====================================================*/
// Ajouter ces nouveaux styled-components pour la mise en page desktop
const DesktopGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr'};
  grid-gap: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

const GridFullWidth = styled.div`
  grid-column: 1 / -1;
  width: 100%;
`;

const CardGrid = styled(Card)`
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const CharacteristicsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
`;

// Modifier le Container principal pour s'adapter à la version desktop
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => {
    const heightVal = parseInt(props.headerHeight || '230');
    // Add a small offset for spacing between header and content
    return `${heightVal - 180}px`;
  }};
  padding-bottom: 10px;
  width: 100%;
`;

/* ====================================================
   GAUGE UTILS
=====================================================*/
const getArcPath = (percentage) => {
  const angle = (percentage / 100) * 180; // en degrés
  const radians = (angle * Math.PI) / 180;
  // Cercle centré en (100,100) de rayon 90.
  const x = 100 + 90 * Math.cos(Math.PI - radians);
  const y = 100 - 90 * Math.sin(Math.PI - radians);
  return `M 10,100 A 90,90 0 ${angle >= 180 ? 1 : 0} 1 ${x},${y}`;
};

const Gauge = ({ percentage }) => {
  const fullArcPath = 'M 10,100 A 90,90 0 0,1 190,100';
  const visibleArcPath = getArcPath(percentage);

  return (
    <GaugeWrapper>
      {/* MEO ICON HERE */}
      <MeoIcon src={meoIcon} alt="M.E.O" />

      <SvgStyled width="200" height="140">
        <defs>
          <linearGradient
            id="scoreGradient"
            gradientUnits="userSpaceOnUse"
            x1="100" y1="10"
            x2="100" y2="190"
            gradientTransform="rotate(270, 100, 100)"
          >
            <stop offset="0%" stopColor="#ff0000" />
            <stop offset="26%" stopColor="#f4932c" />
            <stop offset="53%" stopColor="#f1e424" />
            <stop offset="79%" stopColor="#35de2f" />
            <stop offset="100%" stopColor="#26ff00" />
          </linearGradient>
        </defs>

        {/* Arc de fond gris */}
        <path
          d={fullArcPath}
          fill="none"
          stroke="#D6D2D2"
          strokeWidth="20"
          strokeLinecap="round"
        />

        {/* Arc coloré en dégradé */}
        <path
          d={visibleArcPath}
          fill="none"
          stroke="url(#scoreGradient)"
          strokeWidth="20"
          strokeLinecap="round"
        />

        {/* Textes */}
        <text
          x="100"
          y="95"
          textAnchor="middle"
          fontSize="12"
          fontFamily="Gabarito, sans-serif"
          fill="#555454"
        >
          <tspan x="100" dy="0">Correspondance</tspan>
          <tspan x="100" dy="1.2em">avec la formation</tspan>
        </text>
        <text
          x="100"
          y="75"
          textAnchor="middle"
          fontSize="40"
          fontFamily="Gantari, sans-serif"
          fill="black"
          fontWeight="700"
        >
          {Math.round(percentage)}%
        </text>
      </SvgStyled>
    </GaugeWrapper>
  );
};

// Modifier le Composant principal pour utiliser la mise en page adaptative
const CorrespondanceFormation = ({ formation, posNeg, headerHeight }) => {
  // Import du hook de détection d'appareil
  const { isMobile } = useDeviceType();
  
  // Extraction des scores
  const scoreFormations = formation?.score_formations || 0;
  let moyenne_générale = formation?.moyenne_formation || null;
  moyenne_générale = moyenne_générale === 0 ? null : moyenne_générale; 
  
  // Get admission chances and convert to percentage (default to 50% if not available)
  const admissionChances = formation?.chances_admissions !== undefined 
    ? Math.round(formation.chances_admissions * 100) 
    : 50;

  // Simulons une moyenne utilisateur pour la démonstration

  // Filtrer les items positifs et négatifs selon le type de formation
  const formationType = allFamilles_map_label_backend[formation.type] || formation.type;
  const filteredPositives = posNeg?.positive?.filter(item => item.quiz_type === formationType) || [];
  const filteredNegatives = posNeg?.negative?.filter(item => item.quiz_type === formationType) || [];
  
  // Domaines enseignés
  const domainesP = formation.domaines_enseignés_principaux || [];
  const domainesS = formation.domaines_enseignés_secondaires || [];
  const userObject = JSON.parse(localStorage.getItem('user'));
  const userMoyenne = userObject?.grades?.general || 5.5; // Utilise 14.5 comme valeur par défaut si non disponible
  const matchedDomains = userObject?.sub_domaine_formation?.filter(
    domain => domainesP.includes(domain) || domainesS.includes(domain)
  ) || [];

  // Vérification des informations manquantes de l'utilisateur
  const isLyceeInfoMissing = !userObject?.lycee_city || !userObject?.lycee_name || !userObject?.lycee_uai;
  const isTopOptionMissing = !userObject?.top_option;
  const isProfileIncomplete = isLyceeInfoMissing || isTopOptionMissing;

  // Fonction pour déterminer la redirection
  const handleCompleteProfile = () => {
    if (isLyceeInfoMissing) {
      window.location.href = "/profile/modify/lycee";
    } else if (isTopOptionMissing) {
      window.location.href = "/profile/modify/classe-ment";
    }
  };

  const obj_mapper_spé = {
      "bioeco": "Biologie/Ecologie",
      "hggsp": "HGGSP",
      "hlp": "HLP",
      "llcer": "LLCER",
      "lca": "LCA : Latin",
      "math": "Mathématiques",
      "nsi": "NSI",
      "physchim": "Physique-Chimie",
      "si": "SI",
      "svt": "SVT",
      "ses": "SES",
      "eppcs": "EPPCS",
      'amc': "Anglais Monde Contemporain"
  };

  const obj_mapper_spé_short = {
    "bioeco": "Bio/Eco",
    "hggsp": "HGGSP",
    "hlp": "HLP",
    "llcer": "LLCER",
    "lca": "LCA",
    "math": "Maths",
    "nsi": "NSI",
    "physchim": "PC",
    "si": "SI",
    "svt": "SVT",
    "ses": "SES",
    "eppcs": "EPPCS",
    "amc": "AMC"
  };
  
  let userSpés = userObject?.specialites || [];

  const spéRank = formation?.rang_spécialité || {};
  const max = Object.keys(spéRank).length;
  const allSpés = Object.keys(spéRank);
  const MatchedSpecialitiesRankObj = userSpés.reduce((obj, spé) => {
    obj[spé] = allSpés.indexOf(obj_mapper_spé[spé] || spé) + 1;
    return obj;
  }, {});
  
  // États pour le "voir plus"
  const [showAllDomaines, setShowAllDomaines] = useState(false);
  const [showAllPositives, setShowAllPositives] = useState(false);
  const [showAllNegatives, setShowAllNegatives] = useState(false);

  // Nombre initial d'items affichés
  const initialDomainCount = 2;
  const initialListCount = 3;

  const domainesToShow = showAllDomaines ? matchedDomains : matchedDomains.slice(0, initialDomainCount);
  const positivesToShow = showAllPositives ? filteredPositives : filteredPositives.slice(0, initialListCount);
  const negativesToShow = showAllNegatives ? filteredNegatives : filteredNegatives.slice(0, initialListCount);

  // Ref for the admission container for scrolling
  const admissionContainerRef = useRef(null);
  // State to track if the container is visible
  const [isContainerVisible, setIsContainerVisible] = useState(false);
  // State to track if button is pressed
  const [isButtonPressed, setIsButtonPressed] = useState(false);

  // Update the useEffect for IntersectionObserver
useEffect(() => {
  // Only run if we have a valid reference to the container
  if (!admissionContainerRef.current) return;
  
  // Create an intersection observer to detect when the container is visible
  const observer = new IntersectionObserver(
    (entries) => {
      // We're only observing one element, so we can use the first entry
      const isVisible = entries[0].isIntersecting;
      setIsContainerVisible(isVisible);
      
      // For debugging - remove in production
      console.log('Container visible:', isVisible, 'Mobile:', isMobile);
    },
    {
      // Root is the viewport
      root: null,
      // Use a more aggressive rootMargin for mobile
      rootMargin: isMobile ? '-150px 0px -150px 0px' : '0px 0px -100px 0px',
      // Lower threshold for mobile - element is considered visible sooner
      threshold: isMobile ? 0.1 : 0.01
    }
  );
  
  // Start observing the container
  observer.observe(admissionContainerRef.current);
  
  // Clean up on unmount
  return () => {
    if (admissionContainerRef.current) {
      observer.unobserve(admissionContainerRef.current);
    }
  };
}, [isMobile]); // Add isMobile as a dependency
  // Handle button click to scroll to admission container
  const handleButtonClick = () => {
    // Quick shadow animation
    setIsButtonPressed(true);
    
    // Scroll to the admission container
    if (admissionContainerRef.current) {
      admissionContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
    // Reset the button state very quickly to create a quick "press" effect
    setTimeout(() => setIsButtonPressed(false), 100);
  };

  // Fonction pour rendre les éléments de spécialité avec la nouvelle UI
  const renderSpecialtyItem = (specialityName, rank, max) => {
    if (rank === 0) {
      return (
        <SpecialtyItem key={specialityName}>
          <div style={{color: "#d93025", fontSize: "14px", fontFamily: "Gabarito, sans-serif", fontWeight: 500}}>
            La spécialité {obj_mapper_spé_short[specialityName] || specialityName} n'est pas présente dans la formation !
          </div>
        </SpecialtyItem>
      );
    }

    return (
      <SpecialtyItem key={specialityName}>
        <VerticalBar>
          <VerticalBarFill rank={rank} max={max} />
          <PositionBadge>{rank}{rank === 1 ? 'ère' : 'eme'}</PositionBadge>
        </VerticalBar>
        <SpecialtyInfo>
          {speIconMap.find(item => item.value === specialityName)?.icon ? (
            <SpecialtyIcon
              src={speIconMap.find(item => item.value === specialityName)?.icon}
              alt={specialityName}
            />
          ) : (
            <div style={{ width: '32px', height: '32px', background: '#ccc', borderRadius: '50%' }}></div>
          )}
          <SpecialtyTextContainer>
            <SpecialtyLabel>Spécialité</SpecialtyLabel>
            <SpecialtyName>{obj_mapper_spé_short[specialityName] || specialityName}</SpecialtyName>
          </SpecialtyTextContainer>
        </SpecialtyInfo>
      </SpecialtyItem>
    );
  };

  // Rendu conditionnel basé sur le type d'appareil
  if (isMobile) {
    // Version mobile (existante)
    return (
      <Container headerHeight={headerHeight}>
        {/* --- Gauge avec score global --- */}
        <Card>
          <CardTitle></CardTitle>
          <ScoreCenterWrapper>
            <Gauge percentage={scoreFormations} />
          </ScoreCenterWrapper>
        </Card>

        {/* --- Card: Correspondance avec tes domaines --- */}
        <Card>
          <CardTitle>Tes domaines favoris dans la formation</CardTitle>
          <Separator />
          <DomainList>
            {domainesToShow.length === 0 && (
              <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                Aucun domaine ne correspond à tes domaines favoris.
              </p>
            )}
            {domainesToShow.map((domain, idx) => (
              <DomainItem key={idx}>
                {normalizedDomainMap(domainIconMapRaw)[normalizeString(domain)] ? (
                  <DomainIcon
                    src={normalizedDomainMap(domainIconMapRaw)[normalizeString(domain)]['icon']}
                    alt={domain}
                  />
                ) : (
                  <DefaultDomainIcon />
                )}
                <DomainName>{domain}</DomainName>
              </DomainItem>
            ))}
            {matchedDomains.length > initialDomainCount && (
              <VoirPlusButton onClick={() => setShowAllDomaines(prev => !prev)}>
                {showAllDomaines ? 'voir moins' : 'voir plus...'}
              </VoirPlusButton>
            )}
          </DomainList>
        </Card>

        {/* --- Card: Correspondance avec ton caractère --- */}
        <Card>
          <CardTitle>Pourquoi la formation te correspond</CardTitle>
          <Separator />
          <ListSection>
            {positivesToShow.map((item, idx) => (
              <ListItem key={`pos-${idx}`}>
                <IconCheck src={checkIcon} alt="check" />
                <ListText>{item.mot}</ListText>
              </ListItem>
            ))}
            {filteredPositives.length > initialListCount && (
              <VoirPlusButton onClick={() => setShowAllPositives(prev => !prev)}>
                {showAllPositives ? 'voir moins' : 'voir plus...'}
              </VoirPlusButton>
            )}
          </ListSection>
          <ListSection>
            {negativesToShow.map((item, idx) => (
              <ListItem key={`neg-${idx}`}>
                <IconCross src={crossIcon} alt="cross" />
                <ListText>{item.mot}</ListText>
              </ListItem>
            ))}
            {filteredNegatives.length > initialListCount && (
              <VoirPlusButton onClick={() => setShowAllNegatives(prev => !prev)}>
                {showAllNegatives ? 'voir moins' : 'voir plus...'}
              </VoirPlusButton>
            )}
          </ListSection>
        </Card>

        {/* --- NOUVEAU Card: Tes spécialités dans la formation --- */}
        <Card>
          <CardTitle>Tes spécialités dans la formation</CardTitle>
          <Separator />
          <SpecialtySection>
            <SpecialtyContainer>
              {Object.entries(MatchedSpecialitiesRankObj)
                .slice(0, 3) // Limite à 3 spécialités maximum
                .map(([specialityName, rank]) => renderSpecialtyItem(specialityName, rank, max))}
            </SpecialtyContainer>
            <InfoMessage>
              <InfoIcon src={infoIcon} alt="Info" />
              <InfoText>Rang de tes spécialités parmi les plus courantes chez les lycéens admis dans cette formation</InfoText>
            </InfoMessage>
          </SpecialtySection>
        </Card>

        {/* --- UPDATED Card: Ta moyenne dans la formation --- */}
        <Card>
          <GradeTitle>Ta moyenne dans la formation</GradeTitle>
          <Separator />
          <GradeSection>
            <GradeBarContainer>
              {/* Barre horizontale */}
              <HorizontalBar />
              
              {/* Marqueurs de moyenne - using original markers */}
              <GradeMarker position={moyenne_générale || 10} color="#C0F" />
              <GradeMarker position={userMoyenne} color="#4FEA2C" />
              
              {/* Labels de moyenne - positions updated */}
              <GradeLabel position={moyenne_générale || 10} color="#C0F" isUser={false}>
                {Math.round((moyenne_générale || 10) * 10) / 10}/20
              </GradeLabel>
              <GradeLabel position={userMoyenne} color="#4FEA2C" isUser={true}>
                {userMoyenne}/20
              </GradeLabel>
              
              {/* Surface bleue entre les marqueurs */}
              {userMoyenne !== moyenne_générale && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: `${Math.min(userMoyenne, moyenne_générale || 10) / 20 * 100}%`,
                    width: `${Math.abs(userMoyenne - (moyenne_générale || 10)) / 20 * 100}%`,
                    height: '18px',
                    backgroundColor:' #38B6FF',
                    borderRadius: '30px',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                  }}
                />
              )}
            </GradeBarContainer>
            
            {/* Updated legend to use arrow images instead of circles */}
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              width: '100%', 
              marginTop: '20px',
              gap: '10px' 
            }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <img 
                  src={flecheVioletteIcon} 
                  alt="Formation Average"
                  style={{ 
                    width: '24px', 
                    height: '24px'
                  }}
                />
                <div style={{ 
                  color: '#8D8D8D',
                  textAlign: 'left',
                  fontFamily: 'Gabarito',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal'
                }}>
                  Moyenne des étudiants admis dans la formation
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <img 
                  src={flecheVerteIcon} 
                  alt="Your Average"
                  style={{ 
                    width: '24px', 
                    height: '24px'
                  }}
                />
                <div style={{ 
                  color: '#8D8D8D',
                  textAlign: 'left',
                  fontFamily: 'Gabarito',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal'
                }}>
                  Ta moyenne générale
                </div>
              </div>
            </div>
            
            <InfoMessage>
              <InfoIcon src={infoIcon} alt="Info" />
              <InfoText>Plus l'écart est petit, plus ton niveau scolaire correspond à celui des étudiants de la formation</InfoText>
            </InfoMessage>
          </GradeSection>
        </Card>

        {/* --- NEW Card: Chances d'admission --- */}
        <AdmissionContainer ref={admissionContainerRef}>
          <AdmissionHeader>
            <AdmissionTitle>Tes chances d'admission</AdmissionTitle>
            <StatusBadge>
              <StatusText>{getChancesLevel(admissionChances).text}</StatusText>
              <StatusIcon src={getChancesLevel(admissionChances).icon} alt="Chances level" />
            </StatusBadge>
          </AdmissionHeader>
          <AdmissionProgressContainer>
            <AdmissionProgressFill percentage={admissionChances} />
          </AdmissionProgressContainer>
          <AdmissionInfoMessage>
            <InfoIcon src={infoIcon} alt="Info" style={{ filter: 'brightness(0) invert(1)' }} />
            <AdmissionInfoText>
              Cette estimation provient de calculs rigoureux basés sur ton profil et les statistiques passées de cette formation.
              Cela ne définit en aucun cas une décision future de l'établissement.
            </AdmissionInfoText>
          </AdmissionInfoMessage>
          
          {/* Bouton pour compléter le dossier si informations manquantes */}
          {isProfileIncomplete && (
            <CompleteProfileButton onClick={handleCompleteProfile}>
              Compléter mon dossier
            </CompleteProfileButton>
          )}
        </AdmissionContainer>

        {/* Floating button to scroll to admission chances - shown only when container is not fully visible */}
        <FloatingButton 
          show={!isContainerVisible}
          pressed={isButtonPressed}
          onClick={handleButtonClick}
        >
          Voir mes chances d'admission
        </FloatingButton>
      </Container>
    );
  } else {
    // Version desktop (nouvelle)
    return (
      <Container headerHeight={headerHeight}>
        <DesktopGrid>
          {/* Première ligne: Score à gauche, Domaines favoris à droite */}
          <GridRow>
            <CardGrid style={{ maxWidth: '300px' }}>
              <CardTitle></CardTitle>
              <ScoreCenterWrapper>
                <Gauge percentage={scoreFormations} />
              </ScoreCenterWrapper>
            </CardGrid>
            
            <CardGrid>
              <CardTitle>Tes domaines favoris dans la formation</CardTitle>
              <Separator />
              <DomainList>
                {domainesToShow.length === 0 && (
                  <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                    Aucun domaine ne correspond à tes domaines favoris.
                  </p>
                )}
                {domainesToShow.map((domain, idx) => (
                  <DomainItem key={idx}>
                    {normalizedDomainMap(domainIconMapRaw)[normalizeString(domain)] ? (
                      <DomainIcon
                        src={normalizedDomainMap(domainIconMapRaw)[normalizeString(domain)]['icon']}
                        alt={domain}
                      />
                    ) : (
                      <DefaultDomainIcon />
                    )}
                    <DomainName>{domain}</DomainName>
                  </DomainItem>
                ))}
                {matchedDomains.length > initialDomainCount && (
                  <VoirPlusButton onClick={() => setShowAllDomaines(prev => !prev)}>
                    {showAllDomaines ? 'voir moins' : 'voir plus...'}
                  </VoirPlusButton>
                )}
              </DomainList>
            </CardGrid>
          </GridRow>
          
          {/* Deuxième ligne: Formation correspondance (prend toute la largeur) */}
          <GridRow>
            <GridFullWidth>
              <CardGrid>
                <CardTitle>Pourquoi la formation te correspond</CardTitle>
                <Separator />
                <CharacteristicsGrid>
                  {/* Avantages (à gauche) */}
                  <ListSection>
                    {positivesToShow.map((item, idx) => (
                      <ListItem key={`pos-${idx}`}>
                        <IconCheck src={checkIcon} alt="check" />
                        <ListText>{item.mot}</ListText>
                      </ListItem>
                    ))}
                    {filteredPositives.length > initialListCount && (
                      <VoirPlusButton onClick={() => setShowAllPositives(prev => !prev)}>
                        {showAllPositives ? 'voir moins' : 'voir plus...'}
                      </VoirPlusButton>
                    )}
                  </ListSection>
                  
                  {/* Inconvénients (à droite) */}
                  <ListSection>
                    {negativesToShow.map((item, idx) => (
                      <ListItem key={`neg-${idx}`}>
                        <IconCross src={crossIcon} alt="cross" />
                        <ListText>{item.mot}</ListText>
                      </ListItem>
                    ))}
                    {filteredNegatives.length > initialListCount && (
                      <VoirPlusButton onClick={() => setShowAllNegatives(prev => !prev)}>
                        {showAllNegatives ? 'voir moins' : 'voir plus...'}
                      </VoirPlusButton>
                    )}
                  </ListSection>
                </CharacteristicsGrid>
              </CardGrid>
            </GridFullWidth>
          </GridRow>
          
          {/* Troisième ligne: Spécialités à gauche, Moyenne à droite */}
          <GridRow>
            <CardGrid>
              <CardTitle>Tes spécialités dans la formation</CardTitle>
              <Separator />
              <SpecialtySection>
                <SpecialtyContainer>
                  {Object.entries(MatchedSpecialitiesRankObj)
                    .slice(0, 3) // Limite à 3 spécialités maximum
                    .map(([specialityName, rank]) => renderSpecialtyItem(specialityName, rank, max))}
                </SpecialtyContainer>
                <InfoMessage>
                  <InfoIcon src={infoIcon} alt="Info" />
                  <InfoText>Rang de tes spécialités parmi les plus courantes chez les lycéens admis dans cette formation</InfoText>
                </InfoMessage>
              </SpecialtySection>
            </CardGrid>
            
            <CardGrid>
              <GradeTitle>Ta moyenne dans la formation</GradeTitle>
              <Separator />
              <GradeSection>
                <GradeBarContainer>
                  {/* Barre horizontale */}
                  <HorizontalBar />
                  
                  {/* Marqueurs de moyenne */}
                  <GradeMarker position={moyenne_générale || 10} color="#C0F" />
                  <GradeMarker position={userMoyenne} color="#4FEA2C" />
                  
                  {/* Labels de moyenne */}
                  <GradeLabel position={moyenne_générale || 10} color="#C0F" isUser={false}>
                    {Math.round((moyenne_générale || 10) * 10) / 10}/20
                  </GradeLabel>
                  <GradeLabel position={userMoyenne} color="#4FEA2C" isUser={true}>
                    {userMoyenne}/20
                  </GradeLabel>
                  
                  {/* Surface bleue entre les marqueurs */}
                  {userMoyenne !== moyenne_générale && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: `${Math.min(userMoyenne, moyenne_générale || 10) / 20 * 100}%`,
                        width: `${Math.abs(userMoyenne - (moyenne_générale || 10)) / 20 * 100}%`,
                        height: '18px',
                        backgroundColor:' #38B6FF',
                        borderRadius: '30px',
                        transform: 'translateY(-50%)',
                        zIndex: 1
                      }}
                    />
                  )}
                </GradeBarContainer>
                
                {/* Légende des flèches */}
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  width: '100%', 
                  marginTop: '20px',
                  gap: '10px' 
                }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img 
                      src={flecheVioletteIcon} 
                      alt="Formation Average"
                      style={{ 
                        width: '24px', 
                        height: '24px'
                      }}
                    />
                    <div style={{ 
                      color: '#8D8D8D',
                      textAlign: 'left',
                      fontFamily: 'Gabarito',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal'
                    }}>
                      Moyenne des étudiants admis dans la formation
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img 
                      src={flecheVerteIcon} 
                      alt="Your Average"
                      style={{ 
                        width: '24px', 
                        height: '24px'
                      }}
                    />
                    <div style={{ 
                      color: '#8D8D8D',
                      textAlign: 'left',
                      fontFamily: 'Gabarito',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal'
                    }}>
                      Ta moyenne générale
                    </div>
                  </div>
                </div>
                
                <InfoMessage>
                  <InfoIcon src={infoIcon} alt="Info" />
                  <InfoText>Plus l'écart est petit, plus ton niveau scolaire correspond à celui des étudiants de la formation</InfoText>
                </InfoMessage>
              </GradeSection>
            </CardGrid>
          </GridRow>
          
          {/* Quatrième ligne: Chances d'admission (prend toute la largeur) */}
          <GridRow>
            <GridFullWidth>
              <AdmissionContainer ref={admissionContainerRef}>
                <AdmissionHeader>
                  <AdmissionTitle>Tes chances d'admission</AdmissionTitle>
                  <StatusBadge>
                    <StatusText>{getChancesLevel(admissionChances).text}</StatusText>
                    <StatusIcon src={getChancesLevel(admissionChances).icon} alt="Chances level" />
                  </StatusBadge>
                </AdmissionHeader>
                <AdmissionProgressContainer>
                  <AdmissionProgressFill percentage={admissionChances} />
                </AdmissionProgressContainer>
                <AdmissionInfoMessage>
                  <InfoIcon src={infoIcon} alt="Info" style={{ filter: 'brightness(0) invert(1)' }} />
                  <AdmissionInfoText>
                    Cette estimation provient de calculs rigoureux basés sur ton profil et les statistiques passées de cette formation.
                    Cela ne définit en aucun cas une décision future de l'établissement.
                  </AdmissionInfoText>
                </AdmissionInfoMessage>
                
                {/* Bouton pour compléter le dossier si informations manquantes */}
                {isProfileIncomplete && (
                  <CompleteProfileButton onClick={handleCompleteProfile}>
                    Compléter mon dossier
                  </CompleteProfileButton>
                )}
              </AdmissionContainer>
            </GridFullWidth>
          </GridRow>
        </DesktopGrid>
        
        {/* Bouton flottant pour scrolling - montré uniquement si le conteneur n'est pas visible */}
        <FloatingButton 
          show={!isContainerVisible}
          pressed={isButtonPressed}
          onClick={handleButtonClick}
          style={{ marginLeft: 'auto', marginRight: 'auto'}}
        >
          Voir mes chances d'admission
        </FloatingButton>
      </Container>
    );
  }
};

export default CorrespondanceFormation;