import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Meo from '../../assets/meo_se_connecter.png';
import AppleLogo from '../../assets/apple_logo_blanc.png';
import GoogleLogo from '../../assets/google_logo.png';

import {
  Wrapper,
  NextButton,
  LogoImage,
  Container,
  InputContainer,
  StyledInput,
  EmailLoginContainer,
  SendCodeButton,
  Divider,
  DividerLine,
  DividerText,
  CountdownContainer,
  CountdownText,
  AlertOverlay,
  AlertBox,
  AlertMessage,
  AlertButton,
  GoogleButton,
  GoogleButtonContent,
  GoogleIcon,
  GoogleText,
  AppleButton,
  AppleButtonContent,
  AppleIcon,
  AppleText,
  Subtitle
} from './styles';

import {
  ReturnButton
} from'../Onboarding/styles';

import { useAuth } from '../../contexts/AuthContext';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDeviceType();

  // Auth / Google / Apple
  const { login, error: authError } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  // Email & Code states - initialize from localStorage
  const [email, setEmail] = useState(() => {
    return localStorage.getItem('loginEmail') || '';
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationView, setShowVerificationView] = useState(() => {
    return localStorage.getItem('showVerificationView') === 'true';
  });
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(() => {
    return localStorage.getItem('isEmailSubmitted') === 'true';
  });
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState(() => {
    return localStorage.getItem('hasEmailBeenSent') === 'true';
  });

  // Validation
  const [isValidInput, setIsValidInput] = useState(false);

  // Countdown - initialize from localStorage with expiry time calculation
  const [countdown, setCountdown] = useState(() => {
    const savedCountdown = localStorage.getItem('codeCountdown');
    if (savedCountdown) {
      const expiryTime = parseInt(savedCountdown, 10);
      const now = Date.now();
      const remainingTime = Math.max(0, Math.floor((expiryTime - now) / 1000));
      return remainingTime;
    }
    return 0;
  });

  // Alert
  const [showEmailExistsAlert, setShowEmailExistsAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // States to handle verifying email or token
  const [isVerifying, setIsVerifying] = useState(false);

  const TEST_EMAIL = "adminNoNeedForConfirmationToTestGooglePlay@gmail.com";
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

  // Detect if iOS AppStore environment
  const isAppStore = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Persist state in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('loginEmail', email);
    localStorage.setItem('showVerificationView', showVerificationView.toString());
    localStorage.setItem('isEmailSubmitted', isEmailSubmitted.toString());
    localStorage.setItem('hasEmailBeenSent', hasEmailBeenSent.toString());
    
    if (countdown > 0) {
      const expiryTime = Date.now() + countdown * 1000;
      localStorage.setItem('codeCountdown', expiryTime.toString());
    } else {
      localStorage.setItem('codeCountdown', '0');
    }
  }, [email, showVerificationView, isEmailSubmitted, hasEmailBeenSent, countdown]);

  // Handle visibility change to ensure state is preserved when app regains focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // App has come back to foreground, ensure state is correct
        const storedShowVerificationView = localStorage.getItem('showVerificationView') === 'true';
        if (storedShowVerificationView !== showVerificationView) {
          setShowVerificationView(storedShowVerificationView);
        }
        
        // Update countdown based on stored expiry time
        const savedCountdown = localStorage.getItem('codeCountdown');
        if (savedCountdown) {
          const expiryTime = parseInt(savedCountdown, 10);
          const now = Date.now();
          const remainingTime = Math.max(0, Math.floor((expiryTime - now) / 1000));
          setCountdown(remainingTime);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [showVerificationView]);

  const handleBack = () => {
    if (showVerificationView) {
      setShowVerificationView(false);
      setVerificationCode('');
      localStorage.setItem('showVerificationView', 'false');
    } else {
      // Clear all login state when going back to previous screen
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('showVerificationView');
      localStorage.removeItem('isEmailSubmitted');
      localStorage.removeItem('hasEmailBeenSent');
      localStorage.removeItem('codeCountdown');
      navigate(-1);
    }
  };

  const handleAlertConfirm = () => {
    setShowEmailExistsAlert(false);
    navigate('/login');
  };

  const handleNext = () => {
    // Show verification view if code has been sent
    if (hasEmailBeenSent) {
      setShowVerificationView(true);
      localStorage.setItem('showVerificationView', 'true');
    }
  };

  const checkEmailExists = async (checkEmail) => {
    try {
      console.log("{process.env.REACT_APP_BACKEND_URL} : ", `${process.env.REACT_APP_BACKEND_URL}`);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/check_email_exists/?email=${encodeURIComponent(checkEmail)}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          },
          credentials: 'omit',
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking email:', error);
      return false;
    }
  };

  const handleLoginSuccess = useCallback(async (serverTokenObj, isNewUser = false, userData = null) => {
    try {
      // Clear all login state on successful login
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('showVerificationView');
      localStorage.removeItem('isEmailSubmitted');
      localStorage.removeItem('hasEmailBeenSent');
      localStorage.removeItem('codeCountdown');
      
      await login(userData);
    } catch (error) {
      console.error('Error during login:', error);
      setVerificationMessage(
        'Une erreur est arrivée. Veuillez réessayer ou envoyer un message au support si le problème persiste.'
      );
    }
  }, [login]);

  // ============= GOOGLE LOGIN FLOW =============
  const checkAuthenticationStatus = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/google/verify/`, {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        if (data.token) {
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        }
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
    }
  }, [handleLoginSuccess]);

  const handleGoogleResponse = useCallback(async (response) => {
    try {
      setIsLoading(true);

      // Access the ID token
      const id_token = response.credential;

      if (!id_token) {
        throw new Error('No credential found in response');
      }

      // Send ID token to backend for verification
      const authResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: id_token }),
      });

      if (authResponse.ok) {
        const data = await authResponse.json();
        await handleLoginSuccess(data.token, data.is_new_user, data.user);
      } else {
        const error = await authResponse.json();
        throw new Error(error.message || 'Authentication failed');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      setErrorMessage('Une erreur est survenue lors du processus de connexion Google. Veuillez réessayer.');
    }
  }, [handleLoginSuccess]);

  const handleGoogleLogin = useCallback(async (e) => {
    if (e) e.preventDefault();
    setIsLoadingGoogle(true);
    setErrorMessage('');

    try {
      if (!window.google || !window.google.accounts || !window.google.accounts.id) {
        throw new Error('Google Sign In is not initialized');
      }

      if (window.matchMedia('(max-width: 768px)').matches && navigator.userAgent.includes('iPhone')) {
        // For iPhone mobile browsers - use redirect
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google/`;
      } else {
        // For desktop and Android - use popup
        if (document.getElementById('credential_picker_container')) {
          window.google.accounts.id.prompt();
        } else {
          // Create a button programmatically and click it
          const buttonDiv = document.createElement('div');
          buttonDiv.style.display = 'none';
          document.body.appendChild(buttonDiv);

          window.google.accounts.id.renderButton(buttonDiv, {
            type: 'standard',
            theme: 'outline',
            size: 'large',
          });
          const googleButton = buttonDiv.querySelector('div[role="button"]');
          if (googleButton) {
            googleButton.click();
            setTimeout(() => buttonDiv.remove(), 100);
          }
        }
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      setErrorMessage('Une erreur est survenue lors du processus de connexion Google. Veuillez réessayer.');
    }
  }, [checkAuthenticationStatus]);

  useEffect(() => {
    // Load Google script
    const loadGoogleScript = async () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;

      script.onload = () => {
        if (window.google?.accounts?.id) {
          window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleGoogleResponse,
            auto_select: false,
            cancel_on_tap_outside: true,
            itp_support: true,
            context: 'signin'
          });
        }
      };
      document.body.appendChild(script);
    };
    loadGoogleScript();

    return () => {
      const scriptElement = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [handleGoogleResponse]);

  useEffect(() => {
    // Handle Google redirect callback (authorization code)
    const handleGoogleRedirect = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code }),
          });
          const data = await response.json();
          if (response.ok) {
            await handleLoginSuccess(data.token, data.is_new_user, data.user);
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        } catch (error) {
          console.error('Error handling Google redirect:', error);
          setErrorMessage('Une erreur est survenue lors de l\'authentification Google.');
        }
      }
    };
    handleGoogleRedirect();
  }, [handleLoginSuccess]);

  // ============= APPLE LOGIN FLOW =============
  const handleAppleResponse = useCallback(async (event) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_token: event.authorization.id_token,
          user: event.user || {}
        }),
      });
      const data = await response.json();
      if (response.ok) {
        await handleLoginSuccess(data.token, data.is_new_user, data.user);
      } else {
        throw new Error(data.error || 'Failed to authenticate with Apple');
      }
    } catch (error) {
      console.error('Error during Apple authentication:', error);
      setErrorMessage('Une erreur est survenue lors de l\'authentification Apple. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  }, [handleLoginSuccess]);

  const handleAppleLogin = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      if (!APPLE_CLIENT_ID) {
        throw new Error('Apple Sign In is not configured');
      }
      if (!window.AppleID) {
        throw new Error('Apple Sign In is not initialized');
      }
      if (!window.AppleID.auth.initialized) {
        const config = {
          clientId: APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: `${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`,
          state: 'origin:web',
          usePopup: true
        };
        window.AppleID.auth.init(config);
      }
      const data = await window.AppleID.auth.signIn();
      await handleAppleResponse(data);
    } catch (error) {
      console.error('Error during Apple Sign In:', error);
      setErrorMessage('Une erreur est survenue avec Apple Sign In. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  }, [APPLE_CLIENT_ID, handleAppleResponse]);

  useEffect(() => {
    // Load Apple script
    let isScriptLoaded = false;
    const loadAppleScript = async () => {
      if (!APPLE_CLIENT_ID) {
        console.error('Apple Client ID is not configured');
        return;
      }
      if (document.getElementById('appleid-signin-script')) {
        isScriptLoaded = true;
        return;
      }
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.id = 'appleid-signin-script';

      await new Promise((resolve, reject) => {
        script.onload = () => {
          isScriptLoaded = true;
          resolve();
        };
        script.onerror = (err) => reject(new Error(`Failed to load Apple Sign In script: ${err.message}`));
        document.head.appendChild(script);
      });

      if (window.AppleID && !window.AppleID.auth.initialized) {
        const generateNonce = () => {
          const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz';
          let result = '';
          for (let i = 0; i < 32; i++) {
            result += charset[Math.floor(Math.random() * charset.length)];
          }
          return result;
        };
        const config = {
          clientId: APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
          state: 'origin:web',
          usePopup: true,
          nonce: generateNonce(),
          responseMode: 'form_post'
        };
        window.AppleID.auth.init(config);
      }
    };

    loadAppleScript();
    return () => {
      if (isScriptLoaded) {
        const scriptEl = document.getElementById('appleid-signin-script');
        if (scriptEl) {
          scriptEl.remove();
        }
      }
    };
  }, [APPLE_CLIENT_ID]);

  // ================== EMAIL / CODE LOGIC ==================
  const verifyEmailCode = useCallback(async (emailValue, code) => {
    setIsVerifying(true);
    setIsLoading(true);
    try {
      const data = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`,
        'POST',
        { email: emailValue, code }
      );
      if (data.token) {
        await handleLoginSuccess(data.token, data.is_new_user, data.user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Code expiré ou invalide. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
      setIsVerifying(false);
    }
  }, [handleLoginSuccess]);

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    await verifyEmailCode(email, verificationCode);
  };

  const verifyEmailToken = useCallback(async (token) => {
    setIsVerifying(true);
    setIsLoading(true);
    try {
      const data = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`,
        'POST',
        { token }
      );
      if (data.token && data.token.access) {
        await handleLoginSuccess(data.token, data.is_new_user, data.user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Lien expiré ou invalide. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
      setIsVerifying(false);
    }
  }, [handleLoginSuccess]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const emailExists = await checkEmailExists(email);
      if (!emailExists) {
        setShowEmailExistsAlert(true);
        return;
      }

      const data = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`,
        'POST',
        { email }
      );

      if (email === TEST_EMAIL) {
        // If we have the test email
        if (data.token && data.token.access) {
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        } else {
          throw new Error('Invalid response from server for test email');
        }
      } else {
        setVerificationMessage('Code de vérification envoyé. Vérifiez votre boite mail.');
        setCountdown(180);
        setShowVerificationView(true);
        setIsEmailSubmitted(true);
        setHasEmailBeenSent(true);
        setIsValidInput(false);
        setVerificationCode('');
      }
    } catch (error) {
      console.error('Error during email submission:', error);
      setVerificationMessage('Une erreur est arrivée. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      verifyEmailToken(token);
    }
  }, [location, verifyEmailToken]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      // clear verification message or do nothing
      localStorage.removeItem('codeCountdown');
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  const isValidEmail = (val) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(val);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidInput(isValidEmail(newEmail));
    setIsEmailSubmitted(false);
  };

  const handleVerificationCodeChange = (e) => {
    const newCode = e.target.value;
    setVerificationCode(newCode);
    setIsValidInput(newCode.length === 6);
  };

  useEffect(() => {
    if (authError) {
      setVerificationMessage(authError);
    }
  }, [authError]);

  const getAlertMessage = () => {
    return "Aucun compte n'est rattaché à cette adresse email.";
  };

  if (isVerifying) {
    return (
      <Container>
        Vérification en cours... Veuillez patienter.
      </Container>
    );
  }

  return (
    <Wrapper>
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>

      {/* If the email was sent, show a button to jump to code view if needed */}
      {hasEmailBeenSent && !showVerificationView && (
        <NextButton onClick={handleNext}>
          <IoIosArrowForward />
        </NextButton>
      )}

      <LogoImage src={Meo} alt="Compass Logo" isMobile={isMobile} />

      <Container>
        <EmailLoginContainer>
          {/* Either entering the email or verifying the code */}
          {!showVerificationView && (
            <form onSubmit={handleEmailSubmit}>
              <InputContainer>
                <StyledInput
                  type="email"
                  placeholder="Entrez votre email..."
                  value={email}
                  onChange={handleEmailChange}
                  disabled={countdown > 0}
                />
              </InputContainer>

              <SendCodeButton
                type="submit"
                disabled={!isValidInput || countdown > 0 || isEmailSubmitted}
              >
                {isLoading
                  ? '...'
                  : 'Envoyer un code de validation'}
              </SendCodeButton>
            </form>
          )}

          {showVerificationView && (
            <form onSubmit={handleVerificationSubmit}>
              {/* Text to say nous vous avons envoyé un code à l'adresse email */}
              {showVerificationView && (
                <Subtitle>
                  Code envoyé à {email}
                </Subtitle>
              )}
              <InputContainer>
                <StyledInput
                  type="text"
                  placeholder="Entrez le code à 6 chiffres"
                  value={verificationCode}
                  onChange={handleVerificationCodeChange}
                  maxLength={6}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </InputContainer>

              {/* "Confirmer" button reusing the same style as "Envoyer un code..." */}
              <SendCodeButton
                type="submit"
                disabled={verificationCode.length !== 6 || isLoading}
              >
                {isLoading ? '...' : 'Confirmer'}
              </SendCodeButton>
            </form>
          )}

          {/* Countdown display if there's a limit to resend the code */}
          {countdown > 0 && showVerificationView && (
            <CountdownContainer>
              <CountdownText>
                Vous pourrez renvoyer un code dans :{"\n"}
                {Math.floor(countdown / 60)}:
                {(countdown % 60).toString().padStart(2, '0')}
              </CountdownText>
            </CountdownContainer>
          )}

          {/* Display verification message if any */}
          {verificationMessage && (
            <div style={{ marginTop: '10px', textAlign: 'center', color: 'green' }}>
              {verificationMessage}
            </div>
          )}
        </EmailLoginContainer>

        {/* Divider + Google/Apple only if we are NOT in the code view */}
        {!showVerificationView && (
          <>
            <Divider>
              <DividerLine />
              <DividerText>ou</DividerText>
              <DividerLine />
            </Divider>

            {/* Google Button is shown on non-iOS devices */}
            {!isAppStore && (
              <GoogleButton onClick={handleGoogleLogin} disabled={isLoadingGoogle}>
                <GoogleButtonContent>
                  <GoogleIcon src={GoogleLogo} alt="Google" />
                  <GoogleText>Google</GoogleText>
                </GoogleButtonContent>
              </GoogleButton>
            )}

            {/* Apple Button is shown only on iOS devices */}
            {isAppStore && (
              <AppleButton onClick={handleAppleLogin} disabled={isLoading}>
                <AppleButtonContent>
                  <AppleIcon src={AppleLogo} alt="Apple" />
                  <AppleText>Apple</AppleText>
                </AppleButtonContent>
              </AppleButton>
            )}
          </>
        )}
      </Container>

      {showEmailExistsAlert && (
        <AlertOverlay>
          <AlertBox>
            <AlertMessage>{getAlertMessage()}</AlertMessage>
            <AlertButton onClick={handleAlertConfirm}>
              Ok
            </AlertButton>
          </AlertBox>
        </AlertOverlay>
      )}

      {errorMessage && (
        <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
      )}
    </Wrapper>
  );
};

export default LoginPage;