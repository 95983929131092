// FicheFormation.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InfoFormation from './InfoFormation';
import CorrespondanceFormation from './CorrespondanceFormation';

import {  
  ButtonsContainer, 
  CityContainer, 
  ButtonIcon, 
  CityText, 
  ActionButton, 
  ButtonText, 
  ButtonIconContainer,
  Container, 
  HeaderWrapper, 
  HeaderSvg, 
  HeaderContent, 
  HeaderTopRow, 
  ReturnButton, 
  Subtitle, 
  IconsContainer, 
  ShareIcon, 
  LikeIcon, 
  HeaderBottomRow, 
  FormationName, 
  SchoolName, 
  FloatingHeart,
  TabsContainer, 
  TabButton,
  ScrollableContent,
  RectangleDiv,
} from './styles';


import localisation_icon from '../../assets/localisation_icon.png';
import parcoursup_icon from '../../assets/out.png';
import school_icon from '../../assets/out.png';
import MatchIcon from '../../assets/correspondance_icon.png';
import InfoIcon from '../../assets/infos_icon.png';

import apiCall from '../../utils/api';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { MdOutlineShare } from 'react-icons/md';
import useDeviceType from '../useDeviceType';

const FicheFormation = () => {
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();
  const [formation, setFormation] = useState({});
  const [posNeg, setPosNeg] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [animateLike, setAnimateLike] = useState(false);
  const [animateShare, setAnimateShare] = useState(false);
  const [activeTab, setActiveTab] = useState('correspondance'); // 'correspondance' or 'infos'
  const lastClickTimeRef = useRef(0);
  const [clickPosition, setClickPosition] = useState(null);
  const DOUBLE_CLICK_THRESHOLD = 300;
  const [headerHeight, setHeaderHeight] = useState('230px');
  const formationNameRef = useRef(null);

  // Extract formation id from URL
  const searchParams = new URLSearchParams(location.search);
  const idFormation = searchParams.get('id');

  useEffect(() => {
    if (formationNameRef.current && formation.nom_formation) {
      const element = formationNameRef.current;
      const computedStyle = window.getComputedStyle(element);
      const lineHeight = parseFloat(computedStyle.lineHeight) || 1.3 * 19.2; // 1.3 is the line-height, 19.2px is font-size
      const height = element.scrollHeight;
      
      // Estimate number of lines
      const lines = Math.ceil(height / lineHeight);
      
      // Set header height based on number of lines
      const baseHeight = 160; // Minimum height for one line
      const additionalHeightPerLine = 24; // Height to add per line
      const calculatedHeight = baseHeight + (Math.min(lines - 1, 3) * additionalHeightPerLine);
      
      setHeaderHeight(`${calculatedHeight}px`);
    }
  }, [formation.nom_formation]);

  // Add this useEffect to track card visits
  useEffect(() => {
    const trackCardVisit = async () => {
      try {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/track-card-visit/`,
          'POST',
          { 
            formation_id: idFormation,
            interaction_type: 'view'  // Track this as a view
          }
        );
      } catch (error) {
        console.error('Error tracking card visit:', error);
      }
    };

    if (idFormation && !loading) {
      trackCardVisit();
    }
  }, [idFormation, loading]); // Track when component loads with formation data

  const trackInteraction = async (type) => {
    try {
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/track-card-visit/`,
        'POST',
        { 
          formation_id: idFormation,
          interaction_type: type // 'site' or 'parcoursup'
        }
      );
      console.log(`${type} interaction tracked for card ${idFormation}`);
    } catch (error) {
      console.error(`Error tracking ${type} interaction:`, error);
    }
  };

  const handleHeaderClick = (event) => {
    // Avoid double-triggering when clicking on share/like icons
    if (event.target.closest('[data-exclude="true"]')) return;
  
    const now = Date.now();
    if (now - lastClickTimeRef.current < DOUBLE_CLICK_THRESHOLD) {
      const rect = event.currentTarget.getBoundingClientRect();
      setClickPosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        key: Date.now()
      });
      
      if (!isFavorite) {
        setAnimateLike(true);
        handleLike();
        setTimeout(() => setAnimateLike(false), 500);
      }
    }
    lastClickTimeRef.current = now;
  };

  useEffect(() => {
    const need_update_ranking_formation = JSON.parse(localStorage.getItem("need_update_ranking_formation"));
    const fetchPosNeg = async () => {
      try {
        // Check if there is a ranking in local storage with the id
        var posNeg = null
        console.log('[DEBUG] NEEDUPDATE:', need_update_ranking_formation);
        if (!need_update_ranking_formation) {
          posNeg = JSON.parse(localStorage.getItem("pos_neg"));
          console.log('[DEBUG] Ranking from local storage:', posNeg);
        }
        if (!posNeg) {
          const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/pos-neg/`;
          posNeg = await apiCall(url, 'GET');
        }
        localStorage.setItem("pos_neg", JSON.stringify(posNeg));
        setPosNeg(posNeg);
      } catch (err) {
        console.error('Erreur pos-neg:', err);
      }
    };

    const fetchFormation = async () => {
      try {
        // Check if there is a ranking in local storage with the id
        var ranking = null
        console.log('[DEBUG] NEEDUPDATE:', need_update_ranking_formation);
        if (!need_update_ranking_formation) {
          const data = JSON.parse(localStorage.getItem("ranking_formation"));
          ranking = data ? Object.values(data).find(r => r.id === idFormation) : null;
          console.log('[DEBUG] Ranking from local storage:', ranking);
        }
        if (!ranking) {
          const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/ficheFormation/?id=${idFormation}`;
          ranking = await apiCall(url, 'GET');
        }
        setFormation(ranking);
        
        // Set favorite state from the formation data
        setIsFavorite(ranking.is_favorite || false);
        
        setLoading(false);
      } catch (err) {
        console.error('Erreur formation:', err);
        setError(true);
        setLoading(false);
      }
    };

    if (idFormation) {
      fetchFormation();
      fetchPosNeg();
    } else {
      setError(true);
      setLoading(false);
    }
  }, [idFormation]);

  const handleLike = async () => {
    try {
      // Toggle favorite status in backend
      if (isFavorite) {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`,
          'DELETE',
          { id: idFormation }
        );
        setIsFavorite(false);
      } else {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_formation/`,
          'POST',
          { 
            id: idFormation, 
            nom: formation.nom_formation, 
            etablissement: formation.nom_ecole, 
            matchPercentage: formation?.score_formations || 0 
          }
        );
        setIsFavorite(true);
      }
      
      // Update the formation object in state
      setFormation(prevFormation => ({
        ...prevFormation,
        is_favorite: !isFavorite
      }));
      
      // Update the formation object in localStorage ranking_formation
      const cachedRankings = JSON.parse(localStorage.getItem("ranking_formation"));
      if (cachedRankings) {
        // Find the formation in any ranking position (top1, top2, etc.)
        let foundAndUpdated = false;
        
        Object.keys(cachedRankings).forEach(key => {
          if (cachedRankings[key].id === idFormation) {
            cachedRankings[key].is_favorite = !isFavorite;
            foundAndUpdated = true;
          }
        });
        
        // If we found and updated the formation, save the updated rankings
        if (foundAndUpdated) {
          localStorage.setItem("ranking_formation", JSON.stringify(cachedRankings));
        }
      }
      
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('Lien copié dans le presse‑papiers');
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur de chargement</div>;

  return (
    <Container isMobile={isMobile}>
      {/* HEADER */}
      <HeaderWrapper isMobile={isMobile} height={headerHeight} onClick={handleHeaderClick}>
        {clickPosition && (
          <FloatingHeart
            key={clickPosition.key}
            style={{
              left: `${clickPosition.x}px`,
              top: `${clickPosition.y}px`,
            }}
            onAnimationEnd={() => setClickPosition(null)}
          >
            <FaHeart />
          </FloatingHeart>
        )}

        <HeaderSvg
          xmlns="http://www.w3.org/2000/svg"
          width="379"
          height="168"
          viewBox="0 0 379 168"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 25C0 11.1929 11.1929 0 25 0H354C367.807 0 379 11.1929 379 25V143C379 156.807 367.807 168 354 168H25C11.1929 168 0 156.807 0 143V25Z"
            fill="url(#paint0_linear_287_452)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_287_452"
              x1="0"
              y1="84"
              x2="379"
              y2="84"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CC00FF" />
              <stop offset="1" stopColor="#0F04E3" />
            </linearGradient>
          </defs>
        </HeaderSvg>

        <HeaderContent>
          {/* Ligne supérieure avec bouton retour, titre et icônes */}
          <HeaderTopRow>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <ReturnButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleBack();
                }}
              >
                <IoIosArrowBack />
              </ReturnButton>
              <Subtitle>Formation</Subtitle>
            </div>

            <IconsContainer>
              <ShareIcon
                $animate={animateShare}
                onClick={(e) => {
                  setAnimateShare(true);
                  e.stopPropagation();
                  handleShare();
                  setTimeout(() => setAnimateShare(false), 300);
                }}
              >
                <MdOutlineShare />
              </ShareIcon>
              <LikeIcon
                data-exclude="true"
                $animate={animateLike}
                $isFavorite={isFavorite}
                onClick={handleLike}
              >
                {isFavorite ? <FaHeart /> : <FaRegHeart />}
              </LikeIcon>
            </IconsContainer>
          </HeaderTopRow>

          {/* Nouvelle ligne avec le nom de la formation / école */}
          <HeaderBottomRow>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormationName ref={formationNameRef} title={formation.nom_formation}>
                {formation.nom_formation}
              </FormationName>
              <SchoolName title={formation.nom_ecole}>
                {formation.nom_ecole.split('(')[0].trim()}
              </SchoolName>
            </div>
          </HeaderBottomRow>

          {/* Ligne inférieure avec les boutons d'actions (localisation, site, etc.) */}
          <ButtonsContainer>
            <CityContainer>
              <ButtonIcon src={localisation_icon} alt="Location" />
              <CityText>{formation.ville.slice(0, 20)}</CityText>
            </CityContainer>
            
            {formation.site && (
            <ActionButton 
              href={formation.site} 
              target="_blank"
              onClick={(e) => {
                // Don't prevent default - we still want the link to work
                trackInteraction('site');
              }}
            >
              <ButtonText>Site de l'école</ButtonText>
              <ButtonIconContainer>
                <ButtonIcon src={school_icon} alt="School" />
              </ButtonIconContainer>
            </ActionButton>
          )}

          {formation.link && (
            <ActionButton 
              href={formation.link} 
              target="_blank"
              onClick={(e) => {
                // Don't prevent default - we still want the link to work
                trackInteraction('parcoursup');
              }}
            >
              <ButtonText>Parcoursup</ButtonText>
              <ButtonIconContainer>
                <ButtonIcon src={parcoursup_icon} alt="Parcoursup" />
              </ButtonIconContainer>
            </ActionButton>
          )}
          </ButtonsContainer>
        </HeaderContent>
      </HeaderWrapper>

      {/* TABS */}
      <TabsContainer headerHeight={headerHeight}>
        <TabButton
          active={activeTab === 'correspondance'}
          onClick={() => setActiveTab('correspondance')}
        >
          <img
            src={MatchIcon}
            alt="Match icon"
            style={{
              width: '18px',
              height: '18px',
            }}
          />
          <span>Match</span>
        </TabButton>

        <TabButton
          active={activeTab === 'infos'}
          onClick={() => setActiveTab('infos')}
        >
          <img
            src={InfoIcon}
            alt="Infos icon"
            style={{
              width: '18px',
              height: '18px',
            }}
          />
          <span>Infos</span>
        </TabButton>
      </TabsContainer>
      <RectangleDiv headerHeight={headerHeight} />

      {/* Scrollable container for tab content */}
      <ScrollableContent headerHeight={headerHeight}>
        {activeTab === 'correspondance' ? (
          <CorrespondanceFormation formation={formation} posNeg={posNeg} headerHeight={headerHeight} />
        ) : (
          <InfoFormation formation={formation} headerHeight={headerHeight} />
        )}
      </ScrollableContent>
    </Container>
  );
};

export default FicheFormation;