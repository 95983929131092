 // 1) All possible items for each filter
 export const allDomains = [
    'Arts du Spectacle',
    'Audiovisuel - Cinéma - Photographie',
    'Communication visuelle - Dessin - Animation 3D',
    'Design',
    "Marché de l'art - Culture - Patrimoine",
    'Mode - Textile',
    'Luxe',
    'Artisanat',
    'Sport - Management sportif',
    'Enseignement',
    'Santé (Médical-Paramédical)',
    'Travailler avec des enfants',
    'ESport - Gaming',
    'Tourisme - Loisirs',
    'Droit - Justice - Sciences Politiques',
    'Fonction publique',
    'Humanitaire',
    'Journalisme',
    'Communication',
    'Social',
    'Sécurité - Armée - Défense',
    "Entrepreneuriat - Création d'entreprise",
    "Edition - Métiers du livre",
    'Psychologie - Sociologie',
    'Langues étrangères - Traduction',
    'Commerce - Distribution - Management',
    'Hôtellerie - Restauration',
    'Transport - Logistique',
    'Mathématiques - Sciences Physiques',
    'Finance',
    'Banque - Assurance',
    'Comptabilité - Gestion - Audit',
    'Informatique - Numérique',
    'Cybersécurité',
    'Le nucléaire',
    'Internet - Web - Multimédia',
    'Jeux vidéo',
    'Métiers et Formations de la Blockchain & du Métavers',
    'Chimie - Biologie',
    'Énergie',
    'Industrie - Qualité',
    'Environnement - Développement durable',
    'Métiers animaliers',
    'Agriculture',
    'Agroalimentaire',
    'Métiers de la mer',
    'Métiers du vin',
    'Architecture - Urbanisme - Paysagisme',
    'Automobile - Moto',
    'Bâtiment et travaux publics (BTP)',
    'Électronique',
    'Aéronautique et aérospatial',
    'Ressources humaines',
    'Publicité - Marketing',
    'Immobilier',
    'Métiers de bouche',
  ];

  export const allDurations = ['1 an', '2 ans', '3 ans', '4 ans', '5 ans', '6 ans', '7 ans', '8 ans'];

  export const allFamilles_map_label = {
    // classe_preparatoire: 'Classe Préparatoire',
    // grande_ecole_post_bac: 'Ecole Post-Bac',
    // universite_professionnalisante: 'Université Professionnalisante',
    // universite_theorique: 'Université Théorique',
    // classe_professionnalisante: 'Classe Professionnalisante',

    L1 : "Licence (Université)",
    "Grande école post bac" : "Grandes écoles post bac",
    BUT : "BUT (Brevet universitaire technologique)",
    BTS : "BTS (Brevet de technicien supérieur)",
    CPGE : "CPGE (Classes préparatoires aux grandes écoles)",
    DEUST : "DEUST",
    CS : "Certificat de spécialisation",
    "C.M.I" : "Cursus master ingénieurie",
    CUPGE : "Classes universitaires préparatoires aux grandes écoles",
    "Formations préparatoires à l'enseignement supérieur": "Formations préparatoires à l'enseignement supérieur"
  };


  // For cities:

