// src/components/MobileHistoryTab/styles.js
import styled from 'styled-components';

export const CloseButtonWrapper = styled.div`
  position: absolute;
  left: ${props => props.theme.spacing.medium};
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #FFC1C1;
  background: rgba(217, 217, 217, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;


export const Header = styled.div`
  position: relative;
  padding: ${props => props.theme.spacing.xlarge} 0 ${props => props.theme.spacing.medium};
  width: 110%;
`;

export const Title = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  line-height: 1;
  margin-right: 5%;
`;

export const Line = styled.div`
  width: 110%;
  height: 0.5px;
  background: #FFC1C1;
  transform: rotate(0.067deg);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
`;

export const MobileHistoryContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};
  opacity: ${props => props.$isOpen ? 1 : 0};
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1603;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  /* Hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1602;
  opacity: ${({ $isOpen }) => $isOpen ? 1 : 0};
  visibility: ${({ $isOpen }) => $isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  margin-top: 9%;
  color: ${props => props.theme.colors.primary};
  padding: ${props => props.theme.spacing.small};
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(-2px);
  }

  &:active {
    opacity: 0.8;
  }
`;

export const HistoryContent = styled.div`
  flex: 1;
  padding: 0 ${props => props.theme.spacing.medium};
  overflow-y: auto;

  /* Hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileMenuButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.spacing.medium};
  left: ${props => props.isMobile ? '10px' : '20px'};
  z-index:10;
  transition: transform 0.3s ease-in-out;
  transform: none;
`;

export const HistoryList = styled.ul`
  list-style-type: none;
  padding: ${props => props.theme.spacing.medium};
  margin: 0;
`;

export const HistoryItem = styled.li`
  padding: ${props => props.theme.spacing.small} 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${props => props.theme.colors.veryLightGray};
  }

  &:last-child {
    border-bottom: none;
  }

`;

export const HistoryItemText = styled.span`
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

export const EmptyHistoryMessage = styled.p`
  color: ${props => props.theme.colors.mediumGray};
  font-size: ${props => props.theme.fontSizes.medium};
  text-align: center;
  padding: ${props => props.theme.spacing.large};
`;
