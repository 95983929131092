import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin-top: 20px;
  position: relative;
  margin: 0 auto;
  // width
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
  }
`;

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  border-radius: 10px 0 0 10px;
  background-color: #f8f8f8;
  border: 2px solid #ddd;
  border-right: none;
  cursor: pointer;
  position: relative;
  min-width: 55px;
  height: 50px;
  justify-content: center;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const FlagImage = styled.img`
  width: 32px;
  height: 24px;
  border-radius: 2px;
  object-fit: cover;
`;

export const CountryDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
  margin-top: 5px;
  padding: 5px 0;
  border: 1px solid #eee;
`;

export const CountryOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#f0f8ff' : 'transparent'};

  &:hover {
    background-color: #f5f5f5;
  }

  span {
    font-size: 14px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${FlagImage} {
    width: 28px;
    height: 20px;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 15px;
  border: 2px solid ${props => props.isValid ? '#2ecc71' : props.value ? '#e74c3c' : '#ddd'};
  border-radius: 0 10px 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  background-color: white;
  transition: all 0.3s ease;
  font-family: 'Gabarito', sans-serif;
  letter-spacing: 1px;
  height: 50px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${props => props.isValid ? '#27ae60' : '#3498db'};
  }

  &::placeholder {
    color: #aaa;
    opacity: 0.7;
    font-weight: 500;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;
