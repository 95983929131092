import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormationsClassement from './formationsClassement';
import FilieresClassement from './filieresClassement';
import {
  Container,
  ContentWrapper,
  TopImage,
  ToggleText,
  ToggleIcon,
  ToggleLabel,
  ToggleThumb,
  ToggleTrack,
  PrecisionToggle,
  SearchContainer,
  SearchWrapper,
  CityFilterVertical,
  ToggleContainer,
  ToggleControlContainer,
  // CityFilterVertical

} from './styles';

import FilteringBrowser from './FilteringBrowser';

import cibleIcon from '../../assets/cible_icon.png';
import viserIcon from '../../assets/viser_icon.png';
import MeoBackground from '../../assets/meo_explore.png';
import useDeviceType from '../useDeviceType';
import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { allCitiesFormations } from '../data'; // Assurez-vous que ce chemin est correct

// 2. Ajoutez ces styles dans le fichier styles.js
export const CityFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const CityFilterLabel = styled.div`
  color: #626060;
  font-family: Gabarito;
  font-size: 14px;
  margin-right: 8px;
`;

export const CityFilterDropdown = styled.div`
  position: relative;
  font-family: Gabarito;
`;

export const SelectedCity = styled.div`
  display: flex;
  align-items: center;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #E0E0E0;
  
  &:hover {
    background: #EFEFEF;
  }
`;

export const CityDropdownIcon = styled(IoIosArrowDown)`
  margin-left: 6px;
  color: #626060;
`;

export const CityDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  width: 220px;
`;

export const CityDropdownSearch = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 8px 8px 0 0;
  font-family: Gabarito;
  
  &:focus {
    outline: none;
  }
`;

export const CityOption = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: #F5F5F5;
  }
`;

export const AllFranceOption = styled(CityOption)`
  color: #38B6FF;
  font-weight: bold;
  border-top: 1px solid #E0E0E0;
`;

const CityFilter = ({ selectedCity, onCityChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (searchValue.trim() === '') {
      setFilteredCities([]);
      return;
    }

    const normalized = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const filtered = allCitiesFormations
      .filter(city => city.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(normalized))
      .sort((a, b) => {
        const aStartsWith = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().startsWith(normalized);
        const bStartsWith = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().startsWith(normalized);
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return a.localeCompare(b);
      })
      .slice(0, 10);
    
    setFilteredCities(filtered);
  }, [searchValue]);

  const handleSelectCity = (city) => {
    onCityChange(city);
    setIsOpen(false);
    setSearchValue('');
  };

  return (
    <CityFilterVertical>
      <CityFilterLabel>Autour de :</CityFilterLabel>
      <CityFilterDropdown ref={dropdownRef}>
        <SelectedCity onClick={() => setIsOpen(!isOpen)}>
          {selectedCity || 'Toute la France'}
          <CityDropdownIcon />
        </SelectedCity>
        
        {isOpen && (
          <CityDropdownMenu>
            <CityDropdownSearch
              placeholder="ville/département/région..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
            
            {filteredCities.map((city, index) => (
              <CityOption key={index} onClick={() => handleSelectCity(city)}>
                {city}
              </CityOption>
            ))}
            
            <AllFranceOption onClick={() => handleSelectCity('Toute la France')}>
              Toute la France
            </AllFranceOption>
          </CityDropdownMenu>
        )}
      </CityFilterDropdown>
    </CityFilterVertical>
  );
};




const Classement = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  // Récupérer les données utilisateur pour vérification du profil
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : {};
  const [forceUpdateTimestamp, setForceUpdateTimestamp] = useState(Date.now());
  // Onglet actif (persist dans localStorage)
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeClassementTab') || 'decouvrir');
  // 4. Modifications à apporter dans le composant Classement à l'intérieur de index.js
// Ajoutez cet état pour gérer la ville sélectionnée
  const [selectedCity, setSelectedCity] = useState(() => {
    const storedCity = localStorage.getItem('includeCity');
    // Utiliser user_city par défaut si disponible
    if (!storedCity && user && user.user_city) {
      localStorage.setItem('includeCity', user.user_city);
      return user.user_city;
    }
    return storedCity || 'Toute la France';
  });

  // Ajoutez cette fonction pour gérer le changement de ville
  const handleCityChange = (city) => {
    setSelectedCity(city);
    localStorage.setItem('includeCity', city);
    
    // Déclencher une mise à jour du classement
    localStorage.setItem('need_update_ranking_formation', 'true');
    localStorage.setItem('need_update_ranking', 'true');
    setForceUpdateTimestamp(Date.now());
  };


  // Add these state variables at the top of the component
  const [searchKeyword, setSearchKeyword] = useState(() => {
    const stored = localStorage.getItem('searchKeyword');
    return stored || "";
  });

  const [searchKeywordFiliere, setSearchKeywordFiliere] = useState(() => {
    const stored = localStorage.getItem('searchKeywordType');
    return stored || "";
  });

  const searchInputRef = useRef(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  // Add these handlers
  const handleSearchChange = (value) => {
    console.log(`Search keyword changed to: ${value}`);
    setSearchKeyword(value);
    localStorage.setItem('searchKeyword', value);
    
    // Force a re-render by explicitly setting need_update flag
    localStorage.setItem('need_update_ranking_formation', 'true');
  };
  
  const handleSearchChangeFiliere = (value) => {
    console.log(`Search keyword filiere changed to: ${value}`);
    setSearchKeywordFiliere(value);
    localStorage.setItem('searchKeywordType', value);
    
    // Force a re-render
    localStorage.setItem('need_update_ranking', 'true');
  };
  useEffect(() => {
    const handleStorageChange = () => {
      const storedKeyword = localStorage.getItem('searchKeyword');
      const storedKeywordFiliere = localStorage.getItem('searchKeywordType');
      
      // Only update if values have changed
      if (storedKeyword !== null && storedKeyword !== searchKeyword) {
        console.log(`Updating searchKeyword from storage: ${storedKeyword}`);
        setSearchKeyword(storedKeyword);
      }
      
      if (storedKeywordFiliere !== null && storedKeywordFiliere !== searchKeywordFiliere) {
        console.log(`Updating searchKeywordFiliere from storage: ${storedKeywordFiliere}`);
        setSearchKeywordFiliere(storedKeywordFiliere);
      }
    };
    
    // Listen for storage events (when localStorage changes in another tab)
    window.addEventListener('storage', handleStorageChange);
    
    // Check for changes on component mount
    handleStorageChange();
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [searchKeyword, searchKeywordFiliere]);  

  const handleSearchSubmit = () => {
    console.log("Search submitted with keyword:", searchKeyword);
    localStorage.setItem('need_update_ranking_formation', 'true');
    
    // Force FormationsClassement to re-render with new data
    // by updating a key timestamp
    setForceUpdateTimestamp(Date.now());
  };
  
  // Update the handleSearchSubmitFiliere function
  const handleSearchSubmitFiliere = () => {
    console.log("Search submitted with filiere keyword:", searchKeywordFiliere);
    localStorage.setItem('need_update_ranking', 'true');
    
    // Force FilieresClassement to re-render with new data
    setForceUpdateTimestamp(Date.now());
  };
  
  useEffect(() => {
    localStorage.setItem('activeClassementTab', activeTab);
  }, [activeTab]);

  // Reset filter modal when tab changes
  useEffect(() => {
    setShowFilterModal(false);
  }, [activeTab]);

  // Handle filter icon click
  const handleFilterClick = () => {
    setShowFilterModal(true);
  };

  // Vérifier la version de l'application
  useEffect(() => {
    const localVersion = localStorage.getItem('version_app');
    const appVersion = process.env.REACT_APP_VERSION || '0.0.1';

    if (!localVersion || localVersion !== appVersion) {
      localStorage.setItem('need_update_ranking', 'true');
      localStorage.setItem('need_update_ranking_formation', 'true');
      localStorage.setItem('version_app', appVersion);
    }
  }, []);

  // ====== Vérification du profil utilisateur ======
  const missingFields = [];
  if(!user.user_type || user.user_type.trim() === '' || user.user_type === "autre") {
    missingFields.push({
      field: 'user_type',
      label: 'Type d\'utilisateur',
      route: '/profile/modify/user-type',
    });
  }

  if (!user.type_bac || user.type_bac.trim() === '') {
    missingFields.push({
      field: 'type_bac',
      label: 'Type de bac',
      route: '/profile/modify/type-bac',
    });
  }

  if (!user.specialites || user.specialites.length === 0) {
    missingFields.push({
      field: 'specialites',
      label: 'Spécialités',
      route: '/profile/modify/specialites',
    });
  }

  if (!user.grades || Object.keys(user.grades).includes('general') === false) {
    missingFields.push({ 
      field: 'grades', 
      label: 'Moyennes', 
      route: '/profile/modify/grades' 
    });
  }
  
  if (!user.sub_domaine_formation || user.sub_domaine_formation.length === 0) {
    missingFields.push({
      field: 'sub_domaine_formation',
      label: 'Sous-domaine de formation',
      route: '/profile/modify/domaines-favoris',
    });
  }

  if (!user.user_city || user.user_city.trim() === '' || user.user_city === 'Aucune idée') {
    missingFields.push({
      field: 'city',
      label: 'Ville',
      route: '/profile/modify/city',
    });
  }

  // Si le profil est incomplet, afficher un message pour compléter le profil
  if (missingFields.length > 0) {
    const item = missingFields[0];
    return (
      <Container isMobile={isMobile}>
        <ContentWrapper>
            <TopImage style={{ backgroundImage: `url(${MeoBackground})` }} />
          <div style={{ padding: '2rem', textAlign: 'center' }}>
            <p style={{ marginBottom: '1.5rem', fontSize: '1.1rem' }}>
              Pour accéder au classement, veuillez compléter votre profil :
            </p>
            <div key={item.field} style={{ margin: '1rem 0' }}>
              <button
                style={{
                  padding: '0.5rem 1rem',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: 'black',
                  color: '#fff',
                }}
                onClick={() => navigate(item.route)}
              >
                Compléter votre profil
              </button>
            </div>
          </div>
        </ContentWrapper>
      </Container>
    );
  }

 // In the PrecisionToggle section of the return statement, 
// we'll modify the CityFilter component to only show when activeTab is 'decouvrir'

return (
  <Container isMobile={isMobile}>
    <ContentWrapper>
      <TopImage style={{ backgroundImage: `url(${MeoBackground})` }} />
      
      <SearchWrapper>
        <SearchContainer>
          <FilteringBrowser
            filterType={activeTab === 'decouvrir' ? "search_keyword" : "search_keyword_filiere"}
            onClose={() => {}}
            items={[]}
            selectedItems={activeTab === 'decouvrir' ? searchKeyword : searchKeywordFiliere}
            onSelectedItemsChange={activeTab === 'decouvrir' ? handleSearchChange : handleSearchChangeFiliere}
            toggleButtonRef={searchInputRef}
            onSubmit={activeTab === 'decouvrir' ? handleSearchSubmit : handleSearchSubmitFiliere}
            activeTab={activeTab}
            onFilterClick={handleFilterClick} // Pass the filter click handler
          />
        </SearchContainer>
      </SearchWrapper>
      <PrecisionToggle>
        <ToggleContainer>
          <ToggleLabel>Degré de précision :</ToggleLabel>
          <ToggleControlContainer onClick={() => setActiveTab(activeTab === 'decouvrir' ? 'type' : 'decouvrir')}>
            <ToggleIcon 
              src={activeTab === 'decouvrir' ? cibleIcon : viserIcon} 
              alt={activeTab === 'decouvrir' ? "formation icon" : "filiere icon"} 
            />
            <ToggleTrack active={activeTab === 'type'}>
              <ToggleThumb active={activeTab === 'type'} />
            </ToggleTrack>
            <ToggleText style={{ marginLeft: '4px', width: '80px' }}>
              {activeTab === 'decouvrir' ? 'Formations' : 'Filières'}
            </ToggleText>
          </ToggleControlContainer>
        </ToggleContainer>
        
        {/* Filtre de ville - n'afficher que si activeTab est 'decouvrir' */}
        {activeTab === 'decouvrir' && (
          <CityFilter selectedCity={selectedCity} onCityChange={handleCityChange} />
        )}
      </PrecisionToggle>

    {activeTab === 'decouvrir' ? 
      <FormationsClassement 
        key={`formations-${searchKeyword}-${forceUpdateTimestamp}`}
        searchKeyword={searchKeyword} 
        showFilterModal={showFilterModal}
        setShowFilterModal={setShowFilterModal}
      /> : 
      <FilieresClassement 
        key={`filieres-${searchKeywordFiliere}-${forceUpdateTimestamp}`}
        searchKeyword={searchKeywordFiliere} 
        showFilterModal={showFilterModal}
        setShowFilterModal={setShowFilterModal}
      />
    }
    </ContentWrapper>
  </Container>
);
};

export default Classement;