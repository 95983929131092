// src/utils/auth.js

export const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};

export const getUser = () => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.clear();
};

export const isAuthenticated = () => {
  const user = getUser();
  return !!user;
};

// NEW: Check if access token cookie exists (adjust the cookie name as needed)
export const hasToken = () => {
  return document.cookie
    .split(';')
    .some(cookie => cookie.trim().startsWith('access_token='));
};
