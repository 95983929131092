import styled from 'styled-components';

/* =========================================================
   1. Overlay & Container
   ========================================================= */

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1602;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const MobileHistoryContainer = styled.div`
  /* The main container overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1603;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/* =========================================================
   2. Header
   ========================================================= */

/*
  1) Header
  - Sticky, centered horizontally & vertically
  - z-index to ensure it's on top
*/
export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1602;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 70px; /* Adjust if needed */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the title horizontally */
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  left: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid #ffd4d5;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary || '#ff5678'};
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(-2px);
  }
  &:active {
    opacity: 0.8;
  }
`;

/* Centered text */
export const Title = styled.h2`
  margin: 0;
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: #000;
`;

/* Divider line at the bottom of the header */
export const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px;
  background: #ffd4d5;
`;

/* =========================================================
   3. “Aide nous à améliorer l’app” Box
   ========================================================= */


   export const ScrollContent = styled.div`
   width: 95%;
   flex: 1;
   padding: 20px;
   overflow-y: auto;
   margin-top: -10%;

   &::-webkit-scrollbar {
     display: none;
   }
   scrollbar-width: none; /* Firefox */
 `;

export const BoxAide = styled.div`
  width: 100%;
  max-width: 100%;
  height: 122px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #ffd400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing.big};
  padding: ${({ theme }) => theme.spacing.medium};
  cursor: pointer;
`;

export const AideImage = styled.img`
  width: 38px;
  height: 33px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

export const AideTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensures text is left-aligned */
  justify-content: center;
  flex: 1; /* Allows the text to take up available space */
`;

export const AideTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  text-align: left;
`;

export const AideSubtitle = styled.div`
  color: #000;
  font-family: 'Geist';
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  white-space: pre-line; /* allows \n to create new lines */
  text-align: left;
`;

export const AideArrow = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.spacing.small};
  transform: scaleX(-1); /* Flips the image horizontally */
`;

/* =========================================================
   4. Email Box
   ========================================================= */

export const BoxEmail = styled.div`
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  flex-direction: column; /* Stack title above email */
  align-items: flex-start; /* Align everything to the left */
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const BoxEmailTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall}; /* Space between title and email */
`;

export const BoxEmailContent = styled.div`
  color: #9e9d9d;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  width: 100%; /* Ensure full width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

/* =========================================================
   5. Infos sur l’app
   ========================================================= */

export const BoxInfos = styled.div`
  width: 100%;
  max-width: 100%;
  height: 102px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const InfosTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  text-align: left;
`;

export const InfosText = styled.div`
  color: #9e9d9d;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  text-align: left;
`;

/* =========================================================
   6. Social box
   ========================================================= */

export const BoxSocial = styled.div`
  width: 100%;
  max-width: 100%;
  height: 108px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SocialTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

export const SocialIconsWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
`;

export const SocialIcon = styled.img`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
`;

/* =========================================================
   7. CGU box
   ========================================================= */

export const BoxCGU = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const CGUImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.small};
  flex-shrink: 0;
`;

export const CGUText = styled.a`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

/* =========================================================
   8. Actions (Logout / Delete)
   ========================================================= */

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  gap: ${({ theme }) => theme.spacing.small};
`;

export const LogoutText = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #9e9d9d; /* same color as email text */
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-align: left;

  &:hover {
    opacity: 0.7;
  }
`;

export const DeleteText = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 149px;
  color: #ff2626;
  -webkit-text-stroke-width: 0.01px;
  -webkit-text-stroke-color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-align: left;

  &:hover {
    opacity: 0.7;
  }
`;

export const BoxDelete = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  max-height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const VersionText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  color: #9e9d9d;
  text-align: center;
  width: 100%;
`;

export const TokenText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  color: #9e9d9d;
  text-align: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const BoxLogout = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  max-height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;