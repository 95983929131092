import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import compassImage from '../../assets/meo_normal.png';

// Import the 6 icons from your assets folder
import icon1 from '../../assets/debouches_icon.png';
import icon2 from '../../assets/chances_admission_icon.png';
import icon3 from '../../assets/chat_icon.png';
import icon4 from '../../assets/programme_cours.png';
import icon5 from '../../assets/suggestion_icon.png';
import icon6 from '../../assets/boussole_unboarding.png';

import { IoIosArrowBack } from "react-icons/io";

// Import shared layout components from Onboarding styles
import { 
  Container, 
  ContentWrapper, 
  ReturnButton,
  SubmitButton,
  ButtonText
 } from '../Onboarding/styles';

// Import Meo‑specific styled components (including our new logo & icons containers)
import {
  MeoChatBubbleContainer,
  MeoChatText,
  SalutJeContainer,
  PetitesQuestions,
  LogoContainer,
  CenteredLogoImage,
  IconsWrapper,
  IconImage,
} from './styles';

const fullMessage =
  "Salut, je suis Meo ! Réponds à 7 petites questions pour que je prenne en main ton orientation !";

  const MeoFirstMessage = () => {
    const [isTyping, setIsTyping] = useState(true);
    const [displayedText, setDisplayedText] = useState('');
    // New state to track whether the button is pressed
    const [buttonActive, setButtonActive] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      let currentIndex = 0;
      const typingInterval = setInterval(() => {
        if (currentIndex <= fullMessage.length) {
          setDisplayedText(fullMessage.slice(0, currentIndex));
          currentIndex++;
        } else {
          setIsTyping(false);
          clearInterval(typingInterval);
        }
      }, 15); // Adjust typing speed if needed
  
      return () => clearInterval(typingInterval);
    }, []);
  
    const handleBack = () => {
      navigate('/');
    };
  
    const handleCreateAccount = () => {
      navigate('/pseudo');
    };
  
    // When the user starts pressing (mouse down / touch start)
    const handlePressStart = () => {
      if (isTyping) return;
      setButtonActive(true);
    };
  
    // When the user releases (mouse up / touch end)
    const handlePressEnd = () => {
      if (isTyping) return;
      setButtonActive(false);
      // Delay navigation slightly so the button animates back
      setTimeout(() => {
        handleCreateAccount();
      }, 150);
    };
  
    return (
      <Container>
        <ContentWrapper>
          <ReturnButton onClick={handleBack}>
            <IoIosArrowBack />
          </ReturnButton>
  
          <MeoChatBubbleContainer>
            {isTyping ? (
              <MeoChatText>{displayedText}</MeoChatText>
            ) : (
              <MeoChatText>
                <SalutJeContainer>
                  Salut ! Je suis Meo. Réponds à{' '}
                  <PetitesQuestions>7 petites questions</PetitesQuestions> pour que je prenne en main ton orientation !
                </SalutJeContainer>
              </MeoChatText>
            )}
          </MeoChatBubbleContainer>
  
          {/* Logo and Icons container */}
          <LogoContainer>
            <CenteredLogoImage src={compassImage} alt="Compass Logo" />
            <IconsWrapper>
              <IconImage
                src={icon1}
                alt="Icon 1"
                customStyle={`top: -10px; left: -20%; transform: translateX(-50%);`}
                delay="0s"
              />
              <IconImage
                src={icon2}
                alt="Icon 2"
                customStyle={`top: 60px; left: -50px;`}
                delay="0.1s"
              />
              <IconImage
                src={icon3}
                alt="Icon 3"
                customStyle={`bottom: -35px; left: 8%;`}
                delay="0.2s"
              />
              <IconImage
                src={icon4}
                alt="Icon 4"
                customStyle={`bottom: -35px; right: 8%;`}
                delay="0.3s"
              />
              <IconImage
                src={icon5}
                alt="Icon 5"
                customStyle={`top: 60px; right: -50px;`}
                delay="0.4s"
              />
              <IconImage
                src={icon6}
                alt="Icon 6"
                customStyle={`top: -10px; right: -20%; transform: translateX(-50%);`}
                delay="0.5s"
              />
            </IconsWrapper>
          </LogoContainer>
  
          <SubmitButton 
            /* For desktops: onMouseDown/Up and for mobile: onTouchStart/End */
            onMouseDown={handlePressStart}
            onMouseUp={handlePressEnd}
            onMouseLeave={() => setButtonActive(false)}
            onTouchStart={handlePressStart}
            onTouchEnd={handlePressEnd}
            disabled={isTyping}
            active={buttonActive}
            show={!isTyping}
          >
            <ButtonText disabled={isTyping}>Continuer</ButtonText>
          </SubmitButton>
        </ContentWrapper>
      </Container>
    );
  };
  
  export default MeoFirstMessage;