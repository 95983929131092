import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';

import compassImage from '../../assets/meo_write.png';
import general_icon from '../../assets/top1_icon.png';
import tech_icon from '../../assets/5premiers.png';
import pro_icon from '../../assets/classement-classe.png';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import ProgressBar from '../ProgressBar';

const ClassementClasse = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [selectedRanking, setSelectedRanking] = useState(null);
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Quel est ton classement ?";
  const { isMobile } = useDeviceType();

  // State to track pointer active styles:
  const [activeOption, setActiveOption] = useState(null);
  const [submitActive, setSubmitActive] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, []);

  const rankingOptions = [
    {
      value: 'top1',
      label: 'Premier de la classe',
      icon: general_icon,
      disabled: false
    },
    {
      value: 'top5',
      label: 'Dans les 5 premiers',
      icon: tech_icon,
      disabled: false
    },
    {
      value: 'top_classe',
      label: 'Autre / Je ne sais pas',
      icon: pro_icon,
      disabled: false
    },
  ];

  const handleOptionClick = (option) => {
    if (!option.disabled) {
      setSelectedRanking(option.value);
    }
  };

  const handleSubmit = async () => {
    if (!selectedRanking || isTyping) return;
    setIsLoading(true);
    try {
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          top_option: selectedRanking
        });
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.top_option = selectedRanking;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile');
      } else { // Onboarding
        localStorage.setItem('top_option', selectedRanking);
        navigate('/origine-city');
      }
    } catch (error) {
      console.error('Error saving user ranking to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/lycee');
    }
  };

  // --- Option Button Pointer Handlers ---
  const handleOptionPressStart = (option) => {
    if (!option.disabled) setActiveOption(option.value);
  };

  const handleOptionPressEnd = (option) => {
    if (!option.disabled) {
      setActiveOption(null);
      handleOptionClick(option);
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if (isLoading || isTyping || !selectedRanking) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (isLoading || isTyping || !selectedRanking) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={90} endPercent={95} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer isMobile={isMobile}>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {rankingOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  $isSelected={selectedRanking === option.value}
                  $disabled={option.disabled}
                  type="button"
                  aria-pressed={selectedRanking === option.value}
                  aria-disabled={option.disabled}
                  onMouseDown={() => handleOptionPressStart(option)}
                  onMouseUp={() => handleOptionPressEnd(option)}
                  onMouseLeave={() => setActiveOption(null)}
                  onTouchStart={() => handleOptionPressStart(option)}
                  onTouchEnd={() => handleOptionPressEnd(option)}
                  active={activeOption === option.value}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedRanking === option.value}
                    style={{ opacity: option.disabled ? 0.5 : 1 }}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || !selectedRanking}
          style={{
            opacity: (isTyping || !selectedRanking) ? 0.5 : 1,
            cursor: (isTyping || !selectedRanking) ? 'not-allowed' : 'pointer'
          }}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default ClassementClasse;