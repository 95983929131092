import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  LoadingOverlay
} from './styles';

import {
  GradeContainer,
  SliderContainer,
  SliderLabel,
  CustomSlider,
  SliderHeader,
  LabelIconWrapper,
  IconImage,
  GradeBadge,
  SliderLabelLeft,
  SliderLabelRight,
  SliderWrapper,
} from './styleGeneralMoyenne';

import compassImage from '../../assets/meo_write.png';
import moyenne_croissance_icon from '../../assets/moyenne_croissante_icon.png';

// Specialties icons
import artsIcon from '../../assets/arts_culture_icon.png';
import eppcsIcon from '../../assets/sport_icon.png';
import hggspIcon from '../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../assets/hlp_icon.png';
import llcerIcon from '../../assets/llcer_icon.png';
import lcaIcon from '../../assets/lca_icon.png';
import mathIcon from '../../assets/math_icon.png';
import nsiIcon from '../../assets/informatique_icon.png';
import physChimIcon from '../../assets/physics_icon.png';
import svtIcon from '../../assets/svt_icon.png';
import siIcon from '../../assets/engineer_icon.png';
import sesIcon from '../../assets/ses_icon.png';
import bioEcoIcon from '../../assets/bioeco_icon.png';
import amcIcon from '../../assets/english_icon.png';

import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';

const specialityOptions = [
  { value: 'arts', label: 'Arts', icon: artsIcon },
  { value: 'eppcs', label: 'Éducation physique, pratiques et cultures sportives', icon: eppcsIcon },
  { value: 'hggsp', label: 'Histoire-géographie, géopolitique et sciences politiques', icon: hggspIcon },
  { value: 'hlp', label: 'Humanités, littérature et philosophie', icon: hlpIcon },
  { value: 'llcer', label: 'Langues, littératures et cultures étrangères et régionales', icon: llcerIcon },
  { value: 'lca', label: "Littérature, langues et cultures de l'antiquité", icon: lcaIcon },
  { value: 'math', label: 'Mathématiques', icon: mathIcon },
  { value: 'nsi', label: 'Numérique et sciences informatiques', icon: nsiIcon },
  { value: 'physchim', label: 'Physique-Chimie', icon: physChimIcon },
  { value: 'svt', label: 'Sciences de la vie et de la Terre', icon: svtIcon },
  { value: 'si', label: "Sciences de l'ingénieur", icon: siIcon },
  { value: 'ses', label: 'Sciences économiques et sociales', icon: sesIcon },
  { value: 'bioeco', label: 'Biologie-Ecologie', icon: bioEcoIcon },
  { value: 'amc', label: 'Anglais Monde Contemporain', icon: amcIcon }
];

const GeneralMoyenne = () => {
  const navigate = useNavigate();
  const { checkAuth } = useAuth();
  const [grades, setGrades] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const [classeUser, setclasseUser] = useState('');
  const { isMobile } = useDeviceType();
  const [specialities, setSpecialities] = useState([]);
  const [typeBac, setTypeBac] = useState('');
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');

  const getMessage = (classeUser, typeBac) => {
    if (typeBac === 'technologique' || typeBac === 'professionnel') {
      return "Indique ta moyenne estimée au bac.";
    } else {
      switch (classeUser) {
        case 'terminale':
          return "Indique tes moyennes estimées au bac.";
        case 'premiere':
          return "Indique tes moyennes estimées au bac.";
        case 'seconde':
          return "Indique ta moyenne estimée au bac.";
        default:
          return "Indique tes moyennes au bac.";
      }
    }
  };

  useEffect(() => {
    let storedclasseUser = '';
    let storedTypeBac = '';
    let storedSpecialities = [];
    if (modifyProfile){
      const storedUserData = localStorage.getItem('user');
      if (storedUserData) {
        const user = JSON.parse(storedUserData);
        storedclasseUser = user.user_type;
        storedTypeBac = user.type_bac;
        storedSpecialities = user.specialites;
      }
    } else {
      storedclasseUser = localStorage.getItem('type_utilisateur');
      storedTypeBac = localStorage.getItem('type_bac');
      if (storedTypeBac === 'general') {
        storedSpecialities = JSON.parse(localStorage.getItem('specialites') || []);
      }
    }
    console.log("storedSpecialities", storedSpecialities);
    setclasseUser(storedclasseUser);
    setTypeBac(storedTypeBac);
    setSpecialities(storedSpecialities);
    const fullText = getMessage(storedclasseUser, storedTypeBac);
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, [modifyProfile]);

  const handleSliderChange = (subject, value) => {
    setGrades(prev => ({
      ...prev,
      [subject]: value
    }));
  };

  const isSubmitEnabled = () => {
    if (classeUser === 'seconde' || typeBac === 'professionnel') {
      return grades.general !== undefined;
    } else {
      return specialities.every(subject => grades[subject] !== undefined);
    }
  };

  const handleSubmit = async () => {
    if (!isSubmitEnabled() || isTyping) return;
    setIsLoading(true);
    try {
      if(modifyProfile){
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          grades: grades
        });
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.grades = grades;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile');
      } else {
        localStorage.setItem('grades', JSON.stringify(grades));
        navigate('/domaines-favoris'); // Replace with your next route
      }
    } catch (error) {
      console.error('Error saving grades to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate("/type-bac");
    }
  };

  useEffect(() => {
    const sliders = document.querySelectorAll('input[type="range"]');
    sliders.forEach(slider => {
      slider.style.setProperty('--progress', `${(slider.value / slider.max) * 100}%`);
      slider.addEventListener('input', (e) => {
        e.target.style.setProperty('--progress', `${(e.target.value / e.target.max) * 100}%`);
      });
    });
  }, [grades]);

  const renderSliders = () => {
    const sliders = [];
    // General average slider (always present)
    sliders.push(
      <SliderContainer key="general">
        <SliderHeader>
          <LabelIconWrapper>
            <IconImage src={moyenne_croissance_icon} alt="Moyenne Croissante" />
            <SliderLabel>Moyenne générale</SliderLabel>
          </LabelIconWrapper>
          <GradeBadge>{grades.general || 0}/20</GradeBadge>
        </SliderHeader>
        <SliderWrapper>
          <SliderLabelLeft>0</SliderLabelLeft>
          <CustomSlider
            type="range"
            min={0}
            max={20}
            step={0.5}
            value={grades.general || 0}
            onChange={(e) => handleSliderChange('general', parseFloat(e.target.value))}
          />
          <SliderLabelRight>20</SliderLabelRight>
        </SliderWrapper>
      </SliderContainer>
    );
    // Sliders for specialties (if applicable)
    if ((typeBac === 'general' || typeBac === 'technologique') && classeUser !== 'seconde') {
      specialities.forEach((subject) => {
        const option = specialityOptions.find(opt => opt.value === subject);
        sliders.push(
          <SliderContainer key={subject}>
            <SliderHeader>
              <LabelIconWrapper>
                <IconImage src={option?.icon} alt={option?.label || subject} />
                <SliderLabel>{option?.label || subject}</SliderLabel>
              </LabelIconWrapper>
              <GradeBadge>{grades[subject] || 0}/20</GradeBadge>
            </SliderHeader>
            <SliderWrapper>
              <SliderLabelLeft>0</SliderLabelLeft>
              <CustomSlider
                type="range"
                min={0}
                max={20}
                step={0.5}
                value={grades[subject] || 0}
                onChange={(e) => handleSliderChange(subject, parseFloat(e.target.value))}
              />
              <SliderLabelRight>20</SliderLabelRight>
            </SliderWrapper>
          </SliderContainer>
        );
      });
    }
    return sliders;
  };

  // --- Submit Button Pointer Event Handlers ---
  const [submitActive, setSubmitActive] = useState(false);

  const handleSubmitPressStart = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      {!modifyProfile && <ProgressBar startPercent={65} endPercent={75} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>
        <GradeContainer style={{
          opacity: isTyping ? 0 : 1,
          transition: 'opacity 0.3s ease',
          pointerEvents: isTyping ? 'none' : 'auto'
        }}>
          {renderSliders()}
        </GradeContainer>
        <SubmitButton
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
          disabled={!isSubmitEnabled() || isTyping}
          type="button"
          style={{
            opacity: (!isSubmitEnabled() || isTyping) ? 0.5 : 1,
            cursor: (!isSubmitEnabled() || isTyping) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default GeneralMoyenne;
