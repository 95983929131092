// src/components/PopupGlobale/styles.js
import styled from 'styled-components';
import theme from '../../theme';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupContent = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 80%;
  max-width: 400px;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0,0,0,0.2);
  text-align: center;
`;

export const PopupMessage = styled.p`
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
  margin-bottom: 1.5rem;
  white-space: pre-line;
`;

export const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
`;

export const PopupButton = styled.button`
  flex: 1;
  max-width: 110px;
  background: ${theme.colors.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.small};
  transition: background 0.3s ease;

`;

export const PopupButtonYes = styled(PopupButton)`
  background: ${theme.colors.background_fonce};
  color: ${theme.colors.primary};
  border-radius: 10px;

  &:hover {
    background: #fff;
  }
`;