import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
// import 'core-js/actual/regexp';

// Scroll restoration component
const ScrollToTop = () => {
  const location = useLocation();
  
  useEffect(() => {
    // Force scroll to top on every route change
    window.scrollTo(0, 0);
    // Also try to reset any scrollable elements
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [location]);

  return null;
};

// Custom Router wrapper to handle scroll behavior
const CustomRouter = ({ children }) => {
  return (
    <Router>
      <ScrollToTop />
      {children}
    </Router>
  );
};

// Définir l'objet theme
const theme = {
  colors: {
    primary: 'var(--primary-color)',
    secondary: 'var(--secondary-color)',
    background: 'var(--background-color)',
    text: 'var(--text-color)',
    lightText: 'var(--light-text-color)',
    lightGray: 'var(--light-gray-color)',
    veryLightGray: 'var(--very-light-gray-color)',
    mediumGray: 'var(--medium-gray-color)',
  },
};

// Composant pour gérer les mises à jour du service worker et l'installation de l'app
function ServiceWorkerHandler() {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    // 1. Gestion de l'événement beforeinstallprompt pour l'installation de l'app
    const installHandler = (e) => {
      console.log("Événement beforeinstallprompt détecté:", e);
      e.preventDefault(); // Empêche la mini-infobar d'apparaître sur mobile
      window.deferredPrompt = e; // Le rendre accessible globalement si nécessaire
    };
    
    // 2. Gestion des messages du service worker (mises à jour, etc.)
    const messageHandler = (event) => {
      if (event.data && event.data.type === 'NEW_VERSION') {
        console.log('Nouvelle version détectée:', event.data.version);
        setNewVersionAvailable(true);
        
        // Si vous voulez appliquer la mise à jour automatiquement après un délai
        // Par exemple, appliquer la mise à jour silencieusement après 5 minutes
        setTimeout(() => {
          window.location.reload();
        }, 5 * 60 * 1000); // 5 minutes
      }
    };

    // Enregistrer les écouteurs d'événements
    window.addEventListener('beforeinstallprompt', installHandler);
    window.addEventListener('message', messageHandler);
    
    // Nettoyer les écouteurs d'événements
    return () => {
      window.removeEventListener('beforeinstallprompt', installHandler);
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  // Fonction pour appliquer la mise à jour manuellement
  const applyUpdate = () => {
    window.location.reload();
  };

  // Rendu du composant avec notification de mise à jour (optionnel)
  return newVersionAvailable ? (
    <div 
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        background: '#4caf50',
        color: 'white',
        padding: '10px 15px',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        zIndex: 9999,
        cursor: 'pointer'
      }}
      onClick={applyUpdate}
    >
      Nouvelle version disponible - Cliquez pour mettre à jour
    </div>
  ) : null;
}

const root = createRoot(document.getElementById('root'));

root.render(
  <CustomRouter>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        {/* Inclure le gestionnaire de service worker au niveau supérieur */}
        <ServiceWorkerHandler />
        <App />
      </AuthProvider>
    </ThemeProvider>
  </CustomRouter>
);

// Enregistrer le service worker avec une meilleure gestion des mises à jour
if ('serviceWorker' in navigator) {
  // Prevent multiple registration attempts which can cause issues
  let swRegistration = null;
  let registrationAttempts = 0;
  const MAX_REGISTRATION_ATTEMPTS = 3;
  
  const registerServiceWorker = () => {
    if (registrationAttempts >= MAX_REGISTRATION_ATTEMPTS) {
      console.warn('Maximum service worker registration attempts reached, skipping registration');
      return;
    }
    
    registrationAttempts++;
    
    // Check if we've already registered
    if (swRegistration && swRegistration.active) {
      console.log('Service worker already registered and active');
      return;
    }
    
    console.log(`Attempting service worker registration (attempt ${registrationAttempts})`);
    
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        swRegistration = registration;
        console.log('Service Worker enregistré avec succès:', registration.scope);
        
        // Store the registration timestamp to prevent rapid re-registrations
        localStorage.setItem('sw_registration_time', Date.now().toString());
        
        // Vérifier régulièrement les mises à jour du service worker
        const updateInterval = setInterval(() => {
          if (navigator.onLine) {
            registration.update()
              .then(() => console.log('Vérification des mises à jour du service worker effectuée'))
              .catch(err => console.warn('Erreur lors de la vérification des mises à jour du SW:', err));
          }
        }, 60 * 60 * 1000); // Vérifier toutes les heures

        // Gérer les mises à jour
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          console.log('Nouveau service worker en installation...');
          
          newWorker.addEventListener('statechange', () => {
            console.log('État du service worker:', newWorker.state);
            
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              console.log('Nouveau service worker installé, prêt à prendre le contrôle');
              // Envoi d'un message pour déclencher skipWaiting
              newWorker.postMessage({ type: 'SKIP_WAITING' });
            }
          });
        });
        
        // Clean up the interval if needed
        window.addEventListener('unload', () => {
          clearInterval(updateInterval);
        });
      })
      .catch(error => {
        console.error('Échec de l\'enregistrement du service worker:', error);
        
        // Store the error to prevent loops
        localStorage.setItem('sw_registration_error', Date.now().toString());
        
        // Only retry registration after a delay and if this isn't a persistent error
        const lastErrorTime = parseInt(localStorage.getItem('sw_registration_error') || '0', 10);
        const now = Date.now();
        
        // If it's been more than 10 minutes since the last error, try again
        if (now - lastErrorTime > 10 * 60 * 1000 && registrationAttempts < MAX_REGISTRATION_ATTEMPTS) {
          setTimeout(registerServiceWorker, 30000); // Try again in 30 seconds
        }
      });
  };
  
  // Only register if we haven't registered recently
  const lastRegistrationTime = parseInt(localStorage.getItem('sw_registration_time') || '0', 10);
  const now = Date.now();
  
  // If it's been more than 1 hour since the last registration or never registered, register
  if (now - lastRegistrationTime > 60 * 60 * 1000 || lastRegistrationTime === 0) {
    window.addEventListener('load', () => {
      // Give the page time to fully load before registering the SW
      setTimeout(registerServiceWorker, 1000);
    });
  } else {
    console.log('Service worker registration skipped - registered recently');
  }

  // Gérer les changements de contrôleur du service worker
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Le service worker a changé, la page va se recharger pour appliquer les mises à jour');
    
    // Prevent infinite reload loops by checking when the last reload happened
    const lastReloadTime = parseInt(localStorage.getItem('sw_last_reload') || '0', 10);
    const now = Date.now();
    
    // If we haven't reloaded in the last minute, it's safe to reload
    if (now - lastReloadTime > 60 * 1000) {
      localStorage.setItem('sw_last_reload', now.toString());
      
      // Optional: only reload if the page has been loaded for more than 5 minutes
      // This prevents reloads during the initial session
      const pageLoadTime = parseInt(localStorage.getItem('page_load_time') || '0', 10);
      if (pageLoadTime === 0) {
        localStorage.setItem('page_load_time', now.toString());
      } else if (now - pageLoadTime > 5 * 60 * 1000) {
        window.location.reload();
      }
    } else {
      console.warn('Prevented potential reload loop - last reload was too recent');
    }
  });
}