import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  ChatHistoryContainer,
  HistoryItem,
  DateText,
  RenameInput,
  IconButton,
  DiscussionName
} from './styles';
import { useAuth } from '../../../contexts/AuthContext';
import { Check, X } from 'lucide-react';
import renameIcon from '../../../assets/rename_icon.png'; // Update the path if necessary

// Date formatting function
const formatDiscussionDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };
  const formatted = new Intl.DateTimeFormat('fr-FR', options).format(date);
  return formatted.replace(',', ' -').replace(':', 'h');
};

const ChatHistory = ({ onDiscussionClick }) => {
  const [discussions, setDiscussions] = useState([]);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();
  const [renamingId, setRenamingId] = useState(null);
  const [newName, setNewName] = useState('');

  const fetchDiscussions = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_BACKEND_URL}/inference/get_user_discussions/`,
          withCredentials: true, // include the HttpOnly cookie
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const response = await axios(config);
        setDiscussions(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching discussions:', error);
        setDiscussions([]);
        setError(error.response?.data?.error || 'Error fetching discussions');
      }
    } else {
      setDiscussions([]);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchDiscussions();
  }, [fetchDiscussions]);

  const handleItemClick = useCallback(
    (discussion) => {
      if (onDiscussionClick) {
        const discussionData = {
          id: discussion.id,
          name: discussion.name || `Discussion ${discussion.id}`,
        };
        onDiscussionClick(discussionData);
      }
    },
    [onDiscussionClick]
  );

  const startRenaming = (discussion, e) => {
    e.stopPropagation();
    setRenamingId(discussion.id);
    setNewName(discussion.name || `Discussion ${discussion.id}`);
  };

  const handleRename = async (discussionId, e) => {
    e.stopPropagation();
    if (isAuthenticated && newName.trim() !== '') {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/inference/rename_discussion/`,
          {
            discussion_id: discussionId,
            new_name: newName.trim(),
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.success) {
          await fetchDiscussions();
        }
      } catch (error) {
        console.error('Error renaming discussion:', error);
        setError('Error renaming discussion');
      }
    }
    setRenamingId(null);
  };

  const handleKeyPress = (event, discussionId) => {
    if (event.key === 'Enter') {
      handleRename(discussionId, event);
    }
  };

  const cancelRenaming = (e) => {
    e.stopPropagation();
    setRenamingId(null);
    setNewName('');
  };

  // If not authenticated, we simply return nothing (no message displayed).
  if (!isAuthenticated) {
    return null;
  }

  return (
    <ChatHistoryContainer>
      {error && <p className="error-message">{error}</p>}
      {discussions.length === 0 ? (
        <p></p>
      ) : (
        discussions.map((discussion) => (
          <HistoryItem
            key={discussion.id}
            onClick={() => handleItemClick(discussion)}
          >
            {discussion.begin_date && (
              <DateText>
                {formatDiscussionDate(discussion.begin_date)}
              </DateText>
            )}
{renamingId === discussion.id ? (
  <>
    <RenameInput
      value={newName}
      onChange={(e) => setNewName(e.target.value)}
      onKeyPress={(e) => handleKeyPress(e, discussion.id)}
      autoFocus
      onClick={(e) => e.stopPropagation()}
    />
    {/* Cross (cancel) icon placed first */}
    <IconButton onClick={(e) => {
    e.stopPropagation();
    cancelRenaming(e);
  }} >
      <X size={14} />
    </IconButton>
    {/* Check (validate) icon placed after */}
    <IconButton onClick={(e) => {
    e.stopPropagation();
    handleRename(discussion.id, e);
  }}
>
      <Check size={14} />
    </IconButton>
  </>
) : (
  <>
    <DiscussionName>
      {discussion.name || `Discussion ${discussion.id}`}
    </DiscussionName>
    <IconButton 
      onClick={(e) => {
        e.stopPropagation();
        startRenaming(discussion, e);
      }} >
      <img 
        src={renameIcon} 
        alt="Rename Discussion" 
        style={{ width: '14px', height: '14px', objectFit: 'cover' }} 
      />
    </IconButton>
  </>
)}

          </HistoryItem>
        ))
      )}
    </ChatHistoryContainer>
  );
};

export default ChatHistory;
