// ProtectedClassementOrQuiz.js
import React, { useEffect, useState } from 'react';
import apiCall from '../utils/api';

// -- Mobile components
import Classement from '../components/Classement';
import QuizTypeFormation from '../components/QuizTypeFormation';

// -- Desktop components
// import DesktopClassement from '../components/Desktop/Classement';
import DesktopQuizTypeFormation from '../components/Desktop/QuizTypeFormation';

function ProtectedClassementOrQuiz({ isMobile }) {
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(true);

  // Reusable function to fetch progress
  const fetchProgress = async () => {
    setLoading(true);
    try {
      const data = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/progress/`,
        'GET'
      );
      setProgress(data);
    } catch (error) {
      console.error('Error fetching quiz progress:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Called by the quiz once the user is done answering
  const handleQuizComplete = async () => {
    // Re‐fetch progress so we know if it’s completed now
    await fetchProgress();
    localStorage.setItem('need_update_ranking_formation', 'true');
    localStorage.setItem('need_update_ranking', 'true');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // No progress => quiz not started
  if (!progress) {
    if (isMobile) {
      return <QuizTypeFormation onQuizComplete={handleQuizComplete} />;
    } else {
      return <DesktopQuizTypeFormation onQuizComplete={handleQuizComplete} />;
    }
  }

  const { quiz_completed } = progress;

  // If not completed => show quiz
  if (!quiz_completed) {
    if (isMobile) {
      return <QuizTypeFormation onQuizComplete={handleQuizComplete} />;
    } else {
      return <DesktopQuizTypeFormation onQuizComplete={handleQuizComplete} />;
    }
  }

  // If completed => show classement
  if (isMobile) {
    return <Classement />;
  } else {
    // return <DesktopClassement />;
    return <Classement />;
  }
}

export default ProtectedClassementOrQuiz;
