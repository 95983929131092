import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import selectedIcon from '../../assets/croix_bleue_icon.png';
import filterIcon from '../../assets/filtre_epure.png';
import SchoolIcon from '../../assets/school-icon.png';
import LocalisationIcon from '../../assets/localisation_suggestion.png';

import loupeGrise from '../../assets/loupe_grise.png';
import loupeBlanche from '../../assets/loupe.png'; // Import for the white search icon

// Import search utilities directly from the local directory (not from Comparateur)
import formationsMeta from './formationsMeta.json';
import searchIndex from './searchIndex.json';
import theme from '../../theme';
import {allCitiesFormations} from '../data'

// -------------------------
// Styled Components from original file
// -------------------------

const BrowserContainerNoContainer = styled.div`
  width: 350px;
  position: relative;
  box-sizing: border-box;  
  overflow: visible; /* Changed from hidden to visible to ensure dropdown is shown */
  margin-top: -10%;
  height: auto;
  min-height: ${props => 
    (props.showingSuggestions || props.showingResults) ? 'auto' : 'unset'
  };
  flex-shrink: 0;
  
  ${(props) => (!props.freeText ? "background: #FFFFFF; border-radius: 25px;" : "")}
`;

const SearchArea = styled.div`
  width: 100%;
  position: relative;
  border-radius: 25px;
  background: #FFF;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: space-between; /* Space between input and filter icon */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Optional: add a subtle shadow instead of border */
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  color: #C4C4C4;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%; /* 16.278px */
  text-align: left;
  background-color: transparent;
  padding-left: 5px;
  
  &::placeholder {
    color: #C4C4C4;
  }
`;

const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterImg = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const SearchIcon = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  margin-right: -3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#38B6FF' : '#E6E6E6'};
  cursor: pointer;
`;

const SuggestionList = styled.div`
  margin-top: 0.5rem;
  max-height: calc(200px - 60px);
  overflow-y: auto;
  width: 90%;
  margin-left: 5%;
    
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

const SuggestionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 13px;
  font-family: 'Gabarito', sans-serif;
  color: rgba(0, 0, 0, 0.76);
  cursor: pointer;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const SectionDivider = styled.div`
  width: 310px;
  height: 1px;
  background: #EFEFEF;
  margin-left: 16px;
`;

const ItemText = styled.div`
  color: #000;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SelectedIcon = styled.img`
  width: auto;
  height: 13px;
  object-fit: cover;
`;

const Divider = styled.div`
  width: 100%;
  position: relative;
  border-top: 1px solid #dcf3ff;
  box-sizing: border-box;
  height: 1px;
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 53px; // Position just below the search input
  left: 0;
  width: 100%;
  max-height: 300px; // Height as requested
  background-color: white;
  border-radius: 25px; // Consistent radius with SearchArea
  z-index: 1500;
  overflow-y: auto;
  
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

const SearchResultItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:hover {
    background-color: #f5f5f5;
  }
`;

const SectionHeader = styled.div`
  color: #000;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 12px 16px 8px;
`;

const ResultText = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.76);
  margin-bottom: 2px;
`;

const ResultMeta = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  color: #888;
  margin-top: 2px;
`;

const NoResultsMessage = styled.div`
  padding: 16px;
  text-align: center;
  color: #888;
  font-family: 'Gabarito', sans-serif;
  font-size: 13px;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  color: #666;
`;

// -------------------------
// Search utilities - copied from search-utils.js
// -------------------------

// Function to normalize search text (lowercase, remove accents, replace special chars)
const normalizeText = (text) => {
  if (!text) return '';
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove accents
    .replace(/[',-]/g, " "); // Replace special characters with spaces
};

// Function to search formations using inverted index - slightly modified version
const searchFormationsLocal = (query, searchIdx, metaData, maxResults = 5) => {
  if (!query || !searchIdx || !metaData) {
    console.log('Missing required parameters for search');
    return [];
  }
  
  // Create an array of formation IDs (keys from metaData)
  const formationIds = Object.keys(metaData);
  
  // Normalize the query
  const normalizedQuery = normalizeText(query);
  console.log('Normalized query:', normalizedQuery);
  
  // Split query into words
  const queryWords = normalizedQuery.split(/\s+/).filter(word => word.length > 0);
  console.log('Query words:', queryWords);
  
  if (queryWords.length === 0) {
    console.log('No query words after normalization');
    return [];
  }

  // Get candidate formation indices that match all query words
  let candidateIndices = new Set();
  let isFirstWord = true;

  for (const word of queryWords) {
    // Find all terms in the index that start with this word
    const matchingTerms = Object.keys(searchIdx).filter(term => 
      term.startsWith(word)
    );
    
    console.log(`Terms matching "${word}":`, matchingTerms);
    
    // Get all formation indices for these terms
    let matchingIndices = new Set();
    for (const term of matchingTerms) {
      if (searchIdx[term]) {
        searchIdx[term].forEach(index => matchingIndices.add(index));
      }
    }
    
    console.log(`Found ${matchingIndices.size} indices for "${word}"`);
    
    // For the first word, initialize the candidates
    // For subsequent words, keep only indices that also match this word
    if (isFirstWord) {
      candidateIndices = matchingIndices;
      isFirstWord = false;
    } else {
      candidateIndices = new Set(
        [...candidateIndices].filter(index => matchingIndices.has(index))
      );
    }
    
    // If no matches left, exit early
    if (candidateIndices.size === 0) {
      console.log('No matching candidates found');
      return [];
    }
  }
  
  console.log('Candidate indices:', [...candidateIndices]);
  
  // Convert indices to formation objects with metadata
  const results = [...candidateIndices]
    .map(index => {
      // Convert index to string for lookup
      const id = index.toString();
      if (metaData[id]) {
        return {
          id: id,
          ...metaData[id]
        };
      }
      return null;
    })
    .filter(formation => formation) // Remove null results
    .slice(0, maxResults);
  
  console.log('Final results:', results);
  return results;
};

// -------------------------
// FilteringBrowser Component
// -------------------------

const FilteringBrowser = ({
  filterType,
  items = [],
  selectedItems = [],
  onSelectedItemsChange,
  onClose,
  toggleButtonRef,
  onSubmit,
  activeTab,
  onFilterClick,
}) => {
  // Determine if we're in free text mode (no predefined items)
  const isFreeText = items.length === 0;
  
  // Initialize input value based on mode
  const initialInputValue = isFreeText 
    ? (typeof selectedItems === 'string' ? selectedItems : '') 
    : '';
    
  // State
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  
  // Refs
  const containerRef = useRef(null);
  const selectionMadeRef = useRef(false);
  const selectionTimeoutRef = useRef(null);
  
  // Determine placeholder text based on filter type
  let placeholderText = "Rechercher...";
  if (filterType === "cities_formations") {
    placeholderText = "Ville/Région ex : Aquitaine...";
  } else if (filterType === "domains_formations") {
    placeholderText = "Domaine ex : Informatique...";
  } else if (filterType === "nom_ecole") {
    placeholderText = "École ex : Polytechnique...";
  } else if (filterType === "types_formations") {
    placeholderText = "Type de formation ex: CPGE...";
  } else if (filterType === "debouche") {
    placeholderText = "Métier ex : Journaliste...";
  } else if (filterType === "durations_formations") {
    placeholderText = "Durée ex : 2 ans...";
  } else if (filterType === "search_keyword") {
    placeholderText = "École, Ville, Métier...";
  } else if (filterType === "search_keyword_filiere") {
    placeholderText = "Type de formation...";
  }
  
  // Helper to check if we should use inverted index search
  const shouldUseInvertedIndex = () => {
    return filterType === "cities_formations" || 
           filterType === "nom_ecole" || 
           filterType === "search_keyword" || 
           filterType === "search_keyword_filiere";
  };
  
  // Perform search using inverted index
  const performInvertedIndexSearch = (query) => {
    if (!query || query.trim() === '') {
      setSearchResults([]);
      return;
    }
    
    try {
      setIsLoadingResults(true);
      
      // Use the searchFormationsLocal function
      let results = searchFormationsLocal(query, searchIndex, formationsMeta, 20);
      
      // Check for matching cities
      const lowerCaseQuery = query.toLowerCase().trim();
      const hasLocations = results.some(result => result.type === "location");
      
      if (!hasLocations) {
        const matchingCities = allCitiesFormations
          .filter(city => city.toLowerCase().includes(lowerCaseQuery))
          .slice(0, 5)
          .map(city => ({
            id: city.toLowerCase().replace(/[^a-z0-9]/g, ''),
            displayName: city,
            type: "location",
          }));
        
        if (matchingCities.length > 0) {
          results = [...results, ...matchingCities];
        }
      }
      
      // Add displayValue to all results
      results = results.map(result => ({
        ...result,
        displayValue: result.displayName || result.type || "Résultat"
      }));
      
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching with inverted index:', error);
      setSearchResults([]);
    } finally {
      setIsLoadingResults(false);
    }
  };
  
  // Helper to prioritize selected items in list
  const reorderBySelection = (list) => {
    if (isFreeText) return list;
    const selected = list.filter(item => selectedItems.includes(item));
    const notSelected = list.filter(item => !selectedItems.includes(item));
    return [...selected, ...notSelected];
  };
  
  // Input value change handler
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    // If we should use inverted index search
    if (shouldUseInvertedIndex()) {
      performInvertedIndexSearch(newValue);
      setShowSearchResults(newValue.trim() !== '');
    }
  };
  
  // Handle selection of a search result
  const handleResultSelect = (item) => {
    const displayValue = item.displayName || "";
    
    // Set flag to avoid resetting this value
    selectionMadeRef.current = true;
    
    // Clear any existing timeout
    if (selectionTimeoutRef.current) {
      clearTimeout(selectionTimeoutRef.current);
    }
    
    // Update input value
    setInputValue(displayValue);
    
    // Update parent component
    if (isFreeText) {
      onSelectedItemsChange(displayValue);
    } else {
      const newSelection = [...selectedItems, displayValue];
      onSelectedItemsChange(newSelection);
    }
    
    // Hide search results
    setShowSearchResults(false);
    
    // Call submit callback if provided
    if (onSubmit) {
      onSubmit();
    }
    
    // Close modal
    onClose();
    
    // Reset selection flag after a delay
    selectionTimeoutRef.current = setTimeout(() => {
      selectionMadeRef.current = false;
    }, 2000);
  };
  
  // Handle selection of an item from the suggestion list
  const handleSelect = (item) => {
    const newSelection = selectedItems.includes(item)
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item];
    
    onSelectedItemsChange(newSelection);
  };
  
  // Handle search icon click
  const handleSearchIconClick = () => {
    setIsSearchMode(true);
    handleSearch();
  };
  
  // Handle search submission
  const handleSearch = () => {
    // Don't override recent selection
    if (selectionMadeRef.current) {
      return;
    }
    
    if (isFreeText) {
      // For free text filters
      onSelectedItemsChange(inputValue);
      
      // Store the search keyword in localStorage based on active tab
      if (filterType === "search_keyword") {
        localStorage.setItem('searchKeyword', inputValue);
        localStorage.setItem('need_update_ranking_formation', 'true');
      } else if (filterType === "search_keyword_filiere") {
        localStorage.setItem('searchKeywordType', inputValue);
        localStorage.setItem('need_update_ranking', 'true');
      }
      
      // Call onSubmit if provided
      if (onSubmit) {
        onSubmit();
      }
      
      setShowSearchResults(false);
      onClose();
    } else {
      setShowSearchResults(false);
      onClose();
    }
  };
  
  // Handle clearing the input and selection
  const handleClear = () => {
    setInputValue('');
    
    if (isFreeText) {
      onSelectedItemsChange('');
      
      // Clear localStorage items
      if (filterType === "search_keyword") {
        localStorage.setItem('searchKeyword', '');
        localStorage.setItem('need_update_ranking_formation', 'true');
      } else if (filterType === "search_keyword_filiere") {
        localStorage.setItem('searchKeywordType', '');
        localStorage.setItem('need_update_ranking', 'true');
      }
    } else {
      onSelectedItemsChange([]);
    }
    
    // Call onSubmit if provided
    if (onSubmit) {
      onSubmit();
    }
    
    setShowSearchResults(false);
    onClose();
  };
  
  // Handle input focus
  const handleInputFocus = () => {
    setInputFocused(true);
    
    // Show search results if there's input text and we should use inverted index
    if (inputValue.trim() !== '' && shouldUseInvertedIndex()) {
      setShowSearchResults(true);
      performInvertedIndexSearch(inputValue);
    }
  };
  
  // Handle input blur
  const handleInputBlur = (e) => {
    // If a selection was just made, don't do anything
    if (selectionMadeRef.current) {
      return;
    }
    
    // Don't trigger blur if clicking on suggestion or search results
    if (e.relatedTarget && 
        (e.relatedTarget.closest('.modal-overlay') || 
         e.relatedTarget.closest('.suggestion-list'))) {
      return;
    }
    
    // Add a small delay to allow clicks on results to register
    setTimeout(() => {
      // Double-check if selection was made during the timeout
      if (selectionMadeRef.current) {
        return;
      }
      
      setInputFocused(false);
      
      // For free text, trigger search on blur
      if (isFreeText) {
        handleSearch();
        setIsSearchMode(true);
      }
    }, 200);
  };
  
  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
      setIsSearchMode(true);
    }
  };
  
  // Update suggestions when input value or items change
  useEffect(() => {
    if (!isFreeText) {
      let filtered;
      if (inputValue.trim() === '') {
        filtered = items;
      } else {
        filtered = items.filter(item =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
      setSuggestions(reorderBySelection(filtered));
    }
  }, [inputValue, items, selectedItems, isFreeText]);
  
  // Initialize input value when selectedItems or activeTab changes
  useEffect(() => {
    // Only update if no recent selection was made
    if (!selectionMadeRef.current) {
      if (isFreeText) {
        if (typeof selectedItems === 'string') {
          setInputValue(selectedItems);
        }
      }
    }
  }, [activeTab, selectedItems, isFreeText]);
  
  // Handle clicks outside the component
  useEffect(() => {
    function handleClickOutside(e) {
      if (containerRef.current?.contains(e.target)) return;
      if (toggleButtonRef?.current?.contains(e.target)) return;
      onClose();
      setShowSearchResults(false);
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, toggleButtonRef]);
  
  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (selectionTimeoutRef.current) {
        clearTimeout(selectionTimeoutRef.current);
      }
    };
  }, []);
  
  return (
    <BrowserContainerNoContainer ref={containerRef}>
      <SearchArea>
        <SearchInput
          placeholder={placeholderText}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        
        {!inputFocused ? (
          <FilterIconContainer onClick={onFilterClick}>
            <FilterImg src={filterIcon} alt="filter icon" />
          </FilterIconContainer>
        ) : (
          <SearchIcon>
            <SearchIconCircle 
              active={!isSearchMode} 
              onClick={handleSearchIconClick}
            >
              {isSearchMode ? (
                <img src={loupeGrise} alt="search" width="18" height="18" />
              ) : (
                <img src={loupeBlanche} alt="search" width="18" height="18" />
              )}
            </SearchIconCircle>
          </SearchIcon>
        )}
      </SearchArea>
      
      {/* Search Results Modal */}
      {showSearchResults && shouldUseInvertedIndex() && 
       (isLoadingResults || searchResults.length > 0 || inputValue.trim() !== '') && (
        <ModalOverlay className="modal-overlay">
          {isLoadingResults ? (
            <LoadingSpinner>Chargement des résultats...</LoadingSpinner>
          ) : (
            <>
              {/* Schools Section */}
              <SectionHeader>
                <SectionIcon src={SchoolIcon} alt="school icon" />
                Écoles
              </SectionHeader>
              <SectionDivider />
              
              {searchResults.filter(result => result.type === "school").length > 0 ? (
                searchResults
                  .filter(result => result.type === "school")
                  .slice(0, 3)
                  .map((result, index, arr) => (
                    <React.Fragment key={`school-result-${index}`}>
                      <SearchResultItem 
                        onClick={() => handleResultSelect(result)}
                      >
                        <ItemText>
                          {result.displayName || "École"}
                        </ItemText>
                        {selectedItems.includes(result.displayName) && (
                          <SelectedIcon src={selectedIcon} alt="selected" />
                        )}
                      </SearchResultItem>
                      {index < arr.length - 1 && <SectionDivider />}
                    </React.Fragment>
                  ))
              ) : (
                inputValue.trim() !== '' ? (
                  <SearchResultItem>
                    <ItemText>Aucune école trouvée</ItemText>
                  </SearchResultItem>
                ) : (
                  Array(3).fill(0).map((_, index) => (
                    <SearchResultItem key={`empty-school-${index}`}>
                      <ItemText style={{ color: '#ccc' }}>-</ItemText>
                    </SearchResultItem>
                  ))
                )
              )}
              
              {/* Locations Section */}
              <SectionHeader>
                <SectionIcon src={LocalisationIcon} alt="location icon" />
                Localisation
              </SectionHeader>
              <SectionDivider />
              
              {searchResults.filter(result => result.type === "location").length > 0 ? (
                searchResults
                  .filter(result => result.type === "location")
                  .slice(0, 3)
                  .map((result, index, arr) => (
                    <React.Fragment key={`location-result-${index}`}>
                      <SearchResultItem 
                        onClick={() => handleResultSelect(result)}
                      >
                        <ItemText>
                          {result.displayName || "Localisation"}
                        </ItemText>
                        {selectedItems.includes(result.displayName) && (
                          <SelectedIcon src={selectedIcon} alt="selected" />
                        )}
                      </SearchResultItem>
                      {index < arr.length - 1 && <SectionDivider />}
                    </React.Fragment>
                  ))
              ) : (
                inputValue.trim() !== '' ? (
                  <SearchResultItem>
                    <ItemText>Aucune localisation trouvée</ItemText>
                  </SearchResultItem>
                ) : (
                  Array(3).fill(0).map((_, index) => (
                    <SearchResultItem key={`empty-location-${index}`}>
                      <ItemText style={{ color: '#ccc' }}>-</ItemText>
                    </SearchResultItem>
                  ))
                )
              )}
              
              {/* Other Results Section (if any) */}
              {searchResults.filter(result => result.type !== "school" && result.type !== "location").length > 0 && (
                <>
                  <SectionHeader>
                    <SectionIcon src={filterIcon} alt="result icon" />
                    Résultats
                  </SectionHeader>
                  <SectionDivider />
                  
                  {searchResults
                    .filter(result => result.type !== "school" && result.type !== "location")
                    .slice(0, 3)
                    .map((result, index, arr) => (
                      <React.Fragment key={`other-result-${index}`}>
                        <SearchResultItem 
                          onClick={() => handleResultSelect(result)}
                        >
                          <ItemText>
                            {result.displayName || "Résultat"}
                          </ItemText>
                          {selectedItems.includes(result.displayName) && (
                            <SelectedIcon src={selectedIcon} alt="selected" />
                          )}
                        </SearchResultItem>
                        {index < arr.length - 1 && <SectionDivider />}
                      </React.Fragment>
                    ))
                  }
                </>
              )}
            </>
          )}
        </ModalOverlay>
      )}
      
      {/* Suggestions List (for non-free text mode) */}
      {!isFreeText && suggestions.length > 0 && (
        <SuggestionList className="suggestion-list">
          {suggestions.map((item, index) => (
            <div key={item}>
              <SuggestionItem 
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleSelect(item);
                }}
              >
                <span>{item}</span>
                {selectedItems.includes(item) && (
                  <SelectedIcon src={selectedIcon} alt="selected icon" />
                )}
              </SuggestionItem>
              {index < suggestions.length - 1 && <Divider />}
            </div>
          ))}
        </SuggestionList>
      )}
    </BrowserContainerNoContainer>
  );
};

export default FilteringBrowser;