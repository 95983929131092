import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import apiCall from '../../utils/api';

// Import icons and images
import shareIcon from '../../assets/partager_blanc.png';
import lettreIcon from '../../assets/lettre.png';
import copyIcon from '../../assets/copy_icon.png';
import useDeviceType from '../useDeviceType';
import characterCountIcon from '../../assets/calculatrice.png';

// Import SharePopup component
import SharePopup from './SharePopup';

// Main container with scrolling
const Container = styled.div`
  width: ${props => props.isMobile ? '99%' : 'calc(100% - 330px)'};
  margin-left: ${props => props.isMobile ? '0' : '330px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 20px;
  background-color: ${theme.colors.background || '#f8f9fa'};
  z-index: 1601;
  
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

// Header card with improved layout to prevent overlap
const HeaderCard = styled.div`
  width: 100%;
  height: ${props => props.height || '80px'};
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
`;

// Header content wrapper to organize title and buttons with vertical centering
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  padding: 0px 15px;
  margin-bottom: 20px;
  height: 100%; /* Take up full height of the header */
`;

// Title text
const TitleText = styled.div`
  color: #000;
  font-family: Gabarito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  flex-grow: 1;
  padding: 0;
  margin-right: 15px; /* Add space for share button */
  word-wrap: break-word;
`;

// Subtitle text for establishment
const SubtitleText = styled.div`
  color: #ACACAC;
  text-align: left;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%; /* 16.278px */
  margin-top: 8px; /* Increased from 4px for more spacing */
`;

// Character counter container
const CharCountContainer = styled.div`
  width: 110px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 25px;
  padding: 0 8px;
  margin-top: 10px; /* Add extra margin to prevent overlap */
`;

// Character counter icon
const CharCountIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

// Character counter text
const CharCountText = styled.span`
  color: #2C2C2C;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

// Button container for multiple buttons (vertical stack)
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center; /* Center the button group in the header */
`;

// Share button component
const ShareButton = styled.div`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 0px solid #1396E4;
  background: #41B6FB;
  box-shadow: ${props => props.isPressed ? 'none' : '0px 3px 0px 0px #1396E4'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${props => props.isPressed ? 'translateY(3px)' : 'translateY(0)'};
  transition: transform 0.1s, box-shadow 0.1s;
`;

// Copy button component with white background and gray border
const CopyButton = styled.div`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  background: #FFF;
  box-shadow: ${props => props.isPressed ? 'none' : '0px 3px 0px 0px #E6E6E6'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${props => props.isPressed ? 'translateY(3px)' : 'translateY(0)'};
  transition: transform 0.1s, box-shadow 0.1s;
`;

// Icon image
const ButtonIconImg = styled.img`
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  aspect-ratio: 1/1;
`;

// Toast notification
const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  z-index: 3000;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease;
`;

// Letter container with icon
const LetterContainer = styled.div`
  width: 100%;
  height: auto;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  // margin-top: -3%;
  position: relative;
`;

// Letter icon at top left
const LetterIconContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

// Letter text
const LetterText = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  white-space: pre-line;
  margin-top: 40px;
`;

// Base Button component
const BaseButton = styled.button`
  border: none;
  cursor: pointer;
`;

// Plain HTML button with styling
const PlainButton = styled.button`
  width: 100%;
  margin-top: 20px;
  border-radius: 15px;
  height: 53px;
  background-color: #ff2768;
  border: none;
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px #d42359'};
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transform: ${props => props.active ? 'translateY(4px)' : 'none'};
  transition: all 0.15s ease;
  padding: 0 20px;
  outline: none;
`;

const LettreFinal = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isSharePressed, setIsSharePressed] = useState(false);
  const [isCopyPressed, setIsCopyPressed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(true);
  const {isMobile} = useDeviceType();
  // Letter data state
  const [letterData, setLetterData] = useState({
    letter: '',
    formation_name: '',
    establishment: ''
  });
  
  // Character count calculation
  const characterCount = letterData.letter ? letterData.letter.length : 0;
  
  // Calculate header height based on formation name length and subtitle
  const calculateHeaderHeight = (formationName) => {
    if (!formationName) return '100px'; // Increased base height
    
    const baseHeight = 100; // Increased from 80px
    const avgCharsPerLine = 20; // Estimate for 18px font size in the container
    const fullTitle = `${formationName.toUpperCase()}`;
    
    // Calculate how many lines the text will likely take
    const estimatedLines = Math.ceil(fullTitle.length / avgCharsPerLine);
    
    // Add 20px per additional line beyond the first
    const additionalHeight = Math.max(0, estimatedLines - 1) * 25;
    
    // Add additional height for the establishment subtitle
    const subtitleHeight = 30; // Increased from 25
    
    // Add height for character counter and spacing (increased)
    const counterHeight = 15; // Increased from 5px
    
    return `${baseHeight + additionalHeight + subtitleHeight + counterHeight}px`;
  };
  
  // Format the title nicely
  const formatTitle = (formationName) => {
    if (!formationName) return "Lettre de motivation";
    
    return `${formationName.toUpperCase()}`;
  };
  
  // Format the establishment subtitle
  const formatSubtitle = (establishment) => {
    return establishment || "Non spécifié";
  };
  
  useEffect(() => {
    // Get the selected formation
    const selectedFormationString = localStorage.getItem('selectedFormation');
    if (!selectedFormationString) {
      // If no formation is selected, redirect back
      navigate('/lettres');
      return;
    }
    
    try {
      const selectedFormation = JSON.parse(selectedFormationString);
      const formationId = selectedFormation.id;
      
      // Get letters data from localStorage
      const lettresDataString = localStorage.getItem('lettresData');
      if (!lettresDataString) {
        console.error("No letter data found");
        navigate('/lettres');
        return;
      }
      
      const lettresData = JSON.parse(lettresDataString);
      if (!lettresData[formationId]) {
        console.error("No data for this formation ID:", formationId);
        navigate('/lettres');
        return;
      }
      
      // Set letter data from the lettresData structure
      setLetterData({
        letter: lettresData[formationId].letterContent || '',
        formation_name: lettresData[formationId].formation_name || selectedFormation.name || '',
        establishment: lettresData[formationId].establishment || 'Université'
      });
      
    } catch (error) {
      console.error("Error loading letter data:", error);
      navigate('/lettres');
    }
  }, [navigate]);
  
  // Handle save to user's history
  const saveToHistory = () => {
    try {
      // Get existing letter data from localStorage
      const lettresDataString = localStorage.getItem('lettresData') || '{}';
      const lettresData = JSON.parse(lettresDataString);
      
      // Get the selected formation from localStorage
      const selectedFormationString = localStorage.getItem('selectedFormation');
      if (!selectedFormationString) {
        console.error("No selected formation found");
        return;
      }
      
      const selectedFormation = JSON.parse(selectedFormationString);
      const formationId = selectedFormation.id;
      
      // Check if this formation already exists in history
      if (!lettresData[formationId]) {
        // If not, initialize with empty questions array
        lettresData[formationId] = {
          questions: []
        };
      }
      
      // Update the letter content
      lettresData[formationId].letterContent = letterData.letter;
      lettresData[formationId].formation_name = letterData.formation_name;
      lettresData[formationId].establishment = letterData.establishment;
      lettresData[formationId].completed = true;
      lettresData[formationId].createdAt = new Date().toISOString();
      
      // Save back to localStorage
      localStorage.setItem('lettresData', JSON.stringify(lettresData));
      
      console.log("Letter saved to history:", formationId);
    } catch (error) {
      console.error("Error saving letter to history:", error);
    }
  };
  
 // Handle share button click with animation
const handleShare = (e) => {
    e.stopPropagation();
    setIsSharePressed(true);
    
    setTimeout(() => {
      setIsSharePressed(false);
      
      // Use a single app link for all devices
      const appLink = "https://linktr.ee/meoria";
      
      // Construct the text to share - without the letter content
      const shareText = `Tiens, ma lettre de motivation pour la formation ${letterData.formation_name} sur l'app gratuite Meoria ! 👋\n\n${appLink}`;
      
      // Get user ID from localStorage
      const userString = localStorage.getItem('user');
      let userId = null;
      
      if (userString) {
        try {
          const user = JSON.parse(userString);
          userId = user.id;
        } catch (error) {
          console.error('Error parsing user data:', error);
        }
      }
      
      // Get formation ID from selectedFormation in localStorage
      const selectedFormationString = localStorage.getItem('selectedFormation');
      let formationId = null;
      
      if (selectedFormationString) {
        try {
          const selectedFormation = JSON.parse(selectedFormationString);
          formationId = selectedFormation.id;
        } catch (error) {
          console.error('Error parsing formation data:', error);
        }
      }
      
      // Handle the actual sharing
      let sharePromise;
      if (navigator.share) {
        sharePromise = navigator.share({
          title: `Lettre de motivation pour la formation ${letterData.formation_name}`,
          text: shareText,
        })
        .catch(error => {
          console.log('Error sharing', error);
          return Promise.resolve(); // Continue to increment counter even if sharing fails
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        sharePromise = navigator.clipboard.writeText(shareText)
          .then(() => {
            setShowToast(true);
            setTimeout(() => setShowToast(false), 2000);
          })
          .catch(err => {
            console.error("Impossible de copier le texte: ", err);
            return Promise.resolve(); // Continue to increment counter even if clipboard fails
          });
      }
      
      // After sharing (or attempting to share), increment the share count in the backend
      if (userId && formationId) {
        sharePromise.then(() => {
          // Call the backend API to increment share count
          apiCall('/api/lettres/increment-share-count/', 'POST', {
            user_id: userId,
            formation_id: formationId
          })
          .then(response => {
            console.log('Share count updated:', response);
          })
          .catch(error => {
            console.error('Error updating share count:', error);
          });
        });
      } else {
        console.error('Missing user_id or formation_id for share tracking');
      }
    }, 150);
  };
  
  // Handle copy button click with animation
  const handleCopy = (e) => {
    e.stopPropagation();
    setIsCopyPressed(true);
    
    setTimeout(() => {
      setIsCopyPressed(false);
      
      // Copy just the letter content to clipboard
      navigator.clipboard.writeText(letterData.letter)
        .then(() => {
          // Show toast notification
          setShowToast(true);
          setTimeout(() => setShowToast(false), 2000);
        })
        .catch(err => {
          console.error("Impossible de copier le texte: ", err);
        });
    }, 150);
  };

  // Simplified button click handler with console logging
  const handleButtonClick = () => {
    console.log("Button clicked"); // Debug output
    setIsActive(true);
    
    // Navigate after animation delay
    setTimeout(() => {
      console.log("Navigating to /lettres"); // Debug output
      navigate('/lettres');
    }, 150);
  };

  return (
    <>
      {/* Show SharePopup component when LettreFinal is rendered */}
      {showSharePopup && <SharePopup onClose={() => setShowSharePopup(false)} />}
      
      <Container isMobile={isMobile}>
        <HeaderCard height={calculateHeaderHeight(letterData.formation_name)}>
          <HeaderContent>
            <div>
              <TitleText>
                {formatTitle(letterData.formation_name)}
              </TitleText>
              <SubtitleText>
                {formatSubtitle(letterData.establishment)}
              </SubtitleText>
            </div>
            <ButtonGroup>
              <ShareButton 
                isPressed={isSharePressed}
                onClick={handleShare}
              >
                <ButtonIconImg src={shareIcon} alt="Share Icon" />
              </ShareButton>
              <CopyButton 
                isPressed={isCopyPressed}
                onClick={handleCopy}
              >
                <ButtonIconImg src={copyIcon} alt="Copy Icon" />
              </CopyButton>
            </ButtonGroup>
          </HeaderContent>
          
          {/* Character counter */}
          <CharCountContainer>
            <CharCountIcon src={characterCountIcon} alt="Character Count" />
            <CharCountText>{characterCount}/1500</CharCountText>
          </CharCountContainer>
        </HeaderCard>
        
        <LetterContainer>
          <LetterIconContainer>
            <img src={lettreIcon} alt="Letter Icon" width="30" />
          </LetterIconContainer>
          
          <LetterText>
            {letterData.letter}
          </LetterText>
        </LetterContainer>
        
        {/* Pure HTML button solution */}
        <PlainButton 
          type="button"
          onClick={handleButtonClick}
          active={isActive}
        >
          Accèder à mes lettres
        </PlainButton>
        
        {/* Toast notification */}
        <Toast visible={showToast}>Lettre copiée</Toast>
      </Container>
    </>
  );
};

export default LettreFinal;