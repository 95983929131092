import styled from 'styled-components';
import theme from '../../../theme';
import quizBuzzIcon from '../../../assets/quiz_buzz_icon.png';

/**
 * Main container that appears to the right of the SideMenu.
 */
export const DesktopProfileContainer = styled.div`
  width: calc(100vw - 340px);
  margin-left: 320px;
  margin-bottom: 200px;
  padding-left: 10%;
  padding-right: 10%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
  padding-bottom: 100px;
  box-sizing: border-box;

  /* Add overflow if needed so the custom scrollbar is actually visible */
  overflow-y: auto;

  @media (max-width: 800px) {
    width: calc(100vw - 120px);
    margin-left: 100px;
  }

  /* Firefox-specific scrollbar settings */
  scrollbar-width: thin; 
  /* Format: scrollbar-color: thumb track; */
  scrollbar-color: #f1f1f1 #FAFAFA;

  /* WebKit-based browsers */
  &::-webkit-scrollbar {
    width: 8px;            /* Adjust scrollbar width */
  }

  &::-webkit-scrollbar-track {
    background-color: #FAFAFA; /* Match the second (track) color above */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; /* Match the first (thumb) color above */
    border-radius: 4px;        /* Optional: round corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e5e5e5; /* Slightly darker on hover, if you wish */
  }
`;


/** 
 * A full-width container for the big profile icon on top. 
 * Reduced bottom margin to bring sections closer to the icon.
 */
export const ProfileIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Reduced from 20px to 10px */
`;

export const ProfileIcon = styled.img`
  width: 150px;
  height: auto;
  object-fit: contain;
`;

/**
 * Each "row" in which we can place columns side by side.
 * Reduced gap to bring content closer together.
 */
export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px; /* Reduced from 32px */
  margin-top: 20px; 
`;

/** Each "column" in that row. */
export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
  color: #000;
  margin: 0;
`;

/* -------------------------------------
   Récap elements
-------------------------------------- */
export const RecapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns, 2 boxes per row */
  gap: 8px;
  margin-bottom: 20px;
  width: 98%;
`;

export const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px;
  border-radius: 15px;
  background-color: ${(props) => props.bgColor || '#0066FF'};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
`;

export const StatNumber = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 36px;
  margin-left: 8px;
  margin-bottom: 0;
`;

export const StatLabel = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-left: 8px;
  margin-top: -5px;
`;

/* -------------------------------------
   Mes Swipes 
-------------------------------------- */
export const SwipeBox = styled.div`
  width: 98%;
  height: 90px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 5px 5px 5px rgba(166, 73, 73, 0.25);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const QuizIcon = styled.div`
  width: 41px;
  height: 41px;
  background: url(${quizBuzzIcon}) center / contain no-repeat;
  flex-shrink: 0;
`;

export const SwipeText = styled.div`
  color: #535252;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-weight: 400;
`;

/* -------------------------------------
   Generic container (Domains, Infos, etc.)
-------------------------------------- */
export const LargeBoxContainer = styled.div`
  width: 98%;
  min-height: 135px; /* match your "rectangleDiv" reference */
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 5px 5px 5px rgba(166, 73, 73, 0.25);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  padding-right: 10px;
`;

export const ItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  min-height: 50px;
`;

export const ItemIcon = styled.div`
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  background: ${({ bgImage }) =>
    bgImage
      ? `transparent url(${bgImage}) center / contain no-repeat`
      : 'transparent'};
`;

export const ItemText = styled.div`
  flex: 1;
  color: #535252;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

export const ItemSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.background};
`;

/* No "VoirPlusButton" needed now */

/* -------------------------------------
   ScoreBox (for Mes spécialités)
-------------------------------------- */
export const ScoreBox = styled.div`
  border-radius: 5px;
  background: #000;
  width: 55px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ScoreText = styled.div`
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

/* -------------------------------------
   Grade Slider
-------------------------------------- */
export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 10px 0;
  padding: 12px;
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const SliderValue = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #000;
`;

export const GradeSlider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  background: #000;
  border-radius: 3px;
  outline: none;
  margin: 0 8px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    margin-top: 18px;
    transform: translateY(-50%);
  }
`;

export const ConfirmGradeButton = styled.button`
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background-color: #222;
  }
`;

/* -------------------------------------
   Pseudo text area & confirm button
-------------------------------------- */
export const PseudoTextArea = styled.textarea`
  flex: 1;
  min-height: 35px;
  height: 35px;
  padding: 2px 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Gabarito', sans-serif;
  resize: none;
  background-color: #fdfdfd;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.black};
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const ConfirmButton = styled.button`
  padding: 6px 12px;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 8px;
  &:hover {
    background-color: #222;
  }
`;

/* -------------------------------------
   Status or error messages 
-------------------------------------- */
export const MessageStatus = styled.div`
  margin: 20px 0;
  padding: 14px 18px;
  border-radius: 8px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.main};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background: ${({ theme, error }) =>
    error ? theme.colors.primary : theme.colors.background_fonce};
  color: ${({ theme, error }) =>
    error ? theme.colors.lightText : theme.colors.noir};
`;
