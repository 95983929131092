import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import shareIcon from '../../assets/partager_bleu.png'; // Import share icon

// Import apiCall utility
import apiCall from '../../utils/api';

// Styled Components
const HistoryContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
`;

const LettersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const LetterCard = styled.div`
  background: white;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const FormationName = styled.h3`
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #333;
  flex: 1;
`;

const ShareButton = styled.div`
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  background: #FFF;
  box-shadow: ${props => props.isPressed ? 'none' : '0px 3px 0px 0px #E6E6E6'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${props => props.isPressed ? 'translateY(3px)' : 'translateY(0)'};
  transition: transform 0.1s, box-shadow 0.1s;
`;

const ShareIconImg = styled.img`
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  aspect-ratio: 1/1;
`;

const EstablishmentName = styled.div`
  color: #ACACAC;
  text-align: left;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.52%;
  margin-bottom: 8px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 12px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: ${props => props.complete ? theme.colors.vert : theme.colors.bleu_fonce};
  border-radius: 10px;
  transition: width 0.3s linear;
  position: relative;
`;

const SubBar = styled.div`
  position: absolute;
  top: 45%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  height: 3.5px;
  background-color: ${props => props.complete ? theme.colors.vert_clair : theme.colors.bleu};
  border-radius: 10px;
`;

const NoCompletedLetters = styled.div`
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  color: #666;
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
`;

const LetterHistory = () => {
  const navigate = useNavigate();
  const [letters, setLetters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shareButtonState, setShareButtonState] = useState({});
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Load letters data from localStorage
    const loadLettersData = () => {
      try {
        const storedData = localStorage.getItem('lettresData');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setLetters(parsedData);
        }
        
        // Get user ID from localStorage
        const userString = localStorage.getItem('user');
        if (userString) {
          const user = JSON.parse(userString);
          if (user && user.id) {
            setUserId(user.id);
          }
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadLettersData();
  }, []);

  // Calculate the percentage of answered questions
  const calculateProgress = (questions) => {
    if (!questions || questions.length === 0) return 0;
    
    const answeredCount = questions.filter(q => q.answer && q.answer.trim() !== '').length;
    return Math.round((answeredCount / questions.length) * 100);
  };

  // Check if letter is complete (either all questions answered or has generated letter)
  const isLetterComplete = (formationData) => {
    if (!formationData) return false;
    
    // If we have a generated letter, it's complete
    if (formationData.letterContent) return true;
    
    // Otherwise check if all questions are answered
    const questions = formationData.questions || [];
    if (questions.length === 0) return false;
    return questions.every(q => q.answer && q.answer.trim() !== '');
  };

  // Handle letter card click
  const handleLetterClick = (formationId, formationData) => {
    // Save the formation ID to view the complete letter
    localStorage.setItem('selectedFormation', JSON.stringify({
      id: formationId,
      name: formationData.formation_name || `Formation ${formationId}`
    }));
    
    // Navigate to the final letter view directly
    navigate('/lettres-final');
  };

  // Updated handle share button click with animation and API call
  const handleShareClick = (e, formationId, formationData) => {
    e.stopPropagation(); // Prevent card click
    
    // Set button pressed state
    setShareButtonState({...shareButtonState, [formationId]: true});
    
    // Reset button state after animation
    setTimeout(() => {
      setShareButtonState({...shareButtonState, [formationId]: false});
      
      // Detect if device is iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      
      // Determine which link to use based on device type
      const appLink = isIOS
        ? "https://apps.apple.com/fr/app/meoria-orientation-avec-lia/id6737978700"
        : "https://linktr.ee/meoria";
      
      // Construct the full text to share
      const shareText = `Tiens, ma lettre de motivation pour la formation ${formationData.formation_name} sur l'app gratuite Meoria ! 👋\n\n${appLink}\n\n\n${formationData.letterContent || "Ma lettre de motivation"}`;
      
      // Handle share functionality with Promise to track completion
      let sharePromise;
      
      if (navigator.share) {
        sharePromise = navigator.share({
          title: `Lettre de motivation pour ${formationData.formation_name}`,
          text: shareText,
        })
        .catch(error => {
          console.log('Error sharing', error);
          return Promise.resolve(); // Continue to increment counter even if sharing fails
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        sharePromise = navigator.clipboard.writeText(shareText)
          .then(() => {
            alert("Lettre copiée dans le presse-papier !");
          })
          .catch(err => {
            console.error("Impossible de copier le texte: ", err);
            return Promise.resolve(); // Continue to increment counter even if clipboard fails
          });
      }
      
      // After sharing (or attempting to share), increment the share count in the backend
      if (userId) {
        sharePromise.then(() => {
          // Call the backend API to increment share count
          apiCall('/api/lettres/increment-share-count/', 'POST', {
            user_id: userId,
            formation_id: formationId
          })
          .then(response => {
            console.log('Share count updated:', response);
          })
          .catch(error => {
            console.error('Error updating share count:', error);
          });
        });
      } else {
        console.error('Missing user_id for share tracking');
      }
    }, 150);
  };

  // Filter to get only completed formations
  const completedFormations = Object.entries(letters).filter(([_, data]) => isLetterComplete(data));
  
  // Check if there are any completed formations
  const hasCompletedFormations = completedFormations.length > 0;

  if (isLoading) {
    return null; // Or return a loading spinner
  }

  return (
    <HistoryContainer>
      {hasCompletedFormations ? (
        <LettersList>
          {completedFormations.map(([formationId, data]) => {
            const questions = data.questions || [];
            const progress = calculateProgress(questions);
            
            return (
              <LetterCard 
                key={formationId}
                onClick={() => handleLetterClick(formationId, data)}
              >
                <CardHeader>
                  <FormationName>
                    {data.formation_name || `Formation ${formationId}`}
                  </FormationName>
                  <ShareButton 
                    isPressed={shareButtonState[formationId]}
                    onClick={(e) => handleShareClick(e, formationId, data)}
                  >
                    <ShareIconImg src={shareIcon} alt="Partager" />
                  </ShareButton>
                </CardHeader>
                
                <EstablishmentName>
                  {data.establishment || "Université"}
                </EstablishmentName>
                
                <ProgressBarContainer>
                  <ProgressBarFill 
                    percentage={100} 
                    complete={true}
                  >
                    <SubBar complete={true} />
                  </ProgressBarFill>
                </ProgressBarContainer>
              </LetterCard>
            );
          })}
        </LettersList>
      ) : (
        <NoCompletedLetters>
          Aucune lettre complétée pour le moment.
        </NoCompletedLetters>
      )}
    </HistoryContainer>
  );
};

export default LetterHistory;