import styled, { keyframes } from 'styled-components';

/* ─── KEYFRAMES FOR ICON ANIMATION ───────────────────────────── */
const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

/* ─── CHAT BUBBLE CONTAINER ────────────────────────────────── */
export const MeoChatBubbleContainer = styled.div`
  position: fixed;
  top: calc(var(--vh, 1vh) * 15);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 90%;
  max-width: 700px;
`;

/* ─── CHAT TEXT ────────────────────────────────────────────── */
export const MeoChatText = styled.div`
  background-color: transparent;
  color: #3b3a3a;
  padding: 0.8rem 1.2rem;
  border-radius: 1rem;
  font-family: Gabarito;
  font-size: 15px;
  text-align: left;
  width: 80%;
  margin: 0 auto;
  min-height: 100px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid #d9d7d7;
  position: relative;

  /* Bubble tail at the bottom center */
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #d9d7d7;
  }
`;

/* ─── CHAT TEXT INNER ELEMENTS ─────────────────────────────── */
export const SalutJeContainer = styled.span`
  width: 100%;
  position: relative;
  font-size: 15px;
  color: #3b3a3a;
  text-align: left;
  display: inline-block;
  height: 53px;
  font-family: Gabarito;
`;

export const PetitesQuestions = styled.span`
  font-weight: 600;
`;

/* ─── LOGO & ICONS CONTAINER ─────────────────────────────────── */
export const LogoContainer = styled.div`
  position: relative;
  margin: 250px auto 0;
  width: 35%;
`;

/* Adjusted logo image to fit within the LogoContainer */
export const CenteredLogoImage = styled.img`
  display: block;
  width: 100%;
  object-fit: contain;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
  }
`;

/* ─── ICONS WRAPPER ─────────────────────────────────────────── */
export const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

/* ─── INDIVIDUAL ICON IMAGE ─────────────────────────────────── */
export const IconImage = styled.img`
  position: absolute;
  width: 25px;   /* Adjust size as needed */
  height: 25px;  /* Adjust size as needed */
  opacity: 0;
  animation: ${slideUp} 0.5s ease forwards;
  animation-delay: ${props => props.delay || '0s'};
  ${props => props.customStyle || ''}
`;

