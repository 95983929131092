import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  /* Base container styles */
  width: 100vw;
  height: calc(var(--vh, 1vh) * 80);
  // margin-top: calc(var(--vh, 1vh) * 10);
  padding: ${props => props.theme.spacing.large};
  position: relative;
  background-color: transparent;
`;

export const Title = styled.h2`
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 90);
  left: 50%;
  transform: translateX(-50%); /* Centers horizontally */
  color: ${props => props.theme.colors.noir};
  font-size: ${props => props.theme.fontSizes.xlarge};
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
`;

// export const SubTitle = styled.h2`
//   /* Independent positioning */
//   position: absolute;
//   bottom: calc(var(--vh, 1vh) * 83);
//   left: 50%;
//   transform: translateX(-50%); /* Center container but not text */
//   color: ${props => props.theme.colors.noir};
//   font-size: ${props => props.theme.fontSizes.small};
//   font-weight: ${theme.fontWeights.semiBold};
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   max-width: 300px; /* Match input width */
//   text-align: left; /* Align text to left */
// `;

export const Input = styled.input`
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 24);
  margin-left: 0%;
  width: 100%;
  max-width: 300px;
  padding: 7%;
  border: 1px solid ${props => props.theme.colors.gris_clair};
  border-radius: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.fontSizes.medium};
`;

export const Button = styled.button`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};
  margin-bottom: ${props => props.theme.spacing.medium};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => `${props.theme.colors.primary}1A`}; // 10% opacity
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;



export const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  width: ${props => props.width || '50%'};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const SendButton = styled(BaseButton)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.color || theme.colors.lightText};
  border: 3px solid ${props => props.borderColor || theme.colors.primary};
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 13);
  left: 50%;
  width: 300px;
  border-radius: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease; /* Only transition the opacity */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);

  /* Disabled state - only affects opacity */
  ${props => props.disabled && `
    opacity: 0.6;
    cursor: not-allowed;
    background: ${`${props.background || theme.colors.primary}`};
  `}

  /* Enabled state - just remove the opacity */
  ${props => !props.disabled && `
    opacity: 1;
    cursor: pointer;
  `}
`;

// Update your Form component to handle email validation
export const Form = styled.form`
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;



export const ButtonGoogle = styled.button`
    background: white;
    // color: white;
    border: 0px solid ${props => props.borderColor || theme.colors.primary};
    position: absolute;
    bottom: calc(var(--vh, 1vh) * 45);
    left: 50%;
    width: 300px;
    padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
    border-radius: 20px;
    transform: translateX(-50%);
    transition: opacity 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    cursor: pointer;
`;

// New wrapper component to control logo and text layout
export const GoogleButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${props => props.gap || '8px'}; /* Smaller gap between logo and text */
    margin-left: -20px; /* Pull content slightly left to account for logo space */
`;

export const GoogleIcon = styled.img`
    width: 20px; /* Slightly smaller icon */
    height: 20px;
    object-fit: contain;
`;

export const ButtonTextGoogle = styled.span`
    color: ${theme.colors.noir};
    font-size: ${props => props.fontSize || theme.fontSizes.medium};
    font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
    text-align: center;
`;
export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that don't support custom properties */
  height: calc(var(--vh, 1vh) * 100); /* Use dynamic viewport height */
    overflow: hidden; /* Prevents any scrolling */
`;

export const EmailLoginContainer = styled.div`
  /* Independent positioning */
  position: absolute;
  top: 40%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 300px;
`;

export const ReturnButton = styled.button`
  position: fixed;
  position: fixed;
  top: ${theme.spacing.large};
  left: ${theme.spacing.large};
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.colors.primary};
  padding: ${theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);

    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;

// Add this with your other styled components
export const NextButton = styled(ReturnButton)`
  right: ${theme.spacing.large};
  left: auto;

  &:hover {
    transform: translateX(3px);

    svg {
      stroke: ${theme.colors.secondary};
    }
  }
`;



export const LogoImage = styled.img`
  display: block; // Makes margin auto work
  height: 100px;
  margin: 0 auto; // Centers horizontally
  margin-top: calc(var(--vh, 1vh) * 3);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.05) translateY(-2px);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
    animation-play-state: paused;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform;
  }
`;

export const ButtonLabel = styled.span`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin: ${props => props.isBack ? '0 0 0 8px' : '0 8px 0 0'};
  order: ${props => props.isBack ? 2 : 1};
`;

export const Message = styled.p`
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.spacing.small};
  margin-top: ${props => props.theme.spacing.medium};
  text-align: center;
  width: 100%;
  max-width: 300px;
  border: 1px solid ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
`;

export const CountdownText = styled.p`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
  text-align: center;
  margin-bottom: 20vh;
`;

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

export const PopupButton = styled(Button)`
  margin: 10px;
`;


export const Divider = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 300px; /* Same as buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.medium};
  bottom: calc(var(--vh, 1vh) * 55);
`;

export const DividerLine = styled.span`
  flex: 1;
  height: 1px;
  background-color: ${theme.colors.noir}; /* Or any color you prefer */
  opacity: 0.3;
`;

export const DividerText = styled.span`
  color: ${theme.colors.noir};
  font-size: ${theme.fontSizes.small};
  font-weight: ${theme.fontWeights.medium};
  padding: 0 ${theme.spacing.small};
  opacity: 0.6;
`;

export const TermsText = styled.p`
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 11);
  left: 50%;
  transform: translateX(-50%);
  width: 65%;
  z-index: 2000;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.xsmall};
  color: ${props => props.theme.colors.noir};
`;

export const TermsLink = styled.span`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;

export const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const AlertBox = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 90%;
  width: 300px;
`;

export const AlertMessage = styled.p`
  margin: 0 0 20px 0;
  color: #ef4444;
  font-size: 16px;
`;

export const AlertButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #dc2626;
  }
`;


export const ButtonApple = styled.button`
  background: black;
  color: ${props => props.color || theme.colors.lightText};
  border: 0px solid ${props => props.borderColor || theme.colors.primary};
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 35);
  left: 50%;
  width: min(90vw, 300px);
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  border-radius: 20px;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export const AppleButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.gap || '8px'};
  margin-left: -20px;
  color: white;
`;

export const AppleIcon = styled.img`
  width: clamp(16px, 5vw, 20px);
  height: clamp(16px, 5vw, 20px);
  object-fit: contain;
  // color: white;
`;

export const ButtonTextApple = styled.span`
  color: white;
  font-size: clamp(${props => props.theme.fontSizes.small}, 3vw, ${props => props.theme.fontSizes.medium});
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  text-align: center;
`;

export const BottomActionsContainer = styled.div`
  position: fixed;
  bottom: calc(var(--vh, 1vh) * 5); /* 6vh from bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: baseline;
  justify-content: center; /* This keeps horizontal centering */
  gap: ${theme.spacing.xsmall};
  width: 90%;
  z-index: 1000;
`;

export const StyledText = styled.span`
  color: ${theme.colors.gris};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.color || theme.colors.noir};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semiBold};
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  position: relative;
`
;