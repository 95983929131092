import React, { useState } from 'react';
import styled from 'styled-components';
import apiCall from '../../utils/api';

// Import your assets – adjust the path as needed
import popupImage from '../../assets/meo_write.png';
import shareIcon from '../../assets/partager_blanc.png';

// Styled components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; /* Ensures the overlay is on top */
`;

const PopupContainer = styled.div`
  width: 70%;
  height: auto;
  flex-shrink: 0;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

const Title = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 15px 0 5px;
  text-align: center;
`;

const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  width: 100%;
`;

// Primary button styling with blue color instead of pink
const PrimaryButton = styled.button`
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  background: #41B6FB; /* Changed from #ff2768 to blue */
  box-shadow: ${props => props.isPressed ? 'none' : '0px 3px 0px 0px #1396E4'};
  border: none;
  border-radius: 20px;
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transform: ${props => props.isPressed ? 'translateY(3px)' : 'translateY(0)'};
  transition: transform 0.1s, box-shadow 0.1s;
  
  img {
    width: 20px;
    height: 20px;
  }
`;

const SecondaryButton = styled.button`
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  background: rgba(255, 39, 104, 0);
  color: rgb(187, 187, 187);
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;

// Toast notification
const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  z-index: 3500;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease;
`;

const SharePopup = ({ onClose }) => {
  // Local state to control the visibility
  const [isSharePressed, setIsSharePressed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  
  const handleShare = () => {
    setIsSharePressed(true);
    
    setTimeout(() => {
      setIsSharePressed(false);
      
      // Get formation data from localStorage
      const selectedFormationString = localStorage.getItem('selectedFormation');
      if (!selectedFormationString) {
        console.error("No selected formation found");
        if (onClose) onClose();
        return;
      }
      
      const selectedFormation = JSON.parse(selectedFormationString);
      
      // Get letter data from localStorage
      const lettresDataString = localStorage.getItem('lettresData');
      if (!lettresDataString) {
        console.error("No letter data found");
        if (onClose) onClose();
        return;
      }
      
      const lettresData = JSON.parse(lettresDataString);
      const formationId = selectedFormation.id;
      
      if (!lettresData[formationId]) {
        console.error("No data for this formation ID:", formationId);
        if (onClose) onClose();
        return;
      }
      
      const letterData = {
        letter: lettresData[formationId].letterContent || '',
        formation_name: lettresData[formationId].formation_name || selectedFormation.name || '',
      };
      
      // Use a single app link for all devices
      const appLink = "https://linktr.ee/meoria";
      
      // Construct the text to share - without the letter content
      const shareText = `Tiens, ma lettre de motivation pour la formation ${letterData.formation_name} sur l'app gratuite Meoria ! 👋\n\n${appLink}`;
      
      // Get user ID from localStorage
      const userString = localStorage.getItem('user');
      let userId = null;
      
      if (userString) {
        try {
          const user = JSON.parse(userString);
          userId = user.id;
        } catch (error) {
          console.error('Error parsing user data:', error);
        }
      }
      
      // Handle the actual sharing
      let sharePromise;
      if (navigator.share) {
        sharePromise = navigator.share({
          title: `Lettre de motivation pour la formation ${letterData.formation_name}`,
          text: shareText,
        })
        .catch(error => {
          console.log('Error sharing', error);
          return Promise.resolve(); // Continue to increment counter even if sharing fails
        });
      } else {
        // Fallback for browsers that don't support the Web Share API
        sharePromise = navigator.clipboard.writeText(shareText)
          .then(() => {
            setShowToast(true);
            setTimeout(() => setShowToast(false), 2000);
          })
          .catch(err => {
            console.error("Impossible de copier le texte: ", err);
            return Promise.resolve(); // Continue to increment counter even if clipboard fails
          });
      }
      
      // After sharing (or attempting to share), increment the share count in the backend
      if (userId && formationId) {
        sharePromise.then(() => {
          // Call the backend API to increment share count
          apiCall('/api/lettres/increment-share-count/', 'POST', {
            user_id: userId,
            formation_id: formationId
          })
          .then(response => {
            console.log('Share count updated:', response);
          })
          .catch(error => {
            console.error('Error updating share count:', error);
          });
        });
      } else {
        console.error('Missing user_id or formation_id for share tracking');
      }
      
      // Close the popup after sharing
      if (onClose) onClose();
    }, 150);
  };
  
  return (
    <Overlay>
      <PopupContainer>
        <ImageWrapper src={popupImage} alt="Popup banner" />
        <Title>Partage le bon plan !</Title>
        <Description>
          Si notre outil t'as aidé, partage-le à tes potes pour les aider à écrire leurs lettres de motivation !
        </Description>
        <ButtonContainer>
          <PrimaryButton 
            onClick={handleShare} 
            isPressed={isSharePressed}
          >
            Partager
            <img src={shareIcon} alt="Share icon" />
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>
            Pas maintenant
          </SecondaryButton>
        </ButtonContainer>
      </PopupContainer>
      <Toast visible={showToast}>Le texte est copié</Toast>
    </Overlay>
  );
};

export default SharePopup;