import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ========== ASSET IMPORTS ==========
import MeoQuiz from '../../../assets/meo_compass.png';
import FormationIcon from '../../../assets/diplome_icon.png';
import BacIcon from '../../../assets/programme_cours.png';
import SuggestIcon from '../../../assets/suggestion_icon.png';
import DeboucheIcon from '../../../assets/debouches_icon.png';
import ConseillerIcon from '../../../assets/chat_icon.png';
import AdmissionIcon from '../../../assets/chances_admission_icon.png';

// Additional images
import SwipeLP from '../../../assets/Swipe_LP.png';
import ResultatsLP from '../../../assets/Résultats_LP.png';
import CardTypeFormationLP from '../../../assets/CardTypeFormation_LP.png';
import ChatLP from '../../../assets/Chat_LP.png';

// NEW images for bottom sections
import AppIcone from '../../../assets/app_icone.png';
import AppleAndPlayStoreIcons from '../../../assets/apple_and_play_store_icons.png';
import MeoFamily from '../../../assets/meos.png';
import InstagramIcon from '../../../assets/instagram-icon.png';
import TiktokIcon from '../../../assets/tiktok-icon.png';
import LinkedinIcon from '../../../assets/linkedin-icon.png';

// ========== STYLE IMPORTS ==========
import {
  EnhancedContainer,
  DynamicNavBar,
  DynamicNavLogo,
  NavCTAButton,
  MainContent,
  LeftPart,
  CircleContainer,
  MeoQuizIcon,
  FeatureBox,
  RightPart,
  MeoTeGuide,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton,
  TopButtonText,
  BottomButtonText,
  RotatingFeatures,
  SectionSeparator,
  SectionContainer,
  SectionLeft,
  SectionRight,
  BigSubtitle,
  Description,
  OrientationBox,
  OrientationTitle,
  OrientationIconsRow,
  FooterBox,
  FooterColumn,
  FooterSubtitle,
  FooterLink,
  SocialIconsRow,
  BottomSpacing,
  SchoolButton,
  NavSecondaryButton,
} from './styles';

// ========== IMPORT THE POPUP COMPONENT ========== 
import PopupGlobaleOneChoice from '../../PopupGlobaleOneChoice';

const LandingPage = () => {
  const navigate = useNavigate();

  // === State for button press animation ===
  const [pressedButton, setPressedButton] = useState(null);

  // === Intersection Observer to show/hide header buttons ===
  const [ctaVisible, setCtaVisible] = useState(true);
  const ctaRef = useRef(null);
  const loginRef = useRef(null);

  // === State to control the visibility of the Popup ===
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // === Handler for logo click ===
  const handleLogoClick = () => {
    // First scroll to top
    window.scrollTo(0, 0);
    // Then navigate to home
    navigate('/');
  };

  // === Intersection Observer logic ===
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setCtaVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    const currentCtaRef = ctaRef.current;
    const currentLoginRef = loginRef.current;

    if (currentCtaRef) {
      observer.observe(currentCtaRef);
    }
    if (currentLoginRef) {
      observer.observe(currentLoginRef);
    }
    
    return () => {
      if (currentCtaRef) {
        observer.unobserve(currentCtaRef);
      }
      if (currentLoginRef) {
        observer.unobserve(currentLoginRef);
      }
    };
  }, []);

  // === For the NavBar CTA button ===
  const handleNavCtaPressStart = () => {
    setPressedButton('navCta');
  };
  
  const handleNavCtaPressEnd = () => {
    setTimeout(() => {
      setPressedButton(null);
      navigate('/presentation-meo');
    }, 100);
  };

  // === For the "C'est parti !" button ===
  const handleCreatePressStart = () => {
    setPressedButton('create');
  };
  
  const handleCreatePressEnd = () => {
    setTimeout(() => {
      setPressedButton(null);
      navigate('/presentation-meo');
    }, 100);
  };
  
  // === For the "J'ai déjà un compte" button ===
  const handleLoginPressStart = () => {
    setPressedButton('login');
  };
  
  const handleLoginPressEnd = () => {
    setTimeout(() => {
      setPressedButton(null);
      navigate('/login');
    }, 100);
  };
  
  // === For the School button ===
  const handleSchoolPressStart = () => {
    setPressedButton('school');
  };
  
  const handleSchoolPressEnd = () => {
    setTimeout(() => {
      setPressedButton(null);
      window.scrollTo(0, 0);
      navigate('/manager');
    }, 100);
  };

  // === Handler to CLOSE the popup ===
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  // === The 6 features to place around the Meo quiz icon ===
  const features = [
    { icon: FormationIcon, text: 'Programme des formations' },
    { icon: BacIcon, text: '100% des formations post-bac' },
    { icon: SuggestIcon, text: 'Suggestions personnalisées' },
    { icon: DeboucheIcon, text: 'Débouchés' },
    { icon: ConseillerIcon, text: "Conseiller d'orientation 24/7" },
    { icon: AdmissionIcon, text: "Chances d'admission" },
  ];

  return (
    <EnhancedContainer>
      {/* ========== FIXED NAVBAR ========== */}
      <DynamicNavBar>
        <DynamicNavLogo onClick={handleLogoClick}>
          MEORIA
        </DynamicNavLogo>
        {!ctaVisible && (
          <div style={{ display: 'flex', gap: '20px', marginLeft: 'auto' }}>
            <NavSecondaryButton
              onMouseDown={() => {
                window.scrollTo(0, 0);
                navigate('/manager');
              }}
              onMouseUp={() => {
                window.scrollTo(0, 0);
                navigate('/manager');
              }}
            >
              Je suis une école
            </NavSecondaryButton>
            <NavCTAButton 
              onMouseDown={handleNavCtaPressStart}
              onMouseUp={handleNavCtaPressEnd}
              onMouseLeave={() => setPressedButton(null)}
              onTouchStart={handleNavCtaPressStart}
              onTouchEnd={handleNavCtaPressEnd}
              active={pressedButton === 'navCta'}
            >
              C'est parti !
            </NavCTAButton>
          </div>
        )}
      </DynamicNavBar>

      {/* ========== MAIN CONTENT (2 columns) ========== */}
      <MainContent>
        {/* LEFT PART (2/3 width) */}
        <LeftPart>
          <CircleContainer>
            {/* Center icon */}
            <MeoQuizIcon src={MeoQuiz} alt="Meo Quiz" />

            {/* 6 Feature boxes placed in a circle */}
            <RotatingFeatures>
              {features.map((item, index) => (
                <FeatureBox key={index} index={index}>
                  <img src={item.icon} alt="" />
                  {item.text}
                </FeatureBox>
              ))}
            </RotatingFeatures>
          </CircleContainer>
        </LeftPart>

        {/* RIGHT PART (1/3 width) */}
        <RightPart>
          <MeoTeGuide>
            Meo te guide dans la jungle de l'orientation<br />
            post-bac !
          </MeoTeGuide>

          <ButtonsWrapper>
            <PrimaryButton 
              ref={ctaRef}
              onMouseDown={handleCreatePressStart}
              onMouseUp={handleCreatePressEnd}
              onMouseLeave={() => setPressedButton(null)}
              onTouchStart={handleCreatePressStart}
              onTouchEnd={handleCreatePressEnd}
              active={pressedButton === 'create'}
            >
              <TopButtonText>C'est parti !</TopButtonText>
            </PrimaryButton>
            <SecondaryButton 
              ref={loginRef}
              onMouseDown={handleLoginPressStart}
              onMouseUp={handleLoginPressEnd}
              onMouseLeave={() => setPressedButton(null)}
              onTouchStart={handleLoginPressStart}
              onTouchEnd={handleLoginPressEnd}
              active={pressedButton === 'login'}
            >
              <BottomButtonText>J'ai déjà un compte</BottomButtonText>
            </SecondaryButton>
          </ButtonsWrapper>
        </RightPart>
      </MainContent>

      {/* ========== SEPARATOR LINE ========== */}
      <SectionSeparator />

      {/* ========== NEW 4 SECTIONS BELOW ========== */}
      {/* 1) First Section: text left, image right */}
      <SectionContainer>
        <SectionLeft>
          <BigSubtitle>Gratuit. Fun. Efficace.</BigSubtitle>
          <Description>
            Swipe pour trouver les formations qui te correspondent.
          </Description>
        </SectionLeft>
        <SectionRight>
          <img src={SwipeLP} alt="Swipe" />
        </SectionRight>
      </SectionContainer>

      {/* 2) Second Section: text right, image left */}
      <SectionContainer>
        <SectionLeft>
          <img src={ResultatsLP} alt="Résultats" />
        </SectionLeft>
        <SectionRight>
          <BigSubtitle>Une méthode rigoureuse et expliquée.</BigSubtitle>
          <Description>
            Les scores attribués à chaque formation sont expliqués
            de manière à comprendre pourquoi telle ou telle formation
            nous correspond.
          </Description>
        </SectionRight>
      </SectionContainer>

      {/* 3) Third Section: text left, image right */}
      <SectionContainer>
        <SectionLeft>
          <BigSubtitle>Des infos précises et pertinentes.</BigSubtitle>
          <Description>
            Les fiches Type de formation et Formations rassemblent
            les informations générales les plus pertinentes et
            les informations personnalisées de l'élève.
          </Description>
        </SectionLeft>
        <SectionRight>
          <img src={CardTypeFormationLP} alt="Card Type Formation" />
        </SectionRight>
      </SectionContainer>

      {/* 4) Fourth Section: text right, image left */}
      <SectionContainer>
        <SectionLeft>
          <img src={ChatLP} alt="Chat" />
        </SectionLeft>
        <SectionRight>
          <BigSubtitle>Personnalisé. Disponible.</BigSubtitle>
          <Description>
            Meo répond à toutes les questions dans le chat. En une phrase :
            c'est un ChatGPT spécialisé en orientation (formations, écoles,
            débouchés, admissions, salaires, etc...).
          </Description>
        </SectionRight>
      </SectionContainer>

      {/* ========== BOX: "Oriente-toi dès maintenant" ========== */}
      <OrientationBox>
        <OrientationTitle>
          Oriente-toi dès maintenant,<br />
          gratuitement
        </OrientationTitle>
        <OrientationIconsRow>
          <img
            src={AppIcone}
            alt="App icon"
            style={{ cursor: 'pointer' }}
          />
          <div style={{ position: 'relative', cursor: 'pointer' }}>
            <a 
              href="https://play.google.com/store/apps/details?id=com.meoria.twa&hl=fr"
              target="_blank"
              rel="noopener noreferrer"
              style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%',
                height: '100%'
              }}
              aria-label="Get it on Google Play"
            >
              <span className="sr-only">Get it on Google Play</span>
            </a>
            <a 
              href="https://apps.apple.com/fr/app/meoria-orientation-avec-lia/id6737978700"
              target="_blank"
              rel="noopener noreferrer"
              style={{ 
                position: 'absolute', 
                bottom: 0, 
                left: 0, 
                width: '100%',
                height: '50%',
                zIndex: 2 
              }}
              aria-label="Get it on App Store"
            >
              <span className="sr-only">Get it on App Store</span>
            </a>
            <img
              src={AppleAndPlayStoreIcons}
              alt="Apple & Play Store icons"
              style={{ position: 'relative', zIndex: 1 }}
            />
          </div>
        </OrientationIconsRow>
      </OrientationBox>

      <BottomSpacing />

      {/* ========== FOOTER BOX ========== */}
      <FooterBox>
        {/* Left Column */}
        <FooterColumn>
          <FooterSubtitle>Meo est en règles</FooterSubtitle>
          <FooterLink
            href="https://meoria.com/politique.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Politique de confidentialité
          </FooterLink>
          <FooterLink
            href="https://meoria.com/CGU.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conditions d'utilisations
          </FooterLink>
        </FooterColumn>

        {/* Middle Column */}
        <FooterColumn>
          <FooterSubtitle style={{ marginBottom: '-60px' }}>
            Par l'équipe Meoria
            <br />
            &
          </FooterSubtitle>
          <img src={MeoFamily} alt="Meo Family" />
        </FooterColumn>

        <FooterColumn>
          <FooterSubtitle>Je suis une école</FooterSubtitle>
          <SchoolButton 
            onMouseDown={handleSchoolPressStart}
            onMouseUp={handleSchoolPressEnd}
            onMouseLeave={() => setPressedButton(null)}
            onTouchStart={handleSchoolPressStart}
            onTouchEnd={handleSchoolPressEnd}
            active={pressedButton === 'school'}
          >
            Accéder à Meoria Manager
          </SchoolButton>
        </FooterColumn>

        {/* Right Column */}
        <FooterColumn>
          <FooterSubtitle>Retrouve-nous ici</FooterSubtitle>
          <SocialIconsRow>
            <a
              href="https://www.instagram.com/meoria_app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@meoria_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TiktokIcon} alt="TikTok" />
            </a>
            <a
              href="https://www.linkedin.com/company/96219690/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinIcon} alt="LinkedIn" />
            </a>
          </SocialIconsRow>
        </FooterColumn>
      </FooterBox>

      {/* ========== POPUP COMPONENT ========== */}
      <PopupGlobaleOneChoice
        isVisible={isPopupVisible}
        message={
            "L'application est disponible uniquement en version mobile pour le moment. À très vite sur la version ordinateur ! \n\nOrientant, Meo"
          }
        onClose={handleClosePopup}
        buttonText="C'est noté !"
      />
    </EnhancedContainer>
  );
};

export default LandingPage;