import styled, { keyframes, css } from 'styled-components';
import theme from '../../theme';

/* ----------------- ANIMATIONS ----------------- */
const bounce = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
`;

const pop = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3) rotate(15deg); }
  100% { transform: scale(1) rotate(0); }
`;

const floatHeartAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -200px) scale(0);
    opacity: 0;
  }
`;

/* ----------------- HEADER ----------------- */
export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  // left: 0;
  // right: 0;
  width: ${props => props.isMobile ? '100vw' : 'calc(100vw - 320px)'};
  height: 160px; 
  display: flex;
  justify-content: center;
  background: ${theme.colors.background};
  z-index: 1603;
  overflow: hidden;
  padding: 0 1rem;
`;

export const HeaderSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 0.7rem;
`;

export const HeaderContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 379px;
  padding: 1rem 0 3rem;
  z-index: 1;
`;

export const HeaderTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
`;

export const LikeIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 0%;
  margin-right: 5%;
  color: ${props => props.$isFavorite ? '#FFF' : 'rgba(255,255,255, 1)'};
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: #FFF;
  }

  animation: ${props => props.$animate 
    ? css`${bounce} 0.5s ease` 
    : 'none'};
`;

export const ShareIcon = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  animation: ${props => props.$animate
    ? css`${pop} 0.3s ease`
    : 'none'};
`;

export const FloatingHeart = styled.div`
  position: absolute;
  pointer-events: none;
  animation: ${floatHeartAnimation} 2s ease-out forwards;
  color: #fff;
  font-size: 24px;
  z-index: 2000;
`;

export const HeaderBottomRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 0.3rem;
  width: 100%;
  align-items: flex-start;
  padding: 0 1rem; /* Ensures left alignment inside the header */
`;

export const FormationName = styled.h1`
  color: white;
  font-size: 1.2rem;
  font-family: 'Gabarito', sans-serif;
  font-weight: 500;
  margin: 0.2rem 0;
  line-height: 1.3;

  /* Two-line clamp + ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow text to wrap to multiple lines */

  /* Force a right margin to avoid expanding fully */
  margin-right: 1.3rem;
  max-width: calc(100% - 1.3rem);
`;

export const SchoolName = styled.span`
  font-size: 0.7rem;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  margin-bottom: 0.5rem;

  /* One-line clamp + ellipsis */
   display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-right: 1rem;
  max-width: calc(100% - 1rem);
`;

export const TabsContainer = styled.div`
  position: sticky;
  top: 160px; 
  z-index: 1603;
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 1rem 0;
  background-color: ${theme.colors.background};
  padding-bottom: 1.5rem;
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px; /* space for icon + text */
  width: 30%;
  height: 40px;
  position: relative;
  box-shadow: ${({ active }) =>
    active
      ? '0px 2px 2px rgba(56, 182, 255, 0.75)'
      : '0px 2px 2px rgba(217, 215, 215, 0.75)'};
  border-radius: 25px;
  background-color: ${({ active }) => (active ? '#dcf3ff' : '#fff')};
  border: 2px solid
    ${({ active }) =>
      active ? 'rgba(56, 182, 255, 0.47)' : 'rgba(217, 215, 215, 0.47)'};
  cursor: pointer;
  justify-content: center;
  font-size: 15px;
  font-weight: ${({ active }) => (active ? '600' : '500')};
  font-family: 'Gabarito', sans-serif;
  color: ${({ active }) => (active ? '#38b6ff' : '#000')};
  text-align: left;
`;

export const RectangleDiv = styled.div`
  width: 110%;
  position: sticky;
  border-radius: 25px;
  top: calc(160px + 58px); 
  background-color: #fff;
  height: 10px;
  z-index: 1603; /* Same high z-index to stay on top */
`;


export const ScrollableContent = styled.div`
  padding: 1rem;
`;

/* ----------------- OTHER STYLES ----------------- */
export const Container = styled.div`
  width: 100%;
  ${props => !props.isMobile && `
    width: calc(100vw - 320px);
    margin-left: 320px;
  `}
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1603;
  background-color: ${theme.colors.background};
  font-family: 'Gabarito', sans-serif;

  
  scrollbar-width: thin;
  scrollbar-color:rgb(241, 241, 241) #FAFAFA;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color:f1f1f1;
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
`;

export const CityContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  flex-shrink: 0;
`;

export const CityText = styled.span`
  margin-top: 5px;
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
`;

export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 0px 3px;
  background: white;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid #ddd;
  margin-bottom: 10%;
  margin-left: -2%;

  &:hover {
    background: #f5f5f5;
    transform: translateY(-1px);
  }
`;

export const ButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const ButtonText = styled.span`
  font-family: 'Gabarito', sans-serif;
  font-size: 11px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;

  ${ActionButton}:hover & {
    color: #000;
  }
`;

export const Subtitle = styled.span`
  font-family: 'Gabarito', sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-left: -10%;
`;

export const ReturnButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 1604;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.colors.primary};
    stroke-width: 2.5px;
  }

  &:hover {
    transform: translateX(-3px);
    svg {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`;


export const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  flex: 1;
  overflow: hidden;
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding-right: 1rem;
`;

export const ScoreTopText = styled.span`
  color: white;
  font-size: 1rem;
  font-family: 'Gabarito', sans-serif;
  /* Réduire l'espacement sous le texte "Match à" */
  margin-bottom: 2px; /* Vous pouvez aussi mettre 0 si besoin */
`;

export const ScoreValue = styled.span`
  color: white;
  font-size: 2.5rem;
  font-family: 'Gabarito', sans-serif;
  font-weight: bold;
  /* Supprimer l'espacement en haut */
  margin-top: -10%;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: -5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0 80px; // Ajoute un padding en bas
`;
export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 0.5rem 0;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  text-align: center;
`;

export const InfoIcon = styled.div`
  width: 31px;
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const InfoText = styled.div`
  font-size: 18px;
  font-family: 'Gabarito', sans-serif;
  color: #000;
  text-align: center;
  width: 70%;
`;

export const Section = styled.div`
  width: 95%;
  margin: 1rem auto;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  font-family: 'Gabarito', sans-serif;
  color: #000;
  margin-bottom: 1rem;
  font-weight: 600;
`;

export const SectionText = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.4;
  margin: 0.25rem 0;
  text-align: left;
  width: 100%;
  word-break: break-word;
  padding-left: 1em;
  position: relative;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #666;
  }
`;

export const SubsectionTitle = styled.h3`
  font-size: 18px;
  font-family: 'Gabarito', sans-serif;
  color: #333;
  margin: 1rem 0 0.5rem;
  font-weight: 500;
`;

export const GrayBandWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;


export const GrayBand = styled.div`
  width: 160%;
  height: 23px;
  border-radius: 25px;
  background: #f2f2f2;
  margin: 1rem 0;
  position: relative;
  left: -30%;
  box-sizing: border-box;

`;

/* ----------------- DOMAINS MATCHING ----------------- */
export const DomainContainer = styled.div`
  width: 100%;
  // max-width: 95%;
  margin: 0 auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  border-radius: 25px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1rem;
`;


export const DomainItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DomainIcon = styled.div`
  width: 35px;
  height: 35px;
  background: none;
  border-radius: 4px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DomainText = styled.div`
  font-size: ${theme.fontSizes.small};
  color: #000;
  font-weight: 400;
`;


export const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const ImportanceSubtitle = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Gabarito', sans-serif;
  margin: 0.5rem 0;
  text-align: center;
  width: 30%;
`;

export const DomainItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
`;

export const LeftDomainContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  max-width: 70%;
`;

export const RightIconsContainer = styled.div`
  display: flex;
  justify-content: center;  // Center horizontally
  align-items: center;      // Center vertically
  gap: 0.25rem;
  width: 30%;               // Match the remaining width
  height: 100%;             // Take full height of parent
`;

export const ImportanceIcons = styled.div`
  display: flex;
  gap: 0.25rem;
  color: ${theme.colors.primary};
`;
