// Array of high schools with name and location
const highSchoolsData = [
    { name: "Acajou 1", location: "Le Lamentin", uai: "9720694X" },
    { name: "Acajou II", location: "Le Lamentin", uai: "9720695Y" },
    { name: "Adam de Craponne", location: "Salon-de-Provence", uai: "0130161E" },
    { name: "Adolphe Chérioux", location: "Vitry-sur-Seine", uai: "0941294W" },
    { name: "Adrien Zeller", location: "Bouxwiller", uai: "0670007U" },
    { name: "Agora", location: "Puteaux", uai: "0920144G" },
    { name: "Aiguerande", location: "Belleville-en-Beaujolais", uai: "0693734J" },
    { name: "Aimé Césaire", location: "Clisson", uai: "0442752C" },
    { name: "Al Kindi", location: "Décines-Charpieu", uai: "0694086S" },
    { name: "Alain", location: "Le Vésinet", uai: "0782568T" },
    { name: "Alain", location: "Alençon", uai: "0610001V" },
    { name: "Alain Borne", location: "Montélimar", uai: "0260015A" },
    { name: "Alain Chartier", location: "Bayeux", uai: "0140004D" },
    { name: "Alain Colas", location: "Nevers", uai: "0580753D" },
    { name: "Alain Fournier", location: "Bourges", uai: "0180005H" },
    { name: "Alain Fournier", location: "Mirande", uai: "0320023B" },
    { name: "Alain René Lesage", location: "Vannes", uai: "0560051B" },
    { name: "Albert Calmette", location: "Nice", uai: "0060031B" },
    { name: "Albert Camus", location: "Nîmes", uai: "0300023M" },
    { name: "Albert Camus", location: "Bois-Colombes", uai: "0920132U" },
    { name: "Albert Camus", location: "Fréjus", uai: "0831440P" },
    { name: "Albert Camus", location: "Mourenx", uai: "0640044A" },
    { name: "Albert Camus", location: "Nantes", uai: "0440288Z" },
    { name: "Albert Camus", location: "Firminy", uai: "0420013L" },
    { name: "Albert Châtelet", location: "Douai", uai: "0590063Y" },
    { name: "Albert Châtelet", location: "Saint-Pol-sur-Ternoise", uai: "0620166U" },
    { name: "Albert Claveille", location: "Périgueux", uai: "0240026Y" },
    { name: "Albert de Mun", location: "Nogent-sur-Marne", uai: "0940880W" },
    { name: "Albert Einstein", location: "Bagnols-sur-Cèze", uai: "0300950V" },
    { name: "Albert Einstein", location: "Sainte-Geneviève-des-Bois", uai: "0911346U" },
    { name: "Albert Le Grand", location: "Bordeaux", uai: "0331491S" },
    { name: "Albert Londres", location: "Cusset", uai: "0030051P" },
    { name: "Albert Schweitzer", location: "Le Raincy", uai: "0930830X" },
    { name: "Albert Schweitzer", location: "Mulhouse", uai: "0680031P" },
    { name: "Albert Sorel", location: "Honfleur", uai: "0140056K" },
    { name: "Albert Thomas", location: "Roanne", uai: "0420033H" },
    { name: "Albert Triboulet", location: "Romans-sur-Isère", uai: "0260022H" },
    { name: "Alcide d'Orbigny", location: "Bouaye", uai: "0442309W" },
    { name: "Alcide Dusolier", location: "Nontron", uai: "0240021T" },
    { name: "Alexandra David-Néel", location: "Digne-les-Bains", uai: "0040027H" },
    { name: "Alexandre Denis", location: "Cerny", uai: "0910630R" },
    { name: "Alexandre Dumas", location: "Saint-Cloud", uai: "0920801W" },
    { name: "Alexandre Ribot", location: "Saint-Omer", uai: "0620161N" },
    { name: "Alexis de Tocqueville", location: "Grasse", uai: "0061760F" },
    { name: "Alexis de Tocqueville", location: "Cherbourg-en-Cotentin", uai: "0500017X" },
    { name: "Alexis Monteil", location: "Rodez", uai: "0120024L" },
    { name: "Alfred Kastler", location: "Stenay", uai: "0550072E" },
    { name: "Alfred Kastler", location: "Guebwiller", uai: "0680015X" },
    { name: "Alfred Kastler", location: "Cergy", uai: "0951399E" },
    { name: "Alfred Kastler", location: "Talence", uai: "0330135T" },
    { name: "Alfred Kastler", location: "Denain", uai: "0590060V" },
    { name: "Alfred Mézières", location: "Longwy", uai: "0540030P" },
    { name: "Alfred Nobel", location: "Clichy-sous-Bois", uai: "0932026X" },
    { name: "Algoud Laffemas", location: "Valence", uai: "0261505V" },
    { name: "Aliénor d'Aquitaine", location: "Poitiers", uai: "0860038Z" },
    { name: "Alliance", location: "Les Pavillons-sous-Bois", uai: "0931813R" },
    { name: "Alphonse Benoît", location: "L'Isle-sur-la-Sorgue", uai: "0840021S" },
    { name: "Alphonse Daudet", location: "Nîmes", uai: "0300021K" },
    { name: "Alphonse Daudet", location: "Tarascon", uai: "0130164H" },
    { name: "Alphonse de Lamartine", location: "Paris 9e", uai: "0750670W" },
    { name: "Amboise", location: "Albi", uai: "0810068J" },
    { name: "Ambroise Brugière", location: "Clermont-Ferrand", uai: "0630077S" },
    { name: "Ambroise Croizat", location: "Moûtiers", uai: "0730029K" },
    { name: "Ambroise Paré", location: "Laval", uai: "0530010Y" },
    { name: "Ambroise Vollard", location: "Saint-Pierre", uai: "9740019C" },
    { name: "Amep", location: "Fort-de-France", uai: "9720615L" },
    { name: "Ami", location: "Marseille 6e", uai: "0133555U" },
    { name: "Amiral de Grasse", location: "Grasse", uai: "0060020P" },
    { name: "Amiral Ronarc'h", location: "Brest", uai: "0290008B" },
    { name: "Ampère", location: "Lyon 2e", uai: "0690023A" },
    { name: "Amélie Zurcher", location: "Wittelsheim", uai: "0681888H" },
    { name: "Anatole France", location: "Lillers", uai: "0620120U" },
    { name: "André Argouges", location: "Grenoble", uai: "0381603L" },
    { name: "André Boulloche", location: "Livry-Gargan", uai: "0931585T" },
    { name: "André Chamson", location: "Le Vigan", uai: "0300052U" },
    { name: "André Honnorat", location: "Barcelonnette", uai: "0040003G" },
    { name: "André Lurçat", location: "Maubeuge", uai: "0595884A" },
    { name: "André Malraux", location: "Gaillon", uai: "0271580W" },
    { name: "André Malraux", location: "Allonnes", uai: "0721548S" },
    { name: "André Malraux", location: "Remiremont", uai: "0880153N" },
    { name: "André Malraux", location: "Montataire", uai: "0601864A" },
    { name: "André Malraux", location: "Biarritz", uai: "0640017W" },
    { name: "André Malraux", location: "Béthune", uai: "0620042J" },
    { name: "André Malraux", location: "Montereau-Fault-Yonne", uai: "0770938B" },
    { name: "André Maurois", location: "Elbeuf", uai: "0760029U" },
    { name: "André Maurois", location: "Deauville", uai: "0140043W" },
    { name: "André Maurois", location: "Bischwiller", uai: "0670005S" },
    { name: "André Theuriet", location: "Civray", uai: "0860009T" },
    { name: "Angela Davis", location: "Saint-Denis", uai: "0932667U" },
    { name: "Anguier", location: "Eu", uai: "0760032X" },
    { name: "Anita Conti", location: "Bruz", uai: "0352686E" },
    { name: "Anna de Noailles", location: "Évian-les-Bains", uai: "0740051D" },
    { name: "Anna Judic", location: "Semur-en-Auxois", uai: "0210047M" },
    { name: "Antoine Bourdelle", location: "Montauban", uai: "0820021C" },
    { name: "Antoine de Saint-Exupéry", location: "Les Avirons", uai: "9740045F" },
    { name: "Antoine de Saint-Exupéry", location: "Saint-Raphaël", uai: "0830042V" },
    { name: "Antoine de Saint-Exupéry", location: "Lyon 4e", uai: "0690031J" },
    { name: "Antoine de Saint-Exupéry", location: "Fameck", uai: "0570023R" },
    { name: "Antoine de Saint-Exupéry", location: "Créteil", uai: "0940114N" },
    { name: "Antoine de Saint-Exupéry", location: "La Rochelle", uai: "0171418Z" },
    { name: "Antoine de Saint-Exupéry", location: "Terrasson-Lavilledieu", uai: "0241125T" },
    { name: "Antoine de Saint-Exupéry", location: "Parentis-en-Born", uai: "0400046H" },
    { name: "Antoine Gapp", location: "Forbach", uai: "0572340J" },
    { name: "Antoine Roussin", location: "Saint-Louis", uai: "9740787M" },
    { name: "Antonin Artaud", location: "Marseille 13e", uai: "0132733A" },
    { name: "Aquiba", location: "Strasbourg", uai: "0671615S" },
    { name: "Arago", location: "Paris 12e", uai: "0750680G" },
    { name: "Aragon Picasso", location: "Givors", uai: "0693330V" },
    { name: "Arbez Carme", location: "Bellignat", uai: "0011119L" },
    { name: "Arcisse de Caumont", location: "Bayeux", uai: "0141274J" },
    { name: "Aristide Bergès", location: "Seyssinet-Pariset", uai: "0382780R" },
    { name: "Aristide Briand", location: "Gap", uai: "0050007F" },
    { name: "Aristide Briand", location: "Saint-Nazaire", uai: "0440069L" },
    { name: "Aristide Briand", location: "Évreux", uai: "0270016W" },
    { name: "Aristide Maillol", location: "Perpignan", uai: "0660809W" },
    { name: "Armand Peugeot", location: "Valentigney", uai: "0250058C" },
    { name: "Arnaut Daniel", location: "Ribérac", uai: "0240032E" },
    { name: "Arthur Rimbaud", location: "Istres", uai: "0132495S" },
    { name: "Arthur Rimbaud", location: "Sin-le-Noble", uai: "0595885B" },
    { name: "Arthur Varoquaux", location: "Tomblaine", uai: "0540044E" },
    { name: "Assomption", location: "Rennes", uai: "0350778F" },
    { name: "Assomption", location: "Bondy", uai: "0930933J" },
    { name: "Assomption Bellevue", location: "La Mulatière", uai: "0690539L" },
    { name: "Assomption Sainte-Clotilde", location: "Bordeaux", uai: "0331495W" },
    { name: "Atlantique", location: "Luçon", uai: "0850016F" },
    { name: "Auguste Angellier", location: "Dunkerque", uai: "0590073J" },
    { name: "Auguste Blanqui", location: "Saint-Ouen-sur-Seine", uai: "0930126G" },
    { name: "Auguste Béhal", location: "Lens", uai: "0620109G" },
    { name: "Auguste Chevalier", location: "Domfront en Poiraie", uai: "0610014J" },
    { name: "Auguste et Jean Renoir", location: "Angers", uai: "0492061Z" },
    { name: "Auguste et Louis Lumière", location: "La Ciotat", uai: "0131747D" },
    { name: "Auguste et Louis Lumière", location: "Lyon 8e", uai: "0690035N" },
    { name: "Auguste Loubatières", location: "Agde", uai: "0340002T" },
    { name: "Auguste Pavie", location: "Guingamp", uai: "0220018A" },
    { name: "Auguste Renoir", location: "Cagnes-sur-Mer", uai: "0060009C" },
    { name: "Auguste Renoir", location: "Limoges", uai: "0870017W" },
    { name: "Auguste Renoir", location: "Asnières-sur-Seine", uai: "0920131T" },
    { name: "Augustin Cournot", location: "Gray", uai: "0700009E" },
    { name: "Augustin Fresnel", location: "Caen", uai: "0140014P" },
    { name: "Augustin Fresnel", location: "Bernay", uai: "0270003G" },
    { name: "Augustin Thierry", location: "Blois", uai: "0410001D" },
    { name: "Aux Lazaristes", location: "Lyon 5e", uai: "0690522T" },
    { name: "Averroès", location: "Lille", uai: "0596681S" },
    { name: "Baggio", location: "Lille", uai: "0590121L" },
    { name: "Baimbridge", location: "Les Abymes", uai: "9710003B" },
    { name: "Balata", location: "Matoury", uai: "9730514W" },
    { name: "Balzac", location: "Tours", uai: "0370036N" },
    { name: "Balzac d'Alembert", location: "Issoudun", uai: "0360024F" },
    { name: "Barral", location: "Castres", uai: "0810071M" },
    { name: "Bartholdi", location: "Colmar", uai: "0680007N" },
    { name: "Baudelaire", location: "Annecy", uai: "0741418P" },
    { name: "Baudimont", location: "Arras", uai: "0622196A" },
    { name: "Beau Jardin", location: "Saint-Dié-des-Vosges", uai: "0880099E" },
    { name: "Beaulieu", location: "Cognac", uai: "0160065J" },
    { name: "Beaumont", location: "Redon", uai: "0350022J" },
    { name: "Beaupeyrat", location: "Limoges", uai: "0870081R" },
    { name: "Beaupré", location: "Haubourdin", uai: "0590093F" },
    { name: "Beauregard", location: "Montbrison", uai: "0420018S" },
    { name: "Beaussier", location: "La Seyne-sur-Mer", uai: "0830050D" },
    { name: "Beauséjour", location: "Narbonne", uai: "0110670U" },
    { name: "Bel Air", location: "Sainte-Suzanne", uai: "9741270M" },
    { name: "Bellepierre", location: "Saint-Denis", uai: "9741046U" },
    { name: "Bellevue", location: "Saintes", uai: "0170058W" },
    { name: "Bellevue", location: "Albi", uai: "0810005R" },
    { name: "Bellevue", location: "Alès", uai: "0300077W" },
    { name: "Bellevue", location: "Toulouse", uai: "0310038Y" },
    { name: "Bellevue", location: "Fort-de-France", uai: "9720003W" },
    { name: "Bellevue", location: "Le Mans", uai: "0720030S" },
    { name: "Belmont", location: "Lyon 7e", uai: "0690536H" },
    { name: "Benjamin Franklin", location: "Auray", uai: "0561534N" },
    { name: "Benjamin Franklin", location: "Orléans", uai: "0450051L" },
    { name: "Bernard Palissy", location: "Saint-Léonard-de-Noblat", uai: "0870045B" },
    { name: "Bernard Palissy", location: "Agen", uai: "0470001W" },
    { name: "Bernard Palissy", location: "Gien", uai: "0450029M" },
    { name: "Bernard Palissy", location: "Saintes", uai: "0170060Y" },
    { name: "Bernart de Ventadour", location: "Ussel", uai: "0190038N" },
    { name: "Bernat Etxepare", location: "Bayonne", uai: "0641907A" },
    { name: "Berthollet", location: "Annecy", uai: "0740003B" },
    { name: "Bertran de Born", location: "Périgueux", uai: "0240024W" },
    { name: "Bertrand d'Argentré", location: "Vitré", uai: "0350053T" },
    { name: "Bertène Juminer", location: "Saint-Laurent-du-Maroni", uai: "9730235T" },
    { name: "Beth Hanna", location: "Paris 19e", uai: "0754325U" },
    { name: "Bignon", location: "Mortagne-au-Perche", uai: "0610700E" },
    { name: "Blaise Cendrars", location: "Sevran", uai: "0932048W" },
    { name: "Blaise de Vigenère", location: "Saint-Pourçain-sur-Sioule", uai: "0030044G" },
    { name: "Blaise Pascal", location: "Orsay", uai: "0910626L" },
    { name: "Blaise Pascal", location: "Longuenesse", uai: "0622803K" },
    { name: "Blaise Pascal", location: "Saint-Dizier", uai: "0520028Y" },
    { name: "Blaise Pascal", location: "Rouen", uai: "0760095R" },
    { name: "Blaise Pascal", location: "Colmar", uai: "0680010S" },
    { name: "Blaise Pascal", location: "Ambert", uai: "0630001J" },
    { name: "Blaise Pascal", location: "Clermont-Ferrand", uai: "0630018C" },
    { name: "Blaise Pascal", location: "Brie-Comte-Robert", uai: "0772230F" },
    { name: "Blaise Pascal", location: "Segré-en-Anjou Bleu", uai: "0490782J" },
    { name: "Blaise Pascal", location: "Charbonnières-les-Bains", uai: "0693518Z" },
    { name: "Blanche de Castille", location: "Villemomble", uai: "0930974D" },
    { name: "Blanche de Castille", location: "Le Chesnay-Rocquencourt", uai: "0783282U" },
    { name: "Blanche de Castille", location: "Nantes", uai: "0440154D" },
    { name: "Blomet", location: "Paris 15e", uai: "0753915Y" },
    { name: "Boisjoly Potier", location: "Le Tampon", uai: "9741087N" },
    { name: "Boissy d'Anglas", location: "Annonay", uai: "0070001N" },
    { name: "Bonaparte", location: "Autun", uai: "0710001R" },
    { name: "Bonaparte", location: "Toulon", uai: "0831243A" },
    { name: "Bossuet", location: "Brive-la-Gaillarde", uai: "0190058K" },
    { name: "Bossuet", location: "Meaux", uai: "0771246L" },
    { name: "Bossuet", location: "Condom", uai: "0320009L" },
    { name: "Bossuet Notre-Dame", location: "Paris 10e", uai: "0753887T" },
    { name: "Boucher de Perthes", location: "Abbeville", uai: "0800001S" },
    { name: "Bourg Chevreau Sainte-Anne", location: "Segré-en-Anjou Bleu", uai: "0490840X" },
    { name: "Boutet de Monvel", location: "Lunéville", uai: "0542293Z" },
    { name: "Bristol", location: "Cannes", uai: "0060013G" },
    { name: "Brizeux", location: "Quimper", uai: "0290069T" },
    { name: "Brocéliande", location: "Guer", uai: "0560016N" },
    { name: "Bréquigny", location: "Rennes", uai: "0350028R" },
    { name: "Buffon", location: "Paris 15e", uai: "0750693W" },
    { name: "Camille Claudel", location: "Palaiseau", uai: "0911938M" },
    { name: "Camille Claudel", location: "Blois", uai: "0410959V" },
    { name: "Camille Claudel", location: "Blain", uai: "0442207K" },
    { name: "Camille Claudel", location: "Fourmies", uai: "0590083V" },
    { name: "Camille Claudel", location: "Troyes", uai: "0101028N" },
    { name: "Camille Claudel", location: "Digoin", uai: "0711137A" },
    { name: "Camille Claudel", location: "Vauréal", uai: "0951710T" },
    { name: "Camille Claudel", location: "Pontault-Combault", uai: "0772243V" },
    { name: "Camille Corot", location: "Morestel", uai: "0383069E" },
    { name: "Camille Desmoulins", location: "Le Cateau-Cambrésis", uai: "0590042A" },
    { name: "Camille Guérin", location: "Poitiers", uai: "0860035W" },
    { name: "Camille Jullian", location: "Bordeaux", uai: "0330023W" },
    { name: "Camille Pissarro", location: "Pontoise", uai: "0950649P" },
    { name: "Camille Saint-Saëns", location: "Rouen", uai: "0760093N" },
    { name: "Camille Saint-Saëns", location: "Deuil-la-Barre", uai: "0951922Y" },
    { name: "Camille Sée", location: "Colmar", uai: "0680008P" },
    { name: "Camille Sée", location: "Paris 15e", uai: "0750694X" },
    { name: "Camille Vernet", location: "Valence", uai: "0260035X" },
    { name: "Camus Sermenaz", location: "Rillieux-la-Pape", uai: "0692517L" },
    { name: "Cantau", location: "Anglet", uai: "0640001D" },
    { name: "Carcouet", location: "Nantes", uai: "0441993C" },
    { name: "Carnot", location: "Dijon", uai: "0210015C" },
    { name: "Carnot", location: "Paris 17e", uai: "0750704H" },
    { name: "Carnot", location: "Bruay-la-Buissière", uai: "0620056Z" },
    { name: "Carnot", location: "Cannes", uai: "0060011E" },
    { name: "Caroline Aigle", location: "Nort-sur-Erdre", uai: "0442899M" },
    { name: "Cassini", location: "Clermont", uai: "0600013N" },
    { name: "Catherine et Raymond Janot", location: "Sens", uai: "0891200W" },
    { name: "Catherine Labouré", location: "Paris 14e", uai: "0754045P" },
    { name: "CENS", location: "La Chapelle-sur-Erdre", uai: "0442083A" },
    { name: "Chabrillan Saint-Jean-Baptiste", location: "Montélimar", uai: "0260064D" },
    { name: "Champagnat", location: "Saint-Symphorien-sur-Coise", uai: "0691654Y" },
    { name: "Champagnat", location: "Issenheim", uai: "0682072H" },
    { name: "Champlain", location: "Chennevières-sur-Marne", uai: "0941470M" },
    { name: "Champollion", location: "Grenoble", uai: "0380027Y" },
    { name: "Champollion", location: "Figeac", uai: "0460010L" },
    { name: "Champollion", location: "Lattes", uai: "0341794R" },
    { name: "Chanzy", location: "Charleville-Mézières", uai: "0080006N" },
    { name: "Chaptal", location: "Paris 8e", uai: "0750663N" },
    { name: "Chaptal", location: "Mende", uai: "0480007X" },
    { name: "Charlemagne", location: "Paris 4e", uai: "0750652B" },
    { name: "Charlemagne", location: "Thionville", uai: "0570106F" },
    { name: "Charles Augustin Coulomb", location: "Angoulême", uai: "0160004T" },
    { name: "Charles Baudelaire", location: "Roubaix", uai: "0590182C" },
    { name: "Charles Baudelaire", location: "Fosses", uai: "0951727L" },
    { name: "Charles Coeffin", location: "Baie-Mahault", uai: "9711032V" },
    { name: "Charles de Foucauld", location: "Lyon 3e", uai: "0690514J" },
    { name: "Charles de Foucauld", location: "Brest", uai: "0290336H" },
    { name: "Charles de Foucauld", location: "Paris 18e", uai: "0754086J" },
    { name: "Charles de Gaulle", location: "Chaumont", uai: "0521032P" },
    { name: "Charles de Gaulle", location: "Muret", uai: "0312822Z" },
    { name: "Charles de Gaulle", location: "Poissy", uai: "0781898P" },
    { name: "Charles de Gaulle", location: "Dijon", uai: "0211928G" },
    { name: "Charles de Gaulle", location: "Caen", uai: "0142107P" },
    { name: "Charles de Gaulle", location: "Vannes", uai: "0561627P" },
    { name: "Charles de Gaulle", location: "Apt", uai: "0840001V" },
    { name: "Charles de Gaulle", location: "Compiègne", uai: "0601863Z" },
    { name: "Charles de Gaulle", location: "Rosny-sous-Bois", uai: "0932031C" },
    { name: "Charles de Gaulle", location: "Longperrier", uai: "0772228D" },
    { name: "Charles Despiau", location: "Mont-de-Marsan", uai: "0400018C" },
    { name: "Charles et Adrien Dupuy", location: "Le Puy-en-Velay", uai: "0430020N" },
    { name: "Charles Gide", location: "Uzès", uai: "0300047N" },
    { name: "Charles Hermite", location: "Dieuze", uai: "0570021N" },
    { name: "Charles Jully", location: "Saint-Avold", uai: "0570087K" },
    { name: "Charles le Chauve", location: "Roissy-en-Brie", uai: "0771763Y" },
    { name: "Charles Mangin", location: "Sarrebourg", uai: "0570094T" },
    { name: "Charles Nodier", location: "Dole", uai: "0390012B" },
    { name: "Charles Poncet", location: "Cluses", uai: "0740017S" },
    { name: "Charles Péguy", location: "Paris 11e", uai: "0753890W" },
    { name: "Charles Péguy", location: "Orléans", uai: "0451526P" },
    { name: "Charles Péguy", location: "Bobigny", uai: "0930962R" },
    { name: "Charles Péguy", location: "Gorges", uai: "0442273G" },
    { name: "Charles Renouvier", location: "Prades", uai: "0660021P" },
    { name: "Charles Tellier", location: "Condé-en-Normandie", uai: "0142120D" },
    { name: "Charles-François Lebrun", location: "Coutances", uai: "0500026G" },
    { name: "Charlie Chaplin", location: "Décines-Charpieu", uai: "0692800U" },
    { name: "Charlotte Delbo", location: "Dammartin-en-Goële", uai: "0772751X" },
    { name: "Charlotte Perriand", location: "Genech", uai: "0596925G" },
    { name: "Chateaubriand", location: "Rennes", uai: "0350710G" },
    { name: "Chevalier d'Éon", location: "Tonnerre", uai: "0890032B" },
    { name: "Chevalier de Saint-Georges", location: "Les Abymes", uai: "9711046K" },
    { name: "Chevreul Blancarde", location: "Marseille 4e", uai: "0131335F" },
    { name: "Chevreul Lestonnac", location: "Lyon 7e", uai: "0690574Z" },
    { name: "Chevrollier", location: "Angers", uai: "0490003M" },
    { name: "Choiseul", location: "Tours", uai: "0370037P" },
    { name: "Chrestien de Troyes", location: "Troyes", uai: "0100022V" },
    { name: "Christian Bourquin", location: "Argelès-sur-Mer", uai: "0660924W" },
    { name: "Christophe Colomb", location: "Sucy-en-Brie", uai: "0941918Z" },
    { name: "Claude Bernard", location: "Villefranche-sur-Saône", uai: "0690097F" },
    { name: "Claude Bernard", location: "Paris 16e", uai: "0750698B" },
    { name: "Claude de France", location: "Romorantin-Lanthenay", uai: "0410017W" },
    { name: "Claude et Pierre Virlogeux", location: "Riom", uai: "0630052P" },
    { name: "Claude Fauriel", location: "Saint-Étienne", uai: "0420041S" },
    { name: "Claude Gellée", location: "Épinal", uai: "0880020U" },
    { name: "Claude Lebois", location: "Saint-Chamond", uai: "0420040R" },
    { name: "Claude Monet", location: "Paris 13e", uai: "0750683K" },
    { name: "Claude Monet", location: "Le Havre", uai: "0760174B" },
    { name: "Claude Nougaro", location: "Monteils", uai: "0820883P" },
    { name: "Claude-Nicolas Ledoux", location: "Besançon", uai: "0251711Z" },
    { name: "Claude-Nicolas Ledoux", location: "Les Pavillons-sous-Bois", uai: "0930136T" },
    { name: "Claude-Nicolas Ledoux", location: "Vincennes", uai: "0940822H" },
    { name: "Clemenceau", location: "Nantes", uai: "0440021J" },
    { name: "Clemenceau", location: "Villemomble", uai: "0930127H" },
    { name: "Clemenceau", location: "Reims", uai: "0510031G" },
    { name: "Clos Maire", location: "Beaune", uai: "0210006T" },
    { name: "Clémence Royer", location: "Fonsorbes", uai: "0312754A" },
    { name: "Clément Ader", location: "Athis-Mons", uai: "0910676R" },
    { name: "Clément Ader", location: "Tournan-en-Brie", uai: "0772342C" },
    { name: "Clément Marot", location: "Cahors", uai: "0460007H" },
    { name: "Colbert", location: "Tourcoing", uai: "0590214M" },
    { name: "Colbert", location: "Lyon 8e", uai: "0690042W" },
    { name: "Colbert", location: "Reims", uai: "0511901P" },
    { name: "Colbert", location: "Paris 10e", uai: "0750673Z" },
    { name: "Comte de Foix", location: "Andorre-la-Vieille", uai: "1300023U" },
    { name: "Condorcet", location: "Paris 9e", uai: "0750667T" },
    { name: "Condorcet", location: "Saint-Maur-des-Fossés", uai: "0940122X" },
    { name: "Condorcet", location: "Schœneck", uai: "0573231C" },
    { name: "Condorcet", location: "Belfort", uai: "0900002N" },
    { name: "Condorcet", location: "Lens", uai: "0620108F" },
    { name: "Condorcet", location: "Saint-Priest", uai: "0693478F" },
    { name: "Condorcet", location: "Saint-Quentin", uai: "0020050U" },
    { name: "Condorcet", location: "Méru", uai: "0601865B" },
    { name: "Condorcet", location: "Limay", uai: "0781884Z" },
    { name: "Condorcet", location: "Montreuil", uai: "0930122C" },
    { name: "Cordouan", location: "Royan", uai: "0170042D" },
    { name: "Costebelle", location: "Hyères", uai: "0831563Y" },
    { name: "Cours Bastide", location: "Marseille 6e", uai: "0131344R" },
    { name: "Cours Notre-Dame", location: "Douvres-la-Délivrande", uai: "0141165R" },
    { name: "Cours Secondaire", location: "Orsay", uai: "0910819W" },
    { name: "Curie Corot", location: "Saint-Lô", uai: "0501219D" },
    { name: "Darius Milhaud", location: "Le Kremlin-Bicêtre", uai: "0941474S" },
    { name: "David d'Angers", location: "Angers", uai: "0490001K" },
    { name: "de l'Escaut", location: "Valenciennes", uai: "0595809U" },
    { name: "Delamare-Deboutteville", location: "Forges-les-Eaux", uai: "0762600N" },
    { name: "Demotz de la Salle", location: "Rumilly", uai: "0741469V" },
    { name: "Denis Diderot", location: "Marseille 13e", uai: "0130050J" },
    { name: "Descartes", location: "Tours", uai: "0370035M" },
    { name: "Descartes", location: "Montigny-le-Bretonneux", uai: "0781512V" },
    { name: "Descartes", location: "Antony", uai: "0920130S" },
    { name: "Dhuoda", location: "Nîmes", uai: "0300026R" },
    { name: "Diderot", location: "Langres", uai: "0520021R" },
    { name: "Diderot", location: "Carvin", uai: "0620070P" },
    { name: "Diderot", location: "Paris 19e", uai: "0750712S" },
    { name: "Diwan", location: "Carhaix-Plouguer", uai: "0292137R" },
    { name: "Docteur Charles Mérieux", location: "Lyon 7e", uai: "0694405N" },
    { name: "Docteur Koeberlé", location: "Sélestat", uai: "0670071N" },
    { name: "Docteur Lacroix", location: "Narbonne", uai: "0110022P" },
    { name: "Dom Sortais", location: "Beaupréau-en-Mauges", uai: "0490828J" },
    { name: "Dominique Villars", location: "Gap", uai: "0050006E" },
    { name: "Don Bosco", location: "Landser", uai: "0680134B" },
    { name: "Don Bosco", location: "Nice", uai: "0060773H" },
    { name: "Don Bosco", location: "Mayenne", uai: "0530052U" },
    { name: "Dorian", location: "Paris 11e", uai: "0750676C" },
    { name: "Douanier Rousseau", location: "Laval", uai: "0530011Z" },
    { name: "Duhamel du Monceau", location: "Pithiviers", uai: "0450062Y" },
    { name: "Dumont d'Urville", location: "Toulon", uai: "0830053G" },
    { name: "Dumont d'Urville", location: "Caen", uai: "0142133T" },
    { name: "Dumont d'Urville", location: "Maurepas", uai: "0781883Y" },
    { name: "Dupleix", location: "Landrecies", uai: "0590112B" },
    { name: "Duplessis Mornay", location: "Saumur", uai: "0490040C" },
    { name: "Dupuy de Lôme", location: "Lorient", uai: "0560025Y" },
    { name: "Durzy", location: "Villemandeur", uai: "0450042B" },
    { name: "Déborde", location: "Lyon 6e", uai: "0690529A" },
    { name: "Déodat de Séverac", location: "Céret", uai: "0660004W" },
    { name: "Déodat de Séverac", location: "Toulouse", uai: "0310044E" },
    { name: "Désiré Nisard", location: "Châtillon-sur-Seine", uai: "0210013A" },
    { name: "Ecole alsacienne", location: "Paris 6e", uai: "0753647G" },
    { name: "Ecole des Roches", location: "Verneuil-sur-Avre", uai: "0271051W" },
    { name: "Ecole internationale PACA", location: "Manosque", uai: "0040587S" },
    { name: "Ecole Juive de Lyon", location: "Villeurbanne", uai: "0693769X" },
    { name: "Edgar Faure", location: "Morteau", uai: "0251671F" },
    { name: "Edgar Morin", location: "Douai", uai: "0596892W" },
    { name: "Edgar Poe", location: "Paris 10e", uai: "0753883N" },
    { name: "Edgar Quinet", location: "Bourg-en-Bresse", uai: "0010014K" },
    { name: "Edgar Quinet", location: "Paris 9e", uai: "0750671X" },
    { name: "Edmond Labbé", location: "Douai", uai: "0590065A" },
    { name: "Edmond Michelet", location: "Arpajon", uai: "0911961M" },
    { name: "Edmond Perrier", location: "Tulle", uai: "0190032G" },
    { name: "Edmond Rostand", location: "Bagnères-de-Luchon", uai: "0311334G" },
    { name: "Edmond Rostand", location: "Saint-Ouen-l'Aumône", uai: "0951728M" },
    { name: "Edmé Bouchardon", location: "Chaumont", uai: "0520844K" },
    { name: "Edouard Belin", location: "Vesoul", uai: "0700905D" },
    { name: "Edouard Branly", location: "Nogent-sur-Marne", uai: "0940117S" },
    { name: "Edouard Branly", location: "Boulogne-sur-Mer", uai: "0620052V" },
    { name: "Edouard Branly", location: "Lyon 5e", uai: "0690128P" },
    { name: "Edouard Branly", location: "Châtellerault", uai: "0860005N" },
    { name: "Edouard Branly", location: "Amiens", uai: "0801327H" },
    { name: "Edouard Branly", location: "Dreux", uai: "0280021W" },
    { name: "Edouard Herriot", location: "Lyon 6e", uai: "0690027E" },
    { name: "Edouard Herriot", location: "Voiron", uai: "0380091T" },
    { name: "Edouard Herriot", location: "Sainte-Savine", uai: "0101016A" },
    { name: "Edouard Schuré", location: "Barr", uai: "0670002N" },
    { name: "Edouard Vaillant", location: "Vierzon", uai: "0180035R" },
    { name: "EIB Etoile", location: "Paris 17e", uai: "0752937K" },
    { name: "EIC", location: "Tourcoing", uai: "0592965C" },
    { name: "Elie Cartan", location: "La Tour-du-Pin", uai: "0380073Y" },
    { name: "Elie Faure", location: "Lormont", uai: "0332744D" },
    { name: "Elisa Lemonnier", location: "Paris 12e", uai: "0750677D" },
    { name: "Ella Fitzgerald", location: "Vienne", uai: "0380081G" },
    { name: "Elorn", location: "Landerneau", uai: "0290044R" },
    { name: "Emiland Gauthey", location: "Chalon-sur-Saône", uai: "0711729U" },
    { name: "Emile Littré", location: "Avranches", uai: "0500002F" },
    { name: "Emile Loubet", location: "Valence", uai: "0260034W" },
    { name: "Emile Peytavin", location: "Mende", uai: "0480009Z" },
    { name: "Emile Zola", location: "Rennes", uai: "0350024L" },
    { name: "Emile Zola", location: "Aix-en-Provence", uai: "0130001F" },
    { name: "Emile Zola", location: "Châteaudun", uai: "0280015P" },
    { name: "Emile Zola", location: "Wattrelos", uai: "0590233H" },
    { name: "Emilie de Breteuil", location: "Montigny-le-Bretonneux", uai: "0781819D" },
    { name: "Emilie de Rodat", location: "Toulouse", uai: "0311134P" },
    { name: "Emilie du Châtelet", location: "Serris", uai: "0772688D" },
    { name: "Emily Brontë", location: "Lognes", uai: "0772294A" },
    { name: "Emmanuel Chabrier", location: "Yssingeaux", uai: "0430953C" },
    { name: "Emmanuel d'Alzon", location: "Nîmes", uai: "0300080Z" },
    { name: "Emmanuel Mounier", location: "Angers", uai: "0492089E" },
    { name: "Emmanuel Mounier", location: "Châtenay-Malabry", uai: "0920135X" },
    { name: "Emmanuel Mounier", location: "Grenoble", uai: "0380032D" },
    { name: "Epin", location: "Vitry-sur-Seine", uai: "0941719H" },
    { name: "Erckmann Chatrian", location: "Phalsbourg", uai: "0570081D" },
    { name: "Ernest Bichat", location: "Lunéville", uai: "0540034U" },
    { name: "Ernest Couteaux", location: "Saint-Amand-les-Eaux", uai: "0590192N" },
    { name: "Ernest Ferroul", location: "Lézignan-Corbières", uai: "0111048E" },
    { name: "Ernest Hemingway", location: "Nîmes", uai: "0300027S" },
    { name: "Ernest Pérochon", location: "Parthenay", uai: "0790029C" },
    { name: "Ernest Renan", location: "Saint-Brieuc", uai: "0220057T" },
    { name: "Estic", location: "Saint-Dizier", uai: "0520679F" },
    { name: "Etienne Bézout", location: "Nemours", uai: "0770940D" },
    { name: "Etienne Mimard", location: "Saint-Étienne", uai: "0420046X" },
    { name: "Etienne Œhmichen", location: "Châlons-en-Champagne", uai: "0510007F" },
    { name: "Eugène Delacroix", location: "Maisons-Alfort", uai: "0940116R" },
    { name: "Eugène Delacroix", location: "Drancy", uai: "0930119Z" },
    { name: "Eugène Freyssinet", location: "Saint-Brieuc", uai: "0220060W" },
    { name: "Eugène Henaff", location: "Bagnolet", uai: "0932119Y" },
    { name: "Eugène Ionesco", location: "Issy-les-Moulineaux", uai: "0922397F" },
    { name: "Eugène Jamot", location: "Aubusson", uai: "0230002C" },
    { name: "Eugène Ronceray", location: "Bezons", uai: "0952173W" },
    { name: "Eugène Thomas", location: "Le Quesnoy", uai: "0590168M" },
    { name: "Eugène Woillez", location: "Montreuil-sur-Mer", uai: "0620140R" },
    { name: "Europe Robert Schuman", location: "Cholet", uai: "0490018D" },
    { name: "Evariste Galois", location: "Sartrouville", uai: "0782924E" },
    { name: "Evariste Galois", location: "Noisy-le-Grand", uai: "0932047V" },
    { name: "Evariste Galois", location: "Beaumont-sur-Oise", uai: "0951748J" },
    { name: "Externat Chavagnes", location: "Nantes", uai: "0442699V" },
    { name: "Externat Notre-Dame", location: "Grenoble", uai: "0381803D" },
    { name: "Externat Saint-Joseph de Cluny", location: "Cayenne", uai: "9730010Y" },
    { name: "Externat Saint-Joseph La Cordeille", location: "Ollioules", uai: "0830098F" },
    { name: "Fabert", location: "Metz", uai: "0570054Z" },
    { name: "Faidherbe", location: "Lille", uai: "0590119J" },
    { name: "Faustin Fléret", location: "Morne-à-l'Eau", uai: "9710774P" },
    { name: "Ferdinand Buisson", location: "Voiron", uai: "0380092U" },
    { name: "Ferdinand Buisson", location: "Elbeuf", uai: "0760030V" },
    { name: "Ferdinand Fabre", location: "Bédarieux", uai: "0340005W" },
    { name: "Ferdinand Foch", location: "Rodez", uai: "0120022J" },
    { name: "Fernand Daguin", location: "Mérignac", uai: "0331760J" },
    { name: "Fernand Darchicourt", location: "Hénin-Beaumont", uai: "0620093P" },
    { name: "Fernand et Nadia Léger", location: "Argenteuil", uai: "0951811C" },
    { name: "Fernand Renaudeau", location: "Cholet", uai: "0490054T" },
    { name: "Fesch", location: "Ajaccio", uai: "6200001G" },
    { name: "Flora Tristan", location: "Noisy-le-Grand", uai: "0931565W" },
    { name: "Fragonard", location: "L'Isle-Adam", uai: "0951147F" },
    { name: "Frantz Fanon", location: "La Trinité", uai: "9720350Y" },
    { name: "François 1er", location: "Fontainebleau", uai: "0770927P" },
    { name: "François 1er", location: "Le Havre", uai: "0760052U" },
    { name: "François 1er", location: "Vitry-le-François", uai: "0510062R" },
    { name: "François Arago", location: "Perpignan", uai: "0660010C" },
    { name: "François Arago", location: "Reims", uai: "0511565Z" },
    { name: "François Arago", location: "Villeneuve-Saint-Georges", uai: "0941952L" },
    { name: "François Bazin", location: "Charleville-Mézières", uai: "0080008R" },
    { name: "François Couperin", location: "Fontainebleau", uai: "0770926N" },
    { name: "François d'Estaing", location: "Rodez", uai: "0121438Y" },
    { name: "François Magendie", location: "Bordeaux", uai: "0330026Z" },
    { name: "François Mansart", location: "Thizy-les-Bourgs", uai: "0693566B" },
    { name: "François Mansart", location: "Saint-Maur-des-Fossés", uai: "0940585A" },
    { name: "François Mauriac", location: "Bordeaux", uai: "0330027A" },
    { name: "François Mauriac Forez", location: "Andrézieux-Bouthéon", uai: "0421976V" },
    { name: "François Mitterrand", location: "Moissac", uai: "0820016X" },
    { name: "François Rabelais", location: "Fontenay-le-Comte", uai: "0850068M" },
    { name: "François Rabelais", location: "Chinon", uai: "0370009J" },
    { name: "François Rabelais", location: "Paris 18e", uai: "0750688R" },
    { name: "François Truffaut", location: "Challans", uai: "0851346B" },
    { name: "François Truffaut", location: "Beauvais", uai: "0601823F" },
    { name: "François Truffaut", location: "Bondoufle", uai: "0911937L" },
    { name: "François Villon", location: "Beaugency", uai: "0451484U" },
    { name: "François Villon", location: "Les Mureaux", uai: "0780422K" },
    { name: "François Villon", location: "Paris 14e", uai: "0750690T" },
    { name: "François-Jean Armorin", location: "Crest", uai: "0260006R" },
    { name: "François-Joseph Talma", location: "Brunoy", uai: "0911021R" },
    { name: "François-René de Chateaubriand", location: "Combourg", uai: "0352533N" },
    { name: "Françoise Cabrini", location: "Noisy-le-Grand", uai: "0932036H" },
    { name: "Françoise Combes", location: "Montpellier", uai: "0342266D" },
    { name: "Freppel", location: "Obernai", uai: "0670049P" },
    { name: "Fréderic Mistral", location: "Fresnes", uai: "0941301D" },
    { name: "Frédéric Bazille", location: "Montpellier", uai: "0341915X" },
    { name: "Frédéric Chopin", location: "Nancy", uai: "0540040A" },
    { name: "Frédéric Faÿs", location: "Villeurbanne", uai: "0690103M" },
    { name: "Frédéric Joliot-Curie", location: "Aubagne", uai: "0131549N" },
    { name: "Frédéric Joliot-Curie", location: "Dammarie-les-Lys", uai: "0771027Y" },
    { name: "Frédéric Kirschleger", location: "Munster", uai: "0680051L" },
    { name: "Frédéric Mistral", location: "Avignon", uai: "0840003X" },
    { name: "Frédéric Ozanam", location: "Mâcon", uai: "0711249X" },
    { name: "Frédéric Ozanam", location: "Châlons-en-Champagne", uai: "0511147V" },
    { name: "Frédéric Ozanam", location: "Cesson-Sévigné", uai: "0350793X" },
    { name: "Frémont", location: "Lisieux", uai: "0141169V" },
    { name: "Fulbert", location: "Chartres", uai: "0281047L" },
    { name: "Fulgence Bienvenüe", location: "Loudéac", uai: "0220027K" },
    { name: "Fustel de Coulanges", location: "Strasbourg", uai: "0670079X" },
    { name: "Fustel de Coulanges", location: "Massy", uai: "0910687C" },
    { name: "Félix Eboué", location: "Cayenne", uai: "9730001N" },
    { name: "Félix Esclangon", location: "Manosque", uai: "0040010P" },
    { name: "Félix Faure", location: "Beauvais", uai: "0600001A" },
    { name: "Félix Le Dantec", location: "Lannion", uai: "0220023F" },
    { name: "Félix Mayer", location: "Creutzwald", uai: "0572022N" },
    { name: "Félix Proto", location: "Les Abymes", uai: "9710921Z" },
    { name: "Fénelon", location: "Paris 6e", uai: "0750660K" },
    { name: "Fénelon", location: "Clermont-Ferrand", uai: "0631074A" },
    { name: "Fénelon", location: "Toulon", uai: "0830099G" },
    { name: "Fénelon", location: "Grasse", uai: "0060674A" },
    { name: "Fénelon", location: "Elbeuf", uai: "0761708U" },
    { name: "Fénelon", location: "Lille", uai: "0590116F" },
    { name: "Fénelon", location: "Cambrai", uai: "0590035T" },
    { name: "Fénelon", location: "Vaujours", uai: "0931799A" },
    { name: "Fénelon Notre-Dame", location: "La Rochelle", uai: "0170100S" },
    { name: "Fénelon Sainte-Marie", location: "Paris 8e", uai: "0753873C" },
    { name: "Gabriel Deshayes", location: "Saint-Gildas-des-Bois", uai: "0440175B" },
    { name: "Gabriel Faure", location: "Tournon-sur-Rhône", uai: "0070029U" },
    { name: "Gabriel Fauré", location: "Foix", uai: "0090002D" },
    { name: "Gabriel Fauré", location: "Annecy", uai: "0740005D" },
    { name: "Gabriel Fauré", location: "Paris 13e", uai: "0750684L" },
    { name: "Gabriel Guist'hau", location: "Nantes", uai: "0440024M" },
    { name: "Gabriel Touchard Washington", location: "Le Mans", uai: "0720033V" },
    { name: "Gabriel Voisin", location: "Tournus", uai: "0710071S" },
    { name: "Galilée", location: "Vienne", uai: "0380083J" },
    { name: "Galilée", location: "Franqueville-Saint-Pierre", uai: "0762911B" },
    { name: "Galilée", location: "Combs-la-Ville", uai: "0772127U" },
    { name: "Galilée", location: "Gennevilliers", uai: "0921156G" },
    { name: "Galilée", location: "Cergy", uai: "0951637N" },
    { name: "Galilée", location: "Guérande", uai: "0442112G" },
    { name: "Gambetta", location: "Tourcoing", uai: "0590212K" },
    { name: "Gambetta Carnot", location: "Arras", uai: "0624430D" },
    { name: "Gaspard Monge", location: "Savigny-sur-Orge", uai: "0912142J" },
    { name: "Gaspard Monge La Chauvinière", location: "Nantes", uai: "0440030U" },
    { name: "Gaston Bachelard", location: "Bar-sur-Aube", uai: "0100003Z" },
    { name: "Gaston Bachelard", location: "Chelles", uai: "0770922J" },
    { name: "Gaston Crampe", location: "Aire-sur-l'Adour", uai: "0400002K" },
    { name: "Gaston Fébus", location: "Orthez", uai: "0640052J" },
    { name: "Gaston Monnerville", location: "Cahors", uai: "0460493L" },
    { name: "Gaston Monnerville", location: "Kourou", uai: "9730108E" },
    { name: "Gay-Lussac", location: "Limoges", uai: "0870015U" },
    { name: "Gay-Lussac", location: "Chauny", uai: "0020014E" },
    { name: "Geneviève de Gaulle-Anthonioz", location: "Milhaud", uai: "0301654K" },
    { name: "Geoffroy Saint-Hilaire", location: "Étampes", uai: "0910622G" },
    { name: "George Sand", location: "La Châtre", uai: "0360019A" },
    { name: "George Sand", location: "Nérac", uai: "0470028A" },
    { name: "George Sand", location: "Domont", uai: "0951788C" },
    { name: "George Sand", location: "Le Mée-sur-Seine", uai: "0771663P" },
    { name: "Georges Baumont", location: "Saint-Dié-des-Vosges", uai: "0880152M" },
    { name: "Georges Braque", location: "Argenteuil", uai: "0950666H" },
    { name: "Georges Brassens", location: "Rive-de-Gier", uai: "0420027B" },
    { name: "Georges Brassens", location: "Saint-Denis", uai: "9740053P" },
    { name: "Georges Brassens", location: "Neufchâtel-en-Bray", uai: "0762601P" },
    { name: "Georges Brassens", location: "Villeneuve-le-Roi", uai: "0940743X" },
    { name: "Georges Brassens", location: "Évry-Courcouronnes", uai: "0911828T" },
    { name: "Georges Cabanis", location: "Brive-la-Gaillarde", uai: "0190010H" },
    { name: "Georges Clemenceau", location: "Sartène", uai: "6200043C" },
    { name: "Georges Clemenceau", location: "Montpellier", uai: "0340039H" },
    { name: "Georges Clemenceau", location: "Chantonnay", uai: "0850006V" },
    { name: "Georges Colomb", location: "Lure", uai: "0700018P" },
    { name: "Georges Cuvier", location: "Montbéliard", uai: "0250030X" },
    { name: "Georges de la Tour", location: "Metz", uai: "0572757M" },
    { name: "Georges de la Tour", location: "Nancy", uai: "0540041B" },
    { name: "Georges Duby", location: "Aix-en-Provence", uai: "0133525L" },
    { name: "Georges Dumézil", location: "Vernon", uai: "0270044B" },
    { name: "Georges Imbert", location: "Sarre-Union", uai: "0672614C" },
    { name: "Georges Leven", location: "Paris 12e", uai: "0753935V" },
    { name: "Georges Pompidou", location: "Castelnau-le-Lez", uai: "0341921D" },
    { name: "Germaine Tillion", location: "Castelnaudary", uai: "0110012D" },
    { name: "Germaine Tillion", location: "Sain-Bel", uai: "0694069Y" },
    { name: "Germaine Tillion", location: "Montbéliard", uai: "0251994G" },
    { name: "Germaine Tillion", location: "Le Bourget", uai: "0932577W" },
    { name: "Gerson", location: "Paris 16e", uai: "0753941B" },
    { name: "Gerville Réache", location: "Basse-Terre", uai: "9710002A" },
    { name: "Giocante de Casabianca", location: "Bastia", uai: "7200009X" },
    { name: "Giraux Sannier", location: "Saint-Martin-Boulogne", uai: "0624109E" },
    { name: "Gisèle Halimi", location: "Bazas", uai: "0330010G" },
    { name: "Grand Air", location: "La Baule-Escoublac", uai: "0440012Z" },
    { name: "Grand Air", location: "Arcachon", uai: "0330003Z" },
    { name: "Grandmont", location: "Tours", uai: "0370038R" },
    { name: "Gregor Mendel", location: "Vincennes", uai: "0940821G" },
    { name: "Guez de Balzac", location: "Angoulême", uai: "0160002R" },
    { name: "Guillaume Apollinaire", location: "Thiais", uai: "0940123Y" },
    { name: "Guillaume Apollinaire", location: "Nice", uai: "0061763J" },
    { name: "Guillaume Budé", location: "Limeil-Brévannes", uai: "0940742W" },
    { name: "Guillaume Fichet", location: "Bonneville", uai: "0740013M" },
    { name: "Guillaume le Conquérant", location: "Falaise", uai: "0140052F" },
    { name: "Guillaume Le Conquérant", location: "Lillebonne", uai: "0760072R" },
    { name: "Gustave Courbet", location: "Belfort", uai: "0900003P" },
    { name: "Gustave Eiffel", location: "Armentières", uai: "0590011S" },
    { name: "Gustave Eiffel", location: "Bordeaux", uai: "0330028B" },
    { name: "Gustave Eiffel", location: "Rueil-Malmaison", uai: "0922398G" },
    { name: "Gustave Eiffel", location: "Dijon", uai: "0211033J" },
    { name: "Gustave Eiffel", location: "Gagny", uai: "0931272C" },
    { name: "Gustave Eiffel", location: "Ouangani", uai: "9760125G" },
    { name: "Gustave Flaubert", location: "Rouen", uai: "0760096S" },
    { name: "Gustave Jaume", location: "Pierrelatte", uai: "0260019E" },
    { name: "Gustave Monod", location: "Enghien-les-Bains", uai: "0952196W" },
    { name: "Guy Chauvet", location: "Loudun", uai: "0860021F" },
    { name: "Guy de Maupassant", location: "Fécamp", uai: "0760035A" },
    { name: "Guy de Maupassant", location: "Colombes", uai: "0920137Z" },
    { name: "Guy Gasnier Sainte-Bathilde", location: "Chelles", uai: "0771720B" },
    { name: "Guy Mollet", location: "Arras", uai: "0620256S" },
    { name: "Guy Môquet Etienne Lenoir", location: "Châteaubriant", uai: "0440005S" },
    { name: "Général Leclerc", location: "Saverne", uai: "0670057Y" },
    { name: "Gérard de Nerval", location: "Luzarches", uai: "0950647M" },
    { name: "Gérard de Nerval", location: "Soissons", uai: "0020059D" },
    { name: "Haroun Tazieff", location: "Saint-Paul-lès-Dax", uai: "0401002X" },
    { name: "Harteloire", location: "Brest", uai: "0290010D" },
    { name: "Haut-Barr", location: "Saverne", uai: "0672534R" },
    { name: "Hector Berlioz", location: "Vincennes", uai: "0940124Z" },
    { name: "Hector Berlioz", location: "La Côte-Saint-André", uai: "0380014J" },
    { name: "Heinrich Nessel", location: "Haguenau", uai: "0671509B" },
    { name: "Henri Avril", location: "Lamballe-Armor", uai: "0220196U" },
    { name: "Henri Becquerel", location: "Nangis", uai: "0772277G" },
    { name: "Henri Bergson", location: "Angers", uai: "0491966W" },
    { name: "Henri Bergson", location: "Paris 19e", uai: "0750711R" },
    { name: "Henri Cornat", location: "Valognes", uai: "0500082T" },
    { name: "Henri Darras", location: "Liévin", uai: "0620113L" },
    { name: "Henri de Toulouse-Lautrec", location: "Toulouse", uai: "0311586F" },
    { name: "Henri IV", location: "Paris 5e", uai: "0750654D" },
    { name: "Henri IV", location: "Béziers", uai: "0340009A" },
    { name: "Henri Laurens", location: "Saint-Vallier", uai: "0261397C" },
    { name: "Henri Loritz", location: "Nancy", uai: "0540042C" },
    { name: "Henri Martin", location: "Saint-Quentin", uai: "0020048S" },
    { name: "Henri Matisse", location: "Cugnaux", uai: "0312290W" },
    { name: "Henri Matisse", location: "Vence", uai: "0061884R" },
    { name: "Henri Matisse", location: "Montreuil", uai: "0930965U" },
    { name: "Henri Meck", location: "Molsheim", uai: "0670041F" },
    { name: "Henri Moissan", location: "Meaux", uai: "0770930T" },
    { name: "Henri Nominé", location: "Sarreguemines", uai: "0570099Y" },
    { name: "Henri Parriat", location: "Montceau-les-Mines", uai: "0710054Y" },
    { name: "Henri Poincaré", location: "Nancy", uai: "0540038Y" },
    { name: "Henri Poincaré", location: "Palaiseau", uai: "0912251C" },
    { name: "Henri Vincenot", location: "Louhans", uai: "0710042K" },
    { name: "Henri Vogt", location: "Commercy", uai: "0550008K" },
    { name: "Henri Wallon", location: "Valenciennes", uai: "0590221V" },
    { name: "Henri Wallon", location: "Aubervilliers", uai: "0930116W" },
    { name: "Hilaire de Chardonnet", location: "Chalon-sur-Saône", uai: "0711422K" },
    { name: "Hippolyte Carnot", location: "Roanne", uai: "0420034J" },
    { name: "Hippolyte Fontaine", location: "Dijon", uai: "0210018F" },
    { name: "Hoche", location: "Versailles", uai: "0782562L" },
    { name: "Honoré d'Estienne d'Orves", location: "Carquefou", uai: "0442834S" },
    { name: "Honoré d'Estienne d'Orves", location: "Nice", uai: "0060033D" },
    { name: "Honoré d'Urfé", location: "Saint-Étienne", uai: "0420042T" },
    { name: "Honoré Daumier", location: "Marseille 8e", uai: "0130175V" },
    { name: "Honoré de Balzac", location: "Paris 17e", uai: "0750705J" },
    { name: "Honoré de Balzac", location: "Mitry-Mory", uai: "0771996B" },
    { name: "Honoré Romane", location: "Embrun", uai: "0050004C" },
    { name: "Hugues Capet", location: "Senlis", uai: "0601826J" },
    { name: "Hugues Libergier", location: "Reims", uai: "0510035L" },
    { name: "Hyacinthe Bastaraud", location: "Grand-Bourg", uai: "9711012Y" },
    { name: "Hyacinthe Friant", location: "Poligny", uai: "0390033Z" },
    { name: "Hélène Boucher", location: "Paris 20e", uai: "0750714U" },
    { name: "Hélène Boucher", location: "Thionville", uai: "0570107G" },
    { name: "Immaculée Conception", location: "Villeurbanne", uai: "0690554C" },
    { name: "Immaculée Conception", location: "Espalion", uai: "0120052S" },
    { name: "Immaculée Conception", location: "Pau", uai: "0640130U" },
    { name: "Immaculée Conception", location: "Laval", uai: "0530048P" },
    { name: "Internat d'excellence de Sourdun", location: "Sourdun", uai: "0772737G" },
    { name: "Irène et Frédéric Joliot-Curie", location: "Sète", uai: "0340076Y" },
    { name: "Isaac de l'Etoile", location: "Poitiers", uai: "0860755D" },
    { name: "Isaac Newton", location: "Clichy", uai: "0920136Y" },
    { name: "Ismaël Dauphin", location: "Cavaillon", uai: "0840017M" },
    { name: "Jacob Holtzer", location: "Firminy", uai: "0420014M" },
    { name: "Jacques Amyot", location: "Auxerre", uai: "0890003V" },
    { name: "Jacques Amyot", location: "Melun", uai: "0770933W" },
    { name: "Jacques Audiberti", location: "Antibes", uai: "0060001U" },
    { name: "Jacques Brel", location: "La Courneuve", uai: "0931430Z" },
    { name: "Jacques Brel", location: "Vénissieux", uai: "0692717D" },
    { name: "Jacques Callot", location: "Vandœuvre-lès-Nancy", uai: "0540070H" },
    { name: "Jacques Cartier", location: "Saint-Malo", uai: "0350048M" },
    { name: "Jacques Cœur", location: "Bourges", uai: "0180007K" },
    { name: "Jacques de Vaucanson", location: "Tours", uai: "0371418R" },
    { name: "Jacques Decour", location: "Paris 9e", uai: "0750668U" },
    { name: "Jacques Duhamel", location: "Dole", uai: "0390013C" },
    { name: "Jacques Feyder", location: "Épinay-sur-Seine", uai: "0930120A" },
    { name: "Jacques Marquette", location: "Pont-à-Mousson", uai: "0540058V" },
    { name: "Jacques Monod", location: "Clamart", uai: "0921555R" },
    { name: "Jacques Monod", location: "Saint-Jean-de-Braye", uai: "0451462V" },
    { name: "Jacques Monod", location: "Lescar", uai: "0641839B" },
    { name: "Jacques Prévert", location: "Savenay", uai: "0440077V" },
    { name: "Jacques Prévert", location: "Saint-Christol-lez-Alès", uai: "0301778V" },
    { name: "Jacques Prévert", location: "Taverny", uai: "0950651S" },
    { name: "Jacques Prévert", location: "Longjumeau", uai: "0911577V" },
    { name: "Jacques Prévert", location: "Pont-Audemer", uai: "0271431J" },
    { name: "Jacques Prévert", location: "Boulogne-Billancourt", uai: "0920134W" },
    { name: "Jacques Ruffié", location: "Limoux", uai: "0110019L" },
    { name: "Jan Lavezzari", location: "Berck", uai: "0622276M" },
    { name: "Janson de Sailly", location: "Paris 16e", uai: "0750699C" },
    { name: "Jardin d'Essai", location: "Les Abymes", uai: "9710923B" },
    { name: "Jaufré Rudel", location: "Blaye", uai: "0330020T" },
    { name: "Javouhey", location: "Brest", uai: "0290181P" },
    { name: "Jay de Beaufort", location: "Périgueux", uai: "0241137F" },
    { name: "Jean Aicard", location: "Hyères", uai: "0830025B" },
    { name: "Jean Baptiste Colbert", location: "Lorient", uai: "0560026Z" },
    { name: "Jean Bart", location: "Dunkerque", uai: "0590071G" },
    { name: "Jean Baylet", location: "Valence", uai: "0820899G" },
    { name: "Jean Bodin", location: "Les Ponts-de-Cé", uai: "0492148U" },
    { name: "Jean Brito", location: "Bain-de-Bretagne", uai: "0352318E" },
    { name: "Jean Calvin", location: "Noyon", uai: "0600040T" },
    { name: "Jean Cassaigne", location: "Saint-Pierre-du-Mont", uai: "0400067F" },
    { name: "Jean Cocteau", location: "Miramas", uai: "0133195C" },
    { name: "Jean Condorcet", location: "Bordeaux", uai: "0332747G" },
    { name: "Jean d'Ormesson", location: "Châteaurenard", uai: "0134252B" },
    { name: "Jean Dautet", location: "La Rochelle", uai: "0170028N" },
    { name: "Jean de La Fontaine", location: "Paris 16e", uai: "0750702F" },
    { name: "Jean de La Fontaine", location: "Château-Thierry", uai: "0020012C" },
    { name: "Jean de Lattre de Tassigny", location: "La Roche-sur-Yon", uai: "0851401L" },
    { name: "Jean de Pange", location: "Sarreguemines", uai: "0570098X" },
    { name: "Jean de Prades", location: "Castelsarrasin", uai: "0820004J" },
    { name: "Jean Dupuy", location: "Tarbes", uai: "0650027B" },
    { name: "Jean Favard", location: "Guéret", uai: "0230051F" },
    { name: "Jean Giraudoux", location: "Châteauroux", uai: "0360008N" },
    { name: "Jean Giraudoux", location: "Bellac", uai: "0870003F" },
    { name: "Jean Guéhenno", location: "Fougères", uai: "0350011X" },
    { name: "Jean Guéhenno", location: "Flers", uai: "0610021S" },
    { name: "Jean Hanzelet", location: "Pont-à-Mousson", uai: "0541270M" },
    { name: "Jean Hinglo", location: "Le Port", uai: "9740979W" },
    { name: "Jean Hyppolite", location: "Jonzac", uai: "0170135E" },
    { name: "Jean Jaurès", location: "Reims", uai: "0510032H" },
    { name: "Jean Jaurès", location: "Saint-Clément-de-Rivière", uai: "0342066L" },
    { name: "Jean Jaurès", location: "Carmaux", uai: "0810012Y" },
    { name: "Jean Jaurès", location: "Saint-Affrique", uai: "0120025M" },
    { name: "Jean Jaurès", location: "Montreuil", uai: "0930121B" },
    { name: "Jean Jaurès", location: "Argenteuil", uai: "0950641F" },
    { name: "Jean Jaurès", location: "Châtenay-Malabry", uai: "0921166T" },
    { name: "Jean Joly", location: "Saint-Louis", uai: "9741182S" },
    { name: "Jean Lurçat", location: "Martigues", uai: "0132210G" },
    { name: "Jean Lurçat", location: "Saint-Céré", uai: "0460026D" },
    { name: "Jean Lurçat", location: "Perpignan", uai: "0660011D" },
    { name: "Jean Lurçat", location: "Bruyères", uai: "0880004B" },
    { name: "Jean Macé", location: "Rennes", uai: "0350026N" },
    { name: "Jean Macé", location: "Niort", uai: "0790023W" },
    { name: "Jean Macé", location: "Lanester", uai: "0561698S" },
    { name: "Jean Macé", location: "Vitry-sur-Seine", uai: "0940129E" },
    { name: "Jean Mermoz", location: "Saint-Louis", uai: "0680066C" },
    { name: "Jean Mermoz", location: "Montpellier", uai: "0340042L" },
    { name: "Jean Michel", location: "Lons-le-Saunier", uai: "0390019J" },
    { name: "Jean Monnet", location: "Crépy-en-Valois", uai: "0601832R" },
    { name: "Jean Monnet", location: "Joué-lès-Tours", uai: "0371417P" },
    { name: "Jean Monnet", location: "Mortagne-au-Perche", uai: "0611148S" },
    { name: "Jean Monnet", location: "Cognac", uai: "0160020K" },
    { name: "Jean Monnet", location: "Annemasse", uai: "0741476C" },
    { name: "Jean Monnet", location: "Montpellier", uai: "0341736C" },
    { name: "Jean Monnet", location: "La Queue-les-Yvelines", uai: "0781839A" },
    { name: "Jean Monnet", location: "Blanquefort", uai: "0332745E" },
    { name: "Jean Monnet", location: "Vitrolles", uai: "0133288D" },
    { name: "Jean Monnet", location: "Saint-Étienne", uai: "0420043U" },
    { name: "Jean Monnet", location: "Franconville", uai: "0951722F" },
    { name: "Jean Monnet", location: "Les Herbiers", uai: "0851400K" },
    { name: "Jean Monnet", location: "Libourne", uai: "0330089T" },
    { name: "Jean Monnet", location: "Yzeure", uai: "0030038A" },
    { name: "Jean Monnet", location: "Strasbourg", uai: "0670078W" },
    { name: "Jean Moulin", location: "Angers", uai: "0492123S" },
    { name: "Jean Moulin", location: "Draguignan", uai: "0830015R" },
    { name: "Jean Moulin", location: "Pézenas", uai: "0340059E" },
    { name: "Jean Moulin", location: "Forbach", uai: "0570029X" },
    { name: "Jean Moulin", location: "Revin", uai: "0080040A" },
    { name: "Jean Moulin", location: "Albertville", uai: "0730005J" },
    { name: "Jean Moulin", location: "Saint-Amand-Montrond", uai: "0180024D" },
    { name: "Jean Moulin", location: "Châteaulin", uai: "0290023T" },
    { name: "Jean Moulin", location: "Montmorillon", uai: "0860028N" },
    { name: "Jean Moulin", location: "Les Andelys", uai: "0271585B" },
    { name: "Jean Moulin", location: "Thouars", uai: "0790036K" },
    { name: "Jean Moulin", location: "Langon", uai: "0331636Z" },
    { name: "Jean Moulin", location: "Béziers", uai: "0340011C" },
    { name: "Jean Moulin", location: "Roubaix", uai: "0590185F" },
    { name: "Jean Moulin", location: "Torcy", uai: "0772120L" },
    { name: "Jean Perrin", location: "Rezé", uai: "0440062D" },
    { name: "Jean Perrin", location: "Lyon 9e", uai: "0690082P" },
    { name: "Jean Perrin", location: "Lambersart", uai: "0590110Z" },
    { name: "Jean Perrin", location: "Saint-Ouen-l'Aumône", uai: "0951104J" },
    { name: "Jean Perrin", location: "Marseille 10e", uai: "0130053M" },
    { name: "Jean Prouvé", location: "Lille", uai: "0595786U" },
    { name: "Jean Prévost", location: "Villard-de-Lans", uai: "0380097Z" },
    { name: "Jean Prévost", location: "Montivilliers", uai: "0760076V" },
    { name: "Jean Puy", location: "Roanne", uai: "0420031F" },
    { name: "Jean Racine", location: "Montdidier", uai: "0801853E" },
    { name: "Jean Renoir", location: "Bondy", uai: "0930118Y" },
    { name: "Jean Renou", location: "La Réole", uai: "0330109P" },
    { name: "Jean Rostand", location: "Strasbourg", uai: "0670084C" },
    { name: "Jean Rostand", location: "Chantilly", uai: "0600009J" },
    { name: "Jean Rostand", location: "Caen", uai: "0140017T" },
    { name: "Jean Rostand", location: "Villepinte", uai: "0931584S" },
    { name: "Jean Rostand", location: "Mantes-la-Jolie", uai: "0782540M" },
    { name: "Jean Talon", location: "Châlons-en-Champagne", uai: "0511951U" },
    { name: "Jean Taris", location: "Peyrehorade", uai: "0400027M" },
    { name: "Jean Vigo", location: "Millau", uai: "0120012Y" },
    { name: "Jean Vilar", location: "Villeneuve-lès-Avignon", uai: "0301722J" },
    { name: "Jean Vilar", location: "Plaisir", uai: "0780582J" },
    { name: "Jean Vilar", location: "Meaux", uai: "0772229E" },
    { name: "Jean XXIII", location: "Montigny-lès-Metz", uai: "0572341K" },
    { name: "Jean XXIII", location: "Yvetot", uai: "0761721H" },
    { name: "Jean XXIII", location: "Les Herbiers", uai: "0850076W" },
    { name: "Jean XXIII", location: "Quintin", uai: "0220106W" },
    { name: "Jean Zay", location: "Orléans", uai: "0450050K" },
    { name: "Jean Zay", location: "Jarny", uai: "0540076P" },
    { name: "Jean Zay", location: "Aulnay-sous-Bois", uai: "0930833A" },
    { name: "Jean-Auguste Margueritte", location: "Verdun", uai: "0550025D" },
    { name: "Jean-Baptiste Corot", location: "Douai", uai: "0590064Z" },
    { name: "Jean-Baptiste Corot", location: "Savigny-sur-Orge", uai: "0910627M" },
    { name: "Jean-Baptiste Darnet", location: "Saint-Yrieix-la-Perche", uai: "0870050G" },
    { name: "Jean-Baptiste de Baudre", location: "Agen", uai: "0470003Y" },
    { name: "Jean-Baptiste de La Salle", location: "Rouen", uai: "0761715B" },
    { name: "Jean-Baptiste Delambre", location: "Amiens", uai: "0801700N" },
    { name: "Jean-Baptiste Dumas", location: "Alès", uai: "0300002P" },
    { name: "Jean-Baptiste Le Taillandier", location: "Fougères", uai: "0350769W" },
    { name: "Jean-Baptiste Poquelin", location: "Saint-Germain-en-Laye", uai: "0782557F" },
    { name: "Jean-Baptiste Say", location: "Paris 16e", uai: "0750700D" },
    { name: "Jean-Baptiste Schwilgué", location: "Sélestat", uai: "0671832C" },
    { name: "Jean-Baptiste Vatelot", location: "Toul", uai: "0541320S" },
    { name: "Jean-Baptiste Vuillaume", location: "Mirecourt", uai: "0880036L" },
    { name: "Jean-Claude Fruteau", location: "Saint-Benoît", uai: "9740471U" },
    { name: "Jean-François Millet", location: "Cherbourg-en-Cotentin", uai: "0500016W" },
    { name: "Jean-Henri Fabre", location: "Carpentras", uai: "0840015K" },
    { name: "Jean-Henri Lambert", location: "Mulhouse", uai: "0681761V" },
    { name: "Jean-Jacques Henner", location: "Altkirch", uai: "0680001G" },
    { name: "Jean-Jacques Rousseau", location: "Montmorency", uai: "0950648N" },
    { name: "Jean-Jacques Rousseau", location: "Sarcelles", uai: "0950650R" },
    { name: "Jean-Joseph Fourier", location: "Auxerre", uai: "0890005X" },
    { name: "Jean-Marc Boivin", location: "Chevigny-Saint-Sauveur", uai: "0211909L" },
    { name: "Jean-Marie le Bris", location: "Douarnenez", uai: "0290034E" },
    { name: "Jean-Paul de Rocca Serra", location: "Porto-Vecchio", uai: "6200063Z" },
    { name: "Jean-Paul II", location: "Saint-Grégoire", uai: "0350801F" },
    { name: "Jean-Paul II", location: "Compiègne", uai: "0601701Y" },
    { name: "Jean-Paul II", location: "Rouen", uai: "0763409T" },
    { name: "Jean-Paul II", location: "Coutances", uai: "0500116E" },
    { name: "Jean-Paul Sartre", location: "Bron", uai: "0693044J" },
    { name: "Jean-Pierre Timbaud", location: "Brétigny-sur-Orge", uai: "0910975R" },
    { name: "Jean-Pierre Vernant", location: "Sèvres", uai: "0920802X" },
    { name: "Jean-Pierre Vernant", location: "Pins-Justaret", uai: "0312744P" },
    { name: "Jean-Victor Poncelet", location: "Saint-Avold", uai: "0570085H" },
    { name: "Jeanne d'Albret", location: "Saint-Germain-en-Laye", uai: "0782132U" },
    { name: "Jeanne d'Arc", location: "Mulhouse", uai: "0680151V" },
    { name: "Jeanne d'Arc", location: "Caen", uai: "0141161L" },
    { name: "Jeanne d'Arc", location: "Montrouge", uai: "0920918Y" },
    { name: "Jeanne d'Arc", location: "Nancy", uai: "0540039Z" },
    { name: "Jeanne d'Arc", location: "Cessy", uai: "0010878Z" },
    { name: "Jeanne d'Arc", location: "Bastia", uai: "7200073S" },
    { name: "Jeanne d'Arc", location: "Mazamet", uai: "0810079W" },
    { name: "Jeanne d'Arc", location: "Clermont-Ferrand", uai: "0630019D" },
    { name: "Jeanne d'Arc", location: "Colombes", uai: "0921663H" },
    { name: "Jeanne d'Arc", location: "Argentan", uai: "0610694Y" },
    { name: "Jeanne d'Arc", location: "Étampes", uai: "0910815S" },
    { name: "Jeanne d'Arc", location: "Brétigny-sur-Orge", uai: "0912384X" },
    { name: "Jeanne d'Arc", location: "Tarbes", uai: "0650062P" },
    { name: "Jeanne d'Arc", location: "Paray-le-Monial", uai: "0710112L" },
    { name: "Jeanne d'Arc", location: "Montaigu-Vendée", uai: "0850136L" },
    { name: "Jeanne d'Arc", location: "Bayeux", uai: "0141157G" },
    { name: "Jeanne d'Arc", location: "Millau", uai: "0120051R" },
    { name: "Jeanne d'Arc", location: "Vitré", uai: "0350808N" },
    { name: "Jeanne d'Arc", location: "Figeac", uai: "0460039T" },
    { name: "Jeanne d'Arc", location: "Rouen", uai: "0760091L" },
    { name: "Jeanne d'Arc", location: "Albertville", uai: "0730760E" },
    { name: "Jeanne d'Arc", location: "Le Péage-de-Roussillon", uai: "0381670J" },
    { name: "Jeanne d'Arc", location: "Franconville", uai: "0950812S" },
    { name: "Jeanne d'Arc Saint-Aspais", location: "Fontainebleau", uai: "0771232W" },
    { name: "Jeanne d'Arc Saint-Ivy", location: "Pontivy", uai: "0560182U" },
    { name: "Jeanne d'Arc Saint-Joseph", location: "Remiremont", uai: "0880097C" },
    { name: "Jeanne Hachette", location: "Beauvais", uai: "0601824G" },
    { name: "Jeannine Manuel", location: "Paris 15e", uai: "0753874D" },
    { name: "Jeannine Manuel", location: "Marcq-en-Barœul", uai: "0596122J" },
    { name: "Jehan Ango", location: "Dieppe", uai: "0760023M" },
    { name: "Jehan de Beauce", location: "Chartres", uai: "0280044W" },
    { name: "Jehan de Chelles", location: "Chelles", uai: "0772276F" },
    { name: "Jessé de Forest", location: "Avesnes-sur-Helpe", uai: "0590018Z" },
    { name: "Joachim du Bellay", location: "Angers", uai: "0490002L" },
    { name: "Joffre", location: "Montpellier", uai: "0340038G" },
    { name: "Johannes Gutenberg", location: "Créteil", uai: "0941930M" },
    { name: "Joliot Curie", location: "Hirson", uai: "0020031Y" },
    { name: "Joliot-Curie", location: "Romilly-sur-Seine", uai: "0100015M" },
    { name: "Joliot-Curie", location: "Rennes", uai: "0350029S" },
    { name: "Joliot-Curie", location: "Nanterre", uai: "0920141D" },
    { name: "Joseph Desfontaines", location: "Melle", uai: "0790019S" },
    { name: "Joseph Gaillard", location: "Fort-de-France", uai: "9720004X" },
    { name: "Joseph Loth", location: "Pontivy", uai: "0560038M" },
    { name: "Joseph Saverne", location: "L'Isle-Jourdain", uai: "0320036R" },
    { name: "Joseph Savina", location: "Tréguier", uai: "0220065B" },
    { name: "Joseph Vallot", location: "Lodève", uai: "0340028W" },
    { name: "Joseph Zobel", location: "Rivière-Salée", uai: "9720725F" },
    { name: "Joseph-Marie Carriat", location: "Bourg-en-Bresse", uai: "0010016M" },
    { name: "Joseph-Marie Jacquard", location: "Caudry", uai: "0590044C" },
    { name: "Joséphine Baker", location: "Toulouse", uai: "0311323V" },
    { name: "Joubert Emilien Maillard", location: "Ancenis-Saint-Géréon", uai: "0440001M" },
    { name: "Jules Ferry", location: "Cannes", uai: "0060014H" },
    { name: "Jules Ferry", location: "Saint-Dié-des-Vosges", uai: "0880055G" },
    { name: "Jules Ferry", location: "Paris 9e", uai: "0750669V" },
    { name: "Jules Ferry", location: "Conflans-Sainte-Honorine", uai: "0781845G" },
    { name: "Jules Ferry", location: "Versailles", uai: "0782565P" },
    { name: "Jules Fil", location: "Carcassonne", uai: "0110007Y" },
    { name: "Jules Froment", location: "Aubenas", uai: "0070064G" },
    { name: "Jules Guesde", location: "Montpellier", uai: "0340040J" },
    { name: "Jules Haag", location: "Besançon", uai: "0250011B" },
    { name: "Jules Hardouin-Mansart", location: "Saint-Cyr-l'École", uai: "0783140P" },
    { name: "Jules Lesven", location: "Brest", uai: "0290013G" },
    { name: "Jules Michelet", location: "Montauban", uai: "0820020B" },
    { name: "Jules Mousseron", location: "Denain", uai: "0590282L" },
    { name: "Jules Renard", location: "Nevers", uai: "0580031U" },
    { name: "Jules Siegfried", location: "Le Havre", uai: "0760056Y" },
    { name: "Jules Supervielle", location: "Oloron-Sainte-Marie", uai: "0640047D" },
    { name: "Jules Uhry", location: "Creil", uai: "0600021X" },
    { name: "Jules Verne", location: "Nantes", uai: "0440022K" },
    { name: "Jules Verne", location: "Limours", uai: "0911983L" },
    { name: "Jules Verne", location: "Château-Thierry", uai: "0021939X" },
    { name: "Jules Verne", location: "Cergy", uai: "0951756T" },
    { name: "Julie Daubié", location: "Rombas", uai: "0570146Z" },
    { name: "Julie-Victoire Daubié", location: "Argenteuil", uai: "0950640E" },
    { name: "Julien Gracq", location: "Beaupréau", uai: "0492430A" },
    { name: "Julien Wittmer", location: "Charolles", uai: "0710018J" },
    { name: "Juliette Récamier", location: "Lyon 2e", uai: "0690032K" },
    { name: "Julliot de la Morandière", location: "Granville", uai: "0500036T" },
    { name: "Jérémie de la Rue", location: "Charlieu", uai: "0420008F" },
    { name: "Kerneuzec", location: "Quimperlé", uai: "0290076A" },
    { name: "Kerraoul", location: "Paimpol", uai: "0221571N" },
    { name: "Kléber", location: "Strasbourg", uai: "0670080Y" },
    { name: "L'Alma", location: "Paris 7e", uai: "0753849B" },
    { name: "L'Annonciation", location: "Seilh", uai: "0311132M" },
    { name: "L'Assomption", location: "Paris 16e", uai: "0753948J" },
    { name: "l'Astrée", location: "Boën-sur-Lignon", uai: "0422132P" },
    { name: "L'Ermitage", location: "Maisons-Laffitte", uai: "0783283V" },
    { name: "L'Espérance", location: "Aulnay-sous-Bois", uai: "0930961P" },
    { name: "L'Essouriau", location: "Les Ulis", uai: "0911492C" },
    { name: "L'Oiselet", location: "Bourgoin-Jallieu", uai: "0380008C" },
    { name: "L'Olivier Robert Coffy", location: "Marseille 12e", uai: "0131345S" },
    { name: "La Baugerie", location: "Saint-Sébastien-sur-Loire", uai: "0440279P" },
    { name: "La Borde Basse", location: "Castres", uai: "0810959C" },
    { name: "La Bruyère", location: "Versailles", uai: "0782563M" },
    { name: "La Chartreuse Paradis", location: "Brives-Charensac", uai: "0430055B" },
    { name: "La Colinière", location: "Nantes", uai: "0440086E" },
    { name: "La Croix Blanche", location: "Bondues", uai: "0592935V" },
    { name: "La Croix Rouge La Salle", location: "Brest", uai: "0290335G" },
    { name: "La Doctrine Chrétienne", location: "Strasbourg", uai: "0671611M" },
    { name: "La Découverte", location: "Decazeville", uai: "0120006S" },
    { name: "La Favorite", location: "Lyon 5e", uai: "0690520R" },
    { name: "La Fayette", location: "Brioude", uai: "0430003V" },
    { name: "La Fayette", location: "Clermont-Ferrand", uai: "0630021F" },
    { name: "La Folie Saint-James", location: "Neuilly-sur-Seine", uai: "0920143F" },
    { name: "La Fontaine des Eaux", location: "Dinan", uai: "0220013V" },
    { name: "La Fontaine du Vé", location: "Sézanne", uai: "0510053F" },
    { name: "La Haie Griselle", location: "Gérardmer", uai: "0880030E" },
    { name: "La Herdrie", location: "Basse-Goulaine", uai: "0442095N" },
    { name: "La Hotoie", location: "Amiens", uai: "0801882L" },
    { name: "La Jetée", location: "Le François", uai: "9720771F" },
    { name: "La Malassise", location: "Longuenesse", uai: "0622116N" },
    { name: "La Malgrange", location: "Jarville-la-Malgrange", uai: "0541986R" },
    { name: "La Martinière Diderot", location: "Lyon 1er", uai: "0690037R" },
    { name: "La Martinière Duchère", location: "Lyon 9e", uai: "0690038S" },
    { name: "La Martinière Monplaisir", location: "Lyon 8e", uai: "0692866R" },
    { name: "La Mennais", location: "Guérande", uai: "0442226F" },
    { name: "La Mennais", location: "Ploërmel", uai: "0560105K" },
    { name: "La Merci Littoral", location: "La Grande-Motte", uai: "0342175E" },
    { name: "La Nativité", location: "Aix-en-Provence", uai: "0131319N" },
    { name: "La Persévérance", location: "Pointe-à-Pitre", uai: "9710062R" },
    { name: "La Perverie", location: "Nantes", uai: "0440172Y" },
    { name: "La Pléiade", location: "Pont-de-Chéruy", uai: "0382838D" },
    { name: "La Prat's", location: "Cluny", uai: "0710023P" },
    { name: "La Providence", location: "Dieppe", uai: "0761707T" },
    { name: "La Providence", location: "Fécamp", uai: "0763112V" },
    { name: "La Providence", location: "Amiens", uai: "0801742J" },
    { name: "La Providence", location: "Montauban-de-Bretagne", uai: "0351930H" },
    { name: "La Providence", location: "Laon", uai: "0021872Z" },
    { name: "La Providence Nicolas Barré", location: "Le Mesnil-Esnard", uai: "0761713Z" },
    { name: "La Présentation de Marie", location: "Saint-Julien-en-Genevois", uai: "0740097D" },
    { name: "La Pérouse Kerichen", location: "Brest", uai: "0290007A" },
    { name: "La Rochefoucauld", location: "Paris 7e", uai: "0753851D" },
    { name: "La Salle", location: "Alès", uai: "0300125Y" },
    { name: "La Salle", location: "Lille", uai: "0593117T" },
    { name: "La Salle", location: "Saint-Étienne", uai: "0420976H" },
    { name: "La Salle", location: "Troyes", uai: "0100059K" },
    { name: "La Salle Godefroy de Bouillon", location: "Clermont-Ferrand", uai: "0631068U" },
    { name: "La Salle Passy Buzenval", location: "Rueil-Malmaison", uai: "0920928J" },
    { name: "La Salle Saint-Charles", location: "Saint-Pierre", uai: "9741255W" },
    { name: "La Salle Saint-Denis", location: "Saint-Denis", uai: "0931797Y" },
    { name: "La Salle Saint-Louis", location: "Pont-l'Abbé-d'Arnoult", uai: "0170098P" },
    { name: "La Salle Saint-Nicolas", location: "Issy-les-Moulineaux", uai: "0922653J" },
    { name: "La Salle Saint-Rosaire", location: "Sarcelles", uai: "0950786N" },
    { name: "La Saulaie", location: "Saint-Marcellin", uai: "0380063M" },
    { name: "La Sauque", location: "La Brède", uai: "0331504F" },
    { name: "La Serre de Sarsan", location: "Lourdes", uai: "0650040R" },
    { name: "La Source", location: "Meudon", uai: "0920916W" },
    { name: "La Tour", location: "Paris 16e", uai: "0753943D" },
    { name: "La Tour des Dames", location: "Rozay-en-Brie", uai: "0772295B" },
    { name: "La Trinité", location: "Lyon 6e", uai: "0690533E" },
    { name: "La Trinité", location: "Béziers", uai: "0340871M" },
    { name: "La Trinité", location: "Neuilly-sur-Seine", uai: "0920985W" },
    { name: "La Versoie", location: "Thonon-les-Bains", uai: "0740046Y" },
    { name: "La Xavière", location: "Lyon 8e", uai: "0693587Z" },
    { name: "Lacassagne", location: "Lyon 3e", uai: "0690029G" },
    { name: "Lacordaire", location: "Marseille 13e", uai: "0131324U" },
    { name: "Lakanal", location: "Sceaux", uai: "0920145H" },
    { name: "Lalande", location: "Bourg-en-Bresse", uai: "0010013J" },
    { name: "Lama-Prévot", location: "Remire-Montjoly", uai: "9730423X" },
    { name: "Lamarck", location: "Albert", uai: "0800007Y" },
    { name: "Lamartine", location: "Mâcon", uai: "0710045N" },
    { name: "Lamartine", location: "Belley", uai: "0010069V" },
    { name: "Langevin Wallon", location: "Champigny-sur-Marne", uai: "0940113M" },
    { name: "Lapérouse", location: "Albi", uai: "0810006S" },
    { name: "Largenté", location: "Bayonne", uai: "0640111Y" },
    { name: "Las Cases", location: "Lavaur", uai: "0810030T" },
    { name: "Laure Gatet", location: "Périgueux", uai: "0240025X" },
    { name: "Laurent de Lavoisier", location: "Mulhouse", uai: "0681768C" },
    { name: "Lavoisier", location: "Paris 5e", uai: "0750656F" },
    { name: "Lavoisier", location: "Mayenne", uai: "0530016E" },
    { name: "Lavoisier", location: "Auchel", uai: "0620017G" },
    { name: "Le Bon Sauveur", location: "Le Vésinet", uai: "0783297K" },
    { name: "Le Caousou", location: "Toulouse", uai: "0311133N" },
    { name: "Le Castel", location: "Dijon", uai: "0210019G" },
    { name: "Le Champ Blanc", location: "Sèvremoine", uai: "0490952U" },
    { name: "Le Corbusier", location: "Illkirch-Graffenstaden", uai: "0672198A" },
    { name: "Le Corbusier", location: "Poissy", uai: "0782546U" },
    { name: "Le Corbusier", location: "Aubervilliers", uai: "0930117X" },
    { name: "Le Ferradou", location: "Blagnac", uai: "0311126F" },
    { name: "Le Garros", location: "Auch", uai: "0320067Z" },
    { name: "Le Gymnase Jean Sturm", location: "Strasbourg", uai: "0671552Y" },
    { name: "Le Likès", location: "Quimper", uai: "0290170C" },
    { name: "Le Mans Sud", location: "Le Mans", uai: "0721094Y" },
    { name: "Le Mirail", location: "Bordeaux", uai: "0331498Z" },
    { name: "Le Rebours", location: "Paris 13e", uai: "0754042L" },
    { name: "Le Verger", location: "Sainte-Marie", uai: "9741185V" },
    { name: "Le Verrier", location: "Saint-Lô", uai: "0500065Z" },
    { name: "Leclerc Navarre", location: "Alençon", uai: "0610002W" },
    { name: "Leconte de Lisle", location: "Saint-Denis", uai: "9740001H" },
    { name: "Les 7 Mares", location: "Maurepas", uai: "0780515L" },
    { name: "Les Arcades", location: "Dijon", uai: "0211091X" },
    { name: "Les Augustins", location: "Pontarlier", uai: "0251024C" },
    { name: "Les Bourdonnières", location: "Nantes", uai: "0441552Y" },
    { name: "Les Bruyères", location: "Sotteville-lès-Rouen", uai: "0760109F" },
    { name: "Les Catalins", location: "Montélimar", uai: "0260113G" },
    { name: "Les Chartreux", location: "Lyon 1er", uai: "0690564N" },
    { name: "Les Cordeliers Notre-Dame de la Victoire", location: "Dinan", uai: "0220094H" },
    { name: "Les Eaux Claires", location: "Grenoble", uai: "0380029A" },
    { name: "Les Eucalyptus", location: "Nice", uai: "0060075Z" },
    { name: "Les Fontenelles", location: "Louviers", uai: "0270029K" },
    { name: "Les Francs Bourgeois", location: "Paris 4e", uai: "0753820V" },
    { name: "Les Haberges", location: "Vesoul", uai: "0701052N" },
    { name: "Les Iris", location: "Lormont", uai: "0332832Z" },
    { name: "Les Iscles", location: "Manosque", uai: "0040533H" },
    { name: "Les Lombards", location: "Troyes", uai: "0100025Y" },
    { name: "Les Maristes", location: "Toulouse", uai: "0311138U" },
    { name: "Les Pannevelles", location: "Provins", uai: "0771336J" },
    { name: "Les Pierres Vives", location: "Carrières-sur-Seine", uai: "0781860Y" },
    { name: "Les Rimains", location: "Saint-Malo", uai: "0350787R" },
    { name: "Les Récollets", location: "Longwy", uai: "0541309E" },
    { name: "Les Trois Sources", location: "Bourg-lès-Valence", uai: "0261277X" },
    { name: "Levavasseur", location: "Saint-Denis", uai: "9741256X" },
    { name: "Lislet Geoffroy", location: "Saint-Denis", uai: "9740054R" },
    { name: "Livet", location: "Nantes", uai: "0440029T" },
    { name: "Lot et Bastides", location: "Villeneuve-sur-Lot", uai: "0470038L" },
    { name: "Louis Aragon", location: "Héricourt", uai: "0701035V" },
    { name: "Louis Armand", location: "Nogent-sur-Marne", uai: "0940118T" },
    { name: "Louis Armand", location: "Chambéry", uai: "0731248K" },
    { name: "Louis Armand", location: "Villefranche-sur-Saône", uai: "0691644M" },
    { name: "Louis Armand", location: "Mulhouse", uai: "0680034T" },
    { name: "Louis Armand", location: "Eaubonne", uai: "0951974E" },
    { name: "Louis Armand", location: "Paris 15e", uai: "0751708Z" },
    { name: "Louis Audouin-Dubreuil", location: "Saint-Jean-d'Angély", uai: "0170051N" },
    { name: "Louis Barthou", location: "Pau", uai: "0640055M" },
    { name: "Louis Bascan", location: "Rambouillet", uai: "0782549X" },
    { name: "Louis Bertrand", location: "Val de Briey", uai: "0541286E" },
    { name: "Louis Blaringhem", location: "Béthune", uai: "0620040G" },
    { name: "Louis Couffignal", location: "Strasbourg", uai: "0670085D" },
    { name: "Louis Davier", location: "Joigny", uai: "0891199V" },
    { name: "Louis de Broglie", location: "Marly-le-Roi", uai: "0781861Z" },
    { name: "Louis de Cormontaigne", location: "Metz", uai: "0573227Y" },
    { name: "Louis de Foix", location: "Bayonne", uai: "0640011P" },
    { name: "Louis Feuillade", location: "Lunel", uai: "0340030Y" },
    { name: "Louis Jouvet", location: "Taverny", uai: "0951763A" },
    { name: "Louis Lachenal", location: "Argonay", uai: "0740006E" },
    { name: "Louis Lapicque", location: "Épinal", uai: "0880019T" },
    { name: "Louis Le Grand", location: "Paris 5e", uai: "0750655E" },
    { name: "Louis Majorelle", location: "Toul", uai: "0540066D" },
    { name: "Louis Marchal", location: "Molsheim", uai: "0672615D" },
    { name: "Louis Pasquet", location: "Arles", uai: "0130011S" },
    { name: "Louis Pasteur", location: "Neuilly-sur-Seine", uai: "0920142E" },
    { name: "Louis Pasteur", location: "Lille", uai: "0590117G" },
    { name: "Louis Pasteur", location: "Avignon", uai: "0840059H" },
    { name: "Louis Pasteur", location: "Strasbourg", uai: "0670082A" },
    { name: "Louis Pasteur", location: "Somain", uai: "0590207E" },
    { name: "Louis Pasteur", location: "Hénin-Beaumont", uai: "0620095S" },
    { name: "Louis Payen", location: "Saint-Paul", uai: "9741050Y" },
    { name: "Louis Pergaud", location: "Besançon", uai: "0250010A" },
    { name: "Louis Querbes", location: "Rodez", uai: "0121428M" },
    { name: "Louis Rascol", location: "Albi", uai: "0810004P" },
    { name: "Louis Thuillier", location: "Amiens", uai: "0800009A" },
    { name: "Louis Vicat", location: "Souillac", uai: "0460028F" },
    { name: "Louis Vincent", location: "Metz", uai: "0570058D" },
    { name: "Louis-Casimir Teyssier", location: "Bitche", uai: "0573326F" },
    { name: "Louis-Modeste Leroy", location: "Évreux", uai: "0270017X" },
    { name: "Louise de Marillac", location: "Paris 5e", uai: "0753827C" },
    { name: "Louise Michel", location: "Narbonne", uai: "0110023R" },
    { name: "Louise Michel", location: "Champigny-sur-Marne", uai: "0940112L" },
    { name: "Louise Michel", location: "Gisors", uai: "0270026G" },
    { name: "Louise Michel", location: "Bobigny", uai: "0931613Y" },
    { name: "Louise Michel", location: "Grenoble", uai: "0380034F" },
    { name: "Louise Weiss", location: "Sainte-Marie-aux-Mines", uai: "0680068E" },
    { name: "Louise Weiss", location: "Achères", uai: "0781950W" },
    { name: "Lucie Aubrac", location: "Bollène", uai: "0841093G" },
    { name: "Lucie Aubrac", location: "Courbevoie", uai: "0922615T" },
    { name: "Lucie Aubrac", location: "Pantin", uai: "0932117W" },
    { name: "Lucien de Hirsch", location: "Paris 19e", uai: "0754965P" },
    { name: "Lumina Sophie", location: "Saint-Laurent-du-Maroni", uai: "9730371R" },
    { name: "Lumière", location: "Luxeuil-les-Bains", uai: "0701078S" },
    { name: "Lycée catholique de Pontlevoy", location: "Pontlevoy", uai: "0411071S" },
    { name: "Lycée d'altitude", location: "Briançon", uai: "0050003B" },
    { name: "Lycée d'Arsonval", location: "Brive-la-Gaillarde", uai: "0190011J" },
    { name: "Lycée d'Arsonval", location: "Saint-Maur-des-Fossés", uai: "0940121W" },
    { name: "Lycée d'Artagnan", location: "Nogaro", uai: "0320025D" },
    { name: "Lycée d'Artois", location: "Nœux-les-Mines", uai: "0624440P" },
    { name: "Lycée d'Avesnières", location: "Laval", uai: "0530049R" },
    { name: "Lycée d'Estournelles de Constant", location: "La Flèche", uai: "0720021G" },
    { name: "Lycée d'Ile-de-France", location: "Villebon-sur-Yvette", uai: "0910823A" },
    { name: "Lycée de Bagatelle", location: "Saint-Gaudens", uai: "0310032S" },
    { name: "Lycée de Balagne", location: "L'Île-Rousse", uai: "7200123W" },
    { name: "Lycée de Bandrelé", location: "Bandrele", uai: "9760363R" },
    { name: "Lycée de Bois d'Olive", location: "Saint-Pierre", uai: "9741206T" },
    { name: "Lycée de Borda", location: "Dax", uai: "0400007R" },
    { name: "Lycée de Cachan", location: "Cachan", uai: "0940580V" },
    { name: "Lycée de Cornouaille", location: "Quimper", uai: "0290098Z" },
    { name: "Lycée de Coulommiers", location: "Coulommiers", uai: "0770924L" },
    { name: "Lycée de Dembéni", location: "Dembeni", uai: "9760338N" },
    { name: "Lycée de Genech", location: "Genech", uai: "0596281G" },
    { name: "Lycée de Haute Auvergne", location: "Saint-Flour", uai: "0150030B" },
    { name: "Lycée de Kawéni", location: "Mamoudzou", uai: "9760163Y" },
    { name: "Lycée de l'Albanais", location: "Rumilly", uai: "0741532N" },
    { name: "Lycée de l'Arc", location: "Orange", uai: "0840026X" },
    { name: "Lycée de l'Authie", location: "Doullens", uai: "0801900F" },
    { name: "Lycée de l'Edit", location: "Roussillon", uai: "0381599G" },
    { name: "Lycée de l'Empéri", location: "Salon-de-Provence", uai: "0130160D" },
    { name: "Lycée de l'Europe", location: "Dunkerque", uai: "0590072H" },
    { name: "Lycée de l'Hautil", location: "Jouy-le-Moutier", uai: "0951824S" },
    { name: "Lycée de l'image et du son", location: "Angoulême", uai: "0161095D" },
    { name: "Lycée de l'Iroise", location: "Brest", uai: "0290009C" },
    { name: "Lycée de la communication", location: "Metz", uai: "0573281G" },
    { name: "Lycée de la Côte d'Albâtre", location: "Saint-Valery-en-Caux", uai: "0762953X" },
    { name: "Lycée de la Côtière", location: "La Boisse", uai: "0011326L" },
    { name: "Lycée de la Mare Carrée", location: "Moissy-Cramayel", uai: "0772296C" },
    { name: "Lycée de la Matheysine", location: "La Mure", uai: "0380049X" },
    { name: "Lycée de la mer", location: "Gujan-Mestras", uai: "0332870R" },
    { name: "Lycée de la mer et du littoral", location: "Bourcefranc-le-Chapus", uai: "0171428K" },
    { name: "Lycée de la montagne", location: "Valdeblore", uai: "0061987C" },
    { name: "Lycée de la Méditerranée", location: "La Ciotat", uai: "0133406G" },
    { name: "Lycée de la Plaine", location: "Prunelli-di-Fiumorbo", uai: "7200719U" },
    { name: "Lycée de la Plaine de l'Ain", location: "Ambérieu-en-Bugey", uai: "0011194T" },
    { name: "Lycée de La Salle", location: "Metz", uai: "0570311D" },
    { name: "Lycée de la Vallée du Cailly", location: "Déville-lès-Rouen", uai: "0762879S" },
    { name: "Lycée de Mauriac", location: "Mauriac", uai: "0150747F" },
    { name: "Lycée de Mirepoix", location: "Mirepoix", uai: "0090013R" },
    { name: "Lycée de Navarre", location: "Saint-Jean-Pied-de-Port", uai: "0641844G" },
    { name: "Lycée de Petite-Terre", location: "Pamandzi", uai: "9760229V" },
    { name: "Lycée de Provence", location: "Marseille 8e", uai: "0131323T" },
    { name: "Lycée de Sada", location: "Sada", uai: "9760182U" },
    { name: "Lycée de Tour Sainte", location: "Marseille 14e", uai: "0131327X" },
    { name: "Lycée de Villaroy", location: "Guyancourt", uai: "0781949V" },
    { name: "Lycée de Vincendo", location: "Saint-Joseph", uai: "9741230U" },
    { name: "Lycée des Andaines", location: "La Ferté Macé", uai: "0610018N" },
    { name: "Lycée des Arènes", location: "Toulouse", uai: "0312267W" },
    { name: "Lycée des Chassagnes", location: "Oullins", uai: "0690542P" },
    { name: "Lycée des Droits de l'Homme", location: "Petit-Bourg", uai: "9710882G" },
    { name: "Lycée des Flandres", location: "Hazebrouck", uai: "0590101P" },
    { name: "Lycée des Glières", location: "Annemasse", uai: "0740009H" },
    { name: "Lycée des Graves", location: "Gradignan", uai: "0332846P" },
    { name: "Lycée des Horizons", location: "Chazelles-sur-Lyon", uai: "0422284E" },
    { name: "Lycée des lumières", location: "Mamoudzou", uai: "9760370Y" },
    { name: "Lycée des Nerviens", location: "Bavay", uai: "0590024F" },
    { name: "Lycée des Petits Champs", location: "Paris 19e", uai: "0753815P" },
    { name: "Lycée du Bois d'Amour", location: "Poitiers", uai: "0861228T" },
    { name: "Lycée du Bugey", location: "Belley", uai: "0010010F" },
    { name: "Lycée du Cheylard", location: "Le Cheylard", uai: "0071397F" },
    { name: "Lycée du Coudon", location: "La Garde", uai: "0831407D" },
    { name: "Lycée du Couserans", location: "Saint-Girons", uai: "0090018W" },
    { name: "Lycée du Dauphiné", location: "Romans-sur-Isère", uai: "0260023J" },
    { name: "Lycée du Diois", location: "Die", uai: "0260008T" },
    { name: "Lycée du Forez", location: "Feurs", uai: "0421788R" },
    { name: "Lycée du Golfe de Saint-Tropez", location: "Gassin", uai: "0831242Z" },
    { name: "Lycée du Granier", location: "La Ravoire", uai: "0731392S" },
    { name: "Lycée du Grésivaudan", location: "Meylan", uai: "0382863F" },
    { name: "Lycée du Hainaut", location: "Valenciennes", uai: "0590223X" },
    { name: "Lycée du Haut Val de Sèvre", location: "Saint-Maixent-l'École", uai: "0790031E" },
    { name: "Lycée du Léon", location: "Landivisiau", uai: "0292047T" },
    { name: "Lycée du Noordover", location: "Grande-Synthe", uai: "0595616J" },
    { name: "Lycée du Nord", location: "Acoua", uai: "9760270P" },
    { name: "Lycée du Parc", location: "Lyon 6e", uai: "0690026D" },
    { name: "Lycée du Parc Impérial", location: "Nice", uai: "0060029Z" },
    { name: "Lycée du Pays d'Aunis", location: "Surgères", uai: "0170070J" },
    { name: "Lycée du Pays de Condé", location: "Condé-sur-l'Escaut", uai: "0596854E" },
    { name: "Lycée du Pays de Soule", location: "Chéraute", uai: "0641779L" },
    { name: "Lycée du Val d'Argens", location: "Le Muy", uai: "0831646N" },
    { name: "Lycée du Val-de-Saône", location: "Trévoux", uai: "0011276G" },
    { name: "Lycée du Vimeu", location: "Friville-Escarbotin", uai: "0801864S" },
    { name: "Lycée en Forêt", location: "Montargis", uai: "0450040Z" },
    { name: "Lycée européen", location: "Villers-Cotterêts", uai: "0021946E" },
    { name: "Lycée franco-allemand", location: "Buc", uai: "0783548H" },
    { name: "Lycée international de Ferney-Voltaire", location: "Ferney-Voltaire", uai: "0010072Y" },
    { name: "Lycée international de l'est parisien", location: "Noisy-le-Grand", uai: "0932638M" },
    { name: "Lycée international de Lyon", location: "Lyon 7e", uai: "0693446W" },
    { name: "Lycée international de Saint-Germain-en-Laye", location: "Saint-Germain-en-Laye", uai: "0783549J" },
    { name: "Lycée international de Valbonne", location: "Valbonne", uai: "0061642C" },
    { name: "Lycée international des Pontonniers", location: "Strasbourg", uai: "0670081Z" },
    { name: "Lycée international Europole", location: "Grenoble", uai: "0383242T" },
    { name: "Lycée militaire d'Aix-en-Provence", location: "Aix-en-Provence", uai: "0131596P" },
    { name: "Lycée militaire d'Autun", location: "Autun", uai: "0711064W" },
    { name: "Lycée militaire de Saint-Cyr", location: "Saint-Cyr-l'École", uai: "0780015T" },
    { name: "Lycée pilote innovant international", location: "Jaunay-Marigny", uai: "0861223M" },
    { name: "Lycée Valéry Giscard d'Estaing", location: "Chamalières", uai: "0631669X" },
    { name: "Lycée épiscopal de Zillisheim", location: "Zillisheim", uai: "0680141J" },
    { name: "Lætitia Bonaparte", location: "Ajaccio", uai: "6200002H" },
    { name: "Légion d'honneur", location: "Saint-Denis", uai: "0930863H" },
    { name: "Léo Ferré", location: "Gourdon", uai: "0460013P" },
    { name: "Léo Lagrange", location: "Bully-les-Mines", uai: "0620059C" },
    { name: "Léon Blum", location: "Villefranche-de-Lauragais", uai: "0312915A" },
    { name: "Léon Blum", location: "Le Creusot", uai: "0710026T" },
    { name: "Léon Blum", location: "Créteil", uai: "0941413A" },
    { name: "Léon-Gontran Damas", location: "Remire-Montjoly", uai: "9730196A" },
    { name: "Léonard de Vinci", location: "Antibes", uai: "0061478Z" },
    { name: "Léonard de Vinci", location: "Montaigu", uai: "0851390Z" },
    { name: "Léonard de Vinci", location: "Monistrol-sur-Loire", uai: "0430947W" },
    { name: "Léonard de Vinci", location: "Villefontaine", uai: "0382440W" },
    { name: "Léonard de Vinci", location: "Calais", uai: "0624141P" },
    { name: "Léonard de Vinci", location: "Amboise", uai: "0370001A" },
    { name: "Léonard de Vinci", location: "Levallois-Perret", uai: "0921230M" },
    { name: "Léonard de Vinci", location: "Saint-Witz", uai: "0951753P" },
    { name: "Léonard de Vinci", location: "Tremblay-en-France", uai: "0932046U" },
    { name: "Léonard de Vinci", location: "Saint-Germain-en-Laye", uai: "0782556E" },
    { name: "Léonard de Vinci", location: "Soissons", uai: "0021476U" },
    { name: "Léonard de Vinci", location: "Saint-Michel-sur-Orge", uai: "0911946W" },
    { name: "Léonard de Vinci", location: "Melun", uai: "0770934X" },
    { name: "Léonard Limosin", location: "Limoges", uai: "0870016V" },
    { name: "Léonce Vieljeux", location: "La Rochelle", uai: "0170029P" },
    { name: "Léopold Elfort", location: "Mana", uai: "9730421V" },
    { name: "Léopold Sédar Senghor", location: "Évreux", uai: "0271579V" },
    { name: "Léopold Sédar Senghor", location: "Magnanville", uai: "0781951X" },
    { name: "Mabillon", location: "Sedan", uai: "0080081V" },
    { name: "Madame de Staël", location: "Saint-Julien-en-Genevois", uai: "0740037N" },
    { name: "Madame de Staël", location: "Montluçon", uai: "0030025L" },
    { name: "Madeleine Daniélou", location: "Rueil-Malmaison", uai: "0921365J" },
    { name: "Madeleine Michelis", location: "Amiens", uai: "0800010B" },
    { name: "Mahatma Gandhi", location: "Saint-André", uai: "9741324W" },
    { name: "Maine de Biran", location: "Bergerac", uai: "0240005A" },
    { name: "Maintenon", location: "Hyères", uai: "0830101J" },
    { name: "Maison Blanche", location: "Saint-Paul", uai: "9741556Y" },
    { name: "Malherbe", location: "Caen", uai: "0140013N" },
    { name: "Marc Bloch", location: "Sérignan", uai: "0342091N" },
    { name: "Marc Bloch", location: "Bischheim", uai: "0672604S" },
    { name: "Marc Bloch", location: "Val-de-Reuil", uai: "0271582Y" },
    { name: "Marc Chagall", location: "Reims", uai: "0511926S" },
    { name: "Marceau", location: "Chartres", uai: "0280007F" },
    { name: "Marcel Cachin", location: "Saint-Ouen-sur-Seine", uai: "0932074Z" },
    { name: "Marcel Callo", location: "Redon", uai: "0350791V" },
    { name: "Marcel Dassault", location: "Rochefort", uai: "0171455P" },
    { name: "Marcel Gambier", location: "Lisieux", uai: "0140061R" },
    { name: "Marcel Gimond", location: "Aubenas", uai: "0070003R" },
    { name: "Marcel Pagnol", location: "Marseille 10e", uai: "0130037V" },
    { name: "Marcel Pagnol", location: "Athis-Mons", uai: "0910623H" },
    { name: "Marcel Rudloff", location: "Strasbourg", uai: "0672806L" },
    { name: "Marcel Sembat", location: "Sotteville-lès-Rouen", uai: "0760110G" },
    { name: "Marcel Sembat", location: "Vénissieux", uai: "0690104N" },
    { name: "Marcelin Berthelot", location: "Saint-Maur-des-Fossés", uai: "0940120V" },
    { name: "Marcelin Berthelot", location: "Toulouse", uai: "0310039Z" },
    { name: "Marcelin Berthelot", location: "Châtellerault", uai: "0860003L" },
    { name: "Marcelin Berthelot", location: "Questembert", uai: "0561641E" },
    { name: "Marcelin Berthelot", location: "Pantin", uai: "0930124E" },
    { name: "Marcq Institution", location: "Marcq-en-Barœul", uai: "0593225K" },
    { name: "Marguerite de Flandre", location: "Gondecourt", uai: "0590086Y" },
    { name: "Marguerite de Navarre", location: "Bourges", uai: "0180006J" },
    { name: "Marguerite de Valois", location: "Angoulême", uai: "0160003S" },
    { name: "Marguerite Filhol", location: "Fumel", uai: "0470018P" },
    { name: "Marguerite Yourcenar", location: "Erstein", uai: "0672677W" },
    { name: "Marguerite Yourcenar", location: "Beuvry", uai: "0623902E" },
    { name: "Marguerite Yourcenar", location: "Le Mans", uai: "0721493G" },
    { name: "Marguerite Yourcenar", location: "Morangis", uai: "0911945V" },
    { name: "Marie Curie", location: "Sceaux", uai: "0920146J" },
    { name: "Marie Curie", location: "Nogent-sur-Oise", uai: "0600020W" },
    { name: "Marie Curie", location: "Vire Normandie", uai: "0141555P" },
    { name: "Marie Curie", location: "Tarbes", uai: "0650026A" },
    { name: "Marie Curie", location: "Échirolles", uai: "0382920T" },
    { name: "Marie Curie", location: "Strasbourg", uai: "0670083B" },
    { name: "Marie Curie", location: "Marseille 5e", uai: "0130051K" },
    { name: "Marie Curie", location: "Versailles", uai: "0782567S" },
    { name: "Marie Curie", location: "Saint-Benoît", uai: "9741231V" },
    { name: "Marie de Champagne", location: "Troyes", uai: "0100023W" },
    { name: "Marie Joseph", location: "Trouville-sur-Mer", uai: "0141172Y" },
    { name: "Marie Laurencin", location: "Mennecy", uai: "0911962N" },
    { name: "Marie Pila", location: "Carpentras", uai: "0840078D" },
    { name: "Marie Reynoard", location: "Villard-Bonnot", uai: "0383263R" },
    { name: "Marie Rivier", location: "Bourg-Saint-Andéol", uai: "0070066J" },
    { name: "Marie-Louise Dissard Françoise", location: "Tournefeuille", uai: "0312746S" },
    { name: "Marie-Madeleine Fourcade", location: "Gardanne", uai: "0133244F" },
    { name: "Mariette", location: "Boulogne-sur-Mer", uai: "0622949U" },
    { name: "Marlioz", location: "Aix-les-Bains", uai: "0730003G" },
    { name: "Marmoutier", location: "Tours", uai: "0370881G" },
    { name: "Marseilleveyre", location: "Marseille 8e", uai: "0130038W" },
    { name: "Martin Luther King", location: "Bussy-Saint-Georges", uai: "0772292Y" },
    { name: "Martin Malvy", location: "Cazères", uai: "0312995M" },
    { name: "Marx Dormoy", location: "Champigny-sur-Marne", uai: "0941951K" },
    { name: "Maryse Condé", location: "Sarcelles", uai: "0950947N" },
    { name: "Maréchal Lannes", location: "Lectoure", uai: "0320015T" },
    { name: "Maréchal Soult", location: "Mazamet", uai: "0810033W" },
    { name: "Masaryk", location: "Vouziers", uai: "0080053P" },
    { name: "Massillon", location: "Clermont-Ferrand", uai: "0631847R" },
    { name: "Massillon", location: "Paris 4e", uai: "0753824Z" },
    { name: "Masséna", location: "Nice", uai: "0060030A" },
    { name: "Mathias", location: "Chalon-sur-Saône", uai: "0710010A" },
    { name: "Maupertuis", location: "Saint-Malo", uai: "0350042F" },
    { name: "Maurice Eliot", location: "Épinay-sous-Sénart", uai: "0911927A" },
    { name: "Maurice Genevoix", location: "Ingré", uai: "0451483T" },
    { name: "Maurice Genevoix", location: "Bressuire", uai: "0790007D" },
    { name: "Maurice Genevoix", location: "Marignane", uai: "0132410Z" },
    { name: "Maurice Genevoix", location: "Decize", uai: "0580761M" },
    { name: "Maurice Genevoix", location: "Montrouge", uai: "0921399W" },
    { name: "Maurice Janetti", location: "Saint-Maximin-la-Sainte-Baume", uai: "0831559U" },
    { name: "Maurice Merleau-Ponty", location: "Rochefort", uai: "0170022G" },
    { name: "Maurice Ravel", location: "Saint-Jean-de-Luz", uai: "0640065Y" },
    { name: "Maurice Ravel", location: "Paris 20e", uai: "0750715V" },
    { name: "Maurice Rondeau", location: "Bussy-Saint-Georges", uai: "0772275E" },
    { name: "Maurice Utrillo", location: "Stains", uai: "0932030B" },
    { name: "Max Linder", location: "Libourne", uai: "0330088S" },
    { name: "Maxence Van Der Meersch", location: "Roubaix", uai: "0590181B" },
    { name: "Maximilien Perret", location: "Alfortville", uai: "0940126B" },
    { name: "Maîtrise de Massabielle", location: "Pointe-à-Pitre", uai: "9710054G" },
    { name: "Melkior et Garré", location: "Cayenne", uai: "9730309Y" },
    { name: "Merkaz Hatorah (filles)", location: "Le Raincy", uai: "0932303Y" },
    { name: "Michel de Montaigne", location: "Mulhouse", uai: "0680032R" },
    { name: "Michel Montaigne", location: "Bordeaux", uai: "0330021U" },
    { name: "Michel-Ange", location: "Villeneuve-la-Garenne", uai: "0921594H" },
    { name: "Michelet", location: "Vanves", uai: "0920149M" },
    { name: "Michelet", location: "Lannemezan", uai: "0650012K" },
    { name: "Mireille Grenet", location: "Compiègne", uai: "0600015R" },
    { name: "Molière", location: "Paris 16e", uai: "0750703G" },
    { name: "Monge", location: "Chambéry", uai: "0730016W" },
    { name: "Monge", location: "Charleville-Mézières", uai: "0080027L" },
    { name: "Monnet Mermoz", location: "Aurillac", uai: "0150006A" },
    { name: "Mont Saint-Jean", location: "Antibes", uai: "0060668U" },
    { name: "Mont-Blanc René Dayve", location: "Passy", uai: "0740027C" },
    { name: "Montaigne", location: "Paris 6e", uai: "0750657G" },
    { name: "Montalembert", location: "Nogent-sur-Marne", uai: "0940881X" },
    { name: "Montalembert", location: "Courbevoie", uai: "0921484N" },
    { name: "Montalembert", location: "Doullens", uai: "0801743K" },
    { name: "Montchapet", location: "Dijon", uai: "0210017E" },
    { name: "Montdory", location: "Thiers", uai: "0630068G" },
    { name: "Monte-Cristo", location: "Allauch", uai: "0134253C" },
    { name: "Montebello", location: "Lille", uai: "0595867G" },
    { name: "Montesquieu", location: "Le Mans", uai: "0720029R" },
    { name: "Montesquieu", location: "Libourne", uai: "0331506H" },
    { name: "Montesquieu", location: "Bordeaux", uai: "0330022V" },
    { name: "Montesquieu", location: "Herblay-sur-Seine", uai: "0951723G" },
    { name: "Montesquieu", location: "Le Plessis-Robinson", uai: "0922249V" },
    { name: "Montgrand", location: "Marseille 6e", uai: "0130042A" },
    { name: "Montgérald", location: "Le Marin", uai: "9720727H" },
    { name: "Montmajour", location: "Arles", uai: "0130010R" },
    { name: "Moulin Joli", location: "La Possession", uai: "9741173G" },
    { name: "Murat", location: "Issoire", uai: "0630034V" },
    { name: "Mémona Hintermann-Afféjee", location: "Saint-Denis", uai: "9741620T" },
    { name: "Mézeray Gabriel", location: "Argentan", uai: "0610006A" },
    { name: "N'R Hatorah", location: "Paris 19e", uai: "0755352K" },
    { name: "Napoléon", location: "L'Aigle", uai: "0610026X" },
    { name: "Nazareth Haffreingue", location: "Boulogne-sur-Mer", uai: "0622107D" },
    { name: "Nelson Mandela", location: "Nantes", uai: "0442765S" },
    { name: "Nelson Mandela", location: "Pibrac", uai: "0312938A" },
    { name: "Nelson Mandela", location: "Poitiers", uai: "0860037Y" },
    { name: "Nelson Mandela", location: "Marseille 12e", uai: "0134003F" },
    { name: "Nevers", location: "Montpellier", uai: "0340991T" },
    { name: "Nicolas Appert", location: "Orvault", uai: "0442094M" },
    { name: "Nicolas Brémontier", location: "Bordeaux", uai: "0330029C" },
    { name: "Nicolas-Joseph Cugnot", location: "Neuilly-sur-Marne", uai: "0932291K" },
    { name: "Nikola Tesla", location: "Dourdan", uai: "0912364A" },
    { name: "Niépce Balleure", location: "Chalon-sur-Saône", uai: "0710012C" },
    { name: "Nord Atlantique", location: "Sainte-Marie", uai: "9720692V" },
    { name: "Nord Bassin Simone Veil", location: "Andernos-les-Bains", uai: "0332724G" },
    { name: "Nord Caraïbe", location: "Bellefontaine", uai: "9720823M" },
    { name: "Nord Grande-Terre", location: "Port-Louis", uai: "9711082Z" },
    { name: "Notre-Dame", location: "Meudon", uai: "0920917X" },
    { name: "Notre-Dame", location: "Bourg-la-Reine", uai: "0920906K" },
    { name: "Notre-Dame", location: "Boulogne-Billancourt", uai: "0920897A" },
    { name: "Notre-Dame", location: "Strasbourg", uai: "0671610L" },
    { name: "Notre-Dame", location: "Valenciennes", uai: "0593141U" },
    { name: "Notre-Dame", location: "Saint-Germain-en-Laye", uai: "0783288A" },
    { name: "Notre-Dame", location: "Sannois", uai: "0950785M" },
    { name: "Notre-Dame", location: "Toulon", uai: "0830100H" },
    { name: "Notre-Dame", location: "Chartres", uai: "0280665W" },
    { name: "Notre-Dame", location: "Dijon", uai: "0211074D" },
    { name: "Notre-Dame", location: "Rezé", uai: "0440274J" },
    { name: "Notre-Dame", location: "Charlieu", uai: "0421025L" },
    { name: "Notre-Dame", location: "Mende", uai: "0480025S" },
    { name: "Notre-Dame", location: "Pamiers", uai: "0090036R" },
    { name: "Notre-Dame", location: "Le Mans", uai: "0720837U" },
    { name: "Notre-Dame", location: "Guingamp", uai: "0220097L" },
    { name: "Notre-Dame", location: "Mantes-la-Jolie", uai: "0783289B" },
    { name: "Notre-Dame", location: "Challans", uai: "0850142T" },
    { name: "Notre-Dame", location: "Nevers", uai: "0580071M" },
    { name: "Notre-Dame", location: "Chemillé-en-Anjou", uai: "0490837U" },
    { name: "Notre-Dame", location: "Valence", uai: "0260069J" },
    { name: "Notre-Dame", location: "Fontenay-le-Comte", uai: "0851344Z" },
    { name: "Notre-Dame", location: "Paris 13e", uai: "0754924V" },
    { name: "Notre-Dame", location: "Bordeaux", uai: "0331499A" },
    { name: "Notre-Dame", location: "Castres", uai: "0810103X" },
    { name: "Notre-Dame", location: "La Flèche", uai: "0720833P" },
    { name: "Notre-Dame d'Espérance", location: "Saint-Nazaire", uai: "0440178E" },
    { name: "Notre-Dame d'Orveau", location: "Segré-en-Anjou Bleu", uai: "0490834R" },
    { name: "Notre-Dame de Bel Air", location: "Tarare", uai: "0693267B" },
    { name: "Notre-Dame de Bellegarde", location: "Neuville-sur-Saône", uai: "0690540M" },
    { name: "Notre-Dame de Bury", location: "Margency", uai: "0950759J" },
    { name: "Notre-Dame de Campostal", location: "Rostrenen", uai: "0220107X" },
    { name: "Notre-Dame de France", location: "Paris 13e", uai: "0753902J" },
    { name: "Notre-Dame de France", location: "Marseille 6e", uai: "0131333D" },
    { name: "Notre-Dame de Grâce", location: "Maubeuge", uai: "0592916Z" },
    { name: "Notre-Dame de Kerbertrand", location: "Quimperlé", uai: "0290174G" },
    { name: "Notre-Dame de la Compassion", location: "Pontoise", uai: "0950761L" },
    { name: "Notre-Dame de la Compassion", location: "Marmande", uai: "0470063N" },
    { name: "Notre-Dame de la Galaure", location: "Châteauneuf-de-Galaure", uai: "0260063C" },
    { name: "Notre-Dame de la Merci", location: "Montpellier", uai: "0340881Y" },
    { name: "Notre-Dame de la Paix", location: "Lille", uai: "0593109J" },
    { name: "Notre-Dame de la Providence", location: "Vincennes", uai: "0940877T" },
    { name: "Notre-Dame de la Providence", location: "Thionville", uai: "0570313F" },
    { name: "Notre-Dame de la Providence", location: "Orchies", uai: "0593040J" },
    { name: "Notre-Dame de la Providence", location: "Avranches", uai: "0500112A" },
    { name: "Notre-Dame de la Tourtelière", location: "Pouzauges", uai: "0850079Z" },
    { name: "Notre-Dame de la Viste", location: "Marseille 15e", uai: "0133931C" },
    { name: "Notre-Dame de Mongré", location: "Villefranche-sur-Saône", uai: "0690552A" },
    { name: "Notre-Dame de Recouvrance", location: "Saintes", uai: "0170103V" },
    { name: "Notre-Dame de Sainte-Croix", location: "Neuilly-sur-Seine", uai: "0920919Z" },
    { name: "Notre-Dame de Sion", location: "Paris 6e", uai: "0753842U" },
    { name: "Notre-Dame de Sion", location: "Saint-Omer", uai: "0622120T" },
    { name: "Notre-Dame de Sion", location: "Marseille 6e", uai: "0131341M" },
    { name: "Notre-Dame de Sion", location: "Évry-Courcouronnes", uai: "0912117G" },
    { name: "Notre-Dame de Toutes Aides", location: "Nantes", uai: "0440166S" },
    { name: "Notre-Dame des Anges", location: "Saint-Amand-les-Eaux", uai: "0592913W" },
    { name: "Notre-Dame des Aydes", location: "Blois", uai: "0410675L" },
    { name: "Notre-Dame des Dunes", location: "Dunkerque", uai: "0593102B" },
    { name: "Notre-Dame des Minimes", location: "Lyon 5e", uai: "0690519P" },
    { name: "Notre-Dame des Missions", location: "Charenton-le-Pont", uai: "0941877E" },
    { name: "Notre-Dame des Oiseaux", location: "Paris 16e", uai: "0753946G" },
    { name: "Notre-Dame des Victoires", location: "Voiron", uai: "0381683Y" },
    { name: "Notre-Dame du Bon Secours", location: "Perpignan", uai: "0660059F" },
    { name: "Notre-Dame du Château", location: "Monistrol-sur-Loire", uai: "0430058E" },
    { name: "Notre-Dame du Grandchamp", location: "Versailles", uai: "0783351U" },
    { name: "Notre-Dame du Kreisker", location: "Saint-Pol-de-Léon", uai: "0290177K" },
    { name: "Notre-Dame du Mur", location: "Morlaix", uai: "0290164W" },
    { name: "Notre-Dame du Roc", location: "La Roche-sur-Yon", uai: "0850130E" },
    { name: "Notre-Dame du Vœu", location: "Hennebont", uai: "0560098C" },
    { name: "Notre-Dame La Riche", location: "Tours", uai: "0370728R" },
    { name: "Notre-Dame le Ménimur", location: "Vannes", uai: "0560200N" },
    { name: "Notre-Dame Les Oiseaux", location: "Verneuil-sur-Seine", uai: "0783344L" },
    { name: "Notre-Dame Providence", location: "Enghien-les-Bains", uai: "0950753C" },
    { name: "Notre-Dame Saint-François", location: "Évreux", uai: "0271070S" },
    { name: "Notre-Dame Saint-Joseph", location: "Épinal", uai: "0880101G" },
    { name: "Notre-Dame Saint-Sigisbert", location: "Nancy", uai: "0541318P" },
    { name: "Notre-Dame Saint-Victor", location: "Épernay", uai: "0511135G" },
    { name: "Odilon Redon", location: "Pauillac", uai: "0332081H" },
    { name: "Olympe de Gouges", location: "Montech", uai: "0820917B" },
    { name: "Olympe de Gouges", location: "Noisy-le-Sec", uai: "0930123D" },
    { name: "Oratoire Sainte-Marie", location: "Auch", uai: "0320051G" },
    { name: "ORT", location: "Villiers-le-Bel", uai: "0951994B" },
    { name: "Oudinot", location: "Chaumont", uai: "0520685M" },
    { name: "Ozanam", location: "Lille", uai: "0593007Y" },
    { name: "Ozar Hatorah", location: "Paris 11e", uai: "0754479L" },
    { name: "Ozar Hatorah (filles)", location: "Créteil", uai: "0941711Z" },
    { name: "Ozar Hatorah (garçons)", location: "Créteil", uai: "0941943B" },
    { name: "Ozenne", location: "Toulouse", uai: "0310047H" },
    { name: "Pablo Neruda", location: "Dieppe", uai: "0762169V" },
    { name: "Pablo Neruda", location: "Saint-Martin-d'Hères", uai: "0382203N" },
    { name: "Pablo Picasso", location: "Perpignan", uai: "0660014G" },
    { name: "Pablo Picasso", location: "Fontenay-sous-Bois", uai: "0941347D" },
    { name: "Pablo Picasso", location: "Avion", uai: "0620027T" },
    { name: "Pape Clément", location: "Pessac", uai: "0332722E" },
    { name: "Parc Chabrières", location: "Oullins", uai: "0690074F" },
    { name: "Parc de Vilgénis", location: "Massy", uai: "0910727W" },
    { name: "Parc des Chaumes", location: "Avallon", uai: "0890008A" },
    { name: "Parc des Loges", location: "Évry-Courcouronnes", uai: "0911251R" },
    { name: "Pardailhan", location: "Auch", uai: "0320002D" },
    { name: "Pascal", location: "Paris 16e", uai: "0753919C" },
    { name: "Pascal Paoli", location: "Corte", uai: "7200021K" },
    { name: "Passy Saint-Honoré", location: "Paris 16e", uai: "0754666P" },
    { name: "Pasteur", location: "Le Blanc", uai: "0360005K" },
    { name: "Pasteur", location: "Besançon", uai: "0250008Y" },
    { name: "Pasteur Mont Roland", location: "Dole", uai: "0391146J" },
    { name: "Pastré Grande Bastide", location: "Marseille 9e", uai: "0131456M" },
    { name: "Paul Arène", location: "Sisteron", uai: "0040023D" },
    { name: "Paul Bert", location: "Paris 14e", uai: "0750689S" },
    { name: "Paul Claudel", location: "Laon", uai: "0020032Z" },
    { name: "Paul Claudel d'Hulst", location: "Paris 7e", uai: "0753809H" },
    { name: "Paul Constans", location: "Montluçon", uai: "0030026M" },
    { name: "Paul Cézanne", location: "Aix-en-Provence", uai: "0130002G" },
    { name: "Paul Doumer", location: "Le Perreux-sur-Marne", uai: "0940119U" },
    { name: "Paul Duez", location: "Cambrai", uai: "0590034S" },
    { name: "Paul Eluard", location: "Saint-Junien", uai: "0870040W" },
    { name: "Paul Eluard", location: "Saint-Denis", uai: "0930125F" },
    { name: "Paul Emile Victor", location: "Osny", uai: "0951937P" },
    { name: "Paul Guérin", location: "Niort", uai: "0790024X" },
    { name: "Paul Hazard", location: "Armentières", uai: "0590010R" },
    { name: "Paul Héroult", location: "Saint-Jean-de-Maurienne", uai: "0730037U" },
    { name: "Paul Langevin", location: "Martigues", uai: "0130143K" },
    { name: "Paul Langevin", location: "La Seyne-sur-Mer", uai: "0830923C" },
    { name: "Paul Langevin", location: "Beauvais", uai: "0600002B" },
    { name: "Paul Langevin", location: "Suresnes", uai: "0920147K" },
    { name: "Paul Lapie", location: "Courbevoie", uai: "0920138A" },
    { name: "Paul Le Rolland", location: "Drancy", uai: "0932229T" },
    { name: "Paul Mathou", location: "Gourdan-Polignan", uai: "0310017A" },
    { name: "Paul Moreau", location: "Bras-Panon", uai: "9741051Z" },
    { name: "Paul Mélizan", location: "Marseille 11e", uai: "0131328Y" },
    { name: "Paul Painlevé", location: "Oyonnax", uai: "0010034G" },
    { name: "Paul Rey", location: "Nay", uai: "0640046C" },
    { name: "Paul Ricoeur", location: "Louvres", uai: "0952158E" },
    { name: "Paul Robert", location: "Les Lilas", uai: "0932073Y" },
    { name: "Paul Sabatier", location: "Carcassonne", uai: "0110004V" },
    { name: "Paul Scarron", location: "Sillé-le-Guillaume", uai: "0720055U" },
    { name: "Paul Sérusier", location: "Carhaix-Plouguer", uai: "0290022S" },
    { name: "Paul Valéry", location: "Sète", uai: "0340075X" },
    { name: "Paul Valéry", location: "Paris 12e", uai: "0750679F" },
    { name: "Paul Vergès", location: "Saint-Paul", uai: "9741380G" },
    { name: "Paul Verlaine", location: "Rethel", uai: "0080039Z" },
    { name: "Paul Vincensini", location: "Bastia", uai: "7200583W" },
    { name: "Paul-Emile Victor", location: "Champagnole", uai: "0391092A" },
    { name: "Paul-Louis Courier", location: "Tours", uai: "0370039S" },
    { name: "Paule Pignolet de Fresne Rivière", location: "Les Trois-Bassins", uai: "9741186W" },
    { name: "Paulette Nardal", location: "Ducos", uai: "9720825P" },
    { name: "Pauline Roland", location: "Chevilly-Larue", uai: "0942269F" },
    { name: "Pays de Retz", location: "Pornic", uai: "0441992B" },
    { name: "Perseigne", location: "Mamers", uai: "0720027N" },
    { name: "Petit Val", location: "Sucy-en-Brie", uai: "0940891H" },
    { name: "Peyramale Saint-Joseph", location: "Lourdes", uai: "0650058K" },
    { name: "Philibert Delorme", location: "L'Isle-d'Abeau", uai: "0382895R" },
    { name: "Philibert Dessaignes", location: "Blois", uai: "0410002E" },
    { name: "Philippe Cousteau", location: "Saint-André-de-Cubzac", uai: "0332835C" },
    { name: "Philippe de Girard", location: "Avignon", uai: "0840005Z" },
    { name: "Philippe Lamour", location: "Nîmes", uai: "0301552Z" },
    { name: "Philippe Lebon", location: "Joinville", uai: "0520019N" },
    { name: "Philippine Duchesne", location: "La Tronche", uai: "0383208F" },
    { name: "Pierre Bayen", location: "Châlons-en-Champagne", uai: "0510006E" },
    { name: "Pierre Bayle", location: "Sedan", uai: "0080045F" },
    { name: "Pierre Bourdan", location: "Guéret", uai: "0230020X" },
    { name: "Pierre Bourdieu", location: "Fronton", uai: "0312696M" },
    { name: "Pierre Brossolette", location: "Villeurbanne", uai: "0690132U" },
    { name: "Pierre Béghin", location: "Moirans", uai: "0383119J" },
    { name: "Pierre Caraminot", location: "Égletons", uai: "0190018S" },
    { name: "Pierre Corneille", location: "Rouen", uai: "0760090K" },
    { name: "Pierre Corneille", location: "La Celle-Saint-Cloud", uai: "0782822U" },
    { name: "Pierre d'Ailly", location: "Compiègne", uai: "0600014P" },
    { name: "Pierre d'Aragon", location: "Muret", uai: "0310024H" },
    { name: "Pierre de Coubertin", location: "Calais", uai: "0620062F" },
    { name: "Pierre de Coubertin", location: "Bolbec", uai: "0762920L" },
    { name: "Pierre de Coubertin", location: "Font-Romeu-Odeillo-Via", uai: "0660057D" },
    { name: "Pierre de Coubertin", location: "Meaux", uai: "0770931U" },
    { name: "Pierre de Fermat", location: "Toulouse", uai: "0310036W" },
    { name: "Pierre de la Ramée", location: "Saint-Quentin", uai: "0020049T" },
    { name: "Pierre du Terrail", location: "Pontcharra", uai: "0382270L" },
    { name: "Pierre et Marie Curie", location: "Neufchâteau", uai: "0880040R" },
    { name: "Pierre et Marie Curie", location: "Châteauroux", uai: "0360009P" },
    { name: "Pierre et Marie Curie", location: "Menton", uai: "0060026W" },
    { name: "Pierre Forest", location: "Maubeuge", uai: "0590149S" },
    { name: "Pierre Guéguin", location: "Concarneau", uai: "0290030A" },
    { name: "Pierre Lagourgue", location: "Le Tampon", uai: "9741263E" },
    { name: "Pierre Larousse", location: "Toucy", uai: "0891168L" },
    { name: "Pierre Marie Théas", location: "Montauban", uai: "0820044C" },
    { name: "Pierre Mendès France", location: "Rennes", uai: "0350030T" },
    { name: "Pierre Mendès France", location: "Péronne", uai: "0800046R" },
    { name: "Pierre Mendès France", location: "La Roche-sur-Yon", uai: "0850025R" },
    { name: "Pierre Mendès France", location: "Vic-en-Bigorre", uai: "0650038N" },
    { name: "Pierre Mendès France", location: "Épinal", uai: "0880021V" },
    { name: "Pierre Mendès France", location: "Vitrolles", uai: "0133015G" },
    { name: "Pierre Mendès France", location: "Savigny-le-Temple", uai: "0772188K" },
    { name: "Pierre Méchain", location: "Laon", uai: "0020034B" },
    { name: "Pierre Poivre", location: "Saint-Joseph", uai: "9740952S" },
    { name: "Pierre Termier", location: "Grenoble", uai: "0381666E" },
    { name: "Pierre Termier", location: "Lyon 8e", uai: "0690538K" },
    { name: "Pierre-Emile Martin", location: "Bourges", uai: "0180008L" },
    { name: "Pierre-Gilles de Gennes", location: "Digne-les-Bains", uai: "0040490L" },
    { name: "Pierre-Gilles de Gennes", location: "Cosne-Cours-sur-Loire", uai: "0580014A" },
    { name: "Pierre-Gilles de Gennes", location: "Paris 13e", uai: "0750685M" },
    { name: "Pierre-Joël Bonté", location: "Riom", uai: "0631985R" },
    { name: "Pierre-Paul Riquet", location: "Saint-Orens-de-Gameville", uai: "0312289V" },
    { name: "Plaine de Neauphle", location: "Trappes", uai: "0781297L" },
    { name: "Pointe-Noire", location: "Pointe-Noire", uai: "9711033W" },
    { name: "Pontus de Tyard", location: "Chalon-sur-Saône", uai: "0710011B" },
    { name: "Porte de Normandie", location: "Verneuil d'Avre et d'Iton", uai: "0270042Z" },
    { name: "Porte Océane", location: "Le Havre", uai: "0760054W" },
    { name: "Portes de l'Oisans", location: "Vizille", uai: "0380089R" },
    { name: "Pothier", location: "Orléans", uai: "0450049J" },
    { name: "Pravaz", location: "Le Pont-de-Beauvoisin", uai: "0380053B" },
    { name: "Prieur de la Côte-d'Or", location: "Auxonne", uai: "0210003P" },
    { name: "Prytanée national militaire", location: "La Flèche", uai: "0720896H" },
    { name: "Pré de Cordy", location: "Sarlat-la-Canéda", uai: "0240035H" },
    { name: "Pré Saint-Sauveur", location: "Saint-Claude", uai: "0390786T" },
    { name: "Pyrène", location: "Pamiers", uai: "0090015T" },
    { name: "Périer", location: "Marseille 8e", uai: "0130036U" },
    { name: "Rabelais", location: "Meudon", uai: "0920798T" },
    { name: "Rabelais", location: "Saint-Brieuc", uai: "0220056S" },
    { name: "Rabelais", location: "Montpellier", uai: "0341765J" },
    { name: "Racan", location: "Montval-sur-Loir", uai: "0720012X" },
    { name: "Racine", location: "Paris 8e", uai: "0750664P" },
    { name: "Rama", location: "Sainte-Luce", uai: "9720479N" },
    { name: "Rambam", location: "Boulogne-Billancourt", uai: "0920898B" },
    { name: "Raoul Dautry", location: "Limoges", uai: "0870118F" },
    { name: "Raoul Follereau", location: "Belfort", uai: "0900004R" },
    { name: "Raoul Follereau", location: "Nevers", uai: "0580032V" },
    { name: "Raoul Georges Nicolo", location: "Basse-Terre", uai: "9710884J" },
    { name: "Raoul Vadepied", location: "Évron", uai: "0530949U" },
    { name: "Raphaël Elizé", location: "Sablé-sur-Sarthe", uai: "0720048L" },
    { name: "Raymond Loewy", location: "La Souterraine", uai: "0230025C" },
    { name: "Raymond Naves", location: "Toulouse", uai: "0310040A" },
    { name: "Raymond Poincaré", location: "Bar-le-Duc", uai: "0550002D" },
    { name: "Raymond Queneau", location: "Villeneuve-d'Ascq", uai: "0594424N" },
    { name: "Raymond Queneau", location: "Yvetot", uai: "0762880T" },
    { name: "Raymond Savignac", location: "Villefranche-de-Rouergue", uai: "0120031U" },
    { name: "Raymond Tarcy", location: "Saint-Laurent-du-Maroni", uai: "9730513V" },
    { name: "Raynouard", location: "Brignoles", uai: "0830007G" },
    { name: "Rempart Vinci", location: "Marseille 7e", uai: "0130049H" },
    { name: "René Auffray", location: "Clichy", uai: "0922149L" },
    { name: "René Billères", location: "Argelès-Gazost", uai: "0650001Y" },
    { name: "René Cassin", location: "Bayonne", uai: "0640010N" },
    { name: "René Cassin", location: "Tarare", uai: "0690085T" },
    { name: "René Cassin", location: "Arpajon", uai: "0911632E" },
    { name: "René Cassin", location: "Mâcon", uai: "0710048S" },
    { name: "René Cassin", location: "Strasbourg", uai: "0670086E" },
    { name: "René Cassin", location: "Gonesse", uai: "0950646L" },
    { name: "René Cassin", location: "Montfort-sur-Meu", uai: "0352235P" },
    { name: "René Char", location: "Avignon", uai: "0840935K" },
    { name: "René Descartes", location: "Saint-Genis-Laval", uai: "0693654X" },
    { name: "René Descartes", location: "Cournon-d'Auvergne", uai: "0631861F" },
    { name: "René Descartes", location: "Rennes", uai: "0351907H" },
    { name: "René Descartes", location: "Champs-sur-Marne", uai: "0772223Y" },
    { name: "René Goscinny", location: "Drap", uai: "0062089N" },
    { name: "René Gosse", location: "Clermont-l'Hérault", uai: "0340023R" },
    { name: "René Laënnec", location: "Pont-l'Abbé", uai: "0290062K" },
    { name: "René Perrin", location: "Ugine", uai: "0730043A" },
    { name: "René-Josué Valin", location: "La Rochelle", uai: "0170027M" },
    { name: "Rey", location: "Bois-Guillaume", uai: "0761718E" },
    { name: "Ribeaupierre", location: "Ribeauvillé", uai: "0680060W" },
    { name: "Richelieu", location: "Rueil-Malmaison", uai: "0920799U" },
    { name: "Robert de Luzarches", location: "Amiens", uai: "0801841S" },
    { name: "Robert de Mortain", location: "Mortain-Bocage", uai: "0500049G" },
    { name: "Robert Doisneau", location: "Corbeil-Essonnes", uai: "0910620E" },
    { name: "Robert Doisneau", location: "Vaulx-en-Velin", uai: "0693619J" },
    { name: "Robert Garnier", location: "La Ferté-Bernard", uai: "0720017C" },
    { name: "Robert Schuman", location: "Haguenau", uai: "0670020H" },
    { name: "Robert Schuman", location: "Charenton-le-Pont", uai: "0941974K" },
    { name: "Robert Schuman", location: "Metz", uai: "0570057C" },
    { name: "Robert Weinum", location: "Saint-Martin", uai: "9711252J" },
    { name: "Robespierre", location: "Arras", uai: "0620006V" },
    { name: "Robin", location: "Vienne", uai: "0381678T" },
    { name: "Rocroy Saint-Vincent de Paul", location: "Paris 10e", uai: "0753885R" },
    { name: "Rodin", location: "Paris 13e", uai: "0750682J" },
    { name: "Roger Frison-Roche", location: "Chamonix-Mont-Blanc", uai: "0741669M" },
    { name: "Roland Garros", location: "Le Tampon", uai: "9740002J" },
    { name: "Rollinat", location: "Argenton-sur-Creuse", uai: "0360002G" },
    { name: "Romain Rolland", location: "Clamecy", uai: "0580008U" },
    { name: "Romain Rolland", location: "Goussainville", uai: "0950667J" },
    { name: "Romain Rolland", location: "Ivry-sur-Seine", uai: "0940115P" },
    { name: "Ronsard", location: "Vendôme", uai: "0410030K" },
    { name: "Roosevelt", location: "Reims", uai: "0510034K" },
    { name: "Rosa Luxemburg", location: "Canet-en-Roussillon", uai: "0660856X" },
    { name: "Rosa Parks", location: "La Roche-sur-Yon", uai: "0850027T" },
    { name: "Rosa Parks", location: "Neuville-sur-Saône", uai: "0694026B" },
    { name: "Rosa Parks", location: "Montgeron", uai: "0910625K" },
    { name: "Rosa Parks", location: "Thionville", uai: "0572027U" },
    { name: "Rotrou", location: "Dreux", uai: "0280019U" },
    { name: "Roumanille", location: "Nyons", uai: "0260017C" },
    { name: "Rouvière", location: "Toulon", uai: "0831616F" },
    { name: "Réaumur", location: "Laval", uai: "0530012A" },
    { name: "Rémi Belleau", location: "Nogent-le-Rotrou", uai: "0280036M" },
    { name: "Sacré-Coeur", location: "Reims", uai: "0511142P" },
    { name: "Sacré-Coeur", location: "Péronne", uai: "0801226Y" },
    { name: "Sacré-Coeur", location: "Aix-en-Provence", uai: "0131862D" },
    { name: "Sacré-Coeur", location: "Tourcoing", uai: "0593139S" },
    { name: "Sacré-Coeur", location: "Privas", uai: "0070069M" },
    { name: "Sacré-Coeur", location: "Tournon-sur-Rhône", uai: "0070071P" },
    { name: "Sacré-Coeur", location: "Amiens", uai: "0801207C" },
    { name: "Sacré-Coeur", location: "Rouen", uai: "0761719F" },
    { name: "Sacré-Coeur La Salle", location: "Angers", uai: "0492015Z" },
    { name: "Sacré-Cœur", location: "La Ville-du-Bois", uai: "0910824B" },
    { name: "Saint-Adjutor", location: "Vernon", uai: "0271053Y" },
    { name: "Saint-Adrien", location: "Villeneuve-d'Ascq", uai: "0592944E" },
    { name: "Saint-Alyre", location: "Clermont-Ferrand", uai: "0631075B" },
    { name: "Saint-Ambroise", location: "Chambéry", uai: "0730763H" },
    { name: "Saint-André", location: "Niort", uai: "0790078F" },
    { name: "Saint-André", location: "Colmar", uai: "0680149T" },
    { name: "Saint-André", location: "Choisy-le-Roi", uai: "0940885B" },
    { name: "Saint-André Sainte-Marie (STAM)", location: "Saint-André-de-Cubzac", uai: "0333406Y" },
    { name: "Saint-Antoine", location: "Phalsbourg", uai: "0570211V" },
    { name: "Saint-Aspais", location: "Melun", uai: "0771238C" },
    { name: "Saint-Aubin La Salle", location: "Verrières-en-Anjou", uai: "0492420P" },
    { name: "Saint-Augustin", location: "Saint-Germain-en-Laye", uai: "0783657B" },
    { name: "Saint-Barthélémy", location: "Nice", uai: "0062147B" },
    { name: "Saint-Benoist de l'Europe", location: "Bagnolet", uai: "0930936M" },
    { name: "Saint-Benoît", location: "Moulins", uai: "0030084A" },
    { name: "Saint-Benoît (site Collégiale)", location: "Angers", uai: "0490824E" },
    { name: "Saint-Benoît (site Université)", location: "Angers", uai: "0490819Z" },
    { name: "Saint-Bernard", location: "Troyes", uai: "0100047X" },
    { name: "Saint-Blaise", location: "Douarnenez", uai: "0290194D" },
    { name: "Saint-Bénigne", location: "Dijon", uai: "0211090W" },
    { name: "Saint-Caprais", location: "Agen", uai: "0470060K" },
    { name: "Saint-Charles", location: "Orléans", uai: "0450110A" },
    { name: "Saint-Charles", location: "Vienne", uai: "0382854W" },
    { name: "Saint-Charles", location: "Saint-Martin-de-Crau", uai: "0134004G" },
    { name: "Saint-Charles", location: "Marseille 1er", uai: "0130039X" },
    { name: "Saint-Charles", location: "Athis-Mons", uai: "0910808J" },
    { name: "Saint-Charles", location: "Rillieux-la-Pape", uai: "0694197M" },
    { name: "Saint-Charles", location: "Chalon-sur-Saône", uai: "0711896A" },
    { name: "Saint-Charles Camas", location: "Marseille 5e", uai: "0131342N" },
    { name: "Saint-Charles La Providence", location: "Saint-Brieuc", uai: "0220110A" },
    { name: "Saint-Charles Sainte-Croix", location: "Le Mans", uai: "0721684P" },
    { name: "Saint-Coeur", location: "Beaune", uai: "0211068X" },
    { name: "Saint-Cricq", location: "Pau", uai: "0640057P" },
    { name: "Saint-Denis", location: "Annonay", uai: "0071126L" },
    { name: "Saint-Denis", location: "Loches", uai: "0370737A" },
    { name: "Saint-Dominique", location: "Neuilly-sur-Seine", uai: "0920921B" },
    { name: "Saint-Dominique", location: "Pau", uai: "0640131V" },
    { name: "Saint-Dominique", location: "Saint-Herblain", uai: "0440176C" },
    { name: "Saint-Dominique", location: "Mortefontaine", uai: "0602070Z" },
    { name: "Saint-Elme", location: "Arcachon", uai: "0331488N" },
    { name: "Saint-Erembert", location: "Saint-Germain-en-Laye", uai: "0783286Y" },
    { name: "Saint-Esprit", location: "Beauvais", uai: "0601699W" },
    { name: "Saint-Esprit", location: "Landivisiau", uai: "0290198H" },
    { name: "Saint-Etienne", location: "Strasbourg", uai: "0671609K" },
    { name: "Saint-Etienne", location: "Sens", uai: "0890067P" },
    { name: "Saint-Etienne", location: "Cahors", uai: "0460038S" },
    { name: "Saint-Exupéry", location: "Bourg-Saint-Maurice", uai: "0731507S" },
    { name: "Saint-Exupéry", location: "Montigny-le-Bretonneux", uai: "0781974X" },
    { name: "Saint-Exupéry", location: "Blagnac", uai: "0312686B" },
    { name: "Saint-Exupéry", location: "Valserhône", uai: "0010006B" },
    { name: "Saint-Exupéry", location: "Mantes-la-Jolie", uai: "0782539L" },
    { name: "Saint-Exupéry", location: "Saint-Dizier", uai: "0520027X" },
    { name: "Saint-Exupéry", location: "Marseille 15e", uai: "0130048G" },
    { name: "Saint-François", location: "Ville-la-Grand", uai: "0740104L" },
    { name: "Saint-François d'Assise", location: "Montigny-le-Bretonneux", uai: "0781899R" },
    { name: "Saint-François d'Assise", location: "La Roche-sur-Yon", uai: "0851642Y" },
    { name: "Saint-François de Sales", location: "Alençon", uai: "0610692W" },
    { name: "Saint-François de Sales", location: "Gien", uai: "0450106W" },
    { name: "Saint-François de Sales", location: "Troyes", uai: "0100046W" },
    { name: "Saint-François Notre-Dame", location: "Lesneven", uai: "0290160S" },
    { name: "Saint-François Xavier", location: "Vannes", uai: "0560114V" },
    { name: "Saint-Félix La Salle", location: "Nantes", uai: "0442774B" },
    { name: "Saint-Gabriel", location: "Pont-l'Abbé", uai: "0290168A" },
    { name: "Saint-Gabriel", location: "Yssingeaux", uai: "0430065M" },
    { name: "Saint-Gabriel Saint-Michel", location: "Saint-Laurent-sur-Sèvre", uai: "0850086G" },
    { name: "Saint-Gatien La Salle", location: "Joué-lès-Tours", uai: "0370748M" },
    { name: "Saint-Genès La Salle", location: "Bordeaux", uai: "0331493U" },
    { name: "Saint-Germain", location: "Drancy", uai: "0932619S" },
    { name: "Saint-Grégoire", location: "Tours", uai: "0370727P" },
    { name: "Saint-Guilhem", location: "Clermont-l'Hérault", uai: "0342298N" },
    { name: "Saint-Jacques", location: "Hazebrouck", uai: "0593136N" },
    { name: "Saint-Jacques de Compostelle", location: "Dax", uai: "0401016M" },
    { name: "Saint-Jacques de Compostelle", location: "Le Puy-en-Velay", uai: "0430968U" },
    { name: "Saint-Jean", location: "Douai", uai: "0592940A" },
    { name: "Saint-Jean", location: "Limoges", uai: "0870864S" },
    { name: "Saint-Jean", location: "Besançon", uai: "0251028G" },
    { name: "Saint-Jean Bosco", location: "Cluses", uai: "0740143D" },
    { name: "Saint-Jean de Garguier", location: "Gémenos", uai: "0133822J" },
    { name: "Saint-Jean de Passy", location: "Paris 16e", uai: "0753947H" },
    { name: "Saint-Jean et La Croix", location: "Saint-Quentin", uai: "0021873A" },
    { name: "Saint-Jean Hulst", location: "Versailles", uai: "0783350T" },
    { name: "Saint-Jean le Baptiste", location: "Valréas", uai: "0841158C" },
    { name: "Saint-Jean XXIII", location: "Reims", uai: "0511130B" },
    { name: "Saint-Jean-Baptiste de La Salle", location: "Reims", uai: "0511146U" },
    { name: "Saint-John Perse", location: "Pau", uai: "0641732K" },
    { name: "Saint-Joseph", location: "Nice", uai: "0060774J" },
    { name: "Saint-Joseph", location: "Fontaine-lès-Vervins", uai: "0021871Y" },
    { name: "Saint-Joseph", location: "Le Havre", uai: "0761710W" },
    { name: "Saint-Joseph", location: "Gap", uai: "0050035L" },
    { name: "Saint-Joseph", location: "Avignon", uai: "0840072X" },
    { name: "Saint-Joseph", location: "Lectoure", uai: "0320054K" },
    { name: "Saint-Joseph", location: "Saint-Martin-Boulogne", uai: "0622198C" },
    { name: "Saint-Joseph", location: "Périgueux", uai: "0240079F" },
    { name: "Saint-Joseph", location: "Nay", uai: "0640125N" },
    { name: "Saint-Joseph", location: "Bruz", uai: "0352341E" },
    { name: "Saint-Joseph", location: "Tassin-la-Demi-Lune", uai: "0690550Y" },
    { name: "Saint-Joseph", location: "Machecoul-Saint-Même", uai: "0440201E" },
    { name: "Saint-Joseph", location: "Mauges-sur-Loire", uai: "0490835S" },
    { name: "Saint-Joseph", location: "Reims", uai: "0511140M" },
    { name: "Saint-Joseph", location: "Sète", uai: "0341523W" },
    { name: "Saint-Joseph", location: "Bressuire", uai: "0790080H" },
    { name: "Saint-Joseph", location: "Lamballe-Armor", uai: "0220099N" },
    { name: "Saint-Joseph", location: "Miribel", uai: "0010075B" },
    { name: "Saint-Joseph", location: "Argenteuil", uai: "0952208J" },
    { name: "Saint-Joseph", location: "Concarneau", uai: "0291654R" },
    { name: "Saint-Joseph", location: "Châteaubriant", uai: "0440151A" },
    { name: "Saint-Joseph", location: "Thônes", uai: "0740099F" },
    { name: "Saint-Joseph", location: "Hasparren", uai: "0640181Z" },
    { name: "Saint-Joseph", location: "Ancenis-Saint-Géréon", uai: "0440149Y" },
    { name: "Saint-Joseph", location: "Oloron-Sainte-Marie", uai: "0640126P" },
    { name: "Saint-Joseph", location: "Villefranche-de-Rouergue", uai: "0120061B" },
    { name: "Saint-Joseph", location: "Villejuif", uai: "0942443V" },
    { name: "Saint-Joseph", location: "Marvejols", uai: "0480023P" },
    { name: "Saint-Joseph", location: "Ustaritz", uai: "0640137B" },
    { name: "Saint-Joseph", location: "Saint-Didier-sur-Chalaronne", uai: "0011431A" },
    { name: "Saint-Joseph", location: "Sallanches", uai: "0740098E" },
    { name: "Saint-Joseph Bossuet", location: "Lannion", uai: "0220102S" },
    { name: "Saint-Joseph Carnolès", location: "Roquebrune-Cap-Martin", uai: "0060750H" },
    { name: "Saint-Joseph de Cluny", location: "Fort-de-France", uai: "9720063L" },
    { name: "Saint-Joseph de l'Apparition", location: "Gaillac", uai: "0810075S" },
    { name: "Saint-Joseph de la Madeleine", location: "Marseille 4e", uai: "0131339K" },
    { name: "Saint-Joseph de Tivoli", location: "Bordeaux", uai: "0331502D" },
    { name: "Saint-Joseph du Loquidy", location: "Nantes", uai: "0440161L" },
    { name: "Saint-Joseph du Moncel", location: "Pont-Sainte-Maxence", uai: "0601831P" },
    { name: "Saint-Joseph La Joliverie", location: "Saint-Sébastien-sur-Loire", uai: "0441653H" },
    { name: "Saint-Joseph La Salle", location: "Toulouse", uai: "0311145B" },
    { name: "Saint-Joseph La Salle", location: "Dijon", uai: "0211089V" },
    { name: "Saint-Joseph La Salle", location: "Thonon-les-Bains", uai: "0740100G" },
    { name: "Saint-Joseph La Salle", location: "Lorient", uai: "0560181T" },
    { name: "Saint-Joseph La Salle", location: "Auxerre", uai: "0890070T" },
    { name: "Saint-Joseph La Salle", location: "Pantin", uai: "0932653D" },
    { name: "Saint-Joseph les Maristes", location: "Marseille 6e", uai: "0131331B" },
    { name: "Saint-Joseph Pierre Rouge", location: "Montferrier-sur-Lez", uai: "0341521U" },
    { name: "Saint-Jude", location: "Armentières", uai: "0592926K" },
    { name: "Saint-Julien", location: "Brioude", uai: "0430053Z" },
    { name: "Saint-Just", location: "Lyon 5e", uai: "0690028F" },
    { name: "Saint-Laurent La Paix Notre-Dame", location: "Lagny-sur-Marne", uai: "0771237B" },
    { name: "Saint-Louis", location: "Saint-Nazaire", uai: "0440177D" },
    { name: "Saint-Louis", location: "Châteaulin", uai: "0290156M" },
    { name: "Saint-Louis", location: "Bar-le-Duc", uai: "0550047C" },
    { name: "Saint-Louis", location: "Orange", uai: "0840075A" },
    { name: "Saint-Louis", location: "Carcassonne", uai: "0110045P" },
    { name: "Saint-Louis", location: "Crest", uai: "0260072M" },
    { name: "Saint-Louis", location: "Saumur", uai: "0490838V" },
    { name: "Saint-Louis", location: "Lorient", uai: "0560101F" },
    { name: "Saint-Louis de Gonzague", location: "Paris 16e", uai: "0753933T" },
    { name: "Saint-Louis de Gonzague", location: "Perpignan", uai: "0660768B" },
    { name: "Saint-Louis Saint-Bruno", location: "Lyon 1er", uai: "0690563M" },
    { name: "Saint-Louis Saint-Clément", location: "Viry-Châtillon", uai: "0910826D" },
    { name: "Saint-Louis Sainte-Clotilde", location: "Le Raincy", uai: "0931812P" },
    { name: "Saint-Louis Sainte-Marie", location: "Gignac-la-Nerthe", uai: "0133314G" },
    { name: "Saint-Louis Villa Pia", location: "Bayonne", uai: "0640115C" },
    { name: "Saint-Luc", location: "Cambrai", uai: "0593061G" },
    { name: "Saint-Léon", location: "Corbeil-Essonnes", uai: "0912161E" },
    { name: "Saint-Léon IX", location: "Nancy", uai: "0541313J" },
    { name: "Saint-Lô", location: "Agneaux", uai: "0500120J" },
    { name: "Saint-Magloire", location: "Dol-de-Bretagne", uai: "0352471W" },
    { name: "Saint-Malo La Providence", location: "Saint-Malo", uai: "0352072M" },
    { name: "Saint-Marc", location: "Nivolas-Vermelle", uai: "0383072H" },
    { name: "Saint-Marc", location: "Lyon 2e", uai: "0690571W" },
    { name: "Saint-Martin", location: "Nort-sur-Erdre", uai: "0442778F" },
    { name: "Saint-Martin (quartier Sainte-Anne)", location: "Rennes", uai: "0350777E" },
    { name: "Saint-Martin (quartier Sainte-Geneviève)", location: "Rennes", uai: "0350783L" },
    { name: "Saint-Martin de France", location: "Pontoise", uai: "0950762M" },
    { name: "Saint-Maurice", location: "Romans-sur-Isère", uai: "0260066F" },
    { name: "Saint-Michel", location: "Solesmes", uai: "0593138R" },
    { name: "Saint-Michel", location: "Annecy", uai: "0740082M" },
    { name: "Saint-Michel", location: "Saint-Étienne", uai: "0421021G" },
    { name: "Saint-Michel", location: "Château-Gontier", uai: "0530046M" },
    { name: "Saint-Michel", location: "Reims", uai: "0511145T" },
    { name: "Saint-Michel de Picpus", location: "Paris 12e", uai: "0753897D" },
    { name: "Saint-Michel de Picpus", location: "Saint-Mandé", uai: "0940882Y" },
    { name: "Saint-Michel des Batignolles", location: "Paris 17e", uai: "0753959W" },
    { name: "Saint-Ouen", location: "Pont-Audemer", uai: "0271049U" },
    { name: "Saint-Paul", location: "Lille", uai: "0593114P" },
    { name: "Saint-Paul", location: "Lens", uai: "0622114L" },
    { name: "Saint-Paul", location: "Besançon", uai: "0251021Z" },
    { name: "Saint-Paul", location: "Vannes", uai: "0560117Y" },
    { name: "Saint-Paul", location: "Saint-Étienne", uai: "0421035X" },
    { name: "Saint-Paul", location: "Angoulême", uai: "0160062F" },
    { name: "Saint-Paul", location: "Roanne", uai: "0421023J" },
    { name: "Saint-Paul", location: "Ajaccio", uai: "6200650M" },
    { name: "Saint-Paul", location: "Charleville-Mézières", uai: "0080082W" },
    { name: "Saint-Paul Bourdon Blanc", location: "Orléans", uai: "0450758E" },
    { name: "Saint-Paul Forez", location: "Montbrison", uai: "0420973E" },
    { name: "Saint-Pierre", location: "Calais", uai: "0622110G" },
    { name: "Saint-Pierre", location: "Bourg-en-Bresse", uai: "0010070W" },
    { name: "Saint-Pierre", location: "Brunoy", uai: "0910843X" },
    { name: "Saint-Pierre", location: "Abbeville", uai: "0801206B" },
    { name: "Saint-Pierre", location: "Saint-Brieuc", uai: "0220111B" },
    { name: "Saint-Pierre", location: "Montrond-les-Bains", uai: "0422318S" },
    { name: "Saint-Pierre", location: "Cusset", uai: "0030072M" },
    { name: "Saint-Pierre Chanel", location: "Thionville", uai: "0570216A" },
    { name: "Saint-Pierre de la Mer", location: "Saint-Cyprien", uai: "0660931D" },
    { name: "Saint-Pierre Fourier", location: "Paris 12e", uai: "0753898E" },
    { name: "Saint-Pierre Saint-Paul", location: "Dreux", uai: "0280667Y" },
    { name: "Saint-Pierre Saint-Paul", location: "Langogne", uai: "0480022N" },
    { name: "Saint-Riquier", location: "Amiens", uai: "0801874C" },
    { name: "Saint-Rémi", location: "Roubaix", uai: "0592921E" },
    { name: "Saint-Rémy", location: "Soissons", uai: "0021874B" },
    { name: "Saint-Sauveur", location: "Redon", uai: "0350774B" },
    { name: "Saint-Sernin", location: "Toulouse", uai: "0310041B" },
    { name: "Saint-Stanislas", location: "Nantes", uai: "0440163N" },
    { name: "Saint-Stanislas", location: "Nîmes", uai: "0300083C" },
    { name: "Saint-Sulpice", location: "Paris 6e", uai: "0753838P" },
    { name: "Saint-Sébastien", location: "Landerneau", uai: "0290159R" },
    { name: "Saint-Thomas d'Aquin", location: "Saint-Jean-de-Luz", uai: "0640134Y" },
    { name: "Saint-Thomas d'Aquin", location: "Flers", uai: "0610698C" },
    { name: "Saint-Thomas d'Aquin", location: "Paris 7e", uai: "0753850C" },
    { name: "Saint-Thomas d'Aquin Veritas", location: "Oullins-Pierre-Bénite", uai: "0690543R" },
    { name: "Saint-Thomas de Villeneuve", location: "Chaville", uai: "0920908M" },
    { name: "Saint-Thomas de Villeneuve", location: "Saint-Germain-en-Laye", uai: "0783323N" },
    { name: "Saint-Vaast Saint-Dominique", location: "Béthune", uai: "0622106C" },
    { name: "Saint-Victor", location: "Valence", uai: "0260071L" },
    { name: "Saint-Vincent", location: "Senlis", uai: "0601149Y" },
    { name: "Saint-Vincent de Paul", location: "Nîmes", uai: "0300127A" },
    { name: "Saint-Vincent de Paul", location: "Loos", uai: "0595657D" },
    { name: "Saint-Vincent Providence", location: "Rennes", uai: "0350776D" },
    { name: "Sainte-Anne", location: "Brest", uai: "0290338K" },
    { name: "Sainte-Anne", location: "Sablé-sur-Sarthe", uai: "0720843A" },
    { name: "Sainte-Anne", location: "Verdun", uai: "0550049E" },
    { name: "Sainte-Anne", location: "Strasbourg", uai: "0673083M" },
    { name: "Sainte-Anne Saint-Louis", location: "Sainte-Anne-d'Auray", uai: "0560119A" },
    { name: "Sainte-Catherine", location: "Villeneuve-sur-Lot", uai: "0470065R" },
    { name: "Sainte-Catherine de Sienne", location: "Aix-en-Provence", uai: "0131320P" },
    { name: "Sainte-Chrétienne La Salle", location: "Sarreguemines", uai: "0570214Y" },
    { name: "Sainte-Claire", location: "Lille", uai: "0593113N" },
    { name: "Sainte-Clotilde", location: "Strasbourg", uai: "0671639T" },
    { name: "Sainte-Croix", location: "Provins", uai: "0772290W" },
    { name: "Sainte-Croix Saint-Euverte", location: "Orléans", uai: "0450757D" },
    { name: "Sainte-Cécile", location: "La Côte-Saint-André", uai: "0381660Y" },
    { name: "Sainte-Céline", location: "La Ferté-sous-Jouarre", uai: "0772602K" },
    { name: "Sainte-Elisabeth", location: "Paris 15e", uai: "0753916Z" },
    { name: "Sainte-Famille", location: "La Roche-sur-Foron", uai: "0740092Y" },
    { name: "Sainte-Famille", location: "Amiens", uai: "0801209E" },
    { name: "Sainte-Geneviève", location: "Asnières-sur-Seine", uai: "0920889S" },
    { name: "Sainte-Geneviève", location: "Saint-Jory", uai: "0313016K" },
    { name: "Sainte-Geneviève", location: "Paris 6e", uai: "0753844W" },
    { name: "Sainte-Jeanne d'Arc", location: "Brignoles", uai: "0831444U" },
    { name: "Sainte-Jeanne d'Arc", location: "Aulnoye-Aymeries", uai: "0592928M" },
    { name: "Sainte-Jeanne d'Arc", location: "Gourin", uai: "0560096A" },
    { name: "Sainte-Jeanne Elisabeth", location: "Paris 7e", uai: "0753852E" },
    { name: "Sainte-Louise", location: "Paris 20e", uai: "0755709Y" },
    { name: "Sainte-Marguerite", location: "Chambray-lès-Tours", uai: "0370749N" },
    { name: "Sainte-Marie", location: "Neuilly-sur-Seine", uai: "0920875B" },
    { name: "Sainte-Marie", location: "Antony", uai: "0920904H" },
    { name: "Sainte-Marie", location: "Aubagne", uai: "0132810J" },
    { name: "Sainte-Marie", location: "Belfort", uai: "0900030U" },
    { name: "Sainte-Marie", location: "Beaucamps-Ligny", uai: "0592932S" },
    { name: "Sainte-Marie", location: "La Seyne-sur-Mer", uai: "0830095C" },
    { name: "Sainte-Marie", location: "Caen", uai: "0141164P" },
    { name: "Sainte-Marie", location: "Lyon 5e", uai: "0690521S" },
    { name: "Sainte-Marie", location: "Chantonnay", uai: "0850135K" },
    { name: "Sainte-Marie", location: "Cannes", uai: "0060670W" },
    { name: "Sainte-Marie", location: "Riom", uai: "0631034G" },
    { name: "Sainte-Marie", location: "Bourges", uai: "0180561M" },
    { name: "Sainte-Marie", location: "Cholet", uai: "0492406Z" },
    { name: "Sainte-Marie", location: "Créteil", uai: "0942354Y" },
    { name: "Sainte-Marie", location: "Lons-le-Saunier", uai: "0390070P" },
    { name: "Sainte-Marie", location: "Stains", uai: "0932654E" },
    { name: "Sainte-Marie Bastide", location: "Bordeaux", uai: "0331501C" },
    { name: "Sainte-Marie de Nevers", location: "Toulouse", uai: "0311147D" },
    { name: "Sainte-Marie des Champs", location: "Toulouse", uai: "0311146C" },
    { name: "Sainte-Marie du Port", location: "Les Sables-d'Olonne", uai: "0850133H" },
    { name: "Sainte-Marie Grand Lebrun", location: "Bordeaux", uai: "0331503E" },
    { name: "Sainte-Marie La Grand'Grange", location: "Saint-Chamond", uai: "0421022H" },
    { name: "Sainte-Marthe Chavagnes", location: "Angoulême", uai: "0160079Z" },
    { name: "Sainte-Marthe Saint-Front", location: "Bergerac", uai: "0240076C" },
    { name: "Sainte-Odile", location: "Lambersart", uai: "0593106F" },
    { name: "Sainte-Philomène", location: "Haguenau", uai: "0671602C" },
    { name: "Sainte-Solange", location: "Châteauroux", uai: "0360693H" },
    { name: "Sainte-Thècle", location: "Chamalières", uai: "0631070W" },
    { name: "Sainte-Thérèse", location: "Rambouillet", uai: "0781664K" },
    { name: "Sainte-Thérèse", location: "Ozoir-la-Ferrière", uai: "0772324H" },
    { name: "Sainte-thérèse", location: "Remire-Montjoly", uai: "9730511T" },
    { name: "Sainte-Thérèse", location: "Saint-Gaudens", uai: "0311131L" },
    { name: "Sainte-Thérèse", location: "Quimper", uai: "0290171D" },
    { name: "Sainte-Trinité", location: "Marseille 9e", uai: "0131347U" },
    { name: "Sainte-Ursule", location: "Paris 17e", uai: "0754081D" },
    { name: "Sainte-Ursule", location: "Tours", uai: "0370731U" },
    { name: "Sainte-Ursule", location: "Luçon", uai: "0850077X" },
    { name: "Salvador Allende", location: "Hérouville-Saint-Clair", uai: "0141796B" },
    { name: "Samuel Beckett", location: "La Ferté-sous-Jouarre", uai: "0772685A" },
    { name: "Sarda Garriga", location: "Saint-André", uai: "9740043D" },
    { name: "Sasserno", location: "Nice", uai: "0060677D" },
    { name: "Savary de Mauléon", location: "Les Sables-d'Olonne", uai: "0850032Y" },
    { name: "Scheurer Kestner", location: "Thann", uai: "0680073K" },
    { name: "Schuman Perret", location: "Le Havre", uai: "0760058A" },
    { name: "Silvia Monfort", location: "Luisant", uai: "0281077U" },
    { name: "Simone de Beauvoir", location: "Garges-lès-Gonesse", uai: "0951766D" },
    { name: "Simone Signoret", location: "Vaux-le-Pénil", uai: "0772310T" },
    { name: "Simone Veil", location: "Valbonne", uai: "0062015H" },
    { name: "Simone Veil", location: "Liffré", uai: "0352950S" },
    { name: "Simone Veil", location: "Gignac", uai: "0342436N" },
    { name: "Simone Veil", location: "Noisiel", uai: "0771940R" },
    { name: "Simone Veil", location: "Marseille 13e", uai: "0134155W" },
    { name: "Simone Veil", location: "Boulogne-Billancourt", uai: "0922801V" },
    { name: "Simone Weil", location: "Saint-Priest-en-Jarez", uai: "0420044V" },
    { name: "Simone Weil", location: "Le Puy-en-Velay", uai: "0430021P" },
    { name: "Simone Weil", location: "Paris 3e", uai: "0750651A" },
    { name: "Sivard de Beaulieu", location: "Carentan-les-Marais", uai: "0501839C" },
    { name: "Sonia Delaunay", location: "Cesson", uai: "0772332S" },
    { name: "Sonia Delaunay", location: "Villepreux", uai: "0781952Y" },
    { name: "Sonny Rupaire", location: "Sainte-Rose", uai: "9710940V" },
    { name: "Sophie Barat", location: "Châtenay-Malabry", uai: "0920907L" },
    { name: "Sophie Berthelot", location: "Calais", uai: "0620063G" },
    { name: "Sophie Germain", location: "Paris 4e", uai: "0750653C" },
    { name: "Stanislas", location: "Paris 6e", uai: "0753840S" },
    { name: "Stanislas", location: "Nice", uai: "0060729K" },
    { name: "Stanislas", location: "Cannes", uai: "0060673Z" },
    { name: "Stanislas", location: "Saint-Raphaël", uai: "0831551K" },
    { name: "Stanislas", location: "Wissembourg", uai: "0670114K" },
    { name: "Stanislas", location: "Villers-lès-Nancy", uai: "0542208G" },
    { name: "Stella", location: "Saint-Leu", uai: "9741052A" },
    { name: "Stendhal", location: "Grenoble", uai: "0380028Z" },
    { name: "Stendhal", location: "Aiguillon", uai: "0470009E" },
    { name: "Stephen Liégeard", location: "Brochon", uai: "0210012Z" },
    { name: "Stéphane Hessel", location: "Toulouse", uai: "0311902Z" },
    { name: "Stéphane Hessel", location: "Vaison-la-Romaine", uai: "0841117H" },
    { name: "Stéphane Hessel", location: "Épernay", uai: "0510068X" },
    { name: "Sud des Landes", location: "Saint-Vincent-de-Tyrosse", uai: "0400933X" },
    { name: "Sud Médoc La Boétie", location: "Le Taillan-Médoc", uai: "0332831Y" },
    { name: "Suger", location: "Vaucresson", uai: "0920937U" },
    { name: "Suger", location: "Saint-Denis", uai: "0932121A" },
    { name: "Suzanne Valadon", location: "Limoges", uai: "0870019Y" },
    { name: "Séminaire Collège Sainte-Marie", location: "Fort-de-France", uai: "9720829U" },
    { name: "Séminaire de jeunes", location: "Walbourg", uai: "0671618V" },
    { name: "Sévigné", location: "Paris 5e", uai: "0753598D" },
    { name: "Sévigné", location: "Marseille 13e", uai: "0131348V" },
    { name: "Sévigné", location: "Cesson-Sévigné", uai: "0352304P" },
    { name: "Sévigné", location: "Granville", uai: "0500119H" },
    { name: "Sévigné", location: "Charleville-Mézières", uai: "0080007P" },
    { name: "Sévigné Saint-Louis", location: "Issoire", uai: "0631033F" },
    { name: "Talensac", location: "Nantes", uai: "0442725Y" },
    { name: "Tani Malandi", location: "Chirongui", uai: "9760316P" },
    { name: "Teilhard de Chardin", location: "Saint-Maur-des-Fossés", uai: "0940878U" },
    { name: "Thibaut de Champagne", location: "Provins", uai: "0770942F" },
    { name: "Thierry Maulnier", location: "Nice", uai: "0061691F" },
    { name: "Thiers", location: "Marseille 1er", uai: "0130040Y" },
    { name: "Thomas Corneille", location: "Barentin", uai: "0760005T" },
    { name: "Thomas Edison", location: "Lorgues", uai: "0830032J" },
    { name: "Thomas Hélye", location: "Cherbourg-en-Cotentin", uai: "0501299R" },
    { name: "Théodore Aubanel", location: "Avignon", uai: "0840004Y" },
    { name: "Théodore de Banville", location: "Moulins", uai: "0030036Y" },
    { name: "Théodore Deck", location: "Guebwiller", uai: "0680016Y" },
    { name: "Théodore Monod", location: "Le Rheu", uai: "0352867B" },
    { name: "Théophile Gautier", location: "Tarbes", uai: "0650025Z" },
    { name: "Théophile Roussel", location: "Saint-Chély-d'Apcher", uai: "0480688M" },
    { name: "Thérèse d'Avila", location: "Lille", uai: "0592922F" },
    { name: "Thérèse Planiol", location: "Loches", uai: "0370016S" },
    { name: "Torat Emet", location: "Sarcelles", uai: "0951048Y" },
    { name: "Tristan Corbière", location: "Morlaix", uai: "0290051Y" },
    { name: "Turgot", location: "Limoges", uai: "0870056N" },
    { name: "Turgot", location: "Paris 3e", uai: "0750647W" },
    { name: "Tézenas du Montcel", location: "Saint-Étienne", uai: "0420979L" },
    { name: "Union Chrétienne", location: "Poitiers", uai: "0860903P" },
    { name: "Urbain Mongazon", location: "Angers", uai: "0492407A" },
    { name: "Uruguay France", location: "Avon", uai: "0770918E" },
    { name: "Val de Durance", location: "Pertuis", uai: "0840918S" },
    { name: "Val de Garonne", location: "Marmande", uai: "0470020S" },
    { name: "Val de Lys", location: "Estaires", uai: "0597005U" },
    { name: "Val de Seine", location: "Le Grand-Quevilly", uai: "0761742F" },
    { name: "Valentine Labbé", location: "La Madeleine", uai: "0590122M" },
    { name: "Vallée de Chevreuse", location: "Gif-sur-Yvette", uai: "0911913K" },
    { name: "Van Dongen", location: "Lagny-sur-Marne", uai: "0771512A" },
    { name: "Van Gogh", location: "Ermont", uai: "0950645K" },
    { name: "Vauban", location: "Pontoise", uai: "0951221L" },
    { name: "Vauban", location: "Aire-sur-la-Lys", uai: "0623981R" },
    { name: "Vauban", location: "Givet", uai: "0080018B" },
    { name: "Vaucanson", location: "Grenoble", uai: "0380033E" },
    { name: "Vaugelas", location: "Chambéry", uai: "0730013T" },
    { name: "Vauvenargues", location: "Aix-en-Provence", uai: "0130003H" },
    { name: "Venise Verte", location: "Niort", uai: "0791062A" },
    { name: "Versailles Saint-Joseph de Cluny", location: "Basse-Terre", uai: "9710055H" },
    { name: "Viala Lacoste", location: "Salon-de-Provence", uai: "0131360H" },
    { name: "Victor Bérard", location: "Hauts de Bienne", uai: "0390027T" },
    { name: "Victor Considerant", location: "Salins-les-Bains", uai: "0390042J" },
    { name: "Victor Duruy", location: "Paris 7e", uai: "0750662M" },
    { name: "Victor Duruy", location: "Mont-de-Marsan", uai: "0400017B" },
    { name: "Victor Duruy", location: "Bagnères-de-Bigorre", uai: "0650005C" },
    { name: "Victor et Hélène Basch", location: "Rennes", uai: "0352009U" },
    { name: "Victor Grignard", location: "Cherbourg-en-Cotentin", uai: "0501828R" },
    { name: "Victor Hugo", location: "Paris 3e", uai: "0750648X" },
    { name: "Victor Hugo", location: "Colomiers", uai: "0312093G" },
    { name: "Victor Hugo", location: "Caen", uai: "0142059M" },
    { name: "Victor Hugo", location: "Gaillac", uai: "0810023K" },
    { name: "Victor Hugo", location: "Poitiers", uai: "0860034V" },
    { name: "Victor Hugo", location: "Besançon", uai: "0250007X" },
    { name: "Victor Hugo", location: "Lunel", uai: "0342090M" },
    { name: "Victor Hugo", location: "Château-Gontier-sur-Mayenne", uai: "0530004S" },
    { name: "Victor Hugo", location: "Carpentras", uai: "0840016L" },
    { name: "Victor Hugo", location: "Hennebont", uai: "0561607T" },
    { name: "Victor Hugo", location: "Marseille 3e", uai: "0130043B" },
    { name: "Victor Louis", location: "Talence", uai: "0330126H" },
    { name: "Victor Schœlcher", location: "Fort-de-France", uai: "9720002V" },
    { name: "Vincent Auriol", location: "Revel", uai: "0310028M" },
    { name: "Vincent d'Indy", location: "Privas", uai: "0070021K" },
    { name: "Vincent Van Gogh", location: "Aubergenville", uai: "0781859X" },
    { name: "Viollet-le-Duc", location: "Villiers-Saint-Frédéric", uai: "0782587N" },
    { name: "Voillaume", location: "Aulnay-sous-Bois", uai: "0930834B" },
    { name: "Voltaire", location: "Wingles", uai: "0623915U" },
    { name: "Voltaire", location: "Orléans", uai: "0450782F" },
    { name: "Voltaire", location: "Paris 11e", uai: "0750675B" },
    { name: "Václav Havel", location: "Bègles", uai: "0333273D" },
    { name: "Watteau", location: "Valenciennes", uai: "0590222W" },
    { name: "Wolfgang Amadeus Mozart", location: "Le Blanc-Mesnil", uai: "0932034F" },
    { name: "Xavier Bichat", location: "Nantua", uai: "0010032E" },
    { name: "Xavier Mallet", location: "Le Teil", uai: "0071351F" },
    { name: "Xavier Marmier", location: "Pontarlier", uai: "0250043L" },
    { name: "Yabné", location: "Paris 13e", uai: "0753834K" },
    { name: "Younoussa Bamana", location: "Mamoudzou", uai: "9760127J" },
    { name: "Yves Kernanec", location: "Marcq-en-Barœul", uai: "0590143K" },
    { name: "Yves Leborgne", location: "Sainte-Anne", uai: "9710922A" },
    { name: "Yves Thépôt", location: "Quimper", uai: "0290071V" },
    { name: "Élie Vinet", location: "Barbezieux-Saint-Hilaire", uai: "0160010Z" },
    { name: "Élisée Reclus", location: "Sainte-Foy-la-Grande", uai: "0330115W" },
    { name: "Émile Combes", location: "Pons", uai: "0170020E" },
    { name: "Émile Duclaux", location: "Aurillac", uai: "0150646W" },
    { name: "Émile Roux", location: "Confolens", uai: "0160022M" },
    { name: "Étienne-Jules Marey", location: "Beaune", uai: "0212045J" },
    { name: "Évariste de Parny", location: "Saint-Paul", uai: "9740597F" }
];

// Sort by name alphabetically
const sortedHighSchoolsData = highSchoolsData.sort((a, b) =>
   a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
);

export default sortedHighSchoolsData;