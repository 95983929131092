// CorrespondanceFormation.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { domainIconMapRaw } from './domainIconMap'; // votre mapping d'icônes
import moyenne_croissance_icon from '../../assets/moyenne_croissante_icon.png';
import meoIcon from '../../assets/meo_write.png';
import useDeviceType from '../useDeviceType'; // Import du hook pour la détection de device

// Import des icônes
import checkIcon from '../../assets/true_icon.png';
import crossIcon from '../../assets/attention_icon.png';

/* ====================================================
   FONCTIONS UTILITAIRES POUR LE CALCUL DU GRADIENT
=====================================================*/
function normalizeString(str) {
  return str
    .toLowerCase()                  // Lowercase
    .normalize('NFD')               // Décomposer les accents
    .replace(/[\u0300-\u036f]/g, '')// Retirer les accents
    .trim();                        // Supprimer les espaces en fin de chaîne
}

// Construction d'une map avec des clés normalisées
const normalizedDomainMap = Object.entries(domainIconMapRaw).reduce(
  (acc, [displayDomain, icon]) => {
    const key = normalizeString(displayDomain);
    acc[key] = { displayDomain, icon };
    return acc;
  },
  {}
);

/* ====================================================
   STYLED-COMPONENTS POUR LA STRUCTURE
=====================================================*/

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  flex-wrap: ${props => props.isMobile ? 'nowrap' : 'wrap'};
  align-items: ${props => props.isMobile ? 'center' : 'flex-start'};
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
  margin-top: 0px;
  width: 100%;
`;

const MainSection = styled.div`
  display: ${props => props.isMobile ? 'block' : 'grid'};
  grid-template-columns: ${props => props.isMobile ? '1fr' : 'repeat(2, 1fr)'};
  grid-gap: 20px;
  width: 100%;
`;

// eslint-disable-next-line no-unused-vars
const BottomSection = styled.div`
  // eslint-disable-next-line no-unused-vars
  display: ${props => props.isMobile ? 'block' : 'grid'};
  grid-template-columns: ${props => props.isMobile ? '1fr' : 'repeat(2, 1fr)'};
  grid-gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

const MeoIcon = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  top: -55px;         /* Adjust as needed to position the icon on the arc */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

/* ---------- Composant Gauge ---------- */

const GaugeWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 120px; /* un peu plus grand pour intégrer le texte */
  margin-top: 30px;
`;

const SvgStyled = styled.svg`
  overflow: visible;
`;

/* ---------- Card générique ---------- */
const Card = styled.div`
  width: ${props => props.isMobile ? '100%' : props.fullWidth ? '100%' : 'auto'};
  background: white;
  border-radius: 25px;
  padding: 20px;
  margin: ${props => props.isMobile ? '10px 0' : '0'};
  height: ${props => props.equalHeight ? '100%' : 'auto'};
`;

const CardTitle = styled.div`
  color: black;
  font-size: 16px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
`;

const ScoreCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  /* horizontally center */
  justify-content: center;
`;

/* ---------- Séparateur ---------- */
const Separator = styled.div`
  width: 100%;
  height: 1px;
  border: 1.5px solid #FFF5F8;
  margin: 10px 0;
`;

/* ---------- Liste des domaines ---------- */
const DomainList = styled.div`
  margin-top: 10px;
`;

const DomainItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const DomainIcon = styled.img`
  width: 35px;
  height: 35px;
  background: none;
  border-radius: 4px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DefaultDomainIcon = styled.div`
  width: 22px;
  height: 22px;
  background: #ccc;
  border-radius: 50%;
  margin-right: 10px;
`;

const DomainName = styled.div`
  color: black;
  font-size: 13px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  margin-left: 10px;
`;

const VoirPlusButton = styled.div`
  width: fit-content;
  color: rgba(130, 128, 128, 0.7);
  font-size: 14px;
  font-family: 'Geologica', sans-serif;
  font-style: italic;
  font-weight: 300;
  cursor: pointer;
  margin-left: ${props => props.isMobile ? '75%' : 'auto'};
  &:hover {
    text-decoration: underline;
  }
`;

/* ---------- Liste pour le caractère ---------- */
const ListSection = styled.div`
  margin-top: 10px;
  /* Force left alignment inside the section */
  text-align: left;
`;

const ListSectionContainer = styled.div`
  display: ${props => props.isMobile ? 'block' : 'flex'};
  justify-content: space-between;
`;

const ListColumn = styled.div`
  flex: 1;
  margin-right: ${props => props.isMobile ? '0' : '20px'};
  &:last-child {
    margin-right: 0;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;    /* Ensure icon and text are top-aligned */
  margin-bottom: 8px;
`;

const IconCheck = styled.img`
   width: 18px;
   height: 18px;
   margin-right: 10px;
   margin-top: 2px; /* or whatever small offset if you want better alignment */
 `;

const IconCross = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const ListText = styled.div`
  color: black;
  font-size: 13px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
`;

/* ====================================================
   GAUGE UTILS
=====================================================*/
const getArcPath = (percentage) => {
  const angle = (percentage / 100) * 180; // en degrés
  const radians = (angle * Math.PI) / 180;
  // Cercle centré en (100,100) de rayon 90.
  const x = 100 + 90 * Math.cos(Math.PI - radians);
  const y = 100 - 90 * Math.sin(Math.PI - radians);
  return `M 10,100 A 90,90 0 ${angle >= 180 ? 1 : 0} 1 ${x},${y}`;
};

const Gauge = ({ percentage }) => {
  const fullArcPath = 'M 10,100 A 90,90 0 0,1 190,100';
  const visibleArcPath = getArcPath(percentage);

  return (
    <GaugeWrapper>
      {/* MEO ICON HERE */}
      <MeoIcon src={meoIcon} alt="M.E.O" />

      <SvgStyled width="200" height="140">
        <defs>
          <linearGradient
            id="scoreGradient"
            gradientUnits="userSpaceOnUse"
            x1="100" y1="10"
            x2="100" y2="190"
            gradientTransform="rotate(270, 100, 100)"
          >
            <stop offset="0%" stopColor="#ff0000" />
            <stop offset="26%" stopColor="#f4932c" />
            <stop offset="53%" stopColor="#f1e424" />
            <stop offset="79%" stopColor="#35de2f" />
            <stop offset="100%" stopColor="#26ff00" />
          </linearGradient>
        </defs>

        {/* Arc de fond gris */}
        <path
          d={fullArcPath}
          fill="none"
          stroke="#D6D2D2"
          strokeWidth="20"
          strokeLinecap="round"
        />

        {/* Arc coloré en dégradé */}
        <path
          d={visibleArcPath}
          fill="none"
          stroke="url(#scoreGradient)"
          strokeWidth="20"
          strokeLinecap="round"
        />

        {/* Textes */}
        <text
          x="100"
          y="95"
          textAnchor="middle"
          fontSize="12"
          fontFamily="Gabarito, sans-serif"
          fill="#555454"
        >
          <tspan x="100" dy="0">Correspondance</tspan>
          <tspan x="100" dy="1.2em">avec la formation</tspan>
        </text>
        <text
          x="100"
          y="75"
          textAnchor="middle"
          fontSize="40"
          fontFamily="Gantari, sans-serif"
          fill="black"
          fontWeight="700"
        >
          {Math.round(percentage)}%
        </text>
      </SvgStyled>
    </GaugeWrapper>
  );
};

/* ====================================================
   COMPOSANT PRINCIPAL
=====================================================*/

const CorrespondanceFormation = ({ formation, posNeg }) => {
  // Detection device type
  const { isMobile } = useDeviceType();
  
  // Extraction des scores
  const scoreFormations = formation?.score_formations || 0;
  // eslint-disable-next-line no-unused-vars
  const scoreDomaines = formation?.score_domaines/30*100 || 0;
  // eslint-disable-next-line no-unused-vars
  const scoreQuiz = formation?.score_quiz/40*100 || 0;
  // eslint-disable-next-line no-unused-vars
  let moyenne_générale = formation?.moyenne_formation || null;
  moyenne_générale = moyenne_générale === 0 ? null : moyenne_générale;

  // Filtrer les items positifs et négatifs selon le type de formation
  const formationType = formation.type;
  const filteredPositives = posNeg?.positive?.filter(item => item.quiz_type === formationType) || [];
  const filteredNegatives = posNeg?.negative?.filter(item => item.quiz_type === formationType) || [];

  // Domaines enseignés
  const domainesP = formation.domaines_enseignés_principaux || [];
  const domainesS = formation.domaines_enseignés_secondaires || [];
  const userObject = JSON.parse(localStorage.getItem('user'));
  const matchedDomains = userObject?.sub_domaine_formation?.filter(
    domain => domainesP.includes(domain) || domainesS.includes(domain)
  ) || [];

  const obj_mapper_spé = {
      "bioeco": "Biologie/Ecologie",
      "hggsp": "HGGSP",
      "hlp": "HLP",
      "llcer": "LLCER",
      "lca": "LCA : Latin",
      "math": "Mathématiques",
      "nsi": "NSI",
      "physchim": "Physique-Chimie",
      "si": "SI",
      "svt": "SVT",
      "ses": "SES",
      "eppcs": "EPPCS"
  };
  
  let userSpés = userObject?.specialites || [];

  const spéRank = formation?.rang_spécialité || {};
  // eslint-disable-next-line no-unused-vars
  const max = Object.keys(spéRank).length;
  const allSpés = Object.keys(spéRank);
  // eslint-disable-next-line no-unused-vars
  const MatchedSpecialitiesRankObj = userSpés.reduce((obj, spé) => {
    obj[spé] = allSpés.indexOf(obj_mapper_spé[spé] || spé) + 1;
    return obj;
  }, {});
  
  // États pour le "voir plus"
  const [showAllDomaines, setShowAllDomaines] = useState(false);
  const [showAllPositives, setShowAllPositives] = useState(false);
  const [showAllNegatives, setShowAllNegatives] = useState(false);

  // Nombre initial d'items affichés
  const initialDomainCount = isMobile ? 2 : matchedDomains.length; // Montrer tous les domaines sur desktop
  const initialListCount = isMobile ? 3 : filteredPositives.length; // Montrer tous les items sur desktop

  const domainesToShow = showAllDomaines ? matchedDomains : matchedDomains.slice(0, initialDomainCount);
  const positivesToShow = showAllPositives ? filteredPositives : filteredPositives.slice(0, initialListCount);
  const negativesToShow = showAllNegatives ? filteredNegatives : filteredNegatives.slice(0, initialListCount);

  /* ---------- Styled Components pour les spécialités ---------- */
  // eslint-disable-next-line no-unused-vars
  const SpecialityList = styled.div`
    margin-top: 10px;
  `;
  const SpecialityItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  `;
  const DonutContainer = styled.div`
    position: relative;
    display: inline-block;
  `;
  const StyledSvg = styled.svg`
    transform: rotate(-90deg);
  `;

  function DonutProgress({
    rank = null,
    // eslint-disable-next-line no-unused-vars
    max = null,
    size = 80,
    thickness = 8,
    trackColor = '#bcbcff',
    fillColor = '#420eef',
    moyenne_g = null,
    ...props
  }) {
    let percentage = 0;
    if (moyenne_g !== null) {
      percentage = (moyenne_g / max) * 100;
    } else {
      percentage = ((max - (rank - 1)) / max) * 100;
    }
    
    if (rank <= 0) {
      percentage = 0;
    }
    const radius = (size - thickness) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    return (
      <DonutContainer style={{ width: size, height: size }} {...props}>
        <StyledSvg width={size} height={size}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={trackColor}
            strokeWidth={thickness}
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke={fillColor}
            strokeWidth={thickness}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
          />
        </StyledSvg>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: moyenne_g !== null ? 'column' : undefined,
            alignItems: 'center'
          }}
        >
          {props.children}
        </div>
      </DonutContainer>
    );
  }
  
  const RankWrapper = styled.div`
    position: relative;
    display: inline-block;
  `;

  const RankNumber = styled.div`
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
  `;

  const RankSup = styled.div`
    position: absolute;
    top: 4px;
    left: 12px;
    font-size: 8px;       /* taille plus petite */
    line-height: 1;
    color: grey;          /* couleur grise */
    margin: 0;
    padding: 0;
  `;

  const RankSub = styled.div`
    font-size: 10px;
    line-height: 1;
    color: grey;
    margin-top: 2px;
    position: relative;
    left: 5px;
  `;

  const NoFormationText = styled.div`
    color: #d93025;
    font-size: 14px;
    font-family: 'Gabarito', sans-serif;
    font-weight: 500;
  `;

  const SpecialityInfo = styled.div`
    margin-left: 16px;
    text-align: left;
  `;
  const SpecialityTitle = styled.div`
    color: #474747;
    font-size: 12px;
    font-family: 'Gabarito', sans-serif;
    margin-bottom: 4px;
  `;
  const SpecialityIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
  `;
  const SpecialityIconPlaceholder = styled.div`
    width: 30px;
    height: 30px;
    background: #ccc;
    border-radius: 50%;
    margin-right: 10px;
  `;
  const SpecialityName = styled.div`
    color: black;
    font-size: 13px;
    font-family: 'Gabarito', sans-serif;
    font-weight: 400;
  `;
  const SpecialityRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `;
  const SpecialityDescription = styled.div`
    font-size: 10px;
    color: #666;
    margin-top: 4px;
  `;

  // eslint-disable-next-line no-unused-vars
  function renderSpecialityItem(specialityName, rank, max, speIconMap) {
    if (rank === 0) {
      return (
        <SpecialityItem key={specialityName}>
          <NoFormationText>
            Cette spé n'est pas présente dans la formation !
          </NoFormationText>
        </SpecialityItem>
      );
    }

    return (
      <SpecialityItem key={specialityName}>
        <DonutProgress
          rank={rank}
          max={max}
          size={90}
          thickness={14}
          trackColor="#bcbcff"
          fillColor="#420eef"
          moyenne_g={null}
        >
          <RankWrapper>
            <RankNumber>{rank}</RankNumber>
            <RankSup>{rank === 1 ? 'ère' : 'ème'}</RankSup>
          </RankWrapper>
          <RankSub>/{max}</RankSub>
        </DonutProgress>

        <SpecialityInfo>
          <SpecialityTitle>Spécialité</SpecialityTitle>
          <SpecialityRow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {speIconMap.find(item => item.value === specialityName)?.icon ? (
                <SpecialityIcon
                  src={speIconMap.find(item => item.value === specialityName)?.icon}
                  alt={specialityName}
                />
              ) : (
                <SpecialityIconPlaceholder />
              )}
              <SpecialityName>{obj_mapper_spé[specialityName] || specialityName}</SpecialityName>
            </div>
            <SpecialityDescription>
              '{specialityName}' est la {rank}ème spécialité la plus courante 
              sur les {max} spécialités des étudiants ayant intégré la formation.
            </SpecialityDescription>
          </SpecialityRow>
        </SpecialityInfo>
      </SpecialityItem>
    );
  }

  // eslint-disable-next-line no-unused-vars
  function renderMoyenne(moyenne_générale) {
    if (moyenne_générale === null || moyenne_générale === 0) {
      return (
        <SpecialityItem key={"moyenne"}>
          <NoFormationText>
            Pas de données sur la moyenne générale de la formation !
          </NoFormationText>
        </SpecialityItem>
      );
    }

    return (
      <SpecialityItem key={"moyenne"}>
        <DonutProgress
          rank={moyenne_générale}
          max={20}
          size={90}
          thickness={14}
          trackColor="#bcbcff"
          fillColor="#420eef"
          moyenne_g={moyenne_générale}
        >
          <RankWrapper>
            <RankNumber>{moyenne_générale}</RankNumber>
          </RankWrapper>
          <RankSub style={{ marginBottom: '3px' }}>/20</RankSub>
        </DonutProgress>

        <SpecialityInfo>
          <SpecialityTitle>Moyenne générale</SpecialityTitle>
          <SpecialityRow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SpecialityIcon
                src={moyenne_croissance_icon}
                alt={"moyenne"}
              />
              <SpecialityName>
                Moyenne générale des lycéens ayant intégré la formation
              </SpecialityName>
            </div>
            <SpecialityDescription>
              Si ta moyenne générale est trop en dessous tu risques de galérer. 
              Si ta moyenne est trop au dessus tu risques de t'ennuyer.
            </SpecialityDescription>
          </SpecialityRow>
        </SpecialityInfo>
      </SpecialityItem>
    );
  }

  return (
    <Container isMobile={isMobile}>
      {isMobile ? (
        // Mobile layout
        <>
          {/* --- Gauge avec score global --- */}
          <Card isMobile={isMobile}>
            <CardTitle></CardTitle>
            <ScoreCenterWrapper>
              <Gauge percentage={scoreFormations} />
            </ScoreCenterWrapper>
          </Card>

          {/* --- Card: Correspondance avec tes domaines --- */}
          <Card isMobile={isMobile}>
            <CardTitle>Tes domaines favoris dans la formation</CardTitle>
            <Separator />
            <DomainList>
              {domainesToShow.length === 0 && (
                <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  Aucun domaine ne correspond à tes domaines favoris.
                </p>
              )}
              {domainesToShow.map((domain, idx) => (
                <DomainItem key={idx}>
                  {normalizedDomainMap[normalizeString(domain)] ? (
                    <DomainIcon
                      src={normalizedDomainMap[normalizeString(domain)]['icon']}
                      alt={domain}
                    />
                  ) : (
                    <DefaultDomainIcon />
                  )}
                  <DomainName>{domain}</DomainName>
                </DomainItem>
              ))}
              {matchedDomains.length > initialDomainCount && (
                <VoirPlusButton isMobile={isMobile} onClick={() => setShowAllDomaines(prev => !prev)}>
                  {showAllDomaines ? 'voir moins' : 'voir plus...'}
                </VoirPlusButton>
              )}
            </DomainList>
          </Card>

          {/* --- Affichage de la moyenne générale --- */}
          <Card isMobile={isMobile}>
            <CardTitle>Moyenne générale</CardTitle>
            <Separator />
            {renderMoyenne(moyenne_générale)}
          </Card>

          {/* --- Card: Correspondance avec ton caractère --- */}
          <Card isMobile={isMobile}>
            <CardTitle>Pourquoi la formation te correspond</CardTitle>
            <Separator />
            <ListSection>
              {/* Replace emojis with icons */}
              {positivesToShow.map((item, idx) => (
                <ListItem key={`pos-${idx}`}>
                  <IconCheck src={checkIcon} alt="check" />
                  <ListText>{item.mot}</ListText>
                </ListItem>
              ))}
              {filteredPositives.length > initialListCount && (
                <VoirPlusButton isMobile={isMobile} onClick={() => setShowAllPositives(prev => !prev)}>
                  {showAllPositives ? 'voir moins' : 'voir plus...'}
                </VoirPlusButton>
              )}
            </ListSection>
            <ListSection>
              {negativesToShow.map((item, idx) => (
                <ListItem key={`neg-${idx}`}>
                  <IconCross src={crossIcon} alt="cross" />
                  <ListText>{item.mot}</ListText>
                </ListItem>
              ))}
              {filteredNegatives.length > initialListCount && (
                <VoirPlusButton isMobile={isMobile} onClick={() => setShowAllNegatives(prev => !prev)}>
                  {showAllNegatives ? 'voir moins' : 'voir plus...'}
                </VoirPlusButton>
              )}
            </ListSection>
          </Card>
        </>
      ) : (
        // Desktop layout
        <>
          <MainSection isMobile={isMobile}>
            {/* --- Gauge avec score global --- */}
            <Card isMobile={isMobile} equalHeight>
              <CardTitle></CardTitle>
              <ScoreCenterWrapper>
                <Gauge percentage={scoreFormations} />
              </ScoreCenterWrapper>
            </Card>

            {/* --- Card: Correspondance avec tes domaines --- */}
            <Card isMobile={isMobile} equalHeight>
              <CardTitle>Tes domaines favoris dans la formation</CardTitle>
              <Separator />
              <DomainList>
                {domainesToShow.length === 0 && (
                  <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                    Aucun domaine ne correspond à tes domaines favoris.
                  </p>
                )}
                {domainesToShow.map((domain, idx) => (
                  <DomainItem key={idx}>
                    {normalizedDomainMap[normalizeString(domain)] ? (
                      <DomainIcon
                        src={normalizedDomainMap[normalizeString(domain)]['icon']}
                        alt={domain}
                      />
                    ) : (
                      <DefaultDomainIcon />
                    )}
                    <DomainName>{domain}</DomainName>
                  </DomainItem>
                ))}
              </DomainList>
            </Card>

            {/* --- Affichage de la moyenne générale --- */}
            <Card isMobile={isMobile} fullWidth style={{ marginTop: '20px' }}>
              <CardTitle>Moyenne générale</CardTitle>
              <Separator />
              {renderMoyenne(moyenne_générale)}
            </Card>
          </MainSection>

          {/* --- Card: Correspondance avec ton caractère --- */}
          <Card isMobile={isMobile} fullWidth style={{ marginTop: '20px' }}>
            <CardTitle>Pourquoi la formation te correspond</CardTitle>
            <Separator />
            <ListSectionContainer isMobile={isMobile}>
              <ListColumn isMobile={isMobile}>
                {filteredPositives.map((item, idx) => (
                  <ListItem key={`pos-${idx}`}>
                    <IconCheck src={checkIcon} alt="check" />
                    <ListText>{item.mot}</ListText>
                  </ListItem>
                ))}
              </ListColumn>
              <ListColumn isMobile={isMobile}>
                {filteredNegatives.map((item, idx) => (
                  <ListItem key={`neg-${idx}`}>
                    <IconCross src={crossIcon} alt="cross" />
                    <ListText>{item.mot}</ListText>
                  </ListItem>
                ))}
              </ListColumn>
            </ListSectionContainer>
          </Card>
        </>
      )}
    </Container>
  );
};

export default CorrespondanceFormation;