// src/contexts/AuthContext.js
// ----------------------------------------------------------------
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import apiCall from '../utils/api';
import { setUser as setLocalUser, logout as authLogout } from '../utils/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Memoize protected routes so its reference is stable
  const protectedRoutes = useMemo(
    () => [
      '/chat',
      '/classement',
      '/settings',
      '/card-type-formation',
      '/fiche-formation',
      '/formation',
      '/favoris',
      '/profile',
      '/profile/modify/city',
      '/profile/modify/type-formation',
      '/profile/modify/domaines-favoris',
      '/profile/modify/domaine-formation',
      '/profile/modify/specialites',
      '/profile/modify/type-bac',
      '/profile/modify/lycee',
      '/profile/modify/phone',
      '/profile/modify/swipe',
      '/profile/modify/user-type',
    ],
    []
  );

  const handleLogout = useCallback(() => {
    console.log('Logging out user');
    authLogout();
    setUserState(null);
    setError(null);
    // Remove the flag when logging out
    localStorage.removeItem('hasAccessToken');

    // If the current route is protected, bounce user to '/'
    const currentPath = location.pathname;
    const isOnProtectedRoute = protectedRoutes.some((route) =>
      currentPath.startsWith(route)
    );
    if (isOnProtectedRoute) {
      navigate('/');
    }
  }, [navigate, protectedRoutes]);

  const fetchUser = useCallback(
    async (retryCount = 0) => {
      console.log(`[fetchUser] Attempt #${retryCount}...`);
      try {
        const userData = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
          'GET'
        );
        console.log('[fetchUser] Received userData:', userData);
        setUserState(userData);
        setLocalUser(userData);
        setError(null);
      } catch (error) {
        if (error.message.includes('401')) {
          handleLogout(); // unauthorized → logout
          return;
        } else {
          if (retryCount < 3) {
            const delay = 1000 * 2 ** retryCount;
            await new Promise((res) => setTimeout(res, delay));
            return fetchUser(retryCount + 1);
          } else {
            console.error(
              'Max retries for network/5xx errors. Not logging out.'
            );
            setError('Cannot contact server. Check your connection and try again.');
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [handleLogout]
  );

  async function fetchUserWithRetries(retryCount = 3) {
    for (let i = 0; i < retryCount; i++) {
      try {
        const userData = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
          'GET'
        );
        return userData; // success
      } catch (error) {
        if (error.message.includes('401')) {
          // If 401, short wait and try again
          await new Promise((res) => setTimeout(res, 200));
        } else {
          // Other error, let it propagate
          throw error;
        }
      }
    }
    throw new Error('Unauthorized after multiple retries');
  }

  // Check if an access token exists either via localStorage flag or document.cookie.
  // (If your cookie is HttpOnly, document.cookie won’t show it.)
  const hasAccessToken = () => {
    if (localStorage.getItem('hasAccessToken') === 'true') {
      return true;
    }
    return document.cookie
      .split(';')
      .some((cookie) => cookie.trim().startsWith('access_token='));
  };

  const checkAuth = useCallback(async () => {
    // Wait a short period to allow cookies/localStorage to update.
    await new Promise((resolve) => setTimeout(resolve, 300));

    // Debug logs
    console.log('document.cookie:', document.cookie);
    console.log(
      'hasAccessToken flag in localStorage:',
      localStorage.getItem('hasAccessToken')
    );

    if (!hasAccessToken()) {
      console.log(
        'No access token found. Skipping API call to fetch user info.'
      );
      setUserState(null);
      setLoading(false);
      return;
    }

    try {
      const userData = await fetchUserWithRetries(3);
      setUserState(userData);
      setLocalUser(userData); // Save to localStorage as well
    } catch (err) {
      console.error('Still unauthorized after retries:', err);
      setUserState(null);
      // Optionally, you could call handleLogout() here
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Call "login()" after a successful login process.
   * Here, we set the localStorage flag to indicate that an access token exists.
   */
  const login = useCallback(
    async (userData = null) => {
      setLoading(true);
      try {
        // Set the flag in localStorage on successful login.
        localStorage.setItem('hasAccessToken', 'true');
        await checkAuth();
        navigate('/classement');
      } catch (err) {
        console.error('Login error:', err);
        setError('Failed to log in. Please try again.');
      } finally {
        setLoading(false);
      }
    },
    [checkAuth, navigate]
  );

  // On mount, check if we are logged in
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const value = {
    user,
    loading,
    error,
    isAuthenticated: !!user,
    token: null, // We store token in a cookie, so no explicit token here
    login,
    logout: handleLogout,
    checkAuth,
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthContext;
