import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import styled from 'styled-components';
import theme from '../../theme';

import {
  Container
} from './styles';

import {
  ReturnButton
} from '../Onboarding/styles';

import {
  ContentWrapperQuestions,
  LogoImageQuestions,
  ButtonText,
  ChatTextQuestions,
} from './styles';


import MeoWrite from '../../assets/meo_write.png';
import RewriteIcon from '../../assets/ecrire.png'; // Assuming this exists in the assets folder
import useDeviceType from '../useDeviceType';
// Import the icon mapping utility
import { getIcon } from './BubbleIcons';

const RelectureContentWrapperQuestions = styled(ContentWrapperQuestions)`
  height: 100%;
`;
// Base button component
const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.15s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

// Logo container with relative positioning
export const LogoContainer = styled.div`
  position: relative;
  margin: 20px 0 0 5%;
  width: 15%;
  max-width: 120px;
  display: flex;
  justify-content: center;
  float: left;
`;

// Updated SubmitButton styled component
const SubmitButton = styled(BaseButton)`
  width: 90%;
  // position: fixed;
  // bottom: calc(var(--vh, 1vh) * 5);
  // left: 50%;
  // transform: ${props =>props.active ? 'translateX(-50%) translateY(4px)' : 'translateX(-50%)'};
  max-width: 600px;
  border-radius: 15px;
  height: 53px;
  background-color: ${props => (props.disabled ? '#efecec' : '#ff2768')};
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 3px 0px #d42359'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease;
  margin:auto;
`;

// Bold text for emphasis
const BoldText = styled.span`
  font-weight: 600;
`;

// Container for all answer boxes
const AnswerBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  // margin-top: -50%; // More space at top
  max-height: calc(var(--vh, 1vh) * 75); // Set max height to 75% of viewport
  overflow-y: auto;
  width: 100%;
  clear: both; // Clear the float from LogoContainer
  // margin-bottom: calc(var(--vh, 1vh) * 10); // Add padding to prevent button overlap
    margin-top: -20vh;
  
    scrollbar-width: large;
    scrollbar-color: ${theme.colors.primary};
  
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.background};
      border-radius: 2px;
    }
`;

// Individual answer box
const AnswerBox = styled.div`
  width: 100%;
  min-height: 70px; // Minimum height
  height: auto; // Allow height to grow based on content
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #E6E6E6;
  background: #FFF;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 5px 0; // Add vertical padding
`;


// Icon container on the left
const IconContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Answer text in the middle
const AnswerText = styled.div`
  width: 70%;
  color: #3B3A3A;
  font-family: Gabarito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px;
  /* Remove max-height and overflow to allow text to expand naturally */
`;

// Rewrite icon on the right
const RewriteIconContainer = styled.div`
  width: 15%;
  display: flex;
  margin-right: 5%;
`;

// QuestionIcon styled component for the answer boxes
const QuestionIcon = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  aspect-ratio: 1/1;
`;

// Error message styling
const ErrorMessage = styled.div`
  color: red;
  margin: 10px 0;
  text-align: center;
  font-family: 'Gabarito';
`;

// Chat bubble container for Relecture
const RelectureChatBubble = styled.div`
  position: relative;
  top: calc(var(--vh, 1vh) * -30);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 90%;
  max-width: 700px;
  margin-left: 20%;
  margin-top: 60px;
`;

const Relecture = () => {
  // State for questions data
  const [questionsData, setQuestionsData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [buttonActive, setButtonActive] = useState(false);
  const [allBoxesVisible, setAllBoxesVisible] = useState(false);
  const [formationId, setFormationId] = useState(null);
  const {isMobile} = useDeviceType();
  
  // References
  const containerRef = useRef(null);
  const lastBoxRef = useRef(null);
  
  // Device and navigation hooks
  const navigate = useNavigate();
  // Force layout recalculation after component mounts and data loads
  useEffect(() => {
    if (!loading && questionsData.length > 0) {
      // Force a layout recalculation with a slight delay
      const timer = setTimeout(() => {
        // Trigger a resize event to force layout recalculation
        window.dispatchEvent(new Event('resize'));
        
        // If we have a container ref, force a reflow by accessing its layout properties
        if (containerRef.current) {
          // Reading a layout property forces a reflow
          const forceReflow = containerRef.current.offsetHeight;
          console.log('Forced layout recalculation, container height:', forceReflow);
        }
        
        // Fix for the AnswerBoxesContainer margin
        const answerBoxes = document.querySelector('div[class^="Relecture__AnswerBoxesContainer"]');
        if (answerBoxes) {
          // Apply correct positioning
          answerBoxes.style.clear = 'both';
          answerBoxes.style.marginTop = isMobile ? '-15vh' : '-20vh';
        }
        
        // Fix for the RelectureChatBubble
        const chatBubble = document.querySelector('div[class^="Relecture__RelectureChatBubble"]');
        if (chatBubble) {
          chatBubble.style.marginLeft = '20%';
        }
      }, 100); // Small delay to ensure DOM is ready
      
      return () => clearTimeout(timer);
    }
  }, [loading, questionsData, isMobile]);

  // Get formation ID on component mount
  useEffect(() => {
    try {
      const storedFormation = localStorage.getItem('selectedFormation');
      if (storedFormation) {
        const formation = JSON.parse(storedFormation);
        setFormationId(formation.id);
        console.log('Formation ID:', formation.id);
      } else {
        throw new Error('Aucune formation sélectionnée. Veuillez retourner à l\'étape précédente.');
      }
    } catch (error) {
      console.error('Error getting formation ID:', error);
      setError(error.message || 'Une erreur est survenue lors du chargement de la formation.');
    }
  }, []);
  
  // Fetch questions data from localStorage based on formation ID
  useEffect(() => {
    if (!formationId) return;
    
    try {
      // Get the lettresData from localStorage
      const lettresDataString = localStorage.getItem('lettresData');
      
      if (!lettresDataString) {
        throw new Error('Aucune donnée trouvée. Veuillez retourner à l\'étape précédente.');
      }
      
      const lettresData = JSON.parse(lettresDataString);
      
      if (!lettresData[formationId] || !lettresData[formationId].questions) {
        throw new Error('Aucune question trouvée pour cette formation. Veuillez retourner à l\'étape précédente.');
      }
      
      const questions = lettresData[formationId].questions;
      
      if (!Array.isArray(questions) || questions.length === 0) {
        throw new Error('Format de questions invalide. Veuillez réessayer.');
      }
      
      // Check if all questions have answers
      const hasEmptyAnswers = questions.some(q => !q.answer || q.answer.trim() === '');
      
      if (hasEmptyAnswers) {
        console.warn('Some questions have empty answers. This may cause issues in the next steps.');
      }
      
      setQuestionsData(questions);
      console.log(`Loaded ${questions.length} questions for formation ${formationId}`);
      
    } catch (error) {
      console.error('Error loading questions data:', error);
      setError(error.message || 'Une erreur est survenue lors du chargement des questions.');
    } finally {
      setLoading(false);
    }
  }, [formationId]);
  
  // Set up intersection observer to check if all boxes are visible
  useEffect(() => {
    if (!lastBoxRef.current || questionsData.length === 0) return;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If the last box is visible, enable the button and keep it enabled
        if (entry.isIntersecting) {
          setAllBoxesVisible(true);
        }
        // Don't set to false when scrolling away - once enabled, stays enabled
      },
      { threshold: 0.5 } // Require at least 50% of the element to be visible
    );
    
    observer.observe(lastBoxRef.current);
    
    return () => {
      if (lastBoxRef.current) {
        observer.unobserve(lastBoxRef.current);
      }
    };
  }, [questionsData]);

  // Clear any edit mode flag when entering this component
  useEffect(() => {
    localStorage.removeItem('editQuestionIndex');
  }, []);
  
  // Handle back button
  const handleBack = () => {
    // Go back to the last question of the sequence
    if (questionsData.length > 0) {
      // Clear any edit mode flag
      localStorage.removeItem('editQuestionIndex');
      // Navigate back to the questions component, which will show the last question
      // since we're not setting editQuestionIndex
      navigate('/lettres-questions');
    } else {
      // Fallback if no questions exist
      navigate('/lettres-validate');
    }
  };
  
  // Handle box click to edit a specific answer
  const handleBoxClick = (index) => {
    console.log("Box clicked at index:", index);
    // Save the index of the question to edit
    localStorage.setItem('editQuestionIndex', index);
    // Navigate to Questions component
    navigate('/lettres-questions');
  };
  
  // Updated handle button press and release
  const handlePressStart = () => {
    if (!allBoxesVisible) return;
    setButtonActive(true);
  };
  
  const handlePressEnd = () => {
    if (!allBoxesVisible) return;
    setButtonActive(false);
    
    // Navigate to the next page
    navigate('/lettres-wait');
  };
  
  // Show loading or error state if needed
  if (loading) {
    return (
      <Container isMobile={isMobile}>
        <RelectureContentWrapperQuestions>
          <div>Chargement des questions et réponses...</div>
        </RelectureContentWrapperQuestions>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container isMobile={isMobile}>
        <ReturnButton onClick={() => navigate('/lettres-questions')}>
          <IoIosArrowBack />
        </ReturnButton>
        <RelectureContentWrapperQuestions>
          <ErrorMessage>{error}</ErrorMessage>
          <SubmitButton
            onClick={() => navigate('/lettres-questions')}
            active={false}
          >
            <ButtonText>Retour</ButtonText>
          </SubmitButton>
        </RelectureContentWrapperQuestions>
      </Container>
    );
  }
  
  return (
    <Container isMobile={isMobile} ref={containerRef}>
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <RelectureContentWrapperQuestions>
        <LogoContainer>
          <LogoImageQuestions 
            src={MeoWrite} 
            alt="Meo Write" 
            onLoad={() => console.log('LogoImageQuestions loaded in DOM')}
            onError={(e) => console.error('LogoImageQuestions failed to load in DOM:', e)}
          />
        </LogoContainer>
        
        <RelectureChatBubble>
          <ChatTextQuestions isVisible={true}>
            <span>Relis-toi bien avant que je passe à l'écriture de la lettre ! N'oublie pas de <BoldText>détailler un maximum</BoldText> pour m'aider </span>
          </ChatTextQuestions>
        </RelectureChatBubble>
        
        <AnswerBoxesContainer>
          {questionsData.map((item, index) => (
            <AnswerBox 
              key={index} 
              onClick={() => handleBoxClick(index)}
              // Reference the last box for intersection observer
              ref={index === questionsData.length - 1 ? lastBoxRef : null}
            >
              <IconContainer>
                <QuestionIcon 
                  src={getIcon(item.icon)} 
                  alt="Question Icon" 
                />
              </IconContainer>
              <AnswerText>
                {item.answer || "Pas de réponse"}
              </AnswerText>
              <RewriteIconContainer>
                <QuestionIcon 
                  src={RewriteIcon} 
                  alt="Rewrite" 
                />
              </RewriteIconContainer>
            </AnswerBox>
          ))}
        </AnswerBoxesContainer>
      </RelectureContentWrapperQuestions>
      
      <SubmitButton
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={() => setButtonActive(false)}
        onTouchStart={handlePressStart}
        onTouchEnd={handlePressEnd}
        // active={buttonActive}
        // disabled={!allBoxesVisible}
      >
        <ButtonText>Écrire ma lettre !</ButtonText>
      </SubmitButton>
    </Container>
  );
};

export default Relecture;