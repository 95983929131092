import styled from 'styled-components';

/* 
   1) Main container for the desktop version
   - 60% width and 30% left margin
   - to match the side menu occupying 30%
*/
export const SettingsContainer = styled.div`
  width: 60%;
  margin-left: 30%;
  min-height: 80vh;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px;
  @media (max-width: 800px) {
    margin-left: 15%;
    width: 80%;
  }
`;

/*
  2) A 2-column grid to place your boxes side by side
*/
export const SettingsGrid = styled.div`
  margin-top: 15%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* space between boxes */
`;

/* =========================================================
   Boxes below: (Aide, Email, Infos, Social, CGU, Actions)
   Remain mostly the same. 
   We just keep them as "cards" that fit into the grid.
========================================================= */

export const BoxAide = styled.div`
  width: 100%;
  height: 122px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #ffd400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.medium};
  cursor: pointer;
  @media (max-width: 800px) {
    height: 200px;
  }
`;

export const AideImage = styled.img`
  width: 38px;
  height: 33px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

export const AideTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AideTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const AideSubtitle = styled.div`
  color: #000;
  font-family: 'Geist';
  font-size: 14px;
  font-weight: 100;
  white-space: pre-line; /* allows line breaks via \n */
`;

export const AideArrow = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.spacing.small};
  transform: scaleX(-1); /* flip horizontally */
`;

export const BoxEmail = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const BoxEmailTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const BoxEmailContent = styled.div`
  color: #9e9d9d;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BoxInfos = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const InfosTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const InfosText = styled.div`
  color: #9e9d9d;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
`;

export const BoxSocial = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const SocialTitle = styled.div`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

export const SocialIconsWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
`;

export const SocialIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const BoxCGU = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const CGUImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

export const CGUText = styled.a`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

/* 
   For the "logout" and "delete" boxes:
   We'll put them side by side in the same row,
   so the entire "ActionsContainer" is just
   one item in the 2-column grid.
*/
export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
`;

export const BoxLogout = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const BoxDelete = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
`;

export const LogoutText = styled.button`
  background: none;
  border: none;
  color: #9e9d9d;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const DeleteText = styled.button`
  background: none;
  border: none;
  color: #ff2626;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

/* 
   Version info at bottom
*/
export const VersionText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  color: #9e9d9d;
  text-align: center;
`;
