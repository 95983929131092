import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import { IoIosArrowBack } from "react-icons/io";
import styled from 'styled-components';
import theme from '../../theme';



import {
  ReturnButton
} from '../Onboarding/styles';

import {
  ContentWrapperQuestions,
  LogoImageQuestions,
  ButtonText,
  ChatBubbleContainerQuestions,
  ChatTextQuestions,
} from './styles';

import ProgressBar from '../ProgressBar';
import MeoWrite from '../../assets/meo_write.png';
// Import the bubble PNG
import BubblePng from '../../assets/bulle-de-pensee.png';

// Import the icon mapping utility
import { getIcon } from './BubbleIcons';
import {
  Container
} from './styles';


// Base button component
const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.15s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  top: -70px;
  left: -50%;
  width: 70px;
  height: 70px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Background bubble image
const BubbleBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scaleX(-1); /* Keep the original flip transformation */
  z-index: 1;
`;

// Logo container with relative positioning to allow absolute positioning of the bubble
export const LogoContainer = styled.div`
  position: relative;
  margin: 100px 0 40px 5%;
  width: 15%;
  max-width: 120px;
  display: flex;
  justify-content: center;
  float: left; // Add float to position it on the left
`;

// Updated SubmitButton styled component
const SubmitButton = styled(BaseButton)`
  width: 100%;
  // position: fixed;
  // bottom: calc(var(--vh, 1vh) * -10);
  // left: 50%;
  /* When active, shift down 4px */
  max-width: 600px;
  border-radius: 15px;
  height: 53px;
  background-color: ${props => (props.disabled ? '#efecec' : '#ff2768')};
  /* Remove shadow when disabled or pressed */
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 3px 0px #d42359'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease;
`;

// Positioning for the input and button container
const InputAndButtonContainer = styled.div`
  // position: fixed;
  // left: 50%;
  // transform: translateX(-50%);
  margin:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.small};
  width: 90%;
  max-width: 600px;
  ${props => props.isMobile ? 'top: 30%;' : 'top: 45%;'}
`;

// Taller text area
const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px; // Taller than original
  border: 2px solid #e6e6e6;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  font-family: 'Gabarito';
  color: ${theme.colors.noir};
  line-height: 28px;
  padding-left: 5%;
  padding-top: 16px;
  outline: none;
  resize: none;
  overflow: auto;
  margin-top: 100px;

  &::placeholder {
    font-size: 16px;
    font-family: 'Gabarito';
    color: #e6e6e6;
    text-align: left;
  }
`;

const QuestionIcon = styled.img`
  position: relative;
  width: 40%;
  height: 40%;
  z-index: 2;
  object-fit: contain;
  /* Adjust these values to fine-tune the position inside the bubble */
  margin-top: -10px;
  margin-left: 1px;
`;

// Error message styling
const ErrorMessage = styled.div`
  color: red;
  margin: 10px 0;
  text-align: center;
  font-family: 'Gabarito';
`;


const Questions = () => {
  // State for questions and answers
  const [questionsData, setQuestionsData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [displayedText, setDisplayedText] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formationId, setFormationId] = useState(null);
  // const {isMobile} = useDeviceType();
  // State for button interaction
  const [buttonActive, setButtonActive] = useState(false);
  
  // References
  const textAreaRef = useRef(null);
  
  // Device and navigation hooks
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if we're in edit mode based on localStorage
  useEffect(() => {
    const editIndex = localStorage.getItem('editQuestionIndex');
    
    if (editIndex !== null) {
      setIsEditMode(true);
      setCurrentQuestionIndex(parseInt(editIndex, 10));
      console.log('Edit mode detected, editing question index:', editIndex);
    } else if (location.pathname === '/lettres-questions') {
      // Only reset to first question when in normal mode AND 
      // we're actually on the questions page
      setIsEditMode(false);
      setCurrentQuestionIndex(0);
      console.log('Normal mode, reset to first question');
    }
    // Do nothing if we're navigating away
  }, [location.pathname]);
  
  // Calculate progress percentage based on current question
  const progressPercentage = (!isEditMode && questionsData.length > 0) 
    ? ((currentQuestionIndex + 1) / questionsData.length) * 100 
    : 0;

  useEffect(() => {
    console.log('Questions component mounted');
    console.log('Is Edit Mode:', isEditMode);
    console.log('Current question index on mount:', currentQuestionIndex);
    
    // Get the selected formation from localStorage
    const storedFormation = localStorage.getItem('selectedFormation');
    if (storedFormation) {
      try {
        const formation = JSON.parse(storedFormation);
        setFormationId(formation.id);
        console.log('Formation ID:', formation.id);
      } catch (error) {
        console.error('Error parsing formation:', error);
      }
    }
    
  }, [currentQuestionIndex, questionsData, isEditMode]);
  
// In Questions.js, modify the useEffect that loads questions from localStorage
// Around line 358-360 in your paste-2.txt file

useEffect(() => {
  if (!formationId) return;
  
  // Add this to prevent any navigation issues during loading
  setLoading(true);
  
  try {
    // Get the lettresData from localStorage
    const lettresDataString = localStorage.getItem('lettresData');
    let lettresData = {};
    
    if (lettresDataString) {
      try {
        lettresData = JSON.parse(lettresDataString);
      } catch (error) {
        console.error('Error parsing lettresData:', error);
        lettresData = {};
      }
    }
    
    // Get selected formation data
    const storedFormation = localStorage.getItem('selectedFormation');
    let formationName = '';
    if (storedFormation) {
      try {
        const formation = JSON.parse(storedFormation);
        formationName = formation.name || '';
      } catch (error) {
        console.error('Error parsing formation:', error);
      }
    }
    
    // IMPORTANT: Set a flag to prevent navigation during initial load
    const isInitialLoad = true;
    
    // Check if we have data for this formation
    if (lettresData[formationId]) {
      console.log('Found existing data for formation:', formationId);
      
      // IMPORTANT: Normalize the questions data to ensure consistent format
      // This handles both old and new formats
      const normalizedQuestions = lettresData[formationId].questions.map(item => {
        // If it's already an object with question property, ensure it has all required fields
        if (item && typeof item === 'object' && item.question) {
          return {
            question: item.question,
            answer: item.answer || '',
            icon: item.icon || 'book_industry_icon.png'
          };
        } 
        // If it's a string, convert to object with defaults
        else if (typeof item === 'string') {
          return {
            question: item,
            answer: '',
            icon: 'book_industry_icon.png'
          };
        }
        // Fallback for any other format
        return {
          question: 'Question non disponible',
          answer: '',
          icon: 'book_industry_icon.png'
        };
      });
      
      // Use the normalized data - Use a callback to ensure proper state update
      setQuestionsData(normalizedQuestions);
      
      // Update localStorage with the normalized format to prevent future issues
      lettresData[formationId].questions = normalizedQuestions;
      localStorage.setItem('lettresData', JSON.stringify(lettresData));
      
      console.log(`Loaded and normalized ${normalizedQuestions.length} questions for formation ${formationId}`);
    } else {
      // Try to get the legacy data format and convert it
      const legacyQuestions = localStorage.getItem('lettreQuestions');
      const legacyAnswers = localStorage.getItem('lettreAnswers');
      
      if (!legacyQuestions) {
        throw new Error('Aucune question trouvée. Veuillez retourner à l\'étape précédente.');
      }
      
      const parsedQuestions = JSON.parse(legacyQuestions);
      let parsedAnswers = [];
      
      if (legacyAnswers) {
        parsedAnswers = JSON.parse(legacyAnswers);
      }
      
      if (!Array.isArray(parsedQuestions) || parsedQuestions.length === 0) {
        throw new Error('Format de questions invalide. Veuillez réessayer.');
      }
      
      // Convert to the new format
      const newFormatQuestions = parsedQuestions.map((q, index) => ({
        question: typeof q === 'string' ? q : (q.question || 'Question non disponible'),
        icon: (q && q.icon) ? q.icon : 'book_industry_icon.png',
        answer: parsedAnswers[index] || ''
      }));
      
      // Update state with the converted data
      setQuestionsData(newFormatQuestions);
      
      // Save to the new format in localStorage with the formation name
      lettresData[formationId] = {
        questions: newFormatQuestions,
        formation_name: formationName
      };
      
      localStorage.setItem('lettresData', JSON.stringify(lettresData));
      console.log(`Converted and saved ${newFormatQuestions.length} questions for formation ${formationId}`);
    }
  } catch (error) {
    console.error('Error loading questions:', error);
    setError(error.message || 'Une erreur est survenue lors du chargement des questions.');
  } finally {
    // IMPORTANT: Add a slight delay before setting loading to false
    // This ensures the component has time to fully render before any other actions
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }
}, [formationId]); // IMPORTANT: Remove dependencies that could cause re-renders
  
  // Track when question index changes to trigger animation
  const [shouldAnimate, setShouldAnimate] = useState(true);
  
  // When the current question index changes, set shouldAnimate to true
  useEffect(() => {
    if (!isEditMode) {
      setShouldAnimate(true);
    }
  }, [currentQuestionIndex, isEditMode]);
  // Type animation effect for the current question (only in normal mode, not edit mode)
  useEffect(() => {
    // Only run animation if we should animate and we have valid data
    if (!shouldAnimate || !questionsData || questionsData.length === 0 || loading) return;
    
    // Skip animation in edit mode
    if (isEditMode) {
      // Safe access to question
      const questionObj = questionsData[currentQuestionIndex];
      if (!questionObj) {
        console.error('Missing question object for index:', currentQuestionIndex);
        return;
      }
      
      // Try to get question text in various formats
      const questionText = typeof questionObj === 'string' 
        ? questionObj 
        : (questionObj.question || '');
      
      setDisplayedText(questionText);
      setIsTyping(false);
      
      // Focus on textarea immediately in edit mode
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
      return;
    }
    
    // Add debug logs to see what's in the data
    console.log('Starting typing animation');
    console.log('Current question index:', currentQuestionIndex);
    console.log('Questions data:', questionsData);
    
    // Get the current question object
    const questionObj = questionsData[currentQuestionIndex];
    if (!questionObj) {
      console.error('Missing question object for index:', currentQuestionIndex);
      return;
    }
    
    // Safe access to question text with fallback
    // Handle both string format (new) and object format (old)
    const currentQuestion = typeof questionObj === 'string' 
      ? questionObj 
      : (questionObj.question || '');
    
    // Log the question text to verify it exists
    console.log('Current question text:', currentQuestion);
    
    let currentIndex = 0;
    setIsTyping(true);
    
    const typingInterval = setInterval(() => {
      if (currentIndex <= currentQuestion.length) {
        setDisplayedText(currentQuestion.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        // Once animation is complete, set shouldAnimate to false to prevent re-running
        setShouldAnimate(false);
        clearInterval(typingInterval);
        // Focus on textarea after typing animation completes
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }
    }, 15);
    
    return () => clearInterval(typingInterval);
  }, [currentQuestionIndex, questionsData, loading, isEditMode, shouldAnimate]);
  // Handle back button
  const handleBack = () => {
    if (isEditMode) {
      // In edit mode, go back to relecture page
      localStorage.removeItem('editQuestionIndex'); // Clear edit mode
      navigate('/lettres-relecture');
    } else if (currentQuestionIndex > 0) {
      // Go to previous question
      setCurrentQuestionIndex(prev => prev - 1);
      console.log('Moving to previous question:', currentQuestionIndex - 1);
    } else {
      // If we're on the first question, navigate back to validate-formation
      console.log('Going back to validate-formation page');
      navigate('/lettres-validate');
    }
  };
  
  // Handle text input - only updates state, does not save to localStorage
  const handleAnswerChange = (e) => {
    if (!formationId || questionsData.length === 0) return;
    
    // Create a copy of the questions data
    const updatedQuestionsData = [...questionsData];
    
    // Get the current question object
    const currentQuestionObj = updatedQuestionsData[currentQuestionIndex];
    
    // Update based on whether it's a string or object
    if (typeof currentQuestionObj === 'string') {
      // If it's a string, convert it to an object with question and answer
      updatedQuestionsData[currentQuestionIndex] = {
        question: currentQuestionObj,
        answer: e.target.value,
        icon: 'book_industry_icon.png' // Default icon
      };
    } else {
      // Otherwise update the existing object
      updatedQuestionsData[currentQuestionIndex] = {
        ...updatedQuestionsData[currentQuestionIndex],
        answer: e.target.value
      };
    }
    
    // Only update state, don't save to localStorage yet
    setQuestionsData(updatedQuestionsData);
  };
  
  
  // Check if current answer is empty
  const isCurrentAnswerEmpty = () => {
    const current = questionsData[currentQuestionIndex];
    if (!current) return true;
    
    if (typeof current === 'string') {
      // If question is stored as string, there's no answer yet
      return true;
    } else {
      // If question is an object, check the answer field
      return !current.answer || current.answer.trim().length === 0;
    }
  };
  // Updated handle button press and release
  const handlePressStart = () => {
    if (isTyping || isCurrentAnswerEmpty()) return;
    setButtonActive(true);
  };
  
  // 2. Update handlePressEnd function to save formation name - Around line 287
const handlePressEnd = () => {
    if (isTyping || isCurrentAnswerEmpty()) return;
    setButtonActive(false);
    
    // Debug log before saving
    console.log('Saving answers, current index:', currentQuestionIndex, 'of', questionsData.length);
    
    // Save current state to localStorage
    try {
      // Get the current lettresData
      const lettresDataString = localStorage.getItem('lettresData');
      let lettresData = {};
      
      if (lettresDataString) {
        lettresData = JSON.parse(lettresDataString);
      }
      
      // Get formation name from selectedFormation if available
      let formationName = '';
      const storedFormation = localStorage.getItem('selectedFormation');
      if (storedFormation) {
        try {
          const formation = JSON.parse(storedFormation);
          formationName = formation.name || '';
        } catch (error) {
          console.error('Error parsing formation:', error);
        }
      }
      
      // Update the lettresData with current state and include the formation name
      lettresData[formationId] = {
        ...lettresData[formationId],
        questions: questionsData,
        formation_name: formationName || lettresData[formationId]?.formation_name || ''
      };
      
      // Save to localStorage
      localStorage.setItem('lettresData', JSON.stringify(lettresData));
      console.log('Saved answers to localStorage for formation', formationId);
    } catch (error) {
      console.error('Error saving answers to localStorage:', error);
    }
    
    if (isEditMode) {
      // In edit mode, go back to relecture page
      localStorage.removeItem('editQuestionIndex'); // Clear edit mode
      console.log('Edit mode: returning to relecture page');
      navigate('/lettres-relecture');
    } else if (currentQuestionIndex < questionsData.length - 1) {
      // If we're not at the last question, go to the next one
      console.log('Moving to next question:', currentQuestionIndex + 1);
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      // We've answered all questions, proceed to relecture
      console.log('All questions answered, navigating to review');
      navigate('/lettres-relecture');
    }
  };
  
  // Show loading or error state if needed
  if (loading) {
    return (
      <Container>
        <ContentWrapperQuestions>
          <div>Chargement des questions...</div>
        </ContentWrapperQuestions>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container isMobile={isMobile}>
        <ReturnButton onClick={() => navigate('/lettres-validate')}>
          <IoIosArrowBack />
        </ReturnButton>
        <ContentWrapperQuestions>
          <ErrorMessage>{error}</ErrorMessage>
          <SubmitButton
            onClick={() => navigate('/lettres-validate')}
            active={false}
          >
            <ButtonText>Retour</ButtonText>
          </SubmitButton>
        </ContentWrapperQuestions>
      </Container>
    );
  }
  
  if (questionsData.length === 0) {
    return (
      <Container isMobile={isMobile}>
        <ReturnButton onClick={() => navigate('/lettres-validate')}>
          <IoIosArrowBack />
        </ReturnButton>
        <ContentWrapperQuestions>
          <ErrorMessage>Aucune question trouvée. Veuillez retourner à l'étape précédente.</ErrorMessage>
          <SubmitButton
            onClick={() => navigate('/lettres-validate')}
            active={false}
          >
            <ButtonText>Retour</ButtonText>
          </SubmitButton>
        </ContentWrapperQuestions>
      </Container>
    );
  }
  
  return (
    <Container isMobile={isMobile}>
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapperQuestions>
        {/* Show progress bar only in normal mode, not edit mode */}
        {!isEditMode && <ProgressBar startPercent={0} endPercent={progressPercentage} />}
        
        <LogoContainer>
          <BubbleContainer>
            <BubbleBackground 
              src={BubblePng} 
              alt="Bubble" 
              onLoad={() => console.log('BubbleBackground loaded in DOM')}
              onError={(e) => console.error('BubbleBackground failed to load in DOM:', e)}
            />
           {questionsData.length > 0 && questionsData[currentQuestionIndex] && (
              <QuestionIcon 
                src={getIcon(questionsData[currentQuestionIndex].icon || 'book_industry_icon.png')} 
                alt="Question Icon" 
                onLoad={() => console.log('QuestionIcon loaded in DOM')}
                onError={(e) => console.error('QuestionIcon failed to load in DOM', e)}
              />
            )}
          </BubbleContainer>
          <LogoImageQuestions 
            src={MeoWrite} 
            alt="Meo Write" 
            onLoad={() => console.log('LogoImageQuestions loaded in DOM')}
            onError={(e) => console.error('LogoImageQuestions failed to load in DOM:', e)}
          />
        </LogoContainer>
                    
        <ChatBubbleContainerQuestions isMobile={isMobile}>
          <ChatTextQuestions isVisible={displayedText.length > 0}>
            <span>{displayedText}</span>
          </ChatTextQuestions>
        </ChatBubbleContainerQuestions>
      </ContentWrapperQuestions>
      
      <InputAndButtonContainer isMobile={isMobile}>
        <StyledTextArea
          ref={textAreaRef}
          placeholder="Écris ta réponse..."
          value={questionsData[currentQuestionIndex]?.answer || ''}
          onChange={handleAnswerChange}
          disabled={isTyping}
        />
        <SubmitButton
          onMouseDown={handlePressStart}
          onMouseUp={handlePressEnd}
          onMouseLeave={() => setButtonActive(false)}
          onTouchStart={handlePressStart}
          onTouchEnd={handlePressEnd}
          active={buttonActive}
          disabled={isTyping || isCurrentAnswerEmpty()}
        >
          <ButtonText>
            {isEditMode ? "Sauvegarder" : 
              (currentQuestionIndex < questionsData.length - 1 ? "Continuer" : "Terminer")}
          </ButtonText>
        </SubmitButton>
      </InputAndButtonContainer>
    </Container>
  );
};

export default Questions;