import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import apiCall from '../../utils/api';
import { searchFormations, loadSearchData, metaToFormation } from './search-utils';
import { useCallback } from 'react';
import theme from '../../theme';

// Import shared assets
import localisation_icon from '../../assets/localisation_icon.png';
import checkIcon from '../../assets/true_icon.png';
import crossIcon from '../../assets/croix_rouge.png';
import chancesLowIcon from '../../assets/chances_tres_basses.png';
import chancesBasseIcon from '../../assets/chances_basses.png';
import chancesEleveesIcon from '../../assets/true_icon.png';
import chancesTresEleveesIcon from '../../assets/chances_tres_elevees.png';
import comparateurHeaderImage from '../../assets/meo_comparateur.png';
import loupe_grise from '../../assets/loupe_grise.png';
import loupe_blanche from '../../assets/loupe.png';
import dureeImg from '../../assets/duree.png';
import prixImg from '../../assets/prix.png';
import statutImg from '../../assets/statut.png';
import salaireImg from '../../assets/salaire_icon.png';
import debouchesImg from '../../assets/debouches_icon.png';
import poursuiteImg from '../../assets/diplome_icon.png';
import useDeviceType from '../useDeviceType';

import {domainIconMapRaw} from '../FicheFormation/domainIconMap';

// Définir la carte des niveaux de chances
const chancesLevelMap = [
  { range: [0, 20], text: "Très Basses", icon: chancesLowIcon },
  { range: [20, 40], text: "Basses", icon: chancesBasseIcon },
  { range: [40, 60], text: "Moyennes", icon: chancesBasseIcon },
  { range: [60, 80], text: "Élevées", icon: chancesEleveesIcon },
  { range: [80, 100], text: "Très élevées", icon: chancesTresEleveesIcon }
];

// Fonction pour obtenir le niveau de chances
const getChancesLevel = (percentage) => {
  for (const level of chancesLevelMap) {
    if (percentage >= level.range[0] && percentage <= level.range[1]) {
      return level;
    }
  }
  // Default fallback
  return chancesLevelMap[2]; // "Moyenne" as default
};

// Container pour les tags de domaine
export const DomaineTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-height: 60px;
`;

// Modifier le style de ScrollableContainer pour masquer les scrollbars tout en maintenant la fonctionnalité de défilement
export const ScrollableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px;
  margin-bottom: 40px;
  -webkit-overflow-scrolling: touch;
  
  /* S'assurer que toutes les colonnes ont la même hauteur */
  align-items: stretch;
  
    scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

export const Section = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  overflow: hidden;
`;

const SectionGray = styled(Section)`
  background-color: #f1f1f1;
  margin-bottom: 1px;
`;

export const DomainesSection = styled(Section)`
  min-height: 100px;
  display: flex;
  flex-direction: column;
`;

// Modifier FormationColumn pour assurer que toutes les colonnes ont la même structure
export const FormationColumn = styled.div`
  flex: 0 0 80%;
  max-width: 80%;
  background: white;
  border-radius: 25px;
  margin-right: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  
  /* Fixer une largeur minimale pour éviter que les colonnes ne deviennent trop étroites */
  min-width: 300px;
  
  @media (min-width: 768px) {
    flex: 0 0 45%;
    max-width: 45%;
  }
  
  /* S'assurer que toutes les sections à l'intérieur de la colonne ont des hauteurs cohérentes */
  & > div {
    flex-shrink: 0;
  }
`;

// Modifier le rendu des listes pour qu'elles aient une hauteur uniforme
export const AdvantageList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 60px; /* Hauteur minimum pour les listes d'avantages */
`;

export const DisadvantageList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 60px; /* Hauteur minimum pour les listes de désavantages */
`;

export const DebouchesList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 80px; /* Hauteur minimum pour les listes de débouchés */
`;

const PoursuiteList = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 80px; /* Hauteur minimum pour les listes de poursuites d'études */
`;

const Size20 = styled.div `
  height: 13px !important;
  position: absolute !important;
  // top: 112px !important;
  bottom: 15px;
  left: 50% !important;
  transform: translateX(-50%) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: auto !important;
`;

// Styled Components
const Container = styled.div`

  width:  ${props => props.isMobile ?
    '99%' :
    'calc(100% - 330px)'
  };idth: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff5f8;
  font-family: 'Gabarito', sans-serif;
  padding-bottom: 100px; // Ajouter du padding en bas
  margin-left:  ${props => props.isMobile ?
    '0' :
    '330px'
  };
  overflow-x: hidden;
`;

// Updated Header component - only shown when formations exist
export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(90deg, #CC00FF 0%, #0F04E3 100%);
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
`;

// New Header Image Container - shown when no formations exist
export const HeaderImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0;
  width: 100%;
`;

export const HeaderImage = styled.img`
  max-width: 150px;
  height: auto;
`;

export const ReturnButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
`;

// Updated SearchBox styles
export const SearchBox = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  z-index: 100;
  background-color: #fff5f8; // Même couleur que le fond
  display: flex;
  justify-content: center;
`;

// Updated SearchInputContainer
export const SearchInputContainer = styled.div`
  position: relative;
  top: -50%;
  width: 350px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #38B6FF;
  background: #FFF;
  overflow: hidden;
`;

// Updated SearchInput
export const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  border: none;
  font-size: 15px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  line-height: 108.52%;
  text-align: left;
  outline: none;
  background: transparent;
  
  &::placeholder {
    color: #C4C4C4;
    text-align: left;
    font-family: Gabarito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 108.52%;
  }
`;

// Updated SearchIcon position to right
export const SearchIcon = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Circle container for search icon
export const SearchIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  margin-right: -3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#38B6FF' : '#E6E6E6'};
`;

// New empty state message
export const EmptyStateMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  color: #4B4B4B;
  font-family: Gabarito;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: -15%;
`;

const FormationResultsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  z-index: 200;
`;
// Composants pour le résultat de recherche
const FormationResultItem = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  z-index: 200;
  position: relative;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  &:last-child {
    border-bottom: none;
  }
  
  ${props => props.loading && `
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
  `}
`;

const ResultItemLoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 12px;
  color: #38B6FF;
  font-size: 12px;
  font-weight: bold;
`;

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(56, 182, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #38B6FF;
  animation: spin 1s linear infinite;
  margin-left: 8px;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Composants pour le chargement global
const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 245, 248, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const LoadingSpinnerLarge = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid rgba(56, 182, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #38B6FF;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
`;

const LoadingText = styled.div`
  color: #38B6FF;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

// Composant LoadingIndicator
const LoadingIndicator = () => (
  <LoadingOverlay>
    <LoadingSpinnerLarge />
    <LoadingText>Chargement des formations...</LoadingText>
  </LoadingOverlay>
);

const FormationMeta = styled.div`
  color: #888;
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 6px;
    font-size: 12px;
  }
`;

// Modifier le style pour FormationName pour limiter à 3 lignes
export const FormationName = styled.h2`
  font-size: ${props => props.small ? '16px' : '19px'};
  font-weight: 400;
  margin: 0 0 4px 0;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limiter à 3 lignes max */
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
`;

// Modifier le style de FormationHeader pour utiliser min-height au lieu de height fixe
export const FormationHeader = styled.div`
  background: linear-gradient(90deg, #CC00FF 0%, #0F04E3 100%);
  color: white;
  padding: 16px;
  position: relative;
  min-height: 120px; /* Utiliser min-height comme hauteur de base */
  display: flex;
  flex-direction: column;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #38B6FF;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 10px auto;
  transition: background 0.2s;
  
  &:hover {
    background: #2f9ad6;
  }
  
  &:disabled {
    background: #b3e0ff;
    cursor: not-allowed;
  }
`;

// Mettre à jour le style du RemoveButton pour afficher clairement une croix
export const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: white; /* Couleur de l'icône */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  min-height:0;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  
  /* Ajouter des styles spécifiques pour l'icône à l'intérieur du bouton */
  svg {
    width: 16px;
    height: 16px;
    fill: white; /* Forcer la couleur de remplissage */
    stroke: white; /* Forcer la couleur de contour */
    stroke-width: 1;
  }
`;

export const SchoolName = styled.div`
  font-size: 14px;
  opacity: 0.9;
  margin-bottom: 8px;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  
  svg, img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  
  span {
    font-size: 12px;
  }
`;

export const SeeMoreButton = styled.div`
  background: white;
  color: black;
  border-radius: 25px;
  padding: 2px 10px;
  font-size: 9px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
`;

const SectionTitle = styled.div`
  font-size: 12px;
  color: #535353;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const TypeText = styled.div`
  font-size: 17px;
  color: #525252;
`;

export const ChancesBadge = styled.div`
  background: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  margin-top: 6px;
  
  span {
    font-size: 14px;
    margin-right: 8px;
  }
  
  img {
    width: 15px;
    height: 15px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
  
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-top: 2px;
  }
  
  span {
    font-size: 14px;
    color: black;
  }
`;

export const DomainTag = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  border-radius: 46px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: fit-content;
  float: left;
  height: 30px;
  img {
    width: 14px;
    height: 16px;
    margin-right: 4px;
  }
  
  span {
    font-size: 14px;
    white-space: nowrap;
  }
`;

const DeboucheItem = styled.div`
  font-size: 16px;
  color: #535353;
  margin-bottom: 4px;
  display: flex;
  align-items: flex-start;
`;

export const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  color: #666;
`;

const clearStyles = {
  clear: 'both',
  width: '100%',
  height: '1px',
  marginBottom: '15px'
};

const allFamilles_map_label_backend = {
  "Grande école post bac" : "grande_ecole_post_bac",
  "C.M.I" : "cmi",
  "Formations préparatoires à l'enseignement supérieur": "formations_preparatoires"
};

function normalizeString(str) {
  return str
    .toLowerCase()                  // Lowercase
    .normalize('NFD')               // Décomposer les accents
    .replace(/[\u0300-\u036f]/g, '')// Retirer les accents
    .trim();                        // Supprimer les espaces en fin de chaîne
}

// Construction d'une map avec des clés normalisées
const normalizedDomainMap = (domainIconMapRaw) => Object.entries(domainIconMapRaw).reduce(
  (acc, [displayDomain, icon]) => {
    const key = normalizeString(displayDomain);
    acc[key] = { displayDomain, icon };
    return acc;
  },
  {}
);

const Comparateur = () => {
  const navigate = useNavigate();
  const [formations, setFormations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchIndex, setSearchIndex] = useState({});
  const [formationsMeta, setFormationsMeta] = useState([]);
  const [posNeg, setPosNeg] = useState({});
  const { isMobile } = useDeviceType();
  const headerRefs = useRef([]);
  const typeRefs = useRef([]);
  const chancesRefs = useRef([]);
  const avantagesRefs = useRef([]);
  const desavantagesRefs = useRef([]);
  const domainesRefs = useRef([]);
  const debouchesRefs = useRef([]);
  const poursuiteRefs = useRef([]);
  const infoRefs = useRef([]);
  const searchInputRef = useRef(null);
  const resultsContainerRef = useRef(null);
  const [loadingFormationId, setLoadingFormationId] = useState(null);
  // Fonction pour ajuster les hauteurs de toutes les sections
  const adjustHeights = useCallback(() => {
    // Fonction helper pour définir des hauteurs égales
    const setEqualHeights = (refs) => {
      if (!refs || refs.length === 0) return;
      
      // Trouver la hauteur maximale
      let maxHeight = 0;
      refs.forEach(ref => {
        if (ref && ref.offsetHeight > maxHeight) {
          maxHeight = ref.offsetHeight;
        }
      });
      
      // Appliquer la hauteur maximale à tous les éléments
      if (maxHeight > 0) {
        refs.forEach(ref => {
          if (ref) {
            ref.style.height = `${maxHeight}px`;
          }
        });
      }
    };

    // Ajuster les hauteurs de chaque type de section
    setEqualHeights(headerRefs.current);
    setEqualHeights(typeRefs.current);
    setEqualHeights(chancesRefs.current);
    setEqualHeights(avantagesRefs.current);
    setEqualHeights(desavantagesRefs.current);
    setEqualHeights(domainesRefs.current);
    setEqualHeights(debouchesRefs.current);
    setEqualHeights(poursuiteRefs.current);
    setEqualHeights(infoRefs.current);
  }, []);

  // Réinitialiser les refs quand les formations changent
  useEffect(() => {
    // Réinitialiser les refs pour chaque type de section
    headerRefs.current = headerRefs.current.slice(0, formations.length);
    typeRefs.current = typeRefs.current.slice(0, formations.length);
    chancesRefs.current = chancesRefs.current.slice(0, formations.length);
    avantagesRefs.current = avantagesRefs.current.slice(0, formations.length);
    desavantagesRefs.current = desavantagesRefs.current.slice(0, formations.length);
    domainesRefs.current = domainesRefs.current.slice(0, formations.length);
    debouchesRefs.current = debouchesRefs.current.slice(0, formations.length);
    poursuiteRefs.current = poursuiteRefs.current.slice(0, formations.length);
    infoRefs.current = infoRefs.current.slice(0, formations.length);
    
    // Utiliser un court délai pour s'assurer que le DOM est complètement rendu
    const timer = setTimeout(() => {
      adjustHeights();
    }, 100);
    
    return () => clearTimeout(timer);
  }, [formations, adjustHeights]);

  // Ajouter un listener pour redimensionner si la fenêtre change de taille
  useEffect(() => {
    window.addEventListener('resize', adjustHeights);
    return () => window.removeEventListener('resize', adjustHeights);
  }, [adjustHeights]);

  const fetchPosNeg = async () => {
    try {
      // Check if there is a ranking in local storage
      const need_update_ranking_formation = JSON.parse(localStorage.getItem("need_update_ranking_formation"));
      let posNeg = null;
      
      if (!need_update_ranking_formation) {
        posNeg = JSON.parse(localStorage.getItem("pos_neg"));
      }
      
      if (!posNeg) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/pos-neg/`;
        posNeg = await apiCall(url, 'GET');
        localStorage.setItem("pos_neg", JSON.stringify(posNeg));
      }
      
      setPosNeg(posNeg);
    } catch (err) {
      console.error('Erreur pos-neg:', err);
    }
  };

  const fetchFormation = async (idFormation) => {
    try {
      // Try to get from localStorage first
      const need_update_ranking_formation = localStorage.getItem("need_update_ranking_formation");
      let formationData = null;
      
      if (!need_update_ranking_formation) {
        const storedRankingsStr = localStorage.getItem("ranking_formation");
        if (storedRankingsStr) {
          try {
            // Parse the rankings data properly
            const storedRankings = JSON.parse(storedRankingsStr);
            
            // Check if it's the structure with top1, top2, etc.
            if (storedRankings.top1 || storedRankings.top2) {
              // Search through all top entries
              for (const key in storedRankings) {
                if (storedRankings[key] && storedRankings[key].id === idFormation) {
                  formationData = storedRankings[key];
                  break;
                }
              }
            } else {
              // Try a direct search if not in the expected format
              formationData = Object.values(storedRankings).find(r => r.id === idFormation);
            }
          } catch (parseError) {
            console.error('Error parsing stored rankings:', parseError);
          }
        }
      }
      
      // If not found in localStorage or needs update, fetch from API
      if (!formationData) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/ficheFormation/?id=${idFormation}`;
        formationData = await apiCall(url, 'GET');
      }
      
      return formationData;
    } catch (err) {
      console.error(`Erreur lors du chargement de la formation ${idFormation}:`, err);
      return null;
    }
  };
  
  useEffect(() => {
    const loadComparateurData = async () => {
      setLoading(true);
      
      try {
        // Load search index and metadata for search functionality
        const { searchIndex, formationsMeta } = await loadSearchData();
        setSearchIndex(searchIndex);
        setFormationsMeta(formationsMeta);
        
        // Get formation data from localStorage
        const storedFormations = localStorage.getItem('comparateur');
        if (storedFormations) {
          try {
            // Check if it's array of objects (new format) or array of IDs (old format)
            const parsedData = JSON.parse(storedFormations);
            
            if (Array.isArray(parsedData)) {
              // Determine if it's array of IDs or array of objects
              if (parsedData.length > 0 && typeof parsedData[0] === 'object') {
                // Array of objects (new format)
                setFormations(parsedData);
              } else {
                // Array of IDs (old format) - fetch all formation data
                const formationIds = parsedData;
                const formationPromises = formationIds.map(id => fetchFormation(id));
                const formationResults = await Promise.all(formationPromises);
                const validFormations = formationResults.filter(f => f !== null);
                setFormations(validFormations);
                
                // Update localStorage with full formation objects
                localStorage.setItem('comparateur', JSON.stringify(validFormations));
              }
            }
          } catch (error) {
            console.error('Error parsing stored formations:', error);
          }
        }
        
        // Fetch positive/negative aspects for formations
        await fetchPosNeg();
      } catch (error) {
        console.error('Error loading comparateur data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    loadComparateurData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // Search formations using inverted index when search text changes
  useEffect(() => {
    if (searchText.trim() === '') {
      setSearchResults([]);
      return;
    }
    
    // If searchIndex and formationsMeta are loaded, search using inverted index
    if (Object.keys(searchIndex).length > 0 && Object.keys(formationsMeta).length > 0) {
      const results = searchFormations(searchText, searchIndex, formationsMeta, 40); // Augmenter la limite à 100 résultats
      setSearchResults(results);
    }
  }, [searchText, searchIndex, formationsMeta]);
  
  // Modifier la fonction handleFormationSelect pour ajouter directement la formation au comparateur
  const handleFormationSelect = async (formationMeta) => {
    // Définir l'ID de la formation en cours de chargement
    setLoadingFormationId(formationMeta.id);
    
    try {
      // Vérifier si l'ID est disponible
      if (!formationMeta || !formationMeta.id) {
        console.error('Formation metadata sans ID', formationMeta);
        return;
      }
      
      console.log('Sélection de la formation avec ID:', formationMeta.id);
      
      // Essayer de récupérer les données complètes de la formation
      const fullFormation = await fetchFormation(formationMeta.id);
      
      let formationToAdd;
      if (fullFormation) {
        console.log('Formation complète récupérée:', fullFormation);
        formationToAdd = fullFormation;
      } else {
        console.log('Fallback vers les métadonnées de base');
        // Fallback vers les métadonnées de base si la récupération échoue
        formationToAdd = metaToFormation(formationMeta);
        console.log('Formation de base créée:', formationToAdd);
      }
      
      // Vérifier si la formation existe déjà dans le comparateur
      const alreadyExists = formations.some(f => f.id === formationToAdd.id);
      
      if (!alreadyExists) {
        const updatedFormations = [...formations, formationToAdd];
        setFormations(updatedFormations);
        
        // Sauvegarder les formations complètes dans localStorage
        localStorage.setItem('comparateur', JSON.stringify(updatedFormations));
        
        // NOUVEAU CODE: Enregistrer l'action dans l'historique
        const timestamp = new Date().toISOString();
        const actionEntry = `add_${formationToAdd.id}_${timestamp}`;
        
        try {
          await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update-comparateur-history/`, 'POST', {
            action: actionEntry
          });
        } catch (error) {
          console.error("Erreur lors de l'enregistrement de l'historique:", error);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des détails complets de la formation:', error);
    } finally {
      // Réinitialiser l'ID de chargement
      setLoadingFormationId(null);
      setSearchText('');
      setSearchResults([]);
      setShowResults(false);
    }
  };
  
  // Remove formation from comparison
  const handleRemoveFormation = async (id) => {
    const updatedFormations = formations.filter(formation => formation.id !== id);
    setFormations(updatedFormations);
    
    // Update localStorage with new list of formations
    localStorage.setItem('comparateur', JSON.stringify(updatedFormations));
    
    // NOUVEAU CODE: Enregistrer l'action de suppression dans l'historique
    const timestamp = new Date().toISOString();
    const actionEntry = `sup_${id}_${timestamp}`;
    
    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update-comparateur-history/`, 'POST', {
        action: actionEntry
      });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'historique de suppression:", error);
    }
  };
  
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setShowResults(true);
  };
  
  // Handle focus on search input
  const handleSearchFocus = () => {
    setShowResults(true);
  };
  
  // Handle click outside to close search results
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsContainerRef.current && !resultsContainerRef.current.contains(event.target) &&
          searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleSeeMore = (id) => {
    navigate(`/formation?id=${id}`);
  };
 
  // Modifier le rendu des sections pour utiliser les refs
  const renderTypeSection = (formation, index) => (
    <Section
      ref={el => typeRefs.current[index] = el}
    >
      <SectionTitle>
        <SectionIcon src={statutImg} alt="Type" />
        Type de formation
      </SectionTitle>
      <TypeText>{formation.type || 'CPGE (Classe prépa)'}</TypeText>
    </Section>
  );

  const renderChancesSection = (formation, index) => {
    // Calculate admission chances percentage
    const chancesValue = formation.chances_admissions !== undefined 
      ? Math.round(formation.chances_admissions * 100) 
      : 50;
    
    // Get the appropriate level and icon
    const chancesLevel = getChancesLevel(chancesValue);
    
    return (
      <SectionGray
        ref={el => chancesRefs.current[index] = el}
      >
        <SectionTitle>Chances d'admission</SectionTitle>
        <ChancesBadge>
          <span>{chancesLevel.text}</span>
          <img src={chancesLevel.icon} alt="Chances" />
        </ChancesBadge>
      </SectionGray>
    );
  };

  const renderAvantagesSection = (formation, index, filteredPositives) => (
    <Section
      ref={el => avantagesRefs.current[index] = el}
    >
      <SectionTitle>Avantages</SectionTitle>
      <AdvantageList>
        {filteredPositives.length > 0 ? 
          filteredPositives.map((avantage, idx) => (
            <ListItem key={`adv-${idx}`}>
              <img src={checkIcon} alt="+" />
              <span>{avantage.mot}</span>
            </ListItem>
          )) : 
          <ListItem>
            <span>Informations non disponibles</span>
          </ListItem>
        }
      </AdvantageList>
    </Section>
  );

  const renderDesavantagesSection = (formation, index, filteredNegatives) => (
    <SectionGray
      ref={el => desavantagesRefs.current[index] = el}
    >
      <SectionTitle>Désavantages</SectionTitle>
      <DisadvantageList>
        {filteredNegatives.length > 0 ? 
          filteredNegatives.map((desavantage, idx) => (
            <ListItem key={`disadv-${idx}`}>
              <img src={crossIcon} alt="-" />
              <span>{desavantage.mot}</span>
            </ListItem>
          )) : 
          <ListItem>
            <span>Informations non disponibles</span>
          </ListItem>
        }
      </DisadvantageList>
    </SectionGray>
  );

  const renderDomainesSection = (formation, index) => (
    <DomainesSection
      ref={el => domainesRefs.current[index] = el}
    >
      <SectionTitle>Domaines</SectionTitle>
      <DomaineTagsContainer>
        {(formation.domaines_enseignés_principaux || []).slice(0, 2).map((domaine, idx) => (
          <DomainTag key={`dom-${idx}`}>
            <img src={normalizedDomainMap(domainIconMapRaw)[normalizeString(domaine)]?.icon} alt={domaine} />
            <span>{domaine}</span>
          </DomainTag>
        ))}
        {(formation.domaines_enseignés_principaux || []).length === 0 && (
          <span style={{ fontSize: '10px', color: '#535353' }}>Informations non disponibles</span>
        )}
      </DomaineTagsContainer>
      <div style={clearStyles}></div>
    </DomainesSection>
  );

  const renderDebouchesSection = (formation, index) => (
    <SectionGray
      ref={el => debouchesRefs.current[index] = el}
    >
      <SectionTitle>
        <SectionIcon src={debouchesImg} alt="Débouchés" />
        Débouchés
      </SectionTitle>
      <DebouchesList>
        {Array.isArray(formation.débouchés_professionnels) 
          ? formation.débouchés_professionnels.map((debouche, idx) => (
              <DeboucheItem key={`deb-${idx}`}>
                <span>- {debouche}</span>
              </DeboucheItem>
            ))
          : Object.values(formation.débouchés_professionnels || {}).map((debouche, idx) => (
              <DeboucheItem key={`deb-${idx}`}>
                <span>- {debouche}</span>
              </DeboucheItem>
            ))
        }
        {(!formation.débouchés_professionnels || 
          (Array.isArray(formation.débouchés_professionnels) && formation.débouchés_professionnels.length === 0) || 
          (!Array.isArray(formation.débouchés_professionnels) && Object.keys(formation.débouchés_professionnels).length === 0)) && (
          <DeboucheItem>Informations non disponibles</DeboucheItem>
        )}
      </DebouchesList>
    </SectionGray>
  );

  // Nouvelle section pour la poursuite d'études
  const renderPoursuiteSection = (formation, index) => (
    <Section
      ref={el => poursuiteRefs.current[index] = el}
    >
      <SectionTitle>
        <SectionIcon src={poursuiteImg} alt="Poursuite d'études" />
        Poursuite d'études
      </SectionTitle>
      <PoursuiteList>
        {Array.isArray(formation.poursuite_etudes) 
          ? formation.poursuite_etudes.map((poursuite, idx) => (
              <DeboucheItem key={`pur-${idx}`}>
                <span>- {poursuite}</span>
              </DeboucheItem>
            ))
          : Object.values(formation.poursuite_etudes || {}).map((poursuite, idx) => (
              <DeboucheItem key={`pur-${idx}`}>
                <span>- {poursuite}</span>
              </DeboucheItem>
            ))
        }
        {(!formation.poursuite_etudes || 
          (Array.isArray(formation.poursuite_etudes) && formation.poursuite_etudes.length === 0) || 
          (!Array.isArray(formation.poursuite_etudes) && Object.keys(formation.poursuite_etudes).length === 0)) && (
          <DeboucheItem>Informations non disponibles</DeboucheItem>
        )}
      </PoursuiteList>
    </Section>
  );

  const renderInfoSection = (formation, index) => (
    <SectionGray
      ref={el => infoRefs.current[index] = el}
    >
      <SectionTitle>Informations complémentaires</SectionTitle>
      <ListItem>
        <SectionIcon src={prixImg} alt="Coût" />
        <span><strong>Coût:</strong> {formation.cout || 'N/A'} €/an</span>
      </ListItem>
      <ListItem>
        <SectionIcon src={salaireImg} alt="Salaire" />
        <span><strong>Salaire de sortie:</strong> {formation.salaire_sortie || 'Non spécifié'}</span>
      </ListItem>
      <ListItem>
        <SectionIcon src={statutImg} alt="Statut" />
        <span><strong>Statut:</strong> {formation.public_prive || 'N/A'}</span>
      </ListItem>
    </SectionGray>
  );

  // Modifier la fonction renderAdvantagesDisadvantages pour utiliser les index
  const renderAdvantagesDisadvantages = (formation, index) => {
    const formationType = allFamilles_map_label_backend[formation.type] || formation.type;
    
    const filteredPositives = posNeg?.positive?.filter(item => item.quiz_type === formationType) || [];
    const filteredNegatives = posNeg?.negative?.filter(item => item.quiz_type === formationType) || [];
    
    return (
      <>
        {renderAvantagesSection(formation, index, filteredPositives)}
        {renderDesavantagesSection(formation, index, filteredNegatives)}
      </>
    );
  };

 
  // Render empty state UI when no formations are selected
  if (formations.length === 0) {
    return (
      <Container isMobile={isMobile}>
        {loading && <LoadingIndicator />}
        {/* Custom header with image for empty state */}
        <HeaderImageContainer>
          <HeaderImage src={comparateurHeaderImage} alt="Comparateur" />
        </HeaderImageContainer>
        
        {/* Modified search box for empty state */}
        <SearchBox>
          <div style={{ position: 'relative' }}>
            <SearchInputContainer ref={searchInputRef}>
              <SearchInput
                type="text"
                placeholder="Rechercher une formation"
                value={searchText}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
              />
              <SearchIcon>
                <SearchIconCircle active={searchText.length > 0}>
                  {searchText.length === 0 ? (
                    <img src={loupe_grise} alt="Search" style={{ width: '20px', height: '20px' }} />
                  ) : (
                    <img src={loupe_blanche} alt="Search" style={{ width: '20px', height: '20px' }} />
                  )}
                </SearchIconCircle>
              </SearchIcon>
            </SearchInputContainer>
            
            <FormationResultsContainer 
              ref={resultsContainerRef}
              visible={showResults && searchResults.length > 0}
            >
              {searchResults.map((formation, index) => (
                <FormationResultItem 
                  key={index} 
                  loading={loadingFormationId === formation.id}
                  onClick={(e) => {
                    // Empêche la propagation
                    e.preventDefault();
                    e.stopPropagation();
                    
                    // Ne rien faire si déjà en chargement
                    if (loadingFormationId) return;
                    
                    console.log("Formation sélectionnée:", formation);
                    handleFormationSelect(formation);
                  }}
                >
                  <FormationName small>{formation.nomFormation}</FormationName>
                  <FormationMeta>
                    <span>{formation.nomEtablissement}</span>
                    {formation.commune && (
                      <span> • {formation.commune}</span>
                    )}
                  </FormationMeta>
                  
                  {/* Afficher le spinner si cette formation est en cours de chargement */}
                  {loadingFormationId === formation.id && (
                    <ResultItemLoadingSpinner>
                      <span>Chargement</span>
                      <Spinner />
                    </ResultItemLoadingSpinner>
                  )}
                </FormationResultItem>
              ))}
              {searchResults.length === 0 && searchText.trim() !== '' && (
                <div style={{ padding: '16px', textAlign: 'center', color: '#888' }}>
                  Aucun résultat trouvé
                </div>
              )}
            </FormationResultsContainer>
          </div>
        </SearchBox>
        
        {/* Empty state message */}
        <EmptyStateMessage>
          Sélectionne des formations à comparer !
        </EmptyStateMessage>
      </Container>
    );
  }
  
  // Regular UI when formations are selected
  return (
    <Container isMobile={isMobile}>

      {/* Search Box Area */}
      <SearchBox>
        <SearchInputContainer ref={searchInputRef}>
          <SearchInput
            type="text"
            placeholder="Rechercher une formation"
            value={searchText}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
          />
          <SearchIcon>
            <SearchIconCircle active={searchText.length > 0}>
              {searchText.length === 0 ? (
                <img src={loupe_grise} alt="Search" style={{ width: '20px', height: '20px' }} />
              ) : (
                <img src={loupe_blanche} alt="Search" style={{ width: '20px', height: '20px' }} />
              )}
            </SearchIconCircle>
          </SearchIcon>
        </SearchInputContainer>
        
        <FormationResultsContainer 
          ref={resultsContainerRef}
          visible={showResults && searchResults.length > 0}
        >
          {searchResults.map((formation, index) => (
            <FormationResultItem 
              key={index} 
              onClick={(e) => {
                // Empêche la propagation
                e.preventDefault();
                e.stopPropagation();
                
                console.log("Formation sélectionnée:", formation);
                
                // Utilisez setTimeout pour s'assurer que l'action s'exécute après la gestion des clics
                setTimeout(() => {
                  handleFormationSelect(formation);
                }, 0);
              }}
            >
              <FormationName small>{formation.nomFormation}</FormationName>
              <FormationMeta>
                <span>{formation.nomEtablissement}</span>
                {formation.commune && (
                  <span> • {formation.commune}</span>
                )}
              </FormationMeta>
            </FormationResultItem>
          ))}
          {searchResults.length === 0 && searchText.trim() !== '' && (
            <div style={{ padding: '16px', textAlign: 'center', color: '#888' }}>
              Aucun résultat trouvé
            </div>
          )}
        </FormationResultsContainer>
      </SearchBox>
      
      <ScrollableContainer>
        {formations.map((formation, index) => (
          <FormationColumn key={index}>
            {/* Remove button */}
            <RemoveButton onClick={() => handleRemoveFormation(formation.id)}>
              ✕
            </RemoveButton>
            
            {/* Ajouter ref au header */}
            <FormationHeader ref={el => headerRefs.current[index] = el}>
              <FormationName>{formation.nom_formation}</FormationName>
              <SchoolName>{formation.nom_ecole?.split('(')[0].trim()}</SchoolName>
              
              <HeaderActions>
                <ActionItem>
                  <img src={localisation_icon} alt="Location" />
                  <span>
                    {formation.nom_ecole ? 
                      (formation.nom_ecole.match(/\(([^)]+)\)/) ? 
                        formation.nom_ecole.match(/\(([^)]+)\)/)[1].split(' - ')[0].slice(0, 15) : 
                        'Non spécifié') 
                      : 'Non spécifié'}
                  </span>
                </ActionItem>
              </HeaderActions>
              
              <Size20>
                <img src={dureeImg} alt="Duration" style={{ width: '16px', height: '16px', marginRight: '5px' }} />
                <span>{formation.duree || '2 ans'}</span>
              </Size20>
              
              <SeeMoreButton onClick={() => handleSeeMore(formation.id)}>
                Voir plus
              </SeeMoreButton>
            </FormationHeader>
            
            {renderTypeSection(formation, index)}
            {renderChancesSection(formation, index)}
            {renderAdvantagesDisadvantages(formation, index)}
            {renderDomainesSection(formation, index)}
            {renderDebouchesSection(formation, index)}
            {renderPoursuiteSection(formation, index)}
            {renderInfoSection(formation, index)}
          </FormationColumn>
        ))}
      </ScrollableContainer>
    </Container>
  );
};

export default Comparateur;