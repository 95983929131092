import styled from 'styled-components';

// Overlay that covers the entire screen with a semi-transparent dark background
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; /* Ensures the overlay is on top */
`;

// Main container styled as a fixed rectangle with responsive adjustments
export const PopupContainer = styled.div`
  width: 70%;
  height: auto;
  flex-shrink: 0;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

// The top image – it fills the width and keeps its aspect ratio
export const ImageWrapper = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

// Title with the specified typography
export const Title = styled.h2`
  color: #000;
  font-family: 'Gabarito', sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 15px 0 5px;
  text-align: center;
`;

// Description paragraph style
export const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 15px;
`;

// Container for the buttons
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  width: 100%;
`;

// Primary button styling – redirects users to the appropriate store
export const PrimaryButton = styled.button`
  width: 100%;
  height: 55px;
  flex-shrink: 0;
  background: #ff2768;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }
`;

// Secondary button styling – closes the popup
export const SecondaryButton = styled.button`
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  background: rgba(255, 39, 104, 0);
  color:rgb(187, 187, 187);
  font-family: 'Gabarito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;
