import styled from 'styled-components';
import theme from '../../../theme';

// ========== WRAPPER CONTAINER ==========
export const EnhancedContainer = styled.div`
  background: ${theme.colors.background};
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  
  /* Show a minimal custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  /* Webkit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }

  /* For Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.background} transparent;
  }
`;

// ========== FIXED NAVBAR ==========
export const DynamicNavBar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: ${theme.colors.background};
  display: flex;
  align-items: center;
  padding: 0 5%;
  z-index: 1000;
  border-bottom: 3px solid ${theme.colors.background_fonce};
`;

export const DynamicNavLogo = styled.h2`
  font-family: 'Londrina Solid', sans-serif;
  font-size: 35px;
  font-weight: 900;
  color: ${theme.colors.primary};
  margin: 0;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const NavCTAButton = styled.button`
  margin-left: auto;
  background: #ff2768;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-weight: 600;
  height: 45px;
  width: 200px;
  padding: 0 15px;
  cursor: pointer;
  transition: background-color 0.15s ease;
  transform: ${props => props.active ? 'translateY(3px)' : 'translateY(0)'};
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px #d42359'};

  &:hover {
    background: #ff4783;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }

  @media (max-width: 800px) {
    font-size: 16px;
    width: 120px;
  }
`;

export const NavSecondaryButton = styled(NavCTAButton)`
  background: ${theme.colors.white};
  color: #38B6FF;
  border: 1px solid #E6E6E6;
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px 0px #E6E6E6'};
  width: auto;
  min-width: 200px;
  padding: 0 20px;
  white-space: nowrap;
  
  &:hover {
    background: #f5f5f5;
    color: #38B6FF;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }
`;

// ========== MAIN CONTENT LAYOUT (2 columns) ==========
export const MainContent = styled.div`
  display: flex;
  margin-top: 70px; /* push below navbar */
  min-height: calc(100vh - 70px);
  width: 90%;
  margin-left: 5%;
`;

export const LeftPart = styled.div`
  flex: 2; /* 2/3 of space */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const RightPart = styled.div`
  flex: 1; /* 1/3 of space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// ========== MEO TE GUIDE TEXT ==========
export const MeoTeGuide = styled.h1`
  width: 100%;
  position: relative;
  font-size: 35px;
  font-weight: 900;
  font-family: 'Gabarito', sans-serif;
  color: #5c5c5c;
  text-align: center;
  display: inline-block;
  height: 197px;
`;

// ========== BUTTONS WRAPPER ==========
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
`;

// ========== BUTTON TEXT STYLES ==========
export const TopButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #fff;
  text-align: center;
`;

export const BottomButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #1db1f7;
  text-align: center;
`;

// ========== SHARED BUTTON STYLES ==========
export const PrimaryButton = styled.button`
  width: 70%;
  max-width: 400px;
  height: 53px;
  background: #ff2768;
  color: #ffffff;
  border-radius: 15px;
  border: none;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.15s ease;
  transform: ${props => props.active ? 'translateY(3px)' : 'translateY(0)'};
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px #d42359'};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ff4783;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background: ${theme.colors.white};
  color: #38B6FF;
  border: 1px solid #E6E6E6;
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px 0px #E6E6E6'};
  
  &:hover {
    background: #f5f5f5;
    color: #38B6FF;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }
`;

// ========== LEFT SIDE: ICON + FEATURES "AROUND" ==========
export const CircleContainer = styled.div`
  position: relative;
  width: 400px; /* adjust as desired */
  height: 400px;
`;

export const MeoQuizIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%; /* adjust size as needed */
  height: auto;
`;

export const FeatureBox = styled.div.attrs((props) => {
  const itemCount = 6;
  const index = props.index || 0;
  const angleDeg = (360 / itemCount) * index;
  const center = 200;

  // Keep your chosen radii
  const radiusX = 300;
  const radiusY = 270;

  const angleRad = (Math.PI / 180) * angleDeg;
  const x = center + radiusX * Math.cos(angleRad);
  const y = center + radiusY * Math.sin(angleRad);

  // Small nudge for top/bottom
  let extraY = 0;
  if (index === 0) {
    extraY = -20;
  } else if (index === 3) {
    extraY = 20;
  }

  return {
    style: {
      position: 'absolute',
      left: `${x}px`,
      top: `${y + extraY}px`,
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
  };
})`
  background: ${theme.colors.white};
  border-radius: 30px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  color: ${theme.colors.noir_clair};
  width: max-content;
  transition: transform 0.2s ease;
  transform-origin: 50% 50%;
  animation: keepTextUpright 80s linear infinite;

  @keyframes keepTextUpright {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  &:hover {
    transform: translate(-50%, -50%) rotate(-360deg) translateY(-2px);
  }

  img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  @media (max-width: 800px) {
    font-size: 16px;
    padding: 6px 15px;
  }
`;

export const RotatingFeatures = styled.div`
  position: absolute;
  inset: 0;
  transform-origin: 50% 50%;
  animation: revolve 80s linear infinite;

  @keyframes revolve {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// ========== SECTIONS BELOW MAIN CONTENT ==========

export const SectionSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${theme.colors.background_fonce};
  margin: 40px 0;
`;

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 5% 15% 10%;
`;

export const SectionLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 50%;
    height: auto;
  }
`;

export const SectionRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 50%;
    height: auto;
  }
`;

export const BigSubtitle = styled.h2`
  width: 80%;
  position: relative;
  font-size: 50px;
  font-weight: 900;
  font-family: 'Londrina Solid', sans-serif;
  color: #ff2768;
  text-align: left;
  display: inline-block;
  margin: 0;

  @media (max-width: 800px) {
    font-size: 32px;
    width: auto;
    text-align: center;
  }
`;

export const Description = styled.p`
  width: 80%;
  position: relative;
  font-size: 20px;
  font-family: 'Gabarito', sans-serif;
  color: #626060;
  text-align: left;
  display: inline-block;
  margin-top: 10px;

  @media (max-width: 800px) {
    font-size: 16px;
    width: auto;
    text-align: center;
  }
`;

// ========== NEW BOTTOM SECTIONS ==========

/* The first white rectangle (260px height, radius: 50px) */
export const OrientationBox = styled.div`
  width: 90%;
  margin-left: 5%;
  height: 260px;
  position: relative;
  border-radius: 50px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OrientationTitle = styled.h2`
  margin: 0;
  width: 60%;
  position: relative;
  font-size: 30px;
  font-weight: 800;
  font-family: 'Gabarito', sans-serif;
  color: #070d2f;
  text-align: center;
  display: inline-block;
  height: auto; /* let it size naturally */
  line-height: 1.3;
  margin-top: 0%;
`;

export const OrientationIconsRow = styled.div`
  margin-top: 20px; 
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;

  img {
    height: 120px; /* Adjust as you wish */
    width: auto;
  }
`;

/* Just a little space before the second white rectangle */
export const BottomSpacing = styled.div`
  height: 50px;
`;

/* The second white rectangle (full width) for the "Footer-like" info */
export const FooterBox = styled.div`
  max-width: 100%;
  background-color: #fff;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 0;

`;

export const FooterColumn = styled.div`
  flex: 1 1 250px; /* each column tries to be at least 250px wide */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 20px;    /* adds horizontal spacing if needed */
  text-align: center;

  img {
    width: 150px; 
    height: auto; 
  }
`;

export const FooterSubtitle = styled.h3`
  margin-top: 10%;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #070d2f;
  text-align: center;
  display: inline-block;
  line-height: 1.3;
`;

export const FooterLink = styled.a`
  font-size: 15px;
  font-family: 'Gabarito', sans-serif;
  color: #070d2f;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SocialIconsRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
  }
`;


export const SchoolButton = styled.button`
  border-radius: 15px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  box-shadow: ${props => props.active ? 'none' : '0px 3px 0px 0px #E6E6E6'};
  color: #38B6FF;
  text-align: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 45px;
  min-width: 200px;
  width: auto;
  padding: 0 20px;
  white-space: nowrap;
  transition: background-color 0.15s ease;
  transform: ${props => props.active ? 'translateY(3px)' : 'translateY(0)'};
  
  &:hover {
    background: #f5f5f5;
  }
  
  &:active {
    transform: translateY(3px);
    box-shadow: none;
  }
`;