import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';

import compassImage from '../../assets/meo_write.png';
import artsIcon from '../../assets/arts_culture_icon.png';
import eppcsIcon from '../../assets/sport_icon.png';
import hggspIcon from '../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../assets/hlp_icon.png';
import llcerIcon from '../../assets/llcer_icon.png';
import lcaIcon from '../../assets/lca_icon.png';
import mathIcon from '../../assets/math_icon.png';
import nsiIcon from '../../assets/informatique_icon.png';
import physChimIcon from '../../assets/physics_icon.png';
import svtIcon from '../../assets/svt_icon.png';
import siIcon from '../../assets/engineer_icon.png';
import sesIcon from '../../assets/ses_icon.png';
import bioecoIcon from '../../assets/bioeco_icon.png';
import amc_icon from '../../assets/english_icon.png';

import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';

const BacGeneral = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const { checkAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const [user_type, setUser_type] = useState('');
  const { isMobile } = useDeviceType();
  const [showJeNeSaisPas, setShowJeNeSaisPas] = useState(false);

  // States to track pointer active styles:
  const [submitActive, setSubmitActive] = useState(false);

  const getMessage = (type) => {
    switch (type) {
      case 'seconde':
        return "Sélectionne les trois spécialités que tu veux choisir l'année prochaine. Scrolle vers le bas !";
      case 'premiere':
        return "Sélectionne tes trois spécialités. Scrolle vers le bas !";
      case 'terminale':
        return "Sélectionne tes deux spécialités. Scrolle vers le bas !";
      default:
        return "Sélectionne les 2 spécialités que tu avais en terminale !";
    }
  };

  useEffect(() => {
    let storeduser_type = '';
    if (modifyProfile) {
      const user = JSON.parse(localStorage.getItem('user'));
      console.log(user);
      storeduser_type = user.user_type;
    } else {
      storeduser_type = localStorage.getItem('type_utilisateur');
    }
    console.log(storeduser_type);
    setUser_type(storeduser_type);
    const fullText = getMessage(storeduser_type);

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, [modifyProfile]);

  const specialityOptions = [
    { value: 'arts', label: 'Arts', icon: artsIcon },
    { value: 'eppcs', label: 'Éducation physique, cultures sportives', icon: eppcsIcon },
    { value: 'hggsp', label: 'Histoire-géo, géopolitique et sciences politiques', icon: hggspIcon },
    { value: 'hlp', label: 'Humanités, littérature et philosophie', icon: hlpIcon },
    { value: 'llcer', label: 'Langues, littératures et cultures étrangères', icon: llcerIcon },
    { value: 'lca', label: "Littérature, langues et cultures de l'antiquité", icon: lcaIcon },
    { value: 'math', label: 'Mathématiques', icon: mathIcon },
    { value: 'nsi', label: 'Numérique et sciences informatiques', icon: nsiIcon },
    { value: 'physchim', label: 'Physique-Chimie', icon: physChimIcon },
    { value: 'svt', label: 'Sciences de la vie et de la Terre', icon: svtIcon },
    { value: 'si', label: "Sciences de l'ingénieur", icon: siIcon },
    { value: 'ses', label: 'Sciences économiques et sociales', icon: sesIcon },
    { value: 'bioeco', label: 'Biologie-Ecologie', icon: bioecoIcon },
    { value: 'amc', label: 'Anglais Monde Contemporain', icon: amc_icon }
  ];

  const handleOptionClick = (value) => {
    if (value === 'je-ne-sais-pas') {
      setShowJeNeSaisPas(!showJeNeSaisPas);
      setSelectedSpecialities([]);
      return;
    }
    const maxSelections = user_type === 'terminale' ? 2 : 3;
    if (selectedSpecialities.includes(value)) {
      setSelectedSpecialities(prev => prev.filter(item => item !== value));
    } else if (selectedSpecialities.length < maxSelections) {
      setSelectedSpecialities(prev => [...prev, value]);
    }
  };

  const isSubmitEnabled = () => {
    if (user_type === 'seconde') {
      return showJeNeSaisPas || selectedSpecialities.length === 3;
    } else if (user_type === 'premiere') {
      return selectedSpecialities.length === 3;
    } else {
      return selectedSpecialities.length === 2;
    }
  };

  const handleSubmit = async () => {
    if (!isSubmitEnabled() || isTyping) return;
    setIsLoading(true);
    try {
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          specialites: selectedSpecialities
        });
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.specialites = selectedSpecialities;
        localStorage.setItem('user', JSON.stringify(userData));
        console.log("Specialites mises à jour :", selectedSpecialities);
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile/modify/grades');
      } else { // Onboarding
        localStorage.setItem('specialites', JSON.stringify(selectedSpecialities));
        navigate('/general-moyenne');
      }
    } catch (error) {
      console.error('Error saving specialities to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/type-bac');
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      {!modifyProfile && <ProgressBar startPercent={55} endPercent={65} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer isMobile={isMobile}>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {specialityOptions.map((option) => (
                <OptionButton
                key={option.value}
                $isSelected={selectedSpecialities.includes(option.value)}
                type="button"
                aria-pressed={selectedSpecialities.includes(option.value)}
                onClick={() => handleOptionClick(option.value)}
              >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedSpecialities.includes(option.value)}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={!isSubmitEnabled() || isTyping}
          style={{
                opacity: (!isSubmitEnabled() || isTyping) ? 0.5 : 1,
                cursor: (!isSubmitEnabled() || isTyping) ? 'not-allowed' : 'pointer',
                boxShadow: (!isSubmitEnabled() || isTyping || submitActive)
                  ? 'none'
                  : '0px 4px 0px #d42359'
              }}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default BacGeneral;
