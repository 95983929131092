import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import { 
  MobileHistoryContainer, 
  HistoryContent, 
  Overlay,
  CloseButtonWrapper,
  CloseButton,
  Header,
  Title,
  Line
} from './styles';
import ChatHistory from '../ChatHistory';

const MobileHistoryTab = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleDiscussionClick = (discussion) => {
    navigate('/chat', { state: { selectedDiscussion: discussion } });
    onClose();
  };

  return (
    <>
      <Overlay $isOpen={isOpen} onClick={onClose} />
      <MobileHistoryContainer $isOpen={isOpen}>
        <Header>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose} aria-label="Close menu">
              <IoIosArrowBack size={24} />
            </CloseButton>
          </CloseButtonWrapper>
          <Title>Historique de discussion</Title>
          <Line />
        </Header>
        <HistoryContent>
          <ChatHistory onDiscussionClick={handleDiscussionClick} />
        </HistoryContent>
      </MobileHistoryContainer>
    </>
  );
};

export default MobileHistoryTab;