import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';

import compassImage from '../../assets/meo_write.png';
import defaultIcon from '../../assets/dont_know_icon.png';
import chargeExcessiveIcon from '../../assets/chances_tres_basses.png';
import chargeInsufficienteIcon from '../../assets/doubt.png';
import autreIcon from '../../assets/other-icon.png';

import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import { domainIconMapRaw } from './domainIconMap';

const ProblèmesFormationOnboarding = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [selectedProblemes, setSelectedProblemes] = useState([]);
  const { checkAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const { isMobile } = useDeviceType();
  const [domaineOptions, setDomaineOptions] = useState([]);
  
  // État pour le bouton de soumission uniquement
  const [submitActive, setSubmitActive] = useState(false);

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim();
  };

  // Build a map with normalized keys
  const normalizedDomainMap = Object.entries(domainIconMapRaw).reduce(
    (acc, [displayDomain, icon]) => {
      const key = normalizeString(displayDomain);
      acc[key] = { displayDomain, icon };
      return acc;
    },
    {}
  );

  useEffect(() => {
    const fetchDomainesFormation = async () => {
      setIsLoading(true);
      try {
        const formation_id = localStorage.getItem('formation_id');
        
        if (!formation_id) {
          console.error('Aucun ID de formation trouvé dans le localStorage');
          navigate('/type-bac');
          return;
        }
        
        // Faire une requête API pour obtenir les domaines de la formation
        const url = `${process.env.REACT_APP_BACKEND_URL}/quiz/getFormationInfoNotAuthenticated/?id=${formation_id}`;
        const response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
            credentials: 'omit',
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Transformer les domaines en options pour l'affichage
        // Combiner les domaines principaux et secondaires
        const domaines = [
          ...(data.domaines_enseignés_principaux || []),
          ...(data.domaines_enseignés_secondaires || [])
        ];
        
        // Créer les options avec les icônes appropriées
        const options = domaines.map(domaine => {
          const normalized = normalizeString(domaine);
          const found = normalizedDomainMap[normalized];
          
          return {
            value: domaine,
            label: domaine,
            icon: found ? found.icon : defaultIcon
          };
        });
        
        // Ajouter les options supplémentaires
        options.push(
          { value: 'trop_charge_travail', label: 'Trop de charge de travail', icon: chargeExcessiveIcon },
          { value: 'pas_assez_charge_travail', label: 'Pas assez de charge de travail', icon: chargeInsufficienteIcon },
          { value: 'autre', label: 'Autre', icon: autreIcon }
        );
        
        setDomaineOptions(options);
      } catch (error) {
        console.error('Erreur lors de la récupération des domaines:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDomainesFormation();
    
    const fullText = "Quels élément(s) n'as-tu pas aimé pendant cette formation ?";
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  const handleOptionClick = (value) => {
    if (selectedProblemes.includes(value)) {
      setSelectedProblemes(prev => prev.filter(item => item !== value));
    } else {
      setSelectedProblemes(prev => [...prev, value]);
    }
  };

  const isSubmitEnabled = () => {
    // Au moins un problème doit être sélectionné
    return selectedProblemes.length > 0;
  };

  const handleSubmit = async () => {
    if (!isSubmitEnabled() || isTyping) return;
    setIsLoading(true);
    try {
      // Récupérer l'ID de la formation depuis le localStorage
      const formation_id = localStorage.getItem('formation_id');
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          problemes_formation: selectedProblemes
        });
        var userData = JSON.parse(localStorage.getItem('user'));
        userData.problemes_formation = selectedProblemes;
        localStorage.setItem('user', JSON.stringify(userData));
        console.log("Problèmes mis à jour :", selectedProblemes);
        await checkAuth();
        navigate('/profile');
      } else { // Onboarding
        localStorage.setItem('problemes_formation', JSON.stringify(selectedProblemes));
        
        
        // Rediriger vers la page suivante dans le flux d'onboarding
        navigate('/type-bac');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des problèmes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/bac1');
    }
  };

  // --- Submit Button Pointer Handlers ---
  const handleSubmitPressStart = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(true);
  };

  const handleSubmitPressEnd = () => {
    if (!isSubmitEnabled() || isTyping) return;
    setSubmitActive(false);
    setTimeout(() => {
      handleSubmit();
    }, 150);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={85} endPercent={90} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer isMobile={isMobile}>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {domaineOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  $isSelected={selectedProblemes.includes(option.value)}
                  type="button"
                  aria-pressed={selectedProblemes.includes(option.value)}
                  onClick={() => handleOptionClick(option.value)}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedProblemes.includes(option.value)}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={!isSubmitEnabled() || isTyping}
          style={{
            opacity: (!isSubmitEnabled() || isTyping) ? 0.5 : 1,
            cursor: (!isSubmitEnabled() || isTyping) ? 'not-allowed' : 'pointer',
            transform: submitActive
              ? 'translateX(-50%) translateY(4px)'
              : 'translateX(-50%)',
            boxShadow: (!isSubmitEnabled() || isTyping || submitActive)
              ? 'none'
              : '0px 4px 0px #d42359'
          }}
          onMouseDown={handleSubmitPressStart}
          onMouseUp={handleSubmitPressEnd}
          onMouseLeave={() => setSubmitActive(false)}
          onTouchStart={handleSubmitPressStart}
          onTouchEnd={handleSubmitPressEnd}
          active={submitActive}
        >
          {isLoading ? 'Chargement...' : modifyProfile ? 'Valider' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default ProblèmesFormationOnboarding;