// index.js
import React, { useEffect, useState } from 'react';
import {
  ProgressBarContainer,
  ProgressBarFill,
  SubBar
} from './styles';

const ProgressBar = ({ 
  startPercent = 0,
  endPercent = 100
}) => {
  const [width, setWidth] = useState(startPercent);

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(endPercent);
    }, 100);
    return () => clearTimeout(timer);
  }, [startPercent, endPercent]);

  return (
    <ProgressBarContainer>
      <ProgressBarFill width={width}>
        <SubBar />
      </ProgressBarFill>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
