import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// If you have a context for authentication:
import { useAuth } from '../../../contexts/AuthContext';
// Or import your own API utils:
import apiCall from '../../../utils/api';

// Popup
import PopupGlobale from '../../../components/PopupGlobale';

// Styles
import {
  SettingsContainer,
  SettingsGrid,
  BoxAide,
  AideTitle,
  AideSubtitle,
  AideImage,
  AideArrow,
  AideTextContainer,
  BoxEmail,
  BoxEmailTitle,
  BoxEmailContent,
  BoxInfos,
  InfosTitle,
  InfosText,
  BoxSocial,
  SocialTitle,
  SocialIconsWrapper,
  SocialIcon,
  BoxCGU,
  CGUImage,
  CGUText,
  ActionsContainer,
  BoxLogout,
  BoxDelete,
  LogoutText,
  DeleteText,
  VersionText,
} from './styles';

import needYouIcon from '../../../assets/need_you_icon.png';
import arrowRightIcon from '../../../assets/back_button_noir.png';
import instagramIcon from '../../../assets/instagram-icon.png';
import tiktokIcon from '../../../assets/tiktok-icon.png';
import cguIcon from '../../../assets/cgu_icon.png';

const Settings = () => {
  const navigate = useNavigate();
  const { logout } = useAuth(); // from your AuthContext

  const [email, setEmail] = useState('');

  // State to handle popups
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
          'GET'
        );
        // The API returns user object, e.g. { email: "..." }
        setEmail(response.email || '');
      } catch (error) {
        console.error('Error retrieving user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);

  // Logout logic
  const handleLogout = async () => {
    try {
      await logout(); // from your AuthContext
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Delete account logic
  const handleDeleteAccount = async () => {
    try {
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/user/delete/`,
        'DELETE'
      );
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Account deletion error:', error);
    }
  };

  return (
    <SettingsContainer>
      {/* We now organize our boxes in a 2-column grid */}
      <SettingsGrid>
        {/* 1) “Aide nous à améliorer l’app” Box */}
        <BoxAide onClick={() => navigate('/settings/aide-meo')}>
          <AideImage src={needYouIcon} />
          <AideTextContainer>
            <AideTitle>Aide nous à améliorer l’app !</AideTitle>
            <AideSubtitle>
              Un bug, une recommandation{'\n'}
              d’amélioration, de nouvelles{'\n'}
              fonctionnalités... Meo a besoin de toi !
            </AideSubtitle>
          </AideTextContainer>
          <AideArrow src={arrowRightIcon} />
        </BoxAide>

        {/* 2) Box for Email */}
        <BoxEmail>
          <BoxEmailTitle>Ton email</BoxEmailTitle>
          <BoxEmailContent>{email}</BoxEmailContent>
        </BoxEmail>

        {/* 3) Box for infos about the app */}
        <BoxInfos>
          <InfosTitle>Infos sur l’app</InfosTitle>
          <InfosText>
            L’application Meoria est 100% gratuite. Pour qu’elle le reste,
            nous devons limiter le chat à 10 requêtes par jour et par utilisateur.
          </InfosText>
        </BoxInfos>

        {/* 4) Box with social media icons */}
        <BoxSocial>
          <SocialTitle>Meo sur les réseaux !</SocialTitle>
          <SocialIconsWrapper>
            <a
              href="https://www.instagram.com/meoria_app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={instagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@meoria_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={tiktokIcon} alt="TikTok" />
            </a>
          </SocialIconsWrapper>
        </BoxSocial>

        {/* 5) Box for CGU */}
        <BoxCGU>
          <CGUImage src={cguIcon} alt="CGU" />
          <CGUText
            href="https://meoria.com/CGU.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conditions d’utilisation
          </CGUText>
        </BoxCGU>

        {/* 6) Action buttons (logout / delete) */}
        <ActionsContainer>
          <BoxLogout>
            <LogoutText onClick={() => setShowLogoutPopup(true)}>
              Me déconnecter
            </LogoutText>
          </BoxLogout>
          <BoxDelete>
            <DeleteText onClick={() => setShowDeletePopup(true)}>
              Supprimer mon compte
            </DeleteText>
          </BoxDelete>
        </ActionsContainer>
      </SettingsGrid>

      {/* Version info at the bottom */}
      <VersionText>
        Version {process.env.REACT_APP_VERSION || '1.0.1'}
      </VersionText>

      {/* Popup for Logout */}
      <PopupGlobale
        isVisible={showLogoutPopup}
        message="Êtes-vous sûr de vouloir vous déconnecter ?"
        cancelText="Annuler"
        confirmText="Continuer"
        onCancel={() => setShowLogoutPopup(false)}
        onConfirm={() => {
          handleLogout();
          setShowLogoutPopup(false);
        }}
      />

      {/* Popup for Delete Account */}
      <PopupGlobale
        isVisible={showDeletePopup}
        message="Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible"
        cancelText="Annuler"
        confirmText="Continuer"
        onCancel={() => setShowDeletePopup(false)}
        onConfirm={() => {
          handleDeleteAccount();
          setShowDeletePopup(false);
        }}
      />
    </SettingsContainer>
  );
};

export default Settings;
