import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import apiCall from '../../utils/api';
import emptyHeartIcon from '../../assets/coeur_vide_rouge_icon.png';
import filledHeartIcon from '../../assets/coeur_icon.png';

import {
  PlaceholderLine,
} from '../DecouvrirFormations/styles';

import {
  ScoreValue,
  ScoreContainer,
  RankNumber,
  TrophyIcon,
  ScrollableRankings,
  ContentWrapper,
  FilterModal,
  CloseButton,
  ClearAllButton,
  FilterSection,
  FilterTitle,
  FilterOptionsGrid,
  FilterOption,
  FilterSeparator,
  DomainIcon,
  SeeMoreButton,
  ModalOverlay,
  ScoreCircle,
  HeartIconClassement,
} from './styles';

import { domainIconMapRaw } from '../Onboarding/domainIconMap';

import TrophyGold from '../../assets/top1_icon.png';
import TrophySilver from '../../assets/top2_icon.png';
import TrophyBronze from '../../assets/top3_icon.png';
import { allDomains, allDurations, allFamilles_map_label } from './data';

const EmptyStateContainer = styled.div`
  padding: 20px;
  text-align: center;
  font-family: Gabarito;
  font-size: 16px;
  color: #626060;
`;

const FilterLine = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const ClearFilterButton = styled.button`
  margin-left: 10px;
  background: #38B6FF;
  color: #fff;
  border: none;
  border-radius: 9px;
  padding: 4px 8px;
  cursor: pointer;
`;

const EstablishmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 60%;
`;

const EstablishmentCard = styled.div`
  width: 95%;
  margin-left: 2.5%;
  height: 100px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
`;

const EstablishmentName = styled.div`
  color: #000;
  text-align: left;
  font-family: Gabarito;
  font-size: 16px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: 2.4em;
`;

const EstablishmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Gabarito;
  font-size: 14px;
  color: #626060;
  div:last-child {
    color: #888;
    font-size: 13px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FormationsClassement = ({ searchKeyword = '', showFilterModal = false, setShowFilterModal = () => {} }) => {
  // Ajouter des keyframes pour l'animation
  useEffect(() => {
    // On crée un style dynamique pour l'animation avec une transition plus fluide
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes pulse {
        0% { transform: scale(1); }
        40% { transform: scale(1.3); }
        100% { transform: scale(1); }
      }
    `;
    document.head.appendChild(styleElement);
    
    // Nettoyage lors du démontage du composant
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  // États pour les filtres sélectionnés
  const [selectedDomainsFormations, setSelectedDomainsFormations] = useState(() => {
    const stored = localStorage.getItem('selectedDomainsFormations');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedUniqueTypesFormations, setSelectedUniqueTypesFormations] = useState(() => {
    const stored = localStorage.getItem('selectedUniqueTypesFormations');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedDurationsFormations, setSelectedDurationsFormations] = useState(() => {
    const stored = localStorage.getItem('selectedDurationsFormations');
    return stored ? JSON.parse(stored) : [];
  });
  
  // Nouveaux états pour les filtres supplémentaires
  const [selectedSalaryRange, setSelectedSalaryRange] = useState(() => {
    const stored = localStorage.getItem('selectedSalaryRange');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedCostRange, setSelectedCostRange] = useState(() => {
    const stored = localStorage.getItem('selectedCostRange');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedAlternance, setSelectedAlternance] = useState(() => {
    const stored = localStorage.getItem('selectedAlternance');
    return stored ? JSON.parse(stored) : [];
  });
  
  const [selectedSchoolType, setSelectedSchoolType] = useState(() => {
    const stored = localStorage.getItem('selectedSchoolType');
    return stored ? JSON.parse(stored) : [];
  });

  // Add this SVG rendering function within your FormationsClassement component
const renderScoreCircle = (score, index) => {
  const numericScore = Number(score) || 0;
  const clampedScore = Math.max(0, Math.min(100, numericScore));
  
  // Define progressive color gradient based on score
  // Color stops for progressive gradient (from red to yellow to green)
  const colorStops = [
    { value: 0, color: '#FF0000' },   // Red at 0%
    { value: 50, color: '#F1E424' },  // Yellow at 50%
    { value: 100, color: '#32EA2C' }  // Green at 100%
  ];
  
  // Find the two color stops to lerp between
  let lowerStop = colorStops[0];
  let upperStop = colorStops[colorStops.length - 1];
  
  for (let i = 0; i < colorStops.length - 1; i++) {
    if (clampedScore >= colorStops[i].value && clampedScore <= colorStops[i + 1].value) {
      lowerStop = colorStops[i];
      upperStop = colorStops[i + 1];
      break;
    }
  }
  
  // Calculate interpolation factor
  const factor = (clampedScore - lowerStop.value) / (upperStop.value - lowerStop.value);
  
  // Helper function to interpolate between hex colors
  const lerpColor = (a, b, factor) => {
    const ah = parseInt(a.replace(/#/g, ''), 16);
    const ar = ah >> 16;
    const ag = (ah >> 8) & 0xff;
    const ab = ah & 0xff;
    
    const bh = parseInt(b.replace(/#/g, ''), 16);
    const br = bh >> 16;
    const bg = (bh >> 8) & 0xff;
    const bb = bh & 0xff;
    
    const rr = ar + factor * (br - ar);
    const rg = ag + factor * (bg - ag);
    const rb = ab + factor * (bb - ab);
    
    return `#${((1 << 24) + (Math.round(rr) << 16) + (Math.round(rg) << 8) + Math.round(rb)).toString(16).slice(1)}`;
  };
  
  // Calculate gradient colors
  const gradientStart = lerpColor(lowerStop.color, upperStop.color, factor);
  const gradientEnd = lerpColor(lowerStop.color, upperStop.color, Math.max(0, factor - 0.3)); // Slightly darker for end
  
  // Use start color for text
  const textColor = gradientStart;
  
  // Create a unique ID for this gradient
  const gradientId = `scoreGradient_${index}_${clampedScore}`;
  
  return {
    svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
        <circle cx="42.5" cy="42.5" r="37.5" stroke={`url(#${gradientId})`} strokeWidth="10"/>
        <defs>
          <linearGradient id={gradientId} x1="85" y1="42.5" x2="0" y2="42.5" gradientUnits="userSpaceOnUse">
            <stop stopColor={gradientStart}/>
            <stop offset="1" stopColor={gradientEnd}/>
          </linearGradient>
        </defs>
      </svg>
    ),
    textColor
  };
};

  const modalRef = useRef(null);
  
  // États pour les options "voir plus/voir moins"
  const [showAllDomains, setShowAllDomains] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);
  const [showAllDurations, setShowAllDurations] = useState(false);
  const [showAllSalaries, setShowAllSalaries] = useState(false);
  const [showAllCosts, setShowAllCosts] = useState(false);
  const [showAllSchoolTypes, setShowAllSchoolTypes] = useState(false);

  // Sauvegarde des filtres dans le localStorage
  useEffect(() => {
    localStorage.setItem('selectedDomainsFormations', JSON.stringify(selectedDomainsFormations));
  }, [selectedDomainsFormations]);
  
  useEffect(() => {
    localStorage.setItem('selectedUniqueTypesFormations', JSON.stringify(selectedUniqueTypesFormations));
  }, [selectedUniqueTypesFormations]);
  
  useEffect(() => {
    localStorage.setItem('selectedDurationsFormations', JSON.stringify(selectedDurationsFormations));
  }, [selectedDurationsFormations]);
  
  // Sauvegarde des nouveaux filtres
  useEffect(() => {
    localStorage.setItem('selectedSalaryRange', JSON.stringify(selectedSalaryRange));
  }, [selectedSalaryRange]);
  
  useEffect(() => {
    localStorage.setItem('selectedCostRange', JSON.stringify(selectedCostRange));
  }, [selectedCostRange]);
  
  useEffect(() => {
    localStorage.setItem('selectedAlternance', JSON.stringify(selectedAlternance));
  }, [selectedAlternance]);
  
  useEffect(() => {
    localStorage.setItem('selectedSchoolType', JSON.stringify(selectedSchoolType));
  }, [selectedSchoolType]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showFilterModal && modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilterModal(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFilterModal]);
  
  // Définition des options pour les nouveaux filtres
  const salaryRanges = [
    { id: 'very_low', label: 'Salaire très bas (< 1 500 € net/mois)' },
    { id: 'low', label: 'Salaire bas (1 500 - 2 000 € net/mois)' },
    { id: 'medium', label: 'Salaire moyen (2 000 - 2 500 € net/mois)' },
    { id: 'high', label: 'Salaire élevé (2 500 - 3 000 € net/mois)' },
    { id: 'very_high', label: 'Salaire très élevé (> 3 000 € net/mois)' }
  ];
  
  const costRanges = [
    { id: 'free', label: 'Gratuit (moins de 500 €/an)' },
    { id: 'very_low', label: 'Prix très bas (500 € - 2 000 €/an)' },
    { id: 'low', label: 'Prix bas (2 000 € - 4 000 €/an)' },
    { id: 'medium', label: 'Prix moyen (4 000 € - 8 000 €/an)' },
    { id: 'high', label: 'Prix élevé (8 000 € - 15 000 €/an)' },
    { id: 'very_high', label: 'Prix très élevé (plus de 15 000 €/an)' }
  ];
  
  const alternanceOptions = [
    { id: 'yes', label: 'Oui' },
    { id: 'no', label: 'Non' }
  ];
  
  const schoolTypes = [
    { id: 'public', label: 'Publics' },
    { id: 'private_state_contract', label: 'Privés en contrat avec l\'État' },
    { id: 'private_no_state_contract', label: 'Privés sans contrat avec l\'État' },
    { id: 'private', label: 'Privés' }
  ];

  // Chargement des données
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rankingsFormations, setRankingsFormations] = useState({});
  const isFirstRender = useRef(true);

  const fetchFormationsRanking = async () => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams();
      
      if (selectedDomainsFormations.length) {
        params.append('domains_formations', selectedDomainsFormations.join(','));
      }
      
      if (selectedUniqueTypesFormations.length) {
        params.append('familles_formations', selectedUniqueTypesFormations.join(','));
      }
      
      if (selectedDurationsFormations.length) {
        params.append('durations_formations', selectedDurationsFormations.join(','));
      }
      
      // Ajouter le mot-clé de recherche pour la recherche combinée
      if (searchKeyword && searchKeyword.trim() !== "") {
        params.append('search_keyword', searchKeyword.trim());
      }
      
      // Ajouter les nouveaux paramètres
      if (selectedSalaryRange.length) {
        params.append('salary_range', selectedSalaryRange.join(','));
      }
      
      if (selectedCostRange.length) {
        params.append('cost_range', selectedCostRange.join(','));
      }
      
      if (selectedAlternance.length) {
        params.append('alternance', selectedAlternance.join(','));
      }
      
      if (selectedSchoolType.length) {
        params.append('school_type', selectedSchoolType.join(','));
      }
          // 5. Modifications à apporter dans la méthode fetchFormationsRanking dans formationsClassement.js
  // Ajoutez ce code après la ligne qui ajoute selectedSchoolType aux paramètres
      const includeCity = localStorage.getItem('includeCity');
      if (includeCity) {
        params.append('includeCity', includeCity);
      }
      
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/ranking_formations?${params.toString()}`,
        'GET'
      );

      if (response && response.rankings_formations) {
        
        setRankingsFormations(response.rankings_formations);
        localStorage.setItem('ranking_formation', JSON.stringify(response.rankings_formations));
        localStorage.setItem('need_update_ranking_formation', 'false');
      }

    } catch (error) {
      console.error('Error fetching formations rankings:', error);
      setRankingsFormations({});
      localStorage.setItem('ranking_formation', '{}');
      localStorage.setItem('need_update_ranking_formation', 'false');
    } finally {
      setIsLoading(false);
    }
  };

  // Effet pour initialiser et mettre à jour les données
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Validation des filtres stockés
      for (const d of selectedDomainsFormations) {
        if (!allDomains.includes(d)) {
          setSelectedDomainsFormations((prev) => prev.filter((x) => x !== d));
        }
      }
      
      for (const t of selectedUniqueTypesFormations) {
        if (!Object.keys(allFamilles_map_label).includes(t)) {
          setSelectedUniqueTypesFormations((prev) => prev.filter((x) => x !== t));
        }
      }
      
      for (const dd of selectedDurationsFormations) {
        if (!allDurations.includes(dd)) {
          setSelectedDurationsFormations((prev) => prev.filter((x) => x !== dd));
        }
      }
      
      // Valider les nouveaux filtres
      for (const s of selectedSalaryRange) {
        if (!salaryRanges.map(item => item.id).includes(s)) {
          setSelectedSalaryRange((prev) => prev.filter((x) => x !== s));
        }
      }
      
      for (const c of selectedCostRange) {
        if (!costRanges.map(item => item.id).includes(c)) {
          setSelectedCostRange((prev) => prev.filter((x) => x !== c));
        }
      }
      
      for (const a of selectedAlternance) {
        if (!alternanceOptions.map(item => item.id).includes(a)) {
          setSelectedAlternance((prev) => prev.filter((x) => x !== a));
        }
      }
      
      for (const st of selectedSchoolType) {
        if (!schoolTypes.map(item => item.id).includes(st)) {
          setSelectedSchoolType((prev) => prev.filter((x) => x !== st));
        }
      }
      
      return;
    }
    
    localStorage.setItem('need_update_ranking_formation', 'true');
    fetchFormationsRanking();
  }, [
    selectedDomainsFormations,
    selectedUniqueTypesFormations,
    selectedDurationsFormations,
    searchKeyword,
    selectedSalaryRange,
    selectedCostRange,
    selectedAlternance,
    selectedSchoolType
  ]);

  useEffect(() => {
    const needUpdate = localStorage.getItem('need_update_ranking_formation');
    if (needUpdate === 'true') {
      fetchFormationsRanking();
    } else if (needUpdate === 'false') {
      const stored = localStorage.getItem('ranking_formation');
      if (stored) {
        setRankingsFormations(JSON.parse(stored));
      } else {
        setRankingsFormations({});
      }
      setIsLoading(false);
    } else {
      localStorage.setItem('need_update_ranking_formation', 'true');
      fetchFormationsRanking();
    }
  }, []);

  // État pour suivre quelle formation a sa pulsation en cours
  const [pulsatingHeartId, setPulsatingHeartId] = useState(null);
  
  const handleHeartClick = async (e, formation) => {
    e.stopPropagation();
    
    const newFavoriteStatus = !formation.is_favorite;
    
    setPulsatingHeartId(formation.id);
    setTimeout(() => {
      setPulsatingHeartId(null);
    }, 600);
    
    // Update rankings immediately
    const updatedRankings = {...rankingsFormations};
    Object.keys(updatedRankings).forEach(key => {
      if (updatedRankings[key].id === formation.id) {
        updatedRankings[key] = {...updatedRankings[key], is_favorite: newFavoriteStatus};
      }
    });
    setRankingsFormations(updatedRankings);
    localStorage.setItem('ranking_formation', JSON.stringify(updatedRankings));
    
    try {
      if (!newFavoriteStatus) {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`,
          'DELETE',
          { id: formation.id }
        );
      } else {
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_formation/`,
          'POST',
          { 
            id: formation.id, 
            nom: formation.nom_formation, 
            etablissement: formation.nom_etablissement, 
            matchPercentage: formation.score_formations || 0 
          }
        );
      }
    } catch (error) {
      console.error('Error updating favorite:', error);
      
      // En cas d'erreur, rétablir l'état précédent
      const revertedRankings = {...rankingsFormations};
      Object.keys(revertedRankings).forEach(key => {
        if (revertedRankings[key].id === formation.id) {
          revertedRankings[key] = {...revertedRankings[key], is_favorite: !newFavoriteStatus};
        }
      });
      setRankingsFormations(revertedRankings);
    }
  };

  // Gestion de la fermeture de la modale et mise à jour des filtres
  const handleFilterModalClose = () => {
    setShowFilterModal(false);
  };

  // Conversion en tableau
  const topFormationsArray = Object.entries(rankingsFormations);

  // Helpers pour le rendu
  const renderTrophyIcon = (rank) => {
    switch (rank) {
      case 1:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyGold})` }} />;
      case 2:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophySilver})` }} />;
      case 3:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyBronze})` }} />;
      default:
        return <RankNumber>{rank}</RankNumber>;
    }
  };

  // Fonction pour gérer le clic sur une option de filtre dans la modale
  const handleFilterOptionClick = (filterType, value) => {
    if (filterType === 'domains') {
      if (selectedDomainsFormations.includes(value)) {
        setSelectedDomainsFormations(selectedDomainsFormations.filter(item => item !== value));
      } else {
        setSelectedDomainsFormations([...selectedDomainsFormations, value]);
      }
    } else if (filterType === 'types') {
      const selectedKey = Object.keys(allFamilles_map_label).find(key => allFamilles_map_label[key] === value);
      if (selectedKey) {
        if (selectedUniqueTypesFormations.includes(selectedKey)) {
          setSelectedUniqueTypesFormations(selectedUniqueTypesFormations.filter(item => item !== selectedKey));
        } else {
          setSelectedUniqueTypesFormations([...selectedUniqueTypesFormations, selectedKey]);
        }
      }
    } else if (filterType === 'durations') {
      if (selectedDurationsFormations.includes(value)) {
        setSelectedDurationsFormations(selectedDurationsFormations.filter(item => item !== value));
      } else {
        setSelectedDurationsFormations([...selectedDurationsFormations, value]);
      }
    } else if (filterType === 'salary') {
      if (selectedSalaryRange.includes(value)) {
        setSelectedSalaryRange(selectedSalaryRange.filter(item => item !== value));
      } else {
        setSelectedSalaryRange([...selectedSalaryRange, value]);
      }
    } else if (filterType === 'cost') {
      if (selectedCostRange.includes(value)) {
        setSelectedCostRange(selectedCostRange.filter(item => item !== value));
      } else {
        setSelectedCostRange([...selectedCostRange, value]);
      }
    } else if (filterType === 'alternance') {
      if (selectedAlternance.includes(value)) {
        setSelectedAlternance(selectedAlternance.filter(item => item !== value));
      } else {
        setSelectedAlternance([...selectedAlternance, value]);
      }
    } else if (filterType === 'school_type') {
      if (selectedSchoolType.includes(value)) {
        setSelectedSchoolType(selectedSchoolType.filter(item => item !== value));
      } else {
        setSelectedSchoolType([...selectedSchoolType, value]);
      }
    }
  };

  // Fonction pour effacer tous les filtres
  const handleClearAllFilters = () => {
    // Clear all filter arrays
    setSelectedDomainsFormations([]);
    setSelectedUniqueTypesFormations([]);
    setSelectedDurationsFormations([]);
    setSelectedSalaryRange([]);
    setSelectedCostRange([]);
    setSelectedAlternance([]);
    setSelectedSchoolType([]);
    
    // Force a refresh of the rankings
    localStorage.setItem('need_update_ranking_formation', 'true');
    fetchFormationsRanking();
    handleFilterModalClose();
  };

  // Fonction pour obtenir l'icône de domaine ou un placeholder
  const getDomainIcon = (domainName) => {
    const icon = domainIconMapRaw[domainName];
    return icon || null; // Retourne l'icône ou null si non trouvée
  };

  return (
    <ContentWrapper>
      {/* Filter button without the search container */}
      {showFilterModal && (
  <>
  <ModalOverlay onClick={() => setShowFilterModal(false)} />
  <FilterModal ref={modalRef}>
    {/* Remove FilterModalHeader and directly display the filters */}
    {/* Keep the clear and close buttons but reposition them */}
    <ClearAllButton onClick={handleClearAllFilters}>
      Réinitialiser
    </ClearAllButton>
    <CloseButton onClick={handleFilterModalClose}>×</CloseButton>
    
    {/* Section Domaine - First Filter */}
    <FilterSection>
      <FilterTitle>Domaine</FilterTitle>
      <FilterOptionsGrid>
        {allDomains
          .slice(0, showAllDomains ? allDomains.length : 10)
          .map((domain) => (
            <FilterOption
              key={domain}
              selected={selectedDomainsFormations.includes(domain)}
              onClick={() => handleFilterOptionClick('domains', domain)}
            >
              {getDomainIcon(domain) && (
                <DomainIcon src={getDomainIcon(domain)} alt={domain} />
              )}
              {domain}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {allDomains.length > 10 && (
        <SeeMoreButton onClick={() => setShowAllDomains(!showAllDomains)}>
          {showAllDomains ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
    
    <FilterSeparator />
    
    {/* Section Type */}
    <FilterSection>
      <FilterTitle>Type</FilterTitle>
      <FilterOptionsGrid>
        {Object.entries(allFamilles_map_label)
          .slice(0, showAllTypes ? Object.entries(allFamilles_map_label).length : 10)
          .map(([key, value]) => (
            <FilterOption
              key={key}
              selected={selectedUniqueTypesFormations.includes(key)}
              onClick={() => handleFilterOptionClick('types', value)}
            >
              {value}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {Object.entries(allFamilles_map_label).length > 10 && (
        <SeeMoreButton onClick={() => setShowAllTypes(!showAllTypes)}>
          {showAllTypes ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
    
    <FilterSeparator />
    
    {/* The rest of the filter sections remain the same */}
    {/* Section Durée */}
    <FilterSection>
      <FilterTitle>Durée</FilterTitle>
      <FilterOptionsGrid>
        {allDurations
          .slice(0, showAllDurations ? allDurations.length : 10)
          .map((duration) => (
            <FilterOption
              key={duration}
              selected={selectedDurationsFormations.includes(duration)}
              onClick={() => handleFilterOptionClick('durations', duration)}
            >
              {duration}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {allDurations.length > 10 && (
        <SeeMoreButton onClick={() => setShowAllDurations(!showAllDurations)}>
          {showAllDurations ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
    
    <FilterSeparator />
    
    {/* Additional sections (Salaire, Coût, etc.) continue as before */}
    {/* Section Salaire */}
    <FilterSection>
      <FilterTitle>Salaire</FilterTitle>
      <FilterOptionsGrid>
        {salaryRanges
          .slice(0, showAllSalaries ? salaryRanges.length : 10)
          .map((salary) => (
            <FilterOption
              key={salary.id}
              selected={selectedSalaryRange.includes(salary.id)}
              onClick={() => handleFilterOptionClick('salary', salary.id)}
            >
              {salary.label}
            </FilterOption>
          ))}
      </FilterOptionsGrid>
      {salaryRanges.length > 10 && (
        <SeeMoreButton onClick={() => setShowAllSalaries(!showAllSalaries)}>
          {showAllSalaries ? "Voir moins" : "Voir plus"}
        </SeeMoreButton>
      )}
    </FilterSection>
          
          <FilterSeparator />
          
          {/* Section Coût */}
          <FilterSection>
            <FilterTitle>Coût</FilterTitle>
            <FilterOptionsGrid>
              {costRanges
                .slice(0, showAllCosts ? costRanges.length : 10)
                .map((cost) => (
                  <FilterOption
                    key={cost.id}
                    selected={selectedCostRange.includes(cost.id)}
                    onClick={() => handleFilterOptionClick('cost', cost.id)}
                  >
                    {cost.label}
                  </FilterOption>
                ))}
            </FilterOptionsGrid>
            {costRanges.length > 10 && (
              <SeeMoreButton onClick={() => setShowAllCosts(!showAllCosts)}>
                {showAllCosts ? "Voir moins" : "Voir plus"}
              </SeeMoreButton>
            )}
          </FilterSection>
          
          <FilterSeparator />
          
          {/* Section Alternance */}
          <FilterSection>
            <FilterTitle>Alternance</FilterTitle>
            <FilterOptionsGrid>
              {alternanceOptions.map((option) => (
                <FilterOption
                  key={option.id}
                  selected={selectedAlternance.includes(option.id)}
                  onClick={() => handleFilterOptionClick('alternance', option.id)}
                >
                  {option.label}
                </FilterOption>
              ))}
            </FilterOptionsGrid>
          </FilterSection>
          
          <FilterSeparator />
          
          {/* Section Type d'École */}
          <FilterSection>
            <FilterTitle>Type d'École</FilterTitle>
            <FilterOptionsGrid>
              {schoolTypes
                .slice(0, showAllSchoolTypes ? schoolTypes.length : 10)
                .map((type) => (
                  <FilterOption
                    key={type.id}
                    selected={selectedSchoolType.includes(type.id)}
                    onClick={() => handleFilterOptionClick('school_type', type.id)}
                  >
                    {type.label}
                  </FilterOption>
                ))}
            </FilterOptionsGrid>
            {schoolTypes.length > 10 && (
              <SeeMoreButton onClick={() => setShowAllSchoolTypes(!showAllSchoolTypes)}>
                {showAllSchoolTypes ? "Voir moins" : "Voir plus"}
              </SeeMoreButton>
            )}
          </FilterSection>
        </FilterModal>
        </>
      )}

      {/* Liste des classements ou message vide */}
      <ScrollableRankings>
        {isLoading ? (
          <EstablishmentList>
            {Array.from({ length: 3 }).map((_, idx) => (
              <EstablishmentCard key={idx}>
                <div style={{ width: "100%" }}>
                  <PlaceholderLine width="90%" />
                  <PlaceholderLine width="60%" />
                </div>
              </EstablishmentCard>
            ))}
          </EstablishmentList>
        ) : topFormationsArray.length > 0 ? (
          <EstablishmentList>
            {topFormationsArray.map(([rankKey, item]) => {
              const rank = parseInt(rankKey.replace("top", ""), 10);
              return (
                <EstablishmentCard key={item.id} onClick={() => navigate(`/formation?id=${item.id}`)}>
                  {rank <= 3 ? renderTrophyIcon(rank) : <RankNumber>{rank}</RankNumber>}
                  <div style={{ width: "calc(100% - 170px)", flexGrow: 1 }}>
                    <EstablishmentName>{item.nom_formation}</EstablishmentName>
                    <EstablishmentDetails>
                      <div>{item.nom_etablissement}</div>
                    </EstablishmentDetails>
                  </div>
                  <ScoreContainer>
                    <ScoreCircle>
                      {(() => {
                        const { svg, textColor } = renderScoreCircle(item.score_formations);
                        return (
                          <>
                            {svg}
                            <ScoreValue color={textColor}>{item.score_formations || 0}%</ScoreValue>
                          </>
                        );
                      })()}
                    </ScoreCircle>
                    <HeartIconClassement 
                      onClick={(e) => handleHeartClick(e, item)}
                      style={{
                        transform: pulsatingHeartId === item.id ? 'scale(1.3)' : 'scale(1)',
                        transition: 'transform 0.3s ease-in-out'
                      }}
                    >
                      <img 
                        src={item.is_favorite ? filledHeartIcon : emptyHeartIcon} 
                        alt={item.is_favorite ? "Favorite" : "Add to favorites"}
                        style={{ 
                          cursor: 'pointer',
                          animation: pulsatingHeartId === item.id ? 'pulse 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)' : 'none'
                        }}
                      />
                    </HeartIconClassement>
                  </ScoreContainer>
                </EstablishmentCard>
              );
            })}
          </EstablishmentList>
        ) : (
          <EmptyStateContainer>
            <div>Aucune formation trouvée pour les filtres :</div>
            {searchKeyword.trim() !== "" && (
              <FilterLine>
                Recherche : {searchKeyword}
                <ClearFilterButton onClick={() => {
                  // We need to notify the parent component to update the searchKeyword
                  localStorage.setItem('searchKeyword', '');
                  localStorage.setItem('need_update_ranking_formation', 'true');
                  window.location.reload(); // A simple way to force update
                }}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedDomainsFormations.length > 0 && (
              <FilterLine>
                Domaines : {selectedDomainsFormations.slice(0, 5).join(" ou ")}
                {selectedDomainsFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedDomainsFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedUniqueTypesFormations.length > 0 && (
              <FilterLine>
                Types :{" "}
                {selectedUniqueTypesFormations
                  .slice(0, 5)
                  .map((key) => allFamilles_map_label[key])
                  .join(" ou ")}
                {selectedUniqueTypesFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedUniqueTypesFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedDurationsFormations.length > 0 && (
              <FilterLine>
                Durée : {selectedDurationsFormations.slice(0, 5).join(" ou ")}
                {selectedDurationsFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedDurationsFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedSalaryRange.length > 0 && (
              <FilterLine>
                Salaire : {selectedSalaryRange.slice(0, 5).map(id => salaryRanges.find(s => s.id === id)?.label).join(" ou ")}
                {selectedSalaryRange.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedSalaryRange([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedCostRange.length > 0 && (
              <FilterLine>
                Coût : {selectedCostRange.slice(0, 5).map(id => costRanges.find(c => c.id === id)?.label).join(" ou ")}
                {selectedCostRange.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedCostRange([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedAlternance.length > 0 && (
              <FilterLine>
                Alternance : {selectedAlternance.map(id => alternanceOptions.find(a => a.id === id)?.label).join(" ou ")}
                <ClearFilterButton onClick={() => setSelectedAlternance([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedSchoolType.length > 0 && (
              <FilterLine>
                Type d'École : {selectedSchoolType.slice(0, 5).map(id => schoolTypes.find(t => t.id === id)?.label).join(" ou ")}
                {selectedSchoolType.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedSchoolType([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {localStorage.getItem('includeCity') && 
              localStorage.getItem('includeCity') !== "Toute la France" && (
              <FilterLine>
                Ville : {localStorage.getItem('includeCity')}
                <ClearFilterButton onClick={() => {
                  localStorage.setItem('includeCity', 'Toute la France');
                  localStorage.setItem('need_update_ranking_formation', 'true');
                  window.location.reload(); // Force a refresh to update the UI
                }}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
          </EmptyStateContainer>
        )}
      </ScrollableRankings>
    </ContentWrapper>
  );
};

export default FormationsClassement;