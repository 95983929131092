// src/components/PopupGlobaleOneChoice/PopupGlobaleOneChoice.js
import React from 'react';
import {
  PopupOverlay,
  PopupContent,
  PopupMessage,
  PopupButtonContainer,
  PopupButtonYes
} from './styles';

const PopupGlobaleOneChoice = ({ 
  isVisible,
  message,
  onClose,
  buttonText = 'C\'est noté !'
}) => {
  if (!isVisible) return null;

  return (
    <PopupOverlay>
      <PopupContent>
        <PopupMessage>{message}</PopupMessage>
        <PopupButtonContainer>
          <PopupButtonYes onClick={onClose}>{buttonText}</PopupButtonYes>
        </PopupButtonContainer>
      </PopupContent>
    </PopupOverlay>
  );
};

export default PopupGlobaleOneChoice;