import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSpring, animated, config, to } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import Meo from '../../assets/meo_quiz.png';
import {
  QuizContainer,
  BackgroundBall,
  CardWrapper,
  FlyEmoji,
  QuestionCard,
  QuestionText,
  StyledIcon,
  AnswerOptionsContainer,
  AnswerButton,
  QuizMessage,
  StyledThumbsUp,
  StyledThumbsDown,
  QuizTypeIndicator,
  ProgressContainer,
  QuizNumber,
  ProgressBar,
  Progress,
  BrandContainer,
  BrandImage,
  BrandSubtitle,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  CategoryContainer,
  CategoryIcon,
  categoryIcons,
  CategoryText,
} from './styles';

import {
  MdOutlineSwipeUp,
  MdOutlineSwipeDown,
  MdOutlineSwipeLeft,
  MdOutlineSwipeRight
} from 'react-icons/md';

import apiCall from '../../utils/api';
import useDeviceType from '../useDeviceType';
import { useQuiz } from '../../contexts/QuizContext';
import questionIcon from '../../assets/dont_know_icon.png';
import StarIcon from '../../assets/etoile_icon.png';
import coeurVert from '../../assets/coeur_vert.png';
import croixRouge from '../../assets/croix_rouge.png';


const ANSWER_MAPPING = {
  1: 'Non',
  2: 'Je ne sais pas',
  3: 'Oui',
  4: "J'adore"
};


const QuizTypeFormation = ({ onQuizComplete }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const [showDirectionArrows, setShowDirectionArrows] = useState(true);
  const [clickedButton, setClickedButton] = useState(null);
  const clickTimeoutRef = useRef(null);
  const hasSwipedRef = useRef(false);
  const [flyEmojiDirection, setFlyEmojiDirection] = useState(null);
  const [flyEmojiSrc, setFlyEmojiSrc] = useState(null);
  const cardWrapperRef = useRef(null);

  const {
    currentQuestion,
    setCurrentQuestion,
    quizProgress,
    setQuizProgress,
    setIsQuizInitialized,
    setQuizId,
    quizCompleted,
    setQuizCompleted,
  } = useQuiz();

  // Calculate progress percentage
  const progressPercent = quizProgress.quiz1_total > 0
    ? (quizProgress.quiz1_progress / quizProgress.quiz1_total) * 100
    : 0;

  // FULL UPDATED SPRING HOOK
  // Replace your existing useSpring initialization in QuizTypeFormation.jsx with:
  const [{ x, y, rotate, scale }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    scale: 1,
    config: { tension: 180, friction: 18 }, // <— more fluid
  }));

  const fetchInitialQuestion = useCallback(async () => {
    try {
      // 1) Call the /quiz/start/ endpoint to ensure a UserQuizID exists
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/start/`,
        'POST'
      );
  
      // 2) Now fetch the first question
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/question-type-formation/`,
        'GET'
      );
  
      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category,
        });
        if (response.progress) {
          setQuizProgress(response.progress);
        }
      } else {
        console.warn('No question returned:', response.message);
      }
    } catch (error) {
      console.error("Error fetching the initial question:", error);
    }
  }, [setCurrentQuestion, setQuizProgress]);
  

  const cardRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });
  // Juste avant ou après `const [clickedButton, setClickedButton] = useState(null);`
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [finalSwipedButton, setFinalSwipedButton] = useState(null);
  const iconSrcMapping = {
    up: StarIcon,        // J'adore
    right: coeurVert,     // Oui
    down: questionIcon,   // Je ne sais pas
    left: croixRouge      // Non
  };

  const animateCard = (direction) => {
    const distance = window.innerWidth + 200;
    const verticalDistance = window.innerHeight + 200;

    switch (direction) {
      case 'up':
        api.start({
          y: -verticalDistance,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'down':
        api.start({
          y: verticalDistance,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'left':
        api.start({
          x: -distance,
          rotate: -45,
          config: { tension: 200, friction: 20 }
        });
        break;
      case 'right':
        api.start({
          x: distance,
          rotate: 45,
          config: { tension: 200, friction: 20 }
        });
        break;
      default:
        api.start({
          x: 0,
          y: 0,
          rotate: 0,
          config: { tension: 200, friction: 20 }
        });
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
  
    // The "absolute" bounding box of the wrapper
    const wrapperRect = cardWrapperRef.current.getBoundingClientRect();
  
    // The bounding box of the card
    const cardRect = cardRef.current.getBoundingClientRect();
  
    // Where inside the card did the user touch?
    // (But subtract the wrapper's top/left so we're in *wrapper* coords)
    const offsetX = touch.clientX - cardRect.left;
    const offsetY = touch.clientY - cardRect.top;
  
    touchStartRef.current = {
      offsetX,
      offsetY,
      wrapperLeft: wrapperRect.left,
      wrapperTop: wrapperRect.top
    };
  };
  
  

  const NO_HIGHLIGHT_RADIUS = 80; // Adjust as you like

  // FULL UPDATED handleTouchMove FUNCTION
// Full updated handleTouchMove function:
const handleTouchMove = (e) => {
  if (!cardRef.current) return;

  // 1) Find screen center
  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;

  // 2) Get the current touch coordinates
  const touch = e.touches[0];
  const currentX = touch.clientX;
  const currentY = touch.clientY;

  // 3) Calculate deltas relative to center
  const deltaX = currentX - centerX;
  const deltaY = currentY - centerY;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

  // 4) If inside a small radius around center, no direction highlight
  if (distance < NO_HIGHLIGHT_RADIUS) {
    setSwipeDirection(null);
    api.start({
      x: deltaX,
      y: deltaY,
      rotate: 0,
      scale: 1,
      immediate: true,
    });
    return;
  }

  // 5) Simpler direction detection: horizontal vs. vertical
  let direction = null;
  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    direction = (deltaX > 0) ? 'right' : 'left';
  } else {
    direction = (deltaY > 0) ? 'down' : 'up';
  }
  setSwipeDirection(direction);

  // 6) Slight tilt + scale while dragging
  const { offsetX, offsetY, wrapperLeft, wrapperTop } = touchStartRef.current;
  const newX = (currentX - wrapperLeft) - offsetX;
  const newY = (currentY - wrapperTop) - offsetY;
  const rotation = (currentX - centerX) / 12; // or tweak for more/less tilt

  api.start({
    x: newX,
    y: newY,
    rotate: rotation,
    scale: 1.05,
    immediate: true,
  });
};


  

const SWIPE_THRESHOLD = 80; // For distance from center

// FULL UPDATED handleTouchEnd FUNCTION
// Full updated handleTouchEnd function:
const handleTouchEnd = (e) => {
  // 1) Calculate final distance from center
  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;
  const touch = e.changedTouches[0];
  const finalDeltaX = touch.clientX - centerX;
  const finalDeltaY = touch.clientY - centerY;
  const distance = Math.sqrt(finalDeltaX * finalDeltaX + finalDeltaY * finalDeltaY);

  // 2) If not dragged far enough, reset
  if (distance < SWIPE_THRESHOLD) {
    api.start({
      x: 0,
      y: 0,
      rotate: 0,
      scale: 1,
      config: { tension: 200, friction: 20 },
    });
    setSwipeDirection(null);
    return;
  }

  // 3) Otherwise, finalize based on swipeDirection
  switch (swipeDirection) {
    case 'up':
      api.start({
        y: -window.innerHeight - 200,
        rotate: 0,
        scale: 1,
        config: { tension: 220, friction: 25 },
      });
      handleAnswer(4, 'up');
      setFinalSwipedButton('up');
      break;
    case 'right':
      api.start({
        x: window.innerWidth + 200,
        rotate: 45,
        scale: 1,
        config: { tension: 220, friction: 25 },
      });
      handleAnswer(3, 'right');
      setFinalSwipedButton('right');
      break;
    case 'down':
      api.start({
        y: window.innerHeight + 200,
        rotate: 0,
        scale: 1,
        config: { tension: 220, friction: 25 },
      });
      handleAnswer(2, 'down');
      setFinalSwipedButton('down');
      break;
    case 'left':
      api.start({
        x: -window.innerWidth - 200,
        rotate: -45,
        scale: 1,
        config: { tension: 220, friction: 25 },
      });
      handleAnswer(1, 'left');
      setFinalSwipedButton('left');
      break;
    default:
      // No recognized direction
      api.start({
        x: 0,
        y: 0,
        rotate: 0,
        scale: 1,
        config: { tension: 200, friction: 20 },
      });
      break;
  }

  // 4) Cleanup states
  setTimeout(() => {
    setFinalSwipedButton(null);
  }, 2000);

  setSwipeDirection(null);
};

  const DirectionArrows = ({ isVisible }) => {
    if (!isVisible) return null;

    return (
      <>
        <ArrowUp>
          <div className="flex flex-col items-center">
            <StyledIcon src={StarIcon} alt="Heart" fill="#FF2768" className="mb-2" />
            <MdOutlineSwipeUp size={32} />
          </div>
        </ArrowUp>
        <ArrowDown>
          <div className="flex flex-col items-center">
            <MdOutlineSwipeDown size={32} />
            <StyledIcon src={questionIcon} alt="Question" fill="yellow" className="mt-2" />
          </div>
        </ArrowDown>
        <ArrowLeft>
          <div className="flex items-center">
            <StyledThumbsDown fill="red" className="mr-2" />
            <MdOutlineSwipeLeft size={32} />
          </div>
        </ArrowLeft>
        <ArrowRight>
          <div className="flex items-center">
            <MdOutlineSwipeRight size={32} />
            <StyledThumbsUp fill="#0BDB22" className="ml-2" />
          </div>
        </ArrowRight>
      </>
    );
  };

  const handleAnswer = async (answer, direction, buttonId) => {
    if (!currentQuestion) return;
  
    // Immediately set fly-emoji direction & icon for the center-scale animation
    setFlyEmojiDirection(direction);
    setFlyEmojiSrc(iconSrcMapping[direction]);
  
    // Set button animations (both highlight & final swipe)
    setClickedButton(buttonId);
    setFinalSwipedButton(direction);
  
    // Animate the card off-screen
    animateCard(direction);
  
    // Clear any previous click timeouts
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    // Briefly highlight the pressed button
    clickTimeoutRef.current = setTimeout(() => {
      setClickedButton(null);
      clickTimeoutRef.current = null;
    }, 1000);
  
    try {
      // Submit answer & get next question
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/submit-answer-next/`,
        'POST',
        {
          question_id: currentQuestion.id,
          question_type: currentQuestion.type,
          answer: answer
        }
      );
  
      // Update quiz progress
      setQuizProgress(response.progress);
  
      // Hide swipe arrows after first answer
      if (!hasSwipedRef.current) {
        setShowDirectionArrows(false);
        hasSwipedRef.current = true;
      }
  
      // Check if quiz is complete
      if (response.progress.quiz1_progress >= response.progress.quiz1_total) {
        setQuizCompleted(true);
        if (onQuizComplete) onQuizComplete();
        return;
      }
  
      // Set next question immediately
      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category
        });
      }
  
      // Reset card position
      api.start({ x: 0, y: 0, rotate: 0, immediate: true });
  
      // Clear states after ~600ms (removes button highlight & fly-emoji)
      setTimeout(() => {
        setClickedButton(null);
        setFinalSwipedButton(null);
        setFlyEmojiDirection(null);
        setFlyEmojiSrc(null);
      }, 600);
  
    } catch (error) {
      console.error('Error submitting answer:', error);
      api.start({ x: 0, y: 0, rotate: 0, config: config.wobbly });
      setClickedButton(null);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
      }
    }
  };
  
  
  const resetQuiz = useCallback(async () => {
    try {
      const response = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`, 'POST');
      setQuizId(response.quiz_id);
      setQuizProgress(response.progress);
      setQuizCompleted(false);
      setIsQuizInitialized(false);
  
      // Set the first question after reset if it's returned
      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category,
        });
      }
  
      setIsQuizInitialized(true);
    } catch (error) {
      console.error('Error resetting quiz:', error);
    }
  }, [
    setQuizId,
    setQuizProgress,
    setQuizCompleted,
    setIsQuizInitialized,
    setCurrentQuestion
  ]);

  useEffect(() => {
    if (!quizCompleted) {
      fetchInitialQuestion();
    }
  }, [fetchInitialQuestion, quizCompleted]);
  

  useEffect(() => {
    return () => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  if (!isAuthenticated) {
    return <QuizMessage>Connecte-toi pour accéder au quiz !</QuizMessage>;
  }

  if (!currentQuestion && !quizCompleted) {
    return <QuizMessage>Chargement de la question...</QuizMessage>;
  }
  if (quizProgress.quiz_completed) {
    // If you only want to do it once, you can set a local state
    // or rely on setQuizCompleted(false) or something similar
    // so that you don't repeatedly do it.
    resetQuiz();       // Or whatever you need to do
    navigate('/classement');
  }
  
  return (
    <QuizContainer isMobile={isMobile}>

      <BrandContainer>
        <BrandImage src={Meo} alt="Meoria Logo" />
        <BrandSubtitle>
          Swipe pour accéder à ton classement !
        </BrandSubtitle>
      </BrandContainer>

      <BackgroundBall isMobile={isMobile}/>

      <QuizTypeIndicator>
        <ProgressContainer>
          <QuizNumber>Type de Formation</QuizNumber>
          <ProgressBar>
            <Progress
              $progress={progressPercent}
              $isQuiz1={true}
            />
          </ProgressBar>
        </ProgressContainer>
      </QuizTypeIndicator>

      <CardWrapper ref={cardWrapperRef} isMobile={isMobile}>
        <animated.div
          ref={cardRef}
          style={{
            touchAction: 'none',
            transform: to(
              [x, y, rotate, scale],
              (x, y, r, s) => `translate3d(${x}px, ${y}px, 0) rotate(${r}deg) scale(${s})`
            )
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
      <QuestionCard isMobile={isMobile} category={currentQuestion.category}>
        <DirectionArrows isVisible={showDirectionArrows} />
        <CategoryContainer>
          <CategoryIcon category={currentQuestion.category}>
            {React.createElement(categoryIcons[currentQuestion.category] || categoryIcons['Charge de travail'])}
          </CategoryIcon>
          <CategoryText category={currentQuestion.category}>
            {currentQuestion.category}
          </CategoryText>
        </CategoryContainer>
        <QuestionText>{currentQuestion.text}</QuestionText>
      </QuestionCard>
        </animated.div>
      </CardWrapper>

      <AnswerOptionsContainer isMobile={isMobile}>

{/* Non (left swipe) => croix_rouge.png */}
<AnswerButton
  clicked={clickedButton === 'left'}
  highlight={swipeDirection === 'left'}
  finalSwiped={finalSwipedButton === 'left'}
  onClick={() => handleAnswer(1, 'left', 'left')}
  aria-label={ANSWER_MAPPING[1]}
>
  <img
    src={croixRouge}
    alt="Croix rouge"
    style={{ width: '80%', height: '80%', objectFit: 'contain' }}
  />
</AnswerButton>

{/* Je ne sais pas (down swipe) => questionIcon */}
<AnswerButton
  clicked={clickedButton === 'down'}
  highlight={swipeDirection === 'down'}
  finalSwiped={finalSwipedButton === 'down'}
  onClick={() => handleAnswer(2, 'down', 'down')}
  aria-label={ANSWER_MAPPING[2]}
>
  <img
    src={questionIcon}
    alt="Question"
    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
  />
</AnswerButton>

{/* Oui (right swipe) => coeur_vert.png */}
<AnswerButton
  clicked={clickedButton === 'right'}
  highlight={swipeDirection === 'right'}
  finalSwiped={finalSwipedButton === 'right'}
  onClick={() => handleAnswer(3, 'right', 'right')}
  aria-label={ANSWER_MAPPING[3]}
>
  <img
    src={coeurVert}
    alt="Coeur vert"
    style={{ width: '85%', height: '85%', objectFit: 'contain' }}
  />
</AnswerButton>

{/* J'adore (up swipe) => StarIcon (pink heart) */}
<AnswerButton
  clicked={clickedButton === 'up'}
  highlight={swipeDirection === 'up'}
  finalSwiped={finalSwipedButton === 'up'}
  onClick={() => handleAnswer(4, 'up', 'up')}
  aria-label={ANSWER_MAPPING[4]}
>
  <img
    src={StarIcon}
    alt="Pink heart"
    style={{ width: '95%', height: '95%', objectFit: 'contain' }}
  />
</AnswerButton>

</AnswerOptionsContainer>

      {flyEmojiDirection && flyEmojiSrc && (
    <FlyEmoji direction={flyEmojiDirection}>
      <img
        src={flyEmojiSrc}
        alt=""
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </FlyEmoji>
  )}
    </QuizContainer>
  );
};

export default QuizTypeFormation;
