import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import styled from 'styled-components';
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LoadingOverlay,
  ChatBubbleContainer,
  ChatText,
  LogoImage,
} from './styles'; // Réutilisation des styles de Lycee

// Import des utilitaires de recherche du Comparateur
import { searchFormations, loadSearchData } from '../Comparateur/search-utils';
import apiCall from '../../utils/api';
import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import { useAuth } from '../../contexts/AuthContext';

// Import des assets
import compassImage from '../../assets/meo_write.png';
import loupe_grise from '../../assets/loupe_grise.png';
import loupe_blanche from '../../assets/loupe.png';

// Conteneur de recherche inspiré du Comparateur
const SearchBox = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin-top: -15%;
`;

// Conteneur de l'input de recherche
const SearchInputContainer = styled.div`
  position: relative;
  width: 350px;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #38B6FF;
  background: #FFF;
  overflow: hidden;
`;

// Input de recherche
const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  border: none;
  font-size: 15px;
  font-family: 'Gabarito', sans-serif;
  font-weight: 400;
  line-height: 108.52%;
  text-align: left;
  outline: none;
  background: transparent;
  
  &::placeholder {
    color: #C4C4C4;
    text-align: left;
    font-family: Gabarito;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 108.52%;
  }
`;

// Position de l'icône de recherche
const SearchIcon = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Cercle pour l'icône de recherche
const SearchIconCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  margin-right: -3px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#38B6FF' : '#E6E6E6'};
`;

// Conteneur des résultats de formation
const FormationResultsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  z-index: 200;
`;

// Item de résultat de formation
const FormationResultItem = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  z-index: 200;
  position: relative;
  
  &:hover {
    background-color: #f5f5f5;
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

// Nom de la formation dans les résultats
const FormationName = styled.h2`
  font-size: ${props => props.small ? '16px' : '19px'};
  font-weight: 400;
  margin: 0 0 4px 0;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
`;

// Métadonnées de la formation
const FormationMeta = styled.div`
  color: #888;
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

// Conteneur des formations sélectionnées
const SelectedFormationsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 16px;
`;

// Carte de formation sélectionnée
const SelectedFormationCard = styled.div`
  width: 100%;
  max-width: 350px;
  background: white;
  border-radius: 15px;
  padding: 12px 16px;
  margin-bottom: 10px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border: 2px solid #38B6FF;
`;

// Bouton de suppression pour la formation sélectionnée
const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.1);
  color: #666;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #333;
  }
`;

// Bouton "Ma formation n'est pas présente"
const NotFoundButton = styled.button`
  background: transparent;
  color: #38B6FF;
  border: 1px solid #38B6FF;
  border-radius: 25px;
  padding: 8px 16px;
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Gabarito', sans-serif;
  
  &:hover {
    background: rgba(56, 182, 255, 0.1);
  }
`;

const FormationOnboarding = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const { isMobile } = useDeviceType();
  const { checkAuth } = useAuth();
  const searchInputRef = useRef(null);
  const resultsContainerRef = useRef(null);

  const [selectedFormation, setSelectedFormation] = useState(null);
  const [isCustomFormation, setIsCustomFormation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = modifyProfile 
    ? "Dans quelle formation es-tu actuellement ?" 
    : "Dans quelle formation es-tu actuellement ?";
  
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [searchIndex, setSearchIndex] = useState({});
  const [formationsMeta, setFormationsMeta] = useState([]);

  // Typing animation effect
  useEffect(() => {
    let currentIndex = 0;
    const charIncrement = 3;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex += charIncrement;
      } else {
        setText(fullText);
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 5);
    return () => clearInterval(typingInterval);
  }, [fullText]);

  // Load search data for formations
  useEffect(() => {
    const initializeSearchData = async () => {
      try {
        const { searchIndex, formationsMeta } = await loadSearchData();
        setSearchIndex(searchIndex);
        setFormationsMeta(formationsMeta);
      } catch (error) {
        console.error('Erreur lors du chargement des données de recherche:', error);
      }
    };

    initializeSearchData();
  }, []);

  // Check if user already has formation data when modifying profile
  useEffect(() => {
    if (modifyProfile) {
      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      
      if (userData.formation_id) {
        // Try to find the matching formation in our data
        const existingFormation = Object.entries(formationsMeta).find(
          ([id, formation]) => id === userData.formation_id
        );
        
        if (existingFormation) {
          setSelectedFormation({
            id: existingFormation[0],
            ...existingFormation[1]
          });
        } else if (userData.formation_custom) {
          setIsCustomFormation(true);
          setSelectedFormation({
            id: 'custom',
            nomFormation: userData.formation_custom,
            nomEtablissement: userData.etablissement_custom || ''
          });
        }
      }
    }
  }, [modifyProfile, formationsMeta]);

  // Search formations using inverted index when search text changes
  useEffect(() => {
    if (searchText.trim() === '') {
      setSearchResults([]);
      return;
    }
    
    // If searchIndex and formationsMeta are loaded, search using inverted index
    if (Object.keys(searchIndex).length > 0 && Object.keys(formationsMeta).length > 0) {
      const results = searchFormations(searchText, searchIndex, formationsMeta, 10);
      setSearchResults(results);
    }
  }, [searchText, searchIndex, formationsMeta]);

  // Handle click outside to close search results
  useEffect(() => { 
    const handleClickOutside = (event) => {
      const clickedInsideSearch = searchInputRef.current && searchInputRef.current.contains(event.target);
      const clickedInsideResults = resultsContainerRef.current && resultsContainerRef.current.contains(event.target);
      
      if (!clickedInsideSearch && !clickedInsideResults) {
        setShowResults(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle formation selection
  const handleFormationSelect = (formation) => {
    setSelectedFormation(formation);
    setSearchText('');
    setShowResults(false);
    setIsCustomFormation(false);
  };

  // Handle custom formation selection
  const handleCustomFormation = () => {
    setSelectedFormation({
      id: 'custom',
      nomFormation: 'Ma formation personnalisée',
      nomEtablissement: ''
    });
    setIsCustomFormation(true);
    setSearchText('');
    setShowResults(false);
  };

  // Handle removing selected formation
  const handleRemoveFormation = () => {
    setSelectedFormation(null);
    setIsCustomFormation(false);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setShowResults(true);
  };

  // Handle focus on search input
  const handleSearchFocus = () => {
    setShowResults(true);
  };

  // Handle continue button click
  const handleSubmit = async () => {
    if ((!selectedFormation && !isCustomFormation) || isTyping) return;
    setIsLoading(true);
    
    try {
      if (modifyProfile) {
        // Send the formation data to the backend
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          formation_id: isCustomFormation ? '' : selectedFormation.id,
        });
        
        // Update user data in localStorage
        let userData = JSON.parse(localStorage.getItem('user') || '{}');
        userData.formation_id = isCustomFormation ? '' : selectedFormation.id;
        userData.formation_custom = isCustomFormation ? selectedFormation.nomFormation : '';
        userData.etablissement_custom = isCustomFormation ? selectedFormation.nomEtablissement : '';
        localStorage.setItem('user', JSON.stringify(userData));
        
        await checkAuth();
        navigate('/profile/modify/type-bac');
      } else {
        // Store formation info in localStorage for onboarding flow
        localStorage.setItem('formation_id', isCustomFormation ? '' : selectedFormation.id);
        // localStorage.setItem('formation_custom', isCustomFormation ? selectedFormation.nomFormation : '');
        // localStorage.setItem('etablissement_custom', isCustomFormation ? selectedFormation.nomEtablissement : '');
        
        navigate('/problemes-formation');
      }
    } catch (error) {
      console.error('Error saving formation data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle back button click
  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/user-type-selection');
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={80} endPercent={85} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <SearchBox>
          <div style={{ position: 'relative' }}>
            <SearchInputContainer ref={searchInputRef}>
              <SearchInput
                type="text"
                placeholder="Rechercher une formation"
                value={searchText}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
              />
              <SearchIcon>
                <SearchIconCircle active={searchText.length > 0}>
                  {searchText.length === 0 ? (
                    <img src={loupe_grise} alt="Search" style={{ width: '20px', height: '20px' }} />
                  ) : (
                    <img src={loupe_blanche} alt="Search" style={{ width: '20px', height: '20px' }} />
                  )}
                </SearchIconCircle>
              </SearchIcon>
            </SearchInputContainer>
            
            <FormationResultsContainer 
              ref={resultsContainerRef}
              visible={showResults && searchResults.length > 0}
            >
              {searchResults.map((formation, index) => (
                <FormationResultItem 
                  key={index} 
                  onClick={() => handleFormationSelect(formation)}
                >
                  <FormationName small>{formation.nomFormation}</FormationName>
                  <FormationMeta>
                    <span>{formation.nomEtablissement}</span>
                    {formation.commune && (
                      <span> • {formation.commune}</span>
                    )}
                  </FormationMeta>
                </FormationResultItem>
              ))}
              {searchResults.length === 0 && searchText.trim() !== '' && (
                <div style={{ padding: '16px', textAlign: 'center', color: '#888' }}>
                  Aucun résultat trouvé
                </div>
              )}
            </FormationResultsContainer>
          </div>
        </SearchBox>

        {/* Affichage de la formation sélectionnée */}
        {selectedFormation && (
          <SelectedFormationsContainer>
            <SelectedFormationCard>
              <FormationName>{selectedFormation.nomFormation}</FormationName>
              {selectedFormation.nomEtablissement && (
                <FormationMeta>
                  <span>{selectedFormation.nomEtablissement}</span>
                </FormationMeta>
              )}
              {isCustomFormation && (
                <div style={{ marginTop: '10px', fontSize: '14px', color: '#38B6FF' }}>
                  Formation personnalisée
                </div>
              )}
              <RemoveButton onClick={handleRemoveFormation}>✕</RemoveButton>
            </SelectedFormationCard>
          </SelectedFormationsContainer>
        )}

        {/* Bouton "Ma formation n'est pas présente" */}
        {!selectedFormation && (
          <NotFoundButton onClick={handleCustomFormation}>
            Ma formation n'est pas présente
          </NotFoundButton>
        )}

        {/* Mode édition pour une formation personnalisée */}
        {isCustomFormation && !selectedFormation.nomFormation.includes('personnalisée') && (
          <div style={{ width: '100%', maxWidth: '350px', margin: '0 auto' }}>
            <input
              type="text"
              value={selectedFormation.nomFormation}
              onChange={(e) => setSelectedFormation({
                ...selectedFormation,
                nomFormation: e.target.value
              })}
              placeholder="Nom de votre formation"
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: '1px solid #ddd'
              }}
            />
            <input
              type="text"
              value={selectedFormation.nomEtablissement}
              onChange={(e) => setSelectedFormation({
                ...selectedFormation,
                nomEtablissement: e.target.value
              })}
              placeholder="Nom de votre établissement (optionnel)"
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ddd'
              }}
            />
          </div>
        )}

        {/* Bouton de validation */}
        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || (!selectedFormation && !isCustomFormation)}
          style={{
            opacity: (isTyping || (!selectedFormation && !isCustomFormation)) ? 0.5 : 1,
            cursor: (isTyping || (!selectedFormation && !isCustomFormation)) ? 'not-allowed' : 'pointer',
            marginTop: '20px'
          }}
          onClick={(e) => { e.preventDefault(); handleSubmit(); }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default FormationOnboarding;