import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../useDeviceType';
import { IoIosArrowBack } from "react-icons/io";
import styled from 'styled-components';
import theme from '../../theme';
import {
  Container,
  ContentWrapper,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  ReturnButton,
  ButtonText
} from './styles';

import ProgressBar from '../ProgressBar';
import MeoWrite from '../../assets/meo_write.png';

// Base button component
const BaseButton = styled.button`
  border-radius: 10px;
  padding: ${props => props.padding || `${theme.spacing.medium} ${theme.spacing.xlarge}`};
  font-family: ${props => props.fontFamily || theme.fonts.main};
  font-size: ${props => props.fontSize || theme.fontSizes.medium};
  font-weight: ${props => props.fontWeight || theme.fontWeights.semiBold};
  cursor: pointer;
  transition: all 0.15s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

// Updated SubmitButton styled component
export const SubmitButton = styled(BaseButton)`
  width: 100%;
  position: fixed;
  bottom: calc(var(--vh, 1vh) * -10);
  left: 50%;
  /* When active, shift down 4px */
  transform: ${props =>
    props.active ? 'translateX(-50%) translateY(4px)' : 'translateX(-50%)'};
  max-width: 600px;
  border-radius: 15px;
  height: 53px;
  background-color: ${props => props.disabled ? '#efecec' : '#ff2768'};
  /* Remove shadow when disabled or pressed */
  box-shadow: ${props =>
    props.disabled || props.active ? 'none' : '0px 3px 0px #d42359'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;

// Positioning for the input and button container
const InputAndButtonContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.small};
  width: 90%;
  max-width: 400px;
  ${props => props.isMobile ? 'top: 25%;' : 'top: 40%;'}
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 62px;
  border: 2px solid #e6e6e6;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  font-family: 'Gabarito';
  color: ${theme.colors.noir};
  line-height: 28px;
  padding-left: 5%;
  padding-top: 16px;
  outline: none;
  resize: none;
  overflow: hidden;

  &::placeholder {
    font-size: 16px;
    font-family: 'Gabarito';
    color: #e6e6e6;
    text-align: left;
  }
`;

const Pseudo = () => {
  const [text, setText] = useState('');
  const fullText = "Quel est ton prénom ?";
  const [pseudo, setPseudo] = useState('');
  const textAreaRef = useRef(null);
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  // State to track if the button is pressed
  const [buttonActive, setButtonActive] = useState(false);

  // Check if pseudo is empty (trimmed)
  const isPseudoEmpty = pseudo.trim().length === 0;

  // Progressive message display
  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, []);

  // Auto focus on text area
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const handleBack = () => {
    navigate('/presentation-meo');
  };

  const handleCreateAccount = () => {
    // Only proceed if the pseudo is not empty
    if (!isPseudoEmpty) {
      localStorage.setItem('pseudo', pseudo);
      navigate('/user-type-selection');
    }
  };

  const handlePseudoChange = (e) => {
    const value = e.target.value.replace(/[\r\n]+/g, '');
    setPseudo(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isPseudoEmpty) {
        handleCreateAccount();
      }
    }
  };

  // Pointer event handlers for the SubmitButton
  const handlePressStart = () => {
    if (!isPseudoEmpty) {
      setButtonActive(true);
    }
  };

  const handlePressEnd = () => {
    if (!isPseudoEmpty) {
      setButtonActive(false);
      // Delay navigation briefly so the button's released state is visible
      setTimeout(() => {
        handleCreateAccount();
      }, 150);
    }
  };

  return (
    <Container>
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        <ProgressBar startPercent={0} endPercent={15} />
        <LogoImage src={MeoWrite} alt="Meo Write" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>
      </ContentWrapper>
      <InputAndButtonContainer isMobile={isMobile}>
        <StyledTextArea
          ref={textAreaRef}
          placeholder="Écris ton prénom..."
          value={pseudo}
          onChange={handlePseudoChange}
          onKeyDown={handleKeyDown}
          maxLength={25}
        />
        <SubmitButton
          onMouseDown={handlePressStart}
          onMouseUp={handlePressEnd}
          onMouseLeave={() => setButtonActive(false)}
          onTouchStart={handlePressStart}
          onTouchEnd={handlePressEnd}
          active={buttonActive}
          disabled={isPseudoEmpty}
        >
          <ButtonText>
            Continuer
          </ButtonText>
        </SubmitButton>
      </InputAndButtonContainer>
    </Container>
  );
};

export default Pseudo;