import styled from 'styled-components';

/**
 * Main container for the SideMenu.
 * Uses the theme background color and an increased width.
 */
export const SideMenuContainer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px; /* Default (desktop) */
  height: 100vh;
  padding: 25px;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.background};
  position: fixed;

  @media (max-width: 800px) {
    width: 100px; /* Collapsed for smaller screens */
    padding: 25px 5px;
  }
`;

/**
 * The "MEORIA" title.
 * Centered horizontally with extra spacing below.
 */
export const MeoriaTitle = styled.div`
  width: 88px;
  font-size: 25px;
  font-family: 'Londrina Solid', sans-serif;
  color: ${(props) => props.theme.colors.primary}; /* primary = #FF2768 */
  text-align: center;
  font-weight: 800;
  height: 24px;
  margin: 0 auto 40px auto; /* centers the title and adds extra gap before the menu items */

  @media (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
`;

/**
 * Vertical list of menu items.
 */
export const MenuList = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

/**
 * A single menu item.
 * Non‑active items show a hover background.
 * Active items display a blue effect.
 */
export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  height: 58px;
  width: 100%;
  padding: 0 16px;
  border: none;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 0.2s ease;
  position: relative;

  &:hover {
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.bleu : '#F0F0F0'};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.colors.bleu}; /* #DCF3FF */
      border: 2px solid ${theme.colors.bleu_fonce}; /* e.g., #38B6FF */
      box-sizing: border-box;
    `}

  @media (max-width: 800px) {
    justify-content: center;
    padding: 0;
  }
`;

/**
 * Icon styling.
 */
export const ButtonIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 8px;
`;

/**
 * Text styling for the menu items.
 * On screens narrower than 800px, the text is hidden.
 */
export const ButtonText = styled.span`
  width: 148px;
  font-size: 20px;
  font-family: 'Gabarito', sans-serif;
  color: #8d8d8d;
  text-align: left;
  display: inline-block;
  height: 35px;
  margin-top: 10px; /* Shifts text a bit lower */
  margin-left: 10px; /* Moves text slightly more to the left */

  @media (max-width: 800px) {
    display: none;
  }
`;

/**
 * A vertical line at the right edge of the SideMenu.
 */
export const RightDelimiter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #dcd9d9;
`;

/**
 * The blue rectangle to the right of the "Chat avec Meo" button.
 * Shown only when the path is "/chat" and active.
 */
export const RectangleDiv = styled.div`
  width: 34px;
  position: relative;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: #41b6fb;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* To move it to the right side within the MenuItem */
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  @media (max-width: 800px) {
    display: none;
  }
`;

/**
 * The plus/new icon inside the rectangle.
 */
export const NewChatIcon1 = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  object-fit: cover;
`;
