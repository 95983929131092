import styled from 'styled-components';
import theme from '../../theme';

export const GradeContainer = styled.div`
  position: relative;
  width: 100%;
  // margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${theme.spacing.large};
  height: calc(var(--vh, 1vh) * 80);
  top: -10%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: ${theme.spacing.large};

  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.medium};
  border-radius: 0.9375rem;
  border: 2px solid rgba(217, 215, 215, 0.47);

  background: #FFF;

  box-shadow: 0px 2px 2px 0px rgba(217, 215, 215, 0.75);
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.small};
`;


export const SliderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.small};
`;

export const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small};
`;

export const IconImage = styled.img`
  width: 15%;
  height: auto;
  object-fit: contain;
`;

export const SliderLabel = styled.label`
  color: ${theme.colors.noir};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.medium};
  margin: 0;
`;

export const GradeBadge = styled.div`
  border-radius: 0.625rem;
  background: #DCF3FF;
  color: #38B6FF;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  height: 50px;
  // border-radius: 8px;
  margin-left: 5px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.small};
  width: 100%;
`;

export const SliderLabelLeft = styled.span`
  color: #38B6FF;

  font-family: Gabarito;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 15px;
  margin-right: 4px;
`;

export const SliderLabelRight = styled(SliderLabelLeft)`
  color: #38B6FF;

  font-family: Gabarito;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 4px;
`;

export const CustomSlider = styled.input`
  width: 100%;
  height: 4px;
  background: #dcf3ff;
  border-radius: 8px;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  margin: 12px 0;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: ${theme.colors.bleu_fonce};
    border: 2px solid ${theme.colors.bleu_fonce};
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: -6px;

    &:hover {
      transform: scale(1.1);
      background: ${theme.colors.bleu_fonce};
    }
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${theme.colors.bleu_clair};
    border: 2px solid ${theme.colors.bleu_fonce};
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: -8px;

    &:hover {
      transform: scale(1.1);
      background: ${theme.colors.bleu_fonce};
    }
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      ${theme.colors.bleu_clair} var(--progress),
      ${theme.colors.lightGray} var(--progress)
    );
    border-radius: 8px;
    height: 4px;
  }

  &::-moz-range-track {
    background: linear-gradient(
      to right,
      ${theme.colors.bleu_clair} var(--progress),
      ${theme.colors.lightGray} var(--progress)
    );
    border-radius: 8px;
    height: 4px;
  }
`;
