import apiCall from '../../utils/api';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

import {
  Container,
  ContentWrapper,
  SubmitButton
} from '../Onboarding/styles';

import {
  ReturnButton
} from '../sharedStyles/styles';
import {
  OptionsContainer,
  ButtonsWrapper,
  LogoImage,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer,
  ChatBubbleContainer,
  ChatText
} from './styles';
import compassImage from '../../assets/meo_write.png';
import universite_professionnalisante_icon from '../../assets/universite_professionnalisante_icon.png';
import classe_professionnalisante_icon from '../../assets/classe_professionnalisante_icon.png';
import grande_ecole_post_bac_icon from '../../assets/grande_ecole_post_bac_icon.png';
import universite_theorique_icon from '../../assets/universite_theorique_icon.png';
import classe_preparatoire_icon from '../../assets/classe_preparatoire_icon.png';
import dont_know_icon from '../../assets/dont_know_icon.png';
import useDeviceType from '../useDeviceType';

const UserTypeFormationSelectionProfile = () => {
  // Change selectedType to selectedTypes array
  const [selectedTypes, setSelectedTypes] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Il y a-t-il un type de formation qui t'intéresse ?";
  const { isMobile } = useDeviceType();

  const typeOptions = [
    {
      value: 'classe_preparatoire',
      label: 'Classe préparatoire',
      icon: classe_preparatoire_icon
    },
    {
      value: 'universite_theorique',
      label: 'Université théorique',
      icon: universite_theorique_icon
    },
    {
      value: 'grande_ecole_post_bac',
      label: 'Ecole post-bac',
      icon: grande_ecole_post_bac_icon
    },
    {
      value: 'classe_professionnalisante',
      label: 'Classe professionnalisante',
      icon: classe_professionnalisante_icon
    },
    {
      value: 'universite_professionnalisante',
      label: "Université professionnalisante",
      icon: universite_professionnalisante_icon
    },
    {
      value: 'dont_know',
      label: "Refaire le swipe !",
      icon: dont_know_icon
    },
  ];

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    try {
      // Get user data from localStorage
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        // Initialize selected types from user's type_formation if it exists
        if (user.type_formation && Array.isArray(user.type_formation)) {
          setSelectedTypes(user.type_formation);
        }
      }
    } catch (error) {
      console.error('Error loading user data from localStorage:', error);
    }

    return () => clearInterval(typingInterval);
  }, []);

  // Add handleSelection function for multiple selections
  const handleSelection = (value) => {
    setSelectedTypes(prev => {
      if (prev.includes(value)) {
        return prev.filter(type => type !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleSubmit = async () => {
    if (selectedTypes.length === 0 || isTyping) return;
    setIsLoading(true);
    try {
      // Check if "je ne sais pas" option is selected
      if (selectedTypes.includes('dont_know')) {
        navigate('/quiz-type-formation', { state: { from: '/profile/modify/type-formation' } });
        return; // Stop further execution
      }
  
      // Update backend with array of selections
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
        type_formation: selectedTypes
      });
      
      // Update localStorage
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          user.type_formation = selectedTypes;
          localStorage.setItem('user', JSON.stringify(user));
        }
      } catch (error) {
        console.error('Error updating localStorage:', error);
      }
      
      navigate(-1);
    } catch (error) {
      console.error('Error saving type formation:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer>
          <OptionsContainer>
            <ButtonsWrapper style={{
              opacity: isTyping ? 0 : 1,
              transition: 'opacity 0.3s ease',
              pointerEvents: isTyping ? 'none' : 'auto'
            }}>
              {typeOptions.map((option) => (
                <OptionButton
                  key={option.value}
                  $isSelected={selectedTypes.includes(option.value)}
                  onClick={() => handleSelection(option.value)}
                  type="button"
                  aria-pressed={selectedTypes.includes(option.value)}
                >
                  <OptionIcon
                    src={option.icon}
                    alt=""
                    $isSelected={selectedTypes.includes(option.value)}
                  />
                  {option.label}
                </OptionButton>
              ))}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          onClick={handleSubmit}
          disabled={isLoading || isTyping || selectedTypes.length === 0}
          type="button"
          style={{
            opacity: (isTyping || selectedTypes.length === 0) ? 0.5 : 1,
            cursor: (isTyping || selectedTypes.length === 0) ? 'not-allowed' : 'pointer'
          }}
        >
          {isLoading ? 'Chargement...' : 'Modifier'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default UserTypeFormationSelectionProfile;