import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// Import assets
import MeoriaManagerLogo from '../../../assets/meoria_manager_bleu.png';
import MeoriaManagerScreen from '../../../assets/meoria_manager_screen.png';

// Global styles to hide scrollbar
const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

// Styled Components
const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #FFF;
  position: relative;
`;

const LogoContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
`;

const HeaderLogo = styled.img`
  height: 55px;
  width: auto;
`;

const MainContent = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF;
  padding-top: 2.5%;
  padding-right: 1.25%;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 95%;
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  margin: 0 auto;
`;

const ScreenContainer = styled.div`
  width: 72.5%;
  height: 90vh;
  flex-shrink: 0;
  border-radius: 50px;
  background: linear-gradient(90deg, #78CEFF 0%, #D3EFFF 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ScreenImage = styled.img`
  width: 85%;
  height: 85%;
  object-fit: contain;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 358px;
  
  @media (max-width: 400px) {
    max-width: 100%;
    width: 100%;
  }
`;

const InputField = styled.input`
  width: 358px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  background: #FFF;
  padding: 0 15px;
  margin-bottom: 20px;
  color: #8C8C8C;
  font-family: 'Gabarito', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  
  &::placeholder {
    color: #B3B3B3;
    font-weight: 200;
    font-size: 18px;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 2px 10px rgba(56, 182, 255, 0.15);
  }
  
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 40px; /* Increased margin to push login button lower */
`;

const ForgotPasswordText = styled.a`
  color: #8C8C8C;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const LoginButton = styled.button`
  width: 358px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #000000;
  border: none;
  color: #FFF;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.15s ease;
  
  &:hover {
    background: #333333;
  }
  
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 15px 30px;
  border-radius: 8px;
  font-family: 'Gabarito', sans-serif;
  font-size: 16px;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: none;
`;

const ManagerLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Prevent scrolling when component mounts
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Handle login button click
  const handleLogin = (e) => {
    e.preventDefault();
    showToastNotification();
  };

  // Handle forgot password click
  const handleForgotPassword = (e) => {
    e.preventDefault();
    showToastNotification();
  };

  // Show toast notification and auto-hide after 3 seconds
  const showToastNotification = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  // Navigate back to manager page
  const goToManager = () => {
    navigate('/manager');
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <LogoContainer onClick={goToManager}>
          <HeaderLogo 
            src={MeoriaManagerLogo} 
            alt="Meoria Manager" 
          />
        </LogoContainer>
        
        <MainContent>
          <ContentWrapper>
            <LoginContainer>
              <form onSubmit={handleLogin}>
                <InputField
                  type="email"
                  placeholder="Entrez votre adresse mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <ForgotPasswordContainer>
                  <ForgotPasswordText onClick={handleForgotPassword}>
                    Mot de passe oublié ?
                  </ForgotPasswordText>
                </ForgotPasswordContainer>
                <LoginButton type="submit">
                  Se connecter
                </LoginButton>
              </form>
            </LoginContainer>
            
            <ScreenContainer>
              <ScreenImage src={MeoriaManagerScreen} alt="Meoria Manager Interface" />
            </ScreenContainer>
          </ContentWrapper>
        </MainContent>
        
        <Toast visible={showToast}>
          Demandez une démo pour créer un compte !
        </Toast>
      </Container>
    </>
  );
};

export default ManagerLogin; 