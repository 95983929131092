import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useSpring, animated, config, to } from '@react-spring/web';
import { useNavigate  } from 'react-router-dom';
import Meo from '../../../assets/meo_quiz.png';
import {
  QuizContainer,
  BackgroundBall,
  CardWrapper,
  FlyEmoji,
  QuestionCard,
  QuestionText,
  AnswerOptionsContainer,
  AnswerButton,
  QuizMessage,
  QuizTypeIndicator,
  ProgressContainer,
  QuizNumber,
  ProgressBar,
  Progress,
  BrandContainer,
  BrandImage,
  BrandSubtitle,
  CategoryContainer,
  CategoryIcon,
  categoryIcons,
  CategoryText,
} from './styles';

import apiCall from '../../../utils/api';
import useDeviceType from '../../useDeviceType';
import { useQuiz } from '../../../contexts/QuizContext';
import questionIcon from '../../../assets/dont_know_icon.png';
import StarIcon from '../../../assets/etoile_icon.png';
import coeurVert from '../../../assets/coeur_vert.png';
import croixRouge from '../../../assets/croix_rouge.png';

const ANSWER_MAPPING = {
  1: 'Non',
  2: 'Je ne sais pas',
  3: 'Oui',
  4: "J'adore"
};

const iconSrcMapping = {
  up: StarIcon,       // "J'adore"
  right: coeurVert,   // "Oui"
  down: questionIcon, // "Je ne sais pas"
  left: croixRouge    // "Non"
};

/**
 * QuizTypeFormation
 * This quiz layout is responsive:
 *  – On mobile, it behaves as before (fixed card, swipes, etc.).
 *  – On desktop, it is shifted to the right to make space for the fixed SideMenu (320px wide).
 */
const QuizTypeFormation = ({ onQuizComplete }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const {
    currentQuestion,
    setCurrentQuestion,
    quizProgress,
    setQuizProgress,
    setIsQuizInitialized,
    setQuizId,
    quizCompleted,
    setQuizCompleted,
  } = useQuiz();

  // State for button and swipe animations
  const [clickedButton, setClickedButton] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [finalSwipedButton, setFinalSwipedButton] = useState(null);

  const [flyEmojiDirection, setFlyEmojiDirection] = useState(null);
  const [flyEmojiSrc, setFlyEmojiSrc] = useState(null);

  // Refs
  const clickTimeoutRef = useRef(null);
  const hasSwipedRef = useRef(false);
  const cardWrapperRef = useRef(null);
  const cardRef = useRef(null);
  const touchStartRef = useRef({ x: 0, y: 0 });

  // React Spring
  const [{ x, y, rotate }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    rotate: 0,
    config: { tension: 300, friction: 20 },
  }));

  // Calculate quiz progress as percentage
  const progressPercent = quizProgress.quiz1_total > 0
    ? (quizProgress.quiz1_progress / quizProgress.quiz1_total) * 100
    : 0;

  const setVhVariable = () => {
    // Définir la variable --vh une fois pour toutes
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  
  // Modifiez le useEffect existant ou ajoutez-en un nouveau
  useEffect(() => {
    // Définir la hauteur correcte initialement
    setVhVariable();
    
    // Ajouter un écouteur pour redéfinir si la taille de l'écran change
    window.addEventListener('resize', setVhVariable);
    
    return () => {
      window.removeEventListener('resize', setVhVariable);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);
  //-------------------------------------------
  // Fetch initial question
  //-------------------------------------------
  const fetchInitialQuestion = async () => {
    try {
      // 1) start quiz if needed
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/start/`,
        'POST'
      );
      // 2) fetch question
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/question-type-formation/`,
        'GET'
      );
      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category,
        });
        if (response.progress) {
          setQuizProgress(response.progress);
        }
      } else {
        console.warn('No question returned:', response.message);
      }
    } catch (error) {
      console.error("Error fetching the initial question:", error);
    }
  };

  //-------------------------------------------
  // Submitting an answer and next question
  //-------------------------------------------
  const handleAnswer = async (answer, direction, buttonId) => {
    if (!currentQuestion) return;

    // For the center-scale "fly" emoji animation
    setFlyEmojiDirection(direction);
    setFlyEmojiSrc(iconSrcMapping[direction]);

    // Show the highlight on the button
    setClickedButton(buttonId);
    setFinalSwipedButton(direction);

    // Animate card off-screen
    animateCard(direction);

    // Clear old timeouts and set a fresh short highlight
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    clickTimeoutRef.current = setTimeout(() => {
      setClickedButton(null);
      clickTimeoutRef.current = null;
    }, 1000);

    try {
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/submit-answer-next/`,
        'POST',
        {
          question_id: currentQuestion.id,
          question_type: currentQuestion.type,
          answer: answer
        }
      );
      setQuizProgress(response.progress);

      // Hide direction arrows after first answer
      if (!hasSwipedRef.current) {
        hasSwipedRef.current = true;
      }

      // If quiz is complete
      if (response.progress.quiz1_progress >= response.progress.quiz1_total) {
        setQuizCompleted(true);
        onQuizComplete && onQuizComplete();
        return;
      }

      // Otherwise, set next question
      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category,
        });
      }

      // Reset the card so it's back in the center
      api.start({ x: 0, y: 0, rotate: 0, immediate: true });

      // Clear highlight + emoji after short delay
      setTimeout(() => {
        setClickedButton(null);
        setFinalSwipedButton(null);
        setFlyEmojiDirection(null);
        setFlyEmojiSrc(null);
      }, 600);

    } catch (error) {
      console.error('Error submitting answer:', error);
      api.start({ x: 0, y: 0, rotate: 0, config: config.wobbly });
      setClickedButton(null);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
        clickTimeoutRef.current = null;
      }
    }
  };

  //-------------------------------------------
  // Animate card via react-spring
  //-------------------------------------------
  const animateCard = (direction) => {
    const distance = window.innerWidth + 200;
    const verticalDistance = window.innerHeight + 200;

    switch (direction) {
      case 'up':
        api.start({ y: -verticalDistance, rotate: 0 });
        break;
      case 'down':
        api.start({ y: verticalDistance, rotate: 0 });
        break;
      case 'left':
        api.start({ x: -distance, rotate: -45 });
        break;
      case 'right':
        api.start({ x: distance, rotate: 45 });
        break;
      default:
        api.start({ x: 0, y: 0, rotate: 0 });
    }
  };

  //-------------------------------------------
  // Touch/swipe handlers (for mobile)
  //-------------------------------------------
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    const wrapperRect = cardWrapperRef.current.getBoundingClientRect();
    const cardRect = cardRef.current.getBoundingClientRect();

    const offsetX = touch.clientX - cardRect.left;
    const offsetY = touch.clientY - cardRect.top;

    touchStartRef.current = {
      offsetX,
      offsetY,
      wrapperLeft: wrapperRect.left,
      wrapperTop: wrapperRect.top
    };
  };

  const handleTouchMove = (e) => {
    if (!cardRef.current) return;
    const NO_HIGHLIGHT_RADIUS = 80;

    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const touch = e.touches[0];
    const currentX = touch.clientX;
    const currentY = touch.clientY;
    const deltaX = currentX - centerX;
    const deltaY = currentY - centerY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    // If inside small circle near center => no direction
    if (distance < NO_HIGHLIGHT_RADIUS) {
      setSwipeDirection(null);
      return;
    }

    // angle relative to +X axis, then shift so up=0
    let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
    angle += 90;

    // Normalize angle to (-180, 180)
    if (angle > 180) angle -= 360;
    if (angle < -180) angle += 360;

    let direction = null;
    if (angle >= -45 && angle <= 45) {
      direction = 'up';
    } else if (angle > 45 && angle <= 135) {
      direction = 'right';
    } else if (angle < -45 && angle >= -135) {
      direction = 'left';
    } else {
      direction = 'down';
    }

    setSwipeDirection(direction);

    // Move/rotate the card
    const { offsetX, offsetY, wrapperLeft, wrapperTop } = touchStartRef.current;
    const newX = (currentX - wrapperLeft) - offsetX;
    const newY = (currentY - wrapperTop) - offsetY;
    const rotation = (currentX - centerX) / 15;

    api.start({
      x: newX,
      y: newY,
      rotate: rotation,
      immediate: true,
    });
  };

  const handleTouchEnd = (e) => {
    const SWIPE_THRESHOLD = 80;
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    const touch = e.changedTouches[0];
    const finalDeltaX = touch.clientX - centerX;
    const finalDeltaY = touch.clientY - centerY;
    const distance = Math.sqrt(finalDeltaX * finalDeltaX + finalDeltaY * finalDeltaY);

    if (distance < SWIPE_THRESHOLD) {
      // Not enough swipe => reset
      api.start({ x: 0, y: 0, rotate: 0 });
      setSwipeDirection(null);
      return;
    }

    switch (swipeDirection) {
      case 'up':
        api.start({ x: 0, y: -window.innerHeight - 200, rotate: 0 });
        handleAnswer(4, 'up', 'up');
        setFinalSwipedButton('up');
        break;
      case 'right':
        api.start({ x: window.innerWidth + 200, y: 0, rotate: 45 });
        handleAnswer(3, 'right', 'right');
        setFinalSwipedButton('right');
        break;
      case 'down':
        api.start({ x: 0, y: window.innerHeight + 200, rotate: 0 });
        handleAnswer(2, 'down', 'down');
        setFinalSwipedButton('down');
        break;
      case 'left':
        api.start({ x: -window.innerWidth - 200, y: 0, rotate: -45 });
        handleAnswer(1, 'left', 'left');
        setFinalSwipedButton('left');
        break;
      default:
        api.start({ x: 0, y: 0, rotate: 0 });
    }

    setTimeout(() => {
      setFinalSwipedButton(null);
    }, 2000);
    setSwipeDirection(null);
  };

  //-------------------------------------------
  // Reset or complete quiz
  //-------------------------------------------
  const resetQuiz = useCallback(async () => {
    try {
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/reset-quiz/`,
        'POST'
      );
      setQuizId(response.quiz_id);
      setQuizProgress(response.progress);
      setQuizCompleted(false);
      setIsQuizInitialized(false);

      if (response.question_id) {
        setCurrentQuestion({
          id: response.question_id,
          text: response.question_text,
          type: response.question_type,
          category: response.question_category,
        });
      }
      setIsQuizInitialized(true);
    } catch (error) {
      console.error('Error resetting quiz:', error);
    }
  }, [
    setQuizId,
    setQuizProgress,
    setQuizCompleted,
    setIsQuizInitialized,
    setCurrentQuestion
  ]);

  //-------------------------------------------
  // Lifecycle
  //-------------------------------------------
  useEffect(() => {
    if (!quizCompleted) {
      fetchInitialQuestion();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  if (!isAuthenticated) {
    return <QuizMessage>Connecte-toi pour accéder au quiz !</QuizMessage>;
  }

  if (!currentQuestion && !quizCompleted) {
    return <QuizMessage>Chargement de la question...</QuizMessage>;
  }

  // If the backend indicates the quiz is truly complete
  if (quizProgress.quiz_completed) {
    resetQuiz();
    navigate('/classement');
  }

  return (
    <QuizContainer isMobile={isMobile}>
      <BrandContainer>
        <BrandImage src={Meo} alt="Meoria Logo" />
        <BrandSubtitle>Swipe pour accéder à ton classement !</BrandSubtitle>
      </BrandContainer>

      <BackgroundBall isMobile={isMobile} />

      <QuizTypeIndicator>
        <ProgressContainer>
          <QuizNumber>Type de Formation</QuizNumber>
          <ProgressBar>
            <Progress
              $progress={progressPercent}
              $isQuiz1={true}
            />
          </ProgressBar>
        </ProgressContainer>
      </QuizTypeIndicator>

      <CardWrapper ref={cardWrapperRef} isMobile={isMobile}>
        <animated.div
          ref={cardRef}
          style={{
            touchAction: 'none',
            transformOrigin: '50% 50%', // This makes the rotation pivot in the center of the card
            transform: to(
              [x, y, rotate],
              (x, y, r) => `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`
            ),
            height: '100%',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <QuestionCard category={currentQuestion.category} isMobile={isMobile}>
            <CategoryContainer>
              <CategoryIcon category={currentQuestion.category}>
                {React.createElement(
                  categoryIcons[currentQuestion.category] ||
                  categoryIcons['Charge de travail']
                )}
              </CategoryIcon>
              <CategoryText category={currentQuestion.category}>
                {currentQuestion.category}
              </CategoryText>
            </CategoryContainer>
            <QuestionText>{currentQuestion.text}</QuestionText>
          </QuestionCard>
        </animated.div>
      </CardWrapper>

      {/* Buttons (non-swipe clicks) */}
      <AnswerOptionsContainer isMobile={isMobile}>
        {/* NON */}
        <AnswerButton
          clicked={clickedButton === 'left'}
          highlight={swipeDirection === 'left'}
          finalSwiped={finalSwipedButton === 'left'}
          onClick={() => handleAnswer(1, 'left', 'left')}
          aria-label={ANSWER_MAPPING[1]}
        >
          <img
            src={croixRouge}
            alt="Croix rouge"
            style={{ width: '80%', height: '80%', objectFit: 'contain' }}
          />
        </AnswerButton>

        {/* JE NE SAIS PAS */}
        <AnswerButton
          clicked={clickedButton === 'down'}
          highlight={swipeDirection === 'down'}
          finalSwiped={finalSwipedButton === 'down'}
          onClick={() => handleAnswer(2, 'down', 'down')}
          aria-label={ANSWER_MAPPING[2]}
        >
          <img
            src={questionIcon}
            alt="Question"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </AnswerButton>

        {/* OUI */}
        <AnswerButton
          clicked={clickedButton === 'right'}
          highlight={swipeDirection === 'right'}
          finalSwiped={finalSwipedButton === 'right'}
          onClick={() => handleAnswer(3, 'right', 'right')}
          aria-label={ANSWER_MAPPING[3]}
        >
          <img
            src={coeurVert}
            alt="Coeur vert"
            style={{ width: '85%', height: '85%', objectFit: 'contain' }}
          />
        </AnswerButton>

        {/* J'ADORE */}
        <AnswerButton
          clicked={clickedButton === 'up'}
          highlight={swipeDirection === 'up'}
          finalSwiped={finalSwipedButton === 'up'}
          onClick={() => handleAnswer(4, 'up', 'up')}
          aria-label={ANSWER_MAPPING[4]}
        >
          <img
            src={StarIcon}
            alt="Star"
            style={{ width: '95%', height: '95%', objectFit: 'contain' }}
          />
        </AnswerButton>
      </AnswerOptionsContainer>

      {/* Fly-emoji that scales near screen center */}
      {flyEmojiDirection && flyEmojiSrc && (
        <FlyEmoji direction={flyEmojiDirection}>
          <img
            src={flyEmojiSrc}
            alt=""
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </FlyEmoji>
      )}
    </QuizContainer>
  );
};

export default QuizTypeFormation;
