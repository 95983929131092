import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import apiCall from '../../utils/api';
import meoImage from '../../assets/meo_write.png';
import useDeviceType from '../useDeviceType';

// Import styled components
import {
  Container,
  ContentWrapper,
  MeoChatBubbleContainer,
  MeoChatText,
  MessageContainer,
  BoldText,
  LogoContainer,
  CenteredLogoImage,
  ProgressBarWrapper,
  ProgressBarContainer,
  ProgressBarFill,
  SubBar,
  ProgressBarAbsoluteContainer,
} from './styles';

const WaitLettre = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  
  // State for progress bar
  const [progressWidth, setProgressWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  
  // State for formation data
  const [formation, setFormation] = useState({
    id: 0,
    name: ''
  });
  
  // Get the formation data from localStorage
  useEffect(() => {
    const storedFormation = localStorage.getItem('selectedFormation');
    if (storedFormation) {
      try {
        const formationData = JSON.parse(storedFormation);
        setFormation(formationData);
        console.log('Loaded formation data:', formationData);
      } catch (error) {
        console.error('Error parsing formation data:', error);
        setErrorMessage('Erreur lors du chargement de la formation.');
      }
    } else {
      setErrorMessage('Aucune formation sélectionnée.');
    }
  }, []);
  
  // Move startProgressAnimation before generateLetter and wrap it in useCallback
  const startProgressAnimation = useCallback(() => {
    // Configure animation phases
    const fastPhaseLimit = 60; // Progress rapidly until 60%
    const mediumPhaseLimit = 85; // Then medium pace until 85%
    const slowPhaseLimit = 95; // Then slowly until 95% (waiting for API)
    
    // Calculate increments and intervals for a natural feeling
    const fastIncrement = 0.8;  // Fast phase increment
    const mediumIncrement = 0.4; // Medium phase increment
    const slowIncrement = 0.1;  // Slow phase increment
    
    // Timing intervals
    const fastInterval = 30;   // Update every 30ms in fast phase
    const mediumInterval = 100; // Update every 100ms in medium phase
    const slowInterval = 300;  // Update every 300ms in slow phase
    
    // Initial phase
    let currentInterval = fastInterval;
    let currentIncrement = fastIncrement;
    
    // Progress interval
    const progressInterval = setInterval(() => {
      setProgressWidth(currentWidth => {
        // Adjust speed based on current progress
        if (currentWidth < fastPhaseLimit && (currentIncrement !== fastIncrement || currentInterval !== fastInterval)) {
          currentIncrement = fastIncrement;
          clearInterval(progressInterval);
          startProgressAnimation();
          return currentWidth;
        } 
        else if (currentWidth >= fastPhaseLimit && currentWidth < mediumPhaseLimit && 
                (currentIncrement !== mediumIncrement || currentInterval !== mediumInterval)) {
          currentIncrement = mediumIncrement;
          clearInterval(progressInterval);
          startProgressAnimation();
          return currentWidth;
        }
        else if (currentWidth >= mediumPhaseLimit && currentWidth < slowPhaseLimit && 
                (currentIncrement !== slowIncrement || currentInterval !== slowInterval)) {
          currentIncrement = slowIncrement;
          clearInterval(progressInterval);
          startProgressAnimation();
          return currentWidth;
        }
        
        // Calculate new width
        let newWidth = currentWidth + currentIncrement;
        
        // Ensure we don't exceed phase limits too quickly
        if (currentWidth < fastPhaseLimit) {
          newWidth = Math.min(newWidth, fastPhaseLimit);
        } else if (currentWidth < mediumPhaseLimit) {
          newWidth = Math.min(newWidth, mediumPhaseLimit);
        } else if (currentWidth < slowPhaseLimit) {
          newWidth = Math.min(newWidth, slowPhaseLimit);
        }
        
        return newWidth;
      });
    }, currentInterval);
    
    // Clean up on component unmount
    return () => clearInterval(progressInterval);
  }, [setProgressWidth]); // Add setProgressWidth as dependency
  
  // Wrapped in useCallback and moved before useEffect that uses it
  const generateLetter = useCallback(async () => {
    // Start progress animation
    startProgressAnimation();
    
    try {
      // Get user object from localStorage
      const userString = localStorage.getItem('user');
      
      if (!userString) {
        throw new Error('Utilisateur non connecté. Veuillez vous connecter pour continuer.');
      }
      
      const user = JSON.parse(userString);
      
      if (!user || !user.id) {
        throw new Error('Informations utilisateur invalides. Veuillez vous reconnecter.');
      }
      
      // Get the stored questions and answers
      const lettresDataString = localStorage.getItem('lettresData');
      if (!lettresDataString) {
        throw new Error('Aucune donnée trouvée. Veuillez retourner à l\'étape précédente.');
      }
      
      const lettresData = JSON.parse(lettresDataString);
      console.log('Formation ID:', formation.id);
      console.log('Lettres data keys:', Object.keys(lettresData));
      
      // Convert formation.id to string to ensure it matches the keys in lettresData
      const formationIdStr = String(formation.id);
      
      if (!lettresData[formationIdStr]) {
        throw new Error(`Formation ${formationIdStr} non trouvée dans les données (${Object.keys(lettresData).join(', ')})`);
      }
      
      if (!lettresData[formationIdStr].questions) {
        throw new Error('Structure de données incorrecte pour cette formation.');
      }
      
      // Format the questions data for the API
      const questionsData = lettresData[formationIdStr].questions.map(q => ({
        question: q.question,
        icon: q.icon,
        answer: q.answer
      }));
      
      // Generate the letter (this endpoint also saves the answers)
      const generateLetterUrl = `${process.env.REACT_APP_BACKEND_URL}/lettres/generate-letter/`;
      const response = await apiCall(generateLetterUrl, 'POST', {
        user_id: user.id,
        formation_id: formation.id,
        questions_data: questionsData
      });
      
      // Instead of using generatedLetter localStorage, update the lettresData directly
      lettresData[formationIdStr] = {
        ...lettresData[formationIdStr],
        letterContent: response.letter,
        formation_name: formation.name,
        establishment: response.establishment || "Université",
        completed: true,
        createdAt: new Date().toISOString()
      };
      
      // Save updated lettresData
      localStorage.setItem('lettresData', JSON.stringify(lettresData));
      
      // Complete the progress
      setProgressWidth(100);
      
      // FIXED: Add a flag to prevent multiple navigations
      let hasNavigated = false;
      
      setTimeout(() => {
        // FIXED: Only navigate if we haven't already
        if (!hasNavigated) {
          hasNavigated = true;
          console.log('Navigation to /lettres-final');
          
          // FIXED: Use replace instead of push to avoid history issues
          navigate('/lettres-final', { replace: true });
        }
      }, 1000); // Give a moment for the progress bar to reach 100%
      
    } catch (error) {
      console.error('Error generating letter:', error);
      setErrorMessage(error.message || 'Une erreur est survenue lors de la génération de la lettre. Veuillez réessayer.');
    }
  }, [formation.id, formation.name, navigate, startProgressAnimation, setProgressWidth, setErrorMessage]);
  
  useEffect(() => {
    // Only start generating letter when formation is loaded
    if (formation.id) {
      console.log('Starting letter generation for formation ID:', formation.id);
      generateLetter();
    }
  }, [formation.id, generateLetter]);
  
  // FIXED: Add protection against unmounting during navigation
  useEffect(() => {
    return () => {
      console.log('WaitLettre component unmounting');
    };
  }, []);
  
  return (
    <Container isMobile={isMobile}>
      <ContentWrapper>
        <MeoChatBubbleContainer>
          <MeoChatText>
            <MessageContainer>
              {errorMessage ? (
                <span style={{ color: 'red' }}>{errorMessage}</span>
              ) : (
                <>
                  Je rédige ta lettre de motivation pour <BoldText>{formation.name}</BoldText>. Cela peut prendre quelques instants...
                </>
              )}
            </MessageContainer>
          </MeoChatText>
        </MeoChatBubbleContainer>
        
        <LogoContainer>
          <CenteredLogoImage src={meoImage} alt="Meo Lettres" />
        </LogoContainer>
        
        {/* Progress bar positioned absolutely */}
        <ProgressBarAbsoluteContainer>
          <ProgressBarContainer show={true}>
            <ProgressBarWrapper>
              <ProgressBarFill width={progressWidth}>
                <SubBar />
              </ProgressBarFill>
            </ProgressBarWrapper>
          </ProgressBarContainer>
        </ProgressBarAbsoluteContainer>
        
        {/* Invisible placeholder for spacing */}
        <div style={{ height: '33px', visibility: 'hidden' }} />
      </ContentWrapper>
    </Container>
  );
};

export default WaitLettre;