import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// ========== Mobile Components ==========
import ChatDiscussion from './components/ChatDiscussion';
import BottomMenu from './components/BottomMenu';
import CitySelection from './components/Onboarding/CitySelection';
import LoginPage from './components/LoginPage';
import FirstLoginAccount from './components/FirstLoginAccount';
import CardTypeFormation from './components/CardTypeFormation';
import ModifySwipeAnswers from './components/ModifySwipeAnswers';
import Favoris from './components/Favoris';
import FicheFormation from './components/FicheFormation';

import Settings from './components/Settings';
import MobileHistoryTab from './components/MobileHistoryTab';
import PresentationFeatures from './components/PresentationFeatures';
import FirstPage from './components/FirstPage';
import Pseudo from './components/Onboarding/Pseudo';
import TypeBac from './components/Onboarding/TypeBac';
import BacGeneral from './components/Onboarding/BacGeneral';
import BacTechnologique from './components/Onboarding/BacTechnologique';
import BacProfessionnel from './components/Onboarding/BacProfessionnel';
import GeneralMoyenne from './components/Onboarding/GeneralMoyenne';
import DomainSelection from './components/Onboarding/DomainSelection';
import TypeFormation from './components/TypeFormation';
import MeoFirstMessage from './components/MeoFirstMessage';
import SourceConnaissance from './components/SourceConnaissance';
import MessagePret from './components/MessagePret';
import UserTypeSelection from './components/Onboarding/UserTypeSelection';
import Lycee from './components/Onboarding/Lycee';
import ClassementClasse from './components/Onboarding/ClassementClasse';
import FormationOnboarding from './components/Onboarding/FormationOnboarding';
import ProblèmesFormationOnboarding from './components/Onboarding/ProblèmesFormationOnboarding';
import PhoneSelection from './components/Onboarding/PhoneSelection';
import Comparateur from './components/Comparateur';
import StarsPopup from './components/StarsPopup';

// ========== Lettres ==========
import LettresSelectFormation from './components/Lettres/SelectFormation';
import LettresValidateFormation from './components/Lettres/ValidateFormation';
import LettresQuestions from './components/Lettres/Questions';
import LettresWait from './components/Lettres/WaitLettre';
import LettresRelecture from './components/Lettres/Relecture';
import LettresFinal from './components/Lettres/Final';

// ========== Contexts and Providers ==========
import { useAuth } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import { QuizProvider } from './contexts/QuizContext';
import AppLoadingHandler from './contexts/AppLoadingHandler';

// ========== Profile ==========
import Profile from './components/Profile';
import UserTypeFormationSelectionProfile from './components/TypeFormation/index_modify_profile';
import UserDomaineFormationSelectionProfile from './components/TypeFormation/index_modify_profile';

// ========== Styles and Utils ==========
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';
import UsersFeedBack from './components/UsersFeedback';
import ProtectedClassementOrQuiz from './utils/ProtectedClassementOrQuiz';

// ========== Desktop Components ==========
import DesktopLandingPage from './components/Desktop/LandingPage';
import DesktopLandingPageManager from './components/Desktop/LandingPage/Manager';
import SideMenu from './components/Desktop/SideMenu';
import DesktopChatDiscussion from './components/Desktop/ChatDiscussion';
import DesktopProfile from './components/Desktop/Profile';
import DesktopSettings from './components/Desktop/Settings';
import DesktopAide from './components/Desktop/UsersFeedback';
import DesktopModifySwipe from './components/Desktop/ModifySwipeAnswers';
import ManagerLogin from './components/Desktop/LandingPage/ManagerLogin';

import apiCall from './utils/api';

// ========== Notification Components ==========
import NotificationPrompt from './components/NotificationPrompt';
import { NotificationProvider } from './contexts/NotificationContext';

// Add ScrollToTop component at the top level
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const location = useLocation();
  const { loading, isAuthenticated } = useAuth();
  const { isMobile } = useDeviceType();

  const [isSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);

  const chatDiscussionRef = useRef();

  const toggleMobileHistory = useCallback(() => {
    setIsMobileHistoryOpen((prev) => !prev);
  }, []);

  // Improved initial loader removal with better error handling
  useEffect(() => {
    try {
      // Safely remove the initial loader
      const removeLoader = () => {
        const loader = document.getElementById('initial-loader');
        if (loader) {
          console.log('Removing initial loader');
          loader.style.opacity = '0';
          loader.style.transition = 'opacity 0.5s';
          
          // Use both timeout and transitionend for reliability
          const handleTransitionEnd = () => {
            if (loader.parentNode) {
              loader.parentNode.removeChild(loader);
              console.log('Initial loader removed successfully');
            }
            loader.removeEventListener('transitionend', handleTransitionEnd);
          };
          
          loader.addEventListener('transitionend', handleTransitionEnd);
          
          // Fallback timeout in case transitionend doesn't fire
          setTimeout(() => {
            if (loader && loader.parentNode) {
              loader.parentNode.removeChild(loader);
              console.log('Initial loader removed via timeout');
            }
          }, 600);
        } else {
          console.log('Initial loader not found, might be already removed');
        }
      };
      
      // Execute after a short delay to ensure React is initialized
      setTimeout(removeLoader, 300);
    } catch (error) {
      console.error('Error removing initial loader:', error);
      // Try one more time if there was an error
      setTimeout(() => {
        try {
          const loader = document.getElementById('initial-loader');
          if (loader && loader.parentNode) {
            loader.parentNode.removeChild(loader);
            console.log('Initial loader removed in fallback handling');
          }
        } catch (finalError) {
          console.error('Final attempt to remove loader failed:', finalError);
        }
      }, 1000);
    }
  }, []);
  // Update CSS variable for responsive heights, etc.
  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    updateViewportHeight();
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });
    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  // Retrieve user info from local storage
  const user = localStorage.getItem('user');
  const userData = user ? JSON.parse(user) : {};
  const appEntryCount = userData.number_of_app_entries || 0;
  console.log("appEntryCount : ", appEntryCount);

  const typeBac = user ? JSON.parse(user).type_bac : null;
  const phoneNumber = user ? JSON.parse(user).phone_number : null;
  // const city = user ? JSON.parse(user).city : null;
  // Memoized ChatDiscussion component
  const MemoizedChatDiscussion = memo(
    ({ chatRef, toggleMobileHistory, isSideMenuOpen, isMobile }) => (
      <ChatDiscussion
        ref={chatRef}
        toggleMobileHistory={toggleMobileHistory}
        isSideMenuOpen={isSideMenuOpen}
        isMobile={isMobile}
      />
    )
  );

  const desktopChatRef = useRef(null);
  
  const handleNewChatDesktop = useCallback(() => {
    if (desktopChatRef.current) {
      desktopChatRef.current.resetChat();
    }
  }, []);

  const navigate = useNavigate();

  const handleDiscussionClick = (discussionData) => {
     navigate('/chat', {
       state: { selectedDiscussion: discussionData }
      });
   };

   useEffect(() => {
    if (isAuthenticated) {
      console.log("incrementing...");
      apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/increment-app-entry/`,
        'POST'
      )
        .then((data) => {
          console.log('App entry incremented', data);
          const user = localStorage.getItem('user');
          if (user) {
            const parsedUser = JSON.parse(user);
            parsedUser.number_of_app_entries = data.number_of_app_entries;
            localStorage.setItem('user', JSON.stringify(parsedUser));
          }
        })
        .catch((err) =>
          console.error('Error incrementing app entry count', err)
        );
    }
  }, [isAuthenticated]);


  useEffect(() => {
    // Only run this check if the user is authenticated
    if (isAuthenticated) {
      
        if (!phoneNumber || phoneNumber === null || phoneNumber == "") {
          console.log("PHONE :: "+phoneNumber)
          // Redirect to phone number update page
          navigate('/profile/modify/phone');
        }
    }
  }, [isAuthenticated, navigate]); // Add navigate to dependencies

  
  // Show a loading screen if still checking authentication status
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }
  // In the App.js file, locate the useEffect section where you check for authentication
// Add this code after the existing useEffect hooks


  // ===================================
  // DESKTOP LOGIC
  // ===================================
  if (!isMobile) {
    if (isAuthenticated) {
      return (
        <ThemeProvider theme={theme}> 
          <AppLoadingHandler>
            <NotificationProvider>
              <ChatProvider>
                <QuizProvider>
                  <GlobalStyles />
                  <ScrollToTop />
                  <SideMenu 
                    onNewChat={handleNewChatDesktop}
                    onDiscussionClick={handleDiscussionClick}
                  />
                  <Routes>
                    <Route
                        path="/card-type-formation/:id"
                        element={<CardTypeFormation />}
                    />
                    <Route path="/formation" element={<FicheFormation />} />
                    <Route
                      path="/chat"
                      element={<DesktopChatDiscussion ref={desktopChatRef} />}
                    />
                    <Route
                      path="/profile"
                      element={<DesktopProfile />}
                    />
                    <Route
                      path="/settings"
                      element={<DesktopSettings />}
                    />
                    <Route
                      path="/explorer"
                      element={
                        <ProtectedClassementOrQuiz isMobile={isMobile} />
                      }
                    />
                    {/* <Route
                      path="/voeux"
                      element={<DesktopVoeux />}
                    /> */}
                    <Route path="/formations-favorites" element={<Favoris />} />
                    <Route path="/filieres-favorites" element={<Favoris />} />
                    <Route
                      path="/settings/aide-meo"
                      element={<DesktopAide />}
                    />
                      <Route
                        path="/profile/modify/city"
                        element={<CitySelection />}
                      />
                      <Route
                        path="/profile/modify/phone"
                        element={<PhoneSelection />}
                      />


                      <Route
                        path="/profile/modify/type-formation"
                        element={<UserTypeFormationSelectionProfile />}
                      />
                      <Route
                        path="/profile/modify/domaines-favoris"
                        element={<DomainSelection />}
                      />
                      <Route
                        path="/profile/modify/domaine-formation"
                        element={<UserDomaineFormationSelectionProfile />}
                      />
                      <Route
                        path="/profile/modify/lycee"
                        element={<Lycee />}
                      />
                      <Route
                        path="/profile/modify/classe-ment"
                        element={<ClassementClasse />}
                      />

                      <Route
                        path="/profile/modify/grades"
                        element={<GeneralMoyenne />}
                      />
                      <Route
                        path="/profile/modify/specialites"
                        element={
                          typeBac === 'professionnel' ? (
                            <BacProfessionnel />
                          ) : typeBac === 'technologique' ? (
                            <BacTechnologique />
                          ) : typeBac === 'general' ? (
                            <BacGeneral />
                          ) : (
                            <TypeBac />
                          )
                        }
                      />
                      <Route
                        path="/profile/modify/type-bac"
                        element={<TypeBac />}
                      />
                      <Route
                        path="/profile/modify/swipe"
                        element={<DesktopModifySwipe />}
                      />
                      <Route
                        path="/profile/modify/user-type"
                        element={<UserTypeSelection />}
                      />
                      <Route path="/comparateur" element={<Comparateur />} />
                    <Route
                      path="*"
                      element={<Navigate to="/chat" replace />}
                    />
                    <Route path="/lettres" element={<LettresSelectFormation />} />
                    <Route path="/lettres-validate" element={<LettresValidateFormation />} />
                    <Route path="/lettres-questions" element={<LettresQuestions />} />
                    <Route path="/lettres-relecture" element={<LettresRelecture />} />
                    <Route path="/lettres-wait" element={<LettresWait />} />
                    <Route path="/lettres-final" element={<LettresFinal />} />
                  </Routes>
                  <NotificationPrompt />
                </QuizProvider>
              </ChatProvider>
            </NotificationProvider>
          </AppLoadingHandler>
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          <AppLoadingHandler>
            <GlobalStyles />
            <ScrollToTop />
            <Routes>
              <Route path="*" element={<DesktopLandingPage />} />
                  <Route
                    path="/first-login"
                    element={<FirstLoginAccount />}
                  />
                  <Route
                    path="/manager"
                    element={<DesktopLandingPageManager />}
                  />
                  <Route
                    path="/manager-login"
                    element={<ManagerLogin />}
                  />
                  <Route
                    path="/login"
                    element={<LoginPage />}
                  />
                  <Route path="/presentation-meo" element={<MeoFirstMessage />} />
                  {/* <Route path="/pseudo" element={<Pseudo />} /> */}

                  <Route path="/pseudo" element={<Pseudo />} />
                  <Route
                    path="/user-type-selection"
                    element={<UserTypeSelection />}
                  />
                  <Route
                    path="/type-bac"
                    element={<TypeBac />}
                  />
                  <Route
                    path="/bac1"
                    element={<FormationOnboarding />}
                  />
                 <Route path="/lycee" element={<Lycee />} />
                 <Route path="/problemes-formation" element={<ProblèmesFormationOnboarding />} />
                  <Route
                    path="/general-moyenne"
                    element={<GeneralMoyenne />}
                  />
                  <Route
                    path="/domaines-favoris"
                    element={<DomainSelection />}
                  />
                  <Route path="/origine-city" element={<CitySelection />} />
                  {/* <Route
                    path="/source-connaissance"
                    element={<DesktopSourceConnaissance />}
                  /> */}
                  <Route
                    path="/message-pret"
                    element={<MessagePret />}
                  />
                  <Route
                    path="/presentation-features"
                    element={<PresentationFeatures />}
                  />
                  <Route
                    path="/phone-selection"
                    element={<PhoneSelection />}
                  />
                  <Route
                    path="/bac-general"
                    element={<BacGeneral />}
                  />
                  <Route
                    path="/bac-technologique"
                    element={<BacTechnologique />}
                  />
                  <Route
                    path="/bac-professionnel"
                    element={<BacProfessionnel />}
                  />
                  <Route
                    path="/classe-ment"
                    element={<ClassementClasse />}
                  />
            </Routes>
          </AppLoadingHandler>
        </ThemeProvider>
      );
    }
  }
  
  // ===================================
  // MOBILE LOGIC
  // ===================================
  if (isAuthenticated) {
    // ------- MOBILE & Authenticated -------
    return (
      <ThemeProvider theme={theme}>
        <AppLoadingHandler>
          <GlobalStyles />
          <ScrollToTop />
          <NotificationProvider>
            <ChatProvider>
              <QuizProvider>
                <div className="App">
                  <main
                    className={`content-area mobile ${isSideMenuOpen ? 'side-menu-open' : ''}`}
                  >
                    <Routes>
                      {/* Chat route */}
                      <Route
                        path="/chat"
                        element={
                          <MemoizedChatDiscussion
                            chatRef={chatDiscussionRef}
                            toggleMobileHistory={toggleMobileHistory}
                            isSideMenuOpen={isSideMenuOpen}
                            isMobile={isMobile}
                          />
                        }
                      />

                      {/* Protected route (Classement / Quiz) */}
                      <Route
                        path="/classement"
                        element={
                          <ProtectedClassementOrQuiz isMobile={isMobile} />
                        }
                      />

                      <Route path="/settings" element={<Settings />} />
                      <Route path="/settings/aide-meo" element={<UsersFeedBack />} />
                      <Route
                        path="/card-type-formation/:id"
                        element={<CardTypeFormation />}
                      />
                      <Route path="/fiche-formation" element={<FicheFormation />} />
                      <Route path="/formation" element={<FicheFormation />} />
                      <Route path="/formations-favorites" element={<Favoris />} />
                      <Route path="/filieres-favorites" element={<Favoris />} />
                      <Route path="/comparateur" element={<Comparateur />} />
                      <Route path="/lettres" element={<LettresSelectFormation />} />
                      <Route path="/lettres-validate" element={<LettresValidateFormation />} />
                      <Route path="/lettres-questions" element={<LettresQuestions />} />
                      <Route path="/lettres-relecture" element={<LettresRelecture />} />
                      <Route path="/lettres-wait" element={<LettresWait />} />
                      <Route path="/lettres-final" element={<LettresFinal />} />

                      {/* Profile-related routes */}
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/profile/modify/city"
                        element={<CitySelection />}
                      />
                      
                      <Route
                        path="/profile/modify/phone"
                        element={<PhoneSelection />}
                      />
                      <Route
                        path="/profile/modify/type-formation"
                        element={<UserTypeFormationSelectionProfile />}
                      />
                      <Route
                        path="/profile/modify/domaines-favoris"
                        element={<DomainSelection />}
                      />
                      <Route
                        path="/profile/modify/domaine-formation"
                        element={<UserDomaineFormationSelectionProfile />}
                      />
                      <Route
                        path="/profile/modify/lycee"
                        element={<Lycee />}
                      />
                      <Route
                        path="/profile/modify/classe-ment"
                        element={<ClassementClasse />}
                      />
                      <Route
                        path="/profile/modify/grades"
                        element={<GeneralMoyenne />}
                      />
                      <Route
                        path="/profile/modify/specialites"
                        element={
                          typeBac === 'professionnel' ? (
                            <BacProfessionnel />
                          ) : typeBac === 'technologique' ? (
                            <BacTechnologique />
                          ) : typeBac === 'general' ? (
                            <BacGeneral />
                          ) : (
                            <TypeBac />
                          )
                        }
                      />
                      <Route
                        path="/profile/modify/type-bac"
                        element={<TypeBac />}
                      />
                      <Route
                        path="/profile/modify/swipe"
                        element={<ModifySwipeAnswers />}
                      />
                      <Route
                        path="/profile/modify/user-type"
                        element={<UserTypeSelection />}
                      />

                      {/* Default redirect for authenticated users */}
                      <Route path="/" element={<Navigate to="/classement" replace />} />
                      <Route
                        path="*"
                        element={<Navigate to="/classement" replace />}
                      />
                    </Routes>
                  </main>

                  {(location.pathname === '/lettres' || !location.pathname.includes('lettres-')) && <BottomMenu />}

                  {isMobile && appEntryCount > 0 && (appEntryCount === 2 || (appEntryCount % 6 === 0)) && <StarsPopup />}
                  {location.pathname === '/chat' && (
                    <MobileHistoryTab
                      isOpen={isMobileHistoryOpen}
                      onClose={toggleMobileHistory}
                    />
                  )}
                  <NotificationPrompt />
                </div>
              </QuizProvider>
            </ChatProvider>
          </NotificationProvider>
        </AppLoadingHandler>
      </ThemeProvider>
    );
  }

  // ------- MOBILE & NOT Authenticated -------
  return (
    <ThemeProvider theme={theme}>
      <AppLoadingHandler>
        <GlobalStyles />
        <ScrollToTop />
        <Routes>
          {/* Typical public routes */}
          <Route path="/" element={<FirstPage />} />
          <Route path="/first-page" element={<FirstPage />} />
          <Route path="/login" element={<LoginPage />} />

          {/* Onboarding Flow */}
          <Route path="/presentation-meo" element={<MeoFirstMessage />} />
          <Route path="/pseudo" element={<Pseudo />} />


          <Route path="/origine-city" element={<CitySelection />} />
          {/* <Route path="/gender-selection" element={<GenderSelection />} /> */}
          {/* <Route path="/no-user-xp" element={<NoUserXP />} /> */}
          <Route path="/user-type-selection" element={<UserTypeSelection />} />
          <Route path="/type-bac" element={<TypeBac />} />
          <Route
            path="/bac1"
            element={<FormationOnboarding />}
          />
          <Route path="/bac-general" element={<BacGeneral />} />
          <Route path="/bac-technologique" element={<BacTechnologique />} />
          <Route path="/bac-professionnel" element={<BacProfessionnel />} />
          {/* <Route path="/domaine-formation" element={<DomaineFormation />} /> */}
          <Route path="/domaines-favoris" element={<DomainSelection />} />
          <Route path="/presentation-features" element={<PresentationFeatures />} />
          <Route path="/phone-selection" element={<PhoneSelection />} />
          <Route path="/type-formation" element={<TypeFormation />} />
          <Route path="/lycee" element={<Lycee />} />
          <Route path="/problemes-formation" element={<ProblèmesFormationOnboarding />} />
          <Route path="/message-pret" element={<MessagePret />} />
          
          <Route path="/general-moyenne" element={<GeneralMoyenne />} />
          <Route path="/source-connaissance" element={<SourceConnaissance />} />

          {/* Registration */}
          <Route path="/first-login" element={<FirstLoginAccount />} />

          {/* Catch-all redirect */}
          <Route path="*" element={<Navigate to="/first-page" replace />} />
          <Route
            path="/classe-ment"
            element={<ClassementClasse />}
          />
        </Routes>
      </AppLoadingHandler>
    </ThemeProvider>
  );
}

export default App;
