import formationsMeta from "./formationsMeta.json"
import searchIndex from "./searchIndex.json"

// Debug flag - set to true to enable detailed console logs
const DEBUG = false;

/**
 * Search formations based on a query string
 * @param {string} query - The search query
 * @param {Object} searchIndex - The search index mapping words to formation indices
 * @param {Object} formationsMeta - The metadata for all formations
 * @param {number} maxResults - Maximum number of results to return
 * @returns {Array} - Sorted array of matching formations with relevance scores
 */
export const searchFormations = (query, searchIndex, formationsMeta, maxResults = 10) => {
  if (!query || !searchIndex || !formationsMeta) return [];
  
  // Normalize the query
  const normalizedQuery = normalizeText(query);
  
  // Skip empty queries
  if (!normalizedQuery) return [];
  
  // Split the query into words for better matching
  const queryWords = normalizedQuery
    .split(/\s+/)
    .filter(word => word.length > 0);
  
  if (queryWords.length === 0) return [];
  
  // Get the array of formation IDs (keys of the formationsMeta object)
  const formationIds = Object.keys(formationsMeta);
  
  // Use a Map to track each candidate's matching words
  const candidateScores = new Map();
  
  // Look for the normalized query as a whole in the index
  if (queryWords.length > 1) {
    if (searchIndex[normalizedQuery]) {
      for (const index of searchIndex[normalizedQuery]) {
        // For exact phrase matches, give credit for all words
        if (!candidateScores.has(index)) {
          candidateScores.set(index, new Set(queryWords));
        } else {
          // Add all query words to this candidate's matched words
          for (const word of queryWords) {
            candidateScores.get(index).add(word);
          }
        }
      }
    }
    
    // Also check if any terms in the search index contain the entire query
    for (const term of Object.keys(searchIndex)) {
      if (term.includes(normalizedQuery)) {
        for (const index of searchIndex[term]) {
          if (!candidateScores.has(index)) {
            candidateScores.set(index, new Set(queryWords));
          } else {
            for (const word of queryWords) {
              candidateScores.get(index).add(word);
            }
          }
        }
      }
    }
  }
  
  // Then try individual word matches
  for (const word of queryWords) {
    if (searchIndex[word]) {
      for (const index of searchIndex[word]) {
        // Track which words matched for each candidate
        if (!candidateScores.has(index)) {
          candidateScores.set(index, new Set([word]));
        } else {
          candidateScores.get(index).add(word);
        }
      }
    }
  }
  
  // PERFORMANCE OPTIMIZATION: Only do partial matching if we don't have enough exact matches
  // and limit how many partial matches we'll look for
  if (candidateScores.size < maxResults * 2) {
    // Avoid redoing partial matching for words we've already processed
    const processedPartialMatches = new Set();
    
    // First try partial matching for the entire query if it's multi-word
    if (queryWords.length > 1 && normalizedQuery.length > 3) {
      let partialPhraseMatchCount = 0;
      const maxPartialPhraseMatches = 30;
      
      for (const term of Object.keys(searchIndex)) {
        // Only match terms that contain our query but aren't the query itself
        if (term !== normalizedQuery && term.includes(normalizedQuery)) {
          for (const index of searchIndex[term]) {
            if (!candidateScores.has(index)) {
              // For partial phrase matches, give credit for all words
              candidateScores.set(index, new Set(queryWords));
            } else {
              // Add all query words
              for (const word of queryWords) {
                candidateScores.get(index).add(word);
              }
            }
          }
          
          partialPhraseMatchCount++;
          if (partialPhraseMatchCount >= maxPartialPhraseMatches) break;
        }
      }
    }
    
    // Then try partial matching for individual words
    for (const word of queryWords) {
      // Skip words that already found enough matches
      if (candidateScores.size >= maxResults * 3) break;
      
      // Only process a limited number of partial matches per word (maximum 20)
      let partialMatchCount = 0;
      const maxPartialMatches = 20;
      
      for (const term of Object.keys(searchIndex)) {
        // Skip if we've already processed this combination
        const key = `${word}:${term}`;
        if (processedPartialMatches.has(key)) continue;
        processedPartialMatches.add(key);
        
        // Only match terms that contain our word but aren't the word itself
        if (term !== word && term.includes(word)) {
          for (const index of searchIndex[term]) {
            if (!candidateScores.has(index)) {
              candidateScores.set(index, new Set([word]));
            } else {
              candidateScores.get(index).add(word);
            }
          }
          
          // Limit partial matching to improve performance
          partialMatchCount++;
          if (partialMatchCount >= maxPartialMatches) break;
        }
      }
    }
  }
  
  if (candidateScores.size === 0) return [];
  
  if (DEBUG) {
    console.log(`[DEBUG] Found ${candidateScores.size} candidate formations`);
  }
  
  // Create a scoring cache for texts we've already normalized and processed
  // This avoids redundant normalization of the same text
  const textCache = new Map();
  
  // PERFORMANCE OPTIMIZATION: Process results and scores in one pass
  const results = [];
  for (const [index, matchedQueryWords] of candidateScores.entries()) {
    if (index >= 0 && index < formationIds.length) {
      const id = formationIds[index];
      if (formationsMeta[id]) {
        const formation = {
          id: id,
          ...formationsMeta[id]
        };
        
        // Create minimal debug info if needed
        const matchDetails = DEBUG ? [] : null;
        
        // Track final matched words, expanding beyond initial matches
        const finalMatchedWords = new Set(matchedQueryWords);
        
        // Get formation name and establishment name
        const formationName = formation.nomFormation || '';
        const establishmentName = formation.nomEtablissement || '';
        const commune = formation.commune || '';
        
        // Get normalized values from cache or normalize
        const getNormalizedText = (text) => {
          if (!textCache.has(text)) {
            textCache.set(text, normalizeText(text));
          }
          return textCache.get(text);
        };
        
        const normalizedFormationName = getNormalizedText(formationName);
        const normalizedEstablishmentName = getNormalizedText(establishmentName);
        const normalizedCommune = commune ? getNormalizedText(commune) : '';
        
        // First check for whole query match (for multi-word phrases like "cy tech")
        if (queryWords.length > 1) {
          let fullQueryMatched = false;
          
          // Check if full query appears in formation name
          if (normalizedFormationName.includes(normalizedQuery)) {
            fullQueryMatched = true;
            if (DEBUG) matchDetails.push(`Full query "${normalizedQuery}" matched in formation name`);
          }
          
          // Check if full query appears in establishment name
          if (normalizedEstablishmentName.includes(normalizedQuery)) {
            fullQueryMatched = true;
            if (DEBUG) matchDetails.push(`Full query "${normalizedQuery}" matched in establishment name`);
          }
          
          // If full query matched, give credit for all words
          if (fullQueryMatched) {
            for (const word of queryWords) {
              finalMatchedWords.add(word);
            }
          }
        }
        
        // For each query word, check all text fields
        for (const word of queryWords) {
          if (finalMatchedWords.has(word)) {
            // Already matched this word
            if (DEBUG) {
              if (normalizedFormationName.includes(word)) {
                matchDetails.push(`Word "${word}" matched in formation name`);
              }
              if (normalizedEstablishmentName.includes(word)) {
                matchDetails.push(`Word "${word}" matched in establishment name`);
              }
              if (normalizedCommune && normalizedCommune.includes(word)) {
                matchDetails.push(`Word "${word}" matched in commune`);
              }
            }
            continue;
          }
          
          // Check for additional matches
          if (normalizedFormationName.includes(word)) {
            finalMatchedWords.add(word);
            if (DEBUG) matchDetails.push(`Word "${word}" matched in formation name`);
          } else if (normalizedEstablishmentName.includes(word)) {
            finalMatchedWords.add(word);
            if (DEBUG) matchDetails.push(`Word "${word}" matched in establishment name`);
          } else if (normalizedCommune && normalizedCommune.includes(word)) {
            finalMatchedWords.add(word);
            if (DEBUG) matchDetails.push(`Word "${word}" matched in commune`);
          }
        }
        
        // Calculate score - not just matched words count, but also consider adjacency
        const matchedWordsCount = finalMatchedWords.size;
        
        // Base score is number of matched words × 1000
        let relevanceScore = matchedWordsCount * 1000;
        
        // BONUS: Check for adjacent words matching in sequence
        if (queryWords.length > 1) {
          // Check if full phrase exists anywhere
          const fullPhraseMatch = 
            normalizedFormationName.includes(normalizedQuery) || 
            normalizedEstablishmentName.includes(normalizedQuery) ||
            (normalizedCommune && normalizedCommune.includes(normalizedQuery));
            
          if (fullPhraseMatch) {
            // Add significant bonus for full phrase matching anywhere (equivalent to +0.5 word)
            relevanceScore += 500;
            if (DEBUG) matchDetails.push(`Full phrase "${normalizedQuery}" matched: +500 bonus`);
          } else {
            // Check for partial phrases (adjacent pairs)
            for (let i = 0; i < queryWords.length - 1; i++) {
              const wordPair = queryWords[i] + " " + queryWords[i+1];
              
              const pairMatched = 
                normalizedFormationName.includes(wordPair) || 
                normalizedEstablishmentName.includes(wordPair) ||
                (normalizedCommune && normalizedCommune.includes(wordPair));
                
              if (pairMatched) {
                // Add smaller bonus for each adjacent pair match (equivalent to +0.2 word)
                relevanceScore += 200;
                if (DEBUG) matchDetails.push(`Adjacent words "${wordPair}" matched: +200 bonus`);
              }
            }
          }
        }
        
        if (DEBUG) {
          const matchPercentage = (matchedWordsCount / queryWords.length) * 100;
          matchDetails.push(`Matched ${matchedWordsCount}/${queryWords.length} words (${matchPercentage.toFixed(1)}%)`);
          matchDetails.push(`Final score: ${relevanceScore}`);
        }
        
        // Only add formations with at least one match
        if (matchedWordsCount > 0) {
          results.push({
            ...formation,
            relevanceScore,
            matchDetails: DEBUG ? matchDetails : undefined
          });
        }
      }
    }
  }
  
  // Sort more precisely now
  // 1. First by number of matched words + adjacency bonus (primary score)
  // 2. Then by exact match of formation vs establishment name
  // 3. Finally alphabetical
  results.sort((a, b) => {
    // For relevance score, we now include adjacency bonuses
    return b.relevanceScore - a.relevanceScore;
  });
  
  if (DEBUG) {
    console.log(`[DEBUG] Sorted results (top ${Math.min(maxResults, results.length)}):`);
    results.slice(0, maxResults).forEach((result, i) => {
      const baseScore = Math.floor(result.relevanceScore / 1000);
      const bonusScore = result.relevanceScore % 1000;
      console.log(`[DEBUG] #${i+1}: "${result.nomFormation}" at "${result.nomEtablissement}" - Words: ${baseScore}, Bonus: ${bonusScore}`);
      if (result.matchDetails) {
        console.log(`[DEBUG]   Match details:`, result.matchDetails.slice(0, 3).join('; ') + '...');
      }
    });
    
    // Check if specific formation is in the results
    const targetFormation = results.findIndex(r => r.id === 4191);
    if (targetFormation !== -1) {
      console.log(`[DEBUG] Formation 4191 found at position ${targetFormation + 1}`);
    } else {
      console.log(`[DEBUG] Formation 4191 NOT found in top ${maxResults} results`);
    }
  }
  
  // Return limited results with minimal data
  return results.slice(0, maxResults).map(result => {
    // Optimize by creating a new object with only needed properties
    return {
      id: result.id,
      nomFormation: result.nomFormation,
      nomEtablissement: result.nomEtablissement,
      commune: result.commune,
      relevanceScore: result.relevanceScore
    };
  });
};

/**
 * Create a debounced version of the searchFormations function
 * @param {Function} func - Function to debounce (usually searchFormations)
 * @param {number} delay - Delay in milliseconds
 * @returns {Function} - Debounced function
 */
export const debounce = (func, delay = 200) => {
  let timerId;
  
  return (...args) => {
    // Clear previous timer
    if (timerId) {
      clearTimeout(timerId);
    }
    
    // Create promise to make the debounced function awaitable
    return new Promise(resolve => {
      timerId = setTimeout(() => {
        const result = func(...args);
        resolve(result);
      }, delay);
    });
  };
};

/**
 * Debounced version of searchFormations - only executes after user stops typing
 * @param {string} query - The search query
 * @param {Object} searchIndex - The search index mapping words to formation indices
 * @param {Object} formationsMeta - The metadata for all formations
 * @param {number} maxResults - Maximum number of results to return
 * @returns {Promise<Array>} - Promise that resolves to sorted array of matching formations
 */
export const debouncedSearchFormations = debounce(searchFormations, 200);

/**
 * Cache to store previous search results
 * This dramatically improves performance for repeated searches
 */
const searchResultsCache = new Map();
const MAX_CACHE_SIZE = 100;

/**
 * Cached version of searchFormations
 * @param {string} query - The search query
 * @param {Object} searchIndex - The search index
 * @param {Object} formationsMeta - Formation metadata
 * @param {number} maxResults - Max results to return
 * @returns {Array} - Search results
 */
export const cachedSearchFormations = (query, searchIndex, formationsMeta, maxResults = 10) => {
  // Create a cache key from the query
  const cacheKey = query.trim().toLowerCase();
  
  // If we have a cached result, return it
  if (searchResultsCache.has(cacheKey)) {
    return searchResultsCache.get(cacheKey);
  }
  
  // Otherwise perform the search
  const results = searchFormations(query, searchIndex, formationsMeta, maxResults);
  
  // Cache the result
  searchResultsCache.set(cacheKey, results);
  
  // Limit cache size (remove oldest entries when too large)
  if (searchResultsCache.size > MAX_CACHE_SIZE) {
    const oldestKey = searchResultsCache.keys().next().value;
    searchResultsCache.delete(oldestKey);
  }
  
  return results;
};

/**
 * Recommended function to use for searching
 * Combines caching and debouncing for optimal performance
 */
export const optimizedSearchFormations = debounce(cachedSearchFormations, 200);

/**
 * Normalize text for searching (lowercase, remove accents, replace special chars)
 * @param {string} text - The text to normalize
 * @returns {string} - Normalized text
 */
export const normalizeText = (text) => {
  if (!text) return '';
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove accents
    .replace(/['-]/g, " ")           // Replace hyphens and apostrophes with spaces
    .replace(/[,()]/g, " ")          // Replace commas and parentheses with spaces
    .replace(/\s+/g, " ")            // Replace multiple spaces with a single space
    .trim();                          // Remove leading/trailing spaces
};

/**
 * Load search data from imported files
 * @returns {Object} - Object containing searchIndex and formationsMeta
 */
export const loadSearchData = async () => {
  try {
    // Validation des données importées
    if (!searchIndex || !formationsMeta) {
      throw new Error('Données de recherche manquantes ou invalides');
    }
    
    // Vérifier le format des métadonnées de formation
    console.log('Format des métadonnées de formation:', 
      Array.isArray(formationsMeta) ? 'Array' : 'Object',
      'Taille:', Array.isArray(formationsMeta) ? formationsMeta.length : Object.keys(formationsMeta).length
    );
    
    // Si c'est un tableau, utiliser tel quel
    let processedFormationsMeta = formationsMeta;
    
    console.log('Chargement des données de recherche terminé');
    return { searchIndex, formationsMeta: processedFormationsMeta };
  } catch (error) {
    console.error('Erreur lors du chargement des données de recherche:', error);
    // Retourner les données importées comme fallback
    return { searchIndex, formationsMeta };
  }
};

/**
 * Convert formation metadata to formation object
 * @param {Object} meta - Formation metadata
 * @returns {Object} - Formatted formation object
 */
export const metaToFormation = (meta) => {
  if (!meta) return null;
  
  // S'assurer que l'ID est présent
  if (!meta.id) {
    console.error('Formation sans ID:', meta);
    return null;
  }
  
  return {
    id: meta.id,
    nom_formation: meta.nomFormation || 'Formation sans nom',
    nom_ecole: meta.nomEtablissement || 'Non spécifié',
    ville: meta.commune || 'Non spécifié',
    duree: "2 ans", // Valeur par défaut
    type: "Formation",
    cout: "Non spécifié",
    salaire_sortie: "Non spécifié", 
    public_prive: "Non spécifié",
    // Ajouter d'autres valeurs par défaut au besoin
    domaines_enseignés_principaux: [],
    domaines_enseignés_secondaires: [],
    avantages: [],
    desavantages: [],
    débouchés_professionnels: {}
  };
};