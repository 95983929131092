import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import apiCall from '../../utils/api';
import emptyHeartIcon from '../../assets/coeur_vide_rouge_icon.png';
import filledHeartIcon from '../../assets/coeur_icon.png';
// import arrowIcon from '../../assets/back_button_gris.png';
// import searchIcon from '../../assets/recherche_icon.png';

import {
  PlaceholderLine,
} from '../DecouvrirFormations/styles';

import {
  ScrollableRankings,
  TrophyIcon,
  RankNumber,
  ScoreContainer,
  ScoreValue,
  ContentWrapper,
  FilterModal,
  CloseButton,
  ClearAllButton,
  FilterSection,
  FilterTitle,
  FilterOptionsGrid,
  FilterOption,
  ModalOverlay,
  ScoreCircle,
  HeartIconClassement
} from '../Classement/styles';

// Imports des styles ont été nettoyés car le filtre a été supprimé

import TrophyGold from '../../assets/top1_icon.png';
import TrophySilver from '../../assets/top2_icon.png';
import TrophyBronze from '../../assets/top3_icon.png';
import { allCitiesFormations } from '../data';
import FilteringBrowser from '../Classement/FilteringBrowser';

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const SearchContainer = styled.div`
  width: 90%;
  max-width: 350px;
  position: relative;
`;

const EmptyStateContainer = styled.div`
  padding: 20px;
  text-align: center;
  font-family: Gabarito;
  font-size: 16px;
  color: #626060;
`;

const FilterLine = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const ClearFilterButton = styled.button`
  margin-left: 10px;
  background: #38B6FF;
  color: #fff;
  border: none;
  border-radius: 9px;
  padding: 4px 8px;
  cursor: pointer;
`;

const FormationsRankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 0%;
`;

const FormationCard = styled.div`
  width: 95%;
  margin-left: 2.5%;
  height: 100px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.98);
  }
`;

const FormationName = styled.div`
  color: #000;
  text-align: left;
  font-family: Gabarito;
  font-size: 16px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: 2.4em;
`;

const FormationDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Gabarito;
  font-size: 14px;
  color: #626060;
  div:last-child {
    color: #888;
    font-size: 13px;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function FormationClassementTypeFormation({ searchKeyword = '', showFilterModal = false, setShowFilterModal = () => {} }) {
  // Animation pour le cœur
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes pulse {
        0% { transform: scale(1); }
        40% { transform: scale(1.3); }
        100% { transform: scale(1); }
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const navigate = useNavigate();
  const { id: urlSafeId } = useParams();
  const formationId = urlSafeId;

  // État pour les filtres
  const [selectedCitiesFormations, setSelectedCitiesFormations] = useState(() => {
    const storedCities = localStorage.getItem('selectedCitiesFormations');
    return storedCities ? JSON.parse(storedCities) : [];
  });

  // État pour les favoris
  const [favorites, setFavorites] = useState(() => {
    const stored = localStorage.getItem('favorites_formations');
    return stored ? JSON.parse(stored) : [];
  });

  // Référence pour la zone de recherche
  const citiesButtonRef = useRef(null);
  
  // État pour la pulsation du cœur
  const [pulsatingHeartId, setPulsatingHeartId] = useState(null);

  // État pour la recherche
  const [searchInputValue, setSearchInputValue] = useState(() => {
    return localStorage.getItem('searchKeywordFormation') || '';
  });
  const searchInputRef = useRef(null);

  // État pour les données de classement
  const [isLoading, setIsLoading] = useState(false);
  const [rankingsFormations, setRankingsFormations] = useState({});
  const isFirstRender = useRef(true);

  // Fonction pour rendre le cercle de score
  const renderScoreCircle = (score, index) => {
    const numericScore = Number(score) || 0;
    const clampedScore = Math.max(0, Math.min(100, numericScore));
    
    // Color stops for progressive gradient (from red to yellow to green)
    const colorStops = [
      { value: 0, color: '#FF0000' },   // Red at 0%
      { value: 50, color: '#F1E424' },  // Yellow at 50%
      { value: 100, color: '#32EA2C' }  // Green at 100%
    ];
    
    // Find the two color stops to lerp between
    let lowerStop = colorStops[0];
    let upperStop = colorStops[colorStops.length - 1];
    
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (clampedScore >= colorStops[i].value && clampedScore <= colorStops[i + 1].value) {
        lowerStop = colorStops[i];
        upperStop = colorStops[i + 1];
        break;
      }
    }
    
    // Calculate interpolation factor
    const factor = (clampedScore - lowerStop.value) / (upperStop.value - lowerStop.value);
    
    // Helper function to interpolate between hex colors
    const lerpColor = (a, b, factor) => {
      const ah = parseInt(a.replace(/#/g, ''), 16);
      const ar = ah >> 16;
      const ag = (ah >> 8) & 0xff;
      const ab = ah & 0xff;
      
      const bh = parseInt(b.replace(/#/g, ''), 16);
      const br = bh >> 16;
      const bg = (bh >> 8) & 0xff;
      const bb = bh & 0xff;
      
      const rr = ar + factor * (br - ar);
      const rg = ag + factor * (bg - ag);
      const rb = ab + factor * (bb - ab);
      
      return `#${((1 << 24) + (Math.round(rr) << 16) + (Math.round(rg) << 8) + Math.round(rb)).toString(16).slice(1)}`;
    };
    
    // Calculate gradient colors
    const gradientStart = lerpColor(lowerStop.color, upperStop.color, factor);
    const gradientEnd = lerpColor(lowerStop.color, upperStop.color, Math.max(0, factor - 0.3)); // Slightly darker for end
    
    // Use start color for text
    const textColor = gradientStart;
    
    // Create a unique ID for this gradient
    const gradientId = `scoreGradient_${index}_${clampedScore}`;
    
    return {
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
          <circle cx="42.5" cy="42.5" r="37.5" stroke={`url(#${gradientId})`} strokeWidth="10"/>
          <defs>
            <linearGradient id={gradientId} x1="85" y1="42.5" x2="0" y2="42.5" gradientUnits="userSpaceOnUse">
              <stop stopColor={gradientStart}/>
              <stop offset="1" stopColor={gradientEnd}/>
            </linearGradient>
          </defs>
        </svg>
      ),
      textColor
    };
  };

  // Fonction pour basculer le statut de favori
  const toggleFavorite = async (e, formation) => {
    e.stopPropagation();
    
    // Vérifier le statut actuel du favori
    const isFavorite = favorites.some(fav => fav.id === formation.id);
    
    // Mettre à jour immédiatement l'interface utilisateur
    const newFavoriteStatus = !isFavorite;
    
    setPulsatingHeartId(formation.id);
    
    setTimeout(() => {
      setPulsatingHeartId(null);
    }, 600);
    
    // Mettre à jour l'état local
    const updatedRankings = {...rankingsFormations};
    Object.keys(updatedRankings).forEach(key => {
      if (updatedRankings[key].id === formation.id) {
        updatedRankings[key] = {...updatedRankings[key], is_favorite: newFavoriteStatus};
      }
    });
    setRankingsFormations(updatedRankings);
    
    try {
      if (isFavorite) {
        // Supprimer des favoris
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/supprimer_favoris_formation/`,
          'DELETE',
          { id: formation.id }
        );
        
        // Mettre à jour localStorage
        const updatedFavorites = favorites.filter(fav => fav.id !== formation.id);
        localStorage.setItem('favorites_formations', JSON.stringify(updatedFavorites));
        setFavorites(updatedFavorites);
      } else {
        // Ajouter aux favoris
        await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/ajouter_favoris_formation/`,
          'POST',
          { 
            id: formation.id, 
            matchPercentage: formation.score_formations 
          }
        );
        
        // Mettre à jour localStorage
        const newFavorite = { 
          id: formation.id,
          matchPercentage: formation.score_formations 
        };
        const updatedFavorites = [...favorites, newFavorite];
        localStorage.setItem('favorites_formations', JSON.stringify(updatedFavorites));
        setFavorites(updatedFavorites);
      }
      
      // Mettre à jour le localStorage pour les classements
      localStorage.setItem('rankings_formations', JSON.stringify(updatedRankings));
    } catch (error) {
      console.error('Error updating favorite:', error);
      
      // En cas d'erreur, rétablir l'état précédent
      const revertedRankings = {...rankingsFormations};
      Object.keys(revertedRankings).forEach(key => {
        if (revertedRankings[key].id === formation.id) {
          revertedRankings[key] = {...revertedRankings[key], is_favorite: !newFavoriteStatus};
        }
      });
      setRankingsFormations(revertedRankings);
    }
  };

  // Sauvegarder les filtres dans localStorage
  useEffect(() => {
    localStorage.setItem('selectedCitiesFormations', JSON.stringify(selectedCitiesFormations));
  }, [selectedCitiesFormations]);

  // Fonction pour récupérer les données de classement
  const fetchFormationsRanking = async () => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams();
      
      if (selectedCitiesFormations.length) {
        params.append('cities_formations', selectedCitiesFormations.join(','));
      }
      
      if (formationId) {
        params.append('unique_types_formations', formationId);
      }
      
      if (searchKeyword && searchKeyword.trim() !== "") {
        params.append('search_keyword_formation', searchKeyword.trim());
      }
      
      const response = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/quiz/ranking_formations?${params.toString()}`,
        'GET'
      );

      if (response && response.rankings_formations) {
        // Récupérer la liste des favoris
        const favoritesFromStorage = JSON.parse(localStorage.getItem('favorites_formations')) || [];
        
        // Mettre à jour chaque formation avec son statut de favori
        const updatedRankings = {};
        Object.entries(response.rankings_formations).forEach(([key, formation]) => {
          updatedRankings[key] = {
            ...formation,
            is_favorite: favoritesFromStorage.some(fav => fav.id === formation.id)
          };
        });
        
        setRankingsFormations(updatedRankings);
        localStorage.setItem('rankings_formations', JSON.stringify(updatedRankings));
        localStorage.setItem('need_update_ranking_formation', 'false');
      } else {
        setRankingsFormations({});
        localStorage.setItem('rankings_formations', '{}');
        localStorage.setItem('need_update_ranking_formation', 'false');
      }
    } catch (error) {
      console.error('Error fetching formations rankings:', error);
      setRankingsFormations({});
      localStorage.setItem('rankings_formations', '{}');
      localStorage.setItem('need_update_ranking_formation', 'false');
    } finally {
      setIsLoading(false);
    }
  };

  // Valider les filtres stockés et les utiliser pour récupérer les données
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      selectedCitiesFormations.forEach((city) => {
        if (!allCitiesFormations.includes(city)) {
          setSelectedCitiesFormations((prev) => prev.filter((c) => c !== city));
        }
      });
      return;
    }
    
    localStorage.setItem('need_update_ranking_formation', 'true');
    fetchFormationsRanking();
  }, [selectedCitiesFormations, searchKeyword]);

  // Récupérer les données au montage
  useEffect(() => {
    const needUpdate = localStorage.getItem('need_update_ranking_formation');
    if (needUpdate === 'true' || needUpdate === null) {
      fetchFormationsRanking();
    } else if (needUpdate === 'false') {
      const stored = localStorage.getItem('rankings_formations');
      if (stored) {
        // Récupérer les rankings du localStorage
        const storedRankings = JSON.parse(stored);
        
        // Récupérer les favoris actuels pour mettre à jour le statut
        const favoritesFromStorage = JSON.parse(localStorage.getItem('favorites_formations')) || [];
        
        // Mettre à jour le statut de favori pour chaque formation
        const updatedRankings = {};
        Object.entries(storedRankings).forEach(([key, formation]) => {
          updatedRankings[key] = {
            ...formation,
            is_favorite: favoritesFromStorage.some(fav => fav.id === formation.id)
          };
        });
        
        setRankingsFormations(updatedRankings);
      } else {
        setRankingsFormations({});
      }
      setIsLoading(false);
    } else {
      localStorage.setItem('need_update_ranking_formation', 'true');
      fetchFormationsRanking();
    }
  }, []);

  // Conversion en tableau pour le rendu
  const topFormationsArray = Object.entries(rankingsFormations);

  // Fonction pour afficher l'icône de trophée
  const renderTrophyIcon = (rank) => {
    switch (rank) {
      case 1:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyGold})` }} />;
      case 2:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophySilver})` }} />;
      case 3:
        return <TrophyIcon style={{ backgroundImage: `url(${TrophyBronze})` }} />;
      default:
        return <RankNumber>{rank}</RankNumber>;
    }
  };

  // Fonction pour gérer la recherche
  const handleSearchChange = (value) => {
    localStorage.setItem('searchKeywordFormation', value);
  };

  // Fonction pour gérer la soumission de recherche
  const handleSearchSubmit = () => {
    localStorage.setItem('need_update_ranking_formation', 'true');
    fetchFormationsRanking();
  };

  return (
    <ContentWrapper>
      {/* Barre de recherche */}
      <SearchWrapper>
        <SearchContainer>
          <FilteringBrowser
            filterType="search_keyword"
            onClose={() => {}}
            items={[]}
            selectedItems={searchKeyword}
            onSelectedItemsChange={handleSearchChange}
            toggleButtonRef={searchInputRef}
            onSubmit={handleSearchSubmit}
            activeTab="decouvrir"
          />
        </SearchContainer>
      </SearchWrapper>
      
      {/* Le bouton filtre a été supprimé */}

      {/* Le navigateur de filtrage pour les villes a été supprimé */}

      {/* Liste des classements ou message vide */}
      <ScrollableRankings>
        {isLoading ? (
          <FormationsRankingList>
            {Array.from({ length: 3 }).map((_, idx) => (
              <FormationCard key={idx}>
                <div style={{ width: "100%" }}>
                  <PlaceholderLine width="90%" />
                  <PlaceholderLine width="60%" />
                </div>
              </FormationCard>
            ))}
          </FormationsRankingList>
        ) : topFormationsArray.length > 0 ? (
          <FormationsRankingList>
            {topFormationsArray.map(([rankKey, item], index) => {
              const rank = parseInt(rankKey.replace("top", ""), 10);
              const isFavorite = favorites.some(fav => fav.id === item.id);
              console.log(item.type)
              return (
                <FormationCard 
                  key={item.id} 
                  onClick={() => navigate(`/formation?id=${item.id}`)}
                >
                  {rank <= 3 ? renderTrophyIcon(rank) : <RankNumber>{rank}</RankNumber>}
                  <div style={{ width: "calc(100% - 170px)", flexGrow: 1 }}>
                    <FormationName>{item.nom_formation}</FormationName>
                    <FormationDetails>
                      <div>{item.type}</div>
                      {/* <div>{item.ville || ''}</div> */}
                    </FormationDetails>
                  </div>
                  <ScoreContainer>
                    <ScoreCircle>
                      {(() => {
                        const { svg, textColor } = renderScoreCircle(item.score_formations, index);
                        return (
                          <>
                            {svg}
                            <ScoreValue color={textColor}>{item.score_formations || 0}%</ScoreValue>
                          </>
                        );
                      })()}
                    </ScoreCircle>
                    <HeartIconClassement 
                      style={{
                        transform: pulsatingHeartId === item.id ? 'scale(1.3)' : 'scale(1)',
                        transition: 'transform 0.3s ease-in-out'
                      }}
                    >
                      <img 
                        src={isFavorite ? filledHeartIcon : emptyHeartIcon} 
                        alt={isFavorite ? "Favorite" : "Add to favorites"}
                        style={{ 
                          cursor: 'pointer',
                          animation: pulsatingHeartId === item.id ? 'pulse 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)' : 'none'
                        }}
                        onClick={(e) => toggleFavorite(e, item)}
                      />
                    </HeartIconClassement>
                  </ScoreContainer>
                </FormationCard>
              );
            })}
          </FormationsRankingList>
        ) : (
          <EmptyStateContainer>
            <div>Aucune formation trouvée pour les filtres :</div>
            {searchKeyword.trim() !== "" && (
              <FilterLine>
                Recherche : {searchKeyword}
                <ClearFilterButton onClick={() => {
                  localStorage.setItem('searchKeywordFormation', '');
                  localStorage.setItem('need_update_ranking_formation', 'true');
                  fetchFormationsRanking();
                }}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
            {selectedCitiesFormations.length > 0 && (
              <FilterLine>
                Villes / Écoles : {selectedCitiesFormations.slice(0, 5).join(" ou ")}
                {selectedCitiesFormations.length > 5 && " ..."}
                <ClearFilterButton onClick={() => setSelectedCitiesFormations([])}>
                  Effacer
                </ClearFilterButton>
              </FilterLine>
            )}
          </EmptyStateContainer>
        )}
      </ScrollableRankings>
    </ContentWrapper>
  );
}

export default FormationClassementTypeFormation;