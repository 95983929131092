import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import mon_profil_icon from '../../../assets/mon_profil_icon.png';

// User type icons
import secondeIcon from '../../../assets/seconde-icon.png';
import premiereIcon from '../../../assets/premiere-icon.png';
import terminaleIcon from '../../../assets/terminale-icon.png';
import etudiantIcon from '../../../assets/students-icon.png';
import parentIcon from '../../../assets/family-icon.png';
import otherIcon from '../../../assets/other-icon.png';

// Formation type icons
import universite_professionnalisante_icon from '../../../assets/universite_professionnalisante_icon.png';
import classe_professionnalisante_icon from '../../../assets/classe_professionnalisante_icon.png';
import grande_ecole_post_bac_icon from '../../../assets/grande_ecole_post_bac_icon.png';
import universite_theorique_icon from '../../../assets/universite_theorique_icon.png';
import classe_preparatoire_icon from '../../../assets/classe_preparatoire_icon.png';
import dont_know_icon from '../../../assets/dont_know_icon.png';

// Bac type icons
import general_icon from '../../../assets/general_bac.png';
import tech_icon from '../../../assets/tech_bac.png';
import pro_icon from '../../../assets/professional_bac.png';

// Domain icons
import arts_spectacle_icon from '../../../assets/spectacle_icon.png';
import audiovisuel_icon from '../../../assets/audiovisuel_icon.png';
import dessin_icon from '../../../assets/dessin_icon.png';
import design_specific_icon from '../../../assets/design_icon.png';
import culture_patimoine_icon from '../../../assets/arts_culture_icon.png';
import mode_textile_icon from '../../../assets/mode_icon.png';
import luxe_specific_icon from '../../../assets/luxe_icon.png';
import artisanat_specific_icon from '../../../assets/artisanat_icon.png';
import sport_management_icon from '../../../assets/sport_icon.png';
import teaching_specific_icon from '../../../assets/teacher_icon.png';
import health_medical_icon from '../../../assets/sante_sport_icon.png';
import working_with_children_icon from '../../../assets/children_icon.png';
import esport_specific_icon from '../../../assets/esport_icon.png';
import tourisme_specific_icon from '../../../assets/tourisme_icon.png';
import droit_justice_icon from '../../../assets/droit_politique_icon.png';
import fonction_publique_specific_icon from '../../../assets/parametres_site.png';
import humanitaire_specific_icon from '../../../assets/help_icon.png';
import journalisme_specific_icon from '../../../assets/journalisme_icon.png';
import communication_specific_icon from '../../../assets/media_icon.png';
import social_specific_icon from '../../../assets/help_icon.png';
import security_army_icon from '../../../assets/armée_icon.png';
import entrepreneurship_icon from '../../../assets/entrepreneurship_icon.png';
import book_industry_icon from '../../../assets/book_industry_icon.png';
import psychology_sociology_icon from '../../../assets/psychology_icon.png';
import langues_translation_icon from '../../../assets/llcer_icon.png';
import commerce_management_icon from '../../../assets/commerce_management_icon.png';
import hospitality_icon from '../../../assets/sante_sport_icon.png';
import transport_icon from '../../../assets/transport_icon.png';
import math_physics_icon from '../../../assets/physics_icon.png';
import finance_specific_icon from '../../../assets/ses_icon.png';
import banque_assurance_icon from '../../../assets/banque_assurance_icon.png';
import accounting_audit_icon from '../../../assets/accounting_audit_icon.png';
import informatics_icon from '../../../assets/nsi_icon.png';
import cybersecurity_specific_icon from '../../../assets/cybersecurity_specific_icon.png';
import nuclear_specific_icon from '../../../assets/nuclear_specific_icon.png';
import internet_web_icon from '../../../assets/internet_web_icon.png';
import gaming_specific_icon from '../../../assets/gaming_specific_icon.png';
import blockchain_metaverse_icon from '../../../assets/blockchain_metaverse_icon.png';
import chemistry_biology_icon from '../../../assets/sciences_icon.png';
import energy_specific_icon from '../../../assets/energy_specific_icon.png';
import industry_quality_icon from '../../../assets/industry_quality_icon.png';
import environment_icon from '../../../assets/dev_durable_icon.png';
import animals_specific_icon from '../../../assets/animals_specific_icon.png';
import agriculture_specific_icon from '../../../assets/agriculture_specific_icon.png';
import marine_specific_icon from '../../../assets/marine_specific_icon.png';
import wine_industry_icon from '../../../assets/wine_industry_icon.png';
import architecture_icon from '../../../assets/architecture_icon.png';
import automobile_icon from '../../../assets/automobile_icon.png';
import btp_icon from '../../../assets/btp_icon.png';
import electronics_icon from '../../../assets/electronics_icon.png';
import aeronautics_icon from '../../../assets/aeronautics_icon.png';
import resources_human_icon from '../../../assets/resources_human_icon.png';
import advertising_icon from '../../../assets/advertising_icon.png';
import real_estate_icon from '../../../assets/real_estate_icon.png';
import culinary_specific_icon from '../../../assets/culinary_specific_icon.png';
import moyenne_croissance_icon from '../../../assets/moyenne_croissante_icon.png';

// Specialties icons
import artsIcon from '../../../assets/arts_culture_icon.png';
import eppcsIcon from '../../../assets/sport_icon.png';
import hggspIcon from '../../../assets/hggsp_icon.jpeg';
import hlpIcon from '../../../assets/hlp_icon.png';
import llcerIcon from '../../../assets/llcer_icon.png';
import lcaIcon from '../../../assets/lca_icon.png';
import mathIcon from '../../../assets/math_icon.png';
import nsiIcon from '../../../assets/informatique_icon.png';
import physChimIcon from '../../../assets/physics_icon.png';
import svtIcon from '../../../assets/svt_icon.png';
import siIcon from '../../../assets/engineer_icon.png';
import sesIcon from '../../../assets/ses_icon.png';
import bioEcoIcon from '../../../assets/bioeco_icon.png';

import {
  DesktopProfileContainer,
  ProfileIconWrapper,
  ProfileIcon,
  RowContainer,
  Column,
  SectionTitle,
  RecapContainer,
  StatBox,
  StatNumber,
  StatLabel,
  LargeBoxContainer,
  SwipeBox,
  QuizIcon,
  SwipeText,
  ItemBox,
  ItemIcon,
  ItemText,
  ItemSeparator,
  ScoreBox,
  ScoreText,
  SliderContainer,
  SliderValue,
  GradeSlider,
  ConfirmGradeButton,
  PseudoTextArea,
  ConfirmButton,
  MessageStatus,
} from './styles';

import apiCall from '../../../utils/api';

/** Helper to format Bac type labels */
const getBacLabel = (type) => {
  const bacLabels = {
    general: 'Général',
    technologique: 'Technologique',
    professionnel: 'Professionnel',
  };
  return bacLabels[type] || type;
};

/** Helper to format Speciality labels */
const getSpecialityLabel = (type) => {
  const specialityLabels = {
    arts: 'Arts',
    eppcs: 'EPPCS',
    hggsp: 'HGGSP',
    hlp: 'HLP',
    llcer: 'LLCER',
    lca: 'LLCA',
    math: 'Mathématiques',
    nsi: 'NSI',
    physchim: 'Physique-Chimie',
    svt: 'SVT',
    si: 'SI',
    ses: 'SES',
    bioeco: 'Biologie-Ecologie',
    general: 'Moyenne générale',
  };
  return specialityLabels[type] || type;
};

/** Helper to format user type labels */
const getUserTypeLabel = (type) => {
  const userLabels = {
    seconde: 'Seconde',
    premiere: 'Première',
    terminale: 'Terminale',
    etudiant: 'Étudiant',
    parent: 'Parent',
    autre: 'Autre',
  };
  return userLabels[type] || type;
};

/** Picks the correct icon depending on type/category */
const getIconForType = (type, category) => {
  if (!type) return null;

  const iconMap = {
    formation: {
      classe_preparatoire: classe_preparatoire_icon,
      universite_theorique: universite_theorique_icon,
      grande_ecole_post_bac: grande_ecole_post_bac_icon,
      classe_professionnalisante: classe_professionnalisante_icon,
      universite_professionnalisante: universite_professionnalisante_icon,
      dont_know: dont_know_icon,
    },
    bac: {
      general: general_icon,
      technologique: tech_icon,
      professionnel: pro_icon,
    },
    user: {
      seconde: secondeIcon,
      premiere: premiereIcon,
      terminale: terminaleIcon,
      etudiant: etudiantIcon,
      parent: parentIcon,
      autre: otherIcon,
    },
    domain: {
      'Arts du Spectacle': arts_spectacle_icon,
      'Audiovisuel - Cinéma - Photographie': audiovisuel_icon,
      'Communication visuelle - Dessin - Animation 3D': dessin_icon,
      'Design': design_specific_icon,
      "Marché de l'art - Culture - Patrimoine": culture_patimoine_icon,
      'Mode - Textile': mode_textile_icon,
      'Luxe': luxe_specific_icon,
      'Artisanat': artisanat_specific_icon,
      'Sport - Management sportif': sport_management_icon,
      'Enseignement': teaching_specific_icon,
      'Santé (Médical-Paramédical)': health_medical_icon,
      'Travailler avec des enfants': working_with_children_icon,
      'ESport - Gaming': esport_specific_icon,
      'Tourisme - Loisirs': tourisme_specific_icon,
      'Droit - Justice - Sciences Politiques': droit_justice_icon,
      'Fonction publique': fonction_publique_specific_icon,
      'Humanitaire': humanitaire_specific_icon,
      'Journalisme': journalisme_specific_icon,
      'Communication': communication_specific_icon,
      'Social': social_specific_icon,
      'Sécurité - Armée - Défense': security_army_icon,
      "Entrepreneuriat - Création d'Entreprise": entrepreneurship_icon,
      'Edition - Métiers du livre': book_industry_icon,
      'Psychologie - Sociologie': psychology_sociology_icon,
      'Langues étrangères - Traduction': langues_translation_icon,
      'Commerce - Distribution - Management': commerce_management_icon,
      'Hôtellerie - Restauration': hospitality_icon,
      'Transport - Logistique': transport_icon,
      'Mathématiques - Sciences Physiques': math_physics_icon,
      'Finance': finance_specific_icon,
      'Banque - Assurance': banque_assurance_icon,
      'Comptabilité - Gestion - Audit': accounting_audit_icon,
      'Informatique - Numérique': informatics_icon,
      'Cybersécurité': cybersecurity_specific_icon,
      'Le nucléaire': nuclear_specific_icon,
      'Internet - Web - Multimédia': internet_web_icon,
      'Jeux vidéo': gaming_specific_icon,
      'Métiers et Formations de la Blockchain & du Métavers': blockchain_metaverse_icon,
      'Chimie - Biologie': chemistry_biology_icon,
      'Énergie': energy_specific_icon,
      'Industrie - Qualité': industry_quality_icon,
      'Environnement - Développement durable': environment_icon,
      'Métiers animaliers': animals_specific_icon,
      'Agriculture': agriculture_specific_icon,
      'Agroalimentaire': chemistry_biology_icon,
      'Métiers de la mer': marine_specific_icon,
      'Métiers du vin': wine_industry_icon,
      'Architecture - Urbanisme - Paysagisme': architecture_icon,
      'Automobile - Moto': automobile_icon,
      'Bâtiment et travaux publics (BTP)': btp_icon,
      'Électronique': electronics_icon,
      'Aéronautique et aérospatial': aeronautics_icon,
      'Ressources humaines': resources_human_icon,
      'Publicité - Marketing': advertising_icon,
      'Immobilier': real_estate_icon,
      'Métiers de bouche': culinary_specific_icon,
    },
    speciality: {
      arts: artsIcon,
      eppcs: eppcsIcon,
      hggsp: hggspIcon,
      hlp: hlpIcon,
      llcer: llcerIcon,
      lca: lcaIcon,
      math: mathIcon,
      nsi: nsiIcon,
      physchim: physChimIcon,
      svt: svtIcon,
      si: siIcon,
      ses: sesIcon,
      bioeco: bioEcoIcon,
      general: moyenne_croissance_icon,
    },
  };

  return iconMap[category]?.[type] || null;
};

const Profile = () => {
  const navigate = useNavigate();
  const { user, loading, error } = useAuth();
  // nav
  // const navigate = useNavigate();
  
  const [newPseudo, setNewPseudo] = useState(user?.pseudo || '');
  const [isEditingPseudo, setIsEditingPseudo] = useState(false);

  // For adjusting a specialty grade
  const [expandedSpec, setExpandedSpec] = useState(null);
  const [sliderValue, setSliderValue] = useState(10);

  const { checkAuth } = useAuth();
  const handleFormationsClick = () => {
    navigate('/formations-favorites');
  };
  
  const handleFilieresClick = () => {
    navigate('/filieres-favorites');
  };
  // Quick stats
  const formationsCount = user?.formations?.length || 0;
  const typesCount = user?.types_formations?.length || 0;
  const messagesCount = user?.number_of_messages_sent || 0;
  const cardsVisited = user?.number_of_cards_visited || 0;

  // All domains (no more "Voir plus")
  const allDomains = Array.isArray(user?.sub_domaine_formation)
    ? user.sub_domaine_formation
    : [];

  // Specialties
  const maxSpecialites = 3;
  const userSpecialites = user?.specialites
    ? user.specialites.slice(0, maxSpecialites)
    : [];
  // We always show "general" (moyenne générale) as well
  const specialtiesWithGeneral = [...userSpecialites, 'general'];

  const handleScoreClick = (e, spec) => {
    e.stopPropagation();
    if (expandedSpec === spec) {
      setExpandedSpec(null);
    } else {
      const currentGrade = user?.grades?.[spec] ?? 10;
      setSliderValue(currentGrade);
      setExpandedSpec(spec);
    }
  };

  const handleScoreConfirm = async (spec) => {
    try {
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`,
        'PATCH',
        { grades: { [spec]: sliderValue } }
      );
      if (!user.grades) user.grades = {};
      user.grades[spec] = sliderValue;
      setExpandedSpec(null);

      localStorage.setItem('need_update_ranking_formation', 'true');
      localStorage.setItem('need_update_ranking', 'true');
      await checkAuth();
    } catch (err) {
      console.error('Error updating grade:', err);
    }
  };

  const handleUpdatePseudo = async () => {
    if (!newPseudo) return;
    try {
      await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`,
        'PATCH',
        { pseudo: newPseudo }
      );
      user.pseudo = newPseudo;
      setIsEditingPseudo(false);
    } catch (error) {
      console.error('Error updating pseudo:', error);
    }
  };

  const handleSwipeClick = () => {
    navigate('/profile/modify/swipe');
  };

  if (loading) {
    return (
      <DesktopProfileContainer>
        <MessageStatus>Chargement...</MessageStatus>
      </DesktopProfileContainer>
    );
  }

  if (error) {
    return (
      <DesktopProfileContainer>
        <MessageStatus error>{error}</MessageStatus>
      </DesktopProfileContainer>
    );
  }

  if (!user) {
    return (
      <DesktopProfileContainer>
        <MessageStatus>Aucun utilisateur connecté.</MessageStatus>
      </DesktopProfileContainer>
    );
  }

  return (
    <DesktopProfileContainer>
      {/* Handle loading, error, or missing user */}
      {loading ? (
        <MessageStatus>Chargement...</MessageStatus>
      ) : error ? (
        <MessageStatus error>{error}</MessageStatus>
      ) : !user ? (
        <MessageStatus>Aucun utilisateur connecté.</MessageStatus>
      ) : (
        <>
          {/* Profile icon at the top */}
          <ProfileIconWrapper>
            <ProfileIcon src={mon_profil_icon} alt="Mon Profil" />
          </ProfileIconWrapper>
  
          {/* Main two-column layout */}
          <RowContainer>
            {/* Left Column: Récap -> Mes swipes -> Mes domaines favoris */}
            <Column>
              <SectionTitle>Récap</SectionTitle>
              <RecapContainer>
                <StatBox onClick={handleFormationsClick} bgColor="#0066FF">
                  <StatNumber>{formationsCount}</StatNumber>
                  <StatLabel>
                    {formationsCount === 1 ? 'voeu' : 'voeux'}
                  </StatLabel>
                </StatBox>
                <StatBox onClick={handleFilieresClick} bgColor="#FF8000">
                  <StatNumber>{typesCount}</StatNumber>
                  <StatLabel>
                    {typesCount === 1 ? 'type favori' : 'types favoris'}
                  </StatLabel>
                </StatBox>
                <StatBox bgColor="#0FE43D">
                  <StatNumber>{messagesCount}</StatNumber>
                  <StatLabel>
                    {messagesCount === 1 ? 'message envoyé' : 'messages envoyés'}
                  </StatLabel>
                </StatBox>
                <StatBox bgColor="#FFDD00">
                  <StatNumber>{cardsVisited}</StatNumber>
                  <StatLabel>
                    {cardsVisited === 1 ? 'fiche visitée' : 'fiches visitées'}
                  </StatLabel>
                </StatBox>
              </RecapContainer>
  
              <SectionTitle>Mes swipes</SectionTitle>
              <SwipeBox onClick={handleSwipeClick}>
                <QuizIcon />
                <SwipeText>Voir mes swipes</SwipeText>
              </SwipeBox>
  
              <SectionTitle>Mes domaines favoris</SectionTitle>
              <LargeBoxContainer
                onClick={() => navigate('/profile/modify/domaines-favoris')}
              >
                {allDomains.map((domain, idx) => {
                  const iconUrl = getIconForType(domain, 'domain');
                  return (
                    <React.Fragment key={domain + idx}>
                      <ItemBox>
                        {iconUrl && <ItemIcon bgImage={iconUrl} />}
                        <ItemText>{domain}</ItemText>
                      </ItemBox>
                      {idx < allDomains.length - 1 && <ItemSeparator />}
                    </React.Fragment>
                  );
                })}
              </LargeBoxContainer>
            </Column>
  
            {/* Right Column: Mes spécialités -> Mes infos */}
            <Column>
              <SectionTitle>Mes spécialités</SectionTitle>
              <LargeBoxContainer>
                {specialtiesWithGeneral.map((spec, idx) => {
                  const iconUrl = getIconForType(spec, 'speciality');
                  const currentGrade =
                    user?.grades?.[spec] !== undefined
                      ? user.grades[spec]
                      : '?';
                  return (
                    <React.Fragment key={spec + idx}>
                      <ItemBox
                        onClick={() => navigate('/profile/modify/specialites')}
                      >
                        {iconUrl && <ItemIcon bgImage={iconUrl} />}
                        <ItemText>{getSpecialityLabel(spec)}</ItemText>
                        <ScoreBox
                          onClick={(e) => handleScoreClick(e, spec)}
                          title="Cliquez pour ajuster la note"
                        >
                          <ScoreText>{currentGrade}/20</ScoreText>
                        </ScoreBox>
                      </ItemBox>
                      {idx < specialtiesWithGeneral.length - 1 && (
                        <ItemSeparator />
                      )}
                      {expandedSpec === spec && (
                        <SliderContainer>
                          <SliderValue>{sliderValue}</SliderValue>
                          <GradeSlider
                            type="range"
                            min="0"
                            max="20"
                            step="0.5"
                            value={sliderValue}
                            onChange={(e) =>
                              setSliderValue(Number(e.target.value))
                            }
                          />
                          <ConfirmGradeButton
                            onClick={() => handleScoreConfirm(spec)}
                          >
                            Valider
                          </ConfirmGradeButton>
                        </SliderContainer>
                      )}
                    </React.Fragment>
                  );
                })}
              </LargeBoxContainer>
  
              <SectionTitle>Mes infos</SectionTitle>
              <LargeBoxContainer>
                {/* Bac type */}
                <ItemBox
                  onClick={() => navigate('/profile/modify/type-bac')}
                  style={{ cursor: 'pointer' }}
                >
                  <ItemIcon bgImage={getIconForType(user.type_bac, 'bac')} />
                  <ItemText>
                    {getBacLabel(user.type_bac) || 'Bac non spécifié'}
                  </ItemText>
                </ItemBox>
                <ItemSeparator />
  
                {/* User type */}
                <ItemBox
                  onClick={() => navigate('/profile/modify/user-type')}
                  style={{ cursor: 'pointer' }}
                >
                  <ItemIcon bgImage={getIconForType(user.user_type, 'user')} />
                  <ItemText>
                    {getUserTypeLabel(user.user_type) || 'Type non spécifié'}
                  </ItemText>
                </ItemBox>
                <ItemSeparator />
  
                {/* Pseudo */}
                <ItemBox>
                  <ItemIcon
                    bgImage={require('../../../assets/pseudo_icon.png')}
                  />
                  {isEditingPseudo ? (
                    <>
                      <PseudoTextArea
                        value={newPseudo}
                        onChange={(e) => setNewPseudo(e.target.value)}
                        placeholder="Entrez votre pseudo"
                      />
                      <ConfirmButton onClick={handleUpdatePseudo}>
                        Valider
                      </ConfirmButton>
                    </>
                  ) : (
                    <ItemText
                      onClick={() => setIsEditingPseudo(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      {user.pseudo || 'Pseudo non spécifié'}
                    </ItemText>
                  )}
                </ItemBox>
              </LargeBoxContainer>
            </Column>
          </RowContainer>
        </>
      )}
    </DesktopProfileContainer>
  );  
};

export default Profile;
