// components/DownloadAndroidPopup/styles.js
import styled, { keyframes } from 'styled-components';

/* The popup container fixed at the bottom of the screen.
   It uses a transform transition to slide out when dismissed. */
const slideOutAnimation = keyframes`
   from { opacity: 1; transform: translateY(0); }
   to { opacity: 0; transform: translateY(100%); }
 `;

export const PopupContainer = styled.div`
  position: fixed;
  bottom: -10%;
  left: -5%;
  width: 110%;
  padding: 10px;
  z-index: 1000;
  transition: transform 0.3s ease;
  animation: ${({ $slideOut }) => $slideOut ? slideOutAnimation : 'none'} 300ms forwards;
`;

/* The main background box for the popup */
export const BackgroundBox = styled.div`
  width: 100%;
  position: relative;
  border-radius: 30px;
  background-color: #fff;
  border: 2px solid #e6e6e6;
  box-sizing: border-box;
  height: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;
`;

/* Top button styling: "Continuer sur navigateur" */
export const TopButton = styled.button`
  width: 90%;
  position: relative;
  border-radius: 15px;
  background-color: #ff2768;
  height: 53px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease;
  box-shadow: ${props => props.active ? 'none' : '0px 4px 0px #d42359'};
  transform: ${props => props.active ? 'translateY(4px)' : 'none'};
`;

export const TopButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #fff;
  text-align: center;
`;

/* Bottom button styling: "Télécharger Meoria" */
export const BottomButton = styled.button`
  width: 90%;
  position: relative;
  margin-bottom: 20%;
  border-radius: 15px;
  background-color: #fff;
  border: 2px solid rgba(217, 215, 215, 1);
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease;
  box-shadow: ${props => props.active ? 'none' : '0px 2px 0px 0px rgba(217, 215, 215, 1)'};
  transform: ${props => props.active ? 'translateY(4px)' : 'none'};
`;

export const BottomButtonText = styled.span`
  font-size: 20px;
  font-weight: 600;
  font-family: 'Gabarito', sans-serif;
  color: #1db1f7;
  text-align: center;
`;

/* Icon container for the blue Play Store icon */
export const IconContainer = styled.div`
  width: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;

  img {
    height: 39px;
    object-fit: contain;
  }
`;
