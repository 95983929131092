import styled, { css, keyframes } from 'styled-components';
import theme from '../../../theme';

/* --------------------------------
   Container & Layout
----------------------------------- */

export const Container = styled.div`
  /* Center this container in the screen (desktop) */
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 12px;
  margin-left: 10%;

  @media (max-width: 800px) {
    margin-left: 5%;
    width: 85%;
  }
`;

export const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.medium};
  overflow: auto; /* we control scrolling inside ChatHistory */
  
  scrollbar-width: thin;
  scrollbar-color:rgb(241, 241, 241) #FAFAFA;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color:f1f1f1;
  }
`;

export const ChatBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: transparent;
  
`;

export const ChatHistory = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: ${theme.spacing.small};
  /* Just some extra spacing at the bottom so messages aren’t hidden behind the input */
  padding-bottom: 0rem;
  
`;

/* --------------------------------
   Messages
----------------------------------- */

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props =>
    props.$user === 'You' ? '0.75rem 1rem' : '0.2rem 0.5rem 0.5rem'};
  margin-bottom: ${props => (props.$user === 'You' ? '1.25rem' : '1.5rem')};
  border-radius: ${props => (props.$user === 'You' ? '1.2rem' : '1rem')};
  background-color: ${props =>
    props.$user === 'You' ? props.theme.colors.veryLightGray : 'transparent'};
  box-shadow: ${props =>
    props.$user === 'You' ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'};
  width: ${props => (props.$user === 'You' ? 'fit-content' : '95%')};
  max-width: ${props => (props.$user === 'You' ? '75%' : '100%')};
  margin-left: ${props => (props.$user === 'You' ? 'auto' : '0')};
  font-family: 'Gabarito', sans-serif;
  font-size: ${theme.fontSizes.medium};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MarkdownBox = styled.div`
  overflow-x: auto;
  min-height: 20px;
  transition: all 0.2s ease-in-out;

  & > * {
    margin-bottom: 0.625rem;
    animation: ${fadeIn} 0.3s ease-out;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  /* Tables */
  & table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }

  & th,
  & td {
    border: 1px solid ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.small};
    text-align: left;
  }

  & th {
    background-color: ${props => props.theme.colors.background};
    font-weight: bold;
  }

  & tr:nth-child(even) {
    background-color: ${props => props.theme.colors.background};
  }

  /* Images */
  & img {
    max-width: 100%;
    height: auto;
  }

  /* Code blocks */
  & code {
    background-color: ${props => props.theme.colors.background};
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
    font-family: 'Gabarito', sans-serif;
  }

  & pre {
    background-color: ${props => props.theme.colors.background};
    padding: ${props => props.theme.spacing.medium};
    border-radius: 4px;
    overflow-x: auto;
  }
`;

/* --------------------------------
   Input Area
----------------------------------- */

export const InputAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent; /* Make it transparent as requested */
  width: 100%;
  padding: ${theme.spacing.medium} ${theme.spacing.small};
  /* 
    "margin-top: auto" helps push this section to
    the bottom, if Container is display:flex; flex-direction: column;
  */
  margin-bottom: 0rem;
  opacity: ${props => (props.isDisabled ? 0.6 : 1)};
  transition: opacity 0.2s ease;
`;

export const InputArea = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  width: 50%; /* or any narrower width you want for centering */
  min-width: 300px;
  justify-content: center;
`;

export const Input = styled.textarea`
  flex: 1;
  padding: 0.75rem 3rem 0.75rem 1rem;
  border: 1px solid #ffb6c1;
  border-radius: 25px;
  font-size: 1rem;
  font-family: 'Gabarito', sans-serif;
  line-height: 1.5;
  resize: none;
  background-color: #fff0f5;
  color: ${props => props.theme.colors.text};
  min-height: 50px;
  max-height: 150px;
  width: 100%;
  outline: none;
  transition: all 0.2s ease;
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};

  &:focus {
    border-color: #ffb6c1;
    background-color: white;
  }

  &::placeholder {
    color: ${props => props.theme.colors.mediumGray};
  }
`;

export const SendButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  position: absolute;
  right: 0.75rem;
  bottom: 50%;
  transform: translateY(50%);
  transition: all 0.2s ease-in-out;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  svg {
    transform: translateY(-1px);
    transition: transform 0.2s ease;
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary}ee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

    svg {
      transform: translateY(-2px);
    }
  }

  &:active {
    transform: translate(0, 48%);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary}33;
  }

  ${props =>
    props.show &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}

  &:disabled {
    background-color: ${props => props.theme.colors.primary}99;
    cursor: not-allowed;
  }
`;

/* --------------------------------
   Subtitle / "careful" message
----------------------------------- */

export const SubtitleText = styled.div`
  margin-top: 0.75rem;
  color: #7f7c7c;
  font-family: Gabarito, sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  width: 50%; /* align with InputArea width for nice centering */
`;

/* --------------------------------
   Sources
----------------------------------- */

export const SourcesContainer = styled.div`
  padding: 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SourceLink = styled.a`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4px 7.5px;
  color: #1565c0;
  text-decoration: none;
  font-size: 0.9em;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }
`;

/* --------------------------------
   Loading Indicator
----------------------------------- */
export const LoadingMessage = styled.div`
  display: flex;
  padding: 0.8rem 1.2rem;
  width: fit-content;
  align-items: flex-start;
  background-color: transparent;
  box-shadow: none;
`;

export const LoadingText = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.text || '#333'};
  font-family: Gabarito, sans-serif;
  font-style: italic;

  &::after {
    content: '';
    animation: loadingDots 0.8s infinite;
  }

  @keyframes loadingDots {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`;

/* --------------------------------
   Welcome Menu Wrapper
----------------------------------- */
export const WelcomeMenuWrapper = styled.div`
  width: 50%;
  height: 100%;
  margin-left: 25%;
`;
