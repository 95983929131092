import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  LogoImage,
  ChatBubbleContainer,
  ChatText,
  OptionsContainer,
  ButtonsWrapper,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  OptionsScrollContainer
} from './styles';
// import { PrimaryButton } from '../SecundPage/styles';

import {speIconMapProfessionnel} from './domainIconMap';

// Exemple d'image de logo (à adapter)
import compassImage from '../../assets/meo_write.png';

// Importation des icônes sélectionnées dans la liste fournie
// import energyIcon from '../../assets/energy_specific_icon.png';
// import santeIcon from '../../assets/sante_sport_icon.png';
// import agricultureIcon from '../../assets/agriculture_specific_icon.png';
// import btpIcon from '../../assets/btp_icon.png';
// import architectureIcon from '../../assets/architecture_icon.png';
// import childrenIcon from '../../assets/children_icon.png';
// import artisanatIcon from '../../assets/artisanat_icon.png';
// import artsCultureIcon from '../../assets/arts_culture_icon.png';
// import advertisingIcon from '../../assets/advertising_icon.png';
// import aeronauticsIcon from '../../assets/aeronautics_icon.png';
// import electronicsIcon from '../../assets/electronics_icon.png';
// import bioecoIcon from '../../assets/bioeco_icon.png';
// import culinaryIcon from '../../assets/culinary_specific_icon.png';
// import transportIcon from '../../assets/transport_icon.png';
// import marineIcon from '../../assets/marine_specific_icon.png';
// import devDurableIcon from '../../assets/dev_durable_icon.png';
// import physchimIcon from '../../assets/physchim_icon.png';
// import industryQualityIcon from '../../assets/industry_quality_icon.png';
// import icon3d from '../../assets/3d_icon.png';
// import commerceManagementIcon from '../../assets/commerce_management_icon.png';
// import cybersecurityIcon from '../../assets/cybersecurity_specific_icon.png';
// import modeIcon from '../../assets/mode_icon.png';
// import audiovisuelIcon from '../../assets/audiovisuel_icon.png';
// import informatiqueIcon from '../../assets/informatique_icon.png';
// import medecineIcon from '../../assets/medecine_icon.png';
// import nuclearIcon from '../../assets/nuclear_specific_icon.png';
// import localisationIcon from '../../assets/localisation_icon.png';
// import designIcon from '../../assets/design_icon.png';
import { useAuth } from '../../contexts/AuthContext';
import apiCall from '../../utils/api';
import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';

const BacProfessionnel = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const [selectedSpe, setSelectedSpe] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const { isMobile } = useDeviceType();
  const { checkAuth } = useAuth();

  // For the “typing” effect
  useEffect(() => {
    const fullText = "Sélectionne ta spécialité de bac professionnel ! Scroll vers le bas !";
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);

    return () => clearInterval(typingInterval);
  }, []);

  // Pointer states for visual “pressed” effect
  const [activeOption, setActiveOption] = useState(null);
  const [submitActive, setSubmitActive] = useState(false);

  // Immediately select/deselect on click
  const handleOptionClick = (value) => {
    setSelectedSpe(prev => (prev === value ? '' : value));
  };

  const isSubmitEnabled = selectedSpe !== '' && !isTyping;

  // Submit logic
  const handleSubmit = async () => {
    if (!isSubmitEnabled) return;
    setIsLoading(true);
    try {
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          specialites: [selectedSpe]
        });
        let userData = JSON.parse(localStorage.getItem('user'));
        userData.specialites = [selectedSpe];
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile/modify/grades');
      } else {
        localStorage.setItem('specialites', JSON.stringify([selectedSpe]));
        navigate('/general-moyenne');
      }
    } catch (error) {
      console.error('Error saving spe to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // “Press” (down) / release (up) only for the visual effect
  const handleOptionPressStart = (value) => {
    setActiveOption(value);
  };
  const handleOptionPressEnd = () => {
    setActiveOption(null);
  };

  // Submit Button pointer handlers
  const handleSubmitPressStart = () => {
    if (!isSubmitEnabled) return;
    setSubmitActive(true);
  };
  const handleSubmitPressEnd = () => {
    setSubmitActive(false);
  };
  const handleSubmitClick = () => {
    handleSubmit();
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate('/type-bac');
    }
  };

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
      {!modifyProfile && <ProgressBar startPercent={55} endPercent={65} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <OptionsScrollContainer  isMobile={isMobile}>
          <OptionsContainer>
            <ButtonsWrapper
              style={{
                opacity: isTyping ? 0 : 1,
                transition: 'opacity 0.3s ease',
                pointerEvents: isTyping ? 'none' : 'auto'
              }}
            >
              {speIconMapProfessionnel.map((option) => {
                const isSelected = (selectedSpe === option.value);
                return (
                  <OptionButton style={{ height: '90px', fontSize: '1rem' }}
                    key={option.value}
                    $isSelected={isSelected}
                    type="button"
                    aria-pressed={isSelected}
                    active={activeOption === option.value}
                    onMouseDown={() => handleOptionPressStart(option.value)}
                    onMouseUp={handleOptionPressEnd}
                    onMouseLeave={handleOptionPressEnd}
                    onClick={() => handleOptionClick(option.value)}
                    onTouchStart={() => handleOptionPressStart(option.value)}
                    onTouchEnd={handleOptionPressEnd}
                  >
                    <OptionIcon
                      src={option.icon}
                      alt=""
                      $isSelected={isSelected}
                    />
                    {option.value}
                  </OptionButton>
                );
              })}
            </ButtonsWrapper>
          </OptionsContainer>
        </OptionsScrollContainer>

      <SubmitButton
        type="button"
        disabled={!isSubmitEnabled || isTyping}
        style={{
          opacity: isSubmitEnabled ? 1 : 0.5,
          cursor: isSubmitEnabled ? 'pointer' : 'not-allowed',
          boxShadow: (!submitActive && isSubmitEnabled) ? '0px 4px 0px #d42359' : 'none'
        }}
        onMouseDown={handleSubmitPressStart}
        onMouseUp={handleSubmitPressEnd}
        onMouseLeave={() => setSubmitActive(false)}
        onClick={handleSubmitClick}
      >
        {isLoading ? 'Chargement...' : 'Continuer'}
      </SubmitButton>

      </ContentWrapper>
    </Container>
  );
};

export default BacProfessionnel;
