// src/utils/api.js
import { logout } from './auth';

// Optional: If you still want to fetch a CSRF token, you can keep fetchCSRFToken() logic
// But you do NOT need to manually attach the JWT anymore.

async function fetchCSRFToken() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/csrf/`, {
    credentials: 'include',  // to include cookies
  });
  if (response.ok) {
    const token = getCookie('csrftoken');
    console.log('CSRF Token:', token);
    return token;
  }
  throw new Error('Failed to fetch CSRF token');
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const apiCall = async (url, method = 'GET', body = null, headers = {}, requireCSRF = false) => {
  try {
    let csrfToken = null;
    if (requireCSRF) {
      csrfToken = await fetchCSRFToken();
    }

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(csrfToken && { 'X-CSRFToken': csrfToken }),
        ...headers,
      },
      // Always include credentials so the HttpOnly cookie is sent
      credentials: 'include',
    };

    console.log('options :', options);

    if (body && method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);

    const contentType = response.headers.get('content-type');
    const responseText = await response.text();

    console.log('Response status:', response.status);
    console.log('Response headers:', Object.fromEntries(response.headers.entries()));
    console.log('Response text:', responseText);

    // If token is invalid or expired, the server might return 401
    if (response.status === 401) {
      // Possibly call logout() to clear any local user info
      logout();
      throw new Error('Unauthorized (401) - Possibly invalid/expired token. Logged out.');
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}, message: ${responseText}`);
    }

    // If the response is JSON, parse it
    if (contentType && contentType.includes('application/json')) {
      try {
        return JSON.parse(responseText);
      } catch (parseError) {
        console.error('Error parsing JSON response:', parseError);
        return responseText; // fallback to raw text
      }
    } else {
      return responseText;
    }
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export default apiCall;
