import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
import FilteringBrowser from './FilteringBrowser';
import {
  Container,
  ContentWrapper,
  ReturnButton,
  SubmitButton,
  OptionsContainer,
  OptionButton,
  OptionIcon,
  LoadingOverlay,
  ChatBubbleContainer,
  ChatText,
  LogoImage,
  HorizontalScrollBox
} from './styles';

import compassImage from '../../assets/meo_write.png';
import ProgressBar from '../ProgressBar';
import useDeviceType from '../useDeviceType';
import { domainIconMapRaw } from './domainIconMap'; 
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import theme from '../../theme';

// Container grouping the search bar and selected domains
const BrowserAndSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -15%;
`;
const OptionsScrollContainer = styled.div`
  position: relative;
  left: 5%;
  width: 90%;
  height: calc(var(--vh, 1vh) * 47);
  overflow-y: auto;
  paddingBottom: 40px;
  
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.lightGray} transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.background};
    border-radius: 2px;
  }
`;

const normalizeString = (str) =>
  str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const DomainSelection = () => {
  const navigate = useNavigate();
  const url = window.location.href;
  const modifyProfile = url.includes('profile/modify');
  const { isMobile } = useDeviceType();
  const { checkAuth } = useAuth();

  const [selectedDomains, setSelectedDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(true);
  const [text, setText] = useState('');
  const fullText = "Choisis les domaines qui t'intéressent";
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setText(fullText.slice(0, currentIndex));
        currentIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, 15);
    return () => clearInterval(typingInterval);
  }, []);

  const toggleDomainSelection = (domainLabel) => {
    setSelectedDomains((prev) => {
      if (prev.includes(domainLabel)) {
        return prev.filter(item => item !== domainLabel);
      } else {
        return [...prev, domainLabel];
      }
    });
  };

  const handleSubmit = async () => {
    if (selectedDomains.length === 0 || isTyping) return;
    setIsLoading(true);
    try {
      if (modifyProfile) {
        await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/update/`, 'PATCH', {
          sub_domaine_formation: selectedDomains
        });
        let userData = JSON.parse(localStorage.getItem('user'));
        userData.sub_domaine_formation = selectedDomains;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('need_update_ranking', 'true');
        localStorage.setItem('need_update_ranking_formation', 'true');
        await checkAuth();
        navigate('/profile');
      } else {
        localStorage.setItem('sub_domaine_formation', JSON.stringify(selectedDomains));
        navigate('/lycee');
      }
    } catch (error) {
      console.error('Error saving domains to localStorage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (modifyProfile) {
      navigate('/profile');
    } else {
      navigate("/general-moyenne");
    }
  };

  const handleOptionClick = (domain, e) => {
    e.preventDefault();
    toggleDomainSelection(domain);
    setSearchText('');
  };

  // Filter domains based on search input
  const allDomains = Object.keys(domainIconMapRaw);
  const filteredDomains = searchText.trim() === ''
    ? allDomains
    : allDomains.filter(domain =>
        normalizeString(domain).includes(normalizeString(searchText))
      );

  return (
    <Container>
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>
      <ContentWrapper>
        {!modifyProfile && <ProgressBar startPercent={75} endPercent={85} />}
        <LogoImage src={compassImage} alt="Compass Logo" />
        <ChatBubbleContainer isMobile={isMobile}>
          <ChatText isVisible={text.length > 0}>
            <span>{text}</span>
          </ChatText>
        </ChatBubbleContainer>

        <BrowserAndSelectedContainer>
          <FilteringBrowser
            searchValue={searchText}
            onSearchChange={setSearchText}
          />
          {selectedDomains.length > 0 && (
            <HorizontalScrollBox style={{ marginTop: isMobile ? '5px' : '25px' }}>
              {selectedDomains.map(domain => (
                <div
                  key={`selected-${domain}`}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    padding: '5px 10px',
                    marginRight: '8px',
                    height: '100%',
                    gap: '8px',
                    backgroundColor: '#DCF3FF',
                    boxShadow: '0px 2px 2px rgba(217, 215, 215, 0.75)',
                    border: '2px solid rgba(56, 182, 255, 0.75)',
                    color: '#38B6FF',
                  }}
                >
                  <img
                    src={domainIconMapRaw[domain]}
                    alt={domain}
                    style={{
                      height: '80%',
                      objectFit: 'contain',
                      transition: 'transform 0.2s ease',
                    }}
                  />
                  <span style={{ fontFamily: "'Gabarito', sans-serif" }}>{domain}</span>
                  <IoMdClose
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDomainSelection(domain);
                    }}
                  />
                </div>
              ))}
            </HorizontalScrollBox>
          )}
        </BrowserAndSelectedContainer>

        <OptionsScrollContainer isMobile={isMobile}>
          <OptionsContainer style={{ paddingBottom: '60px' }}>
            {filteredDomains.map(domain => (
              <OptionButton
                key={domain}
                $isSelected={selectedDomains.includes(domain)}
                type="button"
                aria-pressed={selectedDomains.includes(domain)}
                onClick={(e) => handleOptionClick(domain, e)}
              >
                <OptionIcon
                  src={domainIconMapRaw[domain]}
                  alt={domain}
                  $isSelected={selectedDomains.includes(domain)}
                />
                {domain}
              </OptionButton>
            ))}
          </OptionsContainer>
        </OptionsScrollContainer>

        <SubmitButton
          type="button"
          disabled={isLoading || isTyping || selectedDomains.length === 0}
          style={{
            opacity: (isTyping || selectedDomains.length === 0) ? 0.5 : 1,
            cursor: (isTyping || selectedDomains.length === 0) ? 'not-allowed' : 'pointer'
          }}
          onClick={(e) => { e.preventDefault(); handleSubmit(); }}
        >
          {isLoading ? 'Chargement...' : 'Continuer'}
        </SubmitButton>
      </ContentWrapper>
    </Container>
  );
};

export default DomainSelection;
