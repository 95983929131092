// src/utils/PushSubscriptionManager.js

/**
 * Manages push subscriptions for the application
 * Handles creation, storage, and synchronization of push subscriptions with the backend
 */
class PushSubscriptionManager {
    constructor() {
        this.initialized = false;
        this.subscription = null;
        // Get the VAPID public key from environment variables
        this.publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
    }

    async init() {
        if (this.initialized) return;

        try {
            // Check if push notifications are supported
            if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
                console.warn('Push notifications are not supported');
                return;
            }

            // Make sure we have a VAPID key
            if (!this.publicVapidKey) {
                console.warn('VAPID public key is missing. Push subscriptions will not work.');
                return;
            }

            // Check if we have a subscription
            const registration = await navigator.serviceWorker.ready;
            this.subscription = await registration.pushManager.getSubscription();

            // If we have a subscription, sync it with the server
            if (this.subscription) {
                await this.syncSubscriptionWithServer();
            }

            this.initialized = true;
        } catch (error) {
            console.error('Error initializing push subscription:', error);
        }
    }

    async subscribe() {
        try {
            if (!this.initialized) await this.init();

            // Make sure we have a VAPID key
            if (!this.publicVapidKey) {
                console.error('Cannot subscribe: VAPID public key is missing');
                return null;
            }

            const registration = await navigator.serviceWorker.ready;
            
            // Get permission
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                throw new Error('Notification permission denied');
            }

            // Subscribe to push notifications
            this.subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(this.publicVapidKey)
            });

            // Save subscription to server
            await this.syncSubscriptionWithServer();

            return this.subscription;
        } catch (error) {
            console.error('Error subscribing to push notifications:', error);
            return null;
        }
    }

    async unsubscribe() {
        try {
            if (!this.subscription) return false;

            // Unsubscribe from push notifications
            await this.subscription.unsubscribe();
            this.subscription = null;

            // Remove subscription from server
            await this.removeSubscriptionFromServer();

            return true;
        } catch (error) {
            console.error('Error unsubscribing from push notifications:', error);
            return false;
        }
    }

    async isSubscribed() {
        try {
            if (!this.initialized) await this.init();
            return !!this.subscription;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    }

    async syncSubscriptionWithServer() {
        try {
            if (!this.subscription) return false;

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/push/subscribe/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    subscription: {
                        endpoint: this.subscription.endpoint,
                        keys: {
                            p256dh: this.arrayBufferToBase64(this.subscription.getKey('p256dh')),
                            auth: this.arrayBufferToBase64(this.subscription.getKey('auth'))
                        }
                    }
                })
            });

            return response.ok;
        } catch (error) {
            console.error('Error syncing subscription with server:', error);
            return false;
        }
    }

    async removeSubscriptionFromServer() {
        try {
            if (!this.subscription) return false;

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/push/unsubscribe/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    endpoint: this.subscription.endpoint
                })
            });

            return response.ok;
        } catch (error) {
            console.error('Error removing subscription from server:', error);
            return false;
        }
    }

    // Utility functions for key conversion
    urlBase64ToUint8Array(base64String) {
        if (!base64String) {
            console.error('No base64 string provided to urlBase64ToUint8Array');
            return new Uint8Array();
        }
        
        try {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        } catch (error) {
            console.error('Error converting base64 to Uint8Array:', error);
            return new Uint8Array();
        }
    }

    arrayBufferToBase64(buffer) {
        const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
        return window.btoa(binary);
    }
}

// Export as singleton
export default new PushSubscriptionManager();