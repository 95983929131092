import styled, { css, keyframes } from 'styled-components';
import { FaQuestion } from "react-icons/fa";
import { Heart } from 'lucide-react';
import { LuCross } from "react-icons/lu";
import { FaGrinStars } from "react-icons/fa";
import theme from '../../../theme';
import { Clock, BookOpen, Briefcase, Globe, Calendar, Target } from 'lucide-react';

// Dans styles.js, modifiez les composants suivants:

export const QuizContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 10;
  height: 100vh; /* Utilisez une hauteur fixe de 100vh au lieu de min-height */
  display: flex;
  flex-direction: column;
  
  /* ========== Desktop styles ========== */
  @media (min-width: 800px) {
    /* Leave space for the 320px side menu */
    margin-left: 320px;
    width: calc(100vw - 320px);
    align-items: center;
    justify-content: flex-start;
  }

`;

export const CardWrapper = styled.div`
  transform-style: preserve-3d;
  z-index: 11;
  position: relative;
  width: 300px;
  max-width: 90%;
  margin: 0 auto; /* Centrer horizontalement */
  flex: 1; /* Prendre l'espace disponible */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  /* ========== Mobile styles ========== */
  @media (max-width: 799px) {
    position: relative; /* Changer de fixed à relative */
    top: auto;
    width: 85%;
    height: 50vh; /* Hauteur fixe à 50% de la hauteur de l'écran */
    min-height: 300px; /* Hauteur minimale pour éviter qu'elle soit trop petite */
    margin: 0 auto;
  }
`;

export const QuestionCard = styled.div`
  background-color: ${props => categoryColors[props.category] || props.theme.colors.primary};
  border-radius: 25px;
  padding: ${props => props.theme.spacing.xlarge};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%; /* Prendre toute la hauteur disponible du parent */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
`;

export const BrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  width: 100%;
  padding: 8px;
  z-index: 10;
  position: relative; /* Toujours relatif */
  // margin: 10px 0; /* Marge verticale minimale */

  /* ========== Desktop styles ========== */
  @media (min-width: 800px) {
    margin-top: 1rem;
  }

  /* ========== Mobile styles ========== */
  @media (max-width: 799px) {
    margin-bottom: 10px;
  }
`;

export const AnswerOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10%;
  z-index: 20;
  position: relative;
  width: 80%;
  margin: 15px auto; /* Centrer horizontalement */
  padding-bottom: 20px; /* Marge en bas */

  /* ========== Mobile styles ========== */
  @media (max-width: 799px) {
    position: relative; /* Changer de fixed à relative */
    top: auto;
    width: 90%;
    padding-right: 0;
  }
`;

export const QuizTypeIndicator = styled.div`
  position: relative; /* Changer de absolute à relative */
  padding: 8px 0;
  z-index: 1000;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  max-width: 50%;
  margin: 0 auto;
  position: relative;
  top: auto; /* Supprimer le positionnement vertical */
  // padding: 10px 0;
`;



// Dans styles.js, modifiez les composants suivants:




// Dans styles.js, modifiez les composants suivants:



/* Your category colors, icons, etc. remain unchanged */
export const categoryColors = {
  'Charge de travail': theme.colors.violet,
  'Environnement académique': theme.colors.vert_fonce,
  'Immersion professionnelle': theme.colors.gris_clair,
  'International': theme.colors.orange,
  'Organisation': theme.colors.bleu_fonce,
  'Projets futurs': theme.colors.jaune_clair
};

export const categoryIcons = {
  'Charge de travail': Clock,
  'Environnement académique': BookOpen,
  'Immersion professionnelle': Briefcase,
  'International': Globe,
  'Organisation': Calendar,
  'Projets futurs': Target
};

/* CategoryContainer, CategoryIcon, etc. remain the same */

export const CategoryContainer = styled.div`
  position: absolute;
  top: ${theme.spacing.xxsmall};
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  white-space: nowrap;
`;

export const CategoryText = styled.span`
  background-color: ${props => props.theme.colors.background};
  color: ${props => categoryColors[props.category] || props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: ${props => props.theme.fontWeights.bold};
  padding: ${props => props.theme.spacing.xsmall} ${props => props.theme.spacing.small};
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 2px solid ${props => categoryColors[props.category] || props.theme.colors.primary};
  white-space: nowrap;
  display: inline-block;
`;

export const CategoryIcon = styled.div`
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.spacing.small};

  svg {
    width: 32px;
    height: 32px;
  }
`;


export const QuestionText = styled.p`
  font-size: ${theme.fontSizes.xlarge};
  color: ${theme.colors.background};
  text-align: center;
  font-weight: ${theme.fontWeights.semiBold};
  z-index: 2;
  // flex-grow: 1;
  margin: 0;
  max-width: 98%;
  overflow-y: auto;
`;



export const BackgroundBall = styled.div`
  position: absolute;
  width: 150vw;
  height: calc(var(--vh, 1vh) * 200);
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;

export const QuizMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.large};
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 0 auto;
  position: absolute;
  top: calc(var(--vh, 1vh) * 20);
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.primary};
`;

/* Animation keyframes, etc. remain the same */
const highlightPulse = keyframes`
  0% {
    background-color:${theme.colors.white};
    transform: scale(1);
  }
  50% {
    background-color:${theme.colors.white};
    transform: scale(1.1);
  }
  100% {
    background-color:${theme.colors.white};
    transform: scale(1);
  }
`;

export const AnswerButton = styled.button`
  /* same as your code */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.background};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 10px;

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  /* final swipe pulse */
  ${props => props.finalSwiped && css`
    animation: ${highlightPulse} 0.6s ease-in-out;
  `}
  /* highlight while swiping */
  ${props => props.highlight && css`
    animation: ${highlightPulse} 1s ease-in-out infinite;
    background-color: ${theme.colors.white};
    border: 2px solid ${theme.colors.white};
  `}
`;
const flyCenterScale = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
`;

export const FlyEmoji = styled.div`
  position: absolute;       /* NOT fixed */
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  animation: ${flyCenterScale} 0.8s forwards ease-in-out;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 24px;
  background-color: rgba(230, 230, 230, 0.9);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

export const Progress = styled.div`
  height: 100%;
  border-radius: 12px;
  background-color: ${props => props.$isQuiz1 ? '#4CAF50' : '#2196F3'};
  width: ${props => props.$progress}%;
  transition: width 0.3s ease;
`;

export const QuizNumber = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  z-index: 100;
`;

export const BrandImage = styled.img`
  /* Adjust to taste so it’s not too big on desktop */
  width: 20%;
  height: auto;
  object-fit: contain;

  @media (min-width: 800px) {
    width: 120px; /* example fixed width for desktop */
  }
`;

export const BrandSubtitle = styled.p`
  font-size: 18px;
  color: ${props => props.theme.colors.primary};
  margin: 0;
  font-family: "Sour Gummy", sans-serif;
  margin-top: 0%;
  text-align: center;
`;

/* Direction arrows remain the same */
export const DirectionArrow = styled.div`
  /* same approach, your keyframes remain as-is */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  font-size: 2rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 10;
`;

const pulseUp = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(-5px) translateX(-50%); opacity: 1; }
`;
const pulseDown = keyframes`
  0%, 100% { transform: translateY(0) translateX(-50%); opacity: 0.7; }
  50% { transform: translateY(5px) translateX(-50%); opacity: 1; }
`;
const pulseLeft = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(-5px); opacity: 1; }
`;
const pulseRight = keyframes`
  0%, 100% { transform: translateY(-50%) translateX(0); opacity: 0.7; }
  50% { transform: translateY(-50%) translateX(5px); opacity: 1; }
`;

export const ArrowUp = styled(DirectionArrow)`
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseUp} 2s infinite;
`;
export const ArrowDown = styled(DirectionArrow)`
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${pulseDown} 2s infinite;
  color: ${props => props.theme.colors.white};
`;
export const ArrowLeft = styled(DirectionArrow)`
  left: -20%;
  top: 40%;
  transform: translateY(-50%);
  animation: ${pulseLeft} 2s infinite;
`;
export const ArrowRight = styled(DirectionArrow)`
  right: -20%;
  top: 40%;
  transform: translateY(-50%);
  animation: ${pulseRight} 2s infinite;
`;



export const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  ${props => props.fill && `
    filter: drop-shadow(0 0 0 ${props.fill});
  `}
`;


const IconStyle = `
`;

export const StyledThumbsUp = styled(Heart)`
  ${IconStyle}
`;

export const StyledThumbsDown = styled(LuCross)`
  ${IconStyle}
  transform: rotate(45deg);
`;

export const StyledHelpCircle = styled(FaQuestion)`
  ${IconStyle}
`;

export const StyledHeart = styled(FaGrinStars)`
  ${IconStyle}
`;

