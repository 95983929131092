// src/utils/NotificationService.js

class NotificationService {
    constructor() {
      this.initialized = false;
      this.supported = typeof window !== 'undefined' && 'Notification' in window;
      this.permission = this.supported ? Notification.permission : 'default';
    }
  
    // Initialize the notification service
    init() {
      if (this.initialized) return;
  
      // Check if browser supports notifications
      if (!this.supported) {
        console.warn('This browser does not support notifications');
        return;
      }
  
      this.initialized = true;
      this.permission = Notification.permission;
      
      // Ensure service worker is registered
      // Note: We don't need to register the service worker here since it's already
      // being registered in your main app bootstrap
    }
  
    // Request permission to show notifications
    async requestPermission() {
      if (!this.initialized) this.init();
      
      // Return early if the API is not supported
      if (!this.supported) return false;
      
      // Return early if we already have permission
      if (this.permission === 'granted') return true;
      
      try {
        const permission = await Notification.requestPermission();
        this.permission = permission;
        
        // Store the user's choice in localStorage
        localStorage.setItem('notificationPermissionPrompted', 'true');
        localStorage.setItem('notificationPermission', permission);
        
        return permission === 'granted';
      } catch (error) {
        console.error('Error requesting notification permission:', error);
        localStorage.setItem('notificationPermissionPrompted', 'true');
        localStorage.setItem('notificationPermission', 'denied');
        return false;
      }
    }
  
    // Check if a specific notification has been shown
    hasNotificationBeenShown(notificationId) {
      if (typeof localStorage === 'undefined') return false;
      
      const shownNotifications = JSON.parse(localStorage.getItem('shownNotifications') || '{}');
      return Boolean(shownNotifications[notificationId]);
    }
  
    // Mark a notification as shown
    markNotificationAsShown(notificationId) {
      if (typeof localStorage === 'undefined') return;
      
      const shownNotifications = JSON.parse(localStorage.getItem('shownNotifications') || '{}');
      shownNotifications[notificationId] = Date.now();
      localStorage.setItem('shownNotifications', JSON.stringify(shownNotifications));
    }
    
    // Show a notification if permission is granted and it hasn't been shown before
    async showNotification(notificationId, title, options = {}) {
      if (!this.initialized) this.init();
      
      // Check if notifications are supported
      if (!this.supported) {
        console.warn('Notifications are not supported in this environment');
        return false;
      }
      
      // Check if notification has already been shown
      if (this.hasNotificationBeenShown(notificationId)) {
        console.log(`Notification '${notificationId}' already shown, skipping`);
        return false;
      }
      
      // Check if we have permission
      if (this.permission !== 'granted') {
        console.warn('Notification permission not granted');
        return false;
      }
      
      try {
        // Verify service worker is available
        if (!('serviceWorker' in navigator)) {
          // Fallback to regular Notification if service worker is not available
          const notification = new Notification(title, {
            body: options.body || '',
            icon: options.icon || '/logo192.png',
            badge: options.badge || '/logo192.png',
            tag: notificationId,
          });
          
          if (options.url) {
            notification.onclick = () => {
              window.open(options.url, '_blank');
            };
          }
          
          this.markNotificationAsShown(notificationId);
          return true;
        }
        
        // Get service worker registration
        const registration = await navigator.serviceWorker.ready;
        
        // Show notification
        await registration.showNotification(title, {
          body: options.body || '',
          icon: options.icon || '/logo192.png',  // Use your app's icon
          badge: options.badge || '/logo192.png',
          vibrate: options.vibrate || [200, 100, 200],
          tag: notificationId,
          data: {
            url: options.url || '/',
            ...options.data
          },
          actions: options.actions || []
        });
        
        // Mark as shown
        this.markNotificationAsShown(notificationId);
        return true;
      } catch (error) {
        console.error('Error showing notification:', error);
        return false;
      }
    }
    
    // Check if user has been prompted about notifications
    hasBeenPrompted() {
      return typeof localStorage !== 'undefined' && 
        localStorage.getItem('notificationPermissionPrompted') === 'true';
    }
    
    // Check if user has refused notifications
    hasRefused() {
      return typeof localStorage !== 'undefined' && 
        localStorage.getItem('notificationPermission') === 'denied';
    }
  }
  
  // Export as singleton
  export default new NotificationService();
