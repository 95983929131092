import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// If you have a context for authentication:
import { useAuth } from '../../contexts/AuthContext';
// Or import your own API utils:
import apiCall from '../../utils/api';
import { IoIosArrowBack } from "react-icons/io";

// Popup
import PopupGlobale from '../../components/PopupGlobale';

// Styles
import {
  MobileHistoryContainer,
  Overlay,
  Header,
  CloseButtonWrapper,
  CloseButton,
  Title,
  Line,
  BoxAide,
  AideTitle,
  AideSubtitle,
  AideImage,
  BoxEmail,
  BoxEmailTitle,
  BoxEmailContent,
  BoxInfos,
  VersionText,
  InfosTitle,
  InfosText,
  BoxSocial,
  SocialTitle,
  SocialIconsWrapper,
  SocialIcon,
  BoxCGU,
  ActionsContainer,
  LogoutText,
  DeleteText,
  AideArrow,
  AideTextContainer,
  BoxDelete,
  BoxLogout,
  CGUImage,
  CGUText,
  ScrollContent,
  TokenText,
} from './styles';

import needYouIcon from '../../assets/need_you_icon.png';
import arrowRightIcon from '../../assets/back_button_noir.png';
import instagramIcon from '../../assets/instagram-icon.png';
import tiktokIcon from '../../assets/tiktok-icon.png';
import cguIcon from '../../assets/cgu_icon.png';
import lettreIcon from '../../assets/lettre.png';

const Settings = () => {
  const navigate = useNavigate();
  const { logout } = useAuth(); // from your AuthContext

  const [email, setEmail] = useState('');

  // State to handle popups
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/`,
          'GET'
        );
        // The API returns the user object, e.g. { email: "..." }
        setEmail(response.email || '');
      } catch (error) {
        console.error('Error retrieving user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);

  // Logout logic
  const handleLogout = async () => {
    try {
      await logout(); // from your AuthContext
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Delete account logic
  const handleDeleteAccount = async () => {
    try {
      await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/delete/`, 'DELETE');
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Account deletion error:', error);
    }
  };

  // Navigate back
  const BackButton = () => {
    navigate('/profile');
  };
  console.log("process.env.REACT_APP_VERSION :", process.env.REACT_APP_VERSION);

  return (
    <>
      {/* The dimmed overlay behind the settings container */}
      <Overlay $isOpen={true} />

      {/* Main container */}
      <MobileHistoryContainer $isOpen={true}>
        {/* Header */}
        <Header>
          <CloseButtonWrapper>
            <CloseButton onClick={BackButton} aria-label="Close menu">
              <IoIosArrowBack size={24} />
            </CloseButton>
          </CloseButtonWrapper>
          <Title>Paramètres</Title>
          <Line />
        </Header>

        <ScrollContent>
          {/* "Aide nous à améliorer l'app" Box */}
          <BoxAide onClick={() => navigate('/settings/aide-meo')}>
            <AideImage src={needYouIcon} />
            <AideTextContainer>
              <AideTitle>Aide nous à améliorer l'app !</AideTitle>
              <AideSubtitle>
                Un bug, une recommandation{'\n'}
                d'amélioration, de nouvelles{'\n'}
                fonctionnalités... Meo a besoin de toi !
              </AideSubtitle>
            </AideTextContainer>
            <AideArrow src={arrowRightIcon} />
          </BoxAide>

          {/* Box for Email */}
          <BoxEmail>
            <BoxEmailTitle>Ton email</BoxEmailTitle>
            <BoxEmailContent>{email}</BoxEmailContent>
          </BoxEmail>

          {/* Box for infos about the app */}
          <BoxInfos>
            <InfosTitle>Infos sur l'app</InfosTitle>
            <InfosText>
              L'application Meoria est 100% gratuite. Pour qu'elle le reste,
              nous devons limiter le chat à 10 requêtes par jour et par utilisateur.
            </InfosText>
          </BoxInfos>

          {/* Box with social media icons */}
          <BoxSocial>
            <SocialTitle>Meo sur les réseaux !</SocialTitle>
            <SocialIconsWrapper>
              <a
                href="https://www.instagram.com/meoria_app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={instagramIcon} alt="Instagram" />
              </a>
              <a
                href="https://www.tiktok.com/@meoria_app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={tiktokIcon} alt="TikTok" />
              </a>
            </SocialIconsWrapper>
          </BoxSocial>

          {/* Box for CGU */}
          <BoxCGU>
            <CGUImage src={cguIcon} alt="CGU" />
            <CGUText
              href="https://meoria.com/CGU.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Conditions d'utilisation
            </CGUText>
          </BoxCGU>

          {/* Box for Lettres de motivation bêta */}
          <BoxCGU onClick={() => navigate('/lettres')}>
            <CGUImage src={lettreIcon} alt="Lettres" />
            <CGUText as="span">
              Lettres de motivation bêta
            </CGUText>
          </BoxCGU>

          {/* Action buttons (logout / delete) */}
          <ActionsContainer>
            <BoxLogout>
              <LogoutText onClick={() => setShowLogoutPopup(true)}>
                Me déconnecter
              </LogoutText>
            </BoxLogout>
            <BoxDelete>
              <DeleteText onClick={() => setShowDeletePopup(true)}>
                Supprimer mon compte
              </DeleteText>
            </BoxDelete>
          </ActionsContainer>
          <VersionText>
            Version {process.env.REACT_APP_VERSION || '1.0.1'}
          </VersionText>
        </ScrollContent>
      </MobileHistoryContainer>

      {/* Popup for Logout */}
      <PopupGlobale
        isVisible={showLogoutPopup}
        message="Êtes-vous sûr de vouloir vous déconnecter ?"
        cancelText="Annuler"
        confirmText="Continuer"
        onCancel={() => setShowLogoutPopup(false)}
        onConfirm={() => {
          handleLogout();
          setShowLogoutPopup(false);
        }}
      />

      {/* Popup for Delete Account */}
      <PopupGlobale
        isVisible={showDeletePopup}
        message="Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible"
        cancelText="Annuler"
        confirmText="Continuer"
        onCancel={() => setShowDeletePopup(false)}
        onConfirm={() => {
          handleDeleteAccount();
          setShowDeletePopup(false);
        }}
      />
    </>

  );
};

export default Settings;