import styled from 'styled-components';
import theme from '../../theme';

export const InputContainer = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-bottom: calc(var(--vh, 1vh) * 45);
`;

export const NoIdeaButton = styled.button`
  width: 100%;
  height: calc(var(--vh, 1vh) * 8);
  min-height: 50px;
  border-radius: 15px;
  border: 2px solid ${theme.colors.lightGray};
  margin-top: 10px;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${theme.spacing.medium};

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
    
    &.left-emoji {
      margin-right: 8px;
    }
    
    &.right-emoji {
      margin-left: 8px;
      height: 107%;
    }
  }

  &:hover {
    border-color: ${theme.colors.bleu};
    background-color: ${theme.colors.bleu}10;
  }

  &:focus {
    outline: none;
    border-color: ${theme.colors.bleu};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: calc(var(--vh, 1vh) * 8);
  min-height: 50px;
  border-radius: 15px;
  border: 2px solid ${theme.colors.lightGray};
  padding: 0 ${theme.spacing.large};
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};
  background: white;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${theme.colors.bleu};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }

  &::placeholder {
    color: 'gray';
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;
export const InputWrapper = styled.div`
position: relative; /* Permet d'utiliser top/left absolus sur SuggestionsContainer */
width: 100%;
`;
export const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  max-height: calc(var(--vh, 1vh) * 30);
  overflow-y: auto;
  z-index: 1000;
`;

export const SuggestionItem = styled.div`
  padding: ${theme.spacing.medium};
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-family: ${theme.fonts.main};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.noir};

  &:hover {
    background-color: ${theme.colors.bleu}20;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.lightGray};
  }
`;