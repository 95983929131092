// src/pages/LoginPage/index.js

import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// Import your shared SignIn (or FirstLoginAccount) styles:
import {
  Wrapper,
  EmailLoginContainer,
  ReturnButton,
  NextButton,
  LogoImageFirstLogin,
  InputContainer,
  StyledInput,
  SendCodeButton,
  Divider,
  DividerLine,
  DividerText,
  GoogleButton,
  GoogleButtonContent,
  GoogleIcon,
  GoogleText,
  AppleButton,
  AppleButtonContent,
  AppleIcon,
  AppleText,
  CountdownContainer,
  CountdownText,
  Subtitle,
} from '../LoginPage/styles'; // <-- Adjust this path as needed.

import { Container } from './styles';

import PopupGlobaleOneChoice from '../../components/PopupGlobaleOneChoice';

import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';

// ---------- Assets / Icons / Logos ----------
import MEoSinscrire from '../../assets/sinscrire.png';
import GoogleLogo from '../../assets/google_logo.png';
import AppleLogoWhite from '../../assets/apple_logo_blanc.png';
// Suppose you have a small icon for CGU/politique:
import CguIcon from '../../assets/jugement.png'; // <--- example, adjust to your icon

// ---------- Additional Styled Components ----------

// Optional horizontal line to mimic your Figma snippet:
export const LineDiv = styled.div`
  width: 100%;
  position: relative;
  border-top: 1px solid #b2b2b2;
  box-sizing: border-box;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

// Container holding "En continuant..." text plus icon
export const EnContinuantContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20%;
  font-size: 10px;
  font-family: 'Gabarito', sans-serif;
  color: #b2b2b2;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
`;

export const CguIconImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 6px;
`;

export const UnderlinedLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const AlreadyAccountContainer = styled.div`
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-family: 'Gabarito', sans-serif;
  font-size: 14px;
  color: #bab8b8;
`;

export const LoginLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #bab8b8; /* or adjust to your theme color */
`;
// ---------- Main Component ----------
const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, error: authError } = useAuth();

  // Initialize state from localStorage to persist between app switches
  const [email, setEmail] = useState(() => {
    return localStorage.getItem('signin_email') || '';
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationView, setShowVerificationView] = useState(() => {
    return localStorage.getItem('signin_verification_view') === 'true';
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(() => {
    const savedCountdown = localStorage.getItem('signin_countdown');
    if (savedCountdown) {
      const elapsedTime = (Date.now() - parseInt(localStorage.getItem('signin_countdown_timestamp') || '0')) / 1000;
      const remainingTime = Math.max(0, parseInt(savedCountdown) - Math.floor(elapsedTime));
      return remainingTime;
    }
    return 0;
  });
  const [isValidInput, setIsValidInput] = useState(false);
  const [hasEmailBeenSent, setHasEmailBeenSent] = useState(() => {
    return localStorage.getItem('signin_email_sent') === 'true';
  });
  const [showEmailExistsAlert, setShowEmailExistsAlert] = useState(false);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Apple & Google environment config
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

  // Persist state changes to localStorage
  useEffect(() => {
    if (email) {
      localStorage.setItem('signin_email', email);
    }
  }, [email]);

  useEffect(() => {
    localStorage.setItem('signin_verification_view', showVerificationView);
  }, [showVerificationView]);

  useEffect(() => {
    localStorage.setItem('signin_email_sent', hasEmailBeenSent);
  }, [hasEmailBeenSent]);

  // --------------------------------
  // Navigation Buttons
  // --------------------------------
  const handleBack = () => {
    if (showVerificationView) {
      setShowVerificationView(false);
      setVerificationCode('');
    } else {
      // Clear stored signin state when navigating away completely
      localStorage.removeItem('signin_email');
      localStorage.removeItem('signin_verification_view');
      localStorage.removeItem('signin_email_sent');
      localStorage.removeItem('signin_countdown');
      localStorage.removeItem('signin_countdown_timestamp');
      navigate(-1);
    }
  };

  const handleNext = () => {
    // If an email was sent but user hasn't gone to code view:
    if (hasEmailBeenSent && !showVerificationView) {
      setShowVerificationView(true);
    }
  };

  // --------------------------------
  // Terms & Conditions / CGU & Politique
  // --------------------------------
  const handleViewCGU = () => {
    window.open('https://meoria.com/CGU.pdf', '_blank');
  };

  const handleViewPolitique = () => {
    window.open('https://meoria.com/politique.pdf', '_blank');
  };

  // --------------------------------
  // Checking if an email is already in use
  // --------------------------------
  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/check_email_exists/?email=${encodeURIComponent(email)}`,
        {
          method: 'GET',
          headers: { Accept: 'application/json' },
          credentials: 'omit',
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking email:', error);
      return false;
    }
  };

  // --------------------------------
  // Handle Email Submission
  // --------------------------------
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidInput(isValidEmail(newEmail));
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      // Check if email exists
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        setShowEmailExistsAlert(true);
        return;
      }

      // If email doesn't exist, proceed with sending verification code
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // If success:
      setHasEmailBeenSent(true);
      setShowVerificationView(true);
      
      // Set and persist countdown
      setCountdown(180);
      localStorage.setItem('signin_countdown', '180');
      localStorage.setItem('signin_countdown_timestamp', Date.now().toString());
      
      setIsValidInput(false);
      setVerificationCode('');
    } catch (error) {
      console.error('Error during email submission:', error);
      setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  // --------------------------------
  // Verification Code Submission
  // --------------------------------
  const handleVerificationCodeChange = (e) => {
    const code = e.target.value;
    setVerificationCode(code);
    setIsValidInput(code.length === 6);
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    if (isVerifying || isLoading) return;
    await verifyEmailCode(email, verificationCode);
  };

  // --------------------------------
  // Helper: Validate Email
  // --------------------------------
  const isValidEmail = (emailStr) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailStr);
  };

  // --------------------------------
  // Email Code Verification Flow
  // --------------------------------
  const verifyEmailCode = useCallback(
    async (email, code) => {
      setIsVerifying(true);
      setIsLoading(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email, code }),
          }
        );

        const data = await response.json();
        if (!response.ok) {
          throw new Error(
            data.error || `HTTP error! status: ${response.status}`
          );
        }

        if (data.token && data.token.access) {
          // Clear stored signin state on successful verification
          localStorage.removeItem('signin_email');
          localStorage.removeItem('signin_verification_view');
          localStorage.removeItem('signin_email_sent');
          localStorage.removeItem('signin_countdown');
          localStorage.removeItem('signin_countdown_timestamp');
          
          // If login success:
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setErrorMessage(
          error.message || 'Code expiré ou invalide. Veuillez réessayer.'
        );
      } finally {
        setIsLoading(false);
        setIsVerifying(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // --------------------------------
  // Post-Login Onboarding Save Flow
  // --------------------------------
  const handleLoginSuccess = useCallback(
    async (tokenData, isNewUser = false, userData = null) => {
      try {
        if (isNewUser) {
          // Retrieve localStorage data for your onboarding, if needed
          const typeBac = localStorage.getItem('type_bac');
          let specialites = [];
          specialites = JSON.parse(localStorage.getItem('specialites') || '[]');

          // Inside the handleLoginSuccess function, update the onboardingData object by adding these 3 fields:
          const onboardingData = {
            user_type: localStorage.getItem('type_utilisateur'),
            type_formation: localStorage.getItem('type_formation'),
            type_bac: typeBac,
            niveau_information: localStorage.getItem('niveau_information'),
            source_connaissance: localStorage.getItem('source_connaissance'),
            user_city: localStorage.getItem('user_city'),
            specialites,
            grades: JSON.parse(localStorage.getItem('grades') || '{}'),
            domaine_formation: JSON.parse(
              localStorage.getItem('domaine_formation') || '[]'
            ),
            sub_domaine_formation: JSON.parse(
              localStorage.getItem('sub_domaine_formation') || '[]'
            ),
            pseudo: localStorage.getItem('pseudo') || '',
            
            // Add these three new fields
            lycee_name: localStorage.getItem('lycee_name') || '',
            lycee_city: localStorage.getItem('lycee_city') || '',
            lycee_uai: localStorage.getItem('lycee_uai') || '',
            user_problemes_formation: localStorage.getItem('problemes_formation') || '',
            formation_id: localStorage.getItem('formation_id') || '',
            phone_number: localStorage.getItem('user_phone') || '',
          };

          // Clean up empty or null data:
          const validOnboardingData = Object.entries(onboardingData)
            .filter(([_, value]) => value != null)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

          if (Object.keys(validOnboardingData).length > 0) {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/auth/user/onboarding/`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  credentials: 'include',
                  body: JSON.stringify(validOnboardingData),
                }
              );

              if (!response.ok) {
                const errorData = await response.json();
                console.error('Onboarding error:', errorData);
              }
            } catch (error) {
              console.error('Error saving onboarding data:', error);
            }
          }
        }
        // Finally login to your context
        await login(tokenData.access, isNewUser, userData);
      } catch (err) {
        console.error('Error during login:', err);
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
      }
    },
    [login]
  );

  // --------------------------------
  // Handling a Direct Email Token from the URL
  // (e.g., if user clicked an emailed link)
  // --------------------------------
  const verifyEmailToken = useCallback(
    async (token) => {
      setIsVerifying(true);
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ token }),
          }
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(
            data.error || `HTTP error! status: ${response.status}`
          );
        }

        if (data.token && data.token.access) {
          // Clear stored signin state on successful verification
          localStorage.removeItem('signin_email');
          localStorage.removeItem('signin_verification_view');
          localStorage.removeItem('signin_email_sent');
          localStorage.removeItem('signin_countdown');
          localStorage.removeItem('signin_countdown_timestamp');
          
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error verifying email via token:', error);
        setErrorMessage(error.message || 'Lien expiré ou invalide.');
      } finally {
        setIsVerifying(false);
        setIsLoading(false);
      }
    },
    [handleLoginSuccess]
  );

  useEffect(() => {
    // If the URL has ?token=... handle it
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      verifyEmailToken(token);
    }
  }, [location, verifyEmailToken]);

  // --------------------------------
  // Countdown Timer
  // --------------------------------
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          const newCountdown = prev - 1;
          localStorage.setItem('signin_countdown', newCountdown.toString());
          return newCountdown;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  // --------------------------------
  // Google Login Flow
  // --------------------------------
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const handleGoogleResponse = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        // Decode the token to extract the user's email (if needed)
        const decodedToken = JSON.parse(
          atob(response.credential.split('.')[1])
        );
        const googleEmail = decodedToken.email;

        const emailExists = await checkEmailExists(googleEmail);
        if (emailExists) {
          setShowEmailExistsAlert(true);
          setIsLoading(false);
          return;
        }

        // If email doesn't exist, proceed:
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/auth/google/callback/`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ token: response.credential }),
          }
        );

        const data = await res.json();
        if (res.ok) {
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        } else {
          throw new Error(data.error || 'Failed to authenticate with Google');
        }
      } catch (err) {
        console.error('Error with Google auth:', err);
        setErrorMessage(
          "Une erreur est survenue lors de l'authentification Google."
        );
      } finally {
        setIsLoading(false);
        setIsLoadingGoogle(false);
      }
    },
    [handleLoginSuccess]
  );

  const handleGoogleLogin = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoadingGoogle(true);

      if (window.google?.accounts?.id) {
        // Create a hidden container
        const buttonDiv = document.createElement('div');
        buttonDiv.style.display = 'none';
        document.body.appendChild(buttonDiv);

        // Render the button
        window.google.accounts.id.renderButton(buttonDiv, {
          type: 'standard',
          theme: 'outline',
          size: 'large',
        });

        // Programmatically click it
        const googleButton = buttonDiv.querySelector('div[role="button"]');
        if (googleButton) {
          googleButton.click();
          setTimeout(() => buttonDiv.remove(), 100);
        }
      } else {
        console.error('Google Sign-In API not loaded');
        setIsLoadingGoogle(false);
      }
    },
    []
  );

  useEffect(() => {
    // Load Google Script
    const loadGoogleScript = () => {
      const existingScript = document.querySelector(
        'script[src="https://accounts.google.com/gsi/client"]'
      );
      if (existingScript) return;

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (window.google?.accounts?.id) {
          window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleGoogleResponse,
            auto_select: false,
            cancel_on_tap_outside: true,
          });
        }
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://accounts.google.com/gsi/client"]'
      );
      if (script) script.remove();
    };
  }, [handleGoogleResponse]);

  // --------------------------------
  // Apple Login Flow
  // --------------------------------
  const [appleErrorMessage, setAppleErrorMessage] = useState('');

  const handleAppleResponse = useCallback(
    async (event) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/auth/apple/callback/`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
              id_token: event.authorization.id_token,
              user: event.user || {},
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          await handleLoginSuccess(data.token, data.is_new_user, data.user);
        } else {
          throw new Error(data.error || 'Failed to authenticate with Apple');
        }
      } catch (err) {
        console.error('Error during Apple authentication:', err);
        setAppleErrorMessage(
          "Une erreur est survenue lors de l'authentification Apple."
        );
      } finally {
        setIsLoading(false);
      }
    },
    [handleLoginSuccess]
  );

  const handleAppleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setAppleErrorMessage('');

      try {
        if (!APPLE_CLIENT_ID) {
          throw new Error('Apple Sign In is not configured');
        }
        if (!window.AppleID) {
          throw new Error('Apple Sign In is not initialized');
        }

        const redirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI;
        const config = {
          clientId: APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI,
          state: 'origin:web',
          usePopup: true,
        };

        window.AppleID.auth.init(config);
        // 30s timeout
        const signInPromise = window.AppleID.auth.signIn();
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Sign in timeout')), 30000)
        );

        const data = await Promise.race([signInPromise, timeoutPromise]);
        await handleAppleResponse(data);
      } catch (error) {
        console.error('Error during Apple Sign In:', error);
        if (error.message === 'Sign in timeout') {
          setAppleErrorMessage(
            'La connexion avec Apple prend trop de temps. Veuillez réessayer.'
          );
        } else {
          setAppleErrorMessage(
            'Une erreur est survenue avec Apple Sign In. Veuillez réessayer.'
          );
        }
      } finally {
        setIsLoading(false);
      }
    },
    [APPLE_CLIENT_ID, handleAppleResponse]
  );

  useEffect(() => {
    // Load Apple script
    let scriptLoadTimeout;
    const loadAppleScript = async () => {
      try {
        if (!APPLE_CLIENT_ID) {
          console.error('Apple Client ID is not configured');
          return;
        }
        if (document.getElementById('appleid-signin-script')) return;

        const script = document.createElement('script');
        script.src =
          'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.id = 'appleid-signin-script';

        const scriptLoadPromise = new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = () =>
            reject(new Error('Failed to load Apple Sign In script'));
          document.head.appendChild(script);
        });

        const timeoutPromise = new Promise((_, reject) => {
          scriptLoadTimeout = setTimeout(() => {
            reject(new Error('Failed to load Apple Sign In script: Timeout'));
          }, 10000);
        });

        await Promise.race([scriptLoadPromise, timeoutPromise]);
      } catch (err) {
        console.error('Error loading Apple Sign In:', err);
        setAppleErrorMessage(
          'Erreur lors du chargement de Apple Sign In. Veuillez rafraîchir la page.'
        );
      }
    };

    loadAppleScript();
    return () => {
      if (scriptLoadTimeout) clearTimeout(scriptLoadTimeout);
      const scriptElement = document.getElementById('appleid-signin-script');
      if (scriptElement) scriptElement.remove();
    };
  }, [APPLE_CLIENT_ID]);

  const handleAlertConfirm = () => {
    setShowEmailExistsAlert(false);
    navigate('/login');
  };  

  // --------------------------------
  // "Déjà inscrit ? Se connecter" at bottom
  // --------------------------------
  const handleExistingAccount = () => {
    // Clear stored signin state when navigating to login
    localStorage.removeItem('signin_email');
    localStorage.removeItem('signin_verification_view');
    localStorage.removeItem('signin_email_sent');
    localStorage.removeItem('signin_countdown');
    localStorage.removeItem('signin_countdown_timestamp');
    navigate('/login');
  };

  // Show an overall loading message if verifying
  if (isVerifying) {
    return (
      <Container>
        <p style={{ textAlign: 'center', marginTop: '50px' }}>
          Nous vérifions votre email... Veuillez patienter.
        </p>
      </Container>
    );
  }

  // --------------------------------
  // Render
  // --------------------------------
  return (
    <Wrapper>
      {/* Top Left Return Button */}
      <ReturnButton onClick={handleBack}>
        <IoIosArrowBack />
      </ReturnButton>

      {/* If email was sent but user didn't switch view yet, show NextButton */}
      {hasEmailBeenSent && !showVerificationView && (
        <NextButton onClick={handleNext}>
          <IoIosArrowForward />
        </NextButton>
      )}

      {/* Logo */}
      <LogoImageFirstLogin src={MEoSinscrire} alt="Compass Logo" />

      <Container>
        {/* SubTitle changes if we are verifying */}
        <EmailLoginContainer>
          {/* Input + Button */}
          <form
            onSubmit={
              showVerificationView ? handleVerificationSubmit : handleEmailSubmit
            }
          >
            {showVerificationView && (
              <Subtitle>
                Code envoyé à {email}
              </Subtitle>
            )}
            <InputContainer>
              <StyledInput
                type={showVerificationView ? 'text' : 'email'}
                autoComplete={showVerificationView ? 'off' : 'email'}
                placeholder={
                  showVerificationView
                    ? 'Entrez le code à 6 chiffres'
                    : 'Entrez votre email'
                }
                value={showVerificationView ? verificationCode : email}
                onChange={
                  showVerificationView
                    ? handleVerificationCodeChange
                    : handleEmailChange
                }
                maxLength={showVerificationView ? 6 : undefined}
                pattern={showVerificationView ? '[0-9]*' : undefined}
                inputMode={showVerificationView ? 'numeric' : 'email'}
                required
              />
            </InputContainer>

            {/* Error Message if any */}
            {errorMessage && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '8px' }}>
                {errorMessage}
              </p>
            )}

            <SendCodeButton
              type="submit"
              disabled={
                isLoading ||
                (showVerificationView
                  ? verificationCode.length !== 6
                  : !isValidInput)
              }
            >
              {isLoading
                ? 'Chargement...'
                : showVerificationView
                ? 'Vérifier'
                : 'Envoyer le code'}
            </SendCodeButton>
          </form>

          {/* Countdown */}
          {showVerificationView && (
            <>
              <CountdownContainer>
                {countdown > 0 && showVerificationView && (
                  <CountdownText>
                    Vous pourrez renvoyer un code dans:{' '}
                    {Math.floor(countdown / 60)}:
                    {(countdown % 60).toString().padStart(2, '0')}
                  </CountdownText>
                )}
              </CountdownContainer>
              <EnContinuantContainer>
                <CguIconImg src={CguIcon} alt="cgu-icon" />
                <div>
                  En continuant, vous acceptez nos{' '}
                  <UnderlinedLink onClick={handleViewCGU}>
                    conditions générales d'utilisation
                  </UnderlinedLink>
                  <br />
                  et notre{' '}
                  <UnderlinedLink onClick={handleViewPolitique}>
                    politique de confidentialité
                  </UnderlinedLink>
                </div>
              </EnContinuantContainer>
            </>
          )}

        </EmailLoginContainer>

        {/* If not in verification view => Social Buttons + divider */}
        {!showVerificationView && (
          <>
            {/* Optionally, render a divider if you want to separate from the email/verification form */}
            
              <Divider>
                <DividerLine />
                <DividerText>ou</DividerText>
                <DividerLine />
              </Divider>
            

            {isIOS && (
              <AppleButton onClick={handleAppleLogin} disabled={isLoading}>
                <AppleButtonContent>
                  <AppleIcon src={AppleLogoWhite} alt="Apple" />
                  <AppleText>
                    {isLoading ? 'Chargement...' : 'Apple'}
                  </AppleText>
                </AppleButtonContent>
              </AppleButton>
            )}

            {!isIOS && (
              <GoogleButton onClick={handleGoogleLogin} disabled={isLoadingGoogle}>
                <GoogleButtonContent>
                  <GoogleIcon src={GoogleLogo} alt="Google" />
                  <GoogleText>
                    {isLoadingGoogle ? 'Chargement...' : 'Google'}
                  </GoogleText>
                </GoogleButtonContent>
              </GoogleButton>
            )}
          </>
        )}


        {/* Alert if email already exists */}
        <PopupGlobaleOneChoice
            isVisible={showEmailExistsAlert}
            message="Cette adresse email est déjà utilisée. Veuillez vous connecter avec votre compte existant."
            onClose={handleAlertConfirm}
          />
      </Container>

      {/* "Déjà inscrit ? Se connecter" at the bottom */}
      <AlreadyAccountContainer>
        <span>Déjà inscrit&nbsp;?</span>
        <LoginLink onClick={handleExistingAccount}>Se connecter</LoginLink>
      </AlreadyAccountContainer>
    </Wrapper>
  );
};

export default Signin;
